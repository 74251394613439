import { PayloadAction } from "@reduxjs/toolkit";
import { FormError } from "app/containers/types";
import set from "lodash/set";
import { createSlice } from "utils/@reduxjs/toolkit";
import { UserState, UsersListItemType } from "../types";
const initialState = UserState;
export const usersListSlice = createSlice({
  name: "usersListState",
  initialState,
  reducers: {
    editUser: (state, action: PayloadAction<UsersListItemType>) => {},
    removeUser: (state) => {
      const editUser: any = {};
      editUser.firstName = "";
      editUser.lastName = "";
      editUser.email = "";
      editUser.phoneNumber = "";
      editUser.creationDate = null;
      editUser.dateOfBirth = null;
    },
    create: (state, action) => {
      state.loading = true;
    },
    createSuccess: (state) => {
      state.loading = false;
      state.form.user = initialState.form.user;
    },
    createFailed: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    getList: (state) => {
      state.loading = true;
      state.query.page = 1;
    },
    getUserById: (state, action: PayloadAction<{ id: any }>) => {
      state.loading = true;
    },
    getListSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      const items = action.payload.items;
      state.list.items =
        action.payload.meta.currentPage === 1
          ? items
          : [...state.list.items, ...items];
      state.list.meta = action.payload.meta;
    },
    getQuestionnaireList: (state) => {
      state.loading = true;
    },
    getOuestionnaireListSuccess: (state, action) => {
      state.loading = false;
      state.questionnaire = action.payload;
    },
    setQualfication: (state, action) => {
      state.qualification.items = [
        ...action.payload.map((item) => {
          return {
            "#": item["#"],
            treatmentName: item.treatmentName,
            treatmentId: item.id,
          };
        }),
        ...state.qualification.items,
      ];
    },
    setPermission: (state, action) => {
      state.patientPermission.items = [
        {
          id: null,
          organizationId: action.payload.organization.id,
          organization: action.payload.organization,
          departmentId: action.payload?.department?.id,
          department: action.payload.department,
          bankId: action.payload.bank?.id,
          bank: action.payload.bank,
          emailNotification: false,
        },
        ...state.patientPermission.items,
      ];
    },
    setOrganisationFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    setCityFailure: (state, action) => {
      state.loading = false;
      state.city.items = [];
      state.error = action.payload;
    },
    getTreatmentList: (state, action: PayloadAction<string>) => {
      state.treatment.items = [];
      state.loading = true;
    },
    getInstructorList: (
      state,
      action: PayloadAction<{ treatmentId: number }>
    ) => {
      state.instructor.items = [];
      state.loading = true;
    },
    getactualInstructorList: (
      state,
      action: PayloadAction<{ treatmentId: number }>
    ) => {
      state.actualInstructor.items = [];
      state.loading = true;
    },
    getTreatmentListByBank: (
      state,
      action: PayloadAction<{ bankId: number; all: boolean }>
    ) => {
      state.treatment.items = [];
      state.loading = true;
    },
    getQualificationList: (state, action) => {
      state.loading = true;
    },
    getQualificationListSuccess: (state, action) => {
      state.loading = false;
      state.qualification.items = action.payload.qualification.map(
        (item, index) => {
          return {
            id: item.id,
            "#": index,
            treatment: item.treatment,
            treatmentId: item.id,
          };
        }
      );
      state.patientPermission.items = action.payload.permission;
    },
    getTreatmentListSuccess: (state, action) => {
      state.loading = false;
      state.treatment.items = action.payload.items.map((item, index) => {
        return {
          id: item.id,
          "#": index,
          type: item.type,
          treatmentName: item.name,
          session_count: item.session_count,
          session_duration: item.session_duration,
          maximum_participants: item.maximum_participants,
        };
      });
    },
    getInstructorListSuccess: (state, action) => {
      state.loading = false;
      state.instructor.items = action.payload.items.map((item, index) => {
        return {
          id: item.id,
          "#": index,
          firstName: item.firstName,
          lastName: item.lastName,
        };
      });
    },
    getActualInstructorListSuccess: (state, action) => {
      state.loading = false;
      state.actualInstructor.items = action.payload.items.map((item, index) => {
        return {
          id: item.id,
          "#": index,
          firstName: item.firstName,
          lastName: item.lastName,
        };
      });
    },
    deleteQualification: (state, action) => {
      const { id, idx } = action.payload;
      if (id) {
        for (let i = 0; i < state.qualification.items.length; i++) {
          const item = state.qualification.items[i];
          if (item.id === id) {
            state.qualification.items[i].action = "delete";
            break;
          }
        }
      } else {
        state.qualification.items = state.qualification.items.filter(
          (item, index) => index !== idx
        );
      }
    },
    deletePermission: (state, action) => {
      const { id, idx } = action.payload;
      if (id) {
        for (let i = 0; i < state.patientPermission.items.length; i++) {
          const item = state.patientPermission.items[i];
          if (item.id === id) {
            state.patientPermission.items[i].action = "delete";
            break;
          }
        }
      } else {
        state.patientPermission.items = state.patientPermission.items.filter(
          (item, index) => index !== idx
        );
      }
    },
    getDepartmentList: (state, action) => {
      state.loading = true;
    },
    getDepartmentListSuccess: (state, action) => {
      state.loading = false;
      state.departments.items = action.payload.map((item) => {
        return {
          id: item.id,
          name: item.departmentName,
          display: item.departmentName,
        };
      });
    },
    setDepartmentFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getBankList: (state, action) => {
      state.loading = true;
    },
    getBankListSuccess: (state, action) => {
      state.loading = false;
      state.banks = action.payload.map((item) => {
        return {
          id: item.id,
          name: item.name,
        };
      });
    },
    setBankFailure: (state, action) => {
      state.banks = [];
      state.loading = false;
      state.error = action.payload;
    },
    setTreatmentFailure: (state, action) => {
      state.treatment.items = [];
      state.loading = false;
      state.error = action.payload;
    },
    setInstructorFailure: (state, action) => {
      state.instructor.items = [];
      state.loading = false;
      state.error = action.payload;
    },
    setActualInstructorFailure: (state, action) => {
      state.instructor.items = [];
      state.loading = false;
      state.error = action.payload;
    },
    getNextPage: (state, action: PayloadAction<any>) => {
      state.query.page = state.list.meta.currentPage + 1;
      state.loading = true;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    update: (state, action: PayloadAction<any>) => {
      state.error = "";
      state.loading = true;
    },
    updateFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state.form.user, `${action.payload.key}.value`, action.payload.value);
      set(state.form.user, `${action.payload.key}.error`, null);
      state.error = "";
    },
    updateSuccess(state, action: PayloadAction<UsersListItemType>) {
      state.loading = false;
    },
    updateFailed: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    enableDisable: (
      state,
      action: PayloadAction<{ row: UsersListItemType; id: number }>
    ) => {
      state.list.items[action.payload.id].isLoading = true;
    },
    enableDisableSuccess: (
      state,
      action: PayloadAction<{
        row: { user: UsersListItemType };
      }>
    ) => {
      const item = state.list.items.find(
        (i) => i.id == action.payload?.row?.user?.id
      );
      if (item) {
        item.isLoading = false;
        item.isActive = action.payload?.row?.user?.isActive;
      }
    },
    enableDisableFail: (state, action: PayloadAction<number>) => {
      const item = state.list.items.find((i) => i.id == action.payload);
      if (item) {
        item.isLoading = false;
      }
    },
    enableEMailNofication: (state, action: PayloadAction<{ id: any }>) => {
      state.patientPermission.items[action.payload.id].emailNotification =
        !state.patientPermission.items[action.payload.id].emailNotification;
      state.patientPermission.items[action.payload.id].action = "edit";
    },
    delete: (state) => {
      state.error = "";
      state.loading = true;
    },
    deleteSuccess: (state) => {
      state.loading = false;
      state.error = "";
    },
    deleteFailed: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    resetNewUserForm(state) {
      state.error = "";
      state.form = initialState.form;
    },
    setFormErrors(
      state,
      action: PayloadAction<{
        key: string;
        errors: FormError[];
      }>
    ) {
      action.payload.errors.forEach((error) => {
        set(
          state.form,
          `${action.payload.key}.${error.name}.error`,
          error.error
        );
      });
    },
    getNextPageItems: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    getNextPageItemsSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.list.items = state.list.items.concat(action.payload.items);
      state.list.meta.currentPage = action.payload.meta.currentPage;
      state.list.links = action.payload.links;
      state.query.page = action.payload.meta.currentPage;
    },
    setQuery: (state, action: PayloadAction<{ name: string; value: any }>) => {
      const { name, value } = action.payload;
      state.query.page = 1;
      state.list = initialState.list;
      set(state.query, name, value);
    },
    resetModule: (state) => {
      state = initialState;
    },
    resetData: (state) => {
      state.patientPermission = initialState.patientPermission;
      state.qualification = initialState.qualification;
    },
    resetQualification: (state) => {
      state.qualification.items = [];
    },
    getById: (state, action: PayloadAction<number>) => {
      state.loading = true;
    },
    getGetByIdSuccess: (state, action: PayloadAction<any>) => {
      state.form.user.cityId.value = action.payload.cityId;
      state.form.user.dob.value = action.payload.dateOfBirth;
      state.form.user.email.value = action.payload.email;
      state.form.user.firstName.value = action.payload.firstName;
      state.form.user.gender.value = action.payload.gender;
      state.form.user.lastName.value = action.payload.lastName;
      state.form.user.street.value = action.payload.street;
      state.form.user.mobileNumber.value = action.payload.mobileNumber;
      state.form.user.groupId.value = action.payload.userGroupId;
    },
    clearUserFrom: (state) => {
      state.form.user.cityId.value = "";
      state.form.user.dob.value = null;
      state.form.user.email.value = "";
      state.form.user.firstName.value = "";
      state.form.user.gender.value = "";
      state.form.user.lastName.value = "";
      state.form.user.street.value = "";
      state.form.user.mobileNumber.value = "";
      state.form.user.groupId.value = "";
      state.patientPermission.item = [];
      state.qualification = [];
    },
    resetQuery: (state) => {
      state.query = initialState.query;
    },
  },
});
export const { reducer, actions, name: sliceKey } = usersListSlice;
