import { Grid } from "@mui/material";
import TreatmentTypeFiled from "app/components/FieldComponent/TreatmentType";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectQuery } from "../ListView/redux/selector";
import { actions } from "../ListView/redux/slice";

const OppositeMonthFiltersForm = () => {
  const dispatch = useDispatch();

  const { treatmentId } = useSelector(selectQuery);

  const setPageQuery = (name: string, value: any) => {
    dispatch(actions.setQuery({ name, value }));
  };

  return (
    <Grid container spacing={2} md={12} sx={{ paddingBottom: "10px" }}>
      <TreatmentTypeFiled
        xs={2}
        value={treatmentId}
        bankId={0}
        changeValue={(value) => {
          setPageQuery("treatmentId", value);
        }}
        showBlank={true}
        showAll={false}
        fetchAll={true}
      ></TreatmentTypeFiled>
    </Grid>
  );
};
export default OppositeMonthFiltersForm;
