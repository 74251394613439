import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  messageModal: {
    position: "absolute",
    background: "#FFFFFF",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "0px",
    boxShadow: "0",
    zIndex: "9999",
    backgroundColor: "#F5F8FF",
    outline: "none",
  },
  modalContainer: {
    padding: "25px",
    width: "700px",
  },
  error: {
    fontFamily: "Cairo",
    fontSize: "0.75rem",
    margin: "0px",
    color: "#d32f2f",
    marginLeft: "14px",
  },
  treatment_heading: {
    textAlign: "center",
    fontFamily: "Cairo",
    fontWeight: "bolder",
    fontSize: "24px",
    color: "#012397",
    margin: "5px 0px 20px 0px",
  },
  btnContainer: {
    display: "flex",
    alignItems: "flex-end",
  },
  cancelBtn: {
    color: "#3D63E6",
    borderColor: "#3D63E6",
    "&:hover": {
      borderColor: "#3D63E6",
    },
    fontFamily: "Cairo",
    fontWeight: 700,
    fontSize: "16px",
    width: "170px",
    height: "50px",
  },
  saveBtn: {
    background: "#3D63E6",
    padding: "10px 35px",
    border: "none",
    color: "#fff",
    marginBottom: "10px",
    borderRadius: "3px",
    fontSize: "16px",
    width: "170px",
    height: "50px",
    fontWeight: 700,
    fontFamily: "Cairo",
  },

  button_section: {
    marginTop: "10px",
  },

  textEnabled: {
    background: "#FFF",
    width: "100%",
    height: "56px",
    borderRadius: "4px",
    position: "relative",
    color: "#6C7A7A",
    padding: "5px 10px",
    outline: "none",
  },
  textDisabled: {
    opacity: 0.5,
  },
  dateArea: {
    background: "#FFF",
    width: "100%",
    height: "56px",
    borderRadius: "4px",
    position: "relative",
    color: "#6C7A7A",
    outline: "none",
    margin: "0px",
  },

  label: {
    fontFamily: "Cairo",
    fontWeight: 400,
    fontSize: "16px",
    color: "#2A3333",
  },
}));
