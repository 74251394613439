import {
  emailRegex,
  validateAndFormatIsraeliMobileNumber,
} from "styles/theme/utils";

const validateEmail = (email) => {
  return email.match(emailRegex);
};

export const onValidate = (form) => {
  const errors: { error: any; name: string }[] = [];
  if (form.contactName.value.length === 0) {
    errors.push({
      name: "contactName",
      error: "COMMON.CONTACT_NAME_REQUIRED",
    });
  }
  if (form.mobileNumber.value.length === 0) {
    errors.push({
      name: "mobileNumber",
      error: "COMMON.MOBILE_NUMBER_REQUIRED",
    });
  }
  if (!validateAndFormatIsraeliMobileNumber(form.mobileNumber.value).isValid) {
    errors.push({
      name: "mobileNumber",
      error: "COMMON.INVALID_MOBILE_NUMBER",
    });
  }
  if (form.title.value.length === 0) {
    errors.push({
      name: "title",
      error: "COMMON.TITLE_REQUIRED",
    });
  }
  if (form.email.value.length === 0) {
    errors.push({
      name: "email",
      error: "COMMON.EMAIL_REQUIRED",
    });
  }
  if (!validateEmail(form.email.value)) {
    errors.push({
      name: "email",
      error: "COMMON.INVALID_EMAIL",
    });
  }
  return errors;
};

export const isDuplicate = (form, contacts) => {
  const errors: { error: any; name: string }[] = [];
  const duplicateName = contacts.find(
    (contact) =>
      contact.contactName.toLowerCase() === form.contactName.value.toLowerCase()
  );
  const duplicateEmail = contacts.find(
    (contact) => contact.email.toLowerCase() === form.email.value.toLowerCase()
  );
  const duplicateMobileNumber = contacts.find(
    (contact) => contact.mobileNumber === form.mobileNumber.value
  );
  if (duplicateName) {
    errors.push({
      name: "contactName",
      error: "COMMON.DUPLICATE_CONTACT_NAME",
    });
  }
  if (duplicateEmail) {
    errors.push({
      name: "email",
      error: "COMMON.DUPLICATE_EMAIL",
    });
  }
  if (duplicateMobileNumber) {
    errors.push({
      name: "mobileNumber",
      error: "COMMON.DUPLICATE_MOBILE_NUMBER",
    });
  }
  return errors;
};
