import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
    label: {
        color: "#000",
        fontSize: "16px",
        marginBottom: "10px",
        marginLeft: "16px"
    },
    input: {
        fontFamily: "Cairo",
        fontSize: "20px",
        fontWeight: 400,
        height: "55px",
    },
    error: {
        color: "#FF0000",
        fontSize: "14px",
        fontWeight: 400
    }
}));
