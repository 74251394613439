import queryString from "query-string";
import { delay, put, select, takeLatest } from "redux-saga/effects";
import { API_URL } from "utils/constants";
import {
  TRANSLATE,
  getDefaultHeaders,
  removeBlankProperties,
} from "utils/helpers";
import { request } from "utils/request";
import toastService from "utils/toast";
import { selectQuery } from "../../ListView/redux/selector";
import { actions } from "./slice";

export function* getListMonth(action) {
  yield delay(500);

  const query = yield select(selectQuery);
  const requestData = removeBlankProperties(query);
  const queries = queryString.stringify({
    ...requestData,
  });
  try {
    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    const response = yield request(
      `${API_URL}/v1/timeslot/list-for-calendar?${queries}`,
      options
    );
    response.mode = query.mode;
    yield put(actions.getListMonthSuccess(response));
  } catch (e: any) {
    console.log({ e });

    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", {
        error: JSON.stringify(e),
      })
    );
  }
}
export function* getListWeek(action) {
  yield delay(500);

  const query = yield select(selectQuery);
  const requestData = removeBlankProperties(query);
  const queries = queryString.stringify({
    ...requestData,
  });
  try {
    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    const response = yield request(
      `${API_URL}/v1/timeslot/list-for-calendar?${queries}`,
      options
    );
    response.mode = query.mode;
    yield put(actions.getListWeekSuccess(response));
  } catch (e: any) {
    console.log({ e });
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", {
        error: JSON.stringify(e),
      })
    );
  }
}
export function* getListInstructor(action) {
  yield delay(500);

  const query = yield select(selectQuery);
  const requestData = removeBlankProperties(query);
  const queries = queryString.stringify({
    ...requestData,
  });
  try {
    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    const response = yield request(
      `${API_URL}/v1/timeslot/instructors-for-calendar?${queries}`,
      options
    );
    response.mode = query.mode;
    yield put(actions.getListInstructorSuccess(response));
  } catch (e: any) {
    console.log({ e });
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", {
        error: JSON.stringify(e),
      })
    );
  }
}

export function* deleteRow() {}
export function* useCalenderSaga() {
  yield takeLatest(actions.getListMonth.type, getListMonth);
  yield takeLatest(actions.getListWeek.type, getListWeek);
  yield takeLatest(actions.getListInstructor.type, getListInstructor);
}
