import { Add } from "@mui/icons-material";
import { Fab, Grid } from "@mui/material";
import DataTable from "app/components/DataTable";
import { ScrollableView } from "app/components/ScrollableView";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { selectFeaturePermissions } from "store/global/authPermission/selector";
import { PermissionFeature } from "store/global/authPermission/types";
import { MAX_LIMIT } from "utils/helpers";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import ContactModal from "./components/ContactModal";
import { useContactSaga } from "./redux/saga";
import {
  selectContact,
  selectList,
  selectLoading,
  selectQuery,
} from "./redux/selector";
import { actions, reducer, sliceKey } from "./redux/slice";
import { useStyles } from "./styles";

interface ContactsListProps {
  view: boolean;
  disable: boolean;
  mode: String;
}

const ContactsList = ({ view, disable, mode }: ContactsListProps) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [currentContactName, setCurrentContactName] = useState("");
  const listitems = useSelector(selectList);
  const { meta, items } = listitems;
  const params = useLocation();
  const { t } = useTranslation();

  const featurePermissions = useSelector(selectFeaturePermissions);
  const { hasFullAccess, hasReadAccess, hasNoAccess } =
    featurePermissions[PermissionFeature.ORGANIZATIONS_DETAILS];

  const { q, orderField, orderDirection } = useSelector(selectQuery);
  //const searchField = useSelector(selectSearch);
  const styles = useStyles();

  const hasMoreItems = meta.currentPage < meta.totalPages;
  const getNextPage = () => {
    dispatch(actions.getNextPage(meta.currentPage + 1));
  };

  useInjectReducer({ key: sliceKey, reducer });
  useInjectSaga({ key: sliceKey, saga: useContactSaga });

  const columns = [
    {
      id: "contactName",
      label: t("ORGANIZATIONS.CONTACT_NAME"),
      sortValue: "selector.contactName",
    },
    {
      id: "title",
      label: t("ORGANIZATIONS.TITLE"),
      sortValue: "selector.title",
    },
    {
      id: "email",
      label: t("ORGANIZATIONS.EMAIL"),
      sortValue: "selector.email",
    },
    {
      id: "mobileNumber",
      label: t("ORGANIZATIONS.MOBILE_NUMBER"),
      sortValue: "selector.mobileNumber",
      width: "16%",
    },
  ];
  const dispatch = useDispatch();
  const form = useSelector(selectContact);

  const setPageQuery = (name: string, value: any) => {
    dispatch(actions.setQuery({ name, value }));
  };

  const loading = useSelector(selectLoading);

  const handleDeleteClose = () => {
    setOpenDelete(false);
  };
  const handleDeleteOpen = (e) => {
    setCurrentContactName(e.contactName);
    setOpenDelete(true);
  };
  const handlEditModalClose = (e) => {
    setOpenEditModal(false);
    dispatch(actions.resetNewUserForm());
  };
  const handlEditModalSave = (e) => {
    setOpenEditModal(false);
    dispatch(actions.editUser());
    dispatch(actions.resetNewUserForm());
  };
  const handlEditModalOpen = (e) => {
    for (let i in e) {
      dispatch(actions.updateFormValue({ key: i, value: e[i] }));
    }
    setOpenEditModal(true);
  };
  const handlAddModalClose = () => {
    setOpenAddModal(false);
    dispatch(actions.resetNewUserForm());
  };
  const handlAddModalSave = () => {
    setOpenAddModal(false);
    dispatch(actions.create());
    dispatch(actions.resetNewUserForm());
  };
  const handlAddModalOpen = () => {
    setOpenAddModal(true);
  };
  const handleDeleteContact = (row, idx) => {
    dispatch(actions.deleteContact({ ...row, idx }));
  };
  return (
    <>
      <ScrollableView hasMoreItems={hasMoreItems} loadMore={getNextPage}>
        <DataTable
          loading={loading}
          orderDirection={orderDirection}
          Actions={hasFullAccess ? "Edit, Delete" : ""}
          editAction={handlEditModalOpen}
          orderField={orderField}
          setQuery={setPageQuery}
          rows={listitems["items"].filter((item) => item.action !== "delete")}
          columns={columns}
          page="contactList"
          deleteAction={handleDeleteContact}
          disableActions={disable ? "Edit, Delete" : ""}
        />
      </ScrollableView>

      <Grid
        item
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Fab
          size="medium"
          color="secondary"
          aria-label="add"
          sx={{ marginTop: 2, marginBottom: 2 }}
          onClick={handlAddModalOpen}
          disabled={disable || listitems?.items?.length >= MAX_LIMIT}
        >
          <Add />
        </Fab>
      </Grid>

      <ContactModal
        open={openEditModal}
        handleClose={handlEditModalClose}
        handleSave={handlEditModalSave}
        action={"Edit"}
      />
      <ContactModal
        open={openAddModal}
        handleClose={handlAddModalClose}
        handleSave={handlAddModalSave}
        action={"Add"}
      />
    </>
  );
};
export default ContactsList;
