import Data, { Questiondata } from "./dummyData";

export interface ProgressTabListItemType {
  treatmentName: string;
  patientsCompletedTreatment: number | null;
  patientsInProcessOfTreatment: number | null;
  patientsWhoMissedTreatment: number | null;
  patientsWhoRegisteredToBonusTimeslot: number | null;
}
export interface QuestionItem {
 
  questionBody: string;
  patientSelection: string;
  patientSelectionWeight: number;
}

export interface QuestionnaireResultDetails
  extends ProgressTabListItemType {

}

export type ProgressTabStateType = {
  query: {
    limit: number;
    page: number;
    distributorId: string;
    user_type: string;
    q: string;
    orderField: string;
    orderDirection: string;
    createMin: string;
    createMax: string;
    updateMin: string;
    updateMax: string;
  };
  form?: QuestionnaireResultDetails;
  list: {
    items: Array<ProgressTabListItemType>;
    selectedIds: Array<Number>;
    meta: {
      totalItems: number;
      itemCount: number;
      itemsPerPage: number;
      totalPages: number;
      currentPage: number;
    };
    links: {
      first: string;
      last: string;
      previous: string;
      next: string;
    };
  };
  questionList:QuestionItem[],
  loading: boolean;
  error: string;
  modal: boolean;
};

export const initialState: ProgressTabStateType = {
  query: {
    user_type: "",
    distributorId: "",
    limit: 10,
    page: 1,
    q: "",
    orderField: "",
    orderDirection: "",
    updateMax: "",
    updateMin: "",
    createMax: "",
    createMin: "",
  },
  list: {
    selectedIds: [],
    items: Data,
    meta: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 100,
      totalPages: 1,
      currentPage: 1,
    },
    links: {
      first: "",
      previous: "",
      next: "",
      last: "",
    },
  },
  loading: false,
  form: {
    treatmentName: "",
  patientsCompletedTreatment: 0,
  patientsInProcessOfTreatment: 0,
  patientsWhoMissedTreatment: 0,
  patientsWhoRegisteredToBonusTimeslot: 0,
  },
  error: "",
  modal: false, questionList:Questiondata
};

export type ContainerState = ProgressTabStateType;
