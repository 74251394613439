import { Tooltip } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import download from "./assets/Download.png";
import { useStyles } from "./styles";
export const DownloadIconButton = ({ url }: { url?: string }) => {
  const styles = useStyles();
  const { t } = useTranslation();

  return url ? (
    <Tooltip title={t("TOOLTIP.DOWNLOAD")}>
      <a href={url} target="_blank">
        <img src={download} alt="downloadIcon" className={styles.downloadBtn} />
      </a>
    </Tooltip>
  ) : (
    <div>{"-"}</div>
  );
};
