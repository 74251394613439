import { Box, Button, Grid, Tab, Tabs, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

import BreadCrumb from "app/components/BreadCrumb";
import { CustomDrawer } from "app/components/Drawer";
import { NoPermission } from "app/components/NoPermission";
import { TabPanel } from "app/components/TabPanel";
import Topbar from "app/containers/Dashboard/components/topbar";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import {
  selectFeaturePermissions,
  selectIsAdminRole,
  selectUser,
} from "store/global/authPermission/selector";
import { PermissionFeature } from "store/global/authPermission/types";
import { translatedBreadCrumb } from "styles/theme/utils";
import { getFormattedDateTime } from "utils/helpers";
import { useOrganizationSaga } from "../../redux/saga";
import {
  selectEditUser,
  selectEditcommnts,
  selectLoading,
} from "../../redux/selector";
import {
  actions,
  reducer as orgReducer,
  sliceKey as orgSliceKey,
} from "../../redux/slice";
import ContactsList from "./Contacts";
import { useContactSaga } from "./Contacts/redux/saga";
import {
  actions as contactAction,
  reducer,
  sliceKey,
} from "./Contacts/redux/slice";
import DepartmentList from "./Department";
import { useUserSaga } from "./Department/redux/saga";
import {
  actions as departmentAction,
  reducer as departmentReducer,
  sliceKey as departmentSlicekey,
} from "./Department/redux/slice";
import { QuestionnaireList } from "./Questionnaire";
import { useUserSaga as questionnaireSaga } from "./Questionnaire/redux/saga";
import {
  actions as questionnaireAction,
  reducer as questionnaireReducer,
  sliceKey as questionnaireSlicekey,
} from "./Questionnaire/redux/slice";
import { useStyles } from "./style";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

type Props = {
  mode: string;
};

const Details = ({ mode }: Props) => {
  let { id } = useParams();
  const styles = useStyles();
  const navigate = useNavigate();
  const params = useLocation();
  const { t } = useTranslation();

  const [value, setValue] = useState(0);
  const [comment, setComment] = useState("");

  const [comments, setComments] = useState<String[]>(
    mode !== "new"
      ? params?.state?.e?.comments
        ? params?.state?.e?.comments?.split(",")
        : []
      : []
  );
  // const [disable, setDisable] = useState(true);

  //const view: boolean = params.search.split("=")[1] !== "add" && params.search.split("=")[1] !== "edit" && disable;
  const from = useSelector(selectEditUser);
  const isLoading = useSelector(selectLoading);
  const isAdminRole = useSelector(selectIsAdminRole);
  const dispatch = useDispatch();
  useInjectReducer({ key: sliceKey, reducer });
  useInjectSaga({ key: sliceKey, saga: useContactSaga });

  useInjectReducer({ key: departmentSlicekey, reducer: departmentReducer });
  useInjectSaga({ key: departmentSlicekey, saga: useUserSaga });

  useInjectReducer({
    key: questionnaireSlicekey,
    reducer: questionnaireReducer,
  });
  useInjectSaga({ key: questionnaireSlicekey, saga: questionnaireSaga });

  useInjectReducer({ key: orgSliceKey, reducer: orgReducer });
  useInjectSaga({ key: orgSliceKey, saga: useOrganizationSaga });

  useEffect(() => {
    if (id) {
      dispatch(actions.getDataById(id));
      dispatch(contactAction.getList({ id: id }));
      dispatch(questionnaireAction.getList({ id: id }));
      dispatch(departmentAction.getList({ id: id }));
    }
    return () => {
      dispatch(actions.resetNewUserForm());
      dispatch(contactAction.resetModule());
      dispatch(questionnaireAction.resetModule());
      dispatch(departmentAction.resetModule());
    };
  }, [id]);

  const commentss = useSelector(selectEditcommnts);
  const user = useSelector(selectUser);

  const featurePermissions = useSelector(selectFeaturePermissions);
  const { hasFullAccess, hasReadAccess, hasNoAccess } =
    featurePermissions[PermissionFeature.ORGANIZATIONS_DETAILS];

  const saveClickHandler = () => {
    if (comment.length > 0) {
      setComments([comment, ...comments]);
      setComment("");
      const data = {
        content: comment,
        name: user.firstName + " " + user.lastName,
      };
      dispatch(actions.setComment(data));
    }
  };
  const handelChange = (event) => {
    dispatch(
      actions.updateFormValue({
        key: event.target.name,
        value: event.target.value,
      })
    );
  };
  const handleTabChange = (event, value) => {
    setValue(value);
  };
  const handleEditButton = () => {
    navigate(`/organizations/${id}/edit`, {});
  };
  const handleCancelBtn = () => {
    navigate("/organizations");
  };
  const handleSaveBtn = () => {
    dispatch(
      actions.create({
        navigate,
      })
    );
  };
  const handleEdit = () => {
    dispatch(
      actions.update({
        id: id,
        navigate,
      })
    );
  };
  const handleBackBtn = () => {
    navigate("/organizations");
  };
  if (
    (!isAdminRole && mode == "new") ||
    (!hasFullAccess && mode == "edit") ||
    hasNoAccess
  ) {
    return (
      <div>
        <CustomDrawer />
        <Topbar />
        <NoPermission />
      </div>
    );
  }
  return (
    <div className={styles.mainContainer}>
      <CustomDrawer />
      <Grid item xs={12}>
        <Topbar />
        <div className={styles.bodyContainer}>
          <BreadCrumb
            subTitle={t(`ORGANIZATIONS.${translatedBreadCrumb(mode)}`)}
            onBackClick={handleBackBtn}
            title={t("ORGANIZATIONS.ORGANIZATIONS")}
          />
          <Grid sx={{ padding: "20px 0px 0px 0px" }}>
            <Grid container spacing={2} md={12} sx={{ paddingBottom: "10px" }}>
              <Grid item xs={2.5}>
                <label className={styles.label}>
                  {t("ORGANIZATIONS.ORGANIZATIONAL_NAME")}
                </label>
                <TextField
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  disabled={!isAdminRole || mode == "view" || isLoading}
                  required
                  id="name"
                  placeholder={t("ORGANIZATIONS.ENTER_ORGANIZATION_NAME")}
                  name="name"
                  autoComplete={"off"}
                  inputProps={{ maxLength: 50 }}
                  onChange={handelChange}
                  className={styles.textEnabled}
                  value={from.name.value}
                />
              </Grid>
              <Grid item xs={2}>
                <label className={styles.label}>
                  {t("ORGANIZATIONS.TAX_NUMBER")}
                </label>
                <TextField
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  disabled={!isAdminRole || mode == "view" || isLoading}
                  id="taxNumber"
                  placeholder={t("ORGANIZATIONS.ENTER_TAX_NUMBER")}
                  name="taxNumber"
                  onChange={handelChange}
                  autoComplete={"off"}
                  inputProps={{ maxLength: 15 }}
                  className={styles.textEnabled}
                  value={from.taxNumber.value}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2} md={12} sx={{ padding: "5px 0px" }}>
            <Grid item xs={10}>
              <label className={styles.label}>
                {t("ORGANIZATIONS.COMMENT")}
              </label>
              <TextField
                margin="dense"
                variant="outlined"
                fullWidth
                required
                disabled={mode === "view" || isLoading}
                id="comment"
                placeholder={t("ORGANIZATIONS.ENTER_COMMENT")}
                name="comment"
                value={comment}
                autoComplete={"off"}
                onChange={(e) => setComment(e.target.value)}
                className={styles.textEnabled}
              />
            </Grid>
            <Grid
              xs={2}
              item
              style={{
                display: "flex",
                alignItems: "flex-end",
                marginBottom: "5px",
                paddingRight: "5px",
              }}
            >
              <Button
                onClick={saveClickHandler}
                className={styles.commentSaveBtn}
                variant="contained"
                disabled={mode === "view" || isLoading}
              >
                {t("BUTTONS.SAVE")}
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            md={12}
            sx={{ padding: "15px 5px 15px 15px" }}
          >
            <TextField
              id="outlined-multiline-static"
              multiline
              disabled={true}
              rows={4}
              // placeholder="This organization is..."
              fullWidth
              value={commentss
                .map(
                  (x) =>
                    `${getFormattedDateTime(x.time)} [${x.name}] ${x.content}`
                )
                .join("\n")}
              className={styles.textEnabled}
            />
          </Grid>
          <Grid
            container
            spacing={2}
            md={12}
            sx={{ padding: "10px 0px 0px 15px" }}
          >
            <Box sx={{ width: "100%" }}>
              <Tabs
                value={value}
                onChange={handleTabChange}
                aria-label="tabs"
                textColor="secondary"
                indicatorColor="secondary"
                TabIndicatorProps={{ style: { background: "#012497" } }}
              >
                {/* <Tab label={<span>Treatment Banks</span>} /> */}
                <Tab
                  label={<span>{t("ORGANIZATIONS.CONTACTS")}</span>}
                  style={{
                    fontWeight: value == 0 ? "bold" : "500",
                    color: value == 0 ? "#012497" : "#2A3333",
                    fontSize: "16px",
                  }}
                />
                <Tab
                  label={<span>{t("ORGANIZATIONS.DEPARTMENTS")}</span>}
                  style={{
                    fontWeight: value == 1 ? "bold" : "500",
                    color: value == 1 ? "#012497" : "#2A3333",
                    fontSize: "16px",
                  }}
                />
                <Tab
                  label={<span>{t("ORGANIZATIONS.QUESTIONNAIRES")}</span>}
                  style={{
                    fontWeight: value === 2 ? "bold" : "500",
                    color: value === 2 ? "#012497" : "#2A3333",
                    fontSize: "16px",
                  }}
                />
              </Tabs>
              <TabPanel value={value} index={0}>
                <ContactsList
                  disable={mode == "view" || isLoading}
                  view={mode == "view" || isLoading}
                  mode={mode}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <DepartmentList
                  disable={mode == "view" || isLoading}
                  view={mode == "view"}
                  mode={mode}
                />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <QuestionnaireList
                  disable={mode == "view" || isLoading}
                  view={mode == "view"}
                  mode={mode}
                />
              </TabPanel>
            </Box>
          </Grid>
          <Grid
            item
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          ></Grid>
          <Grid
            container
            columnGap={3}
            style={{ display: "flex", justifyContent: "flex-end" }}
            sx={{ padding: "0px 0px 20px 0px" }}
            md={12}
          >
            {params.search.split("=")[1] !== "add" &&
            params.search.split("=")[1] !== "edit" &&
            mode == "view" ? (
              isAdminRole && (
                <Grid>
                  <Button
                    disabled={isLoading}
                    className={styles.saveBtn}
                    variant="contained"
                    onClick={handleEditButton}
                  >
                    {t("BUTTONS.EDIT")}
                  </Button>
                </Grid>
              )
            ) : (
              <Grid
                container
                columnGap={3}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingRight: "0px",
                }}
              >
                <Grid>
                  <Button
                    className={styles.cancelBtn}
                    variant="outlined"
                    onClick={handleCancelBtn}
                    disabled={isLoading}
                  >
                    {t("BUTTONS.CANCEL")}
                  </Button>
                </Grid>
                <Grid>
                  <Button
                    onClick={mode === "new" ? handleSaveBtn : handleEdit}
                    className={styles.saveBtn}
                    variant="contained"
                    disabled={isLoading}
                  >
                    {t("BUTTONS.SAVE")}
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </div>
      </Grid>
    </div>
  );
};

export default Details;
