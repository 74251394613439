import { Grid } from "@mui/material";
import { CustomDrawer } from "app/components/Drawer";
import { NoPermission } from "app/components/NoPermission";
import React from "react";
import { useSelector } from "react-redux";
import { useInjectReducer } from "redux-injectors";
import { selectFeaturePermissions } from "store/global/authPermission/selector";
import { PermissionFeature } from "store/global/authPermission/types";
import { useInjectSaga } from "utils/redux-injectors";
import useQueryRefNav from "utils/user-ref-nav-query";
import Filters from "./components/Filters";
import GradeAndProgressTab from "./components/Tab/GradeAndProgressTab";
import Topbar from "./components/topbar";
import { useDashboardSaga } from "./redux/saga";
import { reducer, sliceKey } from "./redux/slice";
import { useStyles } from "./styles";

const Dashboard = () => {
  const classes = useStyles();
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: useDashboardSaga });
  const isResetRequired = useQueryRefNav();
  const featurePermissions = useSelector(selectFeaturePermissions);
  const { hasNoAccess } = featurePermissions[PermissionFeature.DASHBOARD];
  if (hasNoAccess) {
    return (
      <div className={classes.container}>
        <CustomDrawer />
        <Topbar />
        <NoPermission />
      </div>
    );
  }
  return (
    <React.Fragment>
      <div className={classes.container}>
        <CustomDrawer />
        <Topbar />
        <div className={classes.root}>
          <Grid>
            <Filters isResetRequired={isResetRequired} />
            <GradeAndProgressTab />
          </Grid>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
