import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "types";
import { UserGroupState } from "../types";

const selectDomain = (state?: RootState) =>
  state?.usersGroupState || UserGroupState;

export const selectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);

export const selectList = createSelector([selectDomain], (state) => state.list);
export const selectQuery = createSelector(
  [selectDomain],
  (state) => state.query
);
export const selectError = createSelector(
  [selectDomain],
  (state) => state.error
);

export const selectForm = createSelector([selectDomain], (state) => state.form);
