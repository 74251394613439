
import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles(() => ({
  dotBox: {
    display: "flex",
    alignItems: "flex-end",
    width: '100%',
    cursor: 'pointer',
  },
  hiddenInput: {
    position: 'absolute',
    top: '-9999px',
    left: '-9999px',
  },
  uploadBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "5px",
    borderStyle: "dashed",
    borderWidth: "1px",
    border: "1px solid #6C7A7A",
    height: "56px",
    width: "100%",
    background: "#FFF",
    marginBottom:"5px"
  },
  uploadBoxErr: {
    borderColor: "red",
  },
  uploadLabel: {
    fontFamily: "Cairo",
    fontWeight: "400px",
    marginTop: "13px",
    fontSize: "14px",
    marginLeft: "16px",
    color: "#2A3333",
    textDecorationLine: 'underline',
  },
}));
