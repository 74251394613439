import React, { ChangeEvent, useEffect, useRef, useState } from "react";

import { Close } from "@mui/icons-material";
import {
  FormHelperText,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import uploadBtn from "app/containers/Patients/assets/Upload.png";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { bytesToMegabytes } from "utils/helpers";
import { actions } from "../../redux/slice";
import { UploadedResource } from "../../types";
import { uploadFileToS3 } from "./actions";
import { useStyles } from "./styles";

const translation = {
  video: "DAILY_ACTIVITY.UPLOAD_VIDEO",
  audio: "DAILY_ACTIVITY.UPLOAD_AUDIO",
  pdf: "DAILY_ACTIVITY.UPLOAD_PDF",
};

const icons = {
  video: require("../../assets/video.png"),
  audio: require("../../assets/audio.png"),
  pdf: require("../../assets/pdf.png"),
};

type Props = {
  supportedFile: string;
  resource: UploadedResource;
  onUpload({
    resourcePath,
    resourceName,
    resourceSize,
    resourceType,
  }: UploadedResource): void;
};
export const UploadField = ({ supportedFile, onUpload, resource }: Props) => {
  const [progress, setProgress] = useState(0);
  const type = resource.resourceType;
  const styles = useStyles();
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const [isLoading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const docRef = useRef<HTMLInputElement | null>(null);
  const dispatch = useDispatch();
  function docClick() {
    if (docRef.current) docRef.current.click();
  }

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e?.target?.files) {
      return setSelectedFile(undefined);
    }
    const file = e?.target?.files[0];
    if (!file) {
      setSelectedFile(undefined);
      return;
    }
    if (!file?.type.includes(supportedFile)) {
      setSelectedFile(undefined);
      return;
    }

    setSelectedFile(file);
    setError("");
  };

  function onCancel(resource: UploadedResource) {
    dispatch(actions.removeFormUploadValue({ resource }));
  }
  const uploadFile = async (file: File) => {
    setLoading(true);
    const res = await uploadFileToS3(file, (value) => {
      setProgress(value);
    });
    if (res.file) {
      onUpload({
        resourcePath: res.file,
        resourceSize: file.size,
        resourceName: file.name,
        resourceType: type,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (selectedFile) {
      uploadFile(selectedFile);
    }
  }, [selectedFile]);

  return (
    <>
      <div className={styles.dotBox}>
        <Grid className={clsx(styles.uploadBox, error && styles.uploadBoxErr)}>
          <input
            type="file"
            accept={supportedFile}
            ref={docRef}
            className={styles.hiddenInput}
            onChange={handleFileChange}
          />

          {resource.resourcePath ? (
            <Grid className={styles.labelWrap}>
              <img src={icons[type]} className={styles.iconImage} />
              <Typography noWrap className={styles.uploadLabel}>
                {resource.resourceName}{" "}
              </Typography>
              <Typography noWrap className={styles.sizeLabel}>
                {bytesToMegabytes(resource.resourceSize || 0)}{" "}
              </Typography>
              <Close
                style={{
                  float: "right",
                  width: "12px",
                  marginTop: "-25px",
                  color: "grey",
                }}
                onClick={(e) => {
                  setLoading(false);
                  setProgress(0);
                  onCancel({
                    resourcePath: resource.resourcePath,
                    resourceSize: undefined,
                    resourceName: undefined,
                    resourceType: type,
                  });
                  setSelectedFile(undefined);
                  // fileClose();
                  // setState({});
                }}
              ></Close>
            </Grid>
          ) : (
            <Grid onClick={() => docClick()} className={styles.labelWrap}>
              <img width="30px" src={uploadBtn} alt="download" />
              <Typography noWrap className={styles.uploadLabel}>
                {t(translation[type])}
              </Typography>
            </Grid>
          )}
        </Grid>
        {isLoading ? (
          <LinearProgress
            className={styles.progress}
            variant="determinate"
            value={progress}
          />
        ) : null}
      </div>
      {error ? <FormHelperText error> {error}</FormHelperText> : null}
    </>
  );
};
