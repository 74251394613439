import { TextField } from "app/components/TextField";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { Lock, LockReset } from "@mui/icons-material";
import { InputAdornment } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";

import { PrimaryButton } from "app/components/Button/PrimaryButton";
import { useTranslation } from "react-i18next";
import { TRANSLATE } from "utils/helpers";
import ThankYou from "../Thankyou";
import { selectLoading, selectResetPasswordForm } from "../selectors";
import { actions } from "../slice";

type Props = {};

const useStyles = makeStyles(() => ({
  heading: {
    fontSize: 16,
    fontWeight: 500,
    textAlign: "center",
    color: "#000",
    fontFamily: "Cairo",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  button: {
    width: "100%",
    height: 40,
    fontSize: 22,
    marginTop: 11,
  },
  icon: {
    width: 48,
    height: 48,
    color: "#B2B2B2",
    transform: "rotateY(180deg)",
    margin: "16px 0px 14px 0px",
  },
  input: {
    marginTop: 24,
  },
}));

const ResetPassword = (props: Props) => {
  const styles = useStyles();
  const [isUpdated, setIsUpdated] = useState(false);
  const { password, confirm_password, error } = useSelector(
    selectResetPasswordForm
  );
  const isLoading = useSelector(selectLoading);
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onSuccess = () => {
    setIsUpdated(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password !== confirm_password) {
      dispatch(
        actions.updateResetForm({
          name: "error",
          value: TRANSLATE("AUTH.PASSWORDS_DO_NOT_MATCH"),
        })
      );
      return;
    }
    dispatch(
      actions.resetPassword({
        token: searchParams.get("hash"),
        callback: onSuccess,
      })
    );
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    dispatch(actions.updateResetForm({ name, value }));
    dispatch(actions.updateResetForm({ name: "error", value: "" }));
  };

  const resetPassword = (
    <form className={styles.form} onSubmit={handleSubmit}>
      <Box mb={2} className={styles.heading}>
        {t("AUTH.RESET_PASSWORD")}
      </Box>
      <LockReset className={styles.icon} />
      <div style={{ marginBottom: "20px" }}>
        <TextField
          height={51}
          variant="outlined"
          margin="normal"
          required
          type="password"
          fullWidth
          id="password"
          placeholder={t("AUTH.NEW_PASSWORD")}
          name="password"
          autoComplete="password"
          value={password}
          autoFocus
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Lock />
              </InputAdornment>
            ),
          }}
          onChange={onChange}
        />
        <TextField
          height={51}
          variant="outlined"
          margin="normal"
          type="password"
          required
          fullWidth
          id="confirmPassword"
          placeholder={t("AUTH.CONFIRM_PASSWORD")}
          name="confirm_password"
          autoComplete="confirmPassword"
          className={styles.input}
          error={error === "PASSWORDS_DO_NOT_MATCH" || !!error}
          helperText={error}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Lock />
              </InputAdornment>
            ),
          }}
          value={confirm_password}
          onChange={onChange}
        />
      </div>
      <PrimaryButton isLoading={isLoading} label={t("AUTH.UPDATE_PASSWORD")} />
    </form>
  );

  return !isUpdated ? resetPassword : <ThankYou />;
};

export default ResetPassword;
