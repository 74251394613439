import { StyledTableCell } from "app/components/DataTable/components/StyledTableCell";
import { StyledTableRow } from "app/components/DataTable/components/StyledTableRow";
import React from "react";
import { getLogTime } from "utils/helpers";

import { makeStyles } from "@mui/styles";

type Props = {
  logs: Array<any>;
};
const useStyles = makeStyles({
  tableRow: {
    border: "1px solid #00000010 !important",
  },
  tableCell: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  tableCellWithLast: {
    paddingTop: 0,
  },
  tableCellWithLastBold: {
    paddingTop: 0,
    fontWeight: "700",
  },
});

export const List = ({ logs }: Props) => {
  const styles = useStyles();
  return (
    <>
      {logs &&
        logs.length > 0 &&
        logs.map((log, index) => {
          return (
            <React.Fragment key={`message-${index}`}>
              {log.data.map((l) => {
                return (
                  <StyledTableRow className={styles.tableRow}>
                    <StyledTableCell>
                      {log.id}:{getLogTime(l.time)}
                    </StyledTableCell>
                    <StyledTableCell className={styles.tableCell}>
                      {l.message}
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </React.Fragment>
          );
        })}
    </>
  );
};

export const Heading = () => {
  const styles = useStyles();
  return (
    <React.Fragment key={`message-head`}>
      <StyledTableRow className={styles.tableRow}>
        <StyledTableCell>ID</StyledTableCell>
        <StyledTableCell className={styles.tableCell}>Message</StyledTableCell>
      </StyledTableRow>
    </React.Fragment>
  );
};
