import { createTheme } from "@mui/material";

export const MySwitch = createTheme({
  components: {
    // Name of the component
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 32,
          height: 14,
          padding: 0,
          marginLeft: 12,
          "&.Mui-checked": {
            color: "#012497",
            "& .Mui-checked": {
              color: "#012497",
              opacity: 1,
            },
          },
        },
        switchBase: {
          padding: 3,
          color: "#012497",
          "&.Mui-checked": {
            color: "#012497",
          },
          "&.MuiSwitch-colorSecondary.Mui-disabled + .MuiSwitch-track": {
            backgroundColor: "#FFF",
          },
        },
        thumb: {
          width: 7,
          height: 7,
        },
        track: {
          borderRadius: 30,
          border: `2px solid #012497`,
          backgroundColor: "#FFF",
          opacity: 1,
          transition: "#012497",
          ".Mui-checked.Mui-checked + &": {
            backgroundColor: "#fff",
            border: `2px solid #012497`,
            opacity: `1`,
          },
        },
      },
    },
  },
});
