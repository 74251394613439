import { LoginResponse } from "types/LoginResponse";
import { createSlice } from "utils/@reduxjs/toolkit";

import { createAction, PayloadAction } from "@reduxjs/toolkit";
import set from "lodash/set";
import { FormError } from "../types";
import { ContainerState } from "./types";

export const logoutSuccess = createAction("LOGOUT_SUCCESS");

export const initialState: ContainerState = {
  email: "",
  password: "",
  loginForm: {
    email: {
      value: "",
    },
    password: {
      value: "",
    },
  },
  loading: false,
  error: null,
  token: localStorage.getItem("sessionToken") || "",
  forgotPasswordForm: {
    email: "",
    error: "",
  },
  resetForm: {
    password: "",
    confirm_password: "",
    error: "",
  },
};

const formSlice = createSlice({
  name: "authState",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(logoutSuccess, (state) => {
      state.token = "";
    });
  },
  reducers: {
    setForm: (state, action: PayloadAction<{ key: string; value: string }>) => {
      const { key, value } = action.payload;
      set(state, `${key}.value`, value);
      set(state, `${key}.error`, "");
    },
    setFormErrors(
      state,
      action: PayloadAction<{
        key: string;
        errors: Array<FormError>;
      }>
    ) {
      action.payload.errors.forEach((error) => {
        set(state.loginForm, `${error.name}.error`, error.error);
      });
    },
    changeEmail(state, action: PayloadAction<string>) {
      state.email = action.payload;
      state.error = null;
    },
    changePassword(state, action: PayloadAction<string>) {
      state.password = action.payload;
      state.error = null;
    },
    logout(state) {
      state.token = "";
    },
    login(state, action: PayloadAction<{ callback: () => void }>) {
      state.loading = true;
      state.error = null;
    },
    forgotPassword(state, action: PayloadAction<{ callback: () => void }>) {
      state.loading = true;
    },
    loginSuccess(state, action: PayloadAction<LoginResponse>) {
      state.email = "";
      state.password = "";
      state.loading = false;
      state.error = null;
      state.token = action.payload.token;
    },
    loginError(state, action: PayloadAction<any>) {
      state.error = action.payload;
      state.loading = false;
    },
    hideLoading(state) {
      state.loading = false;
    },
    setError(state, action) {
      state.error = action.payload;
      state.loading = false;
    },
    setForgotError(state, action: PayloadAction<string>) {
      state.loading = false;
      state.forgotPasswordForm.error = action.payload;
    },
    setForgotEmail(state, action: PayloadAction<string>) {
      state.forgotPasswordForm.email = action.payload;
    },
    forgotPasswordSuccess(state) {
      state.loading = false;
    },
    resetError: (state) => {
      state.forgotPasswordForm.error = "";
    },
    resetEmail: (state) => {
      state.forgotPasswordForm.email = "";
    },
    updateResetForm: (
      state,
      action: PayloadAction<{ name: string; value: any }>
    ) => {
      state.resetForm[action.payload.name] = action.payload.value;
    },
    resetPassword: (
      state,
      action: PayloadAction<{ token: any; callback: () => void }>
    ) => {
      state.loading = true;
      state.token = "";
    },
    createPassword: (
      state,
      action: PayloadAction<{ token: any; callback: () => void }>
    ) => {
      state.loading = true;
    },
    resetPasswordSuccess: (state) => {
      state.loading = false;
      state.resetForm.error = "";
      state.resetForm.password = "";
      state.resetForm.confirm_password = "";
    },
    resetPasswordError: (state) => {
      state.loading = false;
    },
  },
});

export const { actions, reducer, name: sliceKey } = formSlice;
