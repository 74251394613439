import queryString from "query-string";
import { put, select, takeLatest } from "redux-saga/effects";
import { API_URL } from "utils/constants";
import {
  TRANSLATE,
  getDefaultHeaders,
  removeBlankProperties,
} from "utils/helpers";
import { request } from "utils/request";
import toastService from "utils/toast";
import { ContactListItemType } from "../types";
import { selectQuery } from "./selector";
import { actions } from "./slice";

export function* handleBlock(action) {
  try {
    const options = {
      method: "PATCH",
      headers: getDefaultHeaders(),
    };
    const response: ContactListItemType = yield request(
      `${API_URL}/users/${action?.payload}`,
      options
    );
    yield put(actions.handleBlockSuccess(response));
    toastService.success(TRANSLATE("USERS.DATA_UPDATED_SUCCESSFULLY"));
  } catch (e: any) {
    let message = e?.message;
    try {
      message = JSON.parse(e?.message)?.message;
    } catch (error) {
      console.log(error);
    }
    yield put(actions.updateFailed(message));
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", { error: message })
    );
  }
}

export function* getList(action) {
  const query = yield select(selectQuery);
  const requestData = removeBlankProperties(query);
  const queries = queryString.stringify({
    ...requestData,
  });
  try {
    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    const response = yield request(
      `${API_URL}/v1/organization/${action.payload.id}/contact?${queries}`,
      options
    );
    yield put(actions.getListSuccess(response));
  } catch (e: any) {
    yield put(actions.getListComplete());
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", {
        error: JSON.stringify(e),
      })
    );
  }
}

export function* deleteRow() {}
export function* useContactSaga() {
  yield takeLatest(actions.getList.type, getList);
  yield takeLatest(actions.getNextPage.type, getList);
  yield takeLatest(actions.handleBlock.type, handleBlock);
}
