import { Add } from "@mui/icons-material";
import { Fab, Grid } from "@mui/material";
import DataTable from "app/components/DataTable";
import { useUsersListSaga } from "app/containers/Users/redux/saga";
import {
  reducer as userReducer,
  sliceKey as userSliceKey,
} from "app/containers/Users/redux/slice";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectFeaturePermissions } from "store/global/authPermission/selector";
import { PermissionFeature } from "store/global/authPermission/types";
import { MAX_LIMIT } from "utils/helpers";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import DepartmentModal from "../Department/components/DepartmentModal";
import { useUserSaga } from "../Department/redux/saga";
import {
  selectList,
  selectLoading,
  selectQuery,
} from "../Department/redux/selector";
import { actions, reducer, sliceKey } from "../Department/redux/slice";
import { useStyles } from "../Department/styles";

interface DepartmentListProps {
  view: boolean;
  disable: boolean;
  mode: String;
}

const DepartmentList = ({ disable }: DepartmentListProps) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [currentDepartmentName, setCurrentDepartmentName] = useState("");
  const listitems = useSelector(selectList);
  const { meta, items } = listitems;
  const {
    q,
    orderField,
    orderDirection,
    distributorId,
    user_type,
    createMax,
    createMin,
    updateMax,
    updateMin,
  } = useSelector(selectQuery);
  const { t } = useTranslation();
  //const searchField = useSelector(selectSearch);
  const styles = useStyles();
  useInjectReducer({ key: sliceKey, reducer });
  useInjectSaga({ key: sliceKey, saga: useUserSaga });
  useInjectReducer({ key: userSliceKey, reducer: userReducer });
  useInjectSaga({ key: userSliceKey, saga: useUsersListSaga });
  const columns = [
    {
      id: "departmentName",
      label: t("ORGANIZATIONS.DEPARTMENT_NAME"),
      sortValue: "selector.departmentName",
    },
    {
      id: "noOfBanks",
      label: t("ORGANIZATIONS.NUMBER_OF_BANKS"),
      sortValue: "selector.noOfBanks",
    },
    {
      id: "cityName",
      label: t("ORGANIZATIONS.CITY"),
      sortValue: "selector.noOfBanks",
    },
    {
      id: "address",
      label: t("ORGANIZATIONS.ADDRESS"),
      sortValue: "selector.noOfBanks",
    },
  ];
  const dispatch = useDispatch();
  useEffect(() => {}, [
    q,
    orderDirection,
    orderField,
    distributorId,
    user_type,
    createMax,
    createMin,
    updateMax,
    updateMin,
    dispatch,
  ]);
  const featurePermissions = useSelector(selectFeaturePermissions);
  const { hasFullAccess } =
    featurePermissions[PermissionFeature.DAILY_ACTIVITY];
  const setPageQuery = (name: string, value: any) => {
    dispatch(actions.setQuery({ name, value }));
  };
  const loading = useSelector(selectLoading);

  const handlEditModalClose = () => {
    setOpenEditModal(false);
    dispatch(actions.resetNewDepartmentForm());
  };
  const handlEditModalSave = () => {
    setOpenEditModal(false);
    dispatch(actions.editUser());
    dispatch(actions.resetNewDepartmentForm());
  };
  const handlEditModalOpen = (e) => {
    for (let i in e) {
      dispatch(actions.updateFormValue({ key: i, value: e[i] }));
    }
    setOpenEditModal(true);
  };
  const handlAddModalClose = () => {
    setOpenAddModal(false);
    dispatch(actions.resetNewDepartmentForm());
  };
  const handlAddModalSave = () => {
    setOpenAddModal(false);
    dispatch(actions.create());
    dispatch(actions.resetNewDepartmentForm());
  };
  const handlAddModalOpen = () => {
    setOpenAddModal(true);
  };
  const handleDeleteDepartment = (row, idx) => {
    dispatch(actions.deleteDepratment({ ...row, idx }));
  };
  return (
    <Grid xs={12}>
      <DataTable
        loading={loading}
        orderDirection={orderDirection}
        orderField={orderField}
        setQuery={setPageQuery}
        editAction={handlEditModalOpen}
        rows={listitems["items"].filter((item) => item.action !== "delete")}
        columns={columns}
        page="contactList"
        Actions={hasFullAccess ? "Edit/Delete" : ""}
        scrollClass={styles.dataTableDiv}
        disableActions={disable ? "Edit/Delete" : ""}
        deleteAction={handleDeleteDepartment}
      />

      <Grid
        item
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Fab
          size="medium"
          color="secondary"
          aria-label="add"
          sx={{ marginTop: 2, marginBottom: 2 }}
          onClick={handlAddModalOpen}
          disabled={disable || listitems.items.length >= MAX_LIMIT}
        >
          <Add />
        </Fab>
      </Grid>

      <DepartmentModal
        open={openEditModal}
        handleClose={handlEditModalClose}
        handleSave={handlEditModalSave}
        action={"Edit"}
      />
      <DepartmentModal
        open={openAddModal}
        handleClose={handlAddModalClose}
        handleSave={handlAddModalSave}
        action={"Add"}
      />
    </Grid>
  );
};
export default DepartmentList;
