import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles(() => ({
  chipBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "5px",
    border: "1px solid #C6C9CA",
    height: "40px",
    padding: "10px",
    background: "#FFF",
  },
  chipLabel: {
    fontFamily: "Cairo",
    fontWeight: "600px",
    fontSize: "16px",
    color: "#012497",
    marginTop: "15px",
  },
  numLabel: {
    fontFamily: "Cairo",
    fontWeight: "400px",
    fontSize: "14px",
    marginLeft: "20px",
    color: "#6C7A7A",
    marginTop: "16px",
  },
}));
