import i18next from "i18next";
import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { API_URL } from "utils/constants";
import { TRANSLATE } from "utils/helpers";
import { request } from "utils/request";
import toastService from "utils/toast";
import {
  selectForgotEmail,
  selectLoginForm,
  selectResetPasswordForm,
} from "./selectors";
import { actions, logoutSuccess } from "./slice";
import { LoginErrorType } from "./types";

export function* resetPassword(action) {
  const token = action.payload?.token;
  yield delay(500);
  const { password } = yield select(selectResetPasswordForm);
  const options = {
    method: "POST",
    body: JSON.stringify({ token, password }),
  };
  try {
    const response = yield call(
      request,
      `${API_URL}/v1/auth/reset/password`,
      options
    );

    if (response && response.message !== "MESSAGES.AUTH.PASSWORD_UPDATED") {
      yield put(
        actions.updateResetForm({ name: "error", value: response.message })
      );
      return;
    }
    yield put(actions.resetPasswordSuccess());
    yield call(action?.payload?.callback);
  } catch (e) {
    yield put(actions.resetPasswordError());
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", { error: JSON.stringify(e) })
    );
  }
}

export function* createPassword(action) {
  const token = action.payload?.token;
  yield delay(500);
  const { password } = yield select(selectResetPasswordForm);
  const options = {
    method: "POST",
    body: JSON.stringify({ token, password }),
  };
  try {
    const response = yield call(
      request,
      `${API_URL}/v1/auth/create/password`,
      options
    );

    if (response && response.message !== "MESSAGES.AUTH.PASSWORD_Created") {
      yield put(
        actions.updateResetForm({ name: "error", value: response.message })
      );
      return;
    }
    yield put(actions.resetPasswordSuccess());
    yield call(action?.payload?.callback);
  } catch (e) {
    yield put(actions.resetPasswordError());
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", { error: JSON.stringify(e) })
    );
  }
}

export function* forgotPassword(action) {
  yield delay(500);
  const email = yield select(selectForgotEmail);
  const options = {
    method: "POST",
    body: JSON.stringify({ email }),
  };
  try {
    const response = yield call(
      request,
      `${API_URL}/v1/auth/forgot/password`,
      options
    );

    if (
      response &&
      response.message !== "MESSAGES.AUTH.FORGOT_PASSWORD_MAIL_SENT"
    ) {
      yield put(actions.setForgotError(response.message));
      return;
    }
    yield put(actions.forgotPasswordSuccess());
    yield call(action?.payload?.callback);
  } catch (e) {}
}

export function* logoutRequest() {
  yield delay(500);

  const options = {
    method: "POST",
  };

  try {
    const response = yield request(`${API_URL}/auth/logout`, options);
    if (response) {
      yield localStorage.setItem("sessionToken", "");
    }
  } catch (err: any) {}

  yield put(logoutSuccess());
}

export function* loginRequest(action) {
  const form = yield select(selectLoginForm);
  if (form.email.value.length === 0) {
    yield put(actions.loginError(LoginErrorType.EMAIL_EMPTY));
    return;
  }
  if (form.password.value.length === 0) {
    yield put(actions.loginError(LoginErrorType.PASSWORD_EMPTY));
    return;
  }
  const options = {
    method: "POST",
    body: JSON.stringify({
      email: form.email.value,
      password: form.password.value,
    }),
  };

  try {
    const response = yield request(
      `${API_URL}/v1/auth/admin/email/login`,
      options
    );
    if (response && response.message === "LOGIN_FAILED") {
      yield put(actions.loginError("Incorrect email and password"));
      return false;
    }
    yield localStorage.setItem("sessionToken", response.token);
    yield localStorage.setItem("role", response.user.role.name);
    yield put(actions.loginSuccess(response));
    yield call(action.payload?.callback);
  } catch (err: any) {
    yield put(actions.loginError(i18next.t("ERRORS.LOGIN_FAILED")));
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* userRepoSaga() {
  // Watches for LOAD_REPOS actions and calls loginResponse when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(actions.login.type, loginRequest);
  yield takeLatest(actions.logout.type, logoutRequest);
  yield takeLatest(actions.forgotPassword.type, forgotPassword);
  yield takeLatest(actions.resetPassword.type, resetPassword);
  yield takeLatest(actions.createPassword.type, createPassword);
}
