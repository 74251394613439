import { FormElement } from "app/containers/types";

export interface ResponseError {
  message: string;
}
export type RequestError = ResponseError;

export type AddTreatmentItemType = {
  id?: number | null;
  treatmentId: number | null;
  at: string | null;
  duration: string | null;
  questionnaireId: string | null;
  name: string | null;
  session_id: string | null;
  type: string;
};
export type QuestionnaireListItemType = {
  id: number;
  name: string;
};

export type AddTreatmentContentType = {
  id?: number;
  beforeAfter: string | null;
  videoFile: string | null;
  audioFile: string | null;
  pdfFile: string | null;
  hours: string | null;
  description: string | null;
  session: string | null;
  type: string;
};

export type ResourceContentDetails = {
  id?: number;
  name?: string;
  size?: number;
  path?: string;
  type?: string;
  error?: string;
};

export type ContentForm = {
  id?: FormElement;
  name: FormElement;
  beforeAfter: FormElement;
  videoFile: ResourceContentDetails;
  audioFile: ResourceContentDetails;
  pdfFile: ResourceContentDetails;
  hours: FormElement;
  description: FormElement;
  sessionNumber: FormElement;
  session_id: FormElement;
  disable: Boolean;
};

export type TreatmentForm = {
  id?: FormElement;
  participantType: FormElement;
  noOfSession: FormElement;
  sessionLength: FormElement;
  treatmentName: FormElement;
  treatmentDescription: FormElement;
  participants: FormElement;
  treatmentBanksCount: FormElement;
  timeSlotsCount: FormElement;
};
export type QuestionnaireForm = {
  id?: FormElement;
  name: FormElement;
  beforeAfter: FormElement;
  hours: FormElement;
  questionnaireId: FormElement;
  questionaireName: FormElement;
  session_id: FormElement;
  disable: Boolean;
};

export type addTreatmentStateType = {
  query: {
    limit: number;
    page: number;
    distributorId: string;
    user_type: string;
    q: string;
    orderField: string;
    orderDirection: string;
    createMin: string;
    createMax: string;
    updateMin: string;
    updateMax: string;
  };
  form: {
    treatment: TreatmentForm;
    content: ContentForm;
    questionaire: QuestionnaireForm;
  };
  list: {
    items: Array<AddTreatmentItemType>;
    treatment: Array<AddTreatmentContentType>;
    meta: {
      totalItems: number;
      itemCount: number;
      itemsPerPage: number;
      totalPages: number;
      currentPage: number;
    };
    links: {
      first: string;
      last: string;
      previous: string;
      next: string;
    };
  };
  questionnaireModal: boolean;
  contentModal: boolean;
  questionnaireList: {
    items: Array<QuestionnaireListItemType>;
  };
  loading: boolean;
  error: string;
};

export const UserState: addTreatmentStateType = {
  query: {
    user_type: "",
    distributorId: "",
    limit: 10,
    page: 1,
    q: "",
    orderField: "",
    orderDirection: "",
    updateMax: "",
    updateMin: "",
    createMax: "",
    createMin: "",
  },
  list: {
    items: [],
    treatment: [],
    meta: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 100,
      totalPages: 1,
      currentPage: 1,
    },
    links: {
      first: "",
      previous: "",
      next: "",
      last: "",
    },
  },
  questionnaireModal: false,
  contentModal: false,
  questionnaireList: {
    items: [
      {
        id: 1,
        name: "Questionnaire 1",
      },
      {
        id: 2,
        name: "Questionnaire 2",
      },
    ],
  },
  loading: false,
  form: {
    treatment: {
      noOfSession: { value: "1" },
      participants: { value: "1" },
      participantType: { value: "" },
      sessionLength: { value: "" },
      treatmentDescription: { value: "" },
      treatmentName: { value: "" },
      id: { value: "" },
      treatmentBanksCount: { value: 0 },
      timeSlotsCount: { value: 0 },
    },
    content: {
      name: { value: "" },
      beforeAfter: { value: "" },
      hours: { value: "" },
      videoFile: {},
      audioFile: {},
      pdfFile: {},
      description: { value: "" },
      sessionNumber: { value: "" },
      session_id: { value: "" },
      disable: true,
    },
    questionaire: {
      name: { value: "" },
      beforeAfter: { value: "" },
      hours: { value: "" },
      questionnaireId: { value: "" },
      questionaireName: { value: "" },
      session_id: { value: "" },
      disable: true,
    },
  },
  error: "",
};

export type ContainerState = addTreatmentStateType;
