import { Grid, Tabs } from "@mui/material";
import Tab from "@mui/material/Tab";
import { TabPanel } from "app/components/TabPanel";
import * as React from "react";
import { useTranslation } from "react-i18next";
import GradeTabPanel from "../components/GradeTabPanel";
import ProgressTabPanel from "../components/ProgressTabPanel";
import { useStyles } from "./styles";

export default function GradeAndProgressTab() {
  const [value, setSelectedTab] = React.useState(0);
  const classes = useStyles();

  const { t } = useTranslation();

  const handleChange = (e, value: number) => {
    setSelectedTab(value);
  };

  return (
    <Grid
      sx={{
        width: "100%",
      }}
    >
      <Tabs
        value={value}
        TabIndicatorProps={{ style: { background: "#012397" } }}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable"
      >
        <Tab
          label={t("DASHBOARD.GRADES")}
          value={0}
          className={classes.tabLabel}
          style={{
            fontWeight: value === 0 ? "bold" : "500",
            color: value === 0 ? "#012497" : "#2A3333",
            fontSize: "16px",
          }}
        />
        <Tab
          label={t("DASHBOARD.PROGRESS")}
          value={1}
          className={classes.tabLabel}
          style={{
            fontWeight: value === 1 ? "bold" : "500",
            color: value === 1 ? "#012497" : "#2A3333",
            fontSize: "16px",
          }}
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        <GradeTabPanel />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ProgressTabPanel />
      </TabPanel>
    </Grid>
  );
}
