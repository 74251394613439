import { Autocomplete, Box, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectCity } from "store/global/fields/selector";
import { actions } from "store/global/fields/slice";
import { useStyles } from "../styles";
interface TProps {
  value: number;
  xs?: number;
  changeValue: (value: number, name?: string) => void;
  disabled?: boolean;
  error?: string;
}
const CityField = (props: TProps) => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const [value, setValue] = useState<any>({ id: 0, name: "" });

  const List = useSelector(selectCity);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    dispatch(actions.getCityList());
    return () => {};
  }, []);

  const handleChange = (event, newValue: any) => {
    if (newValue) {
      props.changeValue(newValue.id, newValue.name);
    } else {
      props.changeValue(0, "");
    }
  };
  useEffect(() => {
    setValue(List.items.filter((x) => x.id == props.value)[0]);

    return () => {};
  }, [props.value, List]);

  return (
    <Grid item xs={props.xs ? props.xs : 2}>
      <label className={styles.label}>{t("ORGANIZATIONS.CITY")}</label>
      <Autocomplete
        disabled={props.disabled}
        fullWidth
        id="cities"
        options={List.items}
        autoHighlight
        className={styles.textEnabled}
        onChange={(event: any, newValue: any) => handleChange(event, newValue)}
        value={value}
        isOptionEqualToValue={(option, value) => option?.name === value?.name}
        getOptionLabel={(option) => option.name}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            {...props}
          >
            {option.name}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label=""
            error={Boolean(props?.error)}
            inputProps={{
              ...params.inputProps,
            }}
            autoComplete="off"
          />
        )}
      />
      {props?.error && (
        <Box
          sx={{
            backgroundColor: "#fff",
            direction: i18n.dir(),
          }}
        >
          <Typography
            sx={{
              color: "#d32f2f",
              fontSize: "0.75rem",
            }}
            component="small"
          >
            {props?.error}
          </Typography>
        </Box>
      )}
    </Grid>
  );
};
export default CityField;
