import { FormError } from "app/containers/types";
import { emailRegex } from "styles/theme/utils";
import { ProfileState } from "./types";

export function validate(form: ProfileState): Array<FormError> {
  const errors: Array<FormError> = [];

  if (!emailRegex.test(form.email.value)) {
    errors.push({
      name: "email",
      error: "PROFILE.INVALID_EMAIL",
    });
  }
  if (form.firstName.value.length < 3) {
    errors.push({
      name: "firstName",
      error: "PROFILE.FIRST_NAME_ERROR",
    });
  }
  if (form.lastName.value.length < 3) {
    errors.push({
      name: "lastName",
      error: "PROFILE.LAST_NAME_ERROR",
    });
  }
  if (form.mobileNumber.value.length < 10) {
    errors.push({
      name: "phoneNumber",
      error: "PROFILE.PHONE_NUMBER_ERROR",
    });
  }
  if (form.language.value.length < 2) {
    errors.push({
      name: "language",
      error: "PROFILE.LANGUAGE_ERROR",
    });
  }

  return errors;
}
