import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "types";
import { UserState } from "../types";

const selectDomain = (state: RootState) => state.calenderStateType || UserState;

export const selectMonthList = createSelector(
  [selectDomain],
  (state) => state.monthList
);
export const selectWeekList = createSelector(
  [selectDomain],
  (state) => state.weekList
);
export const selectInstructorList = createSelector(
  [selectDomain],
  (state) => state.instructorList
);
export const selectQuery = createSelector(
  [selectDomain],
  (state) => state.query
);
export const selectError = createSelector(
  [selectDomain],
  (state) => state.error
);
