import { FormHelperText, MenuItem, Select } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";

type Props = {
  menuItems: string[];
  label: string;
  onSelect(values): void;
  selectedValues: string[];
  error?: boolean;
  helperText?: string;
};
export function MultiSelectComponent({
  menuItems,
  label,
  selectedValues,
  onSelect,
  error,
  helperText,
}: Props) {
  const { i18n } = useTranslation();
  const handleChange = (event) => {
    const value = event.target.value;
    if (!value) {
      return;
    }
    const arr = [...selectedValues].filter((element) => {
      // Use a condition to keep only non-blank and non-empty elements
      return element !== "" && element !== null && element !== undefined;
    });
    const index = selectedValues.indexOf(String(value));
    if (index > -1) {
      arr.splice(index, 1);
      onSelect(arr.join(","));
    } else {
      arr.push(value);
      onSelect(arr.join(","));
    }
  };
  const styles = useStyles();
  return (
    <>
      <label className={styles.label}>{label}</label>
      <Select
        variant="outlined"
        fullWidth
        margin="dense"
        required
        autoComplete={"off"}
        value={selectedValues}
        onChange={handleChange}
        className={styles.textEnabled}
        error={error}
        renderValue={(selected) => (
          <div>{<label>{selected.join(",")}</label>}</div>
        )}
      >
        {menuItems.map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
      {helperText ? (
        <FormHelperText
          error
          className={styles.errorText}
          sx={{
            textAlign: i18n.dir() === "rtl" ? "right" : "left",
          }}
        >
          {" "}
          {helperText}
        </FormHelperText>
      ) : null}
    </>
  );
}
