import { PayloadAction } from "@reduxjs/toolkit";
import set from "lodash/set";
import moment from "moment";
import { createSlice } from "utils/@reduxjs/toolkit";
import { convertToCalendar } from "../helper";
import { OppositeMonthlyResponse, UserState } from "../types";
const { DateTime } = require("luxon");
const initialState = UserState;

export const CalenderListSlice = createSlice({
  name: "calenderStateType",
  initialState,
  reducers: {
    getListMonth: (state) => {
      state.monthList.loading = true;
      state.monthList.items = [];
    },
    getListMonthSuccess: (state, action: PayloadAction<any>) => {
      state.monthList.loading = false;
      state.monthList.items = action.payload.result.map((item: any) => {
        return {
          title:
            moment(item.start_time).format("hh.mm A") +
            " - " +
            moment(item.end_time).format("hh.mm A") +
            ":" +
            item.timeslot.treatment.name +
            " / " +
            item.timeslot.instructor.firstName +
            "" +
            item.timeslot.instructor.lastName,
          date: moment(item.start_time).format("YYYY-MM-DDTHH:mm:ss"),
          id: item.id,
          object: item,
        };
      });
    },
    getListWeek: (state) => {
      state.weekList.loading = true;
      state.weekList.items = [];
    },
    getListWeekSuccess: (state, action: PayloadAction<any>) => {
      state.weekList.loading = false;
      state.weekList.items = action.payload.result.map((item: any) => {
        return {
          title:
            moment(item.start_time).format("hh.mm A") +
            " - " +
            moment(item.end_time).format("hh.mm A") +
            ":" +
            item.timeslot.treatment.name +
            " / " +
            item.timeslot.instructor.firstName +
            item.timeslot.instructor.lastName,
          start: item.start_time,
          end: item.end_time,

          id: item.id,
          object: item,
        };
      });
    },
    getListInstructor: (state) => {
      state.instructorList.loading = true;
      state.instructorList.items = [];
    },
    getListInstructorSuccess: (
      state,
      action: PayloadAction<OppositeMonthlyResponse>
    ) => {
      state.instructorList.loading = false;
      const specificDate = moment(action.payload.meta.date);

      // Get the start of the month for the specific date
      const startOfMonth = specificDate.clone().startOf("month");
      const endOfMonth = specificDate.clone().endOf("month");

      state.instructorList.items = action.payload.result.flatMap(
        (instructor, index) => {
          const schedule = convertToCalendar(
            instructor,
            startOfMonth,
            endOfMonth
          );
          return schedule.map((m, i2) => {
            return {
              id: `${instructor.id}.${index}.${i2}`,
              title: `${instructor.firstName} ${instructor.lastName}`,
              start: m.startDate,
              end: m.startDate == m.endDate ? undefined : m.endDate,
              backgroundColor: m.backgroundColor,
              textColor: m.color,
              allDay: true,
              object: m.object, // You can attach the original object if needed
            };
          });
        }
      );
    },

    setQuery: (state, action: PayloadAction<{ name: string; value: any }>) => {
      const { name, value } = action.payload;
      set(state.query, name, value);
    },
    resetModule: (state) => {
      state = initialState;
    },
  },
});
export const { reducer, actions, name: sliceKey } = CalenderListSlice;
