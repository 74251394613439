import axios from "axios";
import { API_URL } from "utils/constants";
import { getDefaultHeaders } from "utils/helpers";
import { request } from "utils/request";
import { PresignedResponse, UploadResponse } from "../../types";

export const getPresignedURL = async (
  file: File
): Promise<PresignedResponse> => {
  const reqData = {
    fileName: file.name,
    contentType: file.type,
  };
  const options = {
    method: "POST",
    headers: getDefaultHeaders(),
    body: JSON.stringify(reqData),
  };
  const response = (await request(
    `${API_URL}/v1/files/presigned-url`,
    options
  )) as PresignedResponse;
  return response;
};

export const uploadFileToS3 = async (
  file: File,
  onUploadProgress: (progress: number) => void
): Promise<UploadResponse> => {
  const presignedResponse = await getPresignedURL(file);

  const config = {
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      if (onUploadProgress) {
        onUploadProgress(percentCompleted); // Pass the progress percentage
      }
    },
    headers: {
      "Content-Type": file.type,
    },
  };

  const uploadResponse = await axios.put(
    presignedResponse.signedUrl,
    file,
    config
  );

  return { status: uploadResponse.statusText, file: presignedResponse.file };
};
