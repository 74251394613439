import {
  Box,
  Checkbox,
  CircularProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { selectAttendanceForm } from "app/containers/TimeSlots/components/ListView/redux/selector";
import { actions } from "app/containers/TimeSlots/components/ListView/redux/slice";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AttendanceStatus } from "utils/constants";
import { getFormattedDateTime } from "utils/helpers";
import DataTable from "../../../../../../components/DataTable";
import InputField from "../../../../../../components/InputField";
import { useStyles } from "./styles";

function CalendarForm(props) {
  const styles = useStyles();
  const attendanceForm = useSelector(selectAttendanceForm);
  const dispatch = useDispatch();
  const handleChange = (e) => {};
  const { t } = useTranslation();
  const disabledAttendance = !props.eventInfo.completedAt;
  const columnsColumns = [
    {
      id: "attendance",
      label: "",
      format: (value, index) => {
        if (value.isLoading) {
          return <CircularProgress size={15} />;
        }
        return (
          <Tooltip
            title={
              disabledAttendance
                ? t(
                    "TIMESLOT.ATTENDANCE_UNAVAILABLE_DUE_TO_NON_COMPLETE_SESSION"
                  )
                : t("TIMESLOT.MARK_ATTENDANCE")
            }
            followCursor
          >
            <Box>
              <Checkbox
                checked={value.attendance == AttendanceStatus.Present}
                color="info"
                disabled={disabledAttendance}
                onChange={() => {
                  dispatch(
                    actions.updateTimeslotPatientAttendance({
                      assignedTreatmentId: value.id,
                      attendance:
                        value.attendance !== AttendanceStatus.Present
                          ? AttendanceStatus.Present
                          : AttendanceStatus.Absent,
                      index: index,
                    })
                  );
                }}
                style={{ paddingLeft: "0px" }}
              />
            </Box>
          </Tooltip>
        );
      },
    },
    {
      id: "patientName",
      label: t("TIMESLOT.PATIENT_NAME"),
      format: (value) => {
        return `${value?.involvedPatient?.patient?.firstName} ${value?.involvedPatient?.patient?.lastName}`;
      },
    },
    {
      id: "status",
      label: t("TIMESLOT.STATUS"),
      sortValue: "selector.status",
      format: (value) => {
        return (
          <Tooltip title={value.attendance}>
            <Typography>
              {" "}
              {value.attendance || value?.involvedPatient?.registrationStatus}
            </Typography>
          </Tooltip>
        );
      },
    },
  ];
  return (
    <>
      <Grid2 xs={4}>
        <InputField
          label={t("TIMESLOT.DATE")}
          name="date"
          value={getFormattedDateTime(props.eventInfo.start_time, "dd/MM/yyyy")}
          onChange={handleChange}
          fullWidth
          isDisable={true}
        />
      </Grid2>
      <Grid2 xs={4}>
        <InputField
          label={t("TIMESLOT.START_TIME")}
          name="startDate"
          value={getFormattedDateTime(props.eventInfo.start_time, "HH:mm")}
          onChange={handleChange}
          fullWidth
          isDisable={true}
        />
      </Grid2>
      <Grid2 xs={4}>
        <InputField
          label={t("TIMESLOT.END_TIME")}
          name="endDate"
          value={getFormattedDateTime(props.eventInfo.end_time, "HH:mm")}
          onChange={handleChange}
          fullWidth
          isDisable={true}
        />
      </Grid2>
      <Grid2 xs={4}>
        <InputField
          label={t("TIMESLOT.TREATMENT_NAME")}
          name="endDate"
          value={props.eventInfo.timeslot.treatment.name || ""}
          onChange={handleChange}
          fullWidth
          isDisable={true}
        />
      </Grid2>
      <Grid2 xs={4}>
        <InputField
          label={t("TIMESLOT.INSTRUCTOR_NAME")}
          name="description"
          value={
            props.eventInfo.timeslot.instructor.firstName +
            " " +
            props.eventInfo.timeslot.instructor.lastName
          }
          onChange={handleChange}
          fullWidth
          isDisable={true}
        />
      </Grid2>
      <Grid2 xs={4}>
        <InputField
          label={t("TIMESLOT.COMPLETED_AT")}
          name="completedAt"
          value={getFormattedDateTime(
            props.eventInfo.completedAt,
            "dd/MM/yyyy HH:mm"
          )}
          onChange={handleChange}
          fullWidth
          isDisable={true}
        />
      </Grid2>
      <Grid2 xs={12}>
        <Typography
          sx={{
            fontFamily: "Cairo",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: "600",
            textAlign: "center",
            padding: "14px 0px 16px 0px",
          }}
        >
          {t("TIMESLOT.ATTENDANCE_REPORT")}
        </Typography>
        <DataTable
          setQuery={props.setQuery}
          rows={attendanceForm.assignedTreatment || []}
          columns={columnsColumns}
          scrollClass={styles.dataTableDiv}
          noDataLabel={t("TIMESLOT.ATTENDANCE_NO_DATA")}
        />
      </Grid2>
    </>
  );
}

export default CalendarForm;
