import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles(() => ({
  selectBox: {
    display: "flex",
    alignItems: "flex-start",
  },
  label: {
    fontFamily: "Cairo",
    fontWeight: 400,
    fontSize: "16px",
    color: "#2A3333",
  },
  textEnabled: {
    background: "#FFF",
    marginTop: "7px",
  },
  uploadLabelDisable: {
    fontFamily: "Cairo",
    fontWeight: "400px",
    marginTop: "13px",
    fontSize: "14px",
    marginLeft: "16px",
    color: "#6C7A7A",
  },
  uploadBoxDisable: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "5px",
    borderStyle: "dashed",
    borderWidth: "1px",
    borderColor: "#6C7A7A",
    height: "56px",
    width: "100%",
  },
  uploadBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "5px",
    borderStyle: "dashed",
    borderWidth: "1px",
    border: "1px solid #6C7A7A",
    height: "56px",
    width: "230px",
    background: "#FFF",
    marginBottom: "5px",
  },
  uploadLabel: {
    fontFamily: "Cairo",
    fontWeight: "400px",
    marginTop: "13px",
    fontSize: "14px",
    marginLeft: "16px",
    color: "#2A3333",
    textDecorationLine: "underline",
  },
  dotBox: {
    display: "flex",
    alignItems: "flex-end",
    marginTop: "30px",
  },
  textEnabledDate: {
    background: "#FFF",
    width: "100%",
    marginTop: "7px",
  },
  preview: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "40px",
    width: "300px",
    backgroundColor: "#00000",
    marginBottom: "5px",
    background: "#fff",
  },
}));
