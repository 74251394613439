import moment from "moment";
import { getFormattedDateTime } from "utils/helpers";
import {
  Instructor,
  PatientSchedule,
  TreatmentOptionalInstructor,
} from "./types";

function removeInsideRanges(dateRanges: TreatmentOptionalInstructor[]) {
  // Sort date ranges by start date
  dateRanges.sort(
    (a, b) =>
      moment(a?.treatmentBanktreatment?.startDate).valueOf() -
      moment(b?.treatmentBanktreatment?.startDate).valueOf()
  );

  const nonInsideRanges: PatientSchedule[] = [];

  for (let i = 0; i < dateRanges.length; i++) {
    let isInside = false;
    const currentRange = dateRanges[i];

    for (let j = 0; j < dateRanges.length; j++) {
      if (i !== j) {
        const otherRange = dateRanges[j];
        const startDateCurrent = moment(
          currentRange.treatmentBanktreatment?.startDate
        );
        const endDateCurrent = moment(
          currentRange.treatmentBanktreatment?.endDate
        );
        const startDateOther = moment(
          otherRange.treatmentBanktreatment?.startDate
        );
        const endDateOther = moment(otherRange.treatmentBanktreatment?.endDate);

        // Check if current range is completely inside other range
        if (
          startDateCurrent.isBetween(
            startDateOther,
            endDateOther,
            undefined,
            "[)"
          ) &&
          endDateCurrent.isBetween(
            startDateOther,
            endDateOther,
            undefined,
            "(]"
          )
        ) {
          isInside = true;
          break;
        }
      }
    }

    if (!isInside && currentRange.treatmentBanktreatment) {
      nonInsideRanges.push({
        startDate: getFormattedDateTime(
          currentRange.treatmentBanktreatment?.startDate,
          "yyyy-MM-dd"
        ),
        endDate: moment(
          getFormattedDateTime(
            currentRange.treatmentBanktreatment?.endDate,
            "yyyy-MM-dd"
          )
        )
          .clone()
          .add(1, "day")
          .format("YYYY-MM-DD"),
        backgroundColor: "yellow",
        color: "#000",
      });
    }
  }

  return nonInsideRanges;
}

function mergeOverlappingDates(events: PatientSchedule[]): PatientSchedule[] {
  // Sort events by start date
  events.sort((a, b) => moment(a.startDate).diff(moment(b.startDate)));

  const mergedEvents: PatientSchedule[] = [];
  let currentEvent: PatientSchedule | undefined = undefined;

  for (const event of events) {
    // If no current event or no overlap, add event and update current
    if (
      !currentEvent ||
      moment(currentEvent.endDate).isBefore(moment(event.startDate))
    ) {
      mergedEvents.push(event);
      currentEvent = event;
    } else {
      // Overlap: Update current event end date to the later of both
      currentEvent.endDate = moment
        .max(moment(currentEvent.endDate), moment(event.endDate))
        .format("YYYY-MM-DD");
    }
  }

  return mergedEvents;
}

function completeMonth(
  dateRanges,
  firstDayOfMonth: moment.Moment,
  lastDayOfMonth: moment.Moment
) {
  dateRanges.sort(
    (a, b) => moment(a.startDate).valueOf() - moment(b.startDate).valueOf()
  );

  const fullMonthRanges: PatientSchedule[] = [];

  let currentDate = firstDayOfMonth;

  for (let i = 0; i < dateRanges.length; i++) {
    const range = dateRanges[i];
    const isBefore = moment(currentDate).isBefore(range.startDate);
    if (isBefore) {
      fullMonthRanges.push({
        startDate: currentDate.format("YYYY-MM-DD"),
        endDate: moment(range.startDate)
          .clone()
          .endOf("day")
          .format("YYYY-MM-DD"),
        backgroundColor: "green",
        color: "#fff",
      });
    }
    currentDate = moment(range.endDate);
  }

  if (moment(currentDate).isBefore(lastDayOfMonth)) {
    fullMonthRanges.push({
      startDate: currentDate.format("YYYY-MM-DD"),
      endDate: lastDayOfMonth.clone().add(1, "day").format("YYYY-MM-DD"),
      backgroundColor: "green",
      color: "#fff",
    });
  }

  return fullMonthRanges;
}

function sortSequential(dateRanges: PatientSchedule[]): PatientSchedule[] {
  // Sort date ranges by start date
  dateRanges.sort(
    (a, b) => moment(a.startDate).valueOf() - moment(b.startDate).valueOf()
  );
  return dateRanges;
}

function fixObject(
  originalArray: PatientSchedule[],
  specificDate: string,
  occupiedDates: string[]
): PatientSchedule[] {
  const resultArray: PatientSchedule[] = [];

  for (const entry of originalArray) {
    const startDate = moment(entry.startDate);
    const endDate = moment(entry.endDate);
    const specificDateObj = moment(specificDate);
    if (
      startDate.isSameOrBefore(specificDateObj) &&
      endDate.isSameOrAfter(specificDateObj)
    ) {
      // Check if specificDate falls within the event range (adjusted logic)
      if (
        specificDateObj.isSame(startDate) &&
        specificDateObj.isSame(endDate)
      ) {
        // just remove entry if it is one day entry
      } else if (specificDateObj.isSame(startDate)) {
        const newBeforeStartDate = startDate.clone().add(1, "day");
        const newBeforeEndDate = endDate;
        resultArray.push({
          ...entry,
          startDate: newBeforeStartDate.format("YYYY-MM-DD"),
          endDate: newBeforeEndDate.format("YYYY-MM-DD"),
        });
      } else if (
        specificDateObj.isAfter(startDate) &&
        specificDateObj.isBefore(endDate)
      ) {
        // Create first object up to (but excluding) specificDate

        const newBeforeStartDate = startDate;
        const newBeforeEndDate = specificDateObj.clone();
        if (
          !(
            newBeforeStartDate.isSame(newBeforeEndDate) &&
            occupiedDates.includes(newBeforeStartDate.format("YYYY-MM-DD"))
          )
        ) {
          resultArray.push({
            ...entry,
            startDate: newBeforeStartDate.format("YYYY-MM-DD"),
            endDate: newBeforeEndDate.format("YYYY-MM-DD"),
          });
        }

        // Create second object from (including) specificDate + 1 to endDate
        const newAfterStartDate = specificDateObj.clone().add(1, "day");
        const newAfterEndDate = endDate;
        if (
          !(
            newAfterStartDate.isSame(
              newAfterEndDate.clone().subtract(1, "day")
            ) && occupiedDates.includes(newAfterStartDate.format("YYYY-MM-DD"))
          )
        ) {
          resultArray.push({
            ...entry,
            startDate: newAfterStartDate.format("YYYY-MM-DD"),
            endDate: newAfterEndDate.format("YYYY-MM-DD"),
          });
        }
      } else {
        // Add original entry if specificDate is outside the range
        resultArray.push(entry);
      }
    } else {
      // Add original entry if it doesn't overlap
      resultArray.push(entry);
    }
  }

  return resultArray;
}
// In full calendar end date in not inclusive. if
// date id 2024-02-25 -> 2024-02-29 (so it will show bar from (2024-02-25 -> 2024-02-28)
// create a date array where date we have +1

export function convertToCalendar(
  data: Instructor,
  firstDayOfMonth: moment.Moment,
  lastDayOfMonth: moment.Moment
) {
  try {
    const deDup = removeInsideRanges(data.treatmentOptionalInstructor);
    const merged = mergeOverlappingDates(deDup);
    const fullMonthRanges = completeMonth(
      merged,
      firstDayOfMonth,
      lastDayOfMonth
    );
    const sorted = sortSequential([...merged, ...fullMonthRanges]);
    let dataObj = [...sorted];
    let occupiedDates: string[] = [];
    data.timeSlots.forEach((t) => {
      t.timeslotSession.forEach((s) => {
        const startTime = moment(s.start_time).format("YYYY-MM-DD");
        if (!occupiedDates.includes(startTime)) {
          occupiedDates.push(startTime);
        }
      });
    });
    occupiedDates = occupiedDates.sort();
    occupiedDates.forEach((startTime) => {
      dataObj = fixObject(dataObj, startTime, occupiedDates);
    });

    console.log({
      deDup,
      merged,
      fullMonthRanges,
      sorted,
      occupiedDates,
      dataObj,
    });
    return dataObj.map((m) => {
      return { ...m, object: { ...data, occupiedDates } };
    });
  } catch (error) {
    return [];
  }
}
