import queryString from "query-string";
import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import {
  emailRegex,
  validateAndFormatIsraeliMobileNumber,
} from "styles/theme/utils";
import { API_URL } from "utils/constants";
import {
  TRANSLATE,
  getDefaultHeaders,
  removeBlankProperties,
  safeParseJSON,
} from "utils/helpers";
import { request } from "utils/request";
import toastService from "utils/toast";
import { PatientForm, TComments } from "../types";
import {
  selectAssignedTreatment,
  selectPatientCommnets,
  selectPatients,
  selectQuery,
} from "./selector";
import { actions } from "./slice";

export function* getList(action) {
  yield delay(500);
  const query = yield select(selectQuery);
  const requestData = removeBlankProperties(query);
  const queries = queryString.stringify({
    ...requestData,
  });
  try {
    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    const response = yield request(
      `${API_URL}/v1/patients?${queries}`,
      options
    );
    yield put(actions.getListSuccess(response));
  } catch (e: any) {
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", {
        error: JSON.stringify(e),
      })
    );
  }
}

export function* create(action) {
  yield delay(500);
  try {
    const patientsDetails: PatientForm = yield select(selectPatients);
    const comments: TComments[] = yield select(selectPatientCommnets);
    const treatment = yield select(selectAssignedTreatment);
    if (patientsDetails.organizationId.value === 0) {
      throw Error('{"message":"PATIENTS.PLEASE_ENTER_ORGANIZATION_NAME"}');
    }

    if (patientsDetails.departmentId.value === 0) {
      throw Error('{"message":"PATIENTS.PLEASE_ENTER_DEPARTMENT_NAME"}');
    }

    if (patientsDetails.bankId.value === 0) {
      throw Error('{"message":"PATIENTS.PLEASE_ENTER_HOURS_BANK"}');
    }
    if (patientsDetails.firstName.value.toString().trim().length === 0) {
      throw Error('{"message":"PATIENTS.PLEASE_ENTER_FIRST_NAME"}');
    }
    if (patientsDetails.lastName.value.toString().trim().length === 0) {
      throw Error('{"message":"PATIENTS.PLEASE_ENTER_LAST_NAME"}');
    }

    if (!patientsDetails.email.value.trim()) {
      throw Error('{"message":"PATIENTS.PLEASE_ENTER_EMAIL"}');
    } else {
      if (!emailRegex.test(patientsDetails.email.value)) {
        throw Error('{"message":"PATIENTS.PLEASE_ENTER_VALID_EMAIL"}');
      }
    }

    if (!patientsDetails.cellular.value.toString().trim()) {
      throw Error('{"message":"PATIENTS.PLEASE_ENTER_CELLULAR"}');
    }

    if (
      patientsDetails.cellular.value.length &&
      !validateAndFormatIsraeliMobileNumber(patientsDetails.cellular.value)
        .isValid
    ) {
      throw Error('{"message":"PATIENTS.PLEASE_ENTER_VALID_MOBILE_NUMBER"}');
    }

    const newPatients = {
      patients: {
        organizationId: patientsDetails.organizationId.value,
        departmentId: patientsDetails.departmentId.value,
        bankId: patientsDetails.bankId.value,
        patient_status: "New",
        firstName: patientsDetails.firstName.value,
        lastName: patientsDetails.lastName.value,
        email: patientsDetails.email.value,
        cellular: patientsDetails.cellular.value,
        sex: patientsDetails.sex.value,
        birthDate: !patientsDetails.birthDate.value
          ? null
          : patientsDetails.birthDate.value,
        street: patientsDetails.street.value,
        city:
          patientsDetails.city.value === "" ? null : patientsDetails.city.value,
        status: patientsDetails.status.value,
        howLongInCurrentStatus:
          patientsDetails.howLongInCurrentStatus.value === ""
            ? null
            : patientsDetails.howLongInCurrentStatus.value,
        numberOfChildren:
          patientsDetails.numberOfChildern.value === ""
            ? null
            : patientsDetails.numberOfChildern.value,
        education: patientsDetails.education.value,
        religion: patientsDetails.religion.value,
        economicLevel: patientsDetails.economicLevel.value,
        monthsInCurrentOrganization:
          patientsDetails.monthsInCurrentOrganization.value === ""
            ? null
            : patientsDetails.monthsInCurrentOrganization.value,
        monthsInCurrentJobs:
          patientsDetails.monthsInCurrentJobs.value === ""
            ? null
            : patientsDetails.monthsInCurrentJobs.value,
        dailyWorkHours: patientsDetails.dailyWorkJobs.value,
        anyBadHistory: patientsDetails.anyBadHistory.value,
        cityId:
          patientsDetails.cityId.value === 0
            ? null
            : patientsDetails.cityId.value,
        intakeFinalMeetingHistory1:
          patientsDetails.intakeFinalMeetingHistory1.value,
        intakeFinalMeetingHistory2:
          patientsDetails.intakeFinalMeetingHistory2.value,
        intakeInitialMeetingHistory1:
          patientsDetails.intakeInitialMeetingHistory1.value,
        intakeInitialMeetingHistory2:
          patientsDetails.intakeInitialMeetingHistory2.value,
      },
      assignedTreatment: treatment,
      comments: comments,
    };
    const requestOptions = {
      method: "POST",
      headers: getDefaultHeaders(),
      body: JSON.stringify(newPatients),
    };
    const url = `${API_URL}/v1/patients`;
    yield request(url, requestOptions);
    yield put(actions.getList());
    toastService.success(TRANSLATE("PATIENTS.PATIENTS_CREATED_SUCCESSFULLY"));
    action.payload.navigate("/patients");
  } catch (e) {
    const error = e as Error;
    console.log({ e });
    const { message } = safeParseJSON(error.message);
    yield put(actions.updateFailed(message));
    toastService.error(
      TRANSLATE(message, { defaultValue: "Error in Patients" })
    );
  }
}

export function* update(action) {
  yield delay(500);
  try {
    const patientsDetails = yield select(selectPatients);
    const comments: TComments[] = yield select(selectPatientCommnets);
    const treatment = yield select(selectAssignedTreatment);
    if (!patientsDetails.organizationId.value.toString().trim()) {
      throw Error('{"message":"PATIENTS.PLEASE_ENTER_ORGANIZATION_NAME"}');
    }
    if (!patientsDetails.departmentId.value.toString().trim()) {
      throw Error('{"message":"PATIENTS.PLEASE_ENTER_DEPARTMENT_NAME"}');
    }
    if (!patientsDetails.bankId.value.toString().trim()) {
      throw Error('{"message":"PATIENTS.PLEASE_ENTER_HOURS_BANK"}');
    }
    if (!patientsDetails.firstName.value.toString().trim()) {
      throw Error('{"message":"PATIENTS.PLEASE_ENTER_FIRST_NAME"}');
    }
    if (!patientsDetails.lastName.value.toString().trim()) {
      throw Error('{"message":"PATIENTS.PLEASE_ENTER_LAST_NAME"}');
    }

    if (!patientsDetails.email.value.trim()) {
      throw Error('{"message":"PATIENTS.PLEASE_ENTER_EMAIL"}');
    } else {
      if (!emailRegex.test(patientsDetails.email.value)) {
        throw Error('{"message":"PATIENTS.PLEASE_ENTER_VALID_EMAIL"}');
      }
    }

    if (!patientsDetails.cellular.value.toString().trim()) {
      throw Error('{"message":"PATIENTS.PLEASE_ENTER_CELLULAR"}');
    }

    if (
      patientsDetails.cellular.value.length &&
      !validateAndFormatIsraeliMobileNumber(patientsDetails.cellular.value)
        .isValid
    ) {
      throw Error('{"message":"PATIENTS.PLEASE_ENTER_VALID_MOBILE_NUMBER"}');
    }

    const newPatients = {
      patients: {
        organizationId: patientsDetails.organizationId.value,
        departmentId: patientsDetails.departmentId.value,
        bankId: patientsDetails.bankId.value,
        patient_status: patientsDetails.patientStatus.value,

        firstName: patientsDetails.firstName.value,
        lastName: patientsDetails.lastName.value,
        email: patientsDetails.email.value,
        cellular: patientsDetails.cellular.value,
        cityId:
          patientsDetails.cityId.value === 0
            ? null
            : patientsDetails.cityId.value,
        sex: patientsDetails.sex.value,
        birthDate: !patientsDetails.birthDate.value
          ? null
          : patientsDetails.birthDate.value,
        street: patientsDetails.street.value,
        city: patientsDetails.city.value,
        status: patientsDetails.status.value,
        howLongInCurrentStatus: patientsDetails.howLongInCurrentStatus.value,
        numberOfChildren: patientsDetails.numberOfChildern.value,
        education: patientsDetails.education.value,
        religion: patientsDetails.religion.value,
        economicLevel: patientsDetails.economicLevel.value,
        monthsInCurrentOrganization:
          patientsDetails.monthsInCurrentOrganization.value,
        monthsInCurrentJobs: patientsDetails.monthsInCurrentJobs.value,
        dailyWorkHours: patientsDetails.dailyWorkJobs.value,
        anyBadHistory: patientsDetails.anyBadHistory.value,
        intakeFinalMeetingHistory1:
          patientsDetails.intakeFinalMeetingHistory1.value,
        intakeFinalMeetingHistory2:
          patientsDetails.intakeFinalMeetingHistory2.value,
        intakeInitialMeetingHistory1:
          patientsDetails.intakeInitialMeetingHistory1.value,
        intakeInitialMeetingHistory2:
          patientsDetails.intakeInitialMeetingHistory2.value,
      },
      assignedTreatment: treatment,
      comments: comments,
    };
    const requestOptions = {
      method: "PATCH",
      headers: getDefaultHeaders(),
      body: JSON.stringify(newPatients),
    };
    const url = `${API_URL}/v1/patients/${action.payload.id}`;
    yield request(url, requestOptions);
    yield put(actions.getList());
    toastService.success(TRANSLATE("PATIENTS.PATIENTS_UPDATED_SUCCESSFULLY"));
    action.payload.navigate("/patients");
  } catch (e) {
    const error = e as Error;
    const { message } = safeParseJSON(error.message);
    yield put(actions.updateFailed(message));
    toastService.error(
      TRANSLATE(message, { defaultValue: "Error in Patients" })
    );
  }
}

export function* deleteRow(action) {
  yield delay(500);
  try {
    const options = {
      method: "DELETE",
      headers: getDefaultHeaders(),
    };
    if (action.payload.id) {
      const response = yield call(
        request,
        `${API_URL}/v1/patients/${action.payload.id}`,
        options
      );
      yield put(actions.getList(response.items));
    }
  } catch (e: any) {
    const error = JSON.parse(e.message);
    console.error(e);
  }
}

export function* importPatients(action) {
  yield delay(500);
  try {
    const options = {
      method: "POST",
      headers: getDefaultHeaders(),
      body: JSON.stringify(action.payload),
    };
    if (action.payload) {
      const response = yield call(request, `${API_URL}/v1/imports/`, options);
      yield put(actions.importSuccess());
    }
  } catch (e: any) {
    const error = JSON.parse(e.message);
    console.error(e);
  }
}

export function* getDataById(action) {
  yield delay(500);
  try {
    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    const response = yield request(
      `${API_URL}/v1/patients/get-by-id/${action.payload.id}`,
      options
    );
    yield put(actions.getPatientByIdSuccess(response));
  } catch (e: any) {
    if (e.response.status === 404) {
      toastService.error("Patient Not Found");
      debugger;
      return;
    }
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", {
        error: JSON.stringify(e),
      })
    );
  }
}

export function* sendMessage(action) {
  yield delay(500);
  try {
    const options = {
      method: "POST",
      headers: getDefaultHeaders(),
      body: JSON.stringify(action.payload.data),
    };
    const response = yield request(
      `${API_URL}/v1/notifications/patients`,
      options
    );
    toastService.success(TRANSLATE("PATIENTS.MESSAGE_SENT_SUCCESSFULLY"));
    action.payload.callback();
  } catch (e: any) {
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", {
        error: JSON.stringify(e),
      })
    );
  }
  yield put(actions.sendMessageComplete());
}

function* getTimeSlotForPatient(action) {
  yield delay(500);
  try {
    const query = yield select(selectQuery);
    const { id } = action.payload;
    const requestData = removeBlankProperties(query);
    const queries = queryString.stringify({
      ...requestData,
    });

    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };

    const url = `${API_URL}/v1/timeslot/list-for-patient/${action.payload.treatment.treatmentId}/${action.payload.bankId}/${action.payload.patientId}/${action.payload.treatment.instructorId}`;
    const response = yield call(request, url, options);
    yield put(actions.setTimeSlotItems(response));
  } catch (e: any) {
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", {
        error: JSON.stringify(e),
      })
    );
  }
}

function* deletePatientApi(action) {
  yield delay(500);
  try {
    const options = {
      method: "DELETE",
      headers: getDefaultHeaders(),
    };
    const url = `${API_URL}/v1/patients/${action.payload?.id}`;
    yield call(request, url, options);
    yield put(actions.getList());
    toastService.success(TRANSLATE("PATIENTS.PATIENT_DELETED_SUCCESSFULLY"));
    action.payload.callback();
  } catch (e: any) {
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", {
        error: JSON.stringify(e?.message?.message),
      })
    );
  }
  yield put(actions.deletePatientComplete());
}

function* setTimeSlot(action) {
  try {
    yield put(actions.setTimeSlotSuccess(action.payload));
  } catch (e: any) {
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", {
        error: JSON.stringify(e),
      })
    );
  }
}

export function* usePatientSaga() {
  yield takeLatest(actions.getList.type, getList);
  yield takeLatest(actions.getNextPageItems.type, getList);
  yield takeLatest(actions.create.type, create);
  yield takeLatest(actions.update.type, update);
  yield takeLatest(actions.importPatients.type, importPatients);
  yield takeLatest(actions.getDataById.type, getDataById);
  yield takeLatest(actions.sendMessage.type, sendMessage);
  yield takeLatest(actions.deletePatient.type, deletePatientApi);
  yield takeLatest(actions.openSelectedTreatMent.type, getTimeSlotForPatient);

  yield takeLatest(actions.setTimeSlot.type, setTimeSlot);
}
