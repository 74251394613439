import { Button, CircularProgress } from "@mui/material";
import React from "react";
import { useStyles } from "./styles";
type Props = {
  isLoading?: boolean;
  label: string;
  type?: "submit";
  onClick?: () => void;
  size?: "small";
  variant?: "contained" | "outlined";
  disabled?: boolean;
};
export const PrimaryButton = ({
  size,
  isLoading,
  label,
  type,
  onClick,
  variant = "contained",
  disabled,
}: Props) => {
  const classes = useStyles();
  return (
    <Button
      variant={variant}
      className={size ? classes.smallButton : classes.submit}
      type={type || "submit"}
      onClick={onClick}
      disabled={disabled || isLoading}
    >
      {isLoading ? (
        <CircularProgress size={24} className={classes.buttonProgress} />
      ) : (
        label
      )}
    </Button>
  );
};
