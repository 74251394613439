import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles(() => ({
  mainContainer: {
    background: "#F5F8FF",
    minHeight: "100%",
    minWidth: "100%",
    bottom: "auto",
  },
  bodyContainer: {
    padding: "20px 20px 0px 300px",
    minHeight: "100%",
  },
  btnContainer: {
    display: "flex",
    alignItems: "flex-end",
  },
  imgClass: {
    height: 20,
    width: 20,
    cursor: "pointer",
    color: "#FCC500",
  },
  textHeader: {
    color: "#2A3333",
    marginLeft: "14px",
    fontWeight: "600px",
    fontSize: "16px",
    lineHeight: "29.98px",
    fontStyle: "Semibold",
  },
  textHeaderT: {
    color: "#012497",
    fontWeight: "600px",
    fontSize: "16px",
    lineHeight: "29.98px",
  },
  mainHeader: {
    display: "flex",
    alignItems: "center",
    marginTop: "60px",
  },
  label: {
    fontFamily: "Cairo",
    fontWeight: 400,
    fontSize: "16px",
    color: "#2A3333",
  },
  saveBtn: {
    background: "#3D63E6",
    color: "#ffff",
    "&:hover": {
      background: "#3D63E6",
    },
    fontFamily: "Cairo",
    fontWeight: 700,
    fontSize: "16px",
    width: "160px",
    height: "50px",
  },
  addModal: {
    position: "absolute",
    background: "#F5F8FF",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "0px",
    boxShadow: "0",
    zIndex: "9999",
    width: "700px",
  },
  modalContainer: {
    padding: "20px",
  },
  headerModal: {
    color: "#012497",
    fontFamily: "Cairo",
    fontWeight: "1000",
    fontSize: "24px",
  },
  commentSaveBtn: {
    background: "#3D63E6",
    color: "#ffff",
    "&:hover": {
      background: "#3D63E6",
    },
    fontFamily: "Cairo",
    fontWeight: 700,
    fontSize: "16px",
    width: "120px",
    height: "56px",
  },
  cancelBtn: {
    color: "#3D63E6",
    borderColor: "#3D63E6",
    "&:hover": {
      borderColor: "#3D63E6",
    },
    fontFamily: "Cairo",
    fontWeight: 700,
    fontSize: "16px",
    width: "160px",
    height: "50px",
  },
  tabLabel: {
    color: "#3D63E6",
    fontWeight: "bold",
  },
  uploadLabel: {
    fontFamily: "Cairo",
    fontWeight: "400px",
    marginTop: "13px",
    fontSize: "14px",
    marginLeft: "16px",
    color: "#6C7A7A",
  },
  uploadBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "5px",
    borderStyle: "dashed",
    borderWidth: "1px",
    borderColor: "#6C7A7A",
    height: "56px",
    width: "100%",
  },
  chipBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "5px",
    border: "1px solid #C6C9CA",
    height: "40px",
    padding: "10px",
    background: "#FFF",
  },
  chipLabel: {
    fontFamily: "Cairo",
    fontWeight: "600px",
    fontSize: "16px",
    color: "#012497",
    marginTop: "15px",
  },
  numLabel: {
    fontFamily: "Cairo",
    fontWeight: "400px",
    fontSize: "14px",
    marginLeft: "16px",
    color: "#6C7A7A",
    marginTop: "16px",
  },
  dotBox: {
    display: "flex",
    alignItems: "flex-end",
  },
  textEnabled: {
    background: "#FFF",
  },
  searchIcon: {
    height: "24px",
    width: "24px",
  },
  search: {
    marginTop: "30px",
    marginBottom: "20px",
    width: "100%",
    height: "48px",
    "& .MuiOutlinedInput-root": {
      borderRadius: 3,
      margin: 0,
      background: "#F5F8FF",
      width: "100%",
      height: "48px",
    },
  },
  textEnabledSelect: {
    background: "#FFF",
    width: "100%",
    marginTop: "8px",
  },
  checkLabel: {
    fontStyle: "Cairo",
    fontWeight: "700px",
    fintSize: "16px",
    marginTop: "14px",
  },
  deleteModal: {
    position: "absolute",
    background: "#FFFFFF",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "0px",
    boxShadow: "0",
    zIndex: "9999",
    width: "410px",
    height: "230px",
  },
  deleteTypo: {
    color: "#2A3333",
    fontFamily: "Cairo",
    fontWeight: "400",
    fontSize: "16px",
  },
  deleteUserBtn: {
    color: "#FF4839",
    borderColor: "#FF4839",
    "&:hover": {
      borderColor: "#FF4839",
    },
    fontFamily: "Cairo",
    fontWeight: 700,
    fontSize: "16px",
    width: "160px",
    height: "50px",
    borderRadius: 4,
  },
  dataTableDiv: {
    maxHeight: "calc(100vh - 410px)",
    //maxWidth: "calc(159vh)",
    overflowY: "auto",
    overflowX: "auto",
    marginTop: "0px",
    border: "1px solid #C6C9CA",
    background: "#F5F8FF",
  },
  modalDataTableDiv: {
    maxHeight: "calc(100vh - 350px)",
    overflowY: "auto",
  },
  tabsTitle: {
    fontSize: "16px",
  },
  addButton: {
    marginRight: "0px",
  },
  popupAction: {
    margin: "15px 0px 15px 0px",
  },
}));
