import { Alert } from "@mui/material";
import React from "react";
import { toast } from "react-toastify";

const toastService = (() => {
  const success = (message: string) => {
    toast.success(({ closeToast }) => (
      <Alert
        style={{
          background: "#EBF1FE",
          fontFamily: "Cairo !important",
        }}
        severity="success"
        onClose={closeToast}
      >
        {message}
      </Alert>
    ));
  };

  const error = (message: string) => {
    toast.error(({ closeToast }) => (
      <Alert
        sx={{
          background: "#FF7377",
          fontFamily: "Cairo !important",
        }}
        severity="error"
        onClose={closeToast}
      >
        {message}
      </Alert>
    ));
  };

  return {
    error,
    success,
  };
})();

export default toastService;
