import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles((theme: Theme) => ({
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
    color: "#ffffff",
  },
  smallButton: {
    height: "56px",
    minWidth: "150px",
    fontSize: "16px",
    fontFamily: "Cairo",
    fontStyle: "bold",
    lineHeight: "30px",
    fontWeight: 700,
    borderRadius: "4px",
  },

  submit: {
    background: "linear-gradient(135deg, #3D63E6 0%, #3D63E6 100%)",
    width: "100%",
    height: "56px",
    fontSize: "16px",
    fontFamily: "Cairo",
    fontStyle: "bold",
    lineHeight: "30px",
    fontWeight: 700,
    borderRadius: "4px",
    color: "#FFFFFF",
    maxWidth: "100%",
  },
}));
