import { PayloadAction } from "@reduxjs/toolkit";
import set from "lodash/set";
import { createSlice } from "utils/@reduxjs/toolkit";
import { UserState } from "../types";
const initialState = UserState;
export const treatmentTypeSlice = createSlice({
  name: "treatmentTypeState",
  initialState,
  reducers: {
    getList: (state) => {
      state.loading = true;
      state.query.page = 1;
    },
    getListSuccess: (
      state,
      action: PayloadAction<{ items: []; links: any; meta: any }>
    ) => {
      state.loading = false;
      const items = action.payload.items;
      state.list.items =
        action.payload.meta.currentPage === 1
          ? items
          : [...state.list.items, ...items];
      state.list.meta = action.payload.meta;
    },
    getListError: (state) => {
      state.loading = false;
    },
    getNextPage: (state, action: PayloadAction<any>) => {
      state.query.page = state.list.meta.currentPage + 1;
      state.loading = true;
    },

    setQuery: (state, action: PayloadAction<{ name: string; value: any }>) => {
      const { name, value } = action.payload;
      state.query.page = 1;
      state.list = initialState.list;
      set(state.query, name, value);
    },
    resetModule: (state) => {
      state = initialState;
    },
  },
});
export const { reducer, actions, name: sliceKey } = treatmentTypeSlice;
