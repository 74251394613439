import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  progress: {
    //color: "#fff",
    margin: 10,
  },
  saveBtn: {
    background: "#3D63E6",
    padding: "10px 35px",
    border: "none",
    color: "#fff",
    borderRadius: "3px",
    width: "160px",
    height: "50px",
    fontFamily: "Cairo",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "30px",
  },
  cancelBtn: {
    color: "#3D63E6",
    borderColor: "#3D63E6",
    "&:hover": {
      borderColor: "#3D63E6",
    },
    fontFamily: "Cairo",
    fontWeight: 700,
    fontSize: "16px",
    width: "160px",
    height: "50px",
  },
}));
