export const APP_API_URL =
  process.env.REACT_APP_API_URL || "https://api.dev.medicalcare.zangula.net";
export const APP_VERSION = process.env.REACT_APP_VERSION || "";
export const IS_PRODUCTION = process.env.NODE_ENV !== "production";
export const IS_DEVELOPMENT = process.env.REACT_APP_ENV === "development";
export const API_URL = IS_DEVELOPMENT
  ? "http://localhost:3000/api"
  : APP_API_URL;
export const ALL_TREATMENT_AMOUNTS = [0, 1, 2, 3, 4, 5];

export const ALL_RELIGIONS = [
  "PATIENTS.OPTIONS.JEWISH",
  "PATIENTS.OPTIONS.MUSLIM",
  "PATIENTS.OPTIONS.CHRISTIAN",
  "PATIENTS.OPTIONS.HINDUISM",
  "PATIENTS.OPTIONS.BUDDHISM",
  "PATIENTS.OPTIONS.OTHER",
];

export enum AttendanceStatus {
  Present = "Present",
  Absent = "Absent",
  EMPTY = "",
}

export enum InvolvementType {
  Regular = "Regular",
  Bonus = "Bonus",
}

export const MAX_RESULTS_EXPORT = 200;
