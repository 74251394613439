import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles(() => ({
  dotBox: {
    display: "flex",
    alignItems: "flex-end",
    cursor: "pointer",
    flexDirection: "column",
    position: "relative",
    width: 210,
  },
  progress: {
    position: "absolute",
    bottom: 2,
    left: 5,
    right: 5,
  },
  iconImage: {
    width: 30,
    height: 30,
    flex: 1,
  },
  hiddenInput: {
    position: "absolute",
    top: "-9999px",
    left: "-9999px",
  },
  uploadBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "5px",
    borderStyle: "dashed",
    borderWidth: "1px",
    border: "1px solid #6C7A7A",
    height: "60px",
    width: "100%",
    background: "#FFF",
    flexDirection: "column",
    overflow: "hidden",
  },
  labelWrap: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  uploadBoxErr: {
    borderColor: "red",
  },
  uploadLabel: {
    fontFamily: "Cairo",
    fontWeight: "400px",
    fontSize: "14px",
    marginLeft: "16px",
    color: "#2A3333",
    textDecorationLine: "underline",
    maxWidth: 90,
  },
  sizeLabel: {
    fontFamily: "Cairo",
    fontWeight: "400px",
    fontSize: "14px",
    color: "#2A3333",
    marginLeft: 5,
  },
}));
