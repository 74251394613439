import {
  Button,
  Card,
  Grid,
  Modal,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import CityField from "app/components/FieldComponent/City";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectDepartment, selectList } from "../../redux/selector";
import { actions } from "../../redux/slice";
import { isDuplicate, onValidate } from "../../validator";
import { useStyles } from "./styles";

type Props = {
  open: boolean;
  handleClose: any;
  style?: object;
  action: string;
  handleSave: any;
};

const DepartmentModal = ({
  open,
  handleClose,
  handleSave,
  style,
  action,
}: Props) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const form = useSelector(selectDepartment);
  const data = useSelector(selectList);
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const direction = theme.direction;

  const onChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      actions.setFormErrors({
        key: "department",
        errors: [
          {
            name,
            error: "",
          },
        ],
      })
    );
    if (e.target.name === "noOfBanks") {
      const regex = /^[0-9\b]+$/;
      const data = regex.test(value) ? value : value.replace(/[^0-9]/g, "");
      dispatch(actions.updateFormValue({ key: name, value: data }));
    } else {
      dispatch(actions.updateFormValue({ key: name, value }));
    }
  };

  const validate = () => {
    const errors = onValidate(form);
    if (errors.length > 0) {
      dispatch(
        actions.setFormErrors({
          key: "department",
          errors,
        })
      );
      return;
    }
    const duplicates = isDuplicate(form, data.items);
    if (duplicates.length > 0) {
      dispatch(
        actions.setFormErrors({
          key: "department",
          errors: duplicates,
        })
      );
      return;
    }
    handleSave();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card
        sx={{
          direction: direction,
        }}
        className={styles.importModal}
      >
        <div className={styles.modalContainer}>
          <Grid>
            <Typography align="center" className={styles.headerModal}>
              {action?.toLowerCase() === "add"
                ? t("ORGANIZATIONS.ADD_DEPARTMENT")
                : t("ORGANIZATIONS.EDIT_DEPARTMENT")}
            </Typography>
          </Grid>
          <Grid
            container
            spacing={2}
            md={12}
            sx={{ padding: "10px 0px 0px 0px" }}
          >
            <Grid item xs={6}>
              <label className={styles.label}>
                {t("ORGANIZATIONS.DEPARTMENT_NAME")}
              </label>
              <TextField
                margin="dense"
                variant="outlined"
                fullWidth
                error={Boolean(form.departmentName.error)}
                value={form.departmentName.value}
                onChange={(e) => onChange(e)}
                required
                helperText={
                  form.departmentName.error ? t(form.departmentName.error) : ""
                }
                id="departmentName"
                placeholder={t("ORGANIZATIONS.ENTER_DEPARTMENT_NAME")}
                name="departmentName"
                autoComplete={"off"}
                className={styles.textEnabled}
                FormHelperTextProps={{
                  style: {
                    textAlign: i18n.dir() === "ltr" ? "left" : "right",
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <CityField
                changeValue={(e, name) => {
                  dispatch(
                    actions.setFormErrors({
                      key: "department",
                      errors: [{ name: "city", error: "" }],
                    })
                  );
                  dispatch(actions.updateFormValue({ key: "city", value: e }));
                  dispatch(
                    actions.updateFormValue({ key: "cityName", value: name })
                  );
                }}
                error={form.city.error ? t(form.city.error) : ""}
                value={form.city.value}
                xs={12}
                disabled={false}
              ></CityField>
            </Grid>

            <Grid item xs={12}>
              <label className={styles.label}>
                {t("ORGANIZATIONS.ADDRESS")}
              </label>
              <TextField
                margin="dense"
                variant="outlined"
                fullWidth
                error={Boolean(form.address.error)}
                helperText={form.address.error ? t(form.address.error) : ""}
                value={form.address.value}
                onChange={(e) => onChange(e)}
                required
                id="address"
                placeholder={t("ORGANIZATIONS.ENTER_ADDRESS")}
                name="address"
                autoComplete={"off"}
                className={styles.textEnabled}
                FormHelperTextProps={{
                  style: {
                    textAlign: i18n.dir() === "ltr" ? "left" : "right",
                  },
                }}
              />
            </Grid>
          </Grid>

          <div>
            <Grid
              container
              spacing={3}
              style={{ position: "relative", top: "35px" }}
            >
              <Grid
                className={styles.btnContainer}
                container
                item
                xs={6}
                direction="column"
              >
                <Button
                  className={styles.cancelBtn}
                  variant="outlined"
                  onClick={handleClose}
                >
                  {t("BUTTONS.CANCEL")}
                </Button>
              </Grid>
              <Grid container item xs={6} direction="column">
                <Button
                  onClick={validate}
                  className={styles.saveBtn}
                  variant="contained"
                >
                  {t("BUTTONS.SAVE")}
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </Card>
    </Modal>
  );
};
export default DepartmentModal;
