import { createSelector } from "@reduxjs/toolkit";
import i18next from "i18next";
import { sumBy } from "lodash";
import { RootState } from "types";
import { initialState } from "../types";

const selectDomain = (state?: RootState) =>
  state?.dashboardState || initialState;
export const selectQuestionnaire = createSelector(
  [selectDomain],
  (state) => state.questionnaire
);

export const selectQuestionnaireDetails = createSelector(
  [selectDomain],
  (state) => state.questionnaire.details
);

export const selectProgressList = createSelector(
  [selectDomain],
  (state) => state.progress.progressList
);

export const selectDailyPieProgress = createSelector(
  [selectDomain],
  (state) => {
    const seen = state.progress?.dailyActivity?.seen || 0;
    const total = state.progress?.dailyActivity?.total || 0;
    return [
      ["", ""],
      [i18next.t("DASHBOARD.WATCHED", { value: seen }), seen],
      [i18next.t("DASHBOARD.OUT_OF_SENT", { value: total }), total - seen],
    ];
  }
);

export const selectMeetingPieProgress = createSelector(
  [selectDomain],
  (state) => {
    const complete = sumBy(state.progress?.progressList?.items || [], (p) => {
      return Number(p.sum_of_meetings_completed_count);
    });
    const missed = sumBy(state.progress?.progressList?.items || [], (p) => {
      return Number(p.sum_of_meetings_missed_count);
    });
    const future = sumBy(state.progress?.progressList?.items || [], (p) => {
      return Number(p.sum_of_meetings_in_progress_count);
    });

    return [
      ["", ""],
      [i18next.t("DASHBOARD.COMPLETE", { value: complete }), complete],
      [i18next.t("DASHBOARD.MISSED", { value: missed }), missed],
      [i18next.t("DASHBOARD.FUTURE", { value: future }), future],
    ];
  }
);

export const selectSearch = createSelector(
  [selectDomain],
  (state) => state.search
);

export const selectQuery = createSelector(
  [selectDomain],
  (state) => state.query
);
