import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles(() => ({
  mainContainer: {
    background: "#F5F8FF",
    minHeight: "100%",
    minWidth: "100%",
    bottom: "auto",
  },
  bodyContainer: {
    padding: "20px 20px 0px 300px",
    marginTop: "40px",
    minHeight: "calc(100vh - 40px)",
  },
}));
