import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "8px",
  },
  container: {
    minHeight: "150px",
    marginTop: "30px",
    border: "none",
    overflow: "auto",
  },
  tableSort: {
    color: "#2A3333 !important",
    "& svg": {
      color: "#2A3333 !important",
    },
  },
  cellHead: {
    //position: "sticky",
    padding: "14px 14px 17px 16px",
    fontSize: 14,
    fontWeight: 700,
    lineHeight: "16.71px",
    color: "#2A3333",
    whiteSpace: "nowrap",
    height: 56,
    fontFamily: "Cairo",
    background: "#F5F8FF",
    "&:last-child": {},
    "&:first-child": {},
    "&:first-of-type": {
      borderLeft: "transparent",
    },
    "&.makeStyles-cellHead-33": {
      backgroundColor: "transparent",
      color: "#BDBDBD",
    },
  },
  cellHeadWithoutWidth: {
    position: "sticky",
    padding: "14px 14px 17px 16px",
    fontSize: 14,
    fontWeight: 700,
    lineHeight: "16.71px",
    textAlign: "unset",
    color: "#2A3333",
    whiteSpace: "nowrap",
    height: 56,
    fontFamily: "Cairo",
    background: "#F5F8FF",
    "&:last-child": {},
    "&:first-child": {},
    "&:first-of-type": {
      borderLeft: "transparent",
    },
    "&.makeStyles-cellHead-33": {
      backgroundColor: "transparent",
      color: "#BDBDBD",
    },
  },
  tableBody: {
    backgroundColor: "transparent",
    "& .MuiTableRow-root:nth-of-type(even)": {
      backgroundColor: "#F5F8FF",
    },
    border: "none",
    "& .MuiTableCell-root": {
      border: "none",
      backgroundColor: "transparent",
    },
  },
  table: {
    border: "1px solid #C6C9CA",
  },
  headerCheckbox: {
    padding: 0,
    width: 18,
    height: 18,
    marginRight: 6,
  },
  customCheckbox: {
    padding: 0,
    width: 18,
    height: 18,
  },
  iconButton: {
    padding: "0px",
    marginLeft: "5px",
    marginRight: "5px",
  },
  overrideIconButton: {
    position: "relative",
    right: -20,
  },
  downloadBtn: {
    cursor: "pointer",
  },
  link: {
    fontSize: 14,
    fontWeight: 700,
    color: "#3d63e6",
    fontFamily: "Cairo",
  },
  dataTableDiv: {
    maxHeight: "calc(100vh - 350px)",
    //maxWidth: "calc(159vh)",
    overflowY: "auto",
    overflowX: "auto",
    border: "1px solid #C6C9CA",
    marginTop: "20px",
    background: "#F5F8FF",
  },
});
