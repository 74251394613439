import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles(() => ({
  label: {
    fontFamily: "Cairo",
    fontWeight: 400,
    fontSize: "16px",
    color: "#2A3333",
  },
  textEnabled: {
    background: "#FFF",
    marginTop: "6px",
  },
  loader: {
    width: "30px !important",
    height: "auto !important",
    marginRight: "15px !important",
  },
}));
