import queryString from "query-string";
import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { API_URL } from "utils/constants";
import {
  TRANSLATE,
  getDefaultHeaders,
  removeBlankProperties,
  safeParseJSON,
} from "utils/helpers";
import { request } from "utils/request";
import toastService from "utils/toast";
import { AvailableTimeslotsResponse, TreatmentTypeModal } from "../types";
import { selectAvailableTimeSlots, selectQuery } from "./selector";
import { actions } from "./slice";

export function* getList(action) {
  yield delay(500);
  const { patientId } = action.payload;
  const query = yield select(selectQuery);
  const requestData = removeBlankProperties(query);
  const queries = patientId
    ? queryString.stringify({
        patientId,
        page: query.page,
        limit: 20,
      })
    : queryString.stringify({
        ...requestData,
        patientId,
      });

  try {
    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    const response = yield request(
      `${API_URL}/v1/assigned-treatments?${queries}`,
      options
    );

    yield put(actions.getListSuccess(response));
  } catch (e: any) {
    yield put(actions.getListFailure());
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", {
        error: JSON.stringify(e),
      })
    );
  }
}

function* getTreatmentInfoForPatient(action) {
  yield delay(500);
  try {
    const payload = action.payload as TreatmentTypeModal;
    const queries = queryString.stringify(payload);

    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };

    const url = `${API_URL}/v1/timeslot/treatment-info?${queries}`;
    const response = yield call(request, url, options);
    yield put(actions.openSelectedTreatMentSuccess(response));
  } catch (e: any) {
    yield put(actions.openSelectedTreatMentFailure());
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", {
        error: JSON.stringify(e),
      })
    );
  }
}

function* getInstructorTimeslots(action) {
  yield delay(500);
  try {
    const availableTimeSlots: AvailableTimeslotsResponse = yield select(
      selectAvailableTimeSlots
    );
    const queries = queryString.stringify({
      treatmentInvolvedPatientId:
        availableTimeSlots.treatmentInvolvedPatient.id,
      instructorId: action.payload.instructorId,
    });

    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };

    const url = `${API_URL}/v1/timeslot/available-time-slots?${queries}`;
    const response = yield call(request, url, options);
    yield put(actions.getInstructorTimeslotsSuccess(response));
  } catch (e: any) {
    yield put(actions.openSelectedTreatMentFailure());
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", {
        error: JSON.stringify(e),
      })
    );
  }
}

function* setTimeSlot(action) {
  try {
    const availableTimeSlots: AvailableTimeslotsResponse = yield select(
      selectAvailableTimeSlots
    );
    if (!availableTimeSlots.treatmentInvolvedPatient?.instructorId) {
      throw Error('{"message":"ASSIGNED_TREATMENT.PLEASE_SELECT_INSTRUCTOR"}');
    }

    if (availableTimeSlots?.type == 1) {
      for (const timeslotInvolvedPatient of availableTimeSlots
        .treatmentInvolvedPatient.timeslotInvolvedPatients) {
        if (
          timeslotInvolvedPatient.newTimeslotId &&
          !timeslotInvolvedPatient.timeslotSessionId
        ) {
          const index =
            availableTimeSlots.treatmentInvolvedPatient.timeslotInvolvedPatients.indexOf(
              timeslotInvolvedPatient
            );
          throw Error(
            `{"message":"ASSIGNED_TREATMENT.PLEASE_SELECT_SESSION_FOR_TREATMENT","params":{"treatment":${String(
              index + 1
            )}}}`
          );
        }
      }
    }

    const options = {
      method: "PATCH",
      headers: getDefaultHeaders(),
      body: JSON.stringify({
        id: availableTimeSlots.treatmentInvolvedPatient.id,
        instructorId: availableTimeSlots.treatmentInvolvedPatient.instructorId,
        timeslotInvolvedPatients:
          availableTimeSlots.treatmentInvolvedPatient.timeslotInvolvedPatients.map(
            (tip) => {
              return {
                id: tip.id,
                currentTimeSlotId: tip.currentTimeslotId,
                newTimeSlotId: tip.newTimeslotId,
                timeslotSessionId: tip.timeslotSessionId,
                option: tip.option,
              };
            }
          ),
      }),
    };

    const url = `${API_URL}/v1/timeslot/update-timeslot`;
    const response = yield call(request, url, options);
    yield put(actions.setTimeSlotSuccess(action.payload));
    toastService.success(
      TRANSLATE("ASSIGNED_TREATMENT.UPDATE_TIMESLOT_SUCCESS")
    );
  } catch (e: any) {
    const error = e as Error;
    const { message, params, errors } = safeParseJSON(error.message);
    yield put(actions.setTimeSlotFailed());
    if (message == "TIMESLOT.PATIENT_CONFLICT_TIMINGS") {
      toastService.error(
        TRANSLATE("TIMESLOT.PATIENT_CONFLICT_TIMINGS_WITH_MEETING", {
          meeting: errors.map((m) => m.meetingId),
          defaultValue: "Conflict in patient sessions",
        })
      );
      return;
    }

    toastService.error(
      TRANSLATE(message, {
        ...(params || {}),
        defaultValue: "Error in Organization",
      })
    );
  }
}

function* assignInstructorsApi(action) {
  try {
    const options = {
      method: "POST",
      headers: getDefaultHeaders(),
      body: JSON.stringify({
        treatmentInvolvedPatientIds: action.payload.treatmentInvolvedPatientIds,
        selectedInstructorId: action.payload.selectedInstructorId,
      }),
    };

    const url = `${API_URL}/v1/assigned-treatments/instructors`;
    const response = yield call(request, url, options);
    yield put(actions.getList({ patientId: action.payload.patientId }));
    yield put(actions.assignInstructorsSuccess());
    toastService.success(
      TRANSLATE("ASSIGNED_TREATMENT.ASSIGN_INSTRUCTOR_SUCCESS")
    );
  } catch (e: any) {
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", {
        error: JSON.stringify(e),
      })
    );
  }
  yield put(actions.assignInstructorsComplete());
}

function* getAssignedInstructorsApi(action) {
  try {
    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };

    const url = `${API_URL}/v1/assigned-treatments/instructors?treatmentInvolvedPatientIds=${action.payload}`;
    const response = yield call(request, url, options);
    yield put(actions.getAssignedInstructorsSuccess(response?.items));
  } catch (e: any) {
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", {
        error: JSON.stringify(e),
      })
    );
  }
}

export function* useAssignedTreatmentSaga() {
  yield takeLatest(actions.getList.type, getList);
  yield takeLatest(actions.getNextPage.type, getList);
  yield takeLatest(
    actions.openSelectedTreatMent.type,
    getTreatmentInfoForPatient
  );
  yield takeLatest(actions.getInstructorTimeslots.type, getInstructorTimeslots);

  yield takeLatest(actions.setTimeSlot.type, setTimeSlot);
  yield takeLatest(actions.assignInstructors.type, assignInstructorsApi);
  yield takeLatest(
    actions.getAssignedInstructors.type,
    getAssignedInstructorsApi
  );
}
