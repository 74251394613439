import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Grid, MenuItem, Select, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectUserGroups } from "store/global/fields/selector";
import { actions } from "store/global/fields/slice";
import { useStyles } from "../styles";
interface TProps {
  value: number;
  changeValue: (value: number) => void;
  disabled?: boolean;
  showAll: boolean;
  xs?: number;
}
const UserGroupsFiled = (props: TProps) => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const List = useSelector(selectUserGroups);

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(actions.getUserGroupList());
    return () => {};
  }, []);

  const handleChange = (event) => {
    props.changeValue(event.target.value);
  };

  const direction = useTheme().direction;
  return (
    <Grid item xs={props.xs ? props.xs : 2}>
      <label className={styles.label}>{t("USERS.USER_GROUP")}</label>
      <Select
        margin="dense"
        fullWidth
        disabled={props.disabled}
        variant="outlined"
        required
        autoComplete={"off"}
        value={props.value}
        className={styles.textEnabled}
        onClick={() => setDropDownOpen(props.disabled ? false : !dropDownOpen)}
        onChange={handleChange}
        open={dropDownOpen}
        IconComponent={KeyboardArrowDownIcon}
      >
        {props.showAll && (
          <MenuItem
            sx={{
              direction,
            }}
            value={"0"}
          >
            {t("COMMON.ALL")}
          </MenuItem>
        )}
        {List.items.map((item) => (
          <MenuItem
            key={item.id}
            sx={{
              direction,
            }}
            onClick={() =>
              setDropDownOpen(props.disabled ? false : !dropDownOpen)
            }
            value={item.id}
          >
            {item.userGroupName}
          </MenuItem>
        ))}
      </Select>
    </Grid>
  );
};
export default UserGroupsFiled;
