import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  Box,
  CircularProgress,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import { BankStatusOptions } from "app/containers/TreatmentBanks/types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  selectBanksLoading,
  selectBanksOptions,
} from "store/global/fields/selector";
import { actions } from "store/global/fields/slice";
import { useStyles } from "../styles";
interface TProps {
  value: number;
  departmentId: number;
  organizationId: number;
  xs?: number;
  changeValue: (value: number) => void;
  disabled?: boolean;
  showAll?: boolean;
  error?: string;
  showStatus?: boolean;
  includeStatus?: BankStatusOptions[];
  noReset?: boolean;
}

const statusSummary = {
  New: "(N)",
  Running: "(R)",
  Done: "(D)",
};
const BankFiled = (props: TProps) => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const List = useSelector(selectBanksOptions);
  const loading = useSelector(selectBanksLoading);

  const { t, i18n } = useTranslation();
  const showStatus = props.showStatus || true;
  useEffect(() => {
    if (props.showAll) {
      if (!props?.noReset) {
        props.changeValue(0);
      }
      dispatch(
        actions.getBankList({
          departmentId: props.departmentId,
          organizationId: props.organizationId,
          includeStatus: props.includeStatus || [],
        })
      );
      return;
    }
    if (props.departmentId > 0 && props.organizationId > 0 && !props.showAll) {
      dispatch(
        actions.getBankList({
          departmentId: props.departmentId,
          organizationId: props.organizationId,
          includeStatus: props.includeStatus || [],
        })
      );
      return;
    }
    dispatch(actions.getBankListSuccess([]));

    return () => {};
  }, [props.departmentId, props.organizationId, props.showAll, props?.noReset]);

  const handleChange = (event) => {
    props.changeValue(event.target.value);
  };
  const direction = useTheme().direction;

  const hasError = !!props?.error;
  return (
    <Grid item xs={props.xs ? props.xs : 2}>
      <label className={styles.label}>
        {t("TIMESLOT.BANK_NAME")}
        {Number(props.value) > 0 ? (
          <Link
            to={`/treatment-banks/treatment-bank-details/${props.value}/view`}
            target="_blank"
            style={{ textDecoration: "none" }}
            rel="noopener noreferrer"
          >
            <OpenInNewIcon fontSize="small" />
          </Link>
        ) : null}
      </label>
      <Select
        margin="dense"
        disabled={props.disabled}
        fullWidth
        variant="outlined"
        required
        autoComplete={"off"}
        value={props?.value?.toString()}
        defaultValue={"0"}
        className={styles.textEnabled}
        onClick={() => setDropDownOpen(props.disabled ? false : !dropDownOpen)}
        onChange={handleChange}
        open={dropDownOpen}
        IconComponent={KeyboardArrowDownIcon}
        MenuProps={{
          dir: i18n.dir(),
        }}
        error={hasError}
        endAdornment={
          dropDownOpen && loading ? (
            <CircularProgress className={styles.loader} />
          ) : null
        }
      >
        {props.showAll && (
          <MenuItem
            sx={{
              direction,
            }}
            value={"0"}
          >
            {t("COMMON.ALL")}
          </MenuItem>
        )}
        {List.map((item) => (
          <MenuItem
            sx={{
              direction,
            }}
            key={item.id}
            onClick={() => setDropDownOpen(!dropDownOpen)}
            value={item.id}
          >
            <Typography
              sx={{
                display: "flex",
                justifyContent: "space-between",
                whiteSpace: "nowrap",
                flex: 1,
              }}
            >
              <span
                style={{
                  marginRight: "auto",
                  overflow: "hidden",
                  textOverflow: "ellipsis",

                  flex: 8,
                }}
              >
                {" "}
                {item.name}
              </span>
              {showStatus && (
                <span
                  style={{
                    flex: 2,
                  }}
                >
                  {" "}
                  {statusSummary[item.bankStatus]}
                </span>
              )}
            </Typography>
          </MenuItem>
        ))}
      </Select>

      <Box
        sx={{
          display: "flex",
          direction,
        }}
      >
        {hasError && <FormHelperText error>{props?.error}</FormHelperText>}
      </Box>
    </Grid>
  );
};
export default React.memo(BankFiled);
