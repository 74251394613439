//import { selectPermissionGroupForm } from 'app/containers/PermissionGroups/selector';
import { Radio, TableRow } from "@mui/material";

import { actions } from "app/containers/UserGroups/redux/slice";
import { UserPermission } from "app/containers/UserGroups/types";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { TableRowCell } from "../..";

type Props = {
  noChildBorders?: boolean;
  data: UserPermission;
  index: number;
  mode: string;
};
const PermissionTableRow = ({
  mode,
  index,
  data,
  noChildBorders = false,
}: Props) => {
  const dispatch = useDispatch();

  const handleChange = (e) => {
    let newData = {
      ...data,
      noAccess: false,
      readAccess: false,
      fullAccess: false,
    };
    if (e.target.value === "NO_ACCESS") {
      newData.noAccess = true;
    } else if (e.target.value === "READ_ONLY") {
      newData.readAccess = true;
    } else if (e.target.value === "FULL_ACCESS") {
      newData.fullAccess = true;
    }
    dispatch(actions.updatePermission({ index, data: newData }));
  };
  const { t } = useTranslation();

  const isDisabled = false;
  const disabledActions: string[] = data.disabledActions || [];
  const isNoAccessDisabled = disabledActions.includes("noAccess");
  const isReadAccessDisabled = disabledActions.includes("readAccess");
  const isFullAccessDisabled = disabledActions.includes("fullAccess");
  return (
    <TableRow>
      <TableRowCell
        style={{
          color: "#2A3333",
          fontFamily: "Cairo",
          fontSize: "14px",
          fontWeight: 500,
        }}
      >
        {t(data.featureName)}
      </TableRowCell>
      <TableRowCell style={{ ...(noChildBorders && { border: "none" }) }}>
        <Radio
          name={"NO_ACCESS"}
          radioGroup="permission"
          sx={{
            p: 0,
            color: "#6C7A7A",
            "&.Mui-checked": {
              color: "#012397",
            },
          }}
          value="NO_ACCESS"
          checked={data.noAccess}
          disabled={isNoAccessDisabled || isDisabled || mode === "view"}
          onClick={handleChange}
        />
      </TableRowCell>
      <TableRowCell style={{ ...(noChildBorders && { border: "none" }) }}>
        <Radio
          name={"READ_ONLY"}
          radioGroup="permission"
          sx={{
            p: 0,
            color: "#6C7A7A",
            "&.Mui-checked": {
              color: "#012397",
            },
          }}
          value="READ_ONLY"
          disabled={isReadAccessDisabled || isDisabled || mode === "view"}
          checked={data.readAccess}
          onClick={handleChange}
        />
      </TableRowCell>
      <TableRowCell style={{ ...(noChildBorders && { border: "none" }) }}>
        <Radio
          name={"FULL_ACCESS"}
          radioGroup="permission"
          sx={{
            p: 0,
            color: "#6C7A7A",
            "&.Mui-checked": {
              color: "#012397",
            },
          }}
          value="FULL_ACCESS"
          disabled={isFullAccessDisabled || isDisabled || mode === "view"}
          checked={data.fullAccess}
          onClick={handleChange}
        />
      </TableRowCell>
    </TableRow>
  );
};
export default PermissionTableRow;
