import { Grid } from "@mui/material";
import DataTable from "app/components/DataTable";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectBonusPatientList, selectLoading } from "../redux/selector";
import { actions } from "../redux/slice";

const BonusPatientList = () => {
  const involvedPatientList = useSelector(selectBonusPatientList);
  const { mode } = useParams();

  const dispatch = useDispatch();

  const setPageQuery = (name: string, value: any) => {
    dispatch(actions.setQuery({ name, value }));
  };

  const loading = useSelector(selectLoading);

  const handleBounsEdit = (val, index) => {
    dispatch(actions.setSessionEditIndex(index));
    dispatch(actions.toggalBonusPatient());
  };
  const { t } = useTranslation();

  const handleDelete = (row, index) => {
    dispatch(actions.deleteBonusInvolvedPatient(row.patientId));
  };

  const columnsBonus = [
    {
      id: "patientName",
      label: t("TIMESLOT.PATIENT_NAME"),
      format: (val, index) => {
        return val.firstName + " " + val.lastName;
      },
    },
  ];

  return (
    <Grid item xs={3}>
      <b>{t("TIMESLOT.BONUS_PATIENTS")}:</b>
      <DataTable
        loading={loading}
        setQuery={setPageQuery}
        rows={involvedPatientList.items.filter((x) => !x.deleted)}
        columns={columnsBonus}
        Actions={"Delete"}
        editAction={handleBounsEdit}
        onRowDoubleClick={() => {}}
        isPointerCursor={true}
        deleteAction={handleDelete}
        disableActions={mode === "view" ? ["Delete"] : undefined}
      />
    </Grid>
  );
};
export default BonusPatientList;
