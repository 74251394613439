import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles(() => ({
  dataTableDiv: {
    overflow: "auto",

    overflowY: "auto",
    overflowX: "auto",
    marginTop: "0px",
    background: "#F5F8FF",
  },
  modalDropDownStyle: {
    backgroundColor: "#FFFFFF",
    marginTop: "5px",
  },
  UI: {
    border: "1px solid #C6C9CA",
  },
}));
