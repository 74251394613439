import { BorderColor, Height, Margin, Repeat } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { padding } from "@mui/system";

export const useStyles = makeStyles(() => ({

  inpMainBox: {
    display: "flex",
    border: " 1px solid red",
    position: "relative",
  },
  inputsParent: {
    width: "100%",
    marginLeft: "11px",
  },
  textAreaBox: {
    padding: "0px",
    display: "flex",
    alignItems: "center",
  },
  text1: {
    width: "97%",
  },
  text2: {
    display: "flex",

    justifyContent: "space-between",
  },
  textArea: {
    boxSizing: "border-box",
    width: "555px",
    height: "56px",
    background: "#FFFFFF",
    border: "1px solid #C6C9CA",
    borderRadius: "4px",
    paddingTop: "15px",
    paddingRight: "15px",
    "&::placeholder": {
      width: "197px",
      height: "24px",
      fontFamily: "Cairo",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",

      color: "#6C7A7A",

      flex: "none",
      order: 0,
      flexGrow: 0,
    },

    resize: "none",
  },
  bottomBox: {
    padding: "10px 3px 10px 10px",
  },

  para: {
    padding: "4px 10px",
    margin: 0,
    width: "40%",
    background: "#3D63E6",
    color: "#fff",
    borderRadius: "5px 5px 0 0",
  },
  paraTextArea: {
    resize: "none",
    width: "100%",
    height: "180px",
    borderColor: "#C8CDCD",
    borderRadius: "0 5px 5px 5px",
    outline: "none",
    padding: "10px",
    color: "grey",
    fontFamily: "Cairo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "30px",
    background: "#ffffff"
  },
  label: {
    fontFamily: "Cairo",
    fontWeight: 400,
    fontSize: "16px",
    color: "#2A3333",
  },
  textEnabled: {
    background: "#FFF",
    width: "100%",
  },
  btnSave: {
    width:'100%',
    height: '56px',
    background: "#3D63E6",
    color: "#ffff",
    "&:hover": {
      background: "transparent",
      color: "#3D63E6",
    },
    fontFamily: "Cairo",
    fontWeight: 700,
    fontSize: "16px",
    borderRadius: "5px",
    marginTop: '30px'
  },
}));
