import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles(() => ({
  wrap: {
    borderRadius: "16px !important",
    border: " 1px solid #C6C9CA",
    width: "380px",
    boxShadow:
      "0px 0px 0px 0px rgba(178, 174, 174, 0.10), 1px 2px 5px 0px rgba(178, 174, 174, 0.10), 5px 9px 10px 0px rgba(178, 174, 174, 0.09), 10px 19px 13px 0px rgba(178, 174, 174, 0.05), 18px 34px 16px 0px rgba(178, 174, 174, 0.01), 28px 54px 17px 0px rgba(178, 174, 174, 0.00)",
  },
  title: {
    fontFamily: "Cairo",
    fontSize: 18,
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "130%",
    marginRight: 30,
  },
  button: {
    cursor: "pointer",
    color: "#fff",
    textDecoration: "none",
  },
  time: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  container: {},
  header: {
    padding: 16,
    color: "#fff",
  },
  body: {},
  calProgress: {
    listStyle: "none",
    fontSize: "12pt",
  },
  calProgressItem: {
    borderLeft: "0.2em solid #F326BB",
    padding: "0 0 0.1em 1em",
    position: "relative",
    paddingBottom: 10,
  },
  calProgressDot: {
    position: "absolute",
    left: "-10px",
    top: 0,
    width: 18,
    height: 18,
  },
  calProgressCenterDot: {
    position: "absolute",
    left: "-10px",
    width: 18,
    height: 18,
    top: 6,
  },
  close: {
    flex: 1,
    color: "#fff",
    position: "absolute",
    top: 0,
    right: 0,
  },
}));
