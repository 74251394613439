import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "types";
import { PatientState } from "../types";

const selectDomain = (state?: RootState) =>
  state?.patientsListState || PatientState;

export const selectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const selectList = createSelector([selectDomain], (state) => state.list);
export const selectQuery = createSelector(
  [selectDomain],
  (state) => state.query
);
export const selectOrganization = createSelector(
  [selectDomain],
  (state) => state.organizations
);
export const selectDepartment = createSelector(
  [selectDomain],
  (state) => state.departments
);
export const selectBank = createSelector(
  [selectDomain],
  (state) => state.banks
);
export const selectPatients = createSelector(
  [selectDomain],
  (state) => state.form.patient
);

export const selectIsPatientFormBankStatusDone = createSelector(
  [selectDomain],
  (state) => {
    return state.form?.bank?.bankStatus == "Done";
  }
);

export const selectPatientCommnets = createSelector(
  [selectDomain],
  (state) => state.form.comments
);

export const selectPatientQuestionnaireResults = createSelector(
  [selectDomain],
  (state) => state.form.questionnaireResults
);

export const selectSelectedTreatment = createSelector(
  [selectDomain],
  (state) => state.selectedTreatment
);

export const selectAssignedTreatment = createSelector(
  [selectDomain],
  (state) => state.assignedTreatment
);
export const selectError = createSelector(
  [selectDomain],
  (state) => state.error
);

export const selectImportModalOpen = createSelector(
  [selectDomain],
  (state) => state.importModalOpen
);

export const selectAssignTreatmentGroupPatient = createSelector(
  [selectDomain],
  (state) => state.assignTreatmentGroupPatient
);
export const selectAssignTreatmentIndividualPatient = createSelector(
  [selectDomain],
  (state) => state.assignTreatmentIndividualPatient
);
export const selectTimeSlotModal = createSelector(
  [selectDomain],
  (state) => state.timeSlotModal
);
export const selectPopupTimeSlot = createSelector(
  [selectDomain],
  (state) => state.popupTimeSlot
);
export const selectWellBeingResults = createSelector(
  [selectDomain],
  (state) => state.form.wellBeingResults
);
