import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "types";
import { AssignedTreatmentState } from "../types";

const selectDomain = (state?: RootState) =>
  state?.assignedTreatmentState || AssignedTreatmentState;

export const selectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const selectSelectedTreatment = createSelector(
  [selectDomain],
  (state) => state.selectedTreatment
);
export const selectList = createSelector([selectDomain], (state) => state.list);
export const selectQuery = createSelector(
  [selectDomain],
  (state) => state.query
);
export const selectError = createSelector(
  [selectDomain],
  (state) => state.error
);
export const selectTimeSlotModal = createSelector(
  [selectDomain],
  (state) => state.timeSlotModal
);

export const selectAvailableTimeSlots = createSelector(
  [selectDomain],
  (state) => state.availableTimeSlots
);

export const selectRemainingTreatments = createSelector(
  [selectDomain],
  (state) => {
    const newTreatmentsCount =
      state.availableTimeSlots?.treatmentInvolvedPatient?.timeslotInvolvedPatients.filter(
        (m) => m.option == "new"
      ).length || 0;

    const deletedTreatmentsCount =
      state.availableTimeSlots?.treatmentInvolvedPatient?.timeslotInvolvedPatients.filter(
        (m) => m.option == "delete"
      ).length || 0;
    const info = state.availableTimeSlots?.info;
    const treatmentBankTreatment =
      state.availableTimeSlots?.treatmentInvolvedPatient
        ?.treatmentBankTreatment;
    const treatment = treatmentBankTreatment?.treatment;
    const remainingTreatments =
      treatment?.type == "individual"
        ? (info?.individualPerPatient || 0) +
          (info?.individualTreatmentCountInBank || 0) -
          (info?.countOfIndividualTreatment || 0)
        : (info?.groupPerPatient || 0) +
          (info?.groupTreatmentCountInBank || 0) -
          (info?.countOfGroupTreatment || 0);

    return remainingTreatments - newTreatmentsCount + deletedTreatmentsCount;
  }
);

export const selectInstructors = createSelector(
  [selectDomain],
  (state) => state.assignedInstructors
);
