import React, { useEffect } from "react";
import QuestionnaireTabs from "./components/QuestionnaireTabs";

import { Grid } from "@mui/material";
import { selectQuery } from "app/containers/Dashboard/redux/selector";
import { actions } from "app/containers/Dashboard/redux/slice";
import { useDispatch, useSelector } from "react-redux";
import useWindowDimensions from "utils/hooks/useWindowDimensions";

export default function GradeTabPanel() {
  const dispatch = useDispatch();
  const { organizationId, bankId, departmentId, q } = useSelector(selectQuery);
  useEffect(() => {
    dispatch(actions.getListGrades());
  }, [organizationId, bankId, departmentId, q]);
  const { height } = useWindowDimensions();

  return (
    <Grid
      sx={{
        background: "#fff",
        borderRadius: 3,
        width: "100%",
        height: `${height - 250}px`,
        overflow: "auto",
      }}
    >
      <QuestionnaireTabs key={"questioner"} />
    </Grid>
  );
}
