import axios from "axios";
import queryString from "query-string";
import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { API_URL } from "utils/constants";
import {
  TRANSLATE,
  getDefaultHeaders,
  removeBlankProperties,
  safeParseJSON,
} from "utils/helpers";
import { request } from "utils/request";
import toastService from "utils/toast";
import { selectList, selectQuery } from "./selector";
import { actions } from "./slice";

export function* getList() {
  yield delay(500);
  try {
    const query = yield select(selectQuery);
    const requestData = removeBlankProperties(query);
    const queries = queryString.stringify({
      ...requestData,
    });
    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };

    let url = `${API_URL}/v1/treatments-bank?${queries}`;
    const response = yield call(request, url, options);
    yield put(actions.getTreatmentBankListSuccess(response));
  } catch (e: any) {
    yield put(actions.setLoading(false));
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", {
        error: JSON.stringify(e),
      })
    );
  }
}

function* getTreatmentBankTreatmentList(action) {
  yield delay(500);
  try {
    const query = yield select(selectQuery);
    const { treatmentBankId } = action.payload;
    const requestData = removeBlankProperties(query);
    const queries = queryString.stringify({
      ...requestData,
    });
    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };

    const url = `${API_URL}/v1/treatments-bank/treatment-type/${treatmentBankId}?${queries}`;
    const response = yield call(request, url, options);
    yield put(actions.setTreatmentBankTreatmentItems(response));
  } catch (e: any) {
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", {
        error: JSON.stringify(e),
      })
    );
  }
}

function* addTreatmentBank(action) {
  yield delay(500);
  try {
    const {
      assignedDate,
      organizationName,
      departmentName,
      bankName,
      bankStatus,
      individualTreatmentsPerPatient,
      groupTreatmentsPerPatient,
      totalPatients,
      treatmentBankAsset,
    } = action.payload;
    const newTreatmentBankRow = {
      assigned_date: assignedDate,
      organization_name: organizationName,
      department_name: departmentName,
      bank_name: bankName,
      bank_status: bankStatus,
      individual_treatment_type: "",
      group_treatment_type: "",
      individual_per_patient: individualTreatmentsPerPatient,
      group_per_patient: groupTreatmentsPerPatient,
      total_patients: totalPatients,
      treatmentBankAsset,
    };

    const options = {
      method: "POST",
      headers: getDefaultHeaders(),
      body: JSON.stringify(newTreatmentBankRow),
    };

    const url = `${API_URL}/v1/treatments-bank`;
    const response = yield call(request, url, options);
  } catch (e) {
    console.log(e);
  }
}

function* saveTreatmentBankWithTreatments(action) {
  yield delay(500);
  try {
    const list = yield select(selectList);
    const { treatmentBankDetails, treatmentBankId, callback } = action.payload;

    if (treatmentBankId != null) {
      const {
        assignedDate,
        organizationId,
        departmentId,
        bankName,
        bankStatus,
        indPerPatient: individualTreatmentsPerPatient,
        groupPerPatient: groupTreatmentsPerPatient,
        totalPatient: totalPatients,
        treatmentBankAsset,
      } = treatmentBankDetails;
      const toUpdateObj = {
        assignedDate,
        organizationId: organizationId,
        departmentId: departmentId,
        name: bankName,
        status: bankStatus,
        individualPerPatient: individualTreatmentsPerPatient,
        groupPerPatient: groupTreatmentsPerPatient,
        totalPatientCapacity: totalPatients,
        treatmentBankAsset,
        treatments: list.editItems,
      };
      const options = {
        method: "PATCH",
        headers: getDefaultHeaders(),
        body: JSON.stringify(toUpdateObj),
      };

      const url = `${API_URL}/v1/treatments-bank/${treatmentBankId}`;
      const response = yield call(request, url, options);
      yield put(actions.getTreatmentBankList(response));

      callback();
    } else {
      const {
        assignedDate,
        organizationId,
        departmentId,
        bankName,
        bankStatus,
        indPerPatient: individualTreatmentsPerPatient,
        groupPerPatient: groupTreatmentsPerPatient,
        totalPatient: totalPatients,
        treatmentBankAsset,
      } = treatmentBankDetails;
      const newTreatmentBankRow = {
        assignedDate,
        organizationId: organizationId,
        departmentId: departmentId,
        name: bankName,
        status: bankStatus,
        individualPerPatient: individualTreatmentsPerPatient,
        groupPerPatient: groupTreatmentsPerPatient,
        totalPatientCapacity: totalPatients,
        treatmentBankAsset,
        treatments: list.editItems,
      };

      const options = {
        method: "POST",
        headers: getDefaultHeaders(),
        body: JSON.stringify(newTreatmentBankRow),
      };

      const url = `${API_URL}/v1/treatments-bank`;
      const response = yield call(request, url, options);

      // for (const item of list.editItems) {
      //   if (item.action === "add") {
      //     yield put(
      //       actions.saveNewBankTreatment({
      //         ...item,
      //         treatmentBankId,
      //       })
      //     );
      //   }
      // }

      callback();
    }
  } catch (e) {
    const error = e as Error;
    const { message } = safeParseJSON(error.message);
    yield put(actions.saveTreatmentBankWithTreatmentsFailure(message));
    toastService.error(TRANSLATE(message));
  }
}

export function* deleteTreatmentBank(action) {
  yield delay(500);
  try {
    const treatmentBankId = action.payload.id;
    const options = {
      method: "DELETE",
      headers: getDefaultHeaders(),
    };
    const url = `${API_URL}/v1/treatments-bank/${treatmentBankId}`;
    const response = yield call(request, url, options);
    yield put(actions.getTreatmentBankList());
    toastService.success(
      TRANSLATE("TREATMENT_BANKS.TREATMENT_BANK_DELETED_SUCCESS")
    );
  } catch (e) {
    console.log(e);
    yield put(actions.setLoading(false));
    toastService.error(
      TRANSLATE("TREATMENT_BANKS.ERROR_IN_DELETING_TREATMENT_BANK")
    );
  }
}

export function* getTreatmentTypeOptions(action) {
  yield delay(500);
  try {
    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    // need to make it optional from now
    const queries = queryString.stringify({
      limit: 100,
      page: 1,
    });
    const response = yield call(
      request,
      `${API_URL}/v1/treatment?${queries}`,
      options
    );
    yield put(actions.setTreatmentTypeOptions(response));
  } catch (e) {
    console.log(e);
  }
}
export function* getOptionInstructorOptions(action) {
  yield delay(500);
  try {
    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    // need to make it optional from now
    const queries = queryString.stringify({
      limit: 100,
      page: 1,
    });
    const response = yield call(
      request,
      `${API_URL}/v1/users/get-instructor-by-treatment/${action.payload}?${queries}`,
      options
    );
    yield put(actions.setOptiionInstructorOptions(response));
  } catch (e) {
    console.log(e);
  }
}
export function* deleteBankTreatment(action) {
  yield delay(500);
  try {
    const bankTreatmentId = action.payload.id;
    const options = {
      method: "DELETE",
      headers: getDefaultHeaders(),
    };
    const url = `${API_URL}/v1/treatments-bank/treatment-type/${bankTreatmentId}`;
    const response = yield call(request, url, options);
    // yield put(actions.getTreatmentBankTreatmentList());
  } catch (e) {
    console.log(e);
  }
}

export function* saveNewBankTreatment(action) {
  yield delay(500);
  try {
    const newTreatmentBankTreatmentRow = {
      ...action.payload,
    };
    const options = {
      method: "POST",
      headers: getDefaultHeaders(),
      body: JSON.stringify(newTreatmentBankTreatmentRow),
    };

    const url = `${API_URL}/v1/treatments-bank/treatment-type`;
    const response = yield call(request, url, options);
  } catch (e) {
    console.log(e);
  }
}

export function* uploadImage(action) {
  yield delay(500);
  try {
    const file = action.payload.file;
    const { name: fileName, type: fileType, size: fileSize } = file;
    const reqData = {
      fileName: fileName,
      contentType: fileType,
    };
    const options = {
      method: "POST",
      headers: getDefaultHeaders(),
      body: JSON.stringify(reqData),
    };
    const response = yield call(
      request,
      `${API_URL}/v1/files/presigned-url`,
      options
    );

    const { signedUrl, fileNameWithPrefix } = response;
    const uploadResponse = yield call(axios.put, signedUrl, file, {
      headers: {
        "Content-Type": action.payload.type,
      },
    });
    toastService.success(
      TRANSLATE("TREATMENT_BANKS.FILE_UPLOADED_SUCCESSFULLY")
    );
    yield put(
      actions.setUploadImageSuccess({
        fileName,
        fileNameWithPrefix,
        fileSize,
        fileType,
      })
    );
  } catch (e) {
    console.log(e);
    toastService.error(TRANSLATE("TREATMENT_BANKS.ERROR_IN_UPLOADING_FILE"));
  }
}
function* createTreatmentBankTreatmentSaga(action) {
  yield delay(500);
  try {
    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    const response = yield call(
      request,
      `${API_URL}/v1/treatment/get/${action.payload.treatmentId}`,
      options
    );
    action.payload.treatment = response;
    yield put(actions.setTreatmentBankTreatment(action.payload));
    yield put(actions.resettreatmentBankDetailsEditDetails());
  } catch (e: any) {
    yield put(actions.setTreatmentFailure(e));
    const error = JSON.parse(e.message);
    console.error(e);
  }
}

export function* getTreatmentBankById(action) {
  yield delay(500);
  try {
    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    const response = yield call(
      request,
      `${API_URL}/v1/treatments-bank/${action.payload}`,
      options
    );
    yield put(actions.setTreatmentBankById(response));
  } catch (e: any) {
    yield put(actions.setTreatmentFailure(e));
    const error = JSON.parse(e.message);
    console.error(e);
  }
}

function* deleteTreatmentBankApi(action) {
  yield delay(500);
  try {
    const options = {
      method: "DELETE",
      headers: getDefaultHeaders(),
    };
    yield call(
      request,
      `${API_URL}/v1/treatments-bank/${action.payload?.id}`,
      options
    );
    yield put(actions.getTreatmentBankList());
    toastService.success(
      TRANSLATE("TREATMENT_BANKS.TREATMENT_BANK_DELETED_SUCCESS")
    );
    action.payload?.callback();
  } catch (e: any) {
    let error = e.message;
    try {
      error = JSON.parse(error);
    } catch (error) {
      console.error(error);
    }
    toastService.error(
      TRANSLATE("TREATMENT_BANKS.ERROR_IN_DELETING_TREATMENT_BANK")
    );
  }
  yield put(actions.deleteTreatmentBankRowComplete());
}

function* startDailyContentApi(action) {
  yield delay(500);
  try {
    const options = {
      method: "POST",
      headers: getDefaultHeaders(),
    };
    const res = yield call(
      request,
      `${API_URL}/v1/treatments-bank/${action.payload?.id}/activate-daily`,
      options
    );
    yield put(
      actions.startDailyContentSuccess({
        dailyActivityStartedAt: res?.dailyActivityStartedAt,
      })
    );
    toastService.success(
      TRANSLATE("TREATMENT_BANKS.TREATMENT_BANK_ACTIVATION_SUCCESS")
    );
    action.payload?.callback();
  } catch (e: any) {
    let error = e.message;
    try {
      error = JSON.parse(error);
    } catch (error) {
      console.error(error);
    }
    toastService.error(
      TRANSLATE("TREATMENT_BANKS.TREATMENT_BANK_ACTIVATION_FAILED")
    );
  }
  yield put(actions.deleteTreatmentBankRowComplete());
}

export function* useTreatmentBankSaga() {
  yield takeLatest(actions.getNextPage.type, getList);
  yield takeLatest(actions.getTreatmentBankList.type, getList);
  yield takeLatest(
    actions.getTreatmentBankTreatmentList.type,
    getTreatmentBankTreatmentList
  );
  yield takeLatest(actions.addTreatmentBankRow.type, addTreatmentBank);

  yield takeLatest(
    actions.getTreatmentTypeOptions.type,
    getTreatmentTypeOptions
  );
  yield takeLatest(
    actions.getOptionInstructorOptions.type,
    getOptionInstructorOptions
  );

  yield takeLatest(
    actions.saveTreatmentBankWithTreatments.type,
    saveTreatmentBankWithTreatments
  );
  yield takeLatest(actions.deleteBankTreatment.type, deleteBankTreatment);
  yield takeLatest(actions.deleteTreatmentBankRow.type, deleteTreatmentBankApi);
  yield takeLatest(actions.startDailyContent.type, startDailyContentApi);
  yield takeLatest(actions.saveNewBankTreatment.type, saveNewBankTreatment);
  yield takeLatest(actions.uploadImage.type, uploadImage);
  yield takeLatest(
    actions.createTreatmentBankTreatment.type,
    createTreatmentBankTreatmentSaga
  );
  yield takeLatest(actions.getTreatmentBankById.type, getTreatmentBankById);
}
