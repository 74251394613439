import { Button, Card, Grid, Modal, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";

export default function ConfirmDialog({
  open,
  onClose,
  title,
  description,
  onConfirm,
  onCancel,
  confirmBtnText,
}: {
  open: boolean;
  onClose: () => void;
  title: string;
  description: string;
  onConfirm: () => void;
  onCancel: () => void;
  confirmBtnText?: string;
}) {
  const styles = useStyles();
  const { t, i18n } = useTranslation();
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card
        className={styles.deleteModal}
        style={{
          direction: i18n.dir(),
        }}
      >
        <div className={styles.modalContainer}>
          <Grid>
            <Typography align="center" className={styles.headerModal}>
              {title}
            </Typography>
          </Grid>
          <Grid sx={{ padding: "30px 0px" }}>
            <Typography align="center" className={styles.deleteTypo}>
              {description}
            </Typography>
          </Grid>
          <div>
            <Grid container spacing={3}>
              <Grid
                className={styles.btnContainer}
                container
                item
                xs={6}
                direction="column"
              >
                <Button
                  className={styles.cancelBtn}
                  variant="outlined"
                  onClick={onCancel}
                >
                  {t("BUTTONS.CANCEL")}
                </Button>
              </Grid>
              <Grid container item xs={6} direction="column">
                <Button
                  className={styles.saveBtn}
                  variant="contained"
                  onClick={onConfirm}
                >
                  {confirmBtnText ?? t("BUTTONS.DELETE")}
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </Card>
    </Modal>
  );
}
