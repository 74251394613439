import queryString from "query-string";
import { delay, put, select, takeLatest } from "redux-saga/effects";
import { API_URL } from "utils/constants";
import {
  TRANSLATE,
  getDefaultHeaders,
  removeBlankProperties,
  safeParseJSON,
} from "utils/helpers";
import { request } from "utils/request";
import toastService from "utils/toast";
import { selectForm, selectQuery } from "./selector";
import { actions } from "./slice";

export function* update(action) {
  yield delay(500);
  const form = yield select(selectForm);
  if (form.groupName === "") {
    throw Error('{"message":"USER_GROUPS.GROUP_NAME_REQUIRED"}');
  }
  const newUserGroup = form;
  try {
    const options = {
      method: "PATCH",
      headers: getDefaultHeaders(),
      body: JSON.stringify(newUserGroup),
    };
    const url = `${API_URL}/v1/user-groups/${action.payload.id}`;
    yield request(url, options);
    toastService.success(TRANSLATE("USER_GROUPS.UPDATED_SUCCESSFULLY"));
    yield put(actions.getList());
    action.payload.navigate("/user-groups");
  } catch (e) {
    const error = e as Error;
    const { message } = safeParseJSON(error.message);
    yield put(actions.updateFailed(message));
    toastService.error(
      TRANSLATE(message, {
        defaultValue: "Error in User Group",
      })
    );
  }
}

export function* create(action) {
  yield delay(500);
  try {
    const form = yield select(selectForm);
    if (form.groupName === "") {
      throw Error('{"message":"USER_GROUPS.GROUP_NAME_REQUIRED"}');
    }
    const newUserGroup = form;
    const requestOptions = {
      method: "POST",
      headers: getDefaultHeaders(),
      body: JSON.stringify(newUserGroup),
    };
    const url = `${API_URL}/v1/user-groups`;
    yield request(url, requestOptions);
    toastService.success(TRANSLATE("USER_GROUPS.CREATED_SUCCESSFULLY"));
    yield put(actions.getList());
    action.payload.navigate("/user-groups");
  } catch (e) {
    const error = e as Error;
    const { message } = safeParseJSON(error.message);
    yield put(actions.createFailed(message));
    toastService.error(
      TRANSLATE(message, {
        defaultValue: "Error in User Group",
      })
    );
  }
}
export function* getList(action) {
  yield delay(500);
  const query = yield select(selectQuery);
  const requestData = removeBlankProperties(query);
  const queries = queryString.stringify({
    ...requestData,
  });
  try {
    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    const response = yield request(
      `${API_URL}/v1/user-groups?${queries}`,
      options
    );
    yield put(actions.getListSuccess(response));
  } catch (e: any) {
    yield put(actions.setLoading(false));
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", {
        error: JSON.stringify(e),
      })
    );
  }
}
export function* getDataById(action) {
  yield delay(500);
  try {
    if (!action.payload?.id) {
      return;
    }
    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    const response = yield request(
      `${API_URL}/v1/user-groups/${action.payload.id}`,
      options
    );
    yield put(actions.getDataByIdSuccess(response));
  } catch (e: any) {
    yield put(actions.setLoading(false));
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", {
        error: JSON.stringify(e),
      })
    );
  }
}

export function* deleteRow(action) {
  yield delay(500);
  try {
    const id = action.payload.id;
    const requestOptions = {
      method: "DELETE",
      headers: getDefaultHeaders(),
    };
    const url = `${API_URL}/v1/user-groups/${id}`;
    yield request(url, requestOptions);
    yield put(actions.getList());
    toastService.success(TRANSLATE("USER_GROUPS.DELETED_SUCCESSFULLY"));
    action.payload.navigate("/user-groups");
  } catch (e: any) {
    toastService.error(
      TRANSLATE("USER_GROUPS.ERRORS.CANNOT_DELETE", {
        defaultValue: "Error in User Group",
      })
    );
    yield put(actions.deleteFailed(e.message));
  }
}

export function* useUsersGroupListSaga() {
  yield takeLatest(actions.getList.type, getList);
  yield takeLatest(actions.getDataById.type, getDataById);
  yield takeLatest(actions.getNextPage.type, getList);
  yield takeLatest(actions.create.type, create);
  yield takeLatest(actions.update.type, update);
  yield takeLatest(actions.delete.type, deleteRow);
}
