import { FormError } from "app/containers/types";
import { TRANSLATE } from "utils/helpers";

export const onValidate = (form: any) => {
  const errors: FormError[] = [];
  if (form.treatmentName.value.length === 0) {
    errors.push({
      name: "treatmentName",
      error: TRANSLATE("TREATMENT_TYPES.TREATMENT_NAME_REQUIRED"),
    });
  }

  if (form.sessionLength.value.length === 0) {
    errors.push({
      name: "sessionLength",
      error: TRANSLATE("TREATMENT_TYPES.SESSION_LENGTH_REQUIRED"),
    });
  }

  if (form.participantType.value.length === 0) {
    errors.push({
      name: "participantType",
      error: TRANSLATE("TREATMENT_TYPES.PARTICIPANT_TYPE_REQUIRED"),
    });
  }

  if (form.noOfSession.value.length === 0) {
    errors.push({
      name: "noOfSession",
      error: TRANSLATE("TREATMENT_TYPES.NO_OF_SESSIONS_REQUIRED"),
    });
  }

  if (form.treatmentDescription.value.length === 0) {
    errors.push({
      name: "treatmentDescription",
      error: TRANSLATE("TREATMENT_TYPES.DESCRIPTION_REQUIRED"),
    });
  }

  if (form.noOfSession.value > 10) {
    errors.push({
      name: "noOfSession",
      error: TRANSLATE("TREATMENT_TYPES.MAX_SESSIONS_EXCEEDED"),
    });
  }

  if (form.participantType.value === "group" && form.participants.value <= 1) {
    errors.push({
      name: "participants",
      error: TRANSLATE("TREATMENT_TYPES.MIN_PARTICIPANTS_REQUIRED"),
    });
  }
  return errors;
};
