import { Grid } from "@mui/material";
import { BottomActions } from "app/components/BottomActions";
import { CustomDrawer } from "app/components/Drawer";
import { NoPermission } from "app/components/NoPermission";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { selectFeaturePermissions } from "store/global/authPermission/selector";
import { PermissionFeature } from "store/global/authPermission/types";
import useQueryRefNav from "utils/user-ref-nav-query";
import Topbar from "../Dashboard/components/topbar";
import FiltersForm from "./components/FiltersForm";
import List from "./components/List";
import { useTreatmentBankSaga } from "./redux/saga";
import { selectList, selectQuery } from "./redux/selector";
import { actions, reducer, sliceKey } from "./redux/slice";
import { useStyles } from "./styles";

const TreatmentBanks = () => {
  useInjectReducer({ key: sliceKey, reducer });
  useInjectSaga({ key: sliceKey, saga: useTreatmentBankSaga });
  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isResetRequired = useQueryRefNav();
  const handleAdd = () => {
    navigate("/treatment-banks/treatment-bank-details");
  };

  const featurePermissions = useSelector(selectFeaturePermissions);
  const { hasFullAccess, hasReadAccess, hasNoAccess } =
    featurePermissions[PermissionFeature.TREATMENT_BANK_LIST];

  const { t } = useTranslation();
  const { meta } = useSelector(selectList);
  const { q } = useSelector(selectQuery);

  const setPageQuery = (name: string, value: any) => {
    dispatch(actions.setQuery({ name, value }));
  };

  if (hasNoAccess) {
    return (
      <div className={styles.mainContainer}>
        <CustomDrawer />
        <Topbar />
        <NoPermission />
      </div>
    );
  }

  return (
    <div className={styles.mainContainer}>
      <CustomDrawer />
      <Grid item xs={12}>
        <Topbar
          q={q}
          setSearch={(value) => {
            setPageQuery("q", value);
          }}
        />
        <div className={styles.bodyContainer}>
          <FiltersForm isResetRequired={isResetRequired} />
          <List />
          <BottomActions
            disabled={!hasFullAccess}
            chipBoxes={[
              {
                label: t("PLACEHOLDERS.TOTAL_ITEMS"),
                count: meta.totalItems,
              },
            ]}
            onClickFab={handleAdd}
          />
        </div>
      </Grid>
    </div>
  );
};

export default TreatmentBanks;
