import queryString from "query-string";
import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { API_URL } from "utils/constants";
import {
  TRANSLATE,
  getDefaultHeaders,
  removeBlankProperties,
  safeParseJSON,
} from "utils/helpers";
import { request } from "utils/request";
import toastService from "utils/toast";
import { CalenderListItemType } from "../types";
import { selectEditUser, selectQuery } from "./selector";
import { actions } from "./slice";

export function* update(action) {
  yield delay(500);
  const { firstName, lastName, email, user_type, dob } = yield select(
    selectEditUser
  );
  let user: any = {
    firstName: firstName.value,
    lastName: lastName.value,
    email: email.value,
    user_type: user_type.value,
    dob: dob.value,
  };
  try {
    const options = {
      method: "PUT",
      headers: getDefaultHeaders(),
      body: JSON.stringify(user),
    };
    const response: CalenderListItemType = yield request(
      `${API_URL}/users/${action?.payload.id}`,
      options
    );
    toastService.success(TRANSLATE("USERS.DATA_UPDATED_SUCCESSFULLY"));
    yield put(actions.updateSuccess(response));
    yield call(action?.payload?.callback);
  } catch (e: any) {
    const error = e as Error;
    const { message } = safeParseJSON(error.message);
    yield put(actions.updateFailed(message));
    toastService.error(
      TRANSLATE(message, {
        defaultValue: "Error in Organization",
      })
    );
  }
}

export function* create() {
  yield delay(500);
}
export function* getList(action) {
  yield delay(500);
  const query = yield select(selectQuery);
  const requestData = removeBlankProperties(query);
  const queries = queryString.stringify({
    ...requestData,
  });
  try {
    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    const response = yield request(
      `${API_URL}/v1/timeslot?${queries}`,
      options
    );

    yield put(actions.getListSuccess(response));
  } catch (e: any) {
    console.log({ e });
    yield put(actions.setLoading(false));
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", {
        error: JSON.stringify(e),
      })
    );
  }
}
export function* getTimeslotPatientList(action) {
  yield delay(500);

  try {
    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    const response = yield request(
      `${API_URL}/v1/timeslot/getPatient/${action.payload}`,
      options
    );

    yield put(actions.setTimeslotPatientList(response));
  } catch (e: any) {
    console.log({ e });
    yield put(actions.setLoading(false));
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", {
        error: JSON.stringify(e),
      })
    );
  }
}

export function* updateTimeslotPatientAttendance(action) {
  yield delay(500);

  try {
    const options = {
      method: "PATCH",
      headers: getDefaultHeaders(),
    };

    const queries = queryString.stringify({
      ...action.payload,
    });

    const response = yield request(
      `${API_URL}/v1/timeslot/patient-attendance?${queries}`,
      options
    );
    yield put(actions.updateTimeslotPatientAttendanceSuccess(action.payload));
  } catch (e: any) {
    console.log({ e });
    yield put(actions.updateTimeslotPatientAttendanceFailure(action.payload));
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", {
        error: JSON.stringify(e),
      })
    );
  }
}
export function* deleteRow() {}
export function* useUserSaga() {
  yield takeLatest(actions.getList.type, getList);
  yield takeLatest(actions.getNextPage.type, getList);
  yield takeLatest(actions.create.type, create);
  yield takeLatest(actions.update.type, update);
  yield takeLatest(actions.delete.type, deleteRow);
  yield takeLatest(actions.getTimeslotPatientList.type, getTimeslotPatientList);
  yield takeLatest(
    actions.updateTimeslotPatientAttendance.type,
    updateTimeslotPatientAttendance
  );
}
