import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "types";
import { TreatmentBankState } from "../types";

const selectDomain = (state?: RootState) =>
  state?.treatmentBankState || TreatmentBankState;

export const selectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const selectList = createSelector([selectDomain], (state) => state.list);
export const selectEditTreatmentBankId = createSelector(
  [selectDomain],
  (state) => state.list.editItemId
);
export const selectQuery = createSelector(
  [selectDomain],
  (state) => state.query
);
export const selectError = createSelector(
  [selectDomain],
  (state) => state.error
);
export const selectOrganizationOpen = createSelector(
  [selectDomain],
  (state) => state.organizationOpen
);
export const selectDepartmentOpen = createSelector(
  [selectDomain],
  (state) => state.departmentOpen
);
export const selectOrganization = createSelector(
  [selectDomain],
  (state) => state.organization
);
export const selectDepartment = createSelector(
  [selectDomain],
  (state) => state.department
);
//export const selectModalName = createSelector([selectDomain],(state) => state.treatmentBankDetailsEdit.modalName);
export const selectModalName = createSelector(
  [selectDomain],
  (state) => state.modalName
);

export const selectTreatmentBankAvailableStatus = createSelector(
  [selectDomain],
  (state) => state.availableBankStatus
);

export const selectDepartmentOptions = createSelector(
  [selectDomain],
  (state) => state.departments
);

export const selectOrganizationOptions = createSelector(
  [selectDomain],
  (state) => state.organizations
);

export const selectTreatmentTypesOptions = createSelector(
  [selectDomain],
  (state) => state.treatmentTypes
);
export const selectOptionalInstructorOptions = createSelector(
  [selectDomain],
  (state) => state.optionalTypes
);

export const selectTreatmentBankDetailsEdited = createSelector(
  [selectDomain],
  (state) => state.treatmentBankDetailsEditDetails
);

export const selectIsTreatmentBankDetailsInViewMode = createSelector(
  [selectDomain],
  (state) => state.isTreatmentBankDetailsInViewMode
);

export const selectUploadedFileDetails = createSelector(
  [selectDomain],
  (state) => state.list.editItemRowDetails.fileDetails
);
export const selectForm = createSelector([selectDomain], (state) => state.from);
