import {
  Add,
  Delete,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from "@mui/icons-material";
import { Button, Fab, Grid, MenuItem, Select, TextField } from "@mui/material";
import BreadCrumb from "app/components/BreadCrumb";
import { ActionButtons } from "app/components/Button/ActionButtons";
import ConfirmDialog from "app/components/ConfirmDialog";
import { CustomDrawer } from "app/components/Drawer";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { selectFeaturePermissions } from "store/global/authPermission/selector";
import { PermissionFeature } from "store/global/authPermission/types";
import { translatedBreadCrumb } from "styles/theme/utils";
import toastService from "utils/toast";
import Topbar from "../../../Dashboard/components/topbar";
import { validate } from "../../open-questioner.validator";
import { useQuestionnaireSaga } from "../../redux/saga";
import {
  selectCurrentQuestion,
  selectCurrentQuestionIndex,
  selectForm,
  selectLoading,
  selectQuestions,
} from "../../redux/selector";
import { actions, reducer, sliceKey } from "../../redux/slice";
import { useStyles } from "./styles";

const AddOpenQuestionnaires = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const params = useLocation();
  const mode = params.search.split("=")[1];
  const [openDelete, setOpenDelete] = useState(false);

  const { t, i18n } = useTranslation();
  useInjectReducer({ key: sliceKey, reducer });
  useInjectSaga({ key: sliceKey, saga: useQuestionnaireSaga });

  const { id } = useParams();
  const dispatch = useDispatch();

  const form = useSelector(selectForm);
  const questionnaire = form.questionnaire;
  const currentQuestionIndex = useSelector(selectCurrentQuestionIndex);

  const currentQuestion = useSelector(selectCurrentQuestion);
  const questions = useSelector(selectQuestions);
  const loading = useSelector(selectLoading);
  useEffect(() => {
    if (mode === "add") {
      dispatch(actions.resetQuestionnaire());
      dispatch(actions.addQuestion({ type: "open" }));
    } else if (Number(id) > 0) {
      dispatch(actions.getDataById({ id: id }));
    }
  }, [id]);

  const handleDeleteClose = () => {
    setOpenDelete(false);
  };

  const handleDeleteSave = () => {
    setOpenDelete(false);
    dispatch(
      actions.delete({
        id: id,
        navigate,
      })
    );
  };

  const featurePermissions = useSelector(selectFeaturePermissions);
  const { hasFullAccess, hasReadAccess, hasNoAccess } =
    featurePermissions[PermissionFeature.QUESTIONNAIRES];

  const handleDeleteOpen = () => {
    setOpenDelete(true);
  };

  const handleSave = () => {
    const errors = validate(form);
    if (errors.length) {
      dispatch(actions.setFormErrors({ errors }));
      return toastService.error(
        t("QUESTIONNAIRES.FORM.ERRORS.FIX_ERRORS_FIRST")
      );
    } else {
      dispatch(actions.clearFormErrors());
    }

    if (mode === "add") {
      dispatch(
        actions.createOpenQuestionnaire({
          navigate: navigate,
        })
      );
    } else {
      dispatch(
        actions.updateOpen({
          id: id,
          navigate: navigate,
        })
      );
    }
  };

  const renderDeleteButton = () => {
    if (mode !== "add") {
      return (
        <Button
          className={styles.deletePatientBtn}
          variant="outlined"
          disabled={mode === "view"}
          onClick={handleDeleteOpen}
        >
          <Delete
            sx={{
              color: mode === "view" ? "#C6C9CA" : "#FF4839",
              marginRight: "10px",
            }}
          />{" "}
          {t("QUESTIONNAIRES.DELETE_QUESTIONNAIRE")}
        </Button>
      );
    }
    return null;
  };

  return (
    <div className={styles.bg_color}>
      <CustomDrawer />
      <Grid item xs={12}>
        <Topbar />
        <div className={styles.right}>
          <Grid container>
            <Grid container item xs={9.5} direction="column">
              <BreadCrumb
                subTitle={t(`QUESTIONNAIRES.${translatedBreadCrumb(mode)}`)}
                onBackClick={() => navigate("/questionnaires")}
                title={t("QUESTIONNAIRES.QUESTIONNAIRES")}
              />
            </Grid>
            <Grid container item xs={2.5} direction="column" marginTop="40px">
              {renderDeleteButton()}
            </Grid>
          </Grid>

          <div>
            <Grid
              container
              spacing={1}
              sx={{
                margin: "0px 0px 10px 0px",
              }}
            >
              <Grid item xs={2.5}>
                <span className={styles.label}>
                  {t("QUESTIONNAIRES.QUESTIONNAIRE_NAME")}
                </span>
                <TextField
                  margin="none"
                  variant="outlined"
                  fullWidth
                  required
                  id="questionName"
                  placeholder={t("QUESTIONNAIRES.ENTER_QUESTIONNAIRE_NAME")}
                  name="questionName"
                  autoComplete={"off"}
                  value={questionnaire.name}
                  error={!!questionnaire?.errors?.name}
                  helperText={
                    !!questionnaire?.errors?.name
                      ? t(`${questionnaire?.errors?.name}`)
                      : ""
                  }
                  FormHelperTextProps={{
                    style: {
                      textAlign: i18n.dir() === "ltr" ? "left" : "right",
                    },
                  }}
                  onChange={(e) => {
                    dispatch(
                      actions.updateFormErrors({
                        name: "questionnaire.errors.name",
                        error: "",
                      })
                    );
                    dispatch(
                      actions.setFormValue({
                        name: "questionnaire.name",
                        value: e.target.value,
                      })
                    );
                  }}
                  disabled={mode === "view"}
                  className={styles.textEnabled}
                />
              </Grid>
              <Grid item xs={2.5}>
                <label className={styles.label}>
                  {t("QUESTIONNAIRES.QUESTION_NUMBER")} (
                  {t("QUESTIONNAIRES.OUT_OF", {
                    current: currentQuestion.questionNumber,
                    total: +questions.length,
                  })}
                  )
                </label>
                <Select
                  disabled={mode == "view"}
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  required
                  autoComplete={"off"}
                  value={currentQuestionIndex}
                  className={styles.textEnabled}
                  onChange={(e) => {
                    dispatch(
                      actions.setCurrentQuestionIndex({
                        index: Number(e.target.value),
                      })
                    );
                  }}
                  IconComponent={KeyboardArrowDownIcon}
                >
                  {questions.map((value, index) => {
                    return (
                      <MenuItem key={index} value={`${index}`}>
                        {value.questionNumber}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
            </Grid>
            <Grid>
              <label className={styles.question_body_text_box}>
                <span className={styles.question_text}>
                  {t("QUESTIONNAIRES.QUESTION_BODY")}
                </span>
              </label>
              <TextField
                rows={5}
                multiline
                type="text"
                disabled={mode === "view"}
                value={currentQuestion.question}
                onChange={(e) => {
                  dispatch(
                    actions.setQuestionData({
                      value: e.target.value,
                    })
                  );
                }}
                placeholder={t("QUESTIONNAIRES.QUESTION_BODY")}
                className={styles.question_text_area}
                error={!!currentQuestion?.errors?.question}
                helperText={
                  !!currentQuestion?.errors?.question
                    ? currentQuestion?.errors?.question
                    : ""
                }
                FormHelperTextProps={{
                  style: {
                    textAlign: i18n.dir() === "ltr" ? "left" : "right",
                  },
                }}
              />
            </Grid>
          </div>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              paddingTop: "10px",
            }}
          >
            <span className={styles.addQues}>
              {t("QUESTIONNAIRES.ADD_QUESTIONS")}
            </span>
            <Fab
              size="medium"
              color="secondary"
              aria-label="add"
              disabled={mode === "view"}
              sx={{ marginTop: 2, marginBottom: 2 }}
              onClick={() => {
                if (questions.length < 25) {
                  dispatch(actions.addQuestion({ type: "open" }));
                } else {
                  return toastService.error(
                    t("QUESTIONNAIRES.FORM.ERRORS.MAX_QUESTIONS_REACHED", {
                      value: 25,
                    })
                  );
                }
              }}
            >
              <Add />
            </Fab>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              paddingTop: "10px",
            }}
          >
            <div className={styles.btnContainer1}>
              {mode === "view" ? (
                hasFullAccess && (
                  <Grid>
                    <Button
                      className={styles.saveBtn}
                      variant="contained"
                      onClick={() =>
                        navigate(`/questionnaires/open/${id}?mode=edit`)
                      }
                    >
                      {t("BUTTONS.EDIT")}
                    </Button>
                  </Grid>
                )
              ) : (
                <ActionButtons
                  onCancel={() => navigate("/questionnaires?type=open")}
                  onSave={handleSave}
                  loading={loading}
                />
              )}
            </div>
          </Grid>
          <ConfirmDialog
            title={t("QUESTIONNAIRES.DELETE_QUESTIONNAIRE")}
            description={t("QUESTIONNAIRES.CONFIRM_DELETE_QUESTIONNAIRE")}
            open={openDelete}
            onClose={handleDeleteClose}
            onCancel={handleDeleteClose}
            onConfirm={handleDeleteSave}
          />
        </div>
      </Grid>
    </div>
  );
};

export default AddOpenQuestionnaires;
