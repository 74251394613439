export function getTreatmentsRenderTextByType(treatments, filteType) {
  const filteredTreatments = treatments.length
    ? treatments.filter(
        (treatment) => treatment?.treatmentType?.type === filteType
      )
    : [];
  return filteredTreatments
    .map((treatment) => treatment?.treatmentType?.name || "")
    .join(",");
}
