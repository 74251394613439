import queryString from "query-string";
import { delay, put, select, takeLatest } from "redux-saga/effects";
import { API_URL } from "utils/constants";
import {
  TRANSLATE,
  getDefaultHeaders,
  removeBlankProperties,
} from "utils/helpers";
import { request } from "utils/request";
import toastService from "utils/toast";
import { selectQuery } from "./selector";
import { actions } from "./slice";

export function* getList(action) {
  yield delay(500);

  const query = yield select(selectQuery);
  const requestData = removeBlankProperties(query);
  const queries = queryString.stringify({
    ...requestData,
  });
  try {
    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    const response = yield request(
      `${API_URL}/v1/timeslot?${queries}`,
      options
    );
    yield put(actions.getListSuccess(response.data));
  } catch (e: any) {
    yield put(actions.setLoading(false));
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", {
        error: JSON.stringify(e),
      })
    );
  }
}

export function* deleteRow() {}
export function* useTimeSlotListSaga() {
  yield takeLatest(actions.getList.type, getList);
  yield takeLatest(actions.getNextPage.type, getList);
  yield takeLatest(actions.delete.type, deleteRow);
}
