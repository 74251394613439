import { Search } from "@mui/icons-material";
import {
  Button,
  Card,
  Checkbox,
  Grid,
  Modal,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "../styles";

import DataTable from "app/components/DataTable";
import BankFiled from "app/components/FieldComponent/Bank";
import DepartmentFiled from "app/components/FieldComponent/Department";
import OrganizationField from "app/components/FieldComponent/Organization";
import { ScrollableView } from "app/components/ScrollableView";
import toastService from "utils/toast";
import {
  selectInvolvedPatientList,
  selectLoading,
  selectPatientList,
  selectPatientQuery,
  selectTimeSlotBankInfo,
} from "../redux/selector";
import { actions } from "../redux/slice";
import { TreatmentType } from "../types";
interface TProps {
  open: boolean;
  closeRequest: () => void;
  timeSlotId: number;
  organizationId: number;
  departmentId: number;
  treatmentId: number;
  bankId: number;
  instructorId: number;
  organizationName: string;
  departmentName: string;
  bankName: string;
  addItems: () => void;
  mode: TreatmentType;
}

const RegisterPatientModal = (props: TProps) => {
  const loading = useSelector(selectLoading);
  const dispatch = useDispatch();
  const styles = useStyles();
  const List = useSelector(selectPatientList);
  const timeSlotBankInfo = useSelector(selectTimeSlotBankInfo);
  const involvedPatientList = useSelector(selectInvolvedPatientList);

  const { items, selectedIds, selectedOtherSessionsPatientsIds, meta } = List;

  const {
    q,
    orderField,
    orderDirection,
    organizationId,
    departmentId,
    bankId,
    treatmentId,
    timeSlotId,
    instructorId,
  } = useSelector(selectPatientQuery);

  const [search, setSearch] = useState("");

  const handleSelectChange = (e, patientId) => {
    if (!e.target.checked) {
      const arr = selectedIds.filter((val) => val !== patientId);
      dispatch(actions.setPatientSelected(arr));
      return;
    }
    if (timeSlotBankInfo.type === "group") {
      const arr = [...selectedIds, patientId];
      if (arr.length > timeSlotBankInfo.totalTreatmentPatientCapacity) {
        console.log({ arr });
        toastService.error(
          `Max ${timeSlotBankInfo.totalTreatmentPatientCapacity} in this treatment`
        );
        return;
      }
      dispatch(actions.setPatientSelected(arr));
      return;
    }
    const arr = [patientId];
    dispatch(actions.setPatientSelected(arr));
  };

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(actions.getPatientRegistrationList());
    return () => {};
  }, [
    q,
    orderField,
    orderDirection,
    organizationId,
    departmentId,
    bankId,
    treatmentId,
    timeSlotId,
    instructorId,
  ]);

  useEffect(() => {
    dispatch(
      actions.resetPatientQuery({
        bankId: props.bankId,
        departmentId: props.departmentId,
        organizationId: props.organizationId,
        treatmentId: props.treatmentId,
        excludeBankId: 0,
        timeSlotId: props.timeSlotId,
        instructorId: props.instructorId,
      })
    );
    return () => {};
  }, [
    props.organizationId,
    props.departmentId,
    props.bankId,
    props.treatmentId,
    props.timeSlotId,
  ]);

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setPageQuery("q", event.target.value);
  };
  const setPageQuery = (name: string, value: any) => {
    dispatch(actions.setPatientQuery({ name, value }));
  };

  const columnsReg = [
    {
      id: "regActions",
      label: t("TABLES.ACTIONS"),
      sortValue: "selector.patientName",
      format: (val, index) => {
        const checked =
          timeSlotBankInfo.type === "individual"
            ? selectedIds?.[selectedIds?.length - 1] === val.id
            : selectedIds.includes(val.id);
        const involvedInOthers = selectedOtherSessionsPatientsIds.includes(
          val.id
        );
        if (involvedInOthers) {
          return (
            <Tooltip title={"Involved in others session"}>
              <Typography sx={{ marginLeft: "15px", color: "#ccc" }}>
                {" "}
                -{" "}
              </Typography>
            </Tooltip>
          );
        }
        return (
          <Checkbox
            disabled={involvedInOthers}
            checked={checked}
            value={""}
            onChange={(e) => {
              handleSelectChange(e, val?.id);
            }}
            onDoubleClick={(e) => e.stopPropagation()}
            color="info"
          />
        );
      },
    },
    {
      id: "name",
      label: t("TIMESLOT.PATIENT_NAME"),
      sortValue: "selector.sex",
      format: (val, index) => {
        const involvedInOthers = selectedOtherSessionsPatientsIds.includes(
          val.id
        );
        return (
          <Typography
            sx={{
              color: involvedInOthers ? "#ccc" : "#000",
            }}
          >
            {val.firstName} {val.lastName}
          </Typography>
        );
      },
    },
    {
      id: "sex",
      label: t("TIMESLOT.SEX"),
      sortValue: "selector.sex",
      format: (val, index) => {
        const involvedInOthers = selectedOtherSessionsPatientsIds.includes(
          val.id
        );
        return (
          <Typography
            sx={{
              marginLeft: "15px",
              color: involvedInOthers ? "#ccc" : "#000",
            }}
          >
            {val.sex}
          </Typography>
        );
      },
    },
    {
      id: "organizationName",
      label: t("TIMESLOT.ORGANIZATION"),
      sortValue: "organization.name",
      format: (val, index) => {
        const involvedInOthers = selectedOtherSessionsPatientsIds.includes(
          val.id
        );
        return (
          <Typography
            sx={{
              marginLeft: "15px",
              color: involvedInOthers ? "#ccc" : "#000",
            }}
          >
            {val.organizationName}
          </Typography>
        );
      },
    },
    {
      id: "departmentName",
      label: t("TIMESLOT.DEPARTMENT"),
      sortValue: "department.name",
      format: (val, index) => {
        const involvedInOthers = selectedOtherSessionsPatientsIds.includes(
          val.id
        );
        return (
          <Typography
            sx={{
              marginLeft: "15px",
              color: involvedInOthers ? "#ccc" : "#000",
            }}
          >
            {val.departmentName}
          </Typography>
        );
      },
    },
    {
      id: "email",
      label: t("TIMESLOT.EMAIL"),
      sortValue: "selector.email",
      format: (val, index) => {
        const involvedInOthers = selectedOtherSessionsPatientsIds.includes(
          val.id
        );
        return (
          <Typography
            sx={{
              marginLeft: "15px",
              color: involvedInOthers ? "#ccc" : "#000",
            }}
          >
            {val.email}
          </Typography>
        );
      },
    },
  ];
  const getNextPage = () => {
    if (!loading) {
      dispatch(actions.getPatientRegistrationNextList());
    }
  };
  const hasMoreItems = meta.currentPage < meta.totalPages;

  return (
    <Modal
      open={props.open}
      onClose={props.closeRequest}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card className={styles.regModal}>
        <div className={styles.modalContainer}>
          <Grid>
            <Typography align="center" className={styles.headerModal}>
              {t("TIMESLOT.REGISTER_PATIENTS")}
            </Typography>
          </Grid>

          <Grid sx={{ padding: "30px 0px" }}>
            <Grid
              container
              spacing={2}
              md={12}
              sx={{
                padding: "5px 0px 10px 0px",
                justifyContent: "flex-start",
              }}
            >
              <Grid item xs={3} sx={{ justifyContent: "center" }}>
                <Typography noWrap className={styles.label}>
                  {t("ASSIGNED_TREATMENT.SEARCH")}
                </Typography>
                <TextField
                  className={styles.searchB}
                  placeholder={t("ASSIGNED_TREATMENT.SEARCH")}
                  value={search}
                  onChange={handleSearchChange}
                  InputProps={{
                    endAdornment: <Search sx={{ color: "#012497" }} />,
                  }}
                />
              </Grid>
              <OrganizationField
                value={organizationId}
                changeValue={(value) => {}}
                disabled
                showAll={true}
                activeRequired={false}
                xs={3}
              ></OrganizationField>
              <DepartmentFiled
                value={departmentId}
                changeValue={(value) => {}}
                disabled
                orgId={organizationId}
                showAll={true}
                xs={3}
              ></DepartmentFiled>
              <BankFiled
                value={bankId}
                organizationId={organizationId}
                departmentId={departmentId}
                changeValue={(value) => {}}
                disabled
                showAll={true}
                xs={3}
              ></BankFiled>
            </Grid>
          </Grid>

          <ScrollableView
            hasMoreItems={hasMoreItems}
            height={350}
            loadMore={getNextPage}
          >
            <DataTable
              loading={loading}
              orderDirection={orderDirection}
              orderField={orderField}
              setQuery={setPageQuery}
              rows={items}
              columns={columnsReg}
              editAction={() => {}}
              onRowDoubleClick={() => {}}
              isPointerCursor={true}
              noDataLabel={"TIMESLOT.NO_SESSIONS_PATIENT_LIST"}
            />
          </ScrollableView>

          <div>
            <Grid
              container
              spacing={3}
              sx={{
                padding: "20px 0px 0px 0px",
                justifyContent: "space-between",
              }}
            >
              <Grid
                className={styles.btnContainer}
                container
                item
                xs={6}
                direction="column"
              >
                <Button
                  className={styles.cancelBtn}
                  variant="outlined"
                  onClick={() => props.closeRequest()}
                >
                  {t("BUTTONS.CANCEL")}
                </Button>
              </Grid>
              <Grid container item xs={6} direction="column">
                <Button
                  className={styles.saveBtn}
                  onClick={() => props.addItems()}
                  variant="contained"
                >
                  {t("BUTTONS.SAVE")}
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </Card>
    </Modal>
  );
};
export default RegisterPatientModal;
