import {
  Box,
  Button,
  Card,
  Grid,
  Modal,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { validateAndFormatIsraeliMobileNumber } from "styles/theme/utils";
import { selectContact, selectList } from "../../redux/selector";
import { actions } from "../../redux/slice";
import { isDuplicate, onValidate } from "../../validator";
import { useStyles } from "./styles";

type Props = {
  open: boolean;
  handleClose: any;
  style?: object;
  action: string;
  handleSave: any;
};

const ContactModal = ({
  open,
  handleClose,
  handleSave,
  style,
  action,
}: Props) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const form = useSelector(selectContact);
  const { t, i18n } = useTranslation();
  const data = useSelector(selectList);
  const theme = useTheme();
  const direction = theme.direction;
  const onChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      actions.setFormErrors({
        key: "contact",
        errors: [
          {
            name,
            error: "",
          },
        ],
      })
    );
    if (e.target.name === "mobileNumber") {
      dispatch(actions.updateFormValue({ key: name, value: value }));
    } else {
      dispatch(actions.updateFormValue({ key: name, value }));
    }
  };

  const validate = () => {
    const errors = onValidate(form);
    if (errors.length > 0) {
      dispatch(
        actions.setFormErrors({
          key: "contact",
          errors,
        })
      );
      return;
    }
    if (action !== "Edit") {
      const duplicates = isDuplicate(form, data?.items);
      if (duplicates.length > 0) {
        dispatch(
          actions.setFormErrors({
            key: "contact",
            errors: duplicates,
          })
        );
        return;
      }
    }
    handleSave();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card sx={{ direction: direction }} className={styles.importModal}>
        <div className={styles.modalContainer}>
          <Grid>
            <Typography align="center" className={styles.headerModal}>
              {action?.toLowerCase() === "add"
                ? t("ORGANIZATIONS.ADD_CONTACT")
                : t("ORGANIZATIONS.EDIT_CONTACT")}
            </Typography>
          </Grid>
          <Grid
            item
            container
            spacing={4}
            md={12}
            sx={{ padding: "10px 0px 0px 0px" }}
          >
            <Grid item xs={6}>
              <label className={styles.label}>
                {t("ORGANIZATIONS.CONTACT_NAME")}
              </label>
              <TextField
                error={Boolean(form.contactName.error)}
                margin="dense"
                variant="outlined"
                fullWidth
                value={form.contactName.value}
                onChange={(e) => onChange(e)}
                required
                id="contactName"
                placeholder={t("ORGANIZATIONS.ENTER_CONTACT_NAME")}
                name="contactName"
                autoComplete={"off"}
                helperText={
                  form.contactName.error ? t(form.contactName.error) : ""
                }
                FormHelperTextProps={{
                  style: {
                    textAlign: i18n.dir() === "ltr" ? "left" : "right",
                  },
                }}
                className={styles.textEnabled}
              />
            </Grid>

            <Grid item xs={6}>
              <label className={styles.label}>{t("ORGANIZATIONS.TITLE")}</label>
              <TextField
                margin="dense"
                variant="outlined"
                fullWidth
                error={Boolean(form.title.error)}
                FormHelperTextProps={{
                  style: {
                    textAlign: i18n.dir() === "ltr" ? "left" : "right",
                  },
                }}
                // disabled={disable}
                required
                value={form.title.value}
                id="title"
                onChange={(e) => onChange(e)}
                placeholder={t("ORGANIZATIONS.ENTER_TITLE")}
                name="title"
                autoComplete={"off"}
                helperText={form.title.error ? t(form.title.error) : ""}
                className={styles.textEnabled}
              />
            </Grid>
          </Grid>

          <Grid
            item
            container
            spacing={4}
            md={12}
            sx={{ padding: "10px 0px 0px 0px" }}
          >
            <Grid item xs={6}>
              <label className={styles.label}>{t("ORGANIZATIONS.EMAIL")}</label>
              <TextField
                margin="dense"
                variant="outlined"
                error={Boolean(form.email.error)}
                fullWidth
                helperText={form.email.error ? t(form.email.error) : ""}
                FormHelperTextProps={{
                  style: {
                    textAlign: i18n.dir() === "ltr" ? "left" : "right",
                  },
                }}
                // disabled={disable}
                required
                value={form.email.value}
                id="email"
                onChange={(e) => onChange(e)}
                placeholder={t("ORGANIZATIONS.ENTER_EMAIL")}
                name="email"
                autoComplete={"off"}
                className={styles.textEnabled}
              />
            </Grid>

            <Grid item xs={6}>
              <label className={styles.label}>
                {t("ORGANIZATIONS.MOBILE_NUMBER")}
              </label>
              <TextField
                margin="dense"
                variant="outlined"
                fullWidth
                required
                error={
                  form.mobileNumber.value &&
                  !validateAndFormatIsraeliMobileNumber(form.mobileNumber.value)
                    .isValid
                }
                inputProps={{ maxLength: 10 }}
                helperText={
                  form.mobileNumber.error ? t(form.mobileNumber.error) : ""
                }
                FormHelperTextProps={{
                  style: {
                    textAlign: i18n.dir() === "ltr" ? "left" : "right",
                  },
                }}
                value={
                  validateAndFormatIsraeliMobileNumber(form.mobileNumber.value)
                    .formattedNumber
                }
                id="mobileNumber"
                placeholder={t("ORGANIZATIONS.ENTER_MOBILE_NUMBER")}
                name="mobileNumber"
                onChange={(e) => onChange(e)}
                autoComplete={"off"}
                className={styles.textEnabled}
              />
            </Grid>
          </Grid>
          <div>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "30px",
                gap: "10px",
              }}
            >
              <Button
                className={styles.cancelBtn}
                variant="outlined"
                onClick={handleClose}
              >
                {t("BUTTONS.CANCEL")}
              </Button>
              <Button
                onClick={validate}
                className={styles.saveBtn}
                variant="contained"
              >
                {t("BUTTONS.SAVE")}
              </Button>
            </Box>
          </div>
        </div>
      </Card>
    </Modal>
  );
};
export default ContactModal;
