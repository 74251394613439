import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  heading: {
    fontFamily: "Cairo",
    fontWeight: 700,
    fontSize: "16px",
    color: "#2A3333",
  },
  label: {
    fontFamily: "Cairo",
    fontWeight: 400,
    fontSize: "16px",
    color: "#2A3333",
    height: "30px",
    display: "block",
  },
  textEnabled: {
    background: "#FFF",
    width: "102%",
  },
  textEnabledSelect: {
    background: "#FFF",
    width: "100%",
    marginTop: "8px",
  },
}));
