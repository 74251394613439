import { Mail } from "@mui/icons-material";
import { Alert } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { PrimaryButton } from "app/components/Button/PrimaryButton";
import { TextField } from "app/components/TextField";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import confusion from "../LoginPage/assets/forgot.png";
import Redirect from "../components/Redirect";
import { selectForgotForm, selectLoading } from "../selectors";
import { actions } from "../slice";
type Props = {};
const useStyles = makeStyles(() => ({
  heading: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "19px",
    textAlign: "center",
    color: "#000",
    fontFamily: "Cairo",
  },
  subHeading: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "16px",
    color: "#FF923D",
    textAlign: "center",
    maxWidth: 188,
    fontFamily: "Cairo",
  },
  resetMsgText: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "16px",
    textAlign: "center",
    color: "#999999",
    marginTop: 4,
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  button: {
    width: "100%",
    height: 40,
    fontSize: 22,
    marginTop: 11,
  },
  successHeading: {
    fontSize: 16,
    fontWeight: 500,
    textAlign: "center",
    color: "#000",
  },
  iconMail: {
    fontSize: 53,
    color: "#FF923D",
    margin: "30px 0px 30px 0px",
  },
  input: {
    marginTop: 24,
  },
  mailSent: {
    color: "#3C4858",
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 26,
    textAlign: "center",
    maxWidth: 210,
    lineHeight: "16px",
  },
  descText: {
    fontSize: 14,
    fontWeight: 400,
    textAlign: "center",
    color: "#999",
    maxWidth: 289,
  },
  error: {
    width: "100%",
    textAlign: "center",
    justifyContent: "center",
    marginBottom: 10,
  },
}));
const ForgotPassword = (props: Props) => {
  const styles = useStyles();
  const [isSubmitted, setSubmitted] = useState<boolean>(false);
  const form = useSelector(selectForgotForm);
  const isLoading = useSelector(selectLoading);
  const { email, error } = form;
  const dispatch = useDispatch();
  const [errorMsg, setErrorMsg] = useState("");
  const { t } = useTranslation();
  const onSuccess = () => {
    localStorage?.clear();
    dispatch(actions.logout());
    setSubmitted(true);
    dispatch(actions.resetError());
    dispatch(actions.resetEmail());
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(actions.forgotPassword({ callback: onSuccess }));
  };
  const handleEmailChange = (e) => {
    const { value } = e.target;
    if (error) {
      dispatch(actions.resetError());
    }
    dispatch(actions.setForgotEmail(value));
  };
  const forgotSubmitContent = (
    <>
      <Box className={styles.form}>
        <Box className={styles.successHeading}>{t("AUTH.RESET_PASSWORD")}</Box>
        <Mail className={styles.iconMail} />
        <Box className={styles.mailSent}>
          {t("AUTH.RESET_PASSWORD_MESSAGE")}
        </Box>
        <div className={styles.descText}>{t("AUTH.RESET_PASSWORD_DESC")}</div>
      </Box>
    </>
  );
  useEffect(() => {
    if (error) setErrorMsg(t(`login.${error}`));
  }, [error]);
  const forgotForm = (
    <Redirect>
      <form className={styles.form} onSubmit={handleSubmit}>
        {errorMsg && (
          <Alert className={styles.error} severity="error">
            {errorMsg}
          </Alert>
        )}
        <Box mb={2} className={styles.heading}>
          {t("AUTH.FORGOT_PASSWORD")} ?
        </Box>
        <img src={confusion} alt="forgot_person" />
        <Box className={styles.subHeading} mt={2}>
          {t("AUTH.FORGOT_PASSWORD_HEADING")}
        </Box>
        <Box className={styles.resetMsgText} mb={1}>
          {t("AUTH.FORGOT_PASSWORD_SUB_HEADING")}
        </Box>
        <div dir="ltr" style={{ width: "100%", marginBottom: "15px" }}>
          <TextField
            height={51}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            type="email"
            placeholder={t("AUTH.RESET_PASSWORD_PLACEHOLDER")}
            name="Email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={handleEmailChange}
          />
        </div>

        <PrimaryButton isLoading={isLoading} label={t("BUTTONS.SEND")} />
      </form>
    </Redirect>
  );
  return !isSubmitted ? forgotForm : forgotSubmitContent;
};
export default ForgotPassword;
