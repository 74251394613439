import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  mainContainer: {},
  importModal: {
    position: "absolute",
    background: "#F5F8FF",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "0px",
    boxShadow: "0",
    zIndex: "9999",
    width: "530px",
    height: "auto",
    paddingBottom: "50px",
  },
  modalContainer: {
    padding: "30px",
  },
  headerModal: {
    color: "#012497",
    fontFamily: "Cairo",
    fontWeight: "bolder",
    fontSize: "25px",
    marginBottom: "10px",
  },
  btnContainer: {
    display: "flex",
    alignItems: "flex-end",
  },
  cancelBtn: {
    color: "#3D63E6",
    borderColor: "#3D63E6",
    "&:hover": {
      borderColor: "#3D63E6",
    },
    fontFamily: "Cairo",
    fontWeight: 700,
    fontSize: "16px",
    width: "160px",
    height: "50px",
  },
  saveBtn: {
    background: "#3D63E6",
    color: "#ffff",
    "&:hover": {
      background: "#3D63E6",
    },
    fontFamily: "Cairo",
    fontWeight: 700,
    fontSize: "16px",
    width: "160px",
    height: "50px",
  },
  lable: {
    display: "flex",
    flexDirection: "column",
    width: "230px",
  },
  inp_modal: {
    boxSizing: "border-box",
    width: "220px",
    paddingLeft: "8px",
    height: "56px",
    background: "#FFFFFF",
    border: "2px solid #C6C9CA",
    borderRadius: "4px",
    fontFamily: "Cairo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
  },
  inp_modal_name: {
    fontFamily: "Cairo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#2A3333",
    display: "inline-block",
    marginBottom: "3px",
  },
  dataTableDiv: {
    maxHeight: "calc(100vh - 410px)",
    //maxWidth: "calc(159vh)",
    border: "1px solid #C6C9CA",
    overflowY: "auto",
    overflowX: "auto",
    marginTop: "0px",
    background: "#F5F8FF",
  },
  label: {
    fontFamily: "Cairo",
    fontWeight: 400,
    fontSize: "16px",
    color: "#2A3333",
  },
  textEnabled: {
    background: "#FFF",
    width: "100%",
  },
}));
