import { ThemeKeyType } from "./types";
/* istanbul ignore next line */
export const isSystemDark = window?.matchMedia
  ? window.matchMedia("(prefers-color-scheme: dark)")?.matches
  : undefined;

export function saveTheme(theme: ThemeKeyType) {
  window.localStorage && localStorage.setItem("selectedTheme", theme);
}

/* istanbul ignore next line */
export function getThemeFromStorage(): ThemeKeyType | null {
  return window.localStorage
    ? (localStorage.getItem("selectedTheme") as ThemeKeyType) || null
    : null;
}

export function scrollBottom(element) {
  element && element.scroll({ top: element.scrollHeight, behavior: "smooth" });
}

export function validateAndFormatIsraeliMobileNumber(number = "") {
  // Remove any non-numeric characters from the number
  if (!number) {
    number = "";
  }
  var numericNumber = number.replace(/\D/g, "");

  // Check if the number starts with "05" and has 9 digits
  if (/^05\d{8}$/.test(numericNumber)) {
    // Format the number as "050-0000023"
    var formattedNumber = numericNumber.replace(/(\d{3})(\d{7})/, "$1-$2");

    return {
      isValid: true,
      formattedNumber: formattedNumber,
      numericNumber,
    };
  } else {
    return {
      isValid: false,
      formattedNumber: numericNumber,
      numericNumber,
    };
  }
}

export function translatedBreadCrumb(mode: "view" | "new" | "edit" | string) {
  switch (mode) {
    case "edit":
      return "BREAD_CRUMBS.EDIT";
    case "new":
      return "BREAD_CRUMBS.NEW";
    case "add":
      return "BREAD_CRUMBS.NEW";
    case "view":
      return "BREAD_CRUMBS.VIEW";
  }
  return "BREAD_CRUMBS.VIEW";
}

export const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
