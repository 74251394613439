import { PayloadAction } from "@reduxjs/toolkit";
import { FormError } from "app/containers/types";
import set from "lodash/set";
import moment from "moment";
import { TreatmentListItemType } from "store/global/fields/types";
import { createSlice } from "utils/@reduxjs/toolkit";
import { InvolvementType } from "utils/constants";
import { assignTineWithDate } from "../helper";
import {
  Patient,
  TimeSlotMode,
  TimeSlotsActionsListItemType,
  TreatmentBankInfo,
  TreatmentType,
  UserState,
} from "../types";

const initialState = UserState;
export const TimeSlotActionListSlice = createSlice({
  name: "timeSlotActionsState",
  initialState,
  reducers: {
    editUser: (
      state,
      action: PayloadAction<TimeSlotsActionsListItemType>
    ) => {},
    removeUser: (state) => {
      const editUser: any = {};
      editUser.firstName = "";
      editUser.lastName = "";
      editUser.email = "";
      editUser.phoneNumber = "";
      editUser.creationDate = null;
      editUser.dateOfBirth = null;
    },
    toggelEditState: (state, action: PayloadAction<boolean>) => {
      state.editState = action.payload;
    },
    changeTimeslotMode: (state, action: PayloadAction<TimeSlotMode>) => {
      state.form.type = action.payload;
      state.mode = action.payload;
    },
    changeTreatmentType: (state, action: PayloadAction<TreatmentType>) => {
      state.treatmentType = action.payload;
    },
    create: (state, action) => {
      state.loading = true;
    },
    createSuccess: (state) => {
      state.loading = false;
      state.form = initialState.form;
    },
    getList: (state) => {
      state.loading = true;
    },
    getById: (state, action: PayloadAction<string>) => {
      state.loading = true;
    },
    setEditData: (state, action: PayloadAction<any>) => {
      state.form.id.value = action.payload.timeslot.id;
      state.form.organizationId.value = action.payload.timeslot.organizationId;
      state.form.associatedPatientCount.value =
        action.payload.timeslot.associatedPatientCount;
      state.form.departmentId.value = action.payload.timeslot.departmentId;
      state.form.bankId.value = action.payload.timeslot.bankId;
      state.form.treatmentId.value = action.payload.timeslot.treatmentId;
      state.form.instructorId.value = action.payload.timeslot.instructorId;
      state.form.actualInstructorId.value =
        action.payload.timeslot.actualInstructorId;
      state.form.street.value = action.payload.timeslot.street;
      state.form.cityId.value = action.payload.timeslot.cityId;
      state.form.slotDate.value = action.payload.timeslot.slotDate;
      state.form.type = action.payload.timeslot.type;
      state.mode = action.payload.timeslot.type;
      state.form.bank = action.payload.timeslot.bank;
      state.form.hasPassedSessions = action.payload.timeslot.hasPassedSessions;

      state.timeslotBonusAssociation.items =
        action.payload.timeslotBonusAssociation?.map((e) => {
          return {
            id: e.id,
            patientId: e?.patient?.id,
            type: e?.treatmentInvolvedPatient.type,
            firstName: e?.patient?.firstName,
            lastName: e?.patient?.lastName,
            sex: "",
            email: "",
            cellular: "",
            deleted: false,
          };
        }) || [];
      state.involvedPatientList.items =
        action.payload.timeslotInvolvedPatient?.map((e) => {
          return {
            id: e.id,
            patientId: e?.patient?.id,
            type: e?.treatmentInvolvedPatient.type,
            firstName: e?.patient?.firstName,
            lastName: e?.patient?.lastName,
            sex: "",
            email: "",
            cellular: "",
            deleted: false,
          };
        }) || [];

      state.sessionList.items =
        action.payload.timeslotSession?.map((e, index) => {
          return {
            id: e.id,
            sessionNumber: index + 1,
            completedAt: e.completedAt,
            meetingId: e.meetingId,
            date: e.date,
            start_time: e.start_time,
            end_time: e.end_time,
            involvedPatients:
              e.assignedTreatment
                ?.filter((x) => x.involvedPatient)
                .map((el) => {
                  return {
                    id: el.involvedPatient?.id,
                    assignedTreatmentId: el.id,
                    patientId: el.involvedPatient?.patient.id,
                    firstName: el.involvedPatient?.patient?.firstName,
                    lastName: el.involvedPatient?.patient?.lastName,
                    sex: "",
                    email: "",
                    cellular: "",
                    isActive: el.isActive,
                    deleted: false,
                  };
                }) || [],
            action: "edit",
          };
        }) || [];
      state.loading = false;
      state.sessionList.items = sortedSessionList(state.sessionList.items);
      console.log("JSON.stringify", JSON.stringify(state.sessionList.items));
    },
    setEditDataComplete: (state, action: PayloadAction<any>) => {
      state.loading = false;
    },
    timeSlotInfo: (
      state,
      action: PayloadAction<{ bankId: number; treatmentId: number }>
    ) => {},
    getTimeSlotInfoSuccess(state, action: PayloadAction<TreatmentBankInfo>) {
      state.bankInfo = action.payload;
    },
    setInitSession: (state, action: PayloadAction<TreatmentListItemType>) => {
      state.sessionList.items = [];
      state.involvedPatientList.items = [];
      const type =
        action.payload.session_count === 1
          ? TimeSlotMode.SINGLE
          : TimeSlotMode.MULTI;
      if (type === TimeSlotMode.MULTI) {
        let dateMain = moment().add(1, "day");
        for (let index = 0; index < action.payload.session_count; index++) {
          let date = dateMain.format("YYYY-MM-DD");
          let time = moment(moment(date + " " + "10:00"));
          let start = time.clone();
          let end = time
            .clone()
            .add(action.payload.session_duration, "minutes");
          time = time.add(action.payload.session_duration, "minutes");
          state.sessionList.items.push({
            id: 0,
            sessionNumber: index + 1,
            meetingId: "-",
            date: moment(date).toISOString(),
            start_time: start.toISOString(),
            end_time: end.toISOString(),
            involvedPatients: [],
            action: "add",
          });
          dateMain = dateMain.add(1, "day");
        }
      } else {
        let date = state.form.slotDate.value
          ? moment(state.form.slotDate.value).format("YYYY-MM-DD")
          : moment().add("1", "day").format("YYYY-MM-DD");
        let time = moment(moment(date + " " + "10:00"));
        let start = time.clone();
        let end = time.clone().add(action.payload.session_duration, "minutes");
        time = time.add(action.payload.session_duration, "minutes");
        state.sessionList.items.push({
          id: 0,
          sessionNumber: 1,
          meetingId: "-",
          date: "",
          start_time: start.toISOString(),
          end_time: end.toISOString(),
          involvedPatients: [],
          action: "add",
        });
      }
      state.sessionList.items = sortedSessionList(state.sessionList.items);
      state.loading = false;
    },
    setEditSession: (
      state,
      action: PayloadAction<{
        startTime: any;
        endTime: any;
        type: TimeSlotMode;
        callback: () => void;
      }>
    ) => {},
    setEditSessionSuccess: (
      state,
      action: PayloadAction<{
        startTime: any;
        endTime: any;
        type: TimeSlotMode;
      }>
    ) => {
      const sessionItem = state.sessionList.items[state.editIndex];
      if (sessionItem) {
        sessionItem.end_time = action.payload.endTime;
        sessionItem.start_time = action.payload.startTime;
      }
      state.sessionList.items = sortedSessionList(state.sessionList.items);
      state.editIndex = -1;
      state.loading = false;
    },
    addSlot: (
      state,
      action: PayloadAction<{
        duration: number;
      }>
    ) => {
      let time = moment(
        moment(
          state.sessionList.items[state.sessionList.items.length - 1].end_time
        )
      );
      let start = time.clone();
      let end = time.clone().add(action.payload.duration, "minutes");
      const id = 0;
      state.sessionList.items.push({
        id,
        sessionNumber: 1,
        meetingId: "-",
        date: "",
        start_time: start.toISOString(),
        end_time: end.toISOString(),
        involvedPatients: [],
        action: "add",
      });
      state.sessionList.items = sortedSessionList(state.sessionList.items);
    },
    setSessionPatient: (
      state,
      action: PayloadAction<{
        type: TreatmentType;
        duration: number;
        callback: () => void;
      }>
    ) => {},
    setSessionPatientSuccess: (
      state,
      action: PayloadAction<{
        type: TreatmentType;
        duration: number;
      }>
    ) => {
      if (state.mode === TimeSlotMode.MULTI) {
        state.involvedPatientList.items.forEach((f) => {
          if (!state.patientList.selectedIds.includes(f.id)) {
            f.deleted = true;
          }
        });

        state.patientList.selectedIds.forEach((id) => {
          const existingInvolvedPatient = state.involvedPatientList.items.find(
            (p) => p.id == id
          );

          if (existingInvolvedPatient) {
            return;
          }
          const newPatient = state.patientList.items.find((f) => f.id == id);
          if (newPatient) {
            state.involvedPatientList.items.push({
              id: newPatient.id,
              assignedTreatmentId: null,
              patientId: newPatient.patientId,
              firstName: newPatient.firstName,
              lastName: newPatient.lastName,
              type: InvolvementType.Regular,
              sex: "",
              email: "",
              cellular: "",
              deleted: false,
            });
          }
        });

        for (let index = 0; index < state.sessionList.items.length; index++) {
          for (
            let indexInner = 0;
            indexInner < state.involvedPatientList.items.length;
            indexInner++
          ) {
            const element = state.involvedPatientList.items[indexInner];
            if (
              state.sessionList.items[index].involvedPatients.filter(
                (x) => x.id == element.id
              ).length
            ) {
              const sessionElement = state.sessionList.items[
                index
              ].involvedPatients.filter((x) => x.id == element.id)[0];

              state.involvedPatientList.items[indexInner].assignedTreatmentId =
                sessionElement.assignedTreatmentId;
            } else {
            }
          }
          state.sessionList.items[index].involvedPatients = JSON.parse(
            JSON.stringify(state.involvedPatientList.items)
          );
        }
      } else {
        const item = JSON.parse(
          JSON.stringify(state.sessionList.items[state.editIndex])
        );
        if (item) {
          const involvedPatients: any = [];
          item.involvedPatients.forEach((f) => {
            if (!state.patientList.selectedIds.includes(f.id)) {
              f.deleted = true;
              involvedPatients.push(f);
            }
          });
          state.patientList.selectedIds.forEach((id) => {
            const existingInvolvedPatient =
              state.involvedPatientList.items.find((p) => p.patientId == id);

            if (existingInvolvedPatient) {
              involvedPatients.push(existingInvolvedPatient);
              state.involvedPatientList.items.push(existingInvolvedPatient);
              return;
            }
            const newPatient = state.patientList.items.find((f) => f.id == id);
            if (newPatient) {
              const newInvolvedPatient = {
                id: newPatient.id,
                assignedTreatmentId: null,
                patientId: newPatient.patientId,
                firstName: newPatient.firstName,
                lastName: newPatient.lastName,
                type: InvolvementType.Regular,
                sex: "",
                email: "",
                cellular: "",
                deleted: false,
              };
              involvedPatients.push(newInvolvedPatient);
              state.involvedPatientList.items.push(newInvolvedPatient);
              const existingSessionInvolvedPatient = item.involvedPatients.find(
                (p) => p.id == id
              );
              if (!existingSessionInvolvedPatient) {
                item.involvedPatients.push(newInvolvedPatient);
              }
            }
          });
        }
        state.sessionList.items[state.editIndex] = JSON.parse(
          JSON.stringify(item)
        );
      }

      state.editIndex = -1;
      state.patientList.selectedIds = [];
      state.patientList.selectedOtherSessionsPatientsIds = [];

      state.loading = false;
    },

    setBonusPatient: (state) => {
      const items: Patient[] = [];
      state.timeslotBonusAssociation.items.forEach((val) => {
        if (state.patientList.selectedIds.includes(val.patientId)) {
        } else {
          if (val.id) {
            val.deleted = true;
            items.push(val);
          }
        }
      });

      state.patientList.items
        .filter((val, ind, self) => {
          return state.patientList.selectedIds.includes(val?.patientId);
        })
        .forEach((e) => {
          const existing = state.timeslotBonusAssociation.items.find(
            (f) => f.patientId == e.patientId
          );
          if (existing) {
            items.push(existing);
            return;
          }
          const item = {
            id: 0,
            patientId: e.patientId,
            firstName: e.firstName,
            lastName: e.lastName,
            type: InvolvementType.Bonus,
            sex: "",
            email: "",
            cellular: "",
            deleted: false,
          };
          items.push(item);
        });

      state.timeslotBonusAssociation.items = items;

      state.loading = false;
    },
    deleteBonusInvolvedPatient: (state, action: PayloadAction<number>) => {
      const index = state.timeslotBonusAssociation.items.findIndex(
        (x) => x.deleted != true && x.patientId == action.payload
      );
      if (index > -1) {
        if (state.timeslotBonusAssociation.items[index].id > 0) {
          state.timeslotBonusAssociation.items[index].deleted = true;
        } else {
          state.timeslotBonusAssociation.items.splice(action.payload, 1);
        }
      }
    },
    deleteSessionPatient: (state, action: PayloadAction<number>) => {
      if (state.sessionList.items.length > 1) {
        const session = state.sessionList.items[action.payload];
        if (session.id) {
          session.isActive = false;
          session.isDeleted = true;
        } else {
          state.sessionList.items.splice(action.payload, 1);
        }
        state.sessionList.items = sortedSessionList(state.sessionList.items);
      }
    },
    getListSuccess: (
      state,
      action: PayloadAction<{ items: []; links: any; meta: any }>
    ) => {
      state.loading = false;
      state.sessionList.items = action.payload.items;
      state.sessionList.meta = action.payload.meta;
      state.sessionList.links = action.payload.links;
    },
    getNextPage: (state, action: PayloadAction<any>) => {
      state.query.page = state.sessionList.meta.currentPage + 1;
      state.loading = true;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    update: (
      state,
      action: PayloadAction<{ id: any; callback: () => void }>
    ) => {
      state.error = "";
      state.loading = true;
    },
    updateFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state.form, `${action.payload.key}.value`, action.payload.value);
      set(state.form, `${action.payload.key}.error`, null);
      state.error = "";
    },
    changeSlotDate: (state, action: PayloadAction<{ value: any }>) => {
      state.form.slotDate.value = action.payload.value;
      state.form.slotDate.error = "";
      // check if date is a valid date
      if (!action.payload.value) return;
      if (!moment(action.payload.value)?.isValid() && action.payload.value) {
        state.form.slotDate.error = "INVALID_DATE";
        return;
      }
      state.sessionList.items.forEach((element) => {
        element.start_time = assignTineWithDate(
          action.payload.value,
          element.start_time as string
        );
        element.end_time = assignTineWithDate(
          action.payload.value,
          element.end_time as string
        );
      });
      state.error = "";
    },
    updateSuccess(state, action: PayloadAction<TimeSlotsActionsListItemType>) {
      state.loading = false;
    },
    updateFailed: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    delete: (state) => {
      state.error = "";
      state.loading = true;
    },
    deleteSuccess: (state) => {
      state.loading = false;
      state.error = "";
    },
    deleteFailed: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    resetNewUserForm(state) {
      state.error = "";
      state.form = initialState.form;
    },
    setFormErrors(
      state,
      action: PayloadAction<{
        key: string;
        errors: FormError[];
      }>
    ) {
      action.payload.errors.forEach((error) => {
        set(
          state.form,
          `${action.payload.key}.${error.name}.error`,
          error.error
        );
      });
    },
    getNextPageItems: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    getNextPageItemsSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.sessionList.items = state.sessionList.items.concat(
        action.payload.items
      );
      state.sessionList.meta.currentPage = action.payload.meta.currentPage;
      state.sessionList.links = action.payload.links;
      state.query.page = action.payload.meta.currentPage;
    },
    setQuery: (state, action: PayloadAction<{ name: string; value: any }>) => {
      const { name, value } = action.payload;
      state.query.page = 1;
      state.sessionList = initialState.sessionList;
      set(state.query, name, value);
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    resetModule: (state) => {
      state = initialState;
    },

    setSelected: (state, action: PayloadAction<Array<Number>>) => {
      state.sessionList.selectedIds = action.payload;
    },
    addEntryToSessionsTable: (state, action) => {
      state.sessionList.items = action.payload;
    },
    toggleRegisterPatient: (state) => {
      state.registerPatientModal = !state.registerPatientModal;
      state.patientList.selectedIds = [];
      state.patientList.selectedOtherSessionsPatientsIds = [];
      if (state.mode === TimeSlotMode.MULTI) {
        state.patientList.selectedIds = state.involvedPatientList.items
          .filter((f) => !f.deleted)
          .map((m) => m.id);
      }
      if (state.mode === TimeSlotMode.SINGLE) {
        for (let index = 0; index < state.sessionList.items.length; index++) {
          const item = state.sessionList.items[index];
          const involvedIds = item.involvedPatients.map((m) => m.id);
          if (index === state.editIndex) {
            state.patientList.selectedIds = involvedIds;
          } else {
            state.patientList.selectedOtherSessionsPatientsIds = [
              ...state.patientList.selectedOtherSessionsPatientsIds,
              ...involvedIds,
            ];
          }
        }
      }
    },
    toggalBonusPatient: (state) => {
      state.involvedPatientModal = !state.involvedPatientModal;
      if (state.involvedPatientModal) {
        state.patientList.selectedIds = state.timeslotBonusAssociation.items
          .filter((f) => !f.deleted)
          .map((m) => m.patientId);
        state.patientList.selectedOtherSessionsPatientsIds = [];
      }
    },
    toggalDeleteModal: (state) => {
      state.deleteModal = !state.deleteModal;
    },
    setPatientQuery: (
      state,
      action: PayloadAction<{ name: string; value: any }>
    ) => {
      const { name, value } = action.payload;
      state.patientQuery.page = 1;
      state.patientList.items = [];
      set(state.patientQuery, name, value);
    },
    resetPatientQuery: (
      state,
      action: PayloadAction<{
        departmentId: number;
        organizationId: number;
        bankId: number;
        excludeBankId: number;
        treatmentId: number;
        instructorId: number;
        timeSlotId: number;
      }>
    ) => {
      state.patientQuery.page = 1;
      state.patientList.items = [];
      state.patientQuery.excludeBankId = action.payload.excludeBankId;
      state.patientQuery.bankId = action.payload.bankId;
      state.patientQuery.organizationId = action.payload.organizationId;
      state.patientQuery.departmentId = action.payload.departmentId;
      state.patientQuery.treatmentId = action.payload.treatmentId;
      state.patientQuery.timeSlotId = action.payload.timeSlotId;
      state.patientQuery.instructorId = action.payload.instructorId;
    },
    getPatientRegistrationListSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.patientList.items = state.patientList.items.concat(
        action.payload.items.map((x) => {
          return {
            id: x.id,
            patientId: x?.patient?.id,
            email: x?.patient?.email,
            firstName: x?.patient?.firstName,
            lastName: x?.patient?.lastName,
            cellular: x?.patient?.cellular,
            sex: x?.patient?.sex,
            organizationName: x?.patient?.organization.name,
            departmentName: x?.patient?.department.departmentName,
          };
        })
      );
      state.patientList.meta = action.payload.meta;
      state.patientList.links = action.payload.links;
      state.patientQuery.page = action.payload.meta.currentPage;
    },
    getPatientRegistrationList: (state) => {
      state.loading = true;
      state.patientList.items = [];
    },
    getPatientRegistrationNextList: (state) => {
      state.patientQuery.page = state.patientList.meta.currentPage + 1;
      state.loading = true;
    },
    getPatientBonusListSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.patientList.items = state.patientList.items.concat(
        action.payload.items.map((x) => {
          return {
            id: 0,
            patientId: x?.id,
            email: x.email,
            firstName: x.firstName,
            lastName: x.lastName,
            sex: x.sex,
            organizationName: x.organization.name,
            departmentName: x.department.departmentName,
            cellular: x.cellular,
          };
        })
      );
      state.patientList.meta = action.payload.meta;
      state.patientList.links = action.payload.links;
      state.patientQuery.page = action.payload.meta.currentPage;
    },
    getPatientRegistrationFailure: (state) => {
      state.loading = false;
    },
    getPatientBonusList: (state) => {
      state.loading = true;
      state.patientList.items = [];
    },
    getPatientBonusNextList: (state) => {
      state.patientQuery.page = state.patientList.meta.currentPage + 1;
      state.loading = true;
    },
    getPatientBonusFailure: (state) => {
      state.loading = false;
    },
    setPatientSelected: (state, action: PayloadAction<Array<Number>>) => {
      state.patientList.selectedIds = action.payload;
    },
    setPatientSelectedIds: (state, action: PayloadAction<Array<Number>>) => {
      state.patientList.selected = action.payload;
    },
    setSessionEditIndex: (state, action: PayloadAction<number>) => {
      state.editIndex = action.payload;
    },
    setTogaglEditModal: (state, action: PayloadAction<boolean>) => {
      state.editTimeSlotModel = action.payload;
    },
    doAddTimeslot: (state, action: PayloadAction<{ callback: () => void }>) => {
      state.loading = true;
      state.error = "";
    },
    doEditTimeSlot: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {
      state.loading = true;
      state.error = "";
    },
    doDeleteTimeSlot: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    setTimeDuration: (state, action: PayloadAction<number>) => {
      state.sessionDuration = action.payload;
    },
    clearForm: (state) => {
      state.loading = false;
      state.form.id.value = 0;
      state.form.organizationId.value = 0;
      state.form.departmentId.value = 0;
      state.form.bankId.value = 0;
      state.form.treatmentId.value = 0;
      state.form.instructorId.value = 0;
      state.form.street.value = "";
      state.form.cityId.value = 0;
      state.form.slotDate.value = null;
      state.form.type = TimeSlotMode.SINGLE;
      state.mode = TimeSlotMode.SINGLE;
      state.form.hasPassedSessions = false;
      state.involvedPatientList.items = [];
      state.sessionList.items = [];
      state.timeslotBonusAssociation.items = [];
    },
  },
});

export const { reducer, actions, name: sliceKey } = TimeSlotActionListSlice;
function sortedSessionList(items: TimeSlotsActionsListItemType[]) {
  let activeSessionNumber = 1;
  return items
    .sort((a: any, b: any) => {
      return (
        new Date(a.start_time).getTime() - new Date(b.start_time).getTime()
      );
    })
    .map((m) => {
      if (m.isActive == false) {
        m.sessionNumber = -1;
      } else {
        m.sessionNumber = activeSessionNumber++;
      }
      return m;
    });
}
