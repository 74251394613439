import { PayloadAction } from "@reduxjs/toolkit";
import { FormError } from "app/containers/types";
import set from "lodash/set";
import { createSlice } from "utils/@reduxjs/toolkit";
import { OrganizationsListItemType, UserState } from "../types";

const initialState = UserState;

export const organizationsListSlice = createSlice({
  name: "organizationListState",
  initialState,
  reducers: {
    editUser: (state, action: PayloadAction<OrganizationsListItemType>) => {},
    removeUser: (state) => {
      const editUser: any = {};
      editUser.firstName = "";
      editUser.lastName = "";
      editUser.email = "";
      editUser.phoneNumber = "";
      editUser.creationDate = null;
      editUser.dateOfBirth = null;
    },
    create: (state, action) => {
      state.loading = true;
    },
    getDataById: (state, action: PayloadAction<string>) => {
      state.loading = true;
    },
    createSuccess: (state) => {
      state.loading = false;
      state.form.user = initialState.form.user;
    },
    createFailed: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },

    getList: (state) => {
      state.loading = true;
    },
    getUserById: (state, action: PayloadAction<{ id: any }>) => {
      state.loading = true;
    },
    setEditByIdSuccess: (state, action: any) => {
      state.loading = false;
      state.form.user.name.value = action.payload.organization.name;
      state.form.user.taxNumber.value = action.payload.organization.taxNumber;
      state.form.comments = action.payload.commens.map((x) => {
        return {
          id: x.id,
          content: x.content,
          time: x.created_at,
          name: x?.user?.firstName + " " + x?.user?.lastName,
        };
      });
    },

    getListSuccess: (
      state,
      action: PayloadAction<{ items: []; links: any; meta: any }>
    ) => {
      state.loading = false;
      const items = action.payload.items;
      state.list.items =
        action.payload.meta.currentPage === 1
          ? items
          : [...state.list.items, ...items];
      state.list.meta = action.payload.meta;
    },
    getNextPage: (state, action: PayloadAction<any>) => {
      state.query.page = state.list.meta.currentPage + 1;
      state.loading = true;
    },
    getListFailure: (state) => {
      state.loading = false;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    update: (state, action: PayloadAction<any>) => {
      state.error = "";
      state.loading = true;
    },
    updateFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state.form.user, `${action.payload.key}.value`, action.payload.value);
      set(state.form.user, `${action.payload.key}.error`, null);
      state.error = "";
    },
    updateSuccess(state, action: PayloadAction<OrganizationsListItemType>) {
      state.loading = false;
    },
    updateFailed: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    delete: (state) => {
      state.error = "";
      state.loading = true;
    },
    deleteSuccess: (state) => {
      state.loading = false;
      state.error = "";
    },
    deleteFailed: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    resetNewUserForm(state) {
      state.error = "";
      state.form = initialState.form;
    },
    enableDisable: (
      state,
      action: PayloadAction<{ row: OrganizationsListItemType; id: number }>
    ) => {
      state.list.items[action.payload.id].isLoading = true;
    },
    enableDisableSuccess: (
      state,
      action: PayloadAction<{
        row: { organization: OrganizationsListItemType };
      }>
    ) => {
      const item = state.list.items.find(
        (i) => i.id == action.payload?.row?.organization?.id
      );
      if (item) {
        item.isLoading = false;
        item.isActive = action.payload?.row?.organization?.isActive;
      }
    },
    setFormErrors(
      state,
      action: PayloadAction<{
        key: string;
        errors: FormError[];
      }>
    ) {
      action.payload.errors.forEach((error) => {
        set(
          state.form,
          `${action.payload.key}.${error.name}.error`,
          error.error
        );
      });
    },
    getNextPageItems: (state, action: PayloadAction<any>) => {},
    getNextPageItemsSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.list.items = state.list.items.concat(action.payload.items);
      state.list.meta.currentPage = action.payload.meta.currentPage;
      state.list.links = action.payload.links;
      state.query.page = action.payload.meta.currentPage;
    },
    setQuery: (state, action: PayloadAction<{ name: string; value: any }>) => {
      const { name, value } = action.payload;
      state.query.page = 1;
      state.list = initialState.list;
      set(state.query, name, value);
    },
    resetModule: (state) => {
      state = initialState;
    },
    setComment: (state, action: PayloadAction<any>) => {
      state.form.comments.push({
        id: 0,
        content: action.payload.content,
        time: new Date().toISOString(),
        name: action.payload.name,
      });
    },
  },
});

export const { reducer, actions, name: sliceKey } = organizationsListSlice;
