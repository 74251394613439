import { Tab, Tabs } from "@mui/material";
import { CustomDrawer } from "app/components/Drawer";
import { NoPermission } from "app/components/NoPermission";
import { TabPanel } from "app/components/TabPanel";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { selectFeaturePermissions } from "store/global/authPermission/selector";
import { PermissionFeature } from "store/global/authPermission/types";
import Topbar from "../Dashboard/components/topbar";
import MultiList from "./components/MultiList";
import OpenList from "./components/OpenList";
import { useQuestionnaireSaga } from "./redux/saga";
import { selectQuery } from "./redux/selector";
import { actions, reducer, sliceKey } from "./redux/slice";
import { useStyles } from "./styles";

const Questionnaires = () => {
  useInjectReducer({ key: sliceKey, reducer });
  useInjectSaga({ key: sliceKey, saga: useQuestionnaireSaga });

  const params = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [value, setValue] = React.useState(
    params.search.split("=")[1] === "open" ? 1 : 0
  );
  const styles = useStyles();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const { q } = useSelector(selectQuery);
  const setPageQuery = (name: string, value: any) => {
    dispatch(actions.setQuery({ name, value }));
  };
  const featurePermissions = useSelector(selectFeaturePermissions);
  const { hasNoAccess } = featurePermissions[PermissionFeature.QUESTIONNAIRES];
  if (hasNoAccess) {
    return (
      <div className={styles.bg_color}>
        <CustomDrawer />
        <Topbar />
        <NoPermission />
      </div>
    );
  }
  return (
    <>
      <div className={styles.bg_color}>
        <CustomDrawer />
        <Topbar q={q} setSearch={(value) => setPageQuery("q", value)} />
        <div className={styles.right}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="tabs"
            textColor="secondary"
            indicatorColor="secondary"
            TabIndicatorProps={{ style: { background: "#012497" } }}
          >
            <Tab
              label={
                <span>{t("QUESTIONNAIRES.MULTI_CHOICE_QUESTIONNAIRES")}</span>
              }
              style={{
                fontWeight: value === 0 ? "bold" : "500",
                color: value === 0 ? "#012497" : "#2A3333",
                fontSize: "16px",
              }}
            />
            <Tab
              label={<span>{t("QUESTIONNAIRES.OPEN_QUESTIONNAIRES")}</span>}
              style={{
                fontWeight: value === 1 ? "bold" : "500",
                color: value === 1 ? "#012497" : "#2A3333",
                fontSize: "16px",
              }}
            />
          </Tabs>
          <TabPanel value={value} index={0}>
            <MultiList />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <OpenList />
          </TabPanel>
        </div>
      </div>
    </>
  );
};

export default Questionnaires;
