import { Grid } from "@mui/material";
import { BottomActions } from "app/components/BottomActions";
import DataTable from "app/components/DataTable";
import { CustomDrawer } from "app/components/Drawer";
import { ScrollableView } from "app/components/ScrollableView";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Topbar from "../../../Dashboard/components/topbar";

import { selectFeaturePermissions } from "store/global/authPermission/selector";
import { PermissionFeature } from "store/global/authPermission/types";
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import { selectList, selectLoading, selectQuery } from "../../redux/selector";
import { actions } from "../../redux/slice";
import { useStyles } from "./styles";

// treatment types
const TreatmentTypesList = () => {
  const { meta, items } = useSelector(selectList);
  const { q, orderField, orderDirection } = useSelector(selectQuery);
  const styles = useStyles();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { t } = useTranslation();

  const hasMoreItems = meta.currentPage < meta.totalPages;
  const getNextPage = () => {
    if (!loading) {
      dispatch(actions.getNextPage(meta.currentPage + 1));
    }
  };

  const columns = [
    {
      id: "name",
      label: t("TREATMENT_TYPES.TREATMENT_TYPE_NAME"),
      align: "left",
      sortValue: "selector.name",
      minWidth: 180,
      maxWidth: 280,
    },
    {
      id: "type",
      label: t("TREATMENT_TYPES.GROUP_INDIVIDUAL"),
      sortValue: "selector.type",
      align: "left",
      minWidth: 180,
      maxWidth: 280,
    },
    {
      id: "description",
      label: t("TREATMENT_TYPES.DESCRIPTION"),
      sortValue: "selector.description",
      align: "left",
      minWidth: 180,
      maxWidth: 280,
      format: (row, index) => {
        return row.description;
      },
    },
    {
      id: "session_duration",
      label: t("TREATMENT_TYPES.SESSION_LENGTH"),
      sortValue: "selector.session_duration",
      align: "left",
      minWidth: 230,
      maxWidth: 340,
    },
    {
      id: "session_count",
      label: t("TREATMENT_TYPES.NUMBER_OF_SESSIONS"),
      sortValue: "selector.session_count",
      align: "left",
      minWidth: 180,
      maxWidth: 320,
    },
    {
      id: "maximum_participants",
      label: t("TREATMENT_TYPES.MAXIMUM_PARTICIPANTS"),
      sortValue: "selector.maximum_participants",
      align: "left",
      minWidth: 240,
      maxWidth: 320,
    },
    {
      id: "treatmentBanksCount",
      label: t("TREATMENT_TYPES.TREATMENTS_BANKS_COUNT"),
      sortValue: "selector.treatmentBanksCount",
      align: "left",
      minWidth: 240,
      maxWidth: 320,
    },
  ];

  useEffect(() => {
    dispatch(actions.getList());
  }, [q, orderField, orderDirection]);

  const setPageQuery = (name: string, value: any) => {
    dispatch(actions.setQuery({ name, value }));
  };
  const featurePermissions = useSelector(selectFeaturePermissions);
  const { hasFullAccess, hasReadAccess, hasNoAccess } =
    featurePermissions[PermissionFeature.TREATMENT_TYPES];
  const handleEdit = (row) => {
    navigate(`/treatment-types/${row.id}/edit`);
  };

  const loading = useSelector(selectLoading);

  const onRowDoubleClick = (row) => {
    navigate(`/treatment-types/${row.id}/view`);
  };

  const handleAdd = () => {
    navigate("/treatment-types/new");
  };
  const { height } = useWindowDimensions();
  return (
    <div className={styles.mainContainer}>
      <CustomDrawer />
      <Grid item xs={12}>
        <Topbar q={q} setSearch={(val) => setPageQuery("q", val)} />
        <div className={styles.root}>
          <>
            <ScrollableView
              hasMoreItems={hasMoreItems}
              loadMore={getNextPage}
              height={height - 180}
            >
              <DataTable
                loading={loading}
                orderDirection={orderDirection}
                orderField={orderField}
                setQuery={setPageQuery}
                rows={items}
                columns={columns}
                Actions={hasFullAccess ? "Edit" : ""}
                page="treatmentTypes"
                editAction={handleEdit}
                onRowDoubleClick={onRowDoubleClick}
                isPointerCursor={true}
              />
            </ScrollableView>
          </>
          <BottomActions
            disabled={!hasFullAccess}
            chipBoxes={[
              {
                label: t("PLACEHOLDERS.TOTAL_ITEMS"),
                count: meta.totalItems,
              },
            ]}
            onClickFab={handleAdd}
          />
        </div>
      </Grid>
    </div>
  );
};
export default TreatmentTypesList;
