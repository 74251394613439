import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { styled } from "@mui/system";

import TableLoader from "app/components/DataTable/components/TableLoader";
import { UserPermission } from "app/containers/UserGroups/types";
import PermissionTableRow from "./components/PermissionTableRow";
import { useStyles } from "./styles";

export const TableRowCell = styled(TableCell)({
  textAlign: "center",
  background: "#FFF",
  color: "#FFFFFF",
  fontSize: "14px",
  lineHeight: "18px",
  "&:first-of-type": {
    textAlign: "left",
    borderRight: "1px solid #CCCDCF",
    padding: "16px",
    background: "#F7F7FA",
    maxWidth: 302,
  },
});

interface HeadingCellProps extends TableCellProps {
  otherCellBackground?: any;
  otherCellBorder?: any;
  headingCellBorder?: any;
  headingCellPadding?: any;
}

export const HeadingCell = (props: HeadingCellProps) => {
  return (
    <TableRow
      sx={{
        backgroundColor: props.style?.backgroundColor
          ? props.style?.backgroundColor
          : "red",
      }}
    >
      <TableCell
        sx={{
          p: "8px 16px",
          borderRight: "1px solid #CCCDCF",
          fontWeight: props.style?.fontWeight ? props.style.fontWeight : 700,
          color: "#8F8E8E",
          paddingLeft: props.style?.paddingLeft
            ? props.style?.paddingLeft
            : "16px",
        }}
        {...props}
        colSpan={1}
        style={{
          borderBottom: props.headingCellBorder
            ? props.headingCellBorder
            : props.style?.borderBottom,
          padding: props.headingCellPadding
            ? props.headingCellPadding
            : props.style?.padding,
        }}
      />
      <TableCell
        colSpan={(props.colSpan ? props.colSpan : 4) - 1}
        style={{
          backgroundColor: props?.otherCellBackground
            ? props?.otherCellBackground
            : props.style?.background,
          borderBottom: props.otherCellBorder
            ? props?.otherCellBorder
            : props.style?.borderBottom,
        }}
      ></TableCell>
    </TableRow>
  );
};

export const PermissionGroupTable = ({
  rowsData,
  mode,
  isLoading,
}: {
  rowsData: UserPermission[];
  mode: string;
  isLoading?: boolean;
}) => {
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <TableContainer
      component={Paper}
      className={clsx(styles.container, styles.dataTableDiv)}
      sx={{ marginTop: "30px" }}
    >
      <Table sx={{ minWidth: 650 }} className={styles.table} stickyHeader>
        <TableHead className={styles.cellHead}>
          <TableRow>
            <TableCell className={styles.cellHead} align="left">
              {t("TABLES.FEATURE_NAME")}
            </TableCell>
            <TableCell className={styles.cellHead} align="center">
              {t("TABLES.NO_ACCESS")}
            </TableCell>
            <TableCell className={styles.cellHead} align="center">
              {t("TABLES.READ_ONLY")}
            </TableCell>
            <TableCell className={styles.cellHead} align="center">
              {t("TABLES.FULL_ACCESS")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={styles.tableBody}>
          {isLoading && (
            <TableRow>
              <TableRowCell colSpan={12} sx={{ textAlign: "center" }}>
                <TableLoader />
              </TableRowCell>
            </TableRow>
          )}
          {rowsData.map((item, index) => (
            <PermissionTableRow
              mode={mode}
              index={index}
              key={index}
              data={item}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
