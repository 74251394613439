import { Delete } from "@mui/icons-material";
import { Button, Grid, TextField, Tooltip } from "@mui/material";
import BreadCrumb from "app/components/BreadCrumb";
import { ActionButtons } from "app/components/Button/ActionButtons";
import ConfirmDialog from "app/components/ConfirmDialog";
import { CustomDrawer } from "app/components/Drawer";
import { NoPermission } from "app/components/NoPermission";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import {
  selectFeaturePermissions,
  selectIsAdminRole,
} from "store/global/authPermission/selector";
import { PermissionFeature } from "store/global/authPermission/types";
import { translatedBreadCrumb } from "styles/theme/utils";
import Topbar from "../../../Dashboard/components/topbar";
import { useUsersGroupListSaga } from "../../redux/saga";
import { selectForm, selectLoading } from "../../redux/selector";
import { actions, reducer, sliceKey } from "../../redux/slice";
import { PermissionGroupTable } from "./components/PermissionGroupTable";
import { useStyles } from "./styles";

interface Props {
  mode: string;
}
const Details = ({ mode }: Props) => {
  useInjectReducer({ key: sliceKey, reducer });
  useInjectSaga({ key: sliceKey, saga: useUsersGroupListSaga });

  const navigate = useNavigate();
  const styles = useStyles();
  const [openDelete, setDelete] = useState(false);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const isAdminRole = useSelector(selectIsAdminRole);

  const form = useSelector(selectForm);
  const isLoading = useSelector(selectLoading);

  useEffect(() => {
    if (mode === "new") {
      dispatch(actions.resetForm());
    }
  }, [mode]);

  const handleChange = (key, value) => {
    dispatch(
      actions.updateFormValue({
        key,
        value,
      })
    );
  };

  const handleDelete = () => {
    setDelete(true);
  };

  const handleEditButton = () => {
    navigate(`/user-groups/${id}/edit`);
  };

  const handleDeleteClose = () => {
    setDelete(false);
  };

  const handleDeleteSave = () => {
    setDelete(false);
    dispatch(
      actions.delete({
        id: id,
        navigate,
      })
    );
  };

  const handleModalSave = () => {
    if (mode === "edit") {
      dispatch(
        actions.update({
          id: id,
          navigate,
        })
      );
    } else {
      dispatch(
        actions.create({
          navigate,
        })
      );
    }
  };
  useEffect(() => {
    if (mode === "new") return;
    dispatch(
      actions.getDataById({
        id: id,
      })
    );
  }, [id, mode, dispatch]);

  const featurePermissions = useSelector(selectFeaturePermissions);
  const { hasNoAccess } = featurePermissions[PermissionFeature.USER_LIST];

  if ((!isAdminRole && (mode === "new" || mode === "edit")) || hasNoAccess) {
    return (
      <div>
        <CustomDrawer />
        <Topbar />
        <NoPermission />
      </div>
    );
  }

  return (
    <Grid className={styles.mainContainer}>
      <CustomDrawer />
      <Grid item xs={12}>
        <Topbar />
        <div className={styles.root}>
          <Grid container spacing={3} marginTop="0px">
            <BreadCrumb
              onBackClick={() => navigate("/user-groups")}
              title={t("USER_GROUPS.USER_GROUPS")}
              subTitle={t(`USERS.${translatedBreadCrumb(mode)}`)}
            />
            {mode !== "new" && (
              <Grid container item xs={2} direction="column">
                <Tooltip
                  title={
                    form.numberOfUser > 0
                      ? t("TOOLTIP.DELETE_GROUP_DESCRIPTION", {
                          number: form.numberOfUser,
                        })
                      : t("TOOLTIP.DELETE_THIS_GROUP")
                  }
                >
                  <span>
                    <Button
                      onClick={handleDelete}
                      className={styles.deleteGroupBtn}
                      variant="outlined"
                      disabled={form.numberOfUser > 0 || isLoading}
                    >
                      <Delete
                        sx={{
                          color: form.numberOfUser > 0 ? "#C6C9CA" : "#FF4839",
                          marginRight: "10px",
                        }}
                      />{" "}
                      {t("USER_GROUPS.DELETE_USER_GROUPS")}
                    </Button>
                  </span>
                </Tooltip>
              </Grid>
            )}
          </Grid>

          <Grid xs={12} container spacing={2}>
            <Grid item xs={2} style={{ width: "20%" }}>
              <label className={styles.label}>{t("USERS.GROUP_NAME")}</label>
              <TextField
                //disabled={true}
                variant="outlined"
                fullWidth
                margin="dense"
                required
                autoComplete={"off"}
                name="groupName"
                disabled={mode === "view" || isLoading}
                placeholder={t("USERS.ENTER_GROUP_NAME")}
                className={styles.textEnabled}
                value={form.groupName}
                onChange={(e) => handleChange("groupName", e.target.value)}
              />
            </Grid>
            <Grid item xs={2} style={{ width: "20%" }}>
              <label className={styles.label}>
                {t("USERS.NUMBER_OF_USERS")}
              </label>
              <TextField
                margin="dense"
                required
                id="noOfUsers"
                placeholder={t("USERS.ENTER_NUMBER_OF_USERS")}
                name="noOfUsers"
                disabled
                value={form.numberOfUser}
                autoComplete={"off"}
                className={styles.textFieldStyle}
              />
            </Grid>
          </Grid>
          <div style={{ width: "100%", marginBottom: "20px" }}>
            <PermissionGroupTable
              mode={mode}
              rowsData={form.userGroupsPermissions}
              isLoading={isLoading}
            />
          </div>
          <ConfirmDialog
            open={openDelete}
            title={t("USERS.DELETE_USER_GROUP")}
            description={`${t("USERS.CONFIRM_DELETE_USER_GROUP", {
              value: form.groupName,
            })}`}
            onCancel={handleDeleteClose}
            onConfirm={handleDeleteSave}
            onClose={handleDeleteClose}
          />
          <Grid
            container
            columnGap={3}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            {mode == "view" ? (
              isAdminRole && (
                <Grid>
                  <Button
                    className={styles.saveBtn}
                    variant="contained"
                    onClick={handleEditButton}
                    disabled={isLoading}
                  >
                    {t("BUTTONS.EDIT")}
                  </Button>
                </Grid>
              )
            ) : (
              <ActionButtons
                loading={form.isLoading}
                disabled={isLoading}
                onCancel={() => {
                  navigate("/user-groups");
                }}
                onSave={handleModalSave}
              />
            )}
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};
export default Details;
