import { BottomActions } from "app/components/BottomActions";
import DataTable from "app/components/DataTable";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { selectMultiList, selectMultiQuery } from "../../redux/selector";

import { ScrollableView } from "app/components/ScrollableView";
import { selectFeaturePermissions } from "store/global/authPermission/selector";
import { PermissionFeature } from "store/global/authPermission/types";
import { getFormattedDateTime } from "utils/helpers";
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import { actions } from "../../redux/slice";
import { useStyles } from "./styles";

const MultiList = () => {
  function editOnClickMulti(val) {
    navigate(`/questionnaires/multi/${val.id}?mode=edit`);
  }

  function onClickMulti(val) {
    navigate(`/questionnaires/multi/${val.id}?mode=view`);
  }

  const { q, orderField, orderDirection } = useSelector(selectMultiQuery);
  const { items, meta, loading } = useSelector(selectMultiList);

  useEffect(() => {
    dispatch(actions.getMultiList());
  }, [q, orderField, orderDirection]);

  const { t } = useTranslation();

  const columns = [
    {
      id: "created_at",
      label: t("QUESTIONNAIRES.DATE_CREATED"),
      sortValue: "selector.createdAt",
      align: "left",
      format: (val) => {
        return getFormattedDateTime(val.createdAt);
      },
    },
    {
      id: "updated_at",
      label: t("QUESTIONNAIRES.DATE_EDITED"),
      sortValue: "selector.updatedAt",
      align: "left",
      format: (val) => {
        return getFormattedDateTime(val.updatedAt);
      },
    },
    {
      id: "name",
      label: t("QUESTIONNAIRES.QUESTIONNAIRE_NAME"),
      sortValue: "selector.name",
      align: "left",
    },
    {
      id: "totalQuestions",
      label: t("QUESTIONNAIRES.NUMBER_OF_QUESTIONS"),
      sortValue: "selector.totalQuestions",
      align: "left",
    },
    {
      id: "organizationName",
      label: t("QUESTIONNAIRES.ORGANIZATION_NAME"),
      align: "left",
    },
  ];

  const dispatch = useDispatch();
  const styles = useStyles();
  const navigate = useNavigate();
  const setPageQuery = (name: string, value: any) => {
    dispatch(actions.setQuery({ name, value }));
  };

  const handleAddMulti = () => {
    navigate("/questionnaires/multi/add?mode=add");
  };
  const hasMoreItems = meta.currentPage < meta.totalPages;
  const getNextPage = () => {
    if (!loading) {
      dispatch(actions.getMultiNextPage(meta.currentPage + 1));
    }
  };
  const featurePermissions = useSelector(selectFeaturePermissions);
  const { hasFullAccess, hasReadAccess, hasNoAccess } =
    featurePermissions[PermissionFeature.QUESTIONNAIRES];
  const { height } = useWindowDimensions();
  return (
    <>
      <ScrollableView
        loadMore={getNextPage}
        hasMoreItems={hasMoreItems}
        height={height - 280}
      >
        <DataTable
          loading={loading}
          orderDirection={orderDirection}
          orderField={orderField}
          setQuery={setPageQuery}
          rows={items}
          Actions={hasFullAccess ? "Edit" : ""}
          columns={columns}
          editAction={editOnClickMulti}
          onRowDoubleClick={onClickMulti}
        />
      </ScrollableView>
      <BottomActions
        disabled={!hasFullAccess}
        chipBoxes={[
          {
            label: t("PLACEHOLDERS.TOTAL_ITEMS"),
            count: meta?.totalItems,
          },
        ]}
        onClickFab={handleAddMulti}
      />
    </>
  );
};

export default MultiList;
