import { makeStyles } from "@mui/styles";

export const Styles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  container: {
    minHeight: "150px",
    borderRadius: "7px 7px 0px 0px",
    border: "none",
    overflow: "auto",
    fontFamily: "Cairo",
  },
  drawerListItems: {
    backgroundColor: "#EBF1FF",
    borderRight: "4px solid #012497",
    lineHeight: "140%",
    textDecoration: "none",
  },
  drawerLink: {
    textDecoration: "none",
    fontStyle: "normal",
    fontWeight: "500 !important",
    color: "#6e758e",
  },
  drawerListItemText: {
    fontStyle: "normal",
    fontWeight: "700 !important",
  },
  logoImage: {
    padding: "12px 65px 0px 19px",
    width: "auto",
  },
});
