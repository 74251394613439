export interface ResponseError {
  message: string;
}
export type RequestError = ResponseError;

export type UsersListItemType = {
  id?: undefined;
  groupName: string;
  numberOfUser: number;
};

export type Form = {
  id?: undefined;
  enable: boolean;
  groupName: string;
  numberOfUser: number;
  userGroupsPermissions: UserPermission[];
  isLoading?: boolean;
};

export type PermissionListItemType = {
  id: number;
  featureName: string;
  noAccess: boolean;
  readAccess: boolean;
  fullAccess: boolean;
  createdAt: string;
  updateAt: string;
  userGroupId: number;
};

export type usersGroupStateType = {
  query: {
    limit: number;
    page: number;
    q: string;
    orderField: string;
    orderDirection: string;
  };
  form: Form;
  list: {
    items: Array<UsersListItemType>;
    meta: {
      totalItems: number;
      itemCount: number;
      itemsPerPage: number;
      totalPages: number;
      currentPage: number;
    };
    links: {
      first: string;
      last: string;
      previous: string;
      next: string;
    };
  };
  loading: boolean;
  error: string;
};

export type DisabledActionsType = ["noAccess", "readAccess", "fullAccess"] | [];
export type UserPermission = {
  id?: number;
  featureName: string;
  noAccess: boolean;
  fullAccess: boolean;
  readAccess: boolean;
  disabledActions: DisabledActionsType;
};

export const defaultPermissionList = [
  {
    featureName: "USERS.DASHBOARD",
    noAccess: true,
    readAccess: false,
    fullAccess: false,
    disabledActions: [],
  },
  {
    featureName: "USERS.ORGANIZATIONS_LIST",
    noAccess: true,
    readAccess: false,
    fullAccess: false,
    disabledActions: ["fullAccess"],
  },
  {
    featureName: "USERS.ORGANIZATIONS_DETAILS",
    noAccess: true,
    readAccess: false,
    fullAccess: false,
    disabledActions: [],
  },
  {
    featureName: "USERS.TREATMENT_BANK_LIST",
    noAccess: true,
    readAccess: false,
    fullAccess: false,
    disabledActions: [],
  },
  {
    featureName: "USERS.USER_LIST",
    noAccess: true,
    readAccess: false,
    fullAccess: false,
    disabledActions: ["fullAccess"],
  },
  {
    featureName: "USERS.USER_GROUPS",
    noAccess: true,
    readAccess: false,
    fullAccess: false,
    disabledActions: ["fullAccess"],
  },
  {
    featureName: "USERS.PATIENTS_LIST",
    noAccess: true,
    readAccess: false,
    fullAccess: false,
    disabledActions: [],
  },
  {
    featureName: "USERS.PATIENT_DETAILS_STATUS",
    noAccess: true,
    readAccess: false,
    fullAccess: false,
  },
  {
    featureName: "USERS.PATIENT_DETAILS_PRIVATE",
    noAccess: true,
    readAccess: false,
    fullAccess: false,
    disabledActions: [],
  },
  {
    featureName: "USERS.PATIENT_DETAILS_PROGRESS",
    noAccess: true,
    readAccess: false,
    fullAccess: false,
    disabledActions: [],
  },
  {
    featureName: "USERS.ASSIGNED_TREATNEMTS_A",
    noAccess: true,
    readAccess: false,
    fullAccess: false,
    disabledActions: [],
  },
  {
    featureName: "USERS.ASSIGNED_TREATNEMTS_B",
    noAccess: true,
    readAccess: false,
    fullAccess: false,
    disabledActions: [],
  },
  {
    featureName: "USERS.TREATMENT_TYPES",
    noAccess: true,
    readAccess: false,
    fullAccess: false,
    disabledActions: [],
  },
  {
    featureName: "USERS.DAILY_ACTIVITY",
    noAccess: true,
    readAccess: false,
    fullAccess: false,
    disabledActions: [],
  },
  {
    featureName: "USERS.PUSH_NOTIFICATION",
    noAccess: true,
    readAccess: false,
    fullAccess: false,
    disabledActions: [],
  },
  {
    featureName: "USERS.TIMESLOTS",
    noAccess: true,
    readAccess: false,
    fullAccess: false,
    disabledActions: [],
  },
  {
    featureName: "USERS.QUESTIONNAIRES",
    noAccess: true,
    readAccess: false,
    fullAccess: false,
    disabledActions: [],
  },
  {
    featureName: "USERS.QUESTIONNAIRES_RESULTS",
    noAccess: true,
    readAccess: false,
    fullAccess: false,
    disabledActions: [],
  },
] as UserPermission[];

export const UserGroupState: usersGroupStateType = {
  query: {
    limit: 10,
    page: 1,
    q: "",
    orderField: "",
    orderDirection: "",
  },
  list: {
    items: [],
    meta: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 100,
      totalPages: 1,
      currentPage: 1,
    },
    links: {
      first: "",
      previous: "",
      next: "",
      last: "",
    },
  },
  loading: false,
  form: {
    enable: false,
    id: undefined,
    groupName: "",
    numberOfUser: 0,
    userGroupsPermissions: defaultPermissionList as any,
    isLoading: false,
  },
  error: "",
};

export type ContainerState = usersGroupStateType;
