import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "16px",
        flexDirection: "column",
        marginBottom: "40px",
    },
    imgWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "80px",
        height: "80px",
        borderRadius: "40px",
        background: "#D9D9D9",
        position: "relative",
    },
    text: {
        color: "#3D63E6",
        fontFamily: "Roboto",
        fontSize: "18px",
        fontWeight: 600,
        cursor: "pointer"
    }
}));
