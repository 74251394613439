import { TComments } from "app/containers/Patients/types";
import queryString from "query-string";
import { delay, put, select, takeLatest } from "redux-saga/effects";
import { API_URL } from "utils/constants";
import {
  TRANSLATE,
  getDefaultHeaders,
  removeBlankProperties,
  safeParseJSON,
} from "utils/helpers";
import { request } from "utils/request";
import toastService from "utils/toast";
import { selectList as selectContacts } from "../components/Details/Contacts/redux/selector";
import { selectList as selectDepartment } from "../components/Details/Department/redux/selector";
import { selectList as QuestionnaireList } from "../components/Details/Questionnaire/redux/selector";
import { UserForm } from "../types";
import { selectEditUser, selectEditcommnts, selectQuery } from "./selector";
import { actions } from "./slice";

export function* update(action) {
  yield delay(500);
  try {
    const from: UserForm = yield select(selectEditUser);
    const comments: TComments[] = yield select(selectEditcommnts);
    const conntact = yield select(selectContacts);
    const Department = yield select(selectDepartment);
    const Questionnaire = yield select(QuestionnaireList);
    if (from.name.value.trim().length == 0) {
      throw Error('{"message":"ORGANIZATIONS.PLEASE_ENTER_ORGANIZATION_NAME"}');
    }
    if (from.taxNumber.value.trim().length == 0) {
      throw Error('{"message":"ORGANIZATIONS.PLEASE_ENTER_TAX_NUMBER"}');
    }

    if (conntact.items.filter((item) => item.action !== "delete").length == 0) {
      throw Error('{"message":"ORGANIZATIONS.PLEASE_ENTER_ONE_CONTACT"}');
    }
    if (
      Questionnaire.items.filter((item) => item.action !== "delete").length == 0
    ) {
      throw Error('{"message":"ORGANIZATIONS.PLEASE_ENTER_ONE_QUESTIONNAIRE"}');
    }
    const newOrganization = {
      organization: { name: from.name.value, taxNumber: from.taxNumber.value },
      department: Department.items,
      contact: conntact.items,
      questionnaire: Questionnaire.items,
      comments: comments,
    };
    const requestOptions = {
      method: "PATCH",
      headers: getDefaultHeaders(),
      body: JSON.stringify(newOrganization),
    };
    const url = `${API_URL}/v1/organization/${action.payload.id}`;
    yield request(url, requestOptions);
    yield put(actions.getList());
    toastService.success(TRANSLATE("ORGANIZATIONS.DATA_UPDATED_SUCCESSFULLY"));
    action.payload.navigate("/organizations");
  } catch (e) {
    const error = e as Error;
    const { message } = safeParseJSON(error.message);
    yield put(actions.updateFailed(message));
    toastService.error(
      TRANSLATE(message, {
        defaultValue: "ERROR_IN_ORGANIZATION",
      })
    );
  }
}

export function* handleEnableDisable(action) {
  yield delay(500);
  try {
    const options = {
      method: "PATCH",
      headers: getDefaultHeaders(),
    };
    const res = yield request(
      `${API_URL}/v1/organization/${action?.payload.row.id}/status`,
      options
    );

    yield put(actions.enableDisableSuccess({ row: res }));
    toastService.success(TRANSLATE("ORGANIZATIONS.DATA_UPDATED_SUCCESSFULLY"));
  } catch (e: any) {
    const message = JSON.parse(e.message)?.message;
    yield put(actions.updateFailed(message));
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", {
        error: JSON.stringify(e),
      })
    );
  }
}

export function* create(action) {
  yield delay(500);
  try {
    const from: UserForm = yield select(selectEditUser);
    const comments: TComments[] = yield select(selectEditcommnts);
    const conntact = yield select(selectContacts);
    const Department = yield select(selectDepartment);
    const Questionnaire = yield select(QuestionnaireList);

    if (from.name.value.trim().length == 0) {
      throw Error('{"message":"ORGANIZATIONS.PLEASE_ENTER_ORGANIZATION_NAME"}');
    }
    if (from.taxNumber.value.trim().length == 0) {
      throw Error('{"message":"ORGANIZATIONS.PLEASE_ENTER_TAX_NUMBER"}');
    }
    if (conntact.items.length == 0) {
      throw Error('{"message":"ORGANIZATIONS.PLEASE_ENTER_ONE_CONTACT"}');
    }
    if (Questionnaire.items.length == 0) {
      throw Error('{"message":"ORGANIZATIONS.PLEASE_ENTER_ONE_QUESTIONNAIRE"}');
    }
    const newOrganization = {
      organization: { name: from.name.value, taxNumber: from.taxNumber.value },
      department: Department.items,
      contact: conntact.items,
      questionnaire: Questionnaire.items,
      comments: comments,
    };
    const requestOptions = {
      method: "POST",
      headers: getDefaultHeaders(),
      body: JSON.stringify(newOrganization),
    };
    const url = `${API_URL}/v1/organization`;
    yield request(url, requestOptions);
    yield put(actions.getList());
    toastService.success(TRANSLATE("ORGANIZATIONS.DATA_UPDATED_SUCCESSFULLY"));
    action.payload.navigate("/organizations");
  } catch (e) {
    const error = e as Error;
    const { message } = safeParseJSON(error.message);
    yield put(actions.createFailed(message));
    toastService.error(
      TRANSLATE(message, {
        defaultValue: "ERROR_IN_ORGANIZATION",
      })
    );
  }
}

export function* getList(action) {
  yield delay(500);
  const query = yield select(selectQuery);
  const requestData = removeBlankProperties(query);
  const queries = queryString.stringify({
    ...requestData,
  });
  try {
    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    const response = yield request(
      `${API_URL}/v1/organization?${queries}`,
      options
    );
    yield put(actions.getListSuccess(response));
  } catch (e: any) {
    yield put(actions.getListFailure());
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", {
        error: JSON.stringify(e),
      })
    );
  }
}
export function* getById(action) {
  yield delay(500);

  try {
    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    const response = yield request(
      `${API_URL}/v1/organization/${action.payload}`,
      options
    );

    yield put(actions.setEditByIdSuccess(response));
  } catch (e: any) {
    yield put(actions.setLoading(false));
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", {
        error: JSON.stringify(e),
      })
    );
  }
}

export function* deleteRow() {}

export function* useOrganizationSaga() {
  yield takeLatest(actions.getList.type, getList);
  yield takeLatest(actions.getNextPage.type, getList);
  yield takeLatest(actions.getDataById.type, getById);
  yield takeLatest(actions.create.type, create);
  yield takeLatest(actions.update.type, update);
  yield takeLatest(actions.delete.type, deleteRow);
  yield takeLatest(actions.enableDisable.type, handleEnableDisable);
}
