import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Box,
  CircularProgress,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { TextField } from "app/components/TextField";
import { actions } from "app/containers/AssignedTreament/redux/slice";
import { AvailableTimeslotsResponse } from "app/containers/AssignedTreament/types";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectFeaturePermissions } from "store/global/authPermission/selector";
import { PermissionFeature } from "store/global/authPermission/types";
import { useStyles } from "./styles";
type Props = {
  availableTimeslotsResponse: AvailableTimeslotsResponse;
  maxTreatmentAmount: number;
  minTreatmentAmount: number;
};
export const TimeslotDetails = ({
  maxTreatmentAmount,
  minTreatmentAmount,
  availableTimeslotsResponse,
}: Props) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const featurePermissions = useSelector(selectFeaturePermissions);
  const assigningPermission =
    featurePermissions[PermissionFeature.ASSIGNED_TREATNEMTS_A];
  const schedulingPermission =
    featurePermissions[PermissionFeature.ASSIGNED_TREATNEMTS_B];

  const treatmentInvolvedPatient =
    availableTimeslotsResponse.treatmentInvolvedPatient;
  const treatmentBankTreatment =
    treatmentInvolvedPatient.treatmentBankTreatment;
  const optionalInstructor = treatmentBankTreatment.optionalInstructor;
  const treatment = treatmentBankTreatment?.treatment;
  const changeInstructor = (event: any) => {
    dispatch(
      actions.handleChangeInstructor({ instructorId: event.target.value })
    );
  };

  useEffect(() => {
    if (treatmentInvolvedPatient.instructorId) {
      dispatch(
        actions.getInstructorTimeslots({
          instructorId: treatmentInvolvedPatient.instructorId,
        })
      );
    }
  }, [treatmentInvolvedPatient.instructorId]);

  const timeslotInvolvedPatients =
    availableTimeslotsResponse.treatmentInvolvedPatient.timeslotInvolvedPatients.filter(
      (m) => m.option !== "delete"
    );

  const hasTimeSlotIdEntry = timeslotInvolvedPatients.some(
    (tiP) => tiP.timeSlotId
  );

  return (
    <Grid
      container
      spacing={2}
      sx={{
        padding: "10px",
        justifyContent: "space-between",
      }}
    >
      <Grid spacing={2} container xs={12}>
        <Grid item xs={5}>
          <Typography noWrap className={styles.label}>
            {t("ASSIGNED_TREATMENT.PATIENT_NAME")}
          </Typography>
          <TextField
            disabled={true}
            variant="outlined"
            margin="none"
            fullWidth
            id="treatmentName"
            name="treatmentName"
            value={`${treatmentInvolvedPatient?.patient?.firstName} ${treatmentInvolvedPatient?.patient?.lastName}`}
            className={styles.textEnabled}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography noWrap className={styles.label}>
            {t("ASSIGNED_TREATMENT.SELECTED_INSTRUCTOR")}
          </Typography>
          <Select
            disabled={!assigningPermission.hasFullAccess || hasTimeSlotIdEntry}
            labelId="instructor"
            id="instructor"
            disableUnderline
            IconComponent={KeyboardArrowDownIcon}
            value={treatmentInvolvedPatient.instructorId}
            className={styles.textEnabledSelect}
            margin="dense"
            fullWidth
            variant="outlined"
            onChange={changeInstructor}
            endAdornment={
              availableTimeslotsResponse.timeslotsLoading ? (
                <CircularProgress className={styles.loader} />
              ) : null
            }
          >
            {optionalInstructor?.map((data) => (
              <MenuItem value={data?.treatmentOptionalInstructor?.id}>
                {data.treatmentOptionalInstructor.firstName}{" "}
                {data.treatmentOptionalInstructor.lastName}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={3}>
          <Typography noWrap className={styles.label}>
            {t("ASSIGNED_TREATMENT.TREATMENT_AMOUNT")}
          </Typography>
          <TextField
            disabled={true}
            variant="outlined"
            margin="none"
            fullWidth
            id="treatmentName"
            name="treatmentName"
            value={timeslotInvolvedPatients.length}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Box
                    display={"flex"}
                    justifyContent="center"
                    flexDirection="column"
                    mr={-1.5}
                  >
                    <Typography style={{ color: "#999" }} mb={"3px"}>
                      Max: {maxTreatmentAmount}
                    </Typography>
                    <Typography style={{ color: "#999" }} mt={"3px"}>
                      Min: {minTreatmentAmount}
                    </Typography>
                  </Box>
                </InputAdornment>
              ),
            }}
            className={styles.textEnabled}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
