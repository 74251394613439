import { PayloadAction } from "@reduxjs/toolkit";
import set from "lodash/set";
import { createSlice } from "utils/@reduxjs/toolkit";
import { ItemsEntity, NotificationState } from "../types";
const initialState = NotificationState;
export const pushNotificationSlice = createSlice({
  name: "pushNotificationsState",
  initialState,
  reducers: {
    getList: (state) => {
      state.loading = true;
    },
    getById: (state, action: PayloadAction<{ id: any }>) => {
      state.form = initialState.form;
    },
    setById: (state, action: PayloadAction<any>) => {
      state.form = action.payload;
      state.form.isLoading = false;
    },
    getListSuccess: (
      state,
      action: PayloadAction<{
        items: Array<ItemsEntity>;
        links: any;
        meta: any;
      }>
    ) => {
      state.loading = false;
      state.list.meta = action.payload.meta;
      state.list.links = action.payload.links;
      const items = action.payload.items.map((item: ItemsEntity) => {
        const uniqueOrganizationNames = Array.from(
          new Set(
            item?.notificationPatients
              .map((nP) => nP?.patient?.organization?.name)
              .filter((name) => name !== undefined)
          )
        ).join(",");
        const uniqueBankNames = Array.from(
          new Set(
            item?.notificationPatients
              .map((nP) => nP?.patient?.bank?.name)
              .filter((name) => name !== undefined)
          )
        ).join(",");

        return {
          ...item,
          uniqueOrganizationNames,
          uniqueBankNames,
        };
      });
      state.list.items =
        action.payload.meta.currentPage === 1
          ? items
          : [...state.list.items, ...items];
      state.list.meta = action.payload.meta;
    },
    getNextPage: (state, action: PayloadAction<any>) => {
      state.query.page = state.list.meta.currentPage + 1;
      state.loading = true;
    },
    getListComplete: (state) => {
      state.loading = false;
    },
    getListByIdComplete: (state) => {
      state.form.isLoading = false;
    },
    update: (
      state,
      action: PayloadAction<{ id: any; callback: () => void }>
    ) => {
      state.error = "";
      state.loading = true;
    },

    getNextPageItems: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    getNextPageItemsSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.list.items = state.list.items.concat(action.payload.items);
      state.list.meta.currentPage = action.payload.meta.currentPage;
      state.list.links = action.payload.links;
      state.query.page = action.payload.meta.currentPage;
    },
    setQuery: (state, action: PayloadAction<{ name: string; value: any }>) => {
      const { name, value } = action.payload;
      state.query.page = 1;
      state.list = initialState.list;
      set(state.query, name, value);
    },
    resetModule: (state) => {
      state = initialState;
    },
    toggalModal: (state, action: PayloadAction<{ id?: number }>) => {
      state.notificationId = action.payload.id;
    },
    resetQuery: (state) => {
      state.query = initialState.query;
    },
  },
});
export const { reducer, actions, name: sliceKey } = pushNotificationSlice;
