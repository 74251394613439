import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "types";
import { FieldState } from "./types";

const selectDomain = (state?: RootState) =>
  state?.globalFieldsState || FieldState;

export const selectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);

export const selectOrganization = createSelector(
  [selectDomain],
  (state) => state.organization
);
export const selectFieldGroup = createSelector(
  [selectDomain],
  (state) => state.fieldGroup
);
export const selectFieldQualification = createSelector(
  [selectDomain],
  (state) => state.qualification
);
export const selectFieldPermission = createSelector(
  [selectDomain],
  (state) => state.patientPermission
);
export const selectDepartmentOptions = createSelector(
  [selectDomain],
  (state) => state.departments
);
export const selectBanksOptions = createSelector(
  [selectDomain],
  (state) => state.banks
);

export const selectBanksLoading = createSelector(
  [selectDomain],
  (state) => state.bankLoading
);
export const selectTreatment = createSelector(
  [selectDomain],
  (state) => state.treatment
);

export const selectUserGroups = createSelector(
  [selectDomain],
  (state) => state.userGroup
);
export const selectError = createSelector(
  [selectDomain],
  (state) => state.error
);
export const selectInstructor = createSelector(
  [selectDomain],
  (state) => state.instructor
);
export const selectActualInstructor = createSelector(
  [selectDomain],
  (state) => state.actualInstructor
);
export const selectCity = createSelector([selectDomain], (state) => state.city);
export const selectQuestionnaire = createSelector(
  [selectDomain],
  (state) => state.questionnaire
);
