import { BorderColor, Height, Margin, Repeat } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { padding } from "@mui/system";

export const useStyles = makeStyles(() => ({
  
  
  intakeHeading: {
    marginTop: "20px",
    marginLeft: '10px'
  },
}));
