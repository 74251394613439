import queryString from "query-string";
import { delay, put, select, takeLatest } from "redux-saga/effects";
import { API_URL } from "utils/constants";
import {
  TRANSLATE,
  getDefaultHeaders,
  removeBlankProperties,
} from "utils/helpers";
import { request } from "utils/request";
import toastService from "utils/toast";
import { DashboardQueryState } from "../types";
import { selectQuery } from "./selector";
import { actions } from "./slice";

export function* getListGrades(action) {
  yield delay(500);
  try {
    const query: DashboardQueryState = yield select(selectQuery);
    const requestData = removeBlankProperties(query);
    const queries = queryString.stringify({
      ...requestData,
    });

    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    const response = yield request(
      `${API_URL}/v1/dashboard/grades?${queries}`,
      options
    );
    yield put(actions.getListGradesSuccess(response));
  } catch (e: any) {
    yield put(actions.getGradesListFailure());
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", {
        error: JSON.stringify(e),
      })
    );
  }
}

export function* getQuestionerDetails(action) {
  yield delay(100);
  try {
    const query: DashboardQueryState = yield select(selectQuery);
    const requestData = removeBlankProperties(query);
    const queries = queryString.stringify({
      ...requestData,
    });

    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    const response = yield request(
      `${API_URL}/v1/dashboard/grades/${action.payload.questionnaireId}?${queries}`,
      options
    );
    yield put(actions.getQuestionerDetailsSuccess(response));
  } catch (e: any) {
    yield put(actions.getQuestionerDetailsFailure());
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", {
        error: JSON.stringify(e),
      })
    );
  }
}

export function* getListProgress(action) {
  yield delay(500);
  try {
    const query: DashboardQueryState = yield select(selectQuery);
    const requestData = removeBlankProperties(query);
    const queries = queryString.stringify({
      ...requestData,
    });

    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    const response = yield request(
      `${API_URL}/v1/dashboard/progress?${queries}`,
      options
    );
    yield put(actions.getListProgressSuccess(response));
  } catch (e: any) {
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", {
        error: JSON.stringify(e),
      })
    );
  }
}

export function* useDashboardSaga() {
  yield takeLatest(actions.getListGrades.type, getListGrades);
  yield takeLatest(actions.getListProgress.type, getListProgress);
  yield takeLatest(actions.getQuestionerDetails.type, getQuestionerDetails);
}
