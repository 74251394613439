import { Download } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { API_URL } from "utils/constants";
import { useStyles } from "./styles";

export const DownloadButton = ({
  disabled = false,
  path,
  label = "Download",
  params = {},
}: {
  path: string;
  label: string;
  params?: any;
  disabled?: boolean;
}) => {
  const [downloadUrl, setDownloadUrl] = useState<string>();
  const styles = useStyles();

  useEffect(() => {
    const authToken = localStorage.getItem("sessionToken");
    const newParams = { ...params, authToken };
    setDownloadUrl(`${API_URL}${path}?${queryString.stringify(newParams)}`);
  }, [params, path]);

  const handleDownload = () => {
    if (downloadUrl) {
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.target = "_blank";
      link.rel = "noopener noreferrer";
      link.click();
    }
  };

  return (
    <Button
      disabled={disabled}
      className={styles.gridBtnSmall}
      variant="outlined"
      color="primary"
      onClick={handleDownload}
    >
      <Download
        sx={{ color: disabled ? "#ccc" : "#3D63E6" }}
        fontSize="medium"
      />
      <Typography
        className={
          disabled ? styles.downloadLabelDisabled : styles.downloadLabel
        }
      >
        {label}
      </Typography>
    </Button>
  );
};
