import {
  Box,
  Button,
  Grid,
  InputLabel,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
// import CustomButton from 'app/components/Button'
// import TextInput from 'app/components/TextInput'
import { CustomDrawer } from "app/components/Drawer";
import ProfilePhoto from "app/components/ProfilePhoto";
import SelectInput from "app/components/SelectInput";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import toastService from "utils/toast";
import TopBar from "../Dashboard/components/topbar";
import { useProfileSaga } from "./redux/saga";
import { selectIsLoading, selectProfile } from "./redux/selectors";
import { actions, reducer, profileSliceKey as sliceKey } from "./redux/slice";
import { useStyles } from "./styles";
import { validate } from "./validator";

const LANGUAGES = [
  {
    label: "English",
    value: "en",
  },
  {
    label: "עברית",
    value: "he",
  },
];

export default function Profile({ editable = false }) {
  useInjectReducer({ key: sliceKey, reducer });
  useInjectSaga({ key: sliceKey, saga: useProfileSaga });
  const profile = useSelector(selectProfile);
  const isLoading = useSelector(selectIsLoading);

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(actions.getProfile());
  }, []);

  const handelChange = (event) => {
    dispatch(
      actions.updateFormValue({
        key: event.target.name,
        value: event.target.value,
      })
    );
  };
  const onSuccess = () => {
    toastService.success(`${t("PROFILE.CHANGES_SAVED")}`);
    navigate("/profile");
  };

  const onSubmit = () => {
    const errors = validate(profile);
    if (errors?.length > 0) {
      dispatch(actions.setFormErrors(errors));
      return;
    }
    if (!profile.language.value) return;
    dispatch(actions.updateProfile({ callback: onSuccess }));
  };
  const classes = useStyles();

  const uploadComplete = (data) => {
    dispatch(
      actions.updateFormValue({
        key: "profilePic",
        value: data.fileNameWithPrefix,
      })
    );
    dispatch(
      actions.updateFormValue({
        key: "url",
        value: data.url,
      })
    );
  };

  const fileClose = () => {
    dispatch(
      actions.updateFormValue({
        key: "profilePic",
        value: null,
      })
    );
    dispatch(
      actions.updateFormValue({
        key: "url",
        value: null,
      })
    );
  };

  return (
    <React.Fragment>
      <div className={classes.container}>
        <CustomDrawer />
        <TopBar />
        <div className={classes.root}>
          <Grid>
            <Grid2 sx={{ marginTop: "20px" }} spacing={3} container>
              <Grid2 sm={12} lg={5}>
                <Box
                  sx={{
                    direction: theme.direction,
                  }}
                >
                  <InputLabel className={classes.label}>
                    {t("PROFILE.FULL_NAME")}
                  </InputLabel>
                  <TextField
                    fullWidth
                    name="firstname"
                    placeholder={t("PROFILE.FULL_NAME")}
                    disabled={!editable || isLoading}
                    value={profile.firstName.value}
                    onChange={handelChange}
                    className={classes.input}
                    error={!!profile.firstName.error}
                    helperText={
                      profile.firstName.error ? t(profile.firstName.error) : ""
                    }
                  />
                  <TextField
                    fullWidth
                    name="lastname"
                    placeholder={t("PROFILE.FULL_NAME")}
                    disabled={!editable || isLoading}
                    value={profile.lastName.value}
                    onChange={handelChange}
                    className={classes.input}
                    helperText={
                      profile.lastName.error ? t(profile.lastName.error) : ""
                    }
                    error={!!profile.lastName.error}
                  />
                  <InputLabel className={classes.label}>
                    {t("PROFILE.PHONE_NUMBER")}
                  </InputLabel>
                  <TextField
                    fullWidth
                    name="mobileNumber"
                    placeholder={t("PROFILE.PHONE_NUMBER")}
                    disabled={!editable || isLoading}
                    value={profile.mobileNumber.value}
                    onChange={handelChange}
                    className={classes.input}
                    helperText={
                      profile.mobileNumber.error
                        ? t(profile.mobileNumber.error)
                        : ""
                    }
                    error={!!profile.mobileNumber.error}
                  />
                  <InputLabel className={classes.label}>
                    {t("PROFILE.EMAIL")}
                  </InputLabel>
                  <TextField
                    fullWidth
                    name="email"
                    placeholder={t("PROFILE.EMAIL")}
                    disabled={!editable || isLoading}
                    value={profile.email.value}
                    onChange={handelChange}
                    className={classes.input}
                    helperText={
                      profile.email.error ? t(profile.email.error) : ""
                    }
                    error={!!profile.email.error}
                  />
                  <SelectInput
                    label={t("PROFILE.LANGUAGE")}
                    containerStyle={{
                      marginBottom: "24px",
                    }}
                    inputStyle={{
                      background: "#FFFFFF",
                      fontSize: "16px",
                    }}
                    labelStyle={{
                      fontSize: "16px",
                      marginLeft: "0px",
                    }}
                    name="language"
                    disabled={!editable || isLoading}
                    options={LANGUAGES}
                    value={profile.language.value}
                    onChange={handelChange}
                    error={
                      profile.language.error ? t(profile.language.error) : ""
                    }
                  />
                </Box>
              </Grid2>
              <Grid2 sm={12} lg={7}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <ProfilePhoto
                    url={profile.url.value}
                    uploadComplete={uploadComplete}
                    fileClose={fileClose}
                    isEdit={!editable || isLoading}
                  />
                </Box>
              </Grid2>
            </Grid2>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
                marginTop: "30px",
              }}
            >
              {editable ? (
                <Button
                  className={classes.gridBtn}
                  fullWidth={false}
                  disabled={
                    profile.firstName.value === "" ||
                    profile.mobileNumber.value === "" ||
                    profile.email.value === "" ||
                    isLoading
                  }
                  onClick={onSubmit}
                >
                  <Typography>{t("BUTTONS.SAVE")}</Typography>
                </Button>
              ) : (
                <Button
                  className={classes.gridBtn}
                  fullWidth={false}
                  disabled={isLoading}
                  onClick={() => navigate("/profile/edit")}
                >
                  <Typography>{t("BUTTONS.EDIT")}</Typography>
                </Button>
              )}
            </Box>
          </Grid>
        </div>
      </div>
    </React.Fragment>
  );
}
