import { PayloadAction } from "@reduxjs/toolkit";
import set from "lodash/set";
import { createSlice } from "utils/@reduxjs/toolkit";
import {
  AssignedTreatmentItemType,
  CreateImportPayload,
  PatientsListItemType,
  PatientState,
} from "../types";

const initialState = PatientState;
export const PatientListSlice = createSlice({
  name: "patientsListState",
  initialState,
  reducers: {
    getList: (state) => {
      state.loading = true;
      state.query.page = 1;
    },
    setOrganisationFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getDepartmentList: (state, action) => {
      state.loading = true;
    },
    getDepartmentListSuccess: (state, action) => {
      state.departments = action.payload.map((item) => {
        return {
          id: item.id,
          name: item.departmentName,
          display: item.departmentName,
        };
      });
      state.loading = false;
    },
    setDepartmentFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getBankList: (state, action) => {
      state.loading = true;
    },
    getBankListSuccess: (state, action) => {
      state.loading = false;
      state.banks = action.payload.map((item) => {
        return {
          id: item.id,
          name: item.name,
        };
      });
    },
    setBankFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    setSelected: (state, action: PayloadAction<Array<Number>>) => {
      state.list.selectedIds = action.payload;
    },
    setSelectedAll: (state, action: PayloadAction<boolean>) => {
      state.list.selectedAll = action.payload;
    },
    resetModule: (state) => {
      state = initialState;
    },
    resetFrom: (state) => {
      state.form = initialState.form;
      state.assignedTreatment = [];
    },
    setTreatmentBankTreatmentItems: (state, action) => {
      const listItemsToSet: AssignedTreatmentItemType[] = [];
      if (action.payload.items) {
        state.assignTreatmentGroupPatient =
          action.payload.items[0].treatmentBank.groupPerPatient;
        state.assignTreatmentIndividualPatient =
          action.payload.items[0].treatmentBank.individualPerPatient;
      } else {
        state.assignTreatmentGroupPatient = 0;
        state.assignTreatmentIndividualPatient = 0;
      }
      for (const item of action.payload.items) {
        let reschedule = false;
        for (
          let index = 0;
          index < item.treatmentBank.timeSlot.length;
          index++
        ) {
          //item.treatmentType.session_count
          const element = item.treatmentBank.timeSlot[index];
          for (
            let innerIndex = 0;
            innerIndex < element.timeslotSession.length;
            innerIndex++
          ) {
            const elementSession = element.timeslotSession[innerIndex];
            if (
              elementSession.assignedTreatment.length <
              item.treatmentType.session_count
            ) {
              reschedule = true;
            }
          }
        }
        listItemsToSet.push({
          id:
            item.assignedtreatment.length > 0
              ? item.assignedtreatment[0].id
              : 0,
          banktreatmentId: item.id,
          treatmentId: item.treatment.id,
          treatmentTypeName: item.treatment.name,
          groupIndividual: item.treatment.type,
          numberOfSession: item.treatment.session_count,
          description: item.treatment.description,
          isMust: item.isMust,
          maximumPerPatient: item.maximumPerPatient,
          bonusRegular:
            item.assignedtreatment?.length > 0
              ? item.assignedtreatment[0].patientType
              : "-",
          timeSlotId:
            item.assignedtreatment?.length > 0
              ? item.assignedtreatment[0].timeSlotId
              : 0,
          timeSlots:
            item.assignedtreatment?.length > 0
              ? item.assignedtreatment[0].assignedTreatmentPatientTimeSlots
                  .length > 0
                ? item.assignedtreatment[0].assignedTreatmentPatientTimeSlots.map(
                    (x) => {
                      return {
                        id: x.id,
                        meetingId: x.timeSlotSession.meetingId,
                      };
                    }
                  )
                : Array.from(Array(item.treatment.session_count).keys()).map(
                    (x) => {
                      return { id: 0, meetingId: "" };
                    }
                  )
              : Array.from(Array(item.treatment.session_count).keys()).map(
                  (x) => {
                    return { id: 0, meetingId: "" };
                  }
                ),
          optionalInstructor: item.optionalInstructor.map((x) => {
            return {
              id: x.instructorId,
              name:
                x.treatmentOptionalInstructor.firstName +
                " " +
                x.treatmentOptionalInstructor.lastName,
            };
          }),
          instructorId:
            item.assignedtreatment?.length > 0
              ? item.assignedtreatment[0].instructorId
              : 0,
          reschedule: reschedule,
        });
      }
      state.assignedTreatment = listItemsToSet;
      state.loading = false;
    },
    setTimeSlotItems: (state, action) => {
      const listItemsToSet: any[] = [];

      for (const item of action.payload.timeslotSession) {
        listItemsToSet.push(item);
      }
      state.popupTimeSlot = listItemsToSet;
      state.timeSlotType = action.payload.type;
      state.loading = false;
    },
    getNextPapopupTimeSloteItems: (state, action: PayloadAction<any>) => {
      state.query.page = state.list.meta.currentPage + 1;
      state.loading = true;
    },
    setQuery: (state, action: PayloadAction<{ name: string; value: any }>) => {
      const { name, value } = action.payload;
      state.query.page = 1;
      state.list = initialState.list;
      set(state.query, name, value);
    },
    handleBlock: (state, action: PayloadAction<string>) => {},
    handleBlockSuccess: (
      state,
      action: PayloadAction<PatientsListItemType>
    ) => {},
    getListSuccess: (
      state,
      action: PayloadAction<{ items: []; links: any; meta: any }>
    ) => {
      state.loading = false;
      const items = action.payload.items;
      state.list.items =
        action.payload.meta.currentPage === 1
          ? items
          : [...state.list.items, ...items];
      state.list.meta = action.payload.meta;
    },
    getNextPageItems: (state, action: PayloadAction<any>) => {
      state.query.page = state.list.meta.currentPage + 1;
      state.loading = true;
    },
    updateFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state.form, `${action.payload.key}.value`, action.payload.value);
      set(state.form, `${action.payload.key}.error`, null);
      // if (action.payload.key == "patient.organizationId") {
      //   set(state.form, `patient.departmentId.value`, action.payload.value);
      //   set(state.form, `patient.departmentId.error`, null);
      //   set(state.form, `patient.bankId.value`, action.payload.value);
      //   set(state.form, `patient.bankId.error`, null);
      // }
      state.error = "";
    },
    updateFailed: (state, action: PayloadAction<boolean>) => {
      state.loading = false;
    },
    setComment: (
      state,
      action: PayloadAction<{ content: string; name: string }>
    ) => {
      state.form.comments.push({
        id: 0,
        content: action.payload.content,
        time: new Date().toISOString(),
        name: action.payload.name,
      });
    },
    create: (state, action) => {
      state.loading = true;
    },
    createFailed: (state, action: PayloadAction<boolean>) => {
      state.loading = false;
    },
    getPatientByIdSuccess: (state, action: PayloadAction<any>) => {
      state.form.patient.id = action.payload.patient.id;
      state.form.patient.organizationId.value =
        action.payload.patient.organizationId;
      state.form.patient.departmentId.value =
        action.payload.patient.departmentId;
      state.form.patient.bankId.value = action.payload.patient.bankId;
      state.form.patient.patientStatus.value =
        action.payload.patient.patient_status;
      state.form.patient.firstName.value = action.payload.patient.firstName;
      state.form.patient.lastName.value = action.payload.patient.lastName;
      state.form.patient.email.value = action.payload.patient.email;
      state.form.patient.cellular.value = action.payload.patient.cellular;
      state.form.patient.sex.value = action.payload.patient.sex;
      state.form.patient.birthDate.value = action.payload.patient.birthDate;
      state.form.patient.cityId.value = action.payload.patient.cityId;
      state.form.patient.street.value = action.payload.patient.street;
      state.form.patient.numberOfChildern.value =
        action.payload.patient.numberOfChildren;
      state.form.patient.status.value = action.payload.patient.status;
      state.form.patient.howLongInCurrentStatus.value =
        action.payload.patient.howLongInCurrentStatus;
      state.form.patient.education.value = action.payload.patient.education;
      state.form.patient.religion.value = action.payload.patient.religion;
      state.form.patient.comments.value = action.payload.patient.comments;
      state.form.patient.economicLevel.value =
        action.payload.patient.economicLevel;
      state.form.patient.dailyWorkJobs.value =
        action.payload.patient.dailyWorkHours;
      state.form.patient.monthsInCurrentJobs.value =
        action.payload.patient.monthsInCurrentJobs;
      state.form.patient.monthsInCurrentOrganization.value =
        action.payload.patient.monthsInCurrentOrganization;
      state.form.patient.anyBadHistory.value =
        action.payload.patient.anyBadHistory;
      state.form.patient.intakeFinalMeetingHistory1.value =
        action.payload.patient.intakeFinalMeetingHistory1;
      state.form.patient.intakeFinalMeetingHistory2.value =
        action.payload.patient.intakeFinalMeetingHistory2;
      state.form.patient.intakeInitialMeetingHistory1.value =
        action.payload.patient.intakeInitialMeetingHistory1;
      state.form.patient.intakeInitialMeetingHistory2.value =
        action.payload.patient.intakeInitialMeetingHistory2;

      state.form.patient.progressAssignedSlot =
        action.payload.patient.progressAssignedSlot;
      state.form.patient.progressCompletedSlot =
        action.payload.patient.progressCompletedSlot;

      state.form.patient.progressAssignedTreatment =
        action.payload.patient.progressAssignedTreatment;
      state.form.patient.progressCompletedTreatment =
        action.payload.patient.progressCompletedTreatment;

      state.form.patient.progressDailyActivitySent =
        action.payload.patient.progressDailyActivitySent;
      state.form.patient.progressDailyActivityWatched =
        action.payload.patient.progressDailyActivityWatched;
      state.form.patient.timeZone = action.payload.patient.timeZone;
      state.form.comments = action.payload.commens.map((x) => {
        return {
          id: x.id,
          content: x.content,
          time: x.created_at,
          name: `${x.user?.firstName} ${x.user?.lastName}`,
        };
      });
      state.form.wellBeingResults = action.payload.wellBeingResults;
      state.form.bank = action.payload.patient.bank;
      state.loading = false;
    },
    createSuccess: (state) => {
      state.loading = false;
      state.form.patient = initialState.form.patient;
    },
    update: (state, action: PayloadAction<any>) => {
      state.error = "";
      state.loading = true;
    },
    toggleImport(state, action: PayloadAction<{ open: boolean }>) {
      state.importModalOpen = action.payload.open;
    },
    importPatients(state, action: PayloadAction<CreateImportPayload>) {
      state.importModalOpenLoading = true;
    },
    getDataById: (state, action: PayloadAction<{ id: any }>) => {
      state.loading = true;
    },
    sendMessage: (
      state,
      action: PayloadAction<{ data: any; callback: () => void }>
    ) => {
      state.loading = true;
    },
    sendMessageComplete: (state) => {
      state.loading = false;
    },
    importSuccess(state) {
      state.importModalOpen = false;
      state.importModalOpenLoading = false;
    },
    openSelectedTreatMent(
      state,
      action: PayloadAction<{
        treatment: any;
        bankId: number;
        instructorId: number;
        patientId: number;
        index: number;
      }>
    ) {
      state.selectedTreatmentIndex = action.payload.index;
      state.selectedTreatment = action.payload.treatment;
      state.timeSlotModal = true;
    },
    setTimeSlot(
      state,
      action: PayloadAction<{
        id: number;
      }>
    ) {},

    setTimeSlotSuccess(
      state,
      action: PayloadAction<{
        id: number;
      }>
    ) {
      state.assignedTreatment[state.selectedTreatmentIndex].timeSlotId =
        action.payload.id;
      state.assignedTreatment[state.selectedTreatmentIndex].bonusRegular =
        state.timeSlotType;

      if (
        state.assignedTreatment[state.selectedTreatmentIndex].numberOfSession >
        1
      ) {
        state.assignedTreatment[state.selectedTreatmentIndex].timeSlots =
          state.popupTimeSlot
            .filter((x) => x.id == action.payload.id)[0]
            .timeslotSession.map((item) => {
              return { id: item.id, meetingId: item.meetingId };
            });
      } else {
        let meetings: any[] = [];
        for (let index = 0; index < state.popupTimeSlot.length; index++) {
          const element = state.popupTimeSlot[index];
          if (
            element.timeslotSession.filter((x) => x.id == action.payload.id)
              .length > 0
          ) {
            meetings.push({
              id: action.payload.id,
              meetingId: element.timeslotSession.filter(
                (x) => x.id == action.payload.id
              )[0].meetingId,
            });
          }
        }
        state.assignedTreatment[state.selectedTreatmentIndex].timeSlots =
          meetings;
      }

      state.timeSlotModal = false;
      state.selectedTreatmentIndex = -1;
    },
    setTimeSlotModal(state) {
      state.timeSlotModal = !state.timeSlotModal;
    },
    setAssignedTreatmentInstructor(
      state,
      action: PayloadAction<{
        value: number;
        index: number;
      }>
    ) {
      state.assignedTreatment[action.payload.index].instructorId =
        action.payload.value;
    },
    deletePatient: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {
      state.loading = true;
    },
    deletePatientComplete: (state) => {
      state.loading = false;
    },
    resetQuery: (state) => {
      state.query = initialState.query;
    },
  },
});
export const { reducer, actions, name: sliceKey } = PatientListSlice;
