import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 10,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  paper: {
    padding: 20,
    textAlign: "center",
  },
  button: {
    marginTop: 20,
    backgroundColor: "#44b8b5 !important",
    "&hover": {},
  },
  icon: {
    fontSize: 48,
    marginBottom: 10,
    color: "#44b8b5",
    backgroundColor: "transparent !important",
  },
  logo: {
    maxWidth: 300,
    height: "auto",
    marginBottom: 20,
  },
}));
