import i18next from "i18next";
import { FormError } from "../types";
import { GradesListItemType, RangeListItemType } from "./types";

export const validateCurrentRange = (range: RangeListItemType): FormError[] => {
  const errors: FormError[] = [];
  if (!range.from) {
    errors.push({
      name: `errors.from`,
      error: i18next.t("QUESTIONNAIRES.FORM.ERRORS.RANGE_FROM_TO_REQUIRED"),
    });
  }

  if (!range.to) {
    errors.push({
      name: `errors.to`,
      error: i18next.t("QUESTIONNAIRES.FORM.ERRORS.RANGE_FROM_TO_REQUIRED"),
    });
  }

  if (range.from && range.to && Number(range.to) < Number(range.from)) {
    errors.push({
      name: `errors.to`,
      error: i18next.t(
        "QUESTIONNAIRES.FORM.ERRORS.TO_MUST_BE_GREATER_THAN_FROM",
        {
          min: 0,
          max: 100,
        }
      ),
    });
  }

  if (!range.rangeName) {
    errors.push({
      name: `errors.rangeName`,
      error: i18next.t("QUESTIONNAIRES.FORM.ERRORS.RANGE_NAME_REQUIRED"),
    });
  }

  if (!range.description) {
    errors.push({
      name: `errors.description`,
      error: i18next.t("QUESTIONNAIRES.FORM.ERRORS.RANGE_DESCRIPTION_REQUIRED"),
    });
  }

  return errors;
};

export const validateCurrentGrade = (
  grade: GradesListItemType,
  min?: number,
  max?: number
): FormError[] => {
  const errors: FormError[] = [];
  if (!grade.gradeName) {
    errors.push({
      name: `errors.gradeName`,
      error: i18next.t("QUESTIONNAIRES.FORM.ERRORS.GRADE_NAME_REQUIRED"),
    });
  }

  if (!grade.questionNumbers) {
    errors.push({
      name: `errors.questionNumbers`,
      error: i18next.t(
        "QUESTIONNAIRES.FORM.ERRORS.GRADE_QUESTION_NUMBERS_REQUIRED"
      ),
    });
  }

  if (!grade.gradeType) {
    errors.push({
      name: `errors.gradeType`,
      error: i18next.t("QUESTIONNAIRES.FORM.ERRORS.GRADE_TYPE_REQUIRED"),
    });
  }
  const minNumber = Number(min);
  const maxNumber = Number(max);
  if (minNumber > -1 && maxNumber > -1) {
    validateGradeRanges(grade.ranges, minNumber, maxNumber).forEach(
      (e, index) => {
        errors.push({
          name: `${e.name}[${index}]`,
          error: e.error,
        });
      }
    );
  }

  return errors;
};

export function validateGradeRanges(
  inComingRanges: RangeListItemType[],
  minNumber: number,
  maxNumber: number
): FormError[] {
  const ranges = [...inComingRanges];
  const errors: FormError[] = [];
  if (!ranges.length) {
    errors.push({
      name: `errors.gradeGeneral`,
      error: i18next.t("QUESTIONNAIRES.FORM.ERRORS.RANGES_MIN_REQUIRED", {
        value: 1,
      }),
    });
    return errors;
  }

  // Sort ranges by 'from' value
  ranges.sort(
    (a, b) => (parseNumber(a.from) || 0) - (parseNumber(b.from) || 0)
  );

  for (const range of ranges) {
    const from = parseNumber(range.from);
    const to = parseNumber(range.to);

    if (
      from === undefined ||
      to === undefined ||
      from < minNumber ||
      to > maxNumber
    ) {
      errors.push({
        name: `errors.gradeGeneral`,
        error: i18next.t("QUESTIONNAIRES.FORM.ERRORS.RANGE_OUT_OF_BOUNDS", {
          min: minNumber,
          max: maxNumber,
          range: `${from || ""}-${to || ""}`,
        }),
      });
    }
  }

  // Check if there is a range that starts before minNumber
  if (ranges.length > 0 && (parseNumber(ranges[0].from) || 0) > minNumber) {
    errors.push({
      name: `errors.gradeGeneral`,
      error: i18next.t("QUESTIONNAIRES.FORM.ERRORS.MISSING_RANGE", {
        min: minNumber,
        max: (parseNumber(ranges[0].from) as any) - 1,
      }),
    });
  }

  // Check for overlapping ranges
  let prevRange = ranges[0];
  for (let i = 1; i < ranges.length; i++) {
    const currentRange = ranges[i];

    if (
      (parseNumber(currentRange.from) || 0) >
      (parseNumber(prevRange.to) || 0) + 1
    ) {
      errors.push({
        name: `errors.gradeGeneral`,
        error: i18next.t("QUESTIONNAIRES.FORM.ERRORS.MISSING_RANGE", {
          min: parseNumber(prevRange.to),
          max: (parseNumber(currentRange.from) as any) - 1,
        }),
      });
    }

    if (
      (parseNumber(currentRange.from) || 0) <= (parseNumber(prevRange.to) || 0)
    ) {
      errors.push({
        name: `errors.gradeGeneral`,
        error: i18next.t("QUESTIONNAIRES.FORM.ERRORS.OVERLAPPING_RANGE", {
          range1: `${parseNumber(prevRange.from)}-${parseNumber(prevRange.to)}`,
          range2: `${parseNumber(currentRange.from)}-${parseNumber(
            currentRange.to
          )}`,
        }),
      });
    }

    prevRange = currentRange;
  }

  // Check if there is a range that ends after maxNumber
  if (
    ranges.length > 0 &&
    (parseNumber(ranges[ranges.length - 1].to) || 0) < maxNumber
  ) {
    errors.push({
      name: `errors.gradeGeneral`,
      error: i18next.t("QUESTIONNAIRES.FORM.ERRORS.MISSING_RANGE", {
        min: (parseNumber(ranges[ranges.length - 1].to) as any) + 1,
        max: maxNumber,
      }),
    });
  }

  return errors;
}

export function parseNumber(
  value: string | number | undefined
): number | undefined {
  if (typeof value === "string") {
    const parsed = parseInt(value, 10);
    return isNaN(parsed) ? undefined : parsed;
  }
  if (typeof value === "number") {
    return value;
  }
  return undefined;
}
