import { PayloadAction } from "@reduxjs/toolkit";
import { FormError } from "app/containers/types";
import set from "lodash/set";
import { createSlice } from "utils/@reduxjs/toolkit";
import { getDesiredResourceInFormat } from "utils/helpers";
import { AddTreatmentItemType, UserState } from "../types";
const initialState = UserState;
export const AddTreatmentSlice = createSlice({
  name: "addTreatmentStateType",
  initialState,
  reducers: {
    create: (state, action) => {
      state.loading = true;
      state.loading = false;
    },
    createSuccess: (state) => {
      state.loading = false;
      state.form.content = initialState.form.content;
    },
    questionnaireModalToggal: (state) => {
      state.questionnaireModal = !state.questionnaireModal;
    },
    contentModalToggal: (state) => {
      state.contentModal = !state.contentModal;
    },

    getTreatmentContentList: (state, action) => {
      state.loading = true;
    },
    getTreatmentQuestionnaireList: (state, action) => {
      state.loading = true;
    },
    getQuestionnaireList: (state) => {
      state.loading = true;
    },
    setTreatmentContentList: (state, action) => {
      state.list.treatment = action.payload.items.map((item) => {
        return {
          id: item.id,
          beforeAfter: item.at,
          name: item.name,
          hours: item.duration,
          description: item.description,
          session: item.session_type,
          session_id: item.session_id,
          questionnaireId: item.questionnaireId,
          videoFile: getDesiredResourceInFormat(item.resources, "video"),
          audioFile: getDesiredResourceInFormat(item.resources, "audio"),
          pdfFile: getDesiredResourceInFormat(item.resources, "pdf"),
          type: "edit",
        };
      });
    },
    doaddTreatmentContentItem: (state, action) => {},
    addTreatmentContentItem: (state, action) => {
      state.list.treatment.push(action.payload);
    },
    editTreatmentContentItem: (state, action) => {
      state.list.treatment[action.payload.index] = action.payload;
    },
    deleteTreatmentContentItem: (state, action) => {
      state.list.treatment.splice(action.payload, 1);
    },
    setTreatmentQuestionnaireList: (state, action) => {
      state.list.items = action.payload.items.map((item) => {
        return {
          id: item.id,
          name: item.questionnaire.name,
          beforeAfter: item.at,
          hours: item.duration,
          questionaireName: item.questionnaire.name,
          questionnaireId: item.questionnaireId,
          session_id: item.session_id,
          type: "edit",
        };
      });
    },
    doAddTreatmentQuestionnaireItem: (state, action) => {},

    setTreatmentQuestionnaireItem: (state, action) => {
      state.list.items.push(action.payload);
    },

    editTreatmentQuestionnaireItem: (state, action) => {
      state.list.items[action.payload.index] = action.payload;
    },
    deleteTreatmentQuestionnaireItem: (state, action) => {
      state.list.items.splice(action.payload, 1);
    },
    setQuestionnaireList: (state, action) => {
      state.questionnaireList.items = action.payload.map((item) => {
        return {
          id: item.id,
          name: item.name,
        };
      });
    },
    getUserById: (state, action: PayloadAction<{ id: any }>) => {
      state.loading = true;
    },
    getListSuccess: (
      state,
      action: PayloadAction<{ items: []; links: any; meta: any }>
    ) => {
      state.loading = false;
      state.list.items = action.payload.items;
      state.list.meta = action.payload.meta;
      state.list.links = action.payload.links;
    },
    getNextPage: (state, action: PayloadAction<any>) => {
      state.query.page = state.list.meta.currentPage + 1;
      state.loading = true;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    update: (
      state,
      action: PayloadAction<{ id: any; toUpdate: any; navigate: any }>
    ) => {
      state.error = "";
      state.loading = true;
    },
    updateFormResourseValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state.form, `${action.payload.key}`, action.payload.value);
    },
    updateFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state.form, `${action.payload.key}.value`, action.payload.value);
      set(state.form, `${action.payload.key}.error`, null);
      state.error = "";
      if (
        state.form.content.name.value !== "" &&
        state.form.content.hours.value !== "" &&
        state.form.content.description.value !== "" &&
        state.form.content.beforeAfter.value !== "" &&
        state.form.content.sessionNumber.value !== ""
      ) {
        const key = "content.disable";
        const value = false;
        set(state.form, `${key}`, value);
      } else {
        const key = "content.disable";
        const value = true;
        set(state.form, `${key}`, value);
      }
      if (
        state.form.questionaire.hours.value !== "" &&
        state.form.questionaire.questionaireName.value !== "" &&
        state.form.questionaire.beforeAfter.value !== "" &&
        state.form.questionaire.session_id.value !== ""
      ) {
        const key = "questionaire.disable";
        const value = false;
        set(state.form, `${key}`, value);
      } else {
        const key = "questionaire.disable";
        const value = true;
        set(state.form, `${key}`, value);
      }
    },
    updateFormFileResourceObj: (state, action) => {
      set(state.form, action.payload.resourceKey, action.payload.resourceObj);
    },
    updateEnable: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state.form, `${action.payload.key}`, action.payload.value);
    },
    updateTreatmentForm: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state.form,
        `treatment.${action.payload.key}.value`,
        action.payload.value
      );
      set(state.form, `treatment.${action.payload.key}.error`, "");
    },
    resetTreatmentForm: (state) => {
      state.form = initialState.form;
      state.list.items = [];
      state.list.treatment = [];
    },
    updateSuccess(state, action: PayloadAction<AddTreatmentItemType>) {
      state.loading = false;
    },
    updateFailed: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    createFailed: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    delete: (state, action) => {
      state.error = "";
      state.loading = true;
    },
    deleteSuccess: (state) => {
      state.loading = false;
      state.error = "";
    },
    deleteFailed: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    resetQuestionaireForm(state) {
      state.error = "";
      state.form.questionaire = {
        name: { value: "" },
        beforeAfter: { value: "" },
        hours: { value: "" },
        questionnaireId: { value: "" },
        questionaireName: { value: "" },
        session_id: { value: "" },
        disable: true,
      };
    },

    setFormErrors(
      state,
      action: PayloadAction<{
        key: string;
        errors: FormError[];
      }>
    ) {
      action.payload.errors.forEach((error) => {
        set(
          state.form,
          `${action.payload.key}.${error.name}.error`,
          error.error
        );
      });
    },
    getNextPageItems: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    getNextPageItemsSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.list.items = state.list.items.concat(action.payload.items);
      state.list.meta.currentPage = action.payload.meta.currentPage;
      state.list.links = action.payload.links;
      state.query.page = action.payload.meta.currentPage;
    },
    setQuery: (state, action: PayloadAction<{ name: string; value: any }>) => {
      const { name, value } = action.payload;
      state.query.page = 1;
      state.list = initialState.list;
      set(state.query, name, value);
    },
    resetModule: (state) => {
      state = initialState;
    },
    handleBlock: (state, action: PayloadAction<string>) => {},
    handleBlockSuccess: (
      state,
      action: PayloadAction<AddTreatmentItemType>
    ) => {},
    resetContentForm: (state) => {
      state.form.content = {
        name: { value: "" },
        beforeAfter: { value: "" },
        hours: { value: "" },
        videoFile: {},
        audioFile: {},
        pdfFile: {},
        description: { value: "" },
        sessionNumber: { value: "" },
        session_id: { value: "" },
        disable: true,
      };
    },
    resetQuestionnaireForm: (state) => {
      state.form.questionaire = {
        name: { value: "" },
        beforeAfter: { value: "" },
        hours: { value: "" },
        disable: true,
        questionaireName: { value: "" },
        questionnaireId: { value: "" },
        session_id: { value: "" },
        id: { value: "" },
      };
    },
    addTreatmentContentRow: (state, action) => {},
    addTreatmentQuestionnaireRow: (state, action) => {
      // state.list.items.push({
      //   questionaireName: action.payload.questionaireName,
      //   beforeAfter: action.payload.beforeAfter,
      //   hours: action.payload.hours,
      //   session: action.payload.session,
      // });
    },
    editTreatmentContentRow: (state, action) => {},
    deleteTreatmentContentRow: (state, action) => {
      const { id, treatmentId } = action.payload;
      let index = state.list.treatment.findIndex((x) => x.id == id);
      if (index > -1) {
        state.list.treatment[index].type = "delete";
      }
    },
    deleteTreatmentQuestionnaireRow: (state, action) => {
      const { id, treatmentId } = action.payload;
      let index = state.list.items.findIndex((x) => x.id == id);
      if (index > -1) {
        state.list.items[index].type = "delete";
      }
    },
    editTreatmentQuestionnaireRow: (state, action) => {
      state.error = "";
      state.loading = true;
    },
    uploadFile: (state, action) => {
      const { format } = action.payload;
      const uploadingKey = `${format}Uploading`;
      state[uploadingKey] = true;
    },
    setFileUploadSuccess: (state, action) => {
      const {
        fileName,
        fileSize,
        fileType,
        fileNameWithPrefix,
        keyToUpdateOnSuccess,
        url,
      } = action.payload;

      state.form.content = {
        ...state.form.content,
        [keyToUpdateOnSuccess]: {
          name: fileName,
          size: fileSize,
          type: fileType,
          nameWithPrefix: fileNameWithPrefix,
          url,
        },
      };
      if (!state.form.content.audioFile) {
        state.form.content.audioFile = {};
      }
      if (!state.form.content.videoFile) {
        state.form.content.videoFile = {};
      }
      if (!state.form.content.pdfFile) {
        state.form.content.pdfFile = {};
      }
      state.form.content.audioFile.error = "";
      state.form.content.videoFile.error = "";
      state.form.content.pdfFile.error = "";
    },
    getTretament: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    getTretamentSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.form.treatment.noOfSession.value = action.payload.session_count;
      state.form.treatment.participantType.value = action.payload.type;
      state.form.treatment.participants.value =
        action.payload.maximum_participants;
      state.form.treatment.sessionLength.value =
        action.payload.session_duration;
      state.form.treatment.treatmentDescription.value =
        action.payload.description;
      state.form.treatment.treatmentName.value = action.payload.name;
      state.form.treatment.treatmentBanksCount.value =
        action.payload.treatmentBanksCount;
      state.form.treatment.timeSlotsCount.value = action.payload.timeSlotsCount;
    },
  },
});
export const { reducer, actions, name: sliceKey } = AddTreatmentSlice;
