import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  title: {
    position: "absolute",
    top: "10%",
    left: "45%",
    fontFamily: "Cairo",
    fontSize: "18px",
    fontWeight: 700,
    color: "#464646",
  },
});
