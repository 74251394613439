import { CalendarMonth } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Grid, MenuItem, Select, TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ActualInstructorField from "app/components/FieldComponent/ActualInstructor";
import BankFiled from "app/components/FieldComponent/Bank";
import DepartmentFiled from "app/components/FieldComponent/Department";
import InstructorFiled from "app/components/FieldComponent/Instructor";
import OrganizationField from "app/components/FieldComponent/Organization";
import TreatmentTypeFiled from "app/components/FieldComponent/TreatmentType";
import { useUsersListSaga } from "app/containers/Users/redux/saga";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import {
  sliceKey as userKey,
  reducer as userReducer,
} from "../../../Users/redux/slice";
import { selectQuery } from "../../redux/selector";
import { actions } from "../../redux/slice";
import { useStyles } from "./styles";

const FiltersForm = () => {
  const styles = useStyles();
  const { t } = useTranslation();
  useInjectReducer({ key: userKey, reducer: userReducer });
  useInjectSaga({ key: userKey, saga: useUsersListSaga });
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(actions.resetQuery());
    };
  }, [dispatch]);

  const {
    bankId,
    departmentId,
    organizationId,
    treatmentId,
    from,
    to,
    status,
    instructorId,
    actualInstructorId,
    q,
  } = useSelector(selectQuery);

  const setPageQuery = (name: string, value: any) => {
    dispatch(actions.setQuery({ name, value }));
  };

  return (
    <>
      <Grid
        container
        spacing={1}
        md={12}
        sx={{
          padding: "0px 0px 0px 0px",
        }}
      >
        <Grid item xs={2.4}>
          <OrganizationField
            value={organizationId}
            xs={11.2}
            changeValue={(value) => {
              setPageQuery("organizationId", value);
            }}
            showAll={true}
            activeRequired={false}
          ></OrganizationField>
        </Grid>
        <Grid item xs={2.4}>
          <DepartmentFiled
            xs={11.2}
            value={departmentId}
            changeValue={(value) => {
              setPageQuery("departmentId", value);
            }}
            orgId={organizationId}
            showAll={true}
          ></DepartmentFiled>
        </Grid>
        <Grid item xs={2.4}>
          <BankFiled
            value={bankId}
            xs={11.2}
            organizationId={organizationId}
            departmentId={departmentId}
            changeValue={(value) => {
              setPageQuery("bankId", value);
            }}
            showAll={true}
          ></BankFiled>
        </Grid>
        <Grid item xs={2.4}>
          <TreatmentTypeFiled
            value={treatmentId}
            bankId={bankId}
            xs={11.2}
            changeValue={(value) => {
              setPageQuery("treatmentId", value);
            }}
            showAll={true}
            fetchAll={false}
          ></TreatmentTypeFiled>
        </Grid>
        <Grid item xs={2.4}>
          <label className={styles.label}>
            {t("ASSIGNED_TREATMENT.STATUS")}
          </label>
          <Select
            variant="outlined"
            fullWidth
            margin="dense"
            required
            autoComplete={"off"}
            value={status}
            className={styles.textEnabled}
            onChange={(e) => {
              const value = e.target.value;
              setPageQuery("status", value);
            }}
            IconComponent={KeyboardArrowDownIcon}
          >
            <MenuItem value={"0"}>{t("COMMON.ALL")}</MenuItem>
            <MenuItem value={"1"}>{t("COMMON.ASSIGNED")}</MenuItem>
            <MenuItem value={"2"}>{t("COMMON.NOT_ASSIGNED")}</MenuItem>
          </Select>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        md={12}
        sx={{
          padding: "10px 0px 10px 0px",
        }}
      >
        <Grid item xs={2.4}>
          <InstructorFiled
            label={t("TIMESLOT.INSTRUCTOR_NAME")}
            value={instructorId}
            treatmentId={treatmentId}
            bankId={bankId}
            xs={11.2}
            changeValue={(value: number) => {
              setPageQuery("instructorId", value);
            }}
            showAll={true}
          ></InstructorFiled>
        </Grid>
        <Grid item xs={2.4}>
          <ActualInstructorField
            value={actualInstructorId}
            treatmentId={treatmentId}
            bankId={bankId}
            xs={11.2}
            changeValue={(value: number) => {
              setPageQuery("actualInstructorId", value);
            }}
            showAll={true}
            instructorId={instructorId}
            isEdit={false}
          ></ActualInstructorField>
        </Grid>
        <Grid item xs={2.4}>
          <label className={styles.label}>
            {t("ASSIGNED_TREATMENT.FROM_TREATMENT_DATE")}
          </label>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              className={styles.textEnabled}
              views={["day"]}
              value={from}
              minDate="01/01/1900"
              onChange={(newValue) => {
                setPageQuery("from", newValue);
              }}
              OpenPickerButtonProps={{
                style: { color: "#012397" },
              }}
              components={{
                OpenPickerIcon: CalendarMonth,
              }}
              inputFormat="dd/MM/yyyy"
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  margin="dense"
                  helperText={null}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: "__/__/____",
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={2.4}>
          <label className={styles.label}>
            {t("ASSIGNED_TREATMENT.TO_TREATMENT_DATE")}
          </label>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              className={styles.textEnabled}
              views={["day"]}
              value={to}
              minDate="01/01/1900"
              onChange={(newValue) => {
                setPageQuery("to", newValue);
              }}
              OpenPickerButtonProps={{
                style: { color: "#012397" },
              }}
              components={{
                OpenPickerIcon: CalendarMonth,
              }}
              inputFormat="dd/MM/yyyy"
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  margin="dense"
                  helperText={null}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: "__/__/____",
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
    </>
  );
};

export default FiltersForm;
