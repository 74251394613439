import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles(() => ({
  uploadBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "5px",
    borderStyle: "dashed",
    borderWidth: "1px",
    border: "1px solid #6C7A7A",
    height: "56px",
    width: "100%",
    background: "#FFF",
    marginBottom: "5px",
    maxHeight: "56px",
    flexDirection: "row",
    padding: 10,
  },
  uploadImageBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "5px",
    borderStyle: "dashed",
    borderWidth: "1px",
    border: "1px solid #6C7A7A",
    height: "156px",
    width: "100%",
    background: "#FFF",
    marginBottom: "5px",
    maxHeight: "56px",
    flexDirection: "row",
    padding: 10,
  },
  uploadLabel: {
    fontFamily: "Cairo",
    fontWeight: "400px",
    marginTop: "13px",
    fontSize: "14px",
    marginLeft: "16px",
    color: "#2A3333",
    textDecorationLine: "underline",
  },
  preview: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "56px",
    width: "190px",
    backgroundColor: "#00000",
    marginBottom: "5px",
    background: "#fff",
  },
  previewImage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "156px",
    width: "190px",
    backgroundColor: "#00000",
    marginBottom: "5px",
    background: "#fff",
  },
}));
