import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles(() => ({
  mainContainer: {
    background: "#F5F8FF",
    minHeight: "100%",
    width: "100%",
    position: "absolute",
    bottom: "auto",
  },
  root: {
    padding: "20px 20px 0px 300px",
    marginTop: "40px",
  },
  uploadsError: {
    width: "100%",
    marginLeft: 24,
  },
  uploadsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flex: 1,
    marginLeft: 24,
    marginTop: 20,
  },
  tableDiv: {
    border: "1px",
  },
  filterBox: {
    display: "flex",
    justifyContent: "space-between",
    width: 500,
    gap: 20,
  },
  filterSelect: {
    width: 300,
  },
  clearFilter: {
    color: "#EDAC15",
    fontSize: 14,
    fontWeight: 400,
    cursor: "pointer",
  },
  dateDiv: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "10px",
  },
  dateDivRoot: {
    marginTop: 50,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  page: {
    position: "relative",
  },
  filterContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 50,
    marginBottom: 24,
  },
  ml25: {
    marginLeft: "25px !important",
  },
  search: {
    background: "#F5F5F5",
    border: "none",
    borderRadius: 7,
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  dataTableDiv: {
    maxHeight: "calc(100vh - 285px)",
    border: "1px solid #C6C9CA",
    overflowY: "auto",
    overflowX: "auto",
    marginTop: "0px",
    background: "#F5F8FF",
  },
  addBtn: {
    fontSize: 14,
    color: "#333333",
    border: "1px solid #D5D8DE",
    fontFamily: "Cairo",
    fontWeight: "400",
  },
  btnDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  datePicker1: {
    position: "absolute",
    opacity: 0,
    zIndex: -1,
    right: "200px",
  },
  datePicker: {
    position: "absolute",
    opacity: 0,
    zIndex: -1,
  },
  dateButton: {
    color: "#333333",
    border: "1px solid #D5D8DE",
    backgroundColor: "#F7F7FA",
    minWidth: "180px",
    fontSize: "14px",
    minHeight: "45px",
  },
  addModal: {
    position: "absolute",
    background: "#FFFFFF",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "0px",
    boxShadow: "0",
    zIndex: "9999",
    width: "700px",
  },
  deleteModal: {
    position: "absolute",
    background: "#FFFFFF",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "0px",
    boxShadow: "0",
    zIndex: "9999",
    width: "410px",
    height: "230px",
  },
  modalContainer: {
    padding: "20px",
    background: "#F5F8FF",
  },
  headerModal: {
    color: "#012497",
    fontFamily: "Cairo",
    fontWeight: "800",
    fontSize: "24px",
  },
  deleteTypo: {
    color: "#2A3333",
    fontFamily: "Cairo",
    fontWeight: "400",
    fontSize: "16px",
  },
  btnContainer: {
    display: "flex",
    alignItems: "flex-end",
  },
  cancelBtn: {
    color: "#3D63E6",
    borderColor: "#3D63E6",
    "&:hover": {
      borderColor: "#3D63E6",
    },
    fontFamily: "Cairo",
    fontWeight: 700,
    fontSize: "16px",
    width: "160px",
    height: "50px",
  },
  saveBtn: {
    background: "#3D63E6",
    color: "#ffff",
    "&:hover": {
      background: "#3D63E6",
    },
    fontFamily: "Cairo",
    fontWeight: 700,
    fontSize: "16px",
    width: "160px",
    height: "50px",
  },
  label: {
    fontFamily: "Cairo",
    fontWeight: 400,
    fontSize: "16px",
    color: "#2A3333",
    marginBottom: "0px",
  },
  downloadBtn: {
    cursor: "pointer",
  },
  Textfield: {
    width: "220px",
    maginTop: "10px",
  },
  textEnabled: {
    background: "#FFF",
  },
  dotBox: {
    display: "flex",
    alignItems: "flex-end",
    marginBottom: "5px",
    flex: 1,
  },
  uploadBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "5px",
    borderStyle: "dashed",
    borderWidth: "1px",
    border: "1px solid #6C7A7A",
    height: "56px",
    width: "230px",
    background: "#FFF",
  },
  uploadLabel: {
    fontFamily: "Cairo",
    fontWeight: "400px",
    marginTop: "13px",
    fontSize: "14px",
    marginLeft: "16px",
    color: "#6C7A7A",
    textDecoration: "underline",
  },
  preview: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "56px",
    width: "190px",
    backgroundColor: "#00000",
    marginBottom: "5px",
    background: "#fff",
  },
}));
