import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";
import { FieldState } from "./types";
const initialState = FieldState;
export const fieldsListSlice = createSlice({
  name: "globalFieldsState",
  initialState,
  reducers: {
    getOrganizationList: (state, action: PayloadAction<boolean>) => {
      state.organization.loading = true;
    },
    getQuestionnaireList: (
      state,
      action: PayloadAction<{ type: "open" | "mcq" | "all" }>
    ) => {
      state.loading = true;
    },
    getCityList: (state) => {
      state.loading = true;
    },
    getOrganizationListSuccess: (state, action) => {
      state.organization.loading = false;
      state.organization.items = action.payload.map((item) => {
        return {
          id: item.id,
          organizationName: item.name,
        };
      });
    },
    getOuestionnaireListSuccess: (state, action) => {
      state.loading = false;
      state.questionnaire = action.payload.items;
    },
    getCityListSuccess: (state, action) => {
      state.loading = false;
      state.city.items = action.payload.map((item) => {
        return {
          id: item.id,
          name: item.name || item.nameHw,
        };
      });
    },
    setQualfication: (state, action) => {
      state.qualification.items = [
        ...action.payload.map((item) => {
          return {
            id: null,
            "#": item["#"],
            treatmentName: item.treatmentName,
            treatmentId: item.id,
          };
        }),
        ...state.qualification.items,
      ];
    },
    setPermission: (state, action) => {
      state.patientPermission.items = [
        {
          id: null,
          organizationName: action.payload.organizationName,
          departmentName: action.payload.departmentName,
          bankName: action.payload.bankName,
          emailNotification: false,
        },
        ...state.patientPermission.items,
      ];
    },
    setOrganisationFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    setCityFailure: (state, action) => {
      state.loading = false;
      state.city.items = [];
      state.error = action.payload;
    },
    getFieldGroupList: (state) => {
      state.loading = true;
    },
    getFieldGroupListSuccess: (state, action) => {
      state.loading = false;
      state.fieldGroup.items = action.payload.items.map((item) => {
        return {
          id: item.id,
          fieldGroupName: item.groupName,
        };
      });
    },
    setFieldGroupListFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getTreatmentList: (state, action: PayloadAction<string>) => {
      state.treatment.items = [];
      state.loading = true;
    },
    getInstructorList: (
      state,
      action: PayloadAction<{ treatmentId: number; bankId: number }>
    ) => {
      state.instructor.items = [];
      state.loading = true;
    },
    getActualInstructorList: (
      state,
      action: PayloadAction<{ treatmentId: number; bankId: number }>
    ) => {
      state.actualInstructor.items = [];
      state.loading = true;
    },
    getTreatmentListByBank: (
      state,
      action: PayloadAction<{ bankId: number; all: boolean }>
    ) => {
      state.treatment.items = [];
      state.loading = true;
    },
    getQualificationList: (state, action) => {
      state.loading = true;
    },
    getQualificationListSuccess: (state, action) => {
      state.loading = false;
      state.qualification.items = action.payload.qualification.map(
        (item, index) => {
          return {
            id: item.id,
            "#": index,
            treatmentName: item.treatmentTypeName,
            treatmentId: item.id,
          };
        }
      );
      state.patientPermission.items = action.payload.permission.map(
        (item, index) => {
          return {
            id: item.id,
            organizationName: item.organizationName,
            departmentName: item.departmentName,
            bankName: item.bankName,
            emailNotification: item.emailNotification,
          };
        }
      );
    },
    getQualificationListFailure: (state) => {
      state.loading = false;
    },
    getTreatmentListSuccess: (state, action) => {
      state.loading = false;
      state.treatment.items = action.payload.items.map((item, index) => {
        return {
          id: item.id,
          "#": index,
          type: item.type,
          treatmentName: item.name,
          session_count: item.session_count,
          session_duration: item.session_duration,
          maximum_participants: item.maximum_participants,
        };
      });
    },
    getInstructorListSuccess: (state, action) => {
      state.loading = false;
      state.instructor.items = action.payload.items.map((item, index) => {
        return {
          id: item.id,
          "#": index,
          firstName: item.firstName,
          lastName: item.lastName,
        };
      });
    },
    getActualInstructorListSuccess: (state, action) => {
      state.loading = false;
      state.actualInstructor.items = action.payload.items.map((item, index) => {
        return {
          id: item.id,
          "#": index,
          firstName: item.firstName,
          lastName: item.lastName,
        };
      });
    },

    getDepartmentList: (state, action) => {
      state.departments.loading = true;
    },
    getDepartmentListSuccess: (state, action) => {
      state.departments.loading = false;
      state.departments.items = action.payload.map((item) => {
        return {
          id: item.id,
          name: item.departmentName,
          display: item.departmentName,
        };
      });
    },
    setDepartmentFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getBankList: (state, action) => {
      state.bankLoading = true;
    },
    getBankListSuccess: (state, action) => {
      state.bankLoading = false;
      state.banks = action.payload.map((item) => {
        return {
          id: item.id,
          name: item.name,
          bankStatus: item.bankStatus,
        };
      });
    },
    setBankFailure: (state, action) => {
      state.banks = [];
      state.bankLoading = false;
      state.error = action.payload;
    },
    setTreatmentFailure: (state, action) => {
      state.treatment.items = [];
      state.loading = false;
      state.error = action.payload;
    },
    setInstructorFailure: (state, action) => {
      state.instructor.items = [];
      state.loading = false;
      state.error = action.payload;
    },
    setActualInstructorFailure: (state, action) => {
      state.instructor.items = [];
      state.loading = false;
      state.error = action.payload;
    },

    resetModule: (state) => {
      state = initialState;
    },

    resetQualification: (state) => {
      state.qualification.items = [];
    },
    getUserGroupList: (state) => {
      state.loading = true;
    },
    getUserGroupListSuccess: (state, action) => {
      state.loading = false;
      state.userGroup.items = action.payload.items.map((item) => {
        return {
          id: item.id,
          userGroupName: item.groupName,
        };
      });
    },
    setUserGroupListFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});
export const { reducer, actions, name: sliceKey } = fieldsListSlice;
