import {
  Card,
  CardContent,
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import ComboChart from "app/components/Charts/ComboChart/ComboChart";
import { TabPanel } from "app/components/TabPanel";
import * as React from "react";

import Carousel from "app/containers/Dashboard/components/Carousel";
import {
  selectQuery,
  selectQuestionnaire,
  selectQuestionnaireDetails,
} from "app/containers/Dashboard/redux/selector";
import { actions } from "app/containers/Dashboard/redux/slice";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./styles";

export default function QuestionnaireTabs() {
  const dispatch = useDispatch();
  const styles = useStyles();
  const questionnaires = useSelector(selectQuestionnaire);
  const questionnaireDetails = useSelector(selectQuestionnaireDetails);
  const { t } = useTranslation();
  const handleChange = (event, index: number) => {
    dispatch(actions.setQuestionnaireId({ questionnaireId: index }));
  };

  const { organizationId, bankId, departmentId } = useSelector(selectQuery);
  useEffect(() => {
    if (questionnaireDetails.questionnaireId) {
      dispatch(
        actions.getQuestionerDetails({
          questionnaireId: questionnaireDetails.questionnaireId,
        })
      );
    }
  }, [
    questionnaireDetails.questionnaireId,
    organizationId,
    bankId,
    departmentId,
  ]);
  const isLoading = questionnaireDetails.isLoading;
  const scores = questionnaireDetails.item?.scores;

  const mainGrade = questionnaireDetails.item?.questionnaire.grades.find(
    (f) => f.mainGrade
  );
  if (questionnaires.isLoading) {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Skeleton
          sx={{
            borderRadius: 5,
            marginTop: 1,
            bgcolor: "#F5F5F5",
          }}
          variant="rectangular"
          width={"100%"}
          height={500}
        />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        background: "#fff",
      }}
    >
      <Tabs
        key={`tab`}
        value={questionnaireDetails.questionnaireId}
        TabIndicatorProps={{ style: { background: "#012397" } }}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable"
        style={{ maxWidth: 1000 }}
      >
        {questionnaires.items.map((item, idx) => {
          return (
            <Tab
              key={`tab-${idx}`}
              label={item?.name}
              value={item.id}
              className={styles.tabLabel}
              style={{
                fontWeight:
                  questionnaireDetails.questionnaireId === item?.id
                    ? "bold"
                    : "500",
                color:
                  questionnaireDetails.questionnaireId === item?.id
                    ? "#012497"
                    : "#2A3333",
                fontSize: "16px",
              }}
            />
          );
        })}
      </Tabs>

      {questionnaires.items.map((item, idx) => {
        return (
          <TabPanel
            key={`${item.id}-${idx}`}
            index={item.id}
            value={questionnaireDetails.questionnaireId}
          >
            {mainGrade ? (
              <Grid container>
                <Grid key={"chart"} item xs={6}>
                  <ComboChart grade={mainGrade} />
                </Grid>

                <Grid key={"info"} item xs={6}>
                  <Card className={styles.card}>
                    <CardContent>
                      <div className={styles.title}>{mainGrade.gradeName}</div>
                      <div className={styles.description}>
                        <TableContainer
                          style={{
                            backgroundColor: "rgba(255, 255, 255, 0)",
                            color: "#fff",
                          }}
                        >
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  className={styles.cell}
                                  style={{ width: 75 }}
                                >
                                  {t("DASHBOARD.NAME")}
                                </TableCell>
                                <TableCell
                                  className={styles.cell}
                                  style={{ width: 75 }}
                                >
                                  {t("DASHBOARD.RANGE")}
                                </TableCell>
                                <TableCell
                                  className={styles.cell}
                                  style={{ width: 100 }}
                                >
                                  {t(
                                    "DASHBOARD.GRAPHS.NO_OF_PATIENTS_COMPARISON"
                                  )}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {mainGrade.ranges.map((row, index) => {
                                return (
                                  <TableRow key={index}>
                                    <Tooltip
                                      title={row.description}
                                      arrow
                                      placement="left"
                                    >
                                      <TableCell className={styles.cell}>
                                        {row.rangeName}
                                      </TableCell>
                                    </Tooltip>

                                    <TableCell className={styles.cell}>
                                      {row.from}-{row.to}
                                    </TableCell>
                                    <TableCell className={styles.cell}>
                                      {`${row.score?.totalPatientsT0},${row.score?.totalPatientsT1}`}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </CardContent>
                  </Card>

                  <Card className={styles.card}>
                    <CardContent>
                      <Typography variant="h5" className={styles.chartLegend}>
                        <span className={styles.greenDot}></span>
                        {t("DASHBOARD.GRAPHS.FIRST_GRADE", {
                          avg: mainGrade.t0Avg,
                        })}
                      </Typography>
                      <Typography variant="h5" className={styles.chartLegend}>
                        <span className={styles.purpleDot}></span>
                        {t("DASHBOARD.GRAPHS.SECOND_GRADE", {
                          avg: mainGrade.t1Avg,
                        })}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            ) : (
              <Grid container>
                <Grid key={"chart"} item xs={6}>
                  <Card className={styles.card}>
                    <Box
                      display={"flex"}
                      alignItems="center"
                      justifyContent={"center"}
                      style={{ minHeight: 400, minWidth: "70vw" }}
                    >
                      {isLoading ? (
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "row",
                            padding: "20px 40px",
                            gap: "40px",
                          }}
                        >
                          <Skeleton
                            sx={{
                              bgcolor: "#F5F5F5",
                            }}
                            variant="rectangular"
                            width={"70vw"}
                            height={400}
                          />
                          <Skeleton
                            sx={{
                              bgcolor: "#F5F5F5",
                            }}
                            variant="rectangular"
                            width={"70vw"}
                            height={400}
                          />
                        </Box>
                      ) : (
                        <Typography> No main grade</Typography>
                      )}
                    </Box>
                  </Card>
                </Grid>
                <Grid key={"info"} item xs={6}>
                  <div />
                </Grid>
              </Grid>
            )}
          </TabPanel>
        );
      })}
      <Carousel isLoading={isLoading} />
    </Box>
  );
}
