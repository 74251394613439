import React from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { selectDirection } from "styles/theme/slice";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import AddTreatment from "./Components/AddTreatment";
import TreatmentTypesList from "./Components/List";
import { useUsersListSaga } from "./redux/saga";
import { reducer, sliceKey } from "./redux/slice";
interface Props {}

const TreatmentTypes = (props: Props) => {
  useInjectReducer({ key: sliceKey, reducer });
  useInjectSaga({ key: sliceKey, saga: useUsersListSaga });

  const direction = useSelector(selectDirection);
  return (
    <div dir={direction}>
      <Routes>
        <Route path="/" element={<TreatmentTypesList />} />
        <Route path="new" element={<AddTreatment mode="new" />} />
        <Route path=":id/edit" element={<AddTreatment mode="edit" />} />
        <Route path=":id/view" element={<AddTreatment mode="view" />} />
      </Routes>
    </div>
  );
};

export default TreatmentTypes;
