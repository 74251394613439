import React from "react";

import { Grid } from "@mui/material";
import { BottomActions } from "app/components/BottomActions";
import { ActionButtons } from "app/components/Button/ActionButtons";
import {
  selectAvailableTimeSlots,
  selectRemainingTreatments,
} from "app/containers/AssignedTreament/redux/selector";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectFeaturePermissions } from "store/global/authPermission/selector";
import { PermissionFeature } from "store/global/authPermission/types";
export const TimeslotActions = ({ onClose, disable, onSave, isLoading }) => {
  const { t } = useTranslation();
  const availableTimeSlots = useSelector(selectAvailableTimeSlots);
  const remainingTreatments = useSelector(selectRemainingTreatments);
  const treatmentInvolvedPatient = availableTimeSlots?.treatmentInvolvedPatient;
  const treatmentBankTreatment =
    treatmentInvolvedPatient?.treatmentBankTreatment;
  const treatment = treatmentBankTreatment?.treatment;
  const featurePermissions = useSelector(selectFeaturePermissions);
  const assigningPermission =
    featurePermissions[PermissionFeature.ASSIGNED_TREATNEMTS_A];
  const schedulingPermission =
    featurePermissions[PermissionFeature.ASSIGNED_TREATNEMTS_B];

  const isBonus = treatmentInvolvedPatient?.type == "Bonus";
  return (
    <Grid style={{ zIndex: 1 }}>
      <BottomActions
        chipBoxes={
          isBonus
            ? [
                {
                  label: t("TIMESLOT.TREATMENT_TYPE"),
                  count: treatmentInvolvedPatient?.type,
                },
              ]
            : [
                {
                  label: t("TIMESLOT.REMAINING_TREATMENTS"),
                  count: String(remainingTreatments),
                },
                {
                  label: t("ASSIGNED_TREATMENT.IS_MUST"),
                  count: treatmentBankTreatment?.isMust ? "Yes" : "No",
                },
                {
                  label: t("TIMESLOT.TYPE"),
                  count: String(treatment?.type),
                },
              ]
        }
      >
        <Grid mt={1}>
          <ActionButtons
            loading={isLoading}
            onCancel={onClose}
            onSave={onSave}
            disabled={disable}
            disableSave={
              !(
                assigningPermission.hasFullAccess ||
                schedulingPermission.hasFullAccess
              )
            }
          />
        </Grid>
      </BottomActions>
    </Grid>
  );
};
