import { Add } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Fab,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import DataTable from "app/components/DataTable";
import { Modal } from "app/components/Modal";
import { MultiSelectComponent } from "app/components/MultiSelect";
import { validateCurrentGrade } from "app/containers/Questionnaires/multi-questioner-form.validator";
import {
  GradesListItemType,
  newGradeDefaultValues,
} from "app/containers/Questionnaires/types";

import { getQuestionRange } from "app/containers/Questionnaires/helpers";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import toastService from "utils/toast";
import {
  selectCurrentGrade,
  selectGrades,
  selectHasAnswerAdded,
  selectLoading,
  selectQuestionnaire,
  selectQuestions,
} from "../../../../redux/selector";
import { actions } from "../../../../redux/slice";
import { TableField } from "../TableField";
import RangeForm from "./components/RangeForm";
import { useStyles } from "./styles";

const GradeForm = ({ mode }) => {
  const dispatch = useDispatch();

  const grades = useSelector(selectGrades);
  const hasAnswerAdded = useSelector(selectHasAnswerAdded);

  const currentGrade = useSelector(selectCurrentGrade);

  const questionnaire = useSelector(selectQuestionnaire);

  const [displayGradeRange, setDisplayGradeRange] = useState<
    { min: number; max: number } | undefined
  >();

  const questions = useSelector(selectQuestions);

  const { t, i18n } = useTranslation();

  const changeHandler = (e, index) => {
    dispatch(
      actions.editMainGrade({
        index,
      })
    );
  };

  const gradeColumns = [
    {
      id: "gradeName",
      label: t("QUESTIONNAIRES.GRADE_NAME"),
      sortValue: "selector.gradeName",
      format: (val: GradesListItemType, index) => {
        return (
          <TableField value={val.gradeName} error={val?.errors?.gradeName} />
        );
      },
    },
    {
      id: "questionNumbers",
      label: t("QUESTIONNAIRES.QUESTION_NUMBER"),
      sortValue: "selector.questionNumbers",
      format: (val: GradesListItemType, index) => {
        return (
          <TableField
            value={val.questionNumbers}
            error={val?.errors?.questionNumbers}
          />
        );
      },
    },
    {
      id: "gradeType",
      label: t("QUESTIONNAIRES.GRADE_TYPE"),
      sortValue: "selector.gradeType",
      format: (val: GradesListItemType, index) => {
        return (
          <TableField value={val.gradeType} error={val?.errors?.gradeType} />
        );
      },
    },
    {
      id: "mainGrade",
      label: t("QUESTIONNAIRES.MAIN_GRADE"),
      sortValue: "selector.mainGrade",
      format: (value: GradesListItemType, index) => {
        return (
          <Checkbox
            name={"isActive"}
            checked={!!value?.mainGrade}
            value={"isActive"}
            onChange={(e) => {
              changeHandler(e, index);
            }}
            onDoubleClick={(e) => e.stopPropagation()}
            color="info"
          />
        );
      },
    },
    {
      id: "ranges",
      label: t("QUESTIONNAIRES.RANGES"),
      sortValue: "selector.ranges",
      format: (val: GradesListItemType, index) => {
        const ranges = val.ranges.map((m) => `${m.from}-${m.to}`).join(",");
        const errors = val.ranges
          .filter((m) => m.errors?.to)
          .map((m) => {
            return m.errors?.to;
          })
          .join(",");

        return <TableField value={ranges} error={errors} />;
      },
    },
  ];

  const styles = useStyles();

  const loading = useSelector(selectLoading);

  const setFormValue = (name: string, value: string) => {
    dispatch(actions.setCurrentGradeValue({ name, value }));
  };

  const handleGradeOpen = () => {
    dispatch(actions.setCurrentGrade({ grade: newGradeDefaultValues }));
  };

  const handleGradeOpenEdit = (grade, i) => {
    dispatch(actions.setCurrentGrade({ grade: grade, index: i }));
  };

  const deleteAction = (value, index) => {
    dispatch(actions.removeGrade({ index: index }));
  };

  const handleGradeClose = () => {
    dispatch(actions.setCurrentGrade({ grade: undefined }));
  };

  useEffect(() => {
    const fQuestions = currentGrade?.questionNumbers.length
      ? questions.filter((f) =>
          currentGrade?.questionNumbers.includes(String(f.questionNumber))
        )
      : [];

    const range = fQuestions.length ? getQuestionRange(fQuestions) : undefined;
    setDisplayGradeRange(range);
  }, [questions, currentGrade?.questionNumbers]);

  const handleGradeUpdate = () => {
    if (currentGrade) {
      const errors = validateCurrentGrade(
        currentGrade,
        displayGradeRange?.min,
        displayGradeRange?.max
      );
      if (errors.length) {
        dispatch(actions.setFormErrors({ key: "currentGrade.", errors }));
        return toastService.error(
          t("QUESTIONNAIRES.FORM.ERRORS.FIX_ERRORS_FIRST")
        );
      }
      dispatch(
        actions.editGrade({
          grade: currentGrade,
        })
      );
      handleGradeClose();
    }
  };

  const openGrade = Boolean(currentGrade);

  return (
    <>
      <DataTable
        loading={loading}
        rows={grades}
        columns={gradeColumns}
        Actions="Edit,Delete"
        disableActions={mode === "view" ? "Edit,Delete" : ""}
        scrollClass={styles.dataTableDiv}
        editAction={handleGradeOpenEdit}
        checkboxChange={changeHandler}
        deleteAction={deleteAction}
      />
      <Modal
        open={openGrade}
        onClose={handleGradeClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card
          className={styles.addModal}
          style={{
            direction: i18n.dir(),
          }}
        >
          <div className={styles.modalContainer}>
            <Grid>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography
                  component={"span"}
                  align="center"
                  className={styles.headerModal}
                >
                  {currentGrade?.isEdit
                    ? t("QUESTIONNAIRES.EDIT_GRADE")
                    : t("QUESTIONNAIRES.ADD_GRADE")}
                </Typography>
              </Box>
            </Grid>
            <Grid
              xs={12}
              container
              rowSpacing={1.5}
              sx={{
                padding: "20px 0px",
                margin: "0px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Grid item xs={4}>
                <label className={styles.label}>
                  {t("QUESTIONNAIRES.GRADE_NAME")}
                </label>
                <TextField
                  name="gradeName"
                  margin="none"
                  fullWidth
                  variant="outlined"
                  required
                  autoComplete={"off"}
                  className={styles.textEnabled}
                  placeholder={t("QUESTIONNAIRES.GRADE_NAME")}
                  value={currentGrade?.gradeName}
                  onChange={(e) => {
                    setFormValue("gradeName", e.target.value);
                  }}
                  error={!!currentGrade?.errors?.gradeName}
                  helperText={currentGrade?.errors?.gradeName}
                  FormHelperTextProps={{
                    style: {
                      backgroundColor: "#F5F8FF",
                      margin: "0px",
                      padding: "0px",
                      textAlign: i18n.dir() === "ltr" ? "left" : "right",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <MultiSelectComponent
                  label={t("QUESTIONNAIRES.QUESTIONS")}
                  menuItems={questions.map((value) => value.questionNumber)}
                  error={!!currentGrade?.errors?.questionNumbers}
                  helperText={currentGrade?.errors?.questionNumbers}
                  onSelect={(values) => {
                    setFormValue("questionNumbers", values);
                  }}
                  selectedValues={
                    currentGrade?.questionNumbers?.split(",") || []
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <label className={styles.label}>
                  {t("QUESTIONNAIRES.GRADE_TYPE")}
                </label>
                <Select
                  variant="outlined"
                  fullWidth
                  margin="none"
                  required
                  name="gradeType"
                  autoComplete={"off"}
                  className={styles.textEnabled}
                  IconComponent={KeyboardArrowDownIcon}
                  displayEmpty
                  value={currentGrade?.gradeType}
                  onChange={(e) => {
                    setFormValue("gradeType", e.target.value);
                  }}
                  error={!!currentGrade?.errors?.gradeName}
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return (
                        <p style={{ color: "#A4A9AB", margin: 0 }}>Choose</p>
                      );
                    }
                    return selected;
                  }}
                >
                  <MenuItem value={"RANGE"}>Range</MenuItem>
                  <MenuItem value={"AVERAGE"}>Average</MenuItem>
                </Select>
              </Grid>
            </Grid>
            <RangeForm mode={mode} displayGradeRange={displayGradeRange} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Button
                className={styles.cancelBtn}
                variant="outlined"
                onClick={handleGradeClose}
              >
                {t("BUTTONS.CANCEL")}
              </Button>
              <Button
                className={styles.saveBtn}
                variant="contained"
                onClick={handleGradeUpdate}
              >
                {t("BUTTONS.SAVE")}
              </Button>
            </Box>
          </div>
        </Card>
      </Modal>
      <FormHelperText error>
        {questionnaire?.errors?.gradesGeneral}
      </FormHelperText>

      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          paddingTop: "10px",
        }}
      >
        <Tooltip
          placement="top"
          title={
            !hasAnswerAdded
              ? t("QUESTIONNAIRES.FORM.ERRORS.ADD_LEAST_ONE_QUESTION")
              : t("QUESTIONNAIRES.ADD_GRADE")
          }
        >
          <span>
            <span className={styles.addQues}>
              {t("QUESTIONNAIRES.ADD_GRADE")}
            </span>
            <Fab
              size="medium"
              color="secondary"
              aria-label="add"
              sx={{ marginTop: 2, marginBottom: 2, zIndex: 0 }}
              onClick={handleGradeOpen}
              disabled={!hasAnswerAdded || mode === "view"}
            >
              <Add />
            </Fab>
          </span>
        </Tooltip>
      </Grid>
    </>
  );
};

export default GradeForm;
