import {
  Button,
  Card,
  Grid,
  Modal,
  Link as MuiLink,
  Typography,
} from "@mui/material";
import BankFiled from "app/components/FieldComponent/Bank";
import DepartmentFiled from "app/components/FieldComponent/Department";
import OrganizationField from "app/components/FieldComponent/Organization";
import { FormError } from "app/containers/types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { API_URL } from "utils/constants";
import { actions } from "../../../../redux/slice";
import { UploadField } from "./UploadField";
import { useStyles } from "./styles";
import { validate } from "./validator";
const ImportModal = ({ open, onClose }) => {
  const styles = useStyles();
  const [organizationId, setOrganisation] = useState<number>(0);
  const [bankId, setBank] = useState<number>(0);
  const [departmentId, setDepartment] = useState<number>(0);
  const [sampleDownloadLink, setSampleDownloadLink] = useState<string>("");
  const [file, setUploadFile] = useState<string>("");
  const [errors, setErrors] = useState<FormError[]>([]);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const onUpload = (path: string) => {
    setUploadFile(path);
  };

  useEffect(() => {
    const authToken = localStorage.getItem("sessionToken");
    setSampleDownloadLink(
      `${API_URL}/v1/imports/samples?type=PATIENTS&authToken=${authToken}`
    );
  }, []);

  const onSave = (e) => {
    const errors = validate({ organizationId, departmentId, bankId, file });
    if (errors.length) {
      setErrors(errors);
      return;
    }
    dispatch(
      actions.importPatients({
        treatmentBankId: bankId,
        file: file,
        type: "PATIENTS",
        processNow: true,
      })
    );
  };

  const hasError = (name: string) => {
    return errors.find((f) => f.name === name)?.error || undefined;
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card className={styles.importModal}>
        <div className={styles.modalContainer}>
          <Grid>
            <Typography align="center" className={styles.headerModal}>
              {t("PATIENTS.IMPORT_FROM_EXCEL")}
            </Typography>
          </Grid>
          <Grid
            container
            rowSpacing={3}
            columnSpacing={{ xs: 1, sm: 1, md: 2 }}
            sx={{ padding: "20px 0px" }}
          >
            <Grid item xs={4}>
              <OrganizationField
                value={organizationId}
                xs={11.2}
                changeValue={(value) => {
                  setOrganisation(value);
                }}
                showAll={true}
                error={hasError("organizationId")}
                activeRequired={true}
              ></OrganizationField>
            </Grid>
            <Grid item xs={4}>
              <DepartmentFiled
                xs={11.2}
                value={departmentId}
                changeValue={(value) => {
                  setDepartment(value);
                }}
                orgId={organizationId}
                showAll={true}
                error={hasError("departmentId")}
              ></DepartmentFiled>
            </Grid>
            <Grid item xs={4}>
              <BankFiled
                value={bankId}
                xs={11.2}
                organizationId={organizationId}
                departmentId={departmentId}
                changeValue={(value) => {
                  setBank(value);
                }}
                showAll={true}
                error={hasError("bankId")}
              ></BankFiled>
            </Grid>
            <Grid item xs={12}>
              <UploadField
                onUpload={onUpload}
                supportedFile={
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                }
              />
            </Grid>
            <Grid item xs={12} className={styles.links}>
              <MuiLink href={`${sampleDownloadLink}`} target="_blank">
                {" "}
                {t("PATIENTS.DOWNLOAD_SAMPLE")}{" "}
              </MuiLink>
              <Link to={"/imports"}> {t("PATIENTS.IMPORT_HISTORY")} </Link>
            </Grid>
          </Grid>
          <div style={{ marginTop: "15px" }}>
            <Grid container spacing={4}>
              <Grid
                className={styles.btnContainer}
                container
                item
                xs={6}
                direction="column"
              >
                <Button
                  className={styles.cancelBtn}
                  variant="outlined"
                  onClick={onClose}
                >
                  {t("BUTTONS.CANCEL")}
                </Button>
              </Grid>
              <Grid container item xs={6} direction="column">
                <Button
                  className={styles.saveBtn}
                  variant="contained"
                  onClick={onSave}
                >
                  {t("BUTTONS.SAVE")}
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </Card>
    </Modal>
  );
};
export default ImportModal;
