import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  tableContainer: {
    maxHeight: 300, // Set the desired height for vertical scrolling
    overflowY: "auto",
  },
  head: {
    position: "sticky",
    top: 0,
    zIndex: 1, // Ensure the header stays above the content
    backgroundColor: "#fff",
  },
  label_date: {
    display: "flex",
    gap: "5px",
    // Add your additional styles here
  },
  date_section_wrapper: {
    // Add your additional styles here
  },
  date_section: {
    borderRadius: "5px",
    padding: "10px",
    background: "#F5F8FF",
    minWidth: 120,
    // Add your additional styles here
  },
  timeslot_right_section: {
    background: "#FFFFFF",
    display: "table",
    minWidth: 80,
    position: "absolute",
    right: 0,
    // Add your additional styles here
  },
  timeslot_right: {
    color: "#012397",
    verticalAlign: "middle",
    paddingLeft: "10px",
    display: "table-cell",
    // Add your additional styles here
  },
  title_section: {
    marginLeft: "15px",
    fontWeight: 590,
    color: "#000000",
    // Add your additional styles here
  },
  section_date: {
    display: "block",
    color: "#683AD2",
    // Add your additional styles here
  },
  section_time: {
    display: "block",
    fontSize: "15px",
    // Add your additional styles here
  },

  date_area: {
    marginLeft: "0px",
    padding: "8px",
    background: "#FFFFFF",
    margin: 0,
  },
}));
