import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles(() => ({
  modal: {
    position: "absolute",
    background: "#F5F8FF",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "0px",
    boxShadow: "0",
    zIndex: "9999",
    width: "1150px",
  },
  modalContainer: {
    padding: "20px 20px 20px 20px",
    overflowY: "auto",
    maxHeight: "90vh",
  },
  headerModal: {
    color: "#012497",
    fontFamily: "Cairo",
    fontWeight: "800",
    fontSize: "24px",
  },
  table: {
    marginTop: "20px",
    width: "100%",
    border: "1px solid lightgrey",
    borderRadius: "5px",
    overflowX: "auto",
  },
  trHead: {
    backgroundColor: "#F5F8FF",
  },
  tr: {
    "&:nth-child(odd)": {
      backgroundColor: "#fff",
    },
  },
  tdHeader: {
    padding: "20px",
    fontFamily: "Cairo",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "2",
    color: "#2A3333",
    whiteSpace: "nowrap",
  },
  actionTD: {
    padding: "15px",
  },
  tbody: {},
  tableWrap: {
    paddingBottom: "10px",
  },
  dateTd: {
    padding: "15px 18px",
    fontFamily: "Cairo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px",
    color: "#2A3333",
  },
  btnContainer: {
    display: "flex",
    alignItems: "center",
  },
  cancelBtn: {
    color: "#3D63E6",
    borderColor: "#3D63E6",
    "&:hover": {
      borderColor: "#3D63E6",
    },
    fontFamily: "Cairo",
    fontWeight: 700,
    fontSize: "16px",
    width: "160px",
    height: "50px",
  },
}));
