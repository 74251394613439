export type RootPermissionState = {
  isLoading?: boolean;
  user: User;
};

export interface User {
  id?: number;
  firstName: string;
  lastName: string;
  gender?: string;
  dateOfBirth: any;
  martialStatus: string;
  numberOfChildren: number;
  email: string;
  mobileNumber: string;
  city: string;
  street: string;
  area: string;
  userGroupId?: number;
  socialId: any;
  provider: string;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  userGroup: UserGroup;
  role: Role;
  url?: string;
}

export interface UserGroup {
  id?: number;
  groupName: string;
  numberOfUser: number;
  createdAt: string;
  updatedAt: string;
  userGroupsPermissions: UserGroupsPermission[];
}

export interface UserGroupsPermission {
  id: number;
  featureName: PermissionFeature;
  noAccess: boolean;
  readAccess: boolean;
  fullAccess: boolean;
  createdAt: string;
  updatedAt: string;
  userGroupId?: number;
}

export interface Role {
  id?: number;
  name: string;
  __entity: string;
}

export enum PermissionFeature {
  DASHBOARD = "USERS.DASHBOARD",
  ORGANIZATIONS_LIST = "USERS.ORGANIZATIONS_LIST",
  ORGANIZATIONS_DETAILS = "USERS.ORGANIZATIONS_DETAILS",
  TREATMENT_BANK_LIST = "USERS.TREATMENT_BANK_LIST",
  USER_LIST = "USERS.USER_LIST",
  USER_GROUPS = "USERS.USER_GROUPS",
  PATIENTS_LIST = "USERS.PATIENTS_LIST",
  PATIENT_DETAILS_STATUS = "USERS.PATIENT_DETAILS_STATUS",
  PATIENT_DETAILS_PRIVATE = "USERS.PATIENT_DETAILS_PRIVATE",
  PATIENT_DETAILS_PROGRESS = "USERS.PATIENT_DETAILS_PROGRESS",
  ASSIGNED_TREATNEMTS_B = "USERS.ASSIGNED_TREATNEMTS_B",
  TREATMENT_TYPES = "USERS.TREATMENT_TYPES",
  PUSH_NOTIFICATION = "USERS.PUSH_NOTIFICATION",
  TIMESLOTS = "USERS.TIMESLOTS",
  QUESTIONNAIRES = "USERS.QUESTIONNAIRES",
  QUESTIONNAIRES_RESULTS = "USERS.QUESTIONNAIRES_RESULTS",
  ASSIGNED_TREATNEMTS_A = "USERS.ASSIGNED_TREATNEMTS_A",
  DAILY_ACTIVITY = "USERS.DAILY_ACTIVITY",
}

export const UserPermissionState: RootPermissionState = {
  isLoading: false,
  user: {
    id: undefined,
    firstName: "",
    lastName: "",
    gender: undefined,
    dateOfBirth: null,
    martialStatus: "",
    numberOfChildren: 0,
    email: "",
    mobileNumber: "",
    city: "",
    street: "",
    area: "",
    userGroupId: undefined,
    socialId: null,
    provider: "email",
    isActive: true,
    createdAt: "",
    updatedAt: "",
    userGroup: {
      id: undefined,
      groupName: "",
      numberOfUser: 0,
      createdAt: "",
      updatedAt: "",
      userGroupsPermissions: [],
    },
    role: {
      id: undefined,
      name: "User",
      __entity: "Role",
    },
  },
};

// Define a type for the feature permissions object
export type FeaturePermissionSelectors = {
  [key in PermissionFeature]: {
    hasNoAccess: boolean;
    hasReadAccess: boolean;
    hasFullAccess: boolean;
  };
};

export type ContainerState = RootPermissionState;
