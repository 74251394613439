import { createSlice } from "utils/@reduxjs/toolkit";

import { PayloadAction } from "@reduxjs/toolkit";
import { FormError } from "app/containers/types";
import set from "lodash/set";
import { ContainerState } from "../types";

// The initial state of the GithubRepoForm container
export const initialState: ContainerState = {
  firstName: {
    value: "",
    error: "",
  },
  lastName: {
    value: "",
    error: "",
  },
  mobileNumber: {
    value: "",
    error: "",
  },
  email: {
    value: "",
    error: "",
  },
  language: {
    value: "en",
    error: "",
  },
  profilePic: {
    value: "",
    error: "",
  },
  url: {
    value: "",
    error: "",
  },
  isLoading: false,
};

const profileSlice = createSlice({
  name: "profileState",
  initialState,
  reducers: {
    setLanguage(state, action: PayloadAction<"en" | "he">) {
      set(state.language, "value", action.payload);
      set(state.language, "error", "");
    },
    updateFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `${action.payload.key}.value`, action.payload.value);
      set(state, `${action.payload.key}.error`, null);
    },
    getProfile(state) {
      state.isLoading = true;
    },
    updateProfile: (state, action: PayloadAction<{ callback: () => void }>) => {
      state.isLoading = true;
    },
    setFrom: (state, action: PayloadAction<any>) => {
      state.mobileNumber.value = action.payload.mobileNumber;
      state.firstName.value = action.payload.firstName;
      state.lastName.value = action.payload.lastName;
      state.email.value = action.payload.email;
      state.language.value = action.payload.language;
      state.profilePic.value = action.payload.profilePic;
      state.url.value = action.payload.url;
      state.isLoading = false;
    },
    setFormErrors: (state, action: PayloadAction<FormError[]>) => {
      action.payload.forEach((error) => {
        set(state, `${error.name}.error`, error.error);
      });
    },
    resetIsLoading: (state) => {
      state.isLoading = false;
    },
  },
});

export const { actions, reducer, name: profileSliceKey } = profileSlice;
