import { FormError } from "app/containers/types";
import { emailRegex } from "styles/theme/utils";
import { LoginForm } from "../types";

export function validate(form: LoginForm): Array<FormError> {
  const errors: Array<FormError> = [];
  if (!emailRegex.test(form.email.value)) {
    errors.push({
      name: "email",
      error: "Invalid Email Format",
    });
  }
  if (!form.email.value) {
    errors.push({
      name: "email",
      error: "Email can't be blank",
    });
  }
  if (!form.password.value) {
    errors.push({
      name: "password",
      error: "Password can't be blank",
    });
  }

  return errors;
}
