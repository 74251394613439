import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Box,
  Button,
  Card,
  Grid,
  MenuItem,
  Modal,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectInstructors, selectList } from "../../redux/selector";
import { actions } from "../../redux/slice";
import { useStyles } from "./styles";

export default function AssignInstructorForm({ open, handleClose, patientId }) {
  const styles = useStyles();
  const theme = useTheme();
  const direction = theme.direction;
  const listitems = useSelector(selectList);
  const instructors = useSelector(selectInstructors);
  const { t } = useTranslation();
  const selectedIds = listitems.selectedIds;

  const [instructorId, setInstructorId] = useState("");
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedIds?.length > 0) {
      dispatch(actions.getAssignedInstructors(selectedIds?.join(",")));
    }
  }, [selectedIds]);

  useEffect(() => {
    if (instructors.length == 0) {
      setError(t("ASSIGNED_TREATMENT.MATCHING_INSTRUCTOR_NOT_FOUND"));
    } else {
      setError("");
    }

    return () => {};
  }, [instructors]);

  const handleSave = () => {
    if (instructorId === "") {
      setError(t("ASSIGNED_TREATMENT.SELECT_INSTRUCTOR"));
      return;
    }
    dispatch(
      actions.assignInstructors({
        treatmentInvolvedPatientIds: selectedIds?.join(","),
        selectedInstructorId: instructorId?.toString(),
        patientId: patientId,
      })
    );
    setInstructorId("");
    setError("");
    handleClose();
  };
  return (
    <Modal
      open={open}
      onClose={() => {
        setInstructorId("");
        setError("");
        handleClose();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card sx={{ direction: direction }} className={styles.importModal}>
        <div className={styles.modalContainer}>
          <Grid>
            <Typography align="center" className={styles.headerModal}>
              {t("ASSIGNED_TREATMENT.ASSIGN_INSTRUCTOR")}
            </Typography>
          </Grid>
          <Grid
            item
            container
            spacing={4}
            md={12}
            sx={{ padding: "10px 0px 0px 0px" }}
          >
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <label className={styles.label}>
                  {t("ASSIGNED_TREATMENT.SELECT_INSTRUCTOR")}
                </label>
                <Select
                  labelId="instructor"
                  id="instructor"
                  disableUnderline
                  IconComponent={KeyboardArrowDownIcon}
                  value={instructorId}
                  className={styles.textEnabledSelect}
                  margin="dense"
                  fullWidth
                  variant="outlined"
                  onChange={(e) => {
                    setError("");
                    setInstructorId(e.target.value);
                  }}
                  error={!!error}
                >
                  {instructors?.map((data, i) => (
                    <MenuItem value={data?.id}>
                      {data.firstName} {data.lastName}
                    </MenuItem>
                  ))}
                </Select>
                {error && (
                  <Typography
                    variant="caption"
                    sx={{
                      fontSize: "0.75rem !important",
                      lineHeight: "1.66",
                      marginTop: "3px",
                      color: "#d32f2f",
                    }}
                  >
                    {error}
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
          <div>
            <Grid
              container
              spacing={3}
              style={{ position: "relative", top: "35px" }}
            >
              <Grid
                className={styles.btnContainer}
                container
                item
                xs={6}
                direction="column"
              >
                <Button
                  className={styles.cancelBtn}
                  variant="outlined"
                  onClick={() => {
                    setInstructorId("");
                    setError("");
                    handleClose();
                  }}
                >
                  {t("BUTTONS.CANCEL")}
                </Button>
              </Grid>
              <Grid container item xs={6} direction="column">
                <Button
                  onClick={handleSave}
                  className={styles.saveBtn}
                  variant="contained"
                >
                  {t("BUTTONS.SAVE")}
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </Card>
    </Modal>
  );
}
