import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles(() => ({
  bg_color: {
    background: "#F5F8FF",
  },

  tableDiv: {
    border: "1px",
  },
  dataTableDiv: {
    maxHeight: "calc(100vh - 210px)",
    //maxWidth: "calc(159vh)",
    // overflowY: "auto",
    border: "1px solid #C6C9CA",
    overflowX: "auto",
    marginTop: "0px",
    background: "#F5F8FF",
  },
  button: {
    width: "160px",
    height: "50px",
    padding: "10px 16px",
    background: "#3D63E6",
    borderRadius: "4px",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "30px",
    color: "#FFF",
  },
}));
