import { createSlice } from "utils/@reduxjs/toolkit";
import { UserPermissionState } from "./types/index";

const initialState = UserPermissionState;
export const userPermissionListSlice = createSlice({
  name: "authPermissionState",
  initialState,
  reducers: {
    getPermissionList: (state) => {
      state.isLoading = true;
    },
    getPermissionListSuccess: (state, action) => {
      state.user = action.payload;
      state.isLoading = false;
    },
    getPermissionListFailure: (state) => {
      state.isLoading = false;
    },
  },
});
export const { reducer, actions, name: sliceKey } = userPermissionListSlice;
