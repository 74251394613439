import { FormElement } from "app/containers/types";
import moment from "moment";

export interface ResponseError {
  message: string;
}
export type RequestError = ResponseError;

export type CalenderListItemType = {
  //SHOULD DECIDE TYPES ACCORDINGLY FOR NOW ALL STRINGS
  timeSlotId: string | null;
  meetingId: string | null;
  dateAssigned: string | null;
  organizationName: string | null;
  departmentName: string | null;
  bankName: string | null;
  timeslotDate: string | null;
  startTime: string | null;
  endTime: string | null;
  treatmentType: string | null;
  instructorName: string | null;
  actualInstructorName: string | null;
  patientList: string[];
  assignedTreatment: any[];
  timeslot: any;
};

export interface NewUserRequest {
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
  companyName: string;
  designation: any;
  numberOfEmployees: string;
  user_type: string;
  isActive: any;
}

export interface PatientList {
  id: number;
  firstName: string;
  lastName: string;
  status: string;
  attandence: number;
}

export type NewUserResponse = {
  id: any;
  codeSentAt: any;
  firstName: string;
  lastName: string;
  mobileNumber: string;
  countryCode: any;
  email: string;
  companyName: string;
  designation: string;
  numberOfEmployees: string;
  language: string;
  isActive: true;
  role: any;
  image: any;
  userType: string;
  createdAt: string;
  updatedAt: string;
  fullName: string;
  message?: string;
};

export type UserForm = {
  id: FormElement;
  firstName: FormElement;
  lastName: FormElement;
  email: FormElement;
  dob: FormElement;
  mobileNumber: FormElement;
  createdAt: FormElement;
  updatedAt: FormElement;
  ip: FormElement;
  mac: FormElement;
  user_type: FormElement;
};

export type calenderListStateType = {
  attendanceForm: any;
  query: {
    limit: number;
    page: number;
    departmentId: number;
    organizationId: number;
    bankId: number;
    treatmentId: number;
    instructorId: number;
    actualInstructorId: number;
    orderField: string;
    orderDirection: string;
    createMin: string;
    createMax: string;
    updateMin: string;
    updateMax: string;
    date: string;
    q: string;
    mode: string;
  };
  form: {
    user: UserForm;
  };
  list: {
    items: Array<CalenderListItemType>;
    meta: {
      totalItems: number;
      itemCount: number;
      itemsPerPage: number;
      totalPages: number;
      currentPage: number;
      treatmentLeft: number;
      treatmentScheduled: number;
      treatmentTotal: number;
    };
    links: {
      first: string;
      last: string;
      previous: string;
      next: string;
    };
  };
  loading: boolean;
  error: string;
  patientList: PatientList[];
};

export const UserState: calenderListStateType = {
  attendanceForm: {},
  query: {
    actualInstructorId: 0,
    bankId: 0,
    departmentId: 0,
    instructorId: 0,
    organizationId: 0,
    treatmentId: 0,
    limit: 10,
    page: 1,

    orderField: "",
    orderDirection: "",
    updateMax: "",
    updateMin: "",
    createMax: "",
    createMin: "",
    date: moment().startOf("month").format("YYYY-MM-DD"),
    q: "",
    mode: "month",
  },
  list: {
    items: [],
    meta: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 100,
      totalPages: 1,
      currentPage: 1,
      treatmentLeft: 0,
      treatmentScheduled: 0,
      treatmentTotal: 0,
    },
    links: {
      first: "",
      previous: "",
      next: "",
      last: "",
    },
  },
  loading: false,
  form: {
    user: {
      id: { value: "" },
      firstName: { value: "" },
      lastName: { value: "" },
      mobileNumber: { value: "" },
      createdAt: { value: "" },
      dob: { value: "" },
      email: { value: "" },
      updatedAt: { value: "" },
      user_type: { value: "" },
      ip: { value: "" },
      mac: { value: "" },
    },
  },
  error: "",
  patientList: [],
};

export type ContainerState = calenderListStateType;
