import { actions as authActions } from "app/containers/Auth/slice";
import i18next from "i18next";
import { put, takeLatest } from "redux-saga/effects";
import { themeActions } from "styles/theme/slice";
import { API_URL } from "utils/constants";
import { TRANSLATE, getDefaultHeaders } from "utils/helpers";
import { request } from "utils/request";
import toastService from "utils/toast";
import { actions } from "./slice";

export function* getPermissionList(action) {
  // yield delay(500);

  try {
    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    const response = yield request(`${API_URL}/v1/auth/me`, options);
    if (response.language) {
      localStorage.setItem("i18nextLng", response.language);
      yield i18next.changeLanguage(response.language);
      if (response.language == "en") {
        yield put(themeActions.toggleDirection("ltr"));
      }
      if (response.language == "he") {
        yield put(themeActions.toggleDirection("rtl"));
      }
    }
    yield put(actions.getPermissionListSuccess(response));
    return response;
  } catch (e: any) {
    if (e?.response?.status === 401) {
      yield localStorage.clear();
      yield put(authActions.logout());
      return;
    }
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", {
        error: JSON.stringify(e),
      })
    );
  }
}

export function* authFieldsSaga() {
  yield takeLatest(actions.getPermissionList.type, getPermissionList);
}
