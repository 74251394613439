import { CircularProgress } from "@mui/material";
import React, { ReactNode } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useStyles } from "./styles";
type ScrollableViewProps = {
  height?: number; // Optional height property
  loadMore: () => void; // Function to load more items
  hasMoreItems: boolean; // Boolean indicating whether there are more items to load
  children: ReactNode; // Content to be rendered inside the InfiniteScroll component
};

export const ScrollableView: React.FC<ScrollableViewProps> = ({
  height,
  loadMore,
  hasMoreItems,
  children,
}) => {
  const styles = useStyles();
  const loader = (
    <div key="loader" className={styles.dataTableDiv}>
      <CircularProgress style={{ width: "10px", height: "10px" }} /> Loading ...
    </div>
  );
  return (
    <div className={styles.dataTableDiv} style={{ height: height || "auto" }}>
      <InfiniteScroll
        loadMore={loadMore}
        hasMore={hasMoreItems}
        loader={loader}
        useWindow={false}
      >
        <div className={styles.UI}>{children}</div>
      </InfiniteScroll>
    </div>
  );
};
