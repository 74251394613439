import { PayloadAction } from "@reduxjs/toolkit";
import { FormError } from "app/containers/types";
import set from "lodash/set";
import { createSlice } from "utils/@reduxjs/toolkit";
import { DepartmentListItemType, UserState } from "../types";
const initialState = UserState;
export const DepartmentListSlice = createSlice({
  name: "departmentListState",
  initialState,
  reducers: {
    editUser: (state) => {
      const index = state.list.items.findIndex(
        (item) => item.id == state.form.department.id
      );
      state.list.items = [
        ...state.list.items.slice(0, index),
        {
          id: state.form.department.id,
          departmentName: state.form.department.departmentName.value,
          noOfBanks: state.form.department.noOfBanks.value,
          city: state.form.department.city.value,
          cityName: state.form.department.cityName.value,
          address: state.form.department.address.value,
          action: "edit",
        },
        ...state.list.items.slice(index + 1),
      ];
    },
    removeUser: (state) => {
      const editUser: any = {};
      editUser.firstName = "";
      editUser.lastName = "";
      editUser.email = "";
      editUser.phoneNumber = "";
      editUser.creationDate = null;
      editUser.dateOfBirth = null;
    },
    create: (state) => {
      state.list.items.push({
        id: null,
        departmentName: state.form.department.departmentName.value,
        noOfBanks: state.form.department.noOfBanks.value,
        city: state.form.department.city.value,
        cityName: state.form.department.cityName.value,
        address: state.form.department.address.value,
        action: "new",
      });
    },
    getList: (state, action: PayloadAction<{ id: any }>) => {
      state.loading = true;
    },
    getUserById: (state, action: PayloadAction<{ id: any }>) => {
      state.loading = true;
    },
    getListSuccess: (
      state,
      action: PayloadAction<{ items: any[]; links: any; meta: any }>
    ) => {
      state.loading = false;
      const items = action.payload.items.map((x) => {
        return {
          address: x.address,
          city: x.cityId,
          cityName: x?.cities?.name,
          departmentName: x.departmentName,
          id: x.id,
          noOfBanks: x.noOfBanks,
          action: "",
        };
      });
      state.list.meta = action.payload.meta;
      state.list.items =
        action.payload.meta.currentPage === 1
          ? items
          : [...state.list.items, ...items];
      state.list.meta = action.payload.meta;
    },
    getNextPage: (state, action: PayloadAction<any>) => {
      state.query.page = state.list.meta.currentPage + 1;
      state.loading = true;
    },
    getListComplete: (state) => {
      state.loading = false;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    update: (
      state,
      action: PayloadAction<{ id: any; callback: () => void }>
    ) => {
      state.error = "";
      state.loading = true;
    },
    updateFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      if (action.payload.key == "id") {
        set(
          state.form.department,
          `${action.payload.key}`,
          action.payload.value
        );
      } else {
        set(
          state.form.department,
          `${action.payload.key}.value`,
          action.payload.value
        );
      }
      if (
        state.form.department.departmentName.value !== "" &&
        state.form.department.noOfBanks.value !== ""
      ) {
        set(state.form.department, `disable`, false);
      } else {
        set(state.form.department, `disable`, true);
      }
    },
    updateSuccess(state, action: PayloadAction<DepartmentListItemType>) {
      state.loading = false;
    },
    updateFailed: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    delete: (state) => {
      state.error = "";
      state.loading = true;
    },
    deleteSuccess: (state) => {
      state.loading = false;
      state.error = "";
    },
    deleteFailed: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    resetNewDepartmentForm(state) {
      state.error = "";
      state.form = initialState.form;
    },
    setFormErrors(
      state,
      action: PayloadAction<{
        key: string;
        errors: FormError[];
      }>
    ) {
      action.payload.errors.forEach((error) => {
        set(
          state.form,
          `${action.payload.key}.${error.name}.error`,
          error.error
        );
      });
    },
    getNextPageItems: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    getNextPageItemsSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.list.items = state.list.items.concat(action.payload.items);
      state.list.meta.currentPage = action.payload.meta.currentPage;
      state.list.links = action.payload.links;
      state.query.page = action.payload.meta.currentPage;
    },
    setQuery: (state, action: PayloadAction<{ name: string; value: any }>) => {
      const { name, value } = action.payload;
      state.query.page = 1;
      state.list = initialState.list;
      set(state.query, name, value);
    },
    resetModule: (state) => {
      state.list.items = [];
    },
    handleBlock: (state, action: PayloadAction<string>) => {},
    handleBlockSuccess: (
      state,
      action: PayloadAction<DepartmentListItemType>
    ) => {},
    deleteDepratment: (state, action) => {
      const { id, idx } = action.payload;
      if (id) {
        for (let i = 0; i < state.list.items.length; i++) {
          const item = state.list.items[i];
          if (item.id === id) {
            state.list.items[i].action = "delete";
            break;
          }
        }
      } else {
        state.list.items = state.list.items.filter(
          (item, index) => index !== idx
        );
      }
    },
  },
});
export const { reducer, actions, name: sliceKey } = DepartmentListSlice;
