import React from "react";
import { Link } from "react-router-dom";

import { ThumbUp } from "@mui/icons-material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";

type Props = {};

const useStyles = makeStyles(() => ({
  heading: {
    fontSize: 16,
    fontWeight: 500,
    textAlign: "center",
    color: "#000",
    fontFamily: "Cairo",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  button: {
    width: "100%",
    height: 40,
    fontSize: 22,
    marginTop: 11,
  },
  icon: {
    width: 77,
    height: 70,
    color: "#000",
    margin: "53px 0px 60px 0px",
  },
  input: {
    marginTop: 24,
  },
  subHeading: {
    fontSize: 20,
    lineHeight: "23.87px",
    textAlign: "center",
    fontFamily: "Cairo",
  },
  loginLink: {
    color: "#EDAC15",
  },
}));

const ThankYou = (props: Props) => {
  const styles = useStyles();

  return (
    <Grid2 container justifyContent="center">
      <Grid2 xs={12} sm={6} md={5} lg={4} xl={3} sx={{ marginTop: "100px" }}>
        <Box className={styles.form}>
          <Box mb={2} className={styles.heading}>
            Password Set
          </Box>
          <ThumbUp className={styles.icon} />
          <div className={styles.subHeading}>
            Your Password has been set. Please{" "}
            <Link className={styles.loginLink} to="/login">
              Click Here
            </Link>{" "}
            to log in
          </div>
        </Box>
      </Grid2>
    </Grid2>
  );
};

export default ThankYou;
