import queryString from "query-string";
import { delay, put, select, takeLatest } from "redux-saga/effects";
import { API_URL } from "utils/constants";
import {
  TRANSLATE,
  getDefaultHeaders,
  removeBlankProperties,
} from "utils/helpers";
import { request } from "utils/request";
import toastService from "utils/toast";
import { selectQuery } from "./selector";
import { actions } from "./slice";

export function* getList(action) {
  yield delay(500);
  const patientId = action.payload.patientId;
  const query = yield select(selectQuery);
  const requestData = removeBlankProperties(query);
  const queries = patientId
    ? queryString.stringify({
        limit: 100,
        page: 1,
        patientId,
      })
    : queryString.stringify({
        ...requestData,
        patientId,
      });
  try {
    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    const response = yield request(
      `${API_URL}/v1/questionnaires-results?${queries}`,
      options
    );
    yield put(actions.getListSuccess(response));
  } catch (e: any) {
    yield put(actions.getListComplete());
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", {
        error: JSON.stringify(e),
      })
    );
  }
}

export function* getById(action) {
  yield delay(500);

  try {
    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    const response = yield request(
      `${API_URL}/v1/questionnaires-results/${action.payload.id}`,
      options
    );
    yield put(actions.setById(response));
  } catch (e: any) {
    yield put(actions.getByIdFailure());
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", {
        error: JSON.stringify(e),
      })
    );
  }
}

export function* resetRequest(action) {
  yield delay(500);

  try {
    const options = {
      method: "POST",
      headers: getDefaultHeaders(),
    };
    const response = yield request(
      `${API_URL}/v1/questionnaires-results/${action.payload.id}/reset-request`,
      options
    );
    yield put(actions.resetRequestSuccess(response));
    action.payload.callback();
  } catch (e: any) {
    yield put(actions.getByIdFailure());
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", {
        error: JSON.stringify(e),
      })
    );
  }
}

export function* useQuestionnaireResultSaga() {
  yield takeLatest(actions.getList.type, getList);
  yield takeLatest(actions.resetRequest.type, resetRequest);
  yield takeLatest(actions.getNextPage.type, getList);
  yield takeLatest(actions.getById.type, getById);
}
