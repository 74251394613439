import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  bg_color: {
    background: "#F5F8FF",
  },
  right: {
    marginLeft: "280px",
    marginTop: "70px",
    padding: "15px",
    marginBottom: "20px",
  },
  inputSectionParent: {
    display: "flex",
    alignItems: "center",
  },
  patients: {
    marginLeft: "10px",
    fontWeight: 600,
    fontSize: "16px",
    color: "#2A3333",
  },
  addColor: {
    fontWeight: 600,
    fontSize: "16px",
    color: "#012497",
  },
  textEnabled: {
    background: "#FFF",
    marginTop: "8px",
  },
  deletePatientBtn: {
    color: "#FF4839",
    borderColor: "#FF4839",
    "&:hover": {
      borderColor: "#FF4839",
    },
    fontFamily: "Cairo",
    fontWeight: 700,
    fontSize: "16px",
    width: "240px",
    height: "50px",
    borderRadius: 4,
  },

  label: {
    fontFamily: "Cairo",
    fontWeight: 400,
    fontSize: "16px",
    color: "#2A3333",
  },
  Questionnaire_heading: {
    textAlign: "center",
    margin: "20px 0",

    height: "37px",

    fontFamily: "Cairo",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "37px",
    color: "#012497",
  },
  table: {
    border: "1px solid lightgrey",
    width: "100%",
    borderRadius: "5px",
    overflowX: "auto",
  },
  question_box: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  quest1: {
    fontFamily: "Cairo",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeeight: "30px",
    color: "#3D63E6",
    borderBottom: "1px solid #3D63E6",
    paddingBottom: "3px",
  },
  quest2: {
    fontFamily: "Cairo",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeeight: "30px",
    margin: "0 10px",
  },
  quest3: {
    fontFamily: "Cairo",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeeight: "30px",
  },
  check_box_main_parent: {
    display: "flex",
  },
  question_body: {
    boxSizing: "border-box",
    width: "100%",
    height: "195px",
    borderRadius: "4px",
    marginBottom: "10px",
    padding: "20px",
    background: "#ffffff",
  },
  question_body_text_box: {
    display: "flex",
    flexDirection: "column",
  },
  question_text: {
    fontFamily: "Cairo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeeight: "24px",
  },
  question_text_area: {
    background: "#FFFFFF",
    width: "100%",
  },
  check_box_parent: {},
  check_parent: {
    display: "flex",
    flexDirection: "column",
  },
  ans_text: {
    width: "112px",
    height: "24px",
    fontFamily: "Cairo",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#012497",
    position: "relative",
    top: "8px",
    marginLeft: "28px",
  },
  check_box: {
    width: "15px",
    height: "15px",
  },
  check_text: {
    display: "inline-block",
    margin: "0 10px",
    padding: "10px 16px",
    gap: "10px",
    width: "179px",
    height: "44px",
    background: "#FFFFFF",
    border: "1px solid #C6C9CA",
    borderRadius: " 4px",
    fontFamily: "Cairo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#2A3333",
  },
  check_text1: {
    display: "inline-block",
    margin: "0 10px",
    padding: "10px 16px",
    gap: "10px",
    width: "41px",
    height: "44px",
    background: "#FFFFFF",
    border: "1px solid #C6C9CA",
    borderRadius: " 4px",
    fontFamily: "Cairo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#2A3333",
    marginLeft: "25px",
  },
  addQues: {
    fontFamily: "Cairo",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "30px",
    color: "#2A3333",
    marginRight: "10px",
  },
  tdHeader: {
    padding: "20px",
    fontFamily: "Cairo",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "2",
    color: "#2A3333",
  },
  actionTD: {
    padding: "15px",
  },
  dateTd: {
    padding: "15px 18px",
    fontFamily: "Cairo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px",
    color: "#2A3333",
  },
  trHead: {
    backgroundColor: "#F5F8FF",
  },
  tr: {
    "&:nth-child(odd)": {
      backgroundColor: "#fff",
    },
  },
  saveBtn: {
    background: "#3D63E6",
    padding: "10px 35px",
    border: "none",
    color: "#fff",
    marginBottom: "10px",
    borderRadius: "3px",
    width: "160px",
    height: "50px",
    fontFamily: "Cairo",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "30px",
    marginLeft: "20px",
  },
  cancelBtn: {
    color: "#3D63E6",
    borderColor: "#3D63E6",
    "&:hover": {
      borderColor: "#3D63E6",
    },
    fontFamily: "Cairo",
    fontWeight: 700,
    fontSize: "16px",
    width: "160px",
    height: "50px",
  },
  btnContainer1: {
    marginTop: "10px",
    display: "flex",
    justifyContent: "flex-end",
  },
  deleteModal: {
    position: "absolute",
    background: "#F5F8FF",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "0px",
    boxShadow: "0",
    zIndex: "9999",
    width: "410px",
    height: "230px",
  },
  modalContainer: {
    padding: "20px",
  },
  headerModal: {
    color: "#012497",
    fontFamily: "Cairo",
    fontWeight: "800",
    fontSize: "24px",
  },
  deleteTypo: {
    color: "#2A3333",
    fontFamily: "Cairo",
    fontWeight: "400",
    fontSize: "16px",
  },
  btnContainer: {
    display: "flex",
    alignItems: "flex-end",
  },
}));
