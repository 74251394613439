import queryString from "query-string";
import { delay, put, select, takeLatest } from "redux-saga/effects";
import {
  emailRegex,
  validateAndFormatIsraeliMobileNumber,
} from "styles/theme/utils";
import { API_URL } from "utils/constants";
import {
  TRANSLATE,
  getDefaultHeaders,
  removeBlankProperties,
  safeParseJSON,
} from "utils/helpers";
import { request } from "utils/request";
import toastService from "utils/toast";
import { UserForm } from "../types";
import {
  selectFrom,
  selectQuery,
  selectUserPermission,
  selectUserQualification,
} from "./selector";
import { actions } from "./slice";
export function* update(action) {
  yield delay(500);
  const form: UserForm = yield select(selectFrom);
  const qualificationList = yield select(selectUserQualification);
  const permissionList = yield select(selectUserPermission);
  try {
    if (form.firstName.value.trim().length == 0) {
      throw Error('{"message":"USERS.PLEASE_ENTER_FIRSTNAME"}');
    }

    if (!form.email.value.trim()) {
      throw Error('{"message":"USERS.PLEASE_ENTER_VALID_EMAIL"}');
    } else {
      if (!emailRegex.test(form.email.value)) {
        throw Error('{"message":"USERS.PLEASE_ENTER_VALID_EMAIL"}');
      }
    }

    if (!(Number(form.groupId.value) > 0)) {
      throw Error('{"message":"USERS.PLEASE_SELECT_GROUP"}');
    }

    if (form.lastName.value.trim().length == 0) {
      throw Error('{"message":"USERS.PLEASE_ENTER_LASTNAME"}');
    }

    if (form.gender.value.trim().length == 0) {
      throw Error('{"message":"USERS.PLEASE_ENTER_SEX"}');
    }
    if (form.groupId.value == "") {
      throw Error('{"message":"USERS.PLEASE_SELECT_USER_GROUP"}');
    }
    if (form.cityId.value == "") {
      throw Error('{"message":"USERS.PLEASE_SELECT_CITY"}');
    }
    if (form.dob.value == "") {
      throw Error('{"message":"USERS.PLEASE_PICK_BIRTHDATE"}');
    }
    if (form.street.value == "") {
      throw Error('{"message":"USERS.PLEASE_ENTER_STREET"}');
    }

    if (!form.mobileNumber.value?.trim().length) {
      throw Error('{"message":"USERS.PLEASE_ENTER_MOBILE_NUMBER"}');
    } else {
      if (
        !validateAndFormatIsraeliMobileNumber(form.mobileNumber.value).isValid
      ) {
        throw Error('{"message":"USERS.PLEASE_ENTER_VALID_MOBILE_NUMBER"}');
      }
    }

    let user: any = {
      user: {
        firstName: form.firstName.value,
        lastName: form.lastName.value,
        email: form.email.value,
        gender: form.gender.value,
        martialStatus: "single",
        numberOfChildren: 0,
        dateOfBirth: form.dob.value,
        cityId: form.cityId.value,
        street: form.street.value,
        mobileNumber: form.mobileNumber.value,
        userGroupId: form.groupId.value,
        area: "",
        role: {
          id: 2,
          name: "User",
        },
        status: {
          id: 1,
          name: "Active",
        },
      },
      qualification: qualificationList.items,
      permission: permissionList.items,
    };

    const options = {
      method: "PATCH",
      headers: getDefaultHeaders(),
      body: JSON.stringify(user),
    };
    yield request(`${API_URL}/v1/users/${action?.payload.id}`, options);
    yield put(actions.getList());
    toastService.success(TRANSLATE("USERS.DATA_UPDATED_SUCCESSFULLY"));
    action.payload.navigate("/users");
  } catch (e: any) {
    const error = e as Error;
    const { message } = safeParseJSON(error.message);
    yield put(actions.updateFailed(message));
    toastService.error(
      TRANSLATE(message, {
        defaultValue: "Error in User",
      })
    );
  }
}
export function* handleEnableDisable(action) {
  yield delay(500);
  try {
    const options = {
      method: "PATCH",
      headers: getDefaultHeaders(),
    };
    const res = yield request(
      `${API_URL}/v1/users/${action?.payload.row.id}/status`,
      options
    );
    yield put(actions.enableDisableSuccess({ row: res }));
    toastService.success(TRANSLATE("USERS.DATA_UPDATED_SUCCESSFULLY"));
  } catch (e: any) {
    yield put(actions.enableDisableFail(action?.payload.row.id));
    const error = e as Error;
    const { message } = safeParseJSON(error.message);
    yield put(actions.updateFailed(message));
    toastService.error(
      TRANSLATE(message, {
        defaultValue: "Error in User",
      })
    );
  }
}
export function* create(action) {
  yield delay(500);
  try {
    const form: UserForm = yield select(selectFrom);
    const qualificationList = yield select(selectUserQualification);
    const permissionList = yield select(selectUserPermission);

    if (form.firstName.value.trim().length == 0) {
      throw Error('{"message":"USERS.PLEASE_ENTER_FIRSTNAME"}');
    }
    if (form.lastName.value.trim().length == 0) {
      throw Error('{"message":"USERS.PLEASE_ENTER_LASTNAME"}');
    }

    if (!form.mobileNumber.value?.trim().length) {
      throw Error('{"message":"USERS.PLEASE_ENTER_MOBILE_NUMBER"}');
    } else {
      if (
        !validateAndFormatIsraeliMobileNumber(form.mobileNumber.value).isValid
      ) {
        throw Error('{"message":"USERS.PLEASE_ENTER_VALID_MOBILE_NUMBER"}');
      }
    }

    if (!form.email.value.trim()) {
      throw Error('{"message":"USERS.PLEASE_ENTER_VALID_EMAIL"}');
    } else {
      if (!emailRegex.test(form.email.value)) {
        throw Error('{"message":"USERS.PLEASE_ENTER_VALID_EMAIL"}');
      }
    }
    if (!(Number(form.groupId.value) > 0)) {
      throw Error('{"message":"USERS.PLEASE_SELECT_GROUP"}');
    }
    if (form.gender.value.trim().length == 0) {
      throw Error('{"message":"USERS.PLEASE_ENTER_SEX"}');
    }
    if (form.groupId.value == "") {
      throw Error('{"message":"USERS.PLEASE_SELECT_USER_GROUP"}');
    }
    if (form.cityId.value == "") {
      throw Error('{"message":"USERS.PLEASE_SELECT_CITY"}');
    }
    if (form.dob.value == "") {
      throw Error('{"message":"USERS.PLEASE_PICK_BIRTHDATE"}');
    }
    if (form.street.value == "") {
      throw Error('{"message":"USERS.PLEASE_ENTER_STREET"}');
    }
    const newTreatmentType = {
      user: {
        firstName: form.firstName.value,
        lastName: form.lastName.value,
        email: form.email.value,
        gender: form.gender.value,
        martialStatus: "single",
        numberOfChildren: 0,
        dateOfBirth: form.dob.value,
        cityId: form.cityId.value,
        street: form.street.value,
        mobileNumber: form.mobileNumber.value,
        userGroupId: form.groupId.value,
        area: "",
        role: {
          id: 2,
          name: "User",
        },
        status: {
          id: 1,
          name: "Active",
        },
      },
      qualification: qualificationList.items,
      permission: permissionList.items,
    };

    const requestOptions = {
      method: "POST",
      headers: getDefaultHeaders(),
      body: JSON.stringify(newTreatmentType),
    };
    const url = `${API_URL}/v1/users`;
    yield request(url, requestOptions);
    yield put(actions.getList());
    toastService.success(TRANSLATE("USERS.CREATED_SUCCESSFULLY"));
    action.payload.navigate("/users");
  } catch (e: any) {
    const error = e as Error;
    const { message } = safeParseJSON(error.message);
    yield put(actions.createFailed(message));
    toastService.error(
      TRANSLATE(message, {
        defaultValue: "Error in User",
      })
    );
  }
}
export function* getList(action) {
  yield delay(500);
  const query = yield select(selectQuery);
  const requestData = removeBlankProperties(query);
  const queries = queryString.stringify({
    ...requestData,
  });
  try {
    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    const response = yield request(`${API_URL}/v1/users?${queries}`, options);
    yield put(actions.getListSuccess(response));
  } catch (e: any) {
    yield put(actions.setLoading(false));
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", {
        error: JSON.stringify(e),
      })
    );
  }
}
export function* getById(action) {
  yield delay(500);

  try {
    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    const response = yield request(
      `${API_URL}/v1/users/get-by-id/${action.payload}`,
      options
    );
    yield put(actions.getGetByIdSuccess(response));
  } catch (e: any) {
    yield put(actions.setLoading(false));
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", {
        error: JSON.stringify(e),
      })
    );
  }
}
export function* getQualificationList(action) {
  yield delay(500);

  try {
    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    const response = yield request(
      `${API_URL}/v1/users/${action.payload.id}`,
      options
    );
    yield put(actions.getQualificationListSuccess(response));
  } catch (e: any) {
    yield put(actions.setLoading(e?.message));
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", {
        error: JSON.stringify(e),
      })
    );
  }
}

export function* getTreatmentTypeList(action) {
  yield delay(500);
  try {
    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    const response = yield request(
      `${API_URL}/v1/treatment?limit=200&page=1`,
      options
    );
    yield put(actions.getTreatmentListSuccess(response));
  } catch (e: any) {
    yield put(actions.setTreatmentFailure(e));
    const error = JSON.parse(e.message);
    console.error(e);
  }
}
export function* getTreatmentTypeListByBank(action) {
  yield delay(500);
  try {
    if (action.payload.bankId !== undefined) {
      const options = {
        method: "GET",
        headers: getDefaultHeaders(),
      };
      const response = yield request(
        `${API_URL}/v1/treatment/${action.payload.bankId}?limit=200&page=1`,
        options
      );
      yield put(actions.getTreatmentListSuccess(response));
    } else {
      yield put(actions.getTreatmentListSuccess({ items: [] }));
    }
  } catch (e: any) {
    yield put(actions.setTreatmentFailure(e));
    const error = JSON.parse(e.message);
    console.error(e);
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", {
        error: JSON.stringify(e),
      })
    );
  }
}

export function* getInstructor(action) {
  yield delay(500);
  if (action.payload == undefined) {
    return;
  }
  yield delay(500);

  try {
    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    const response = yield request(
      `${API_URL}/v1/users/get-instructor-by-treatment/${action.payload.treatmentId}?limit=200&page=1`,
      options
    );
    yield put(actions.getInstructorListSuccess(response));
  } catch (e: any) {
    yield put(actions.setInstructorFailure(e));
    const error = JSON.parse(e.message);
    console.error(e);
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", {
        error: JSON.stringify(e),
      })
    );
  }
}

export function* getActualInstructor(action) {
  yield delay(500);
  if (!action.payload) {
    return;
  }
  yield delay(500);

  try {
    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    const response = yield request(
      `${API_URL}/v1/users/get-instructor-by-treatment/${action.payload.treatmentId}?limit=200&page=1`,
      options
    );
    yield put(actions.getInstructorListSuccess(response));
  } catch (e: any) {
    yield put(actions.setInstructorFailure(e));
    const error = JSON.parse(e.message);
    console.error(e);
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", {
        error: JSON.stringify(e),
      })
    );
  }
}

export function* getBankDepartment(action) {
  yield delay(500);
  try {
    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    if (action.payload.id !== undefined) {
      const response = yield request(
        `${API_URL}/v1/organization/${action.payload.id}/department?limit=200&page=1`,
        options
      );
      yield put(actions.getDepartmentListSuccess(response.items));
    }
  } catch (e: any) {
    yield put(actions.setDepartmentFailure(e));
    const error = JSON.parse(e.message);
    console.error(e);
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", {
        error: JSON.stringify(e),
      })
    );
  }
}

export function* getBanks(action) {
  yield delay(500);
  try {
    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    if (
      action.payload.organizationId !== undefined &&
      action.payload.departmentId !== undefined
    ) {
      const response = yield request(
        `${API_URL}/v1/treatments-bank/${action.payload.organizationId}/${action.payload.departmentId}/banks?limit=200&page=1`,
        options
      );
      yield put(actions.getBankListSuccess(response.items));
    }
  } catch (e: any) {
    yield put(actions.setBankFailure(e));
    const error = JSON.parse(e.message);
    console.error(e);
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", {
        error: JSON.stringify(e),
      })
    );
  }
}

export function* deleteRow() {}
export function* useUsersListSaga() {
  yield takeLatest(actions.getList.type, getList);

  yield takeLatest(actions.getNextPage.type, getList);
  yield takeLatest(actions.getById.type, getById);

  yield takeLatest(actions.create.type, create);
  yield takeLatest(actions.update.type, update);
  yield takeLatest(actions.delete.type, deleteRow);
  yield takeLatest(actions.enableDisable.type, handleEnableDisable);

  yield takeLatest(actions.getTreatmentList.type, getTreatmentTypeList);
  yield takeLatest(
    actions.getTreatmentListByBank.type,
    getTreatmentTypeListByBank
  );
  yield takeLatest(actions.getInstructorList.type, getInstructor);
  yield takeLatest(actions.getactualInstructorList.type, getActualInstructor);

  yield takeLatest(actions.getQualificationList.type, getQualificationList);
  yield takeLatest(actions.getDepartmentList.type, getBankDepartment);
  yield takeLatest(actions.getBankList.type, getBanks);
}
