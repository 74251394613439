import DataTable from "app/components/DataTable";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectProgressList } from "../../redux/selector";
import { selectLoading, selectQuery } from "./redux/selector";
import { actions } from "./redux/slice";
import { useStyles } from "./styles";
//import OpenQuestionerModal from "./components/OpenQuestionerModal";

const ProgressTab = () => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const { items, meta } = useSelector(selectProgressList);

  const { q, orderField, orderDirection } = useSelector(selectQuery);

  const setPageQuery = (name: string, value: any) => {
    dispatch(actions.setQuery({ name, value }));
  };
  const { t } = useTranslation();
  const loading = useSelector(selectLoading);

  const columns = [
    {
      id: "treatment_name",
      label: t("TABLES.TREATMENT_NAME"),
      sortValue: "selector.name",
      format: (val, index) => {
        return val.treatment_name;
      },
    },
    {
      id: "sum_of_meetings_completed_count",
      label: t("TABLES.PATIENTS_COMPLETED_TRERATMENT"),
      sortValue: "selector.sum_of_meetings_completed_count",
      minWidth: 90,
      maxWidth: 100,
      format: (val, index) => {
        return val.sum_of_meetings_completed_count;
      },
    },
    {
      id: "sum_of_meetings_in_progress_count",
      label: t("TABLES.PATIENTS_IN_PROGRESS"),
      sortValue: "selector.sum_of_meetings_in_progress_count",
      minWidth: 100,
      maxWidth: 100,
      format: (val, index) => {
        return val.sum_of_meetings_in_progress_count;
      },
    },
    {
      id: "patientsWhoMissedTreatment",
      label: t("TABLES.PATIENTS_MISSED_TRERATMENT"),
      sortValue: "selector.sum_of_meetings_missed_count",
      minWidth: 100,
      maxWidth: 100,
      format: (val, index) => {
        return val.sum_of_meetings_missed_count;
      },
    },
    {
      id: "patientsWhoRegisteredToBonusTimeslot",
      label: t("TABLES.PATIENTS_REGISTERED_BONUS"),
      sortValue: "selector.sum_of_bonus_patients_count",
      whiteSpace: "preLine",
      minWidth: 80,
      maxWidth: 90,
      format: (val, index) => {
        return val.sum_of_bonus_patients_count;
      },
    },
  ];
  const handleModal = () => {
    dispatch(actions.toggalModal());
  };
  const handleViewAction = (data: any) => {
    dispatch(actions.setOpenQuestionnaire(data));
    handleModal();
  };
  return (
    <>
      <div className={styles.bg_color}>
        <div className={styles.tableDiv}>
          <DataTable
            loading={loading}
            orderDirection={orderDirection}
            orderField={orderField}
            setQuery={setPageQuery}
            rows={items}
            columns={columns}
            onRowDoubleClick={handleViewAction}
            page="progressTabPage"
            isPointerCursor={true}
            scrollClass={styles.dataTableDiv}
          />
        </div>
      </div>
    </>
  );
};
export default ProgressTab;
