import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  label: {
    fontFamily: "Cairo",
    fontWeight: 400,
    fontSize: "16px",
    color: "#2A3333",
    marginLeft: "10px",
  },

  messageModal: {
    position: "absolute",
    background: "#FFFFFF",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "0px",
    boxShadow: "0",
    zIndex: "9999",
    backgroundColor: "#F5F8FF",
    outline: "none",
    width: "80%", // Adjust the width as needed
    maxWidth: "850px", // Set a maximum width if necessary
  },
  modalContainer: {
    padding: "15px 15px 15px 15px",
  },
  modalHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  treatment_heading: {
    textAlign: "center",
    fontFamily: "Cairo",
    fontWeight: 800,
    fontSize: "18px",
    color: "#012397",
    margin: "0px",
  },
  treatment_name: {
    textAlign: "center",
    fontFamily: "Cairo",
    fontWeight: 800,
    fontSize: "18px",
    color: "#000",
    margin: "0px",
  },
  treatment_sub: {
    textAlign: "center",
    fontFamily: "Cairo",
    fontWeight: 500,
    fontSize: "18px",
    color: "#000",
    margin: "0px",
    marginBottom: "10px",
  },
}));
