import { Close } from "@mui/icons-material";
import { Box, Grid, IconButton } from "@mui/material";
import axios from "axios";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { API_URL } from "utils/constants";
import { FileType } from "utils/enum";
import {
  TrimFileName,
  ValidateFileType,
  formatBytes,
  getDefaultHeaders,
} from "utils/helpers";
import toastService from "utils/toast";
import uploadBtn from "./assets/Upload.png";
import audioImg from "./assets/audio.png";
import pdfImg from "./assets/pdf.svg";
import videoImg from "./assets/video.png";
import { useStyles } from "./styles";
interface Props {
  file: any;
  fileClose: () => void;
  title: string;
  type: string;
  disabled?: boolean;
  fileType: FileType;
  uploadComplete: (data) => void;
  isUploading: (data) => void;
}
const FileUpload = ({
  file,
  fileClose,
  title,
  fileType,
  uploadComplete,
  type,
  disabled = false,
  isUploading,
}: Props) => {
  const styles = useStyles();
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState("");
  const fileRef = useRef<HTMLInputElement | null>(null);
  const [, setState] = useState({});
  const { t } = useTranslation();
  const handleFileChange = async (event) => {
    setUploadStatus("");
    if (event.target.files.length == 0) {
      return;
    }
    let file = event.target.files[0];
    if (!ValidateFileType(file.name.split(".").pop(), fileType)) {
      toastService.error(t("TREATMENT_BANKS.INVALID_FILE"));
      return;
    }
    isUploading(true);
    setSelectedFile(file);

    try {
      // Step 1: Request a signed URL from the server
      const response = await axios.post(
        `${API_URL}/v1/files/presigned-url`,
        {
          fileName: file.name,
          contentType: file.type,
        },
        { headers: getDefaultHeaders() }
      );

      const { signedUrl, fileNameWithPrefix, url } = response.data;

      // Step 2: Upload the file to the signed URL using PUT method
      const uploadConfig = {
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(progress);
        },
      };

      await axios.put(signedUrl, file, uploadConfig);

      setUploadStatus("Upload completed!");

      uploadComplete({
        fileName: file.name,
        fileSize: file.size,
        fileType: file.type,
        fileNameWithPrefix: fileNameWithPrefix,
        keyToUpdateOnSuccess: type,
        url,
      });
      // You can now save the public URL or perform any other necessary actions with the video
    } catch (error) {
      console.error("Error:", error);
      setUploadStatus("Upload failed.");
    } finally {
      isUploading(false);
    }
  };

  function pdfClick() {
    if (fileRef.current) fileRef.current.click();
  }
  function renderImageIcon() {
    switch (fileType) {
      case FileType.AUDIO:
        return audioImg;
      case FileType.VIDEO:
        return videoImg;
      case FileType.PDF:
        return pdfImg;
      case FileType.IMAGE:
        return file.url;
      default:
        return audioImg;
    }
  }
  return (
    <>
      {!file.name && uploadStatus !== "Upload completed!" ? (
        <Grid
          onClick={() => pdfClick()}
          className={
            fileType === FileType.IMAGE
              ? styles.uploadImageBox
              : styles.uploadBox
          }
          item
          xs={10}
        >
          {uploadProgress > 0 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <progress value={uploadProgress} max="100" />
              <span>{uploadProgress}%</span>
            </div>
          ) : (
            <>
              <input
                disabled={disabled}
                type="file"
                ref={fileRef}
                style={{ display: "none" }}
                onChange={(e) => handleFileChange(e)}
              />
              <img
                width="30px"
                style={disabled ? { filter: "grayscale(100)" } : {}}
                src={uploadBtn}
                alt=""
              ></img>
              <label className={styles.uploadLabel}>
                {t("TREATMENT_BANKS.UPLOAD")} {title}
              </label>
            </>
          )}
        </Grid>
      ) : (
        <Grid
          className={
            fileType == FileType.IMAGE ? styles.previewImage : styles.preview
          }
          item
          xs={10}
        >
          {fileType !== FileType.IMAGE && file?.name ? (
            <>
              <img
                src={renderImageIcon()}
                style={{ marginTop: "-23px", maxWidth: 38, maxHeight: 38 }}
                alt=""
              />
              <div
                style={{
                  display: "inline-block",
                  marginLeft: "5px",
                  width: "120px",
                }}
              >
                <a
                  href={file.url ?? "#"}
                  target="_blank"
                  style={{ fontSize: "15px" }}
                  rel="noreferrer"
                >
                  {TrimFileName(file.name)}
                </a>
                <span
                  style={{
                    display: "block",
                    fontSize: "13px",
                    color: "grey",
                    marginTop: "-5px",
                  }}
                >
                  {formatBytes(file.size)}
                </span>
              </div>
            </>
          ) : (
            <Box style={{ maxWidth: 138, maxHeight: 138 }}>
              <img
                src={renderImageIcon()}
                alt=""
                width={"100%"}
                height={"100%"}
                style={{
                  borderRadius: "38%",
                  width: 120,
                  height: 120,
                }}
              />
            </Box>
          )}
          <IconButton
            onClick={(e) => {
              setUploadStatus("");
              setUploadProgress(0);
              setSelectedFile(null);
              fileClose();
              setState({});
            }}
          >
            <Close />
          </IconButton>
        </Grid>
      )}
    </>
  );
};

export default FileUpload;
