import { Add, Delete } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Button, Fab, Grid, MenuItem, Select, TextField } from "@mui/material";
import BreadCrumb from "app/components/BreadCrumb";
import { ActionButtons } from "app/components/Button/ActionButtons";
import ConfirmDialog from "app/components/ConfirmDialog";
import { CustomDrawer } from "app/components/Drawer";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { selectFeaturePermissions } from "store/global/authPermission/selector";
import { PermissionFeature } from "store/global/authPermission/types";
import { translatedBreadCrumb } from "styles/theme/utils";
import toastService from "utils/toast";
import Topbar from "../../../Dashboard/components/topbar";
import { validate } from "../../multi-questioner.validator";
import { useQuestionnaireSaga } from "../../redux/saga";
import {
  selectCurrentQuestion,
  selectCurrentQuestionIndex,
  selectForm,
  selectLoading,
  selectQuestions,
} from "../../redux/selector";
import { actions, reducer, sliceKey } from "../../redux/slice";
import AnswersForm from "./components/AnswerForm";
import GradeForm from "./components/GradeForm";
import { useStyles } from "./styles";

const AddMultiQuestionnaires = () => {
  useInjectReducer({ key: sliceKey, reducer });
  useInjectSaga({ key: sliceKey, saga: useQuestionnaireSaga });
  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useLocation();
  const mode = params.search.split("=")[1];
  let { id } = useParams();
  const loading = useSelector(selectLoading);

  const form = useSelector(selectForm);
  const questionnaire = form.questionnaire;
  const currentQuestionIndex = useSelector(selectCurrentQuestionIndex);

  const currentQuestion = useSelector(selectCurrentQuestion);
  const questions = useSelector(selectQuestions);

  const { t, i18n } = useTranslation();
  const handleDeleteClose = () => {
    setOpenDelete(false);
  };

  const featurePermissions = useSelector(selectFeaturePermissions);
  const { hasFullAccess, hasReadAccess, hasNoAccess } =
    featurePermissions[PermissionFeature.QUESTIONNAIRES];

  const handleDeleteSave = () => {
    setOpenDelete(false);
    dispatch(
      actions.delete({
        id: id,
        navigate,
      })
    );
  };

  useEffect(() => {
    if (mode === "add") {
      dispatch(actions.resetQuestionnaire());
      dispatch(actions.addQuestion({ type: "open" }));
    } else if (Number(id) > 0) {
      dispatch(actions.getDataById({ id: id }));
    }
  }, [id]);

  const [openDelete, setOpenDelete] = useState(false);

  const handleDeleteOpen = () => {
    setOpenDelete(true);
  };

  const handleSave = () => {
    const errors = validate(form);
    if (errors.length) {
      dispatch(actions.setFormErrors({ errors }));
      return toastService.error(
        t("QUESTIONNAIRES.FORM.ERRORS.FIX_ERRORS_FIRST")
      );
    } else {
      dispatch(actions.clearFormErrors());
    }

    if (mode === "add") {
      dispatch(
        actions.createQuestionnaire({
          navigate: navigate,
        })
      );
    } else {
      dispatch(
        actions.updateQuestionnaire({
          id: id,
          navigate: navigate,
        })
      );
    }
  };

  return (
    <>
      <div className={styles.bg_color}>
        <CustomDrawer />
        <Grid item xs={12}>
          <Topbar />
          <div className={styles.right}>
            <Grid container>
              <Grid item xs={9.5} direction="column">
                <BreadCrumb
                  subTitle={t(`QUESTIONNAIRES.${translatedBreadCrumb(mode)}`)}
                  onBackClick={() => navigate("/questionnaires")}
                  title={t("QUESTIONNAIRES.QUESTIONNAIRES")}
                />
              </Grid>
              <Grid item xs={2.5} direction="column" marginTop="40px">
                {mode !== "add" && (
                  <Button
                    className={styles.deletePatientBtn}
                    variant="outlined"
                    disabled={mode === "view" || loading}
                    onClick={handleDeleteOpen}
                  >
                    <Delete
                      sx={{
                        color: mode === "view" ? "#C6C9CA" : "#FF4839",
                        marginRight: "10px",
                      }}
                    />{" "}
                    {t("QUESTIONNAIRES.DELETE_QUESTIONNAIRE")}
                  </Button>
                )}
              </Grid>
            </Grid>
            <Grid
              container
              md={12}
              justifyContent={"space-between"}
              sx={{
                margin: "0px 0px 10px 0px",
              }}
            >
              <Grid item xs={8}>
                <Grid
                  container
                  spacing={2}
                  xs={12}
                  flexDirection={"row"}
                  display={"flex"}
                >
                  <Grid item xs={3}>
                    <span className={styles.label}>
                      {t("QUESTIONNAIRES.QUESTIONNAIRE_NAME")}
                    </span>
                    <TextField
                      margin="none"
                      variant="outlined"
                      fullWidth
                      required
                      id="questionName"
                      placeholder={t("QUESTIONNAIRES.ENTER_QUESTIONNAIRE")}
                      name="questionName"
                      autoComplete={"off"}
                      disabled={mode === "view" || loading}
                      value={questionnaire.name}
                      error={!!questionnaire?.errors?.name}
                      helperText={
                        !!questionnaire?.errors?.name
                          ? questionnaire?.errors?.name
                          : ""
                      }
                      FormHelperTextProps={{
                        style: {
                          textAlign: i18n.dir() === "ltr" ? "left" : "right",
                        },
                      }}
                      onChange={(e) => {
                        dispatch(
                          actions.updateFormErrors({
                            name: "questionnaire.errors.name",
                            error: "",
                          })
                        );
                        dispatch(
                          actions.setFormValue({
                            name: "questionnaire.name",
                            value: e.target.value,
                          })
                        );
                      }}
                      className={styles.textEnabled}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <label className={styles.label}>
                      {t("QUESTIONNAIRES.QUESTION_NUMBER")} (
                      {t("QUESTIONNAIRES.OUT_OF", {
                        current: currentQuestion.questionNumber,
                        total: +questions.length,
                      })}
                      )
                    </label>
                    <Select
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      required
                      disabled={loading}
                      autoComplete={"off"}
                      value={currentQuestionIndex}
                      className={styles.textEnabled}
                      onChange={(e) => {
                        dispatch(
                          actions.setCurrentQuestionIndex({
                            index: Number(e.target.value),
                          })
                        );
                      }}
                      IconComponent={KeyboardArrowDownIcon}
                    >
                      {questions.map((value, index) => {
                        return (
                          <MenuItem key={index} value={`${index}`}>
                            {value.questionNumber}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={2.5}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  paddingTop: "10px",
                }}
              >
                <span className={styles.addQues}>
                  {t("QUESTIONNAIRES.ADD_QUESTION")}
                </span>
                <Fab
                  size="medium"
                  color="secondary"
                  aria-label="add"
                  disabled={mode === "view" || loading}
                  sx={{ marginTop: 2, marginBottom: 2, zIndex: 0 }}
                  onClick={() => {
                    if (questions.length < 25) {
                      dispatch(actions.addQuestion({ type: "mcq" }));
                    } else {
                      return toastService.error(
                        t("QUESTIONNAIRES.FORM.ERRORS.MAX_QUESTIONS_REACHED", {
                          value: 25,
                        })
                      );
                    }
                  }}
                >
                  <Add />
                </Fab>
              </Grid>
            </Grid>

            <Grid>
              <label className={styles.question_body_text_box}>
                <span className={styles.question_text}>
                  {t("QUESTIONNAIRES.QUESTION_BODY")}
                </span>
                <TextField
                  rows={5}
                  multiline
                  type="text"
                  disabled={mode === "view" || loading}
                  value={currentQuestion.question}
                  onChange={(e) => {
                    dispatch(
                      actions.setQuestionData({
                        value: e.target.value,
                      })
                    );
                  }}
                  placeholder={t("QUESTIONNAIRES.ENTER_QUESTION")}
                  className={styles.question_text_area}
                  error={!!currentQuestion?.errors?.question}
                  helperText={
                    !!currentQuestion?.errors?.question
                      ? currentQuestion?.errors?.question
                      : ""
                  }
                  FormHelperTextProps={{
                    style: {
                      textAlign: i18n.dir() === "ltr" ? "left" : "right",
                    },
                  }}
                />
              </label>
              <AnswersForm mode={mode} />
            </Grid>
            <GradeForm mode={mode} />
            <ConfirmDialog
              title={t("QUESTIONNAIRES.DELETE_QUESTIONNAIRE")}
              description={t("QUESTIONNAIRES.CONFIRM_DELETE_QUESTIONNAIRE")}
              open={openDelete}
              onCancel={handleDeleteClose}
              onClose={handleDeleteClose}
              onConfirm={handleDeleteSave}
            />
            <div className={styles.btnContainer1}>
              {mode === "view" ? (
                hasFullAccess && (
                  <Grid>
                    <Button
                      className={styles.saveBtn}
                      variant="contained"
                      disabled={loading}
                      onClick={() =>
                        navigate(`/questionnaires/multi/${id}?mode=edit`)
                      }
                    >
                      {t("BUTTONS.EDIT")}
                    </Button>
                  </Grid>
                )
              ) : (
                <ActionButtons
                  loading={loading}
                  onCancel={() => navigate("/questionnaires")}
                  onSave={handleSave}
                  disabled={loading}
                />
              )}
            </div>
          </div>
        </Grid>
      </div>
    </>
  );
};

export default AddMultiQuestionnaires;
