import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { MenuItem, Select, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { ALL_RELIGIONS } from "utils/constants";
import { useStyles } from "./styles";

interface TProps {
  value: string;
  disabled?: boolean;
  onChange(e): void;
  label: string;
}
const ReligionField = (props: TProps) => {
  const styles = useStyles();
  const { t, i18n } = useTranslation();

  return (
    <>
      <Typography noWrap className={styles.label}>
        {props.label}
      </Typography>
      <Select
        disabled={props.disabled}
        margin="dense"
        fullWidth
        variant="outlined"
        required
        autoComplete={"off"}
        className={styles.textEnabledSelect}
        IconComponent={KeyboardArrowDownIcon}
        value={props.value}
        name={"religion"}
        MenuProps={{
          dir: i18n.dir(),
        }}
        onChange={props.onChange}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return (
              <p style={{ color: "#A4A9AB", margin: 0 }}>
                {t("PATIENTS.OPTIONS.CHOOSE_RELIGION")}
              </p>
            );
          }
          return t(`PATIENTS.OPTIONS.${selected?.toUpperCase()}`);
        }}
      >
        {ALL_RELIGIONS.map((r) => {
          const value = r?.split(".")[r?.split(".")?.length - 1]?.toLowerCase();
          return (
            <MenuItem key={r} value={value}>
              {t(r)}
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};
export default ReligionField;
