import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles(() => ({
  modal: {
    position: "absolute",
    background: "#FFFFFF",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "0px",
    boxShadow: "0",
    zIndex: "9999",
    width: "1150px",
    maxHeight: "600px",
    overflowY: "auto",
  },
  modalContainer: {
    padding: "20px 40px 40px 40px",
  },
  headerModal: {
    color: "#012497",
    fontFamily: "Cairo",
    fontWeight: "800",
    fontSize: "24px",
  },
  table: {
    marginTop: "20px",
    width: "100%",
    border: "1px solid lightgrey",
    borderRadius: "5px",
    overflowX: "auto",
  },
  trHead: {
    backgroundColor: "#F5F8FF",
  },
  tr: {
    "&:nth-child(odd)": {
      backgroundColor: "#fff",
    },
  },
  tdHeader: {
    padding: "20px",
    fontFamily: "Cairo",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "2",
    color: "#2A3333",
  },
  actionTD: {
    padding: "15px",
  },
  dateTd: {
    padding: "15px 18px",
    fontFamily: "Cairo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px",
    color: "#2A3333",
  },
  btnContainer: {
    display: "flex",
    alignItems: "center",
  },
  cancelBtn: {
    color: "#3D63E6",
    borderColor: "#3D63E6",
    "&:hover": {
      borderColor: "#3D63E6",
    },
    fontFamily: "Cairo",
    fontWeight: 700,
    fontSize: "16px",
    width: "160px",
    height: "50px",
  },
  bodyContainer: {
    padding: "30px 0px",
  },
  labelHead: {
    fontFamily: "Cairo",
    fontWeight: 700,
    fontSize: "16px",
    color: "#2A3333",
    padding: "10px 20px",
    background: "#F5F8FF",
  },
  labelItem: {
    fontFamily: "Cairo",
    fontWeight: 400,
    fontSize: "16px",
    padding: "0px 20px",
  },
  labelContent: {
    fontFamily: "Cairo",
    fontWeight: 300,
    fontSize: "16px",
  },
  textEnabled: {
    background: "#FFF",
    marginTop: "6px",
  },
  box: {
    background: "#FFF",
    marginTop: "6px",
    border: " 1px solid lightgrey",
    height: 85,
    padding: "6px",
    whiteSpace: "pre",
    overflow: "auto",
  },
}));
