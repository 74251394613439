import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Box,
  CircularProgress,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectDepartmentOptions } from "store/global/fields/selector";
import { actions } from "store/global/fields/slice";
import { useStyles } from "../styles";
interface TProps {
  value: number;
  orgId: number;
  xs?: number;
  changeValue: (value: number) => void;
  disabled?: boolean;
  showAll?: boolean;
  error?: string;
  noReset?: boolean;
}
const DepartmentFiled = (props: TProps) => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const List = useSelector(selectDepartmentOptions);

  const { t } = useTranslation();
  useEffect(() => {
    if (props.showAll) {
      if (!props?.noReset) {
        props.changeValue(0);
      }
      dispatch(actions.getDepartmentList({ id: props.orgId }));
      return;
    }
    if (props.orgId > 0 && props.showAll) {
      if (!props?.noReset) {
        props.changeValue(0);
      }
      dispatch(actions.getDepartmentList({ id: props.orgId }));
      return;
    }

    if (props.orgId > 0 && !props.showAll) {
      dispatch(actions.getDepartmentList({ id: props.orgId }));
      return;
    }

    dispatch(actions.getDepartmentListSuccess([]));

    return () => {};
  }, [props.orgId, props.showAll, props?.noReset]);

  const handleChange = (event) => {
    props.changeValue(event.target.value);
  };

  const loading = List.loading;

  const hasError = !!props?.error;

  const direction = useTheme().direction;
  return (
    <Grid item xs={props.xs ? props.xs : 2}>
      <label className={styles.label}>{t("TIMESLOT.DEPARTMENT_NAME")}</label>
      <Select
        margin="dense"
        disabled={props.disabled}
        fullWidth
        variant="outlined"
        required
        autoComplete={"off"}
        value={props?.value?.toString()}
        defaultValue={"0"}
        className={styles.textEnabled}
        onClick={() => setDropDownOpen(props.disabled ? false : !dropDownOpen)}
        open={dropDownOpen}
        onChange={handleChange}
        IconComponent={KeyboardArrowDownIcon}
        error={hasError}
        endAdornment={
          dropDownOpen && loading ? (
            <CircularProgress className={styles.loader} />
          ) : null
        }
      >
        {props.showAll && (
          <MenuItem
            sx={{
              direction,
            }}
            value={"0"}
          >
            {t("COMMON.ALL")}
          </MenuItem>
        )}
        {List.items.map((item) => (
          <MenuItem
            key={item.id}
            onClick={() => setDropDownOpen(!dropDownOpen)}
            value={item.id}
            sx={{
              direction,
            }}
          >
            {item.name}
          </MenuItem>
        ))}
      </Select>
      <Box
        sx={{
          display: "flex",
          direction,
        }}
      >
        {hasError && <FormHelperText error>{props?.error}</FormHelperText>}
      </Box>
    </Grid>
  );
};
export default DepartmentFiled;
