import queryString from "query-string";
import { delay, put, select, takeLatest } from "redux-saga/effects";
import { API_URL } from "utils/constants";
import { removeBlankProperties } from "utils/helpers";
import { request } from "utils/request";

import { selectImportLogs, selectImportsList } from "./selector";
import { actions } from "./slice";
import { ImportsList, LogList } from "./types";

export function* getImportsList(action) {
  yield delay(500);
  try {
    const options = {
      method: "GET",
    };
    const { query }: ImportsList = yield select(selectImportsList);
    const queryProperties = removeBlankProperties(query);
    const queries = queryString.stringify(queryProperties);
    const response: ImportsList | any = yield request(
      `${API_URL}/v1/imports?${queries}`,
      options
    );

    if (response && response.items) {
      yield put(actions.getListSuccess(response));
    }
  } catch (e) {}
}

export function* getImportLogsById(action) {
  yield delay(500);
  try {
    const options = {
      method: "GET",
    };
    const { query }: LogList = yield select(selectImportLogs);
    const queryProperties = removeBlankProperties(query);
    const queries = queryString.stringify(queryProperties);
    const response: LogList = yield request(
      `${API_URL}/v1/imports/${action.payload.id}/logs?${queries && queries}`,
      options
    );
    if (response && response.items) {
      yield put(actions.getImportLogByIdSuccess(response));
    }
  } catch (e) {}
}

export function* processImport(action) {
  yield delay(500);
  try {
    const options = {
      method: "POST",
    };
    const response: any = yield request(
      `${API_URL}/v1/imports/${action.payload}/process`,
      options
    );
    if (response === true) {
      yield put(actions.processImportSuccess(response));
    }
  } catch (e) {
    yield put(actions.processImportFailed(e));
  }
}

export function* useImportsSaga() {
  yield takeLatest(actions.getList.type, getImportsList);
  yield takeLatest(actions.getNextPageItems, getImportsList);
  yield takeLatest(actions.getImportLogById.type, getImportLogsById);
  yield takeLatest(actions.processImport.type, processImport);
}
