import { CalendarMonth } from "@mui/icons-material";
import {
  Button,
  Card,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import toastService from "utils/toast";
import { assignTineWithDate } from "../helper";
import { useStyles } from "../styles";
import { TimeSlotMode } from "../types";

interface TProps {
  open: boolean;
  closeRequest: () => void;
  startTime: any;
  endTime: any;
  addItems: (start_time: any, end_time: any) => void;
  mode: TimeSlotMode;
  sessionDuration: number;
}

const EditTimeSlotModal = (props: TProps) => {
  const styles = useStyles();
  const [assignDate, setAssignDate] = useState(moment().toISOString());
  const [startTime, setStartTime] = useState(moment().toISOString());
  const [endTime, setendTime] = useState(moment().toISOString());
  useEffect(() => {
    setAssignDate(props.startTime);
    return () => {};
  }, [props.startTime]);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    setStartTime(props.startTime);
    return () => {};
  }, [props.startTime]);

  useEffect(() => {
    setendTime(props.endTime);
    return () => {};
  }, [props.endTime]);
  //const currentTime = DateTime.local().plus({ hours: 4 }).endOf("hour");
  const currentTime = DateTime.local().plus({ minutes: 20 });

  const handleSave = () => {
    const assignedStartTime = assignTineWithDate(assignDate, startTime);
    const assignedEndTime = assignTineWithDate(assignDate, endTime);
    const assignedStartDateObject = DateTime.fromISO(assignedStartTime);
    if (assignedStartDateObject < currentTime) {
      toastService.error(
        `${assignedStartDateObject.toFormat(
          "yyyy LLL dd hh:mm"
        )} start time must be greater than ${currentTime.toFormat(
          "yyyy LLL dd hh:mm"
        )}`
      );
      return;
    }
    props.addItems(assignedStartTime, assignedEndTime);
  };
  const assignedStartTime = assignTineWithDate(assignDate, startTime);
  return (
    <Modal
      open={props.open}
      onClose={props.closeRequest}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card
        className={styles.regModal}
        style={{
          direction: i18n.dir(),
        }}
      >
        <div className={styles.modalContainer}>
          <Grid>
            <Typography align="center" className={styles.headerModal}>
              {t("TIMESLOT.EDIT_TIME_SLOT")}
            </Typography>
          </Grid>

          <Grid
            container
            spacing={0}
            md={12}
            sx={{
              padding: "5px 0px 10px 0px",
              justifyContent: "space-between",
            }}
          >
            {
              <Grid item xs={2.5}>
                <label className={styles.label}>
                  {t("TIMESLOT.ASSIGNED_DATE")}
                </label>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <DatePicker
                    disabled={props.mode !== TimeSlotMode.MULTI}
                    views={["day", "month", "year"]}
                    value={assignDate}
                    minDate={currentTime}
                    onChange={(newValue: any) => {
                      let date = DateTime.fromISO(newValue);
                      if (date.isValid) {
                        setAssignDate(date.toJSDate().toISOString() || "");
                      }
                    }}
                    components={{
                      OpenPickerIcon: CalendarMonth,
                    }}
                    inputFormat="dd/MM/yyyy"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        margin="dense"
                        helperText={null}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "__/__/____",
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            }

            <Grid item xs={3.5}>
              <label className={styles.label}>{t("TIMESLOT.START_TIME")}</label>
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <TimePicker
                  label="Start Time"
                  value={startTime}
                  minTime={
                    DateTime.fromISO(assignDate) > currentTime
                      ? null
                      : currentTime
                  }
                  ampm={false}
                  onChange={(newValue: any) => {
                    let date = DateTime.fromISO(newValue);
                    if (date.isValid) {
                      const assignedStartTime = assignTineWithDate(
                        assignDate,
                        date.toJSDate().toISOString()
                      );
                      setStartTime(assignedStartTime || "");
                      setendTime(
                        DateTime.fromISO(assignedStartTime)
                          .plus({ minutes: props.sessionDuration })

                          .toJSDate()
                          .toISOString() || ""
                      );
                    }
                  }}
                  renderInput={(params) => (
                    <TextField fullWidth margin="dense" {...params} />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={3.5}>
              <label className={styles.label}>{t("TIMESLOT.END_TIME")}</label>
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <TimePicker
                  disabled={true}
                  label="End Time"
                  value={endTime}
                  ampm={false}
                  onChange={(newValue) => {}}
                  renderInput={(params) => (
                    <TextField fullWidth margin="dense" {...params} />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>

          <div>
            <Grid
              container
              spacing={3}
              sx={{
                padding: "20px 0px 0px 0px",
                justifyContent: "space-between",
              }}
            >
              <Grid
                className={styles.btnContainer}
                container
                item
                xs={6}
                direction="column"
              >
                <Button
                  className={styles.cancelBtn}
                  variant="outlined"
                  onClick={() => props.closeRequest()}
                >
                  {t("BUTTONS.CANCEL")}
                </Button>
              </Grid>
              <Grid container item xs={6} direction="column">
                <Button
                  className={styles.saveBtn}
                  onClick={handleSave}
                  variant="contained"
                >
                  {t("BUTTONS.SAVE")}
                </Button>
              </Grid>
              <Grid
                container
                color={"#ccc"}
                justifyContent={"center"}
                xs={12}
                direction="row"
              >
                <Typography>
                  {"Can setup session after : "}
                  {currentTime.toFormat("HH:mm LLL dd")}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </div>
      </Card>
    </Modal>
  );
};

export default EditTimeSlotModal;
