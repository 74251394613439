import AndroidIcon from "@mui/icons-material/Android";
import AppleIcon from "@mui/icons-material/Apple";
import {
  Button,
  Container,
  Grid,
  Icon,
  Paper,
  Typography,
} from "@mui/material";
import logo from "app/assets/inHealLogo.png";
import React from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";

const AppDownloadPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <img src={logo} alt="App Logo" className={classes.logo} />

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}>
            <Icon
              className={`${classes.icon} ${classes.button}`}
              component={AppleIcon}
              color="primary"
            />
            <Typography variant="h5" gutterBottom>
              {t("APP_DOWNLOAD.downloadForIOS")}
            </Typography>
            <Typography variant="body1" paragraph>
              {t("APP_DOWNLOAD.iosDescription")}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              href="https://apps.apple.com/app/inheal/id6449166436"
            >
              {t("APP_DOWNLOAD.downloadOnAppStore")}
            </Button>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}>
            <Icon
              className={`${classes.icon} ${classes.button}`}
              component={AndroidIcon}
              color="primary"
            />
            <Typography variant="h5" gutterBottom>
              {t("APP_DOWNLOAD.downloadForAndroid")}
            </Typography>
            <Typography variant="body1" paragraph>
              {t("APP_DOWNLOAD.androidDescription")}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              href="https://play.google.com/store/apps/details?id=com.inheal.medicalcare.app"
            >
              {t("APP_DOWNLOAD.getItOnGooglePlay")}
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AppDownloadPage;
