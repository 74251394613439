import { FormElement } from "app/containers/types";

export interface ResponseError {
  message: string;
}
export type RequestError = ResponseError;

export interface DailyActivityTypes {
  items: Item[];
  meta: Meta;
  links: Links;
}

export interface Item {
  id: number;
  activationDayNumber: number;
  name: string;
  description: string;
  scheduledAt: string;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  organizationId: any;
  resources: Resource[];
  isLoading?: boolean;
}

export interface Resource {
  id?: number;
  dailyActivityId?: number;
  resourceSize?: number;
  resourceName?: string;
  resourceType: "pdf" | "audio" | "video";
  resourcePath?: string;
  isActive?: boolean;
  createdAt?: string;
  updatedAt?: string;
  url?: string;
  isDeleted?: boolean;
}

export interface Meta {
  totalItems: number;
  itemCount: number;
  itemsPerPage: number;
  totalPages: number;
  currentPage: number;
}

export interface Links {
  first: string;
  previous: string;
  next: string;
  last: string;
}

export type PresignedResponse = {
  file: string;
  fileNameWithPrefix: string;
  signedUrl: string;
  url: string;
};
export type UploadResponse = {
  status: string;
  file: string;
};

export type Activity = {
  id?: number;
  activationDayNumber: number;
  organizationId: number;
  name: string;
  description: string;
  scheduledAt: string;
  isActive: boolean;
  createdAt?: string;
  updatedAt?: string;
  resources: Resource[];
};

export type Form = {
  isLoading?: boolean;
  id?: number;
  organizationId: FormElement;
  activationDayNumber: FormElement;
  name: FormElement;
  description: FormElement;
  scheduledAt: FormElement;
  isActive?: boolean;
  createdAt?: string;
  updatedAt?: string;
  resources: Resource[];
  resourceError?: FormElement;
};

export type dailyActivityState = {
  dailyActivityId?: number;
  query: {
    limit: number;
    page: number;
    organizationId: number;
    q: string;
    orderField: string;
    orderDirection: string;
  };
  form: Form;
  list: DailyActivityTypes;
  loading: boolean;
  error: string;
};

export const DailyActivityState: dailyActivityState = {
  dailyActivityId: undefined,
  query: {
    organizationId: 0,
    limit: 20,
    page: 1,
    q: "",
    orderField: "",
    orderDirection: "",
  },
  list: {
    items: [],
    meta: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 100,
      totalPages: 1,
      currentPage: 1,
    },
    links: {
      first: "",
      previous: "",
      next: "",
      last: "",
    },
  },
  loading: false,
  form: {
    id: undefined,
    organizationId: { value: "" },
    name: { value: "" },
    description: { value: "" },
    scheduledAt: { value: null },
    activationDayNumber: { value: "" },
    resources: [
      {
        resourceSize: undefined,
        resourceName: undefined,
        resourceType: "pdf",
        resourcePath: undefined,
      },
      {
        resourceSize: undefined,
        resourceName: undefined,
        resourceType: "audio",
        resourcePath: undefined,
      },
      {
        resourceSize: undefined,
        resourceName: undefined,
        resourceType: "video",
        resourcePath: undefined,
      },
    ],
  },
  error: "",
};
export const ResourcesTypes = {
  video: "video/mp4",
  audio: "audio/mpeg",
  pdf: "application/pdf",
};

export type UploadedResource = {
  resourcePath?: string;
  resourceName?: string;
  resourceSize?: number;
  resourceType: "pdf" | "audio" | "video";
  isDeleted?: boolean;
  id?: number;
};
export type ContainerState = dailyActivityState;
