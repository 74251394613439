import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {
  Button,
  CircularProgress,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";

import { CalenderListItemType } from "app/containers/TimeSlots/components/CalenderView/types";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";

import { useStyles } from "./styles";

///Don't sort
import FullCalendar, { formatDate } from "@fullcalendar/react";
///Don't sort
import PlugIndayGrid from "@fullcalendar/daygrid";
import PluginInteraction from "@fullcalendar/interaction";
import PluginTimeGrid from "@fullcalendar/timegrid";
import { actions } from "app/containers/TimeSlots/components/ListView/redux/slice";
import { useDispatch } from "react-redux";
import { getFormattedDateTime } from "utils/helpers";

type Props = {
  direction?: any;
  data: CalenderListItemType[];
  dateChange: (date) => void;
  loading?: boolean;
};

function Calender(props: Props) {
  const dispatch = useDispatch();
  const styles = useStyles();
  const calanderRef: any = useRef();
  const [event, setEvent]: any = useState({
    modal: false,
    calendarTitle: "",
    startDate: "",
    endDate: "",
    description: "",
    action: "add",
    id: 0,
    events: [],
    eventInfo: undefined,
    updateId: "",
    checked: false,
    error: "",
  });
  const [title, setTitle] = useState("");
  const clickable = true;

  const handlePickerClick = () => {
    // setOpen((isOpen) => !isOpen);
    // setAnchorEl(event.currentTarget);
  };

  const handleDateClick = () => {};

  const eventClick = (arg) => {
    if (!clickable) {
      return;
    }
    let title = arg.event.title;
    let start = getFormattedDateTime(
      arg.event.extendedProps?.object.start_time,
      "hh:mm"
    );
    let end = getFormattedDateTime(
      arg.event.extendedProps?.object.end_time,
      "hh:mm"
    );
    if (end === "Invalid date") {
      end = start;
    }
    let checked;
    if (arg.event.backgroundColor === "#a70707") {
      checked = true;
    } else {
      checked = false;
    }
    let description = arg.event.extendedProps.description;
    let id = arg.event.id;
    const anchorEl = arg.el.parentElement.parentElement;
    setEvent({
      action: "edit",
      modal: true,
      calendarTitle: title,
      startDate: start,
      endDate: end !== "Invalid date" ? end : start,
      description: description,
      updateId: id,
      eventInfo: arg.event.extendedProps?.object,
      checked: checked,
      anchorEl: anchorEl,
      date: moment(arg.event.extendedProps?.object.date).format("DD/MM/yyyy"),
    });
    dispatch(
      actions.getTimeslotPatientList(arg.event.extendedProps?.object.id)
    );
  };

  //Deleting Calendar Events

  const handlePrev = () => {
    let calendar = calanderRef.current.getApi();
    calendar.prev();
    props.dateChange(calendar.getDate());

    const title = calendar.currentDataManager.data.viewTitle;
    setTitle(title);
  };

  const handleNext = () => {
    let calendar = calanderRef.current.getApi();
    calendar.next();
    props.dateChange(calendar.getDate());
    const title = calendar.currentDataManager.data.viewTitle;

    setTitle(title);
  };
  useEffect(() => {
    let str = formatDate(new Date(), {
      month: "long",
      year: "numeric",
    });
    setTitle(str);
  }, []);

  return (
    <div className={styles.calenderMain}>
      <Grid container className={styles.headerContainer}>
        <Grid className={styles.headerItem} item xs={12}>
          <Button onClick={handlePrev}>
            <ArrowLeftIcon
              sx={{
                color: "#ffffff",
              }}
            />
          </Button>
          <Grid display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <Typography className={styles.headerText}>{title}</Typography>{" "}
            <div
              style={{
                marginLeft: "5px",
                color: "#fff",
                width: "20px",
                height: "20px",
              }}
            >
              {props.loading ? (
                <CircularProgress
                  style={{
                    color: "#fff",
                    width: "20px",
                    height: "20px",
                  }}
                />
              ) : null}
            </div>
          </Grid>
          <Grid item>
            <Button onClick={handleNext}>
              <ArrowRightIcon
                sx={{
                  color: "#ffffff",
                }}
              />
            </Button>
            <Button sx={{ height: "25px" }} onClick={handlePickerClick}>
              <CalendarMonthIcon
                sx={{
                  color: "#ffffff",
                }}
              />
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <FullCalendar
        dateClick={handleDateClick}
        ref={calanderRef}
        dayHeaderClassNames={styles.dayHeader}
        viewClassNames={styles.view}
        dayCellClassNames={styles.dayCell}
        height="calc(100vh - 360px)"
        headerToolbar={false}
        dayHeaders={true}
        initialView={"dayGridMonth"}
        plugins={[PlugIndayGrid, PluginTimeGrid, PluginInteraction]}
        weekends={true}
        events={props.data}
        eventClassNames={styles.eventClassname}
        dayHeaderContent={undefined}
        eventContent={(arg) => {
          const occupiedDates =
            arg?.event?._def?.extendedProps?.object?.occupiedDates || [];
          // Convert dates into JSX elements with <br> tags
          let tooltipContent;
          if (occupiedDates.length === 0) {
            tooltipContent = "Instructor is free";
          } else {
            tooltipContent = (
              <React.Fragment>
                Instructor is Busy On: <br />
                {occupiedDates.map((date, index) => (
                  <React.Fragment key={index}>
                    {date}
                    <br />
                  </React.Fragment>
                ))}
              </React.Fragment>
            );
          }

          return (
            <div
              style={{
                backgroundColor: arg?.event?.backgroundColor,
                color: arg?.event?.textColor,
                cursor: "auto",
              }}
              className={styles.eventContainer}
            >
              <Tooltip
                title={<React.Fragment>{tooltipContent}</React.Fragment>}
              >
                <div style={{}} className={styles.eventTitle}>
                  {arg.event.title}
                </div>
              </Tooltip>
            </div>
          );
        }}
        dayCellContent={(arg) => {
          return (
            <div className={arg?.isToday ? styles.dayCellContent : undefined}>
              <div className={arg?.isToday ? styles.dayCellText : undefined}>
                {arg.dayNumberText}
              </div>
            </div>
          );
        }}
        eventClick={eventClick}
        allDaySlot={false}
        direction={props.direction}
        slotLabelClassNames={styles.slotLabel}
      />
    </div>
  );
}

export default Calender;
