import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles(() => ({
  mainContainer: {
    background: "#54C9C4",
    maxWidth: "370px",
    borderRadius: "20px",
    padding: "5px 10px",
    margin: "5px 19px",
  },
  cardTitle: {
    fontFamily: "Cairo",
    fontSize: "19px",
    fontWeight: "700",
    lineHight: "140%",
    color: "#FFFFFF",
  },
  cell: {
    color: "#fff",
    padding: 2,
    margin: 0,
  },
  cardSubTitle: {
    fontFamily: "Cairo",
    fontSize: "18px",
    fontWeight: "700",
    lineHight: "25.2px",
    color: "#FFFFFF",
  },
  box: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "6px 15px",
    gap: "2px",
    background: "#30B4AE",
    borderRadius: "12px",
    marginTop: "10px",
  },
  description: {
    fontFamily: "Cairo",
    fontSize: "15px",
    fontWeight: "700",
    lineHight: "25.2px",
    color: "#FFFFFF",
    display: "flex",
    justifyContent: "start",
  },
  descriptionRange: {
    fontFamily: "Cairo",
    fontSize: "15px",
    fontWeight: "500",
    lineHight: "25.2px",
    color: "#FFFFFF",
    display: "flex",
    justifyContent: "start",
  },
}));
