import { Grid, LinearProgress, Tooltip, Typography } from "@mui/material";
import DataTable from "app/components/DataTable";
import React from "react";

import {
  selectPatients,
  selectWellBeingResults,
} from "app/containers/Patients/redux/selector";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { selectFeaturePermissions } from "store/global/authPermission/selector";
import { PermissionFeature } from "store/global/authPermission/types";
import { useStyles } from "./styles";
const questionnaireDetailList = [];
interface PatientDetailsProps {
  view: boolean;
  disable: boolean;
}
const QuestionnaireGradesResults = ({ view, disable }: PatientDetailsProps) => {
  const styles = useStyles();
  const params = useLocation();
  const { t } = useTranslation();
  const patientsDetails = useSelector(selectPatients);
  const wellBeingResults = useSelector(selectWellBeingResults);

  const featurePermissions = useSelector(selectFeaturePermissions);
  const detailsStatusPermission =
    featurePermissions[PermissionFeature.PATIENT_DETAILS_PROGRESS];

  const detailsPrivatePermission =
    featurePermissions[PermissionFeature.PATIENT_DETAILS_PRIVATE];
  const calculateProgressTimeSlots = () => {
    if (
      patientsDetails.progressCompletedSlot == 0 ||
      patientsDetails.progressAssignedSlot == 0
    ) {
      return 0;
    }
    return (
      (patientsDetails.progressCompletedSlot /
        patientsDetails.progressAssignedSlot) *
      100
    );
  };
  const calculateCompleteProgressTreatment = () => {
    if (
      patientsDetails.progressAssignedTreatment == 0 ||
      patientsDetails.progressCompletedTreatment == 0
    ) {
      return 0;
    }
    return (
      (patientsDetails.progressCompletedTreatment /
        patientsDetails.progressAssignedTreatment) *
      100
    );
  };
  const calculateDailyActivity = () => {
    if (
      patientsDetails.progressDailyActivityWatched == 0 ||
      patientsDetails.progressDailyActivitySent == 0
    ) {
      return 0;
    }
    return (
      (patientsDetails.progressDailyActivityWatched /
        patientsDetails.progressDailyActivitySent) *
      100
    );
  };
  const columnTable = [
    {
      id: "questionnaireName",
      label: t("PATIENTS.QUESTIONNAIRE_NAME"),
      align: "left",
      format: (value) => {
        return <Typography> {value.questionnaire.name}</Typography>;
      },
    },

    {
      id: "beforeGrade",
      label: t("PATIENTS.BEFORE_GRADE"),
      align: "left",
      format: (value) => {
        const outputRange = value?.questionnaireResults?.find(
          (f) => f.status == "BEFORE"
        )?.outputRange;
        const title = `${outputRange?.from}-${outputRange?.to} : ${outputRange?.rangeName}`;

        return (
          <Tooltip title={outputRange ? title : ""}>
            <Typography> {value.t0}</Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "afterGrade",
      label: t("PATIENTS.AFTER_GRADE"),
      align: "left",
      format: (value) => {
        const outputRange = value?.questionnaireResults?.find(
          (f) => f.status == "AFTER"
        )?.outputRange;
        const title = `${outputRange?.from}-${outputRange?.to} : ${outputRange?.rangeName}`;
        return (
          <Tooltip title={outputRange ? title : ""}>
            <Typography> {value.t1}</Typography>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <Grid
      container
      spacing={2}
      md={12}
      direction={"row"}
      sx={{ marginTop: "10px", marginBottom: "5px" }}
    >
      {(detailsPrivatePermission.hasFullAccess ||
        detailsPrivatePermission.hasReadAccess) && (
        <Grid item md={7} direction={"column"}>
          <Typography className={styles.heading}>
            {t("PATIENTS.QUESTIONNAIRE_GRADES_RESULTS")}
          </Typography>
          <DataTable
            loading={false}
            //orderDirection={orderDirection}
            //orderField={orderField}
            className={styles.questionnaireDetailList}
            setQuery={() => {}}
            rows={wellBeingResults}
            columns={columnTable}
            //Actions="Delete"
            page="userDetails"
          />
        </Grid>
      )}
      <Grid item md={5}>
        {(detailsStatusPermission.hasFullAccess ||
          detailsStatusPermission.hasReadAccess) && (
          <>
            <Typography className={styles.heading}>
              {t("PATIENTS.PROGRESS")}{" "}
            </Typography>
            <Typography className={styles.headingProgressBarOne}>
              {t("PATIENTS.ASSIGNED_SLOTS_ASSIGNED_TREATMENT", {
                max: patientsDetails.progressAssignedSlot,
                min: patientsDetails.progressCompletedSlot,
              })}{" "}
            </Typography>
            <LinearProgress
              variant="determinate"
              value={calculateProgressTimeSlots()}
              classes={{
                colorPrimary: styles.colorPrimary,
                barColorPrimary: styles.barColorPrimaryOne,
              }}
              sx={{
                borderRadius: 20,
                height: "15px",
              }}
            />
            <Typography className={styles.headingProgressBar}>
              {t("PATIENTS.COMPLETED_TREATMENTS_ASSIGNED_TREATMENTS", {
                min: patientsDetails.progressCompletedTreatment,
                max: patientsDetails.progressAssignedTreatment,
              })}{" "}
            </Typography>
            <LinearProgress
              variant="determinate"
              value={calculateCompleteProgressTreatment()}
              classes={{
                colorPrimary: styles.colorPrimary,
                barColorPrimary: styles.barColorPrimaryTwo,
              }}
              sx={{
                borderRadius: 20,
                height: "15px",
              }}
            />
            <Typography className={styles.headingProgressBar}>
              {t("PATIENTS.DAILY_ACTIVITY_WATCHED_DAILY_ACTIVITY_SENT", {
                min: patientsDetails.progressDailyActivityWatched,
                max: patientsDetails.progressDailyActivitySent,
              })}{" "}
            </Typography>
            <LinearProgress
              variant="determinate"
              value={calculateDailyActivity()}
              classes={{
                colorPrimary: styles.colorPrimary,
                barColorPrimary: styles.barColorPrimaryThree,
              }}
              sx={{
                borderRadius: 20,
                height: "15px",
                marginTop: "10px",
              }}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
};
export default QuestionnaireGradesResults;
