import { Grid } from "@mui/material";
import { BottomActions } from "app/components/BottomActions";
import DataTable from "app/components/DataTable";
import { CustomDrawer } from "app/components/Drawer";
import { NoPermission } from "app/components/NoPermission";
import { ScrollableView } from "app/components/ScrollableView";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import {
  selectFeaturePermissions,
  selectIsAdminRole,
} from "store/global/authPermission/selector";
import { PermissionFeature } from "store/global/authPermission/types";
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import Topbar from "../../../Dashboard/components/topbar";
import { useUsersGroupListSaga } from "../../redux/saga";
import { selectList, selectLoading, selectQuery } from "../../redux/selector";
import { actions, reducer, sliceKey } from "../../redux/slice";
import { useStyles } from "./styles";

const List = () => {
  useInjectReducer({ key: sliceKey, reducer });
  useInjectSaga({ key: sliceKey, saga: useUsersGroupListSaga });
  const isAdminRole = useSelector(selectIsAdminRole);
  const navigate = useNavigate();
  const styles = useStyles();
  const { meta, items } = useSelector(selectList);
  const isLoading = useSelector(selectLoading);
  const { t } = useTranslation();

  const featurePermissions = useSelector(selectFeaturePermissions);
  const { hasFullAccess, hasReadAccess, hasNoAccess } =
    featurePermissions[PermissionFeature.USER_GROUPS];
  const detailsPermission = featurePermissions[PermissionFeature.USER_LIST];
  const { height } = useWindowDimensions();
  const columnTable = [
    {
      label: "",
      width: "0%",
    },
    {
      id: "groupName",
      label: t("TABLES.GROUP_NAME"),
      align: "left",
      width: "30%",
      sortValue: "selector.groupName",
    },
    {
      id: "numberOfUser",
      label: t("TABLES.NUMBER_OF_ASSIGNED_USERS"),
      align: "left",
      sortValue: "selector.numberOfUser",
      format: (value) => {
        return value.numberOfUser;
      },
    },
  ];

  const hasMoreItems = meta.currentPage < meta.totalPages;
  const getNextPage = () => {
    if (!false) {
      dispatch(actions.getNextPage(meta.currentPage + 1));
    }
  };

  const dispatch = useDispatch();

  const { q, orderDirection, orderField } = useSelector(selectQuery);

  useEffect(() => {
    dispatch(actions.getList());
  }, [q, orderDirection, orderField]);

  const handleAdd = () => {
    navigate(`/user-groups/new`);
  };

  const setPageQuery = (name: string, value: any) => {
    dispatch(actions.setQuery({ name, value }));
  };

  if (hasNoAccess) {
    return (
      <div>
        <CustomDrawer />
        <Topbar />
        <NoPermission />
      </div>
    );
  }

  return (
    <Grid className={styles.mainContainer}>
      <CustomDrawer />
      <Grid item xs={12}>
        <Topbar q={q} setSearch={(q) => setPageQuery("q", q)} />
        <div className={styles.root}>
          <>
            <ScrollableView
              loadMore={getNextPage}
              hasMoreItems={hasMoreItems}
              height={height - 200}
            >
              <DataTable
                loading={isLoading}
                rows={items}
                columns={columnTable}
                Actions={isAdminRole ? "Edit" : ""}
                setQuery={setPageQuery}
                orderDirection={orderDirection}
                orderField={orderField}
                onRowDoubleClick={(item) => {
                  if (detailsPermission?.hasNoAccess) return;
                  navigate(`/user-groups/${item?.id}/view`);
                }}
                editAction={(item) => {
                  navigate(`/user-groups/${item?.id}/edit`);
                }}
              />
            </ScrollableView>
          </>
          <BottomActions
            disabled={!hasFullAccess}
            chipBoxes={[
              {
                label: t("PLACEHOLDERS.TOTAL_ITEMS"),
                count: meta.totalItems,
              },
            ]}
            onClickFab={isAdminRole ? handleAdd : undefined}
          />
        </div>
      </Grid>
    </Grid>
  );
};
export default List;
