import { Add } from "@mui/icons-material";
import { Fab } from "@mui/material";
import React from "react";
type Props = {
  onClick(): void;
  disabled?: boolean;
};
export const FabAddIcon = ({ onClick, disabled }: Props) => {
  return (
    <Fab
      size="medium"
      color="secondary"
      aria-label="add"
      disabled={disabled}
      sx={{ marginTop: 2, marginBottom: 2 }}
      onClick={onClick}
    >
      <Add />
    </Fab>
  );
};
