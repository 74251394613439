import { FormError } from "app/containers/types";
import { TRANSLATE } from "utils/helpers";

export const onValidate = (form) => {
  const errors: FormError[] = [];
  if (form?.name?.value?.length === 0) {
    errors.push({
      name: "name",
      error: TRANSLATE("TREATMENT_TYPES.NAME_REQUIRED"),
    });
  }
  if (form.beforeAfter.value?.length === 0) {
    errors.push({
      name: "beforeAfter",
      error: TRANSLATE("TREATMENT_TYPES.BEFORE_AFTER_REQUIRED"),
    });
  }
  if (form.hours.value?.length === 0) {
    errors.push({
      name: "hours",
      error: TRANSLATE("TREATMENT_TYPES.HOURS_REQUIRED"),
    });
  }
  if (form.session_id.value?.length === 0) {
    errors.push({
      name: "session_id",
      error: TRANSLATE("TREATMENT_TYPES.SESSION_ID_REQUIRED"),
    });
  }
  if (form.description.value?.length === 0) {
    errors.push({
      name: "description",
      error: TRANSLATE("TREATMENT_TYPES.DESCRIPTION_REQUIRED"),
    });
  }
  if (!form.videoFile?.url && !form.audioFile?.url && !form.pdfFile?.url) {
    errors.push({
      name: "videoFile",
      error: TRANSLATE("TREATMENT_TYPES.AT_LEAST_ONE_FILE_REQUIRED"),
    });
  }
  return errors;
};

export const isDuplicate = (form, treatments) => {
  const treatmentsExceptSelected = form?.id?.value
    ? treatments.filter((f) => f.id != form.id.value)
    : treatments;
  const duplicate = treatmentsExceptSelected.find(
    (treatment) =>
      treatment.name === form.name.value && treatment?.id !== form.id?.value
  );
  return !!duplicate;
};
