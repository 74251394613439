import InfoIcon from "@mui/icons-material/Info";
import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { RadioGroup } from "app/components/RadioGroup";
import {
  AvailableTimeslotsResponse,
  LocalTimeslotInvolvedPatient,
  Timeslot,
} from "app/containers/AssignedTreament/types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getFormattedDateTime } from "utils/helpers";

import { actions } from "app/containers/AssignedTreament/redux/slice";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { selectFeaturePermissions } from "store/global/authPermission/selector";
import { PermissionFeature } from "store/global/authPermission/types";
import { useStyles } from "./styles";
type Props = {
  availableTimeslotsResponse: AvailableTimeslotsResponse;
  timeslotInvolvedPatient: LocalTimeslotInvolvedPatient;
  index: number;
};

export const MultiTimeslotSelection = ({
  timeslotInvolvedPatient,
  availableTimeslotsResponse,
  index,
}: Props) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const [timeslots, setTimeslots] = useState<Timeslot[]>([]);
  const selectedTimeSlotId =
    timeslotInvolvedPatient.newTimeslotId || timeslotInvolvedPatient.timeSlotId;
  const featurePermissions = useSelector(selectFeaturePermissions);

  const { t } = useTranslation();
  useEffect(() => {
    const otherTimeslotIds =
      availableTimeslotsResponse.treatmentInvolvedPatient.timeslotInvolvedPatients
        .map((tip) => {
          return [tip.newTimeslotId, tip.timeSlotId];
        })
        .flat();
    setTimeslots(
      availableTimeslotsResponse.timeslots.filter((f) => {
        if (selectedTimeSlotId == f.id) {
          return true;
        }
        if (otherTimeslotIds.includes(f.id)) {
          return false;
        }
        return true;
      })
    );
  }, [
    availableTimeslotsResponse.treatmentInvolvedPatient,
    availableTimeslotsResponse.timeslots,
    selectedTimeSlotId,
  ]);

  const handleSetTimeSlot = (timeslotId) => {
    dispatch(
      actions.handleOnSelectTimeslot({
        timeslotId,
        index,
      })
    );
  };
  const { hasFullAccess } =
    featurePermissions[PermissionFeature.ASSIGNED_TREATNEMTS_B];

  const pathname = window.location.pathname;

  const disabled = !hasFullAccess && pathname === "/assigned-treatment";

  if (!timeslots?.length) {
    return (
      <Grid
        xs={12}
        direction="column"
        sx={{
          minHeight: 300,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography>{t("TIMESLOT.NOT_TIMESLOTS_AVAILABLE")}</Typography>
      </Grid>
    );
  }
  const isStartedTreatment = timeslotInvolvedPatient?.assignedTreatments?.find(
    (f) => !!f.timeslotSession?.completedAt
  );
  const totalAssignedSessions =
    timeslotInvolvedPatient.assignedTreatments?.length ?? 0;
  const totalCompletedSessions =
    timeslotInvolvedPatient.assignedTreatments?.filter(
      (f) => !!f.timeslotSession?.completedAt
    ).length;
  const isCompletedTreatment =
    totalAssignedSessions > 0 &&
    totalCompletedSessions === totalAssignedSessions;

  return (
    <Grid height={250}>
      <RadioGroup name="controlled-radio-buttons-group">
        <Grid style={{ width: "100%" }}>
          <TableContainer className={styles.tableContainer}>
            <Table>
              <TableHead className={styles.head}>
                <TableRow>
                  <TableCell colSpan={9}>
                    {isStartedTreatment ? (
                      <Typography>
                        <InfoIcon style={{ fontSize: "20px" }} />
                        {isCompletedTreatment
                          ? t("TIMESLOT.GROUP_COMPLETED_INFO", {
                              min: totalCompletedSessions,
                              max: totalAssignedSessions,
                            })
                          : t("TIMESLOT.GROUP_PROGRESS_INFO", {
                              min: totalCompletedSessions,
                              max: totalAssignedSessions,
                            })}{" "}
                      </Typography>
                    ) : (
                      <Typography>
                        {t("TIMESLOT.SELECT_MULTI_SESSION")}{" "}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {timeslots.map((x, i) => {
                  const maxParticipants = x.treatment.maximum_participants;

                  const participantCount = Math.max(
                    ...x.timeslotSession.map((item, _index) => {
                      return item.assignedTreatment?.length || 0;
                    })
                  );
                  const isMaxReached = participantCount >= maxParticipants;

                  return (
                    <TableRow key={i}>
                      <TableCell colSpan={7}>
                        <Box
                          sx={{
                            width: 700,
                            overflowX: "scroll",
                          }}
                        >
                          <Tooltip
                            title={
                              isMaxReached
                                ? t(
                                    "ASSIGNED_TREATMENT.MAX_PARTICIPANTS_REACHED_FOR_GROUP"
                                  )
                                : ""
                            }
                          >
                            <FormControlLabel
                              value={x.id}
                              control={
                                <Radio
                                  disabled={
                                    isMaxReached ||
                                    !!isStartedTreatment ||
                                    x.id == selectedTimeSlotId ||
                                    disabled
                                  }
                                  sx={{
                                    "&.Mui-checked": {
                                      color: "#012397",
                                    },
                                  }}
                                  onChange={(e) => {
                                    handleSetTimeSlot(parseInt(e.target.value));
                                  }}
                                />
                              }
                              checked={selectedTimeSlotId == x.id}
                              defaultChecked={selectedTimeSlotId == x.id}
                              label={
                                <div className={styles.label_date}>
                                  <span
                                    style={{
                                      fontSize: 10,
                                      position: "absolute",
                                      top: -10,
                                      left: -48,
                                      color: "#bbb",
                                    }}
                                  >
                                    {" "}
                                    T{x.id}{" "}
                                  </span>
                                  {x.timeslotSession.map((item, index) => {
                                    const currentAssigned =
                                      timeslotInvolvedPatient.assignedTreatments.find(
                                        (f) => f.timeslotSession.id == item.id
                                      );
                                    const isCompleted =
                                      !!currentAssigned?.timeslotSession
                                        .completedAt;
                                    return (
                                      <div
                                        className={styles.date_section_wrapper}
                                        key={index}
                                      >
                                        <div className={styles.date_section}>
                                          <span className={styles.section_date}>
                                            {getFormattedDateTime(
                                              item.start_time,
                                              "EEE, dd MMM"
                                            )}
                                          </span>
                                          <span
                                            className={clsx(
                                              styles.section_time,
                                              isCompleted &&
                                                styles.section_completed
                                            )}
                                          >
                                            {getFormattedDateTime(
                                              item.start_time,
                                              "HH:mm"
                                            )}{" "}
                                            -{" "}
                                            {getFormattedDateTime(
                                              item.end_time,
                                              "HH:mm"
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              }
                              className={styles.date_area}
                            />
                          </Tooltip>
                        </Box>
                      </TableCell>
                      <TableCell colSpan={2}>
                        <Typography className={styles.countData}>
                          {participantCount}/{maxParticipants}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </RadioGroup>
    </Grid>
  );
};
