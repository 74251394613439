import { Grid } from "@mui/material";
import React from "react";
import { useStyles } from "./styles";
type Props = {
  label: number;
  count: string | number;
};
export const ChipBox = ({ label, count }: Props) => {
  const styles = useStyles();
  return (
    <Grid className={styles.chipBox}>
      <label style={{ fontWeight: "bold" }} className={styles.chipLabel}>
        {label}
      </label>
      <label className={styles.numLabel}>{count}</label>
    </Grid>
  );
};
