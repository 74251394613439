import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  title: {
    fontSize: "24px",
    fontWeight: "700",
    color: "#012497",
    fontFamily: "Poppins",
    textAlign: "center",
    marginRight: "10px",
  },
  titleEntity: {
    fontSize: "24px",
    fontWeight: "700",
    color: "red",
    fontFamily: "Poppins",
    textAlign: "center",
  },
  cancelBtn: {
    color: "#3D63E6",
    borderColor: "#3D63E6",
    "&:hover": {
      borderColor: "#3D63E6",
    },
    fontFamily: "Cairo",
    fontWeight: 700,
    fontSize: "16px",
    width: "160px",
    height: "50px",
  },
  btnContainer: {
    display: "flex",
    alignItems: "center",
  },
  titleDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "400px",
  },
  removeButton: {
    background: "#3D63E6",
    padding: "10px 35px",
    border: "none",
    color: "#fff",
    marginBottom: "10px",
    borderRadius: "3px",
    width: "160px",
    height: "50px",
    fontFamily: "Cairo",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "30px",
    marginLeft: "20px",
  },
  button_section: {
    marginTop: "10px",
  },
  deleteDiv: {
    position: "absolute",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "500px",
    minHeight: "263px",
    backgroundColor: "#fff",
    borderRadius: "10px",
    padding: "40px",
    boxShadow: "24px",
  },
  body: {
    color: "#000",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    textAlign: "center",
    margin: "30px 0px",
  },
  closeIcon: {
    position: "absolute",
    right: "5px",
    top: "5px",
  },
}));
