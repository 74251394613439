export const canBankStatusOptionActivated = (
  id: number,
  currentSelection: "New" | "Running" | "Done",
  menu: "New" | "Running" | "Done"
) => {
  if (!id) {
    return true;
  }
  if (currentSelection === "New") {
    return ["Done"].includes(menu);
  }
  if (currentSelection === "Running") {
    return ["New"].includes(menu);
  }
  if (currentSelection === "Done") {
    return true;
  }
  return true;
};
