import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Button, CircularProgress, Grid, Typography } from "@mui/material";

import { CalenderListItemType } from "app/containers/TimeSlots/components/CalenderView/types";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";

import { useStyles } from "./styles";

///Don't sort
import FullCalendar, { formatDate } from "@fullcalendar/react";
///Don't sort
import PlugIndayGrid from "@fullcalendar/daygrid";
import PluginInteraction from "@fullcalendar/interaction";
import PluginTimeGrid from "@fullcalendar/timegrid";
import { actions } from "app/containers/TimeSlots/components/ListView/redux/slice";
import { useDispatch } from "react-redux";
import { getFormattedDateTime } from "utils/helpers";
import DetailsView from "./components/DetailsView";

type Props = {
  direction?: any;
  data: CalenderListItemType[];
  dateChange: (date) => void;
  loading?: boolean;
};

function Calender(props: Props) {
  const dispatch = useDispatch();
  const styles = useStyles();
  const calanderRef: any = useRef();
  const [event, setEvent]: any = useState({
    modal: false,
    calendarTitle: "",
    startDate: "",
    endDate: "",
    description: "",
    action: "add",
    id: 0,
    events: [],
    eventInfo: undefined,
    updateId: "",
    checked: false,
    error: "",
  });
  const [title, setTitle] = useState("");
  const clickable = true;

  const handlePickerClick = () => {
    // setOpen((isOpen) => !isOpen);
    // setAnchorEl(event.currentTarget);
  };

  const handleDateClick = () => {};

  const closeModal = () => {
    setEvent({
      modal: false,
      calendarTitle: "",
      startDate: "",
      endDate: "",
      description: "",
      checked: false,
    });
  };

  const eventClick = (arg) => {
    if (!clickable) {
      return;
    }
    let title = arg.event.title;
    let start = getFormattedDateTime(
      arg.event.extendedProps?.object.start_time,
      "hh:mm"
    );
    let end = getFormattedDateTime(
      arg.event.extendedProps?.object.end_time,
      "hh:mm"
    );
    if (end === "Invalid date") {
      end = start;
    }
    let checked;
    if (arg.event.backgroundColor === "#a70707") {
      checked = true;
    } else {
      checked = false;
    }
    let description = arg.event.extendedProps.description;
    let id = arg.event.id;
    const anchorEl = arg.el;
    setEvent({
      action: "edit",
      modal: true,
      calendarTitle: title,
      startDate: start,
      endDate: end !== "Invalid date" ? end : start,
      description: description,
      updateId: id,
      eventInfo: arg.event.extendedProps?.object,
      checked: checked,
      anchorEl: anchorEl,
      date: moment(arg.event.extendedProps?.object.date).format("DD/MM/yyyy"),
    });
    dispatch(
      actions.getTimeslotPatientList(arg.event.extendedProps?.object.id)
    );
  };

  //Deleting Calendar Events

  const handlePrev = () => {
    let calendar = calanderRef.current.getApi();
    calendar.prev();
    props.dateChange(calendar.getDate());

    const title = calendar.currentDataManager.data.viewTitle;
    setTitle(title);
  };

  const handleNext = () => {
    let calendar = calanderRef.current.getApi();
    calendar.next();
    props.dateChange(calendar.getDate());
    const title = calendar.currentDataManager.data.viewTitle;

    setTitle(title);
  };
  useEffect(() => {
    let str = formatDate(new Date(), {
      month: "long",
      year: "numeric",
    });
    setTitle(str);
  }, []);

  return (
    <div className={styles.calenderMain}>
      <Grid container className={styles.headerContainer}>
        <Grid className={styles.headerItem} item xs={12}>
          <Button onClick={handlePrev}>
            <ArrowLeftIcon
              sx={{
                color: "#ffffff",
              }}
            />
          </Button>
          <Grid display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <Typography className={styles.headerText}>{title}</Typography>{" "}
            <div
              style={{
                marginLeft: "5px",
                color: "#fff",
                width: "20px",
                height: "20px",
              }}
            >
              {props.loading ? (
                <CircularProgress
                  style={{
                    color: "#fff",
                    width: "20px",
                    height: "20px",
                  }}
                />
              ) : null}
            </div>
          </Grid>

          <Grid item>
            <Button onClick={handleNext}>
              <ArrowRightIcon
                sx={{
                  color: "#ffffff",
                }}
              />
            </Button>
            <Button sx={{ height: "25px" }} onClick={handlePickerClick}>
              <CalendarMonthIcon
                sx={{
                  color: "#ffffff",
                }}
              />
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <FullCalendar
        dateClick={handleDateClick}
        ref={calanderRef}
        dayHeaderClassNames={styles.dayHeader}
        viewClassNames={styles.view}
        dayCellClassNames={styles.dayCell}
        height="calc(100vh - 360px)"
        headerToolbar={false}
        dayHeaders={true}
        initialView={"timeGridWeek"}
        plugins={[PlugIndayGrid, PluginTimeGrid, PluginInteraction]}
        weekends={true}
        events={props.data}
        eventClassNames={styles.timeEventClassname}
        dayHeaderContent={(arg) => {
          const day = arg.text?.split(" ")?.[0];
          const date = arg.text?.split(" ")?.[1]?.split("/")?.[1];

          return (
            <div className={styles.dayHeaderContent}>
              <div className={styles.dayHeaderText}>{day}</div>
              <div
                style={
                  arg?.isToday
                    ? {
                        width: 30,
                        height: 30,
                        borderRadius: "50%",
                        backgroundColor: "#3D63E6",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#ffffff",
                        cursor: clickable ? "pointer" : "auto",
                        overflow: "hidden",
                      }
                    : undefined
                }
                className={styles.dayHeaderSubText}
              >
                {date}
              </div>
            </div>
          );
        }}
        eventContent={(arg) => {
          const eventColor =
            arg?.event?.extendedProps?.object?.timeslot?.eventColor;
          return (
            <div
              id={`evt_${arg.event?._def?.extendedProps?.object?.id}`}
              style={{
                borderTop: `2px solid ${eventColor}`,
                borderBottom: `2px solid ${eventColor}`,
                borderRight: `4px solid ${eventColor}`,
                borderLeft: `4px solid ${eventColor}`,
                cursor: clickable ? "pointer" : "auto",
              }}
              className={styles.eventContainer1}
            >
              <span
                className={styles.eventOrigin}
                style={{ backgroundColor: eventColor }}
              >
                {" "}
                {
                  arg?.event?.extendedProps?.object?.timeslot?.organization
                    ?.name
                }{" "}
                {
                  arg?.event?.extendedProps?.object?.timeslot?.department
                    ?.departmentName
                }
              </span>
              <div
                style={{ backgroundColor: arg?.event?.textColor }}
                className={styles.eventTitle1}
              >
                {
                  arg?.event?._def?.extendedProps?.object?.timeslot?.treatment
                    ?.name
                }
              </div>
              <div
                style={{ backgroundColor: arg?.event?.textColor }}
                className={styles.eventTime}
              >
                {moment(arg?.event?.start).format("hh:mm A") +
                  " - " +
                  moment(arg?.event?.end).format("hh:mm A")}
              </div>
            </div>
          );
        }}
        dayCellContent={undefined}
        eventClick={eventClick}
        allDaySlot={false}
        direction={props.direction}
        slotLabelClassNames={styles.slotLabel}
      />
      <DetailsView
        calendarTitle={event.calendarTitle}
        startDate={event.startDate}
        endDate={event.endDate}
        eventInfo={event.eventInfo}
        onClose={closeModal}
        anchorEl={event.anchorEl}
      />
    </div>
  );
}

export default Calender;
