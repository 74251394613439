import { PayloadAction } from "@reduxjs/toolkit";
import { FormError } from "app/containers/types";
import set from "lodash/set";
import { createSlice } from "utils/@reduxjs/toolkit";
import { Activity, DailyActivityState, UploadedResource } from "../types";
const initialState = DailyActivityState;
export const organizationsListSlice = createSlice({
  name: "dailyActivityState",
  initialState,
  reducers: {
    createOrUpdate: (state, payload) => {
      state.form.isLoading = true;
    },
    createOrUpdateSuccess: (state) => {
      state.form.isLoading = false;
      state.dailyActivityId = undefined;
    },
    createOrUpdateFailure: (state) => {
      state.form.isLoading = false;
    },
    getList: (state) => {
      state.loading = true;
    },
    getById: (state, action: PayloadAction<{ id: any }>) => {
      state.form.isLoading = true;
    },
    getByIdSuccess: (state, action: PayloadAction<Activity>) => {
      state.form.name.value = action.payload.name;
      state.form.resources = action.payload.resources;
      state.form.description.value = action.payload.description;
      state.form.activationDayNumber.value = action.payload.activationDayNumber;
      state.form.organizationId.value = action.payload.organizationId;
      state.form.scheduledAt.value = action.payload.scheduledAt;

      state.form.id = action.payload.id;
      state.form.isLoading = false;
    },
    getListByIdFailure: (state) => {
      state.form.isLoading = false;
    },
    getListSuccess: (
      state,
      action: PayloadAction<{ items: []; links: any; meta: any }>
    ) => {
      state.loading = false;
      state.list.meta = action.payload.meta;
      state.list.items =
        action.payload.meta.currentPage === 1
          ? action.payload.items
          : [...state.list.items, ...action.payload.items];
    },
    getListFailure: (state) => {
      state.loading = false;
    },
    getNextPage: (state, action: PayloadAction<any>) => {
      state.query.page = state.list.meta.currentPage + 1;
      state.loading = true;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    updateFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state.form, `${action.payload.key}.value`, action.payload.value);
      state.error = "";
    },
    updateFormUploadValue: (
      state,
      action: PayloadAction<{ resource: UploadedResource }>
    ) => {
      const resource = state.form.resources
        .filter((f) => !f.isDeleted)
        .find((f) => f.resourceType == action.payload.resource.resourceType);
      if (resource) {
        resource.resourceName = action.payload.resource.resourceName;
        resource.resourceSize = action.payload.resource.resourceSize;
        resource.resourcePath = action.payload.resource.resourcePath;
      } else {
        state.form.resources.push(action.payload.resource);
      }
    },
    removeFormUploadValue: (
      state,
      action: PayloadAction<{ resource: UploadedResource }>
    ) => {
      const resource = state.form.resources.find(
        (f) => f.resourcePath == action.payload.resource.resourcePath
      );

      if (resource) {
        resource.isDeleted = true;
      }
    },

    toggleModal(state, action: PayloadAction<{ id?: number }>) {
      state.dailyActivityId = action.payload.id;
    },
    resetForm(state) {
      state.error = "";
      state.form = initialState.form;
    },
    setFormErrors(
      state,
      action: PayloadAction<{
        key?: string;
        errors: FormError[];
      }>
    ) {
      action.payload.errors.forEach((error) => {
        set(state.form, `${error.name}.error`, error.error);
      });
    },
    setQuery: (state, action: PayloadAction<{ name: string; value: any }>) => {
      const { name, value } = action.payload;
      state.query.page = 1;
      state.list = initialState.list;
      set(state.query, name, value);
    },
    toggleStatus: (state, action: PayloadAction<number>) => {
      const item = state.list.items.find(
        (item: Activity) => item.id === action.payload
      );

      if (item) {
        item.isLoading = true;
      }
    },
    toggleStatusSuccess: (state, action: PayloadAction<number>) => {
      const item = state.list.items.find(
        (item: Activity) => item.id === action.payload
      );
      if (item) {
        item.isLoading = false;
        item.isActive = !item.isActive;
      }
    },
    toggleStatusFailure: (
      state,
      action: PayloadAction<{ id: number; message: string }>
    ) => {
      const index = state.list.items.findIndex(
        (item: Activity) => item.id === action.payload.id
      );
      state.list.items[index].isLoading = false;
      state.error = action.payload?.message;
    },
    resetModule: (state) => {
      state = initialState;
    },
    resetQuery: (state) => {
      state.query = initialState.query;
    },
  },
});
export const { reducer, actions, name: sliceKey } = organizationsListSlice;
