import { Add, Done, Edit } from "@mui/icons-material";
import {
  Fab,
  FormHelperText,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import DataTable from "app/components/DataTable";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import imgDelete from "../../../../../../assets/Delete.png";

import {
  selectCurrentQuestion,
  selectLoading,
} from "../../../../redux/selector";
import { actions } from "../../../../redux/slice";

import { AnswersListItemType } from "app/containers/Questionnaires/types";
import toastService from "utils/toast";
import { TableField } from "../TableField";
import { useStyles } from "./styles";

const AnswersForm = ({ mode }) => {
  const styles = useStyles();
  const [inlineEdit, setInlineEdit] = useState<Array<number>>([]);

  const dispatch = useDispatch();

  const currentQuestion = useSelector(selectCurrentQuestion);

  const { t, i18n } = useTranslation();

  const startInlineEdit = (index) => {
    dispatch(actions.answersInlineEdit({ index, isEdit: true }));
  };

  const finishInlineEdit = (index) => {
    dispatch(actions.answersInlineEdit({ index, isEdit: false }));
  };

  const editAnswer = (index: number, name: string, value: string) => {
    dispatch(actions.editAnswer({ index, name, value }));
  };
  const loading = useSelector(selectLoading);
  const columns = [
    {
      id: "action",
      label: "",
      sortValue: "selector.action",
      format: (val, index) => {
        return (
          <span>
            {!val.isEdit ? (
              <span>
                <Tooltip title={t("TOOLTIP.EDIT")}>
                  <IconButton
                    disabled={mode === "view" || loading}
                    onClick={() => startInlineEdit(index)}
                  >
                    <Edit
                      sx={{
                        color: mode === "view" ? "#C6C9CA" : "#FCC500",
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </span>
            ) : (
              <span>
                <Tooltip title={t("TOOLTIP.SAVE")}>
                  <IconButton
                    disabled={loading}
                    onClick={() => finishInlineEdit(index)}
                  >
                    <Done sx={{ color: "#3D63E6" }} />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t("TOOLTIP.DELETE")}>
                  <IconButton
                    disabled={loading}
                    onClick={() => {
                      if (currentQuestion.answers.length > 1) {
                        dispatch(actions.removeAnswer({ index }));
                      } else {
                        toastService.error(
                          t("QUESTIONNAIRES.FORM.ERRORS.MUST_HAVE_MIN_ANSWER", {
                            value: 1,
                          })
                        );
                      }
                    }}
                    onDoubleClick={(e) => e.stopPropagation()}
                  >
                    <img height={"18px"} src={imgDelete} alt=""></img>
                  </IconButton>
                </Tooltip>
              </span>
            )}
          </span>
        );
      },
    },
    {
      id: "content",
      label: t("QUESTIONNAIRES.ANSWER_CONTENT"),
      sortValue: "selector.content",
      format: (val: AnswersListItemType, index) => {
        return !val.isEdit ? (
          <TableField value={val["content"]} error={val.errors?.content} />
        ) : (
          <React.Fragment>
            <TextField
              className={styles.tableInputContent}
              placeholder={t("QUESTIONNAIRES.ENTER_ANSWER")}
              name="content"
              onChange={(e) => {
                editAnswer(index, "content", e.target.value);
              }}
              error={!!val.errors?.content}
              helperText={val.errors?.content}
              FormHelperTextProps={{
                style: {
                  textAlign: i18n.dir() === "ltr" ? "left" : "right",
                },
              }}
              value={val["content"]}
              disabled={loading}
            />
          </React.Fragment>
        );
      },
    },
    {
      id: "weight",
      label: t("QUESTIONNAIRES.ANSWER_WEIGHT"),
      sortValue: "selector.weight",
      format: (val, index) => {
        return !val.isEdit ? (
          <TableField value={val["weight"]} error={val.errors?.weight} />
        ) : (
          <TextField
            type="number"
            className={styles.tableInputWeight}
            placeholder={t("QUESTIONNAIRES.ENTER_ANSWER")}
            name="weight"
            onChange={(e) => {
              editAnswer(index, "weight", e.target.value);
            }}
            disabled={loading}
            error={!!val.errors?.weight}
            helperText={val.errors?.weight}
            FormHelperTextProps={{
              style: {
                textAlign: i18n.dir() === "ltr" ? "left" : "right",
              },
            }}
            value={val["weight"]}
          />
        );
      },
    },
  ];

  const filteredAnswers =
    currentQuestion.answers?.filter((val) => !val?.isDeleted) || [];

  return (
    <>
      <div className={styles.check_box_main_parent}>
        <DataTable
          loading={loading}
          rows={filteredAnswers}
          columns={columns}
          scrollClass={styles.dataTableDiv}
        />
      </div>
      <FormHelperText error>
        {currentQuestion?.errors?.answersGeneral}
      </FormHelperText>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          paddingTop: "10px",
        }}
      >
        <span className={styles.addQues}>{t("QUESTIONNAIRES.ADD_ANSWER")}</span>
        <Fab
          size="medium"
          color="secondary"
          aria-label="add"
          disabled={mode === "view" || loading}
          sx={{ marginTop: 2, marginBottom: 2, zIndex: 0 }}
          onClick={() => {
            if (currentQuestion.answers.length < 8) {
              dispatch(actions.addAnswer({ question: "" }));
              setInlineEdit([...inlineEdit]);
            } else {
              dispatch(
                actions.showError({
                  message: "Only 8 answers can be added",
                })
              );
            }
          }}
        >
          <Add />
        </Fab>
      </Grid>
    </>
  );
};

export default AnswersForm;
