import { CloudDownload, Info, PlayCircleFilled } from "@mui/icons-material";
import { Box, IconButton, Tooltip } from "@mui/material";
import DataTable from "app/components/DataTable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFormattedDateTime } from "utils/helpers";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";

import { selectToken } from "../Auth/selectors";

import BreadCrumb from "app/components/BreadCrumb";
import { CustomDrawer } from "app/components/Drawer";
import { ScrollableView } from "app/components/ScrollableView";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ImportLogs from "./ImportLogs";
import { useImportsSaga } from "./redux/saga";
import {
  selectImportsList,
  selectImportsLoading,
  selectImportsLogsLoading,
  selectIsFormOpen,
  selectIsOpenLogsDialog,
} from "./redux/selector";
import { actions, reducer, sliceKey } from "./redux/slice";
import { useImportsStyles } from "./styles";

type Props = {
  type?: "PATIENTS";
  label?: string;
};

const ImportsModule = (props: Props) => {
  useInjectReducer({ key: sliceKey, reducer });
  useInjectSaga({ key: sliceKey, saga: useImportsSaga });
  let { type } = props;
  if (!type) {
    type = "PATIENTS";
  }
  const token = useSelector(selectToken);
  const [downloadType, setDownloadType] = useState<string>("");
  const [id, setId] = useState<number | null>(null);
  const dispatch = useDispatch();
  const styles = useImportsStyles();
  const { items, query, meta } = useSelector(selectImportsList);
  const isLoading = useSelector(selectImportsLoading);
  const isLogsLoading = useSelector(selectImportsLogsLoading);
  const isFormOpen = useSelector(selectIsFormOpen);
  const isLogsDialogOpened = useSelector(selectIsOpenLogsDialog);
  const { orderField, orderDirection, q } = query;
  const columns = [
    {
      id: "id",
      label: "ID",
      sortValue: "selector.id",
    },
    {
      id: "UploadedBY",
      label: "Uploaded BY",
      sortValue: "selector.user.name",
      format: (value) => value?.user?.email,
      minWidth: 100,
    },
    {
      id: "progress",
      label: "Progress",
      sortValue: "selector.currentProgress",
      format: (value) => `${value?.currentProgress}/${value?.totalRows}`,
      minWidth: 20,
    },
    {
      id: "type",
      label: "Type",
      sortValue: "selector.type",
      minWidth: 30,
    },
    {
      id: "processStatus",
      label: "Status",
      sortValue: "selector.processStatus",
      minWidth: 40,
      format: (value) => value?.processStatus,
    },

    {
      id: "createdAt",
      label: "Upload Date",
      sortValue: "selector.createdAt",
      format: (value) => getFormattedDateTime(value?.createdAt),
      minWidth: 50,
    },
    {
      id: "action",
      label: "Action",
      format: (value) => (
        <Box>
          <Tooltip title={t("TOOLTIP.DOWNLOAD_EXCEL_FILE")}>
            <IconButton onClick={() => window.open(value?.url, "_blank")}>
              <CloudDownload sx={{ color: "#FAA61A" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("TOOLTIP.VIEW_LOGS")}>
            <IconButton
              onClick={() => {
                setId(value.id);
                dispatch(actions.toggleLogsDialog());
              }}
            >
              <Info sx={{ color: "#0D28F4" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("TOOLTIP.PROCESS_IMPORT")}>
            <IconButton
              disabled={
                value?.processImportInProgress
                  ? value?.processImportInProgress
                  : false
              }
              onClick={() => {
                dispatch(actions.processImport(value.id));
              }}
            >
              <PlayCircleFilled />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];
  const navigate = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(actions.setType({ name: "type", value: type }));
    dispatch(actions.getList(""));
  }, [orderField, orderDirection, q, type]);

  const setQuery = (name: string, value: any) => {
    dispatch(actions.setQuery({ name, value }));
  };
  const hasMoreItems = meta.currentPage < meta.totalPages;
  const getNextPage = () => {
    if (!isLoading) {
      dispatch(actions.getNextPageItems(meta.currentPage + 1));
    }
  };

  const handleFormToggle = () => {
    dispatch(actions.toggleFormOpen());
  };

  const handleLogsDialogToggle = () => {
    dispatch(actions.toggleLogsDialog());
  };

  const onBackClick = () => {
    navigate("/patients");
  };

  return (
    <div className={styles.mainContainer}>
      <CustomDrawer />

      <div className={styles.root}>
        <BreadCrumb
          onBackClick={onBackClick}
          title={t("PATIENTS.PATIENT")}
          subTitle={"List"}
        />
        <Box
          sx={{
            mt: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        ></Box>

        <div className={styles.tableContainer}>
          <ScrollableView loadMore={getNextPage} hasMoreItems={hasMoreItems}>
            <DataTable
              loading={isLoading}
              columns={columns}
              rows={items}
              setQuery={setQuery}
              orderDirection={query.orderDirection}
              orderField={query.orderField}
            />
          </ScrollableView>
        </div>

        <ImportLogs
          id={id}
          setId={setId}
          dialogProps={{
            open: isLogsDialogOpened,
            onClose: handleLogsDialogToggle,
          }}
        />
      </div>
    </div>
  );
};

export default ImportsModule;
