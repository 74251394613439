import { PayloadAction } from "@reduxjs/toolkit";
import { FormError } from "app/containers/types";
import set from "lodash/set";
import { createSlice } from "utils/@reduxjs/toolkit";
import {
  UserGroupState,
  UserPermission,
  UsersListItemType,
  defaultPermissionList,
} from "../types";
const initialState = UserGroupState;
export const usersGroupListSlice = createSlice({
  name: "usersGroupState",
  initialState,
  reducers: {
    editUser: (state, action: PayloadAction<UsersListItemType>) => {},
    removeUser: (state) => {
      const editUser: any = {};
      editUser.firstName = "";
      editUser.lastName = "";
      editUser.email = "";
      editUser.phoneNumber = "";
      editUser.creationDate = null;
      editUser.dateOfBirth = null;
    },
    create: (state, action) => {
      state.form.isLoading = true;
    },
    update: (state, action) => {
      state.form.isLoading = true;
    },
    createSuccess: (state) => {
      state.form.isLoading = false;
    },
    createFailed: (state, action) => {
      state.form.isLoading = false;
      state.loading = false;
    },
    getList: (state) => {
      state.loading = true;
      state.query.page = 1;
    },
    getDataById: (state, action: PayloadAction<{ id: any }>) => {
      state.form = initialState.form;
      state.loading = true;
    },
    getListSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      const items = action.payload.items;
      state.list.items =
        action.payload.meta.currentPage === 1
          ? items
          : [...state.list.items, ...items];
      state.list.meta = action.payload.meta;
    },
    getDataByIdSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.form = action.payload;
      if (!state.form.userGroupsPermissions.length) {
        state.form.userGroupsPermissions =
          initialState.form.userGroupsPermissions;
      }
      state.form.userGroupsPermissions.forEach((f) => {
        const defaultFeature = defaultPermissionList.find(
          (d) => d.featureName == f.featureName
        );

        f.disabledActions = defaultFeature
          ? defaultFeature.disabledActions
          : [];
      });
    },
    updatePermission: (
      state,
      action: PayloadAction<{ index: number; data: UserPermission }>
    ) => {
      state.form.userGroupsPermissions[action.payload.index] =
        action.payload.data;
    },
    getNextPage: (state, action: PayloadAction<any>) => {
      state.query.page = state.list.meta.currentPage + 1;
      state.loading = true;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    updateFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state.form, `${action.payload.key}`, action.payload.value);
    },
    updateSuccess(state, action: PayloadAction<UsersListItemType>) {
      state.loading = false;
    },
    updateFailed: (state, action) => {
      state.error = action.payload;
      state.form.isLoading = false;
    },
    delete: (state, payload) => {
      state.error = "";
      state.loading = true;
    },
    deleteSuccess: (state) => {
      state.loading = false;
      state.error = "";
    },
    deleteFailed: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    resetForm(state) {
      state.form = initialState.form;
    },
    setFormErrors(
      state,
      action: PayloadAction<{
        key: string;
        errors: FormError[];
      }>
    ) {
      action.payload.errors.forEach((error) => {
        set(
          state.form,
          `${action.payload.key}.${error.name}.error`,
          error.error
        );
      });
    },
    setQuery: (state, action: PayloadAction<{ name: string; value: any }>) => {
      const { name, value } = action.payload;
      set(state.query, name, value);
    },
    resetModule: (state) => {
      state = initialState;
    },
  },
});
export const { reducer, actions, name: sliceKey } = usersGroupListSlice;
