import { Grid } from "@mui/material";
import { BottomActions } from "app/components/BottomActions";
import { DownloadIconButton } from "app/components/Button/DownloadIconButton";
import DataTable from "app/components/DataTable";
import { CustomDrawer } from "app/components/Drawer";
import { NoPermission } from "app/components/NoPermission";
import { ScrollableView } from "app/components/ScrollableView";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectFeaturePermissions } from "store/global/authPermission/selector";
import { PermissionFeature } from "store/global/authPermission/types";
import { getFormattedDateTime } from "utils/helpers";
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import Topbar from "../Dashboard/components/topbar";
import FiltersForm from "./components/FiltersForm";
import DailyActivityAddModal from "./components/Form";
import { useUserSaga } from "./redux/saga";
import { selectList, selectLoading, selectQuery } from "./redux/selector";
import { actions, reducer, sliceKey } from "./redux/slice";
import { useStyles } from "./styles";
import { Item } from "./types";

const DailyActivity = () => {
  const listitems = useSelector(selectList);
  const { meta, items } = listitems;

  const { q, orderField, orderDirection, organizationId } =
    useSelector(selectQuery);

  const featurePermissions = useSelector(selectFeaturePermissions);
  const { hasFullAccess, hasReadAccess, hasNoAccess } =
    featurePermissions[PermissionFeature.DAILY_ACTIVITY];

  const styles = useStyles();
  useInjectReducer({ key: sliceKey, reducer });
  useInjectSaga({ key: sliceKey, saga: useUserSaga });

  const { t } = useTranslation();

  const columns = [
    {
      id: "organizationName",
      label: t("DAILY_ACTIVITY.ORGANIZATIONAL_NAME"),
      sortValue: "organization.name",
      minWidth: 200,
      maxWidth: 300,
      format: (value, index) => {
        return value?.organization?.name;
      },
    },
    {
      id: "activityName",
      label: t("DAILY_ACTIVITY.ACTIVITY_NAME"),
      sortValue: "selector.name",
      minWidth: 280,
      maxWidth: 420,
      format: (value, index) => {
        return value.name;
      },
    },
    {
      id: "description",
      label: t("DAILY_ACTIVITY.DESCRIPTION"),
      sortValue: "selector.description",
      minWidth: 340,
      maxWidth: 510,
    },
    {
      id: "videoFile",
      label: t("DAILY_ACTIVITY.VIDEO_FILE"),
      format: (val: Item) => {
        const resource = val.resources.find((f) => f.resourceType === "video");
        return <DownloadIconButton url={resource?.url} />;
      },
      minWidth: 120,
      maxWidth: 180,
    },
    {
      id: "audioFile",
      label: t("DAILY_ACTIVITY.AUDIO_FILE"),
      format: (val) => {
        const resource = val.resources.find((f) => f.resourceType === "audio");
        return <DownloadIconButton url={resource?.url} />;
      },
      minWidth: 120,
      maxWidth: 180,
    },
    {
      id: "pdfFile",
      label: t("DAILY_ACTIVITY.PDF_FILE"),
      format: (val) => {
        const resource = val.resources.find((f) => f.resourceType === "pdf");
        return <DownloadIconButton url={resource?.url} />;
      },
      minWidth: 120,
      maxWidth: 180,
    },
    {
      id: "time",
      label: t("DAILY_ACTIVITY.TIME"),
      sortValue: "selector.scheduledAt",
      minWidth: 120,
      maxWidth: 180,
      format: (value, index) => {
        return getFormattedDateTime(value?.scheduledAt, " HH:mm");
      },
    },
    {
      id: "sentAmount",
      label: t("DAILY_ACTIVITY.SENT_AMOUNT"),
      sortValue: "selector.sentAmount",
      minWidth: 120,
      maxWidth: 180,
    },
    {
      id: "watchedAmount",
      label: t("DAILY_ACTIVITY.WATCHED_AMOUNT"),
      sortValue: "selector.watchedAmount",
      minWidth: 140,
      maxWidth: 210,
    },
  ];

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.getList());
  }, [q, orderField, orderDirection, organizationId, dispatch]);

  const setPageQuery = (name: string, value: any) => {
    dispatch(actions.setQuery({ name, value }));
  };

  const loading = useSelector(selectLoading);

  const handleEdit = (e) => {
    dispatch(actions.toggleModal({ id: e.id }));
  };

  const hasMoreItems = meta.currentPage < meta.totalPages;
  const getNextPage = () => {
    if (!loading) {
      dispatch(actions.getNextPage(meta.currentPage + 1));
    }
  };

  const handleModal = () => {
    dispatch(actions.toggleModal({ id: 0 }));
  };
  const switchAction = (row, index) => {
    dispatch(actions.toggleStatus(row.id));
  };
  const { height } = useWindowDimensions();
  if (hasNoAccess) {
    return (
      <div className={styles.mainContainer}>
        <CustomDrawer />
        <Topbar />
        <NoPermission />
      </div>
    );
  }

  return (
    <div className={styles.mainContainer}>
      <CustomDrawer />
      <Grid item xs={12}>
        <Topbar q={q} setSearch={(q) => setPageQuery("q", q)} />
        <div className={styles.root}>
          <FiltersForm></FiltersForm>
          <>
            <ScrollableView
              loadMore={getNextPage}
              hasMoreItems={hasMoreItems}
              height={height - 280}
            >
              <DataTable
                loading={loading}
                orderDirection={orderDirection}
                orderField={orderField}
                setQuery={setPageQuery}
                rows={items}
                columns={columns}
                Actions={hasFullAccess ? "Edit,Toggle" : ""}
                editAction={handleEdit}
                switchAction={switchAction}
              />
            </ScrollableView>
          </>

          <BottomActions
            disabled={!hasFullAccess}
            chipBoxes={[
              {
                label: t("PLACEHOLDERS.TOTAL_ITEMS"),
                count: meta.totalItems,
              },
            ]}
            onClickFab={handleModal}
          />

          <div className={styles.btnDiv}>
            <DailyActivityAddModal />
          </div>
        </div>
      </Grid>
    </div>
  );
};
export default DailyActivity;
