import axios from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { API_URL } from "utils/constants";
import { TRANSLATE, getDefaultHeaders, safeParseJSON } from "utils/helpers";
import { request } from "utils/request";
import toastService from "utils/toast";
import { AddTreatmentItemType, TreatmentForm } from "../types";
import { selectEditTreatment } from "./selector";
import { actions } from "./slice";
export function* handleBlock(action) {
  try {
    const options = {
      method: "PATCH",
      headers: getDefaultHeaders(),
    };
    const response: AddTreatmentItemType = yield request(
      `${API_URL}/users/${action?.payload}`,
      options
    );
    yield put(actions.handleBlockSuccess(response));
    toastService.success(TRANSLATE("USERS.DATA_UPDATED_SUCCESSFULLY"));
  } catch (e: any) {
    const message = JSON.parse(e.message)?.message;
    yield put(actions.updateFailed(message));
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", { error: message })
    );
  }
}
export function* create(action) {
  try {
    const from: TreatmentForm = yield select(selectEditTreatment);
    const newTreatmentType = {
      name: from.treatmentName.value,
      session_duration: from.sessionLength.value,
      type: from.participantType.value,
      maximum_participants:
        from.participantType.value === "individual"
          ? 1
          : from.participants.value,
      description: from.treatmentDescription.value,
      session_count: from.noOfSession.value,
      treatmentQuestionnaire: action.payload.treatmentQuestionaire.map((x) => {
        return {
          id: x.id,
          at: x.beforeAfter,
          duration: x.hours,
          questionnaireId: x.questionnaireId,
          questionaireName: x.questionaireName,
          session_id: x.session_id,
        };
      }),
      treatmentContent: action.payload.treatmentContents.map((x) => {
        return {
          ...x,
          session_id: x.session_id,
          session_type: x.session.toLowerCase(),
          duration: x.hours,
          at: x.beforeAfter,
        };
      }),
    };
    const requestOptions = {
      method: "POST",
      headers: getDefaultHeaders(),
      body: JSON.stringify(newTreatmentType),
    };
    const url = `${API_URL}/v1/treatment`;
    const response = yield request(url, requestOptions);
    yield put(actions.createSuccess);
    toastService.success(
      TRANSLATE("TREATMENT_TYPES.TREATMENT_CREATED_SUCCESS")
    );
    action.payload.navigate("/treatment-types");
  } catch (e) {
    const error = e as Error;
    const { message } = safeParseJSON(error.message);
    yield put(actions.createFailed(message));
    toastService.error(TRANSLATE(message));
  }
}

export function* update(action) {
  try {
    const from: TreatmentForm = yield select(selectEditTreatment);
    if (from.treatmentName.value.length === 0) {
      throw Error('{"message":"TREATMENT_TYPES.TREATMENT_NAME_REQUIRED"}');
    }

    if (from.sessionLength.value.length === 0) {
      throw Error('{"message":"TREATMENT_TYPES.SESSION_LENGTH_REQUIRED"}');
    }

    if (from.participantType.value.length === 0) {
      throw Error('{"message":"TREATMENT_TYPES.PARTICIPANT_TYPE_REQUIRED"}');
    }

    if (from.noOfSession.value.length === 0) {
      throw Error('{"message":"TREATMENT_TYPES.NO_OF_SESSIONS_REQUIRED"}');
    }

    if (from.noOfSession.value > 10) {
      throw Error('{"message":"TREATMENT_TYPES.MAX_SESSIONS_EXCEEDED"}');
    }

    if (from.treatmentDescription.value.length === 0) {
      throw Error('{"message":"TREATMENT_TYPES.DESCRIPTION_REQUIRED"}');
    }

    if (from.participants.value.length === 0) {
      throw Error('{"message":"TREATMENT_TYPES.PARTICIPANTS_REQUIRED"}');
    }

    if (
      from.participantType.value === "group" &&
      from.participants.value <= 1
    ) {
      throw Error('{"message":"TREATMENT_TYPES.MIN_PARTICIPANTS_REQUIRED"}');
    }

    const { id, toUpdate, navigate } = action.payload;
    const url = `${API_URL}/v1/treatment/${id}`;
    const toUpdateObj = {
      name: from.treatmentName.value,
      session_duration: from.sessionLength.value,
      type: from.participantType.value,
      maximum_participants:
        from.participantType.value === "individual"
          ? 1
          : from.participants.value,
      description: from.treatmentDescription.value,
      session_count: from.noOfSession.value,
      treatmentQuestionnaire: action.payload.toUpdate.treatmentQuestionaire.map(
        (x) => {
          return {
            id: x.id,
            at: x.beforeAfter,
            duration: x.hours,
            questionnaireId: x.questionnaireId,
            questionaireName: x.questionaireName,
            session_id: x.session_id,
            type: x.type,
          };
        }
      ),
      treatmentContent: action.payload.toUpdate.treatmentContents.map((x) => {
        return {
          ...x,
          session_id: x.session_id,
          session_type: x.session.toLowerCase(),
          duration: x.hours,
          at: x.beforeAfter,
        };
      }),
    };
    const options = {
      method: "PATCH",
      headers: getDefaultHeaders(),
      body: JSON.stringify(toUpdateObj),
    };
    const response = yield request(url, options);

    navigate("/treatment-types");
    toastService.success(
      TRANSLATE("TREATMENT_TYPES.TREATMENT_UPDATED_SUCCESS")
    );
  } catch (e) {
    const error = e as Error;
    const { message } = safeParseJSON(error.message);
    yield put(actions.updateFailed(message));
    toastService.error(TRANSLATE(message));
  }
}

export function* editTreatmentContentRow(action) {
  try {
    const { id, treatmentId } = action.payload;
    const url = `${API_URL}/v1/treatment/content/${id}`;
    const options = {
      method: "PATCH",
      headers: getDefaultHeaders(),
      body: JSON.stringify(action.payload),
    };
    const response = yield request(url, options);
    // yield put(actions.getTreatmentContentList({ treatmentId }));
    toastService.success(
      TRANSLATE("TREATMENT_TYPES.TREATMENT_CONTENT_UPDATED_SUCCESS")
    );
  } catch (e) {
    console.log(e);
    toastService.error(
      TRANSLATE("TREATMENT_TYPES.ERROR_IN_UPDATING_TREATMENT_CONTENT")
    );
  }
}

export function* deleteTreatmentContentRow(action) {
  try {
    const { id, treatmentId } = action.payload;
    const url = `${API_URL}/v1/treatment/content/${id}`;
    const options = {
      method: "DELETE",
      headers: getDefaultHeaders(),
    };
    const response = yield request(url, options);
    yield put(actions.getTreatmentContentList({ treatmentId }));
    toastService.success(
      TRANSLATE("TREATMENT_TYPES.TREATMENT_CONTENT_DELETED_SUCCESS")
    );
  } catch (e) {
    console.log(e);
    yield put(actions.setLoading(false));
    toastService.error(
      TRANSLATE("TREATMENT_TYPES.ERROR_IN_DELETING_TREATMENT_CONTENT")
    );
  }
}

export function* deleteTreatmentQuestionnaireRow(action) {
  try {
    const { id, treatmentId } = action.payload;
    const url = `${API_URL}/v1/treatment/questionnaire/${id}`;
    const options = {
      method: "DELETE",
      headers: getDefaultHeaders(),
    };
    const response = yield request(url, options);
    yield put(actions.getTreatmentQuestionnaireList({ treatmentId }));
    toastService.success(
      TRANSLATE("TREATMENT_TYPES.TREATMENT_QUESTIONNAIRE_DELETED_SUCCESS")
    );
  } catch (e) {
    console.log(e);
    yield put(actions.setLoading(false));
    toastService.error(
      TRANSLATE("TREATMENT_TYPES.ERROR_IN_DELETING_TREATMENT_QUESTIONNAIRE")
    );
  }
}

export function* getTreatmentContentList(action) {
  try {
    const treatmentId = action.payload.treatmentId;
    const requestOptions = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    const response = yield request(
      `${API_URL}/v1/treatment/content/${treatmentId}?page=1&size=15`,
      requestOptions
    );
    yield put(actions.setTreatmentContentList(response));
  } catch (e) {
    console.log(e);
  }
}

export function* getTreatmentQuestionnaireList(action) {
  try {
    const treatmentId = action.payload.treatmentId;
    const requestOptions = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    const response = yield request(
      `${API_URL}/v1/treatment/questionnaire/${treatmentId}?page=1&size=15`,
      requestOptions
    );
    yield put(actions.setTreatmentQuestionnaireList(response));
  } catch (e: any) {
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", {
        error: JSON.stringify(e),
      })
    );
  }
}

function* addTreatmentContent(action) {
  try {
    const url = `${API_URL}/v1/treatment/content`;
    const requestOptions = {
      method: "POST",
      headers: getDefaultHeaders(),
      body: JSON.stringify(action.payload),
    };
    yield request(url, requestOptions);
    toastService.success(
      TRANSLATE("TREATMENT_TYPES.TREATMENT_CONTENT_CREATED_SUCCESS")
    );
  } catch (e) {
    console.log(e);
    toastService.error(
      TRANSLATE("TREATMENT_TYPES.ERROR_IN_CREATING_TREATMENT_CONTENT")
    );
  }
}

function* addTreatmentQuestionnaire(action) {
  try {
    const url = `${API_URL}/v1/treatment/questionnaire`;
    const requestOptions = {
      method: "POST",
      headers: getDefaultHeaders(),
      body: JSON.stringify(action.payload),
    };
    yield request(url, requestOptions);
    toastService.success(
      TRANSLATE("TREATMENT_TYPES.TREATMENT_QUESTIONNAIRE_CREATED_SUCCESS")
    );
  } catch (e) {
    console.log(e);
    toastService.error(
      TRANSLATE("TREATMENT_TYPES.ERROR_IN_CREATING_TREATMENT_QUESTIONNAIRE")
    );
  }
}

function* editQuestionnaireRow(action) {
  try {
    const { id, treatmentId } = action.payload;
    const url = `${API_URL}/v1/treatment/questionnaire/${id}`;

    const requestOptions = {
      method: "PATCH",
      headers: getDefaultHeaders(),
      body: JSON.stringify(action.payload),
    };

    const response = yield request(url, requestOptions);
    // yield put(actions.getTreatmentQuestionnaireList({ treatmentId }));
    toastService.success(
      TRANSLATE("TREATMENT_TYPES.TREATMENT_QUESTIONNAIRE_UPDATED_SUCCESS")
    );
  } catch (e) {
    console.log(e);
    toastService.error(
      TRANSLATE("TREATMENT_TYPES.ERROR_IN_UPDATING_TREATMENT_QUESTIONNAIRE")
    );
  }
}

export function* deleteRow(action) {
  try {
    const { id, navigate } = action.payload;
    const url = `${API_URL}/v1/treatment/${id}`;
    const options = {
      method: "DELETE",
      headers: getDefaultHeaders(),
    };
    const response = yield request(url, options);
    navigate("/treatment-types");
    toastService.success(
      TRANSLATE("TREATMENT_TYPES.TREATMENT_DELETED_SUCCESS")
    );
  } catch (e) {
    console.log(e);
    toastService.error(
      TRANSLATE("TREATMENT_TYPES.ERROR_IN_DELETING_TREATMENT")
    );
  }
}

export function* AddTreatmentQuestionnaireItem(action) {
  try {
    const { type } = action.payload;
    if (type == "add") {
      yield put(actions.setTreatmentQuestionnaireItem(action.payload));
    } else {
      yield put(actions.editTreatmentQuestionnaireItem(action.payload));
    }
    yield put(actions.resetQuestionnaireForm());

    yield put(actions.questionnaireModalToggal());
  } catch (e) {
    console.log(e);
    toastService.error(
      TRANSLATE("TREATMENT_TYPES.ERROR_IN_CREATING_TREATMENT_QUESTIONNAIRE")
    );
  }
}
export function* AddTreatmentContentItem(action) {
  try {
    const { type } = action.payload;

    if (type == "add") {
      yield put(actions.addTreatmentContentItem(action.payload));
    } else {
      yield put(actions.editTreatmentContentItem(action.payload));
    }
    yield put(actions.contentModalToggal());
    yield put(actions.resetContentForm());
  } catch (e) {
    console.log(e);
    toastService.error(
      TRANSLATE("TREATMENT_TYPES.ERROR_IN_CREATING_TREATMENT_CONTENT")
    );
  }
}

export function* UploadFile(action) {
  try {
    const keyToUpdateOnSuccess = action.payload.format;
    const file = action.payload.file;
    const { name: fileName, type: fileType, size: fileSize } = file;
    const reqData = {
      fileName: fileName,
      contentType: fileType,
    };
    const requestOptions = {
      method: "POST",
      headers: getDefaultHeaders(),
      body: JSON.stringify(reqData),
    };
    const response = yield request(
      `${API_URL}/v1/files/presigned-url`,
      requestOptions
    );
    const { signedUrl, fileNameWithPrefix, url } = response;
    const uploadResponse = yield call(axios.put, signedUrl, file, {
      headers: {
        "Content-Type": action.payload.type,
      },
    });
    toastService.success(
      TRANSLATE("TREATMENT_TYPES.FILE_UPLOADED_SUCCESSFULLY")
    );
    yield put(
      actions.setFileUploadSuccess({
        keyToUpdateOnSuccess,
        fileName,
        fileNameWithPrefix,
        fileSize,
        fileType,
        url,
      })
    );
  } catch (e) {
    console.log(e);
    toastService.error(TRANSLATE("TREATMENT_TYPES.ERROR_IN_UPLOADING_FILE"));
  }
}
export function* get(action) {
  try {
    const url = `${API_URL}/v1/treatment/get/${action.payload}`;
    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    const response = yield request(url, options);
    yield put(actions.getTretamentSuccess(response));
  } catch (e) {
    console.log(e);
  }
}
export function* useUserSaga() {
  yield takeLatest(actions.create.type, create);
  yield takeLatest(actions.update.type, update);
  yield takeLatest(actions.delete.type, deleteRow);
  yield takeLatest(actions.handleBlock.type, handleBlock);
  yield takeLatest(actions.addTreatmentContentRow.type, addTreatmentContent);
  yield takeLatest(
    actions.getTreatmentContentList.type,
    getTreatmentContentList
  );
  yield takeLatest(
    actions.editTreatmentContentRow.type,
    editTreatmentContentRow
  );

  yield takeLatest(
    actions.getTreatmentQuestionnaireList.type,
    getTreatmentQuestionnaireList
  );

  yield takeLatest(
    actions.addTreatmentQuestionnaireRow.type,
    addTreatmentQuestionnaire
  );
  yield takeLatest(
    actions.editTreatmentQuestionnaireRow.type,
    editQuestionnaireRow
  );

  yield takeLatest(
    actions.doAddTreatmentQuestionnaireItem.type,
    AddTreatmentQuestionnaireItem
  );
  yield takeLatest(
    actions.doaddTreatmentContentItem.type,
    AddTreatmentContentItem
  );
  yield takeLatest(actions.uploadFile.type, UploadFile);
  yield takeLatest(actions.getTretament.type, get);
}
