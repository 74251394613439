import { TComments } from "app/containers/Patients/types";
import { FormElement } from "app/containers/types";

export interface ResponseError {
  message: string;
}
export type RequestError = ResponseError;

export type OrganizationsListItemType = {
  id: number;
  name: string;
  taxNumber: string | null;
  contactName: string | null;
  mobileNumber: string | null;
  isLoading?: boolean;
  isActive?: boolean;
};

export interface NewUserRequest {
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
  companyName: string;
  designation: any;
  numberOfEmployees: string;
  user_type: string;
  isActive: any;
}

export type NewUserResponse = {
  id: any;
  codeSentAt: any;
  firstName: string;
  lastName: string;
  mobileNumber: string;
  countryCode: any;
  email: string;
  companyName: string;
  designation: string;
  numberOfEmployees: string;
  language: string;
  isActive: true;
  role: any;
  image: any;
  userType: string;
  createdAt: string;
  updatedAt: string;
  fullName: string;
  message?: string;
};

export type UserForm = {
  id: FormElement;
  name: FormElement;
  taxNumber: FormElement;
};

export type organizationsListStateType = {
  query: {
    limit: number;
    page: number;
    q: string;
    orderField: string;
    orderDirection: string;
  };
  form: {
    user: UserForm;
    comments: TComments[];
  };
  list: {
    items: Array<OrganizationsListItemType>;
    meta: {
      totalItems: number;
      itemCount: number;
      itemsPerPage: number;
      totalPages: number;
      currentPage: number;
    };
    links: {
      first: string;
      last: string;
      previous: string;
      next: string;
    };
  };
  loading: boolean;
  error: string;
};

export const UserState: organizationsListStateType = {
  query: {
    limit: 10,
    page: 1,
    q: "",
    orderField: "",
    orderDirection: "",
  },
  list: {
    items: [],
    meta: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 10,
      totalPages: 1,
      currentPage: 1,
    },
    links: {
      first: "",
      previous: "",
      next: "",
      last: "",
    },
  },
  loading: false,
  form: {
    user: {
      id: { value: "" },
      name: { value: "" },
      taxNumber: { value: "" },
    },
    comments: [],
  },
  error: "",
};

export type ContainerState = organizationsListStateType;
