import { CalendarMonth } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Grid, MenuItem, Select, TextField, useTheme } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import BankFiled from "app/components/FieldComponent/Bank";
import DepartmentFiled from "app/components/FieldComponent/Department";
import OrganizationField from "app/components/FieldComponent/Organization";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectQuery } from "../../redux/selector";
import { actions } from "../../redux/slice";
import { useStyles } from "./styles";
const FiltersForm = () => {
  const { t } = useTranslation();
  const styles = useStyles();
  const theme = useTheme();

  const dispatch = useDispatch();
  const {
    type,
    organizationId,
    departmentId,
    bankId,
    from,
    to,
    treatmentPhase,
  } = useSelector(selectQuery);

  const setPageQuery = (name: string, value: any) => {
    dispatch(actions.setQuery({ name, value }));
  };

  useEffect(() => {
    return () => {
      dispatch(actions.resetQuery());
    };
  }, [dispatch]);

  return (
    <div>
      <Grid sx={{ padding: "0px 5px 0px 5px" }}>
        <Grid container spacing={2} md={12} sx={{ paddingBottom: "10px" }}>
          <Grid item xs={2.5}>
            <OrganizationField
              value={organizationId}
              xs={11.2}
              changeValue={(value) => {
                setPageQuery("organizationId", value);
              }}
              showAll={true}
              activeRequired={true}
            ></OrganizationField>
          </Grid>
          <Grid item xs={2.5}>
            <DepartmentFiled
              xs={11.2}
              value={departmentId}
              changeValue={(value) => {
                setPageQuery("departmentId", value);
              }}
              orgId={organizationId}
              showAll={true}
              noReset={true}
            ></DepartmentFiled>
          </Grid>
          <Grid item xs={2}>
            <BankFiled
              value={bankId}
              xs={11.2}
              organizationId={organizationId}
              departmentId={departmentId}
              changeValue={(value) => {
                setPageQuery("bankId", value);
              }}
              showAll={true}
              noReset={true}
            ></BankFiled>
          </Grid>

          <Grid item xs={2}>
            <label className={styles.label}>
              {t("QUESTIONNAIRE_RESULT.FROM_DATE")}
            </label>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                className={styles.textEnabled}
                views={["day"]}
                value={from}
                minDate="01/01/1900"
                onChange={(newValue) => {
                  setPageQuery("from", newValue);
                }}
                OpenPickerButtonProps={{
                  style: { color: "#012397" },
                }}
                components={{
                  OpenPickerIcon: CalendarMonth,
                }}
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    margin="dense"
                    helperText={null}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "__/__/____",
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={2}>
            <label className={styles.label}>
              {t("QUESTIONNAIRE_RESULT.TO_DATE")}
            </label>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                className={styles.textEnabled}
                views={["day"]}
                value={to}
                minDate="01/01/1900"
                onChange={(newValue) => {
                  setPageQuery("to", newValue);
                }}
                OpenPickerButtonProps={{
                  style: { color: "#012397" },
                }}
                components={{
                  OpenPickerIcon: CalendarMonth,
                }}
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    margin="dense"
                    helperText={null}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "__/__/____",
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid
            item
            xs={2.5}
            style={{ paddingTop: "0px", paddingRight: "12px" }}
          >
            <label className={styles.label}>
              {t("QUESTIONNAIRE_RESULT.QUESTIONNAIRE_TYPE")}
            </label>
            <Select
              margin="dense"
              fullWidth
              variant="outlined"
              required
              autoComplete={"off"}
              value={type}
              className={styles.textEnabled}
              onChange={(e) => setPageQuery("type", e.target.value)}
              IconComponent={KeyboardArrowDownIcon}
              dir={theme.direction}
              MenuProps={{
                sx: {
                  dir: theme.direction,
                },
              }}
            >
              <MenuItem sx={{ direction: theme?.direction }} value={"ALL"}>
                {" "}
                {t("COMMON.ALL")}
              </MenuItem>
              <MenuItem sx={{ direction: theme?.direction }} value={"mcq"}>
                {t("COMMON.MULTI_CHOICE_QUESTIONNAIRE")}
              </MenuItem>
              <MenuItem sx={{ direction: theme?.direction }} value={"open"}>
                {t("COMMON.OPEN_QUESTIONNAIRE")}
              </MenuItem>
            </Select>
          </Grid>
          <Grid
            item
            xs={2.5}
            style={{ paddingTop: "0px", paddingRight: "12px" }}
          >
            <label className={styles.label}>
              {t("QUESTIONNAIRE_RESULT.TREATMENT_PHASE")}
            </label>
            <Select
              margin="dense"
              fullWidth
              variant="outlined"
              required
              autoComplete={"off"}
              value={treatmentPhase}
              className={styles.textEnabled}
              onChange={(e) => setPageQuery("treatmentPhase", e.target.value)}
              IconComponent={KeyboardArrowDownIcon}
              dir={theme.direction}
              MenuProps={{
                sx: {
                  dir: theme.direction,
                },
              }}
            >
              <MenuItem sx={{ direction: theme?.direction }} value={"ALL"}>
                {" "}
                {t("COMMON.ALL")}
              </MenuItem>
              <MenuItem sx={{ direction: theme?.direction }} value={"general"}>
                {t("QUESTIONNAIRE_RESULT.TREATMENT_PHASE_GENERAL")}
              </MenuItem>
              <MenuItem sx={{ direction: theme?.direction }} value={"t0"}>
                {t("QUESTIONNAIRE_RESULT.TREATMENT_PHASE_BEFORE")}
              </MenuItem>
              <MenuItem sx={{ direction: theme?.direction }} value={"t1"}>
                {t("QUESTIONNAIRE_RESULT.TREATMENT_PHASE_AFTER")}
              </MenuItem>
            </Select>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default FiltersForm;
