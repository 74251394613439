import {
  Box,
  Card,
  FormHelperText,
  Grid,
  Modal,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { ActionButtons } from "app/components/Button/ActionButtons";
import OrganizationField from "app/components/FieldComponent/Organization";
import { DateTime } from "luxon";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  selectDailyActivityId,
  selectForm,
  selectLoading,
} from "../../redux/selector";
import { executeErrorValidation } from "../../redux/validator";
import {
  DailyActivityState,
  ResourcesTypes,
  UploadedResource,
} from "../../types";
import { UploadField } from "../UploadField";
import { actions } from "./../../redux/slice";
import { useStyles } from "./styles";

type Props = {};

const DailyActivityAddModal = (props: Props) => {
  const form = useSelector(selectForm);
  const isLoading = useSelector(selectLoading);
  const styles = useStyles();
  const direction = useTheme().direction;

  const { t, i18n } = useTranslation();

  const dailyActivityId = useSelector(selectDailyActivityId);

  const openModal = Number(dailyActivityId) > -1;

  const isEdit = Number(dailyActivityId) > 0;

  useEffect(() => {
    dispatch(actions.resetForm());
    if (dailyActivityId) {
      dispatch(actions.getById({ id: dailyActivityId }));
    }
  }, [dailyActivityId]);

  function onUploadSuccess(resource: UploadedResource) {
    dispatch(actions.updateFormUploadValue({ resource }));
  }
  const dispatch = useDispatch();

  const handleDailyActivitySave = () => {
    const errors = executeErrorValidation(form);
    if (errors.length) {
      dispatch(actions.setFormErrors({ errors }));
    } else {
      dispatch(actions.createOrUpdate({ form: form }));
    }
  };

  const handleModalClose = () => {
    dispatch(actions.toggleModal({ id: undefined }));
  };

  const onChange = (key: string, value: string | number) => {
    dispatch(actions.setFormErrors({ errors: [{ name: key, error: "" }] }));
    dispatch(actions.updateFormValue({ key: key, value: value }));
  };

  return (
    <Modal
      open={openModal}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card
        className={styles.addModal}
        sx={{
          direction,
        }}
      >
        <div className={styles.modalContainer}>
          <Grid>
            <Typography align="center" className={styles.headerModal}>
              {isEdit
                ? t("DAILY_ACTIVITY.EDIT_DAILY_ACTIVITY")
                : t("DAILY_ACTIVITY.ADD_DAILY_ACTIVITY")}
            </Typography>
          </Grid>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{ padding: "20px 0px" }}
          >
            <OrganizationField
              value={form.organizationId.value}
              xs={6}
              changeValue={(value) => {
                onChange("organizationId", value);
              }}
              disabled={isLoading}
              showAll={false}
              activeRequired={true}
              error={form.organizationId.error}
            ></OrganizationField>

            <Grid item xs={6}>
              <label className={styles.label}>
                {t("DAILY_ACTIVITY.DAILY_ACTIVITY_NAME")}
              </label>
              <TextField
                variant="outlined"
                margin="dense"
                required
                fullWidth
                id="name"
                placeholder={t("DAILY_ACTIVITY.ENTER_NAME")}
                name="name"
                autoComplete={"off"}
                onChange={(e) => {
                  const value = e.target.value;
                  onChange("name", value);
                }}
                disabled={isLoading}
                value={form.name.value}
                className={styles.textEnabled}
                helperText={form.name.error}
                error={!!form.name.error}
                FormHelperTextProps={{
                  style: {
                    backgroundColor: "#F5F8FF",
                    margin: "0px",
                    padding: "0px",
                    textAlign: i18n.dir() === "ltr" ? "left" : "right",
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <label className={styles.label}>
                #{t("DAILY_ACTIVITY.DAY_OF_ACTIVATION")}
              </label>
              <TextField
                variant="outlined"
                margin="dense"
                required
                fullWidth
                disabled={isLoading}
                id="activationDayNumber"
                placeholder={t("DAILY_ACTIVITY.ENTER_NUMBER")}
                name="activationDayNumber"
                autoComplete={"off"}
                onChange={(e) => {
                  const value = e.target.value;
                  const regex = /^[0-9\b]+$/;
                  const data = regex.test(value)
                    ? value
                    : value.replace(/[^0-9]/g, "");

                  onChange("activationDayNumber", data);
                }}
                value={form.activationDayNumber.value}
                className={styles.textEnabled}
                helperText={form.activationDayNumber.error}
                error={!!form.activationDayNumber.error}
                FormHelperTextProps={{
                  style: {
                    backgroundColor: "#F5F8FF",
                    margin: "0px",
                    padding: "0px",
                    textAlign: i18n.dir() === "ltr" ? "left" : "right",
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <label className={styles.label}>{t("DAILY_ACTIVITY.TIME")}</label>
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <TimePicker
                  className={styles.textEnabled}
                  onChange={(d: any) => {
                    let date = DateTime.fromISO(d);
                    if (date.isValid) {
                      onChange("scheduledAt", date.toJSDate().toISOString());
                    }
                  }}
                  value={
                    form.scheduledAt.value
                      ? new Date(form.scheduledAt.value)
                      : null
                  }
                  ampm={false}
                  disabled={isLoading}
                  renderInput={(params) => (
                    <TextField
                      helperText={form.scheduledAt.error}
                      error={!!form.scheduledAt.error}
                      FormHelperTextProps={{
                        style: {
                          backgroundColor: "#F5F8FF",
                          margin: "0px",
                          padding: "0px",
                          textAlign: i18n.dir() === "ltr" ? "left" : "right",
                        },
                      }}
                      fullWidth
                      margin="dense"
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <label className={styles.label}>
                {t("DAILY_ACTIVITY.DAILY_ACTIVITY_DESC")}
              </label>
              <TextField
                id="outlined-multiline-static"
                multiline
                name={"description"}
                rows={2}
                placeholder={t("DAILY_ACTIVITY.ENTER_DESCRIPTION")}
                fullWidth
                disabled={isLoading}
                onChange={(e) => {
                  const value = e.target.value;
                  onChange("description", value);
                }}
                value={form.description.value}
                className={styles.textEnabled}
                helperText={form.description.error}
                error={form.description.error?.length ? true : false}
                FormHelperTextProps={{
                  style: {
                    backgroundColor: "#F5F8FF",
                    margin: "0px",
                    padding: "0px",
                    textAlign: i18n.dir() === "ltr" ? "left" : "right",
                  },
                }}
              />
            </Grid>
            <Grid className={styles.uploadsContainer}>
              {DailyActivityState.form.resources.map((m) => {
                const resource = form.resources
                  .filter((f) => !f.isDeleted)
                  .find((f) => f.resourceType == m.resourceType);
                if (resource) {
                  return (
                    <UploadField
                      onUpload={onUploadSuccess}
                      supportedFile={ResourcesTypes[resource.resourceType]}
                      resource={resource}
                    />
                  );
                } else {
                  return (
                    <UploadField
                      onUpload={onUploadSuccess}
                      supportedFile={ResourcesTypes[m.resourceType]}
                      resource={m}
                    />
                  );
                }
              })}
            </Grid>
            <Box
              sx={{
                direction: i18n.dir(),
              }}
            >
              {form.resourceError?.error ? (
                <FormHelperText className={styles.uploadsError} error>
                  {form.resourceError?.error}
                </FormHelperText>
              ) : null}
            </Box>
          </Grid>
          <Grid container item xs={12} justifyContent={"center"}>
            <ActionButtons
              loading={form.isLoading}
              onCancel={handleModalClose}
              onSave={handleDailyActivitySave}
              disabled={isLoading}
            />
          </Grid>
        </div>
      </Card>
    </Modal>
  );
};
export default DailyActivityAddModal;
