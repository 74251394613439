import { Add } from "@mui/icons-material";
import { Fab, Grid } from "@mui/material";
import DataTable from "app/components/DataTable";
import { TabPanel } from "app/components/TabPanel";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { selectFeaturePermissions } from "store/global/authPermission/selector";
import { PermissionFeature } from "store/global/authPermission/types";
import { MAX_LIMIT, TrimFileName, formatBytes } from "utils/helpers";
import toastService from "utils/toast";
import audio from "../../assets/audio.png";
import pdf from "../../assets/pdf.svg";
import video from "../../assets/video.png";
import AddTreatmentContent from "../../components/AddTreatmentContent";
import {
  selectEditTreatment,
  selectList,
  selectLoading,
  selectQuery,
  selectcontentModal,
} from "../../redux/selector";
import { actions } from "../../redux/slice";

type Props = {
  mode: "new" | "edit" | "view";
  value: number;
  disable: boolean;
  disabledOnAssociation: boolean;
};
const TreatmentList = ({
  mode,
  value,
  disable,
  disabledOnAssociation,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [onRow, setOnRow] = useState(true);
  const [edit, setEdit] = useState(mode);
  const featurePermissions = useSelector(selectFeaturePermissions);
  const { hasFullAccess, hasReadAccess, hasNoAccess } =
    featurePermissions[PermissionFeature.TREATMENT_TYPES];

  const [editIndex, setEditIndex] = useState(0);
  const listitems = useSelector(selectList);
  const contentModal = useSelector(selectcontentModal);

  const { treatment } = listitems;

  const { orderField, orderDirection } = useSelector(selectQuery);

  const form = useSelector(selectEditTreatment);

  const setPageQuery = (name: string, value: any) => {
    dispatch(actions.setQuery({ name, value }));
  };
  const columns = [
    {
      id: "name",
      label: t("TREATMENT_TYPES.NAME"),
      sortValue: "selector.name",
      align: "left",
    },
    {
      id: "beforeAfter",
      label: t("TREATMENT_TYPES.BEFORE_AFTER"),
      sortValue: "selector.beforeafter",
      align: "left",
    },
    {
      id: "hours",
      label: t("TREATMENT_TYPES.HOURS"),
      sortValue: "selector.hours",
      align: "left",
    },
    {
      id: "videoFile",
      label: t("TREATMENT_TYPES.VIDEO_FILE"),
      sortValue: "selector.videoFile",
      align: "left",
      format: (val) => {
        if (!val?.videoFile?.url) {
          return "-";
        }
        return (
          <div>
            <img src={video} />
            <div style={{ display: "inline", marginLeft: "5px" }}>
              <a href={val.videoFile.url} target="_blank" rel="noreferrer">
                {val.videoFile.name ? TrimFileName(val.videoFile.name) : ""}
              </a>
              <span
                style={{
                  display: "block",
                  marginLeft: "25px",
                  fontSize: "13px",
                  color: "grey",
                  marginTop: "-5px",
                }}
              >
                {val.videoFile.size ? formatBytes(val.videoFile.size) : ""}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      id: "audioFile",
      label: t("TREATMENT_TYPES.AUDIO_FILE"),
      sortValue: "selector.audioFile",
      align: "left",
      format: (val) => {
        if (!val?.audioFile?.url) {
          return "-";
        }
        return (
          <div>
            <img src={audio} />
            <div style={{ display: "inline", marginLeft: "5px" }}>
              <a href={val.audioFile.url} target="_blank" rel="noreferrer">
                {val.audioFile.name ? TrimFileName(val.audioFile.name) : ""}
              </a>
              <span
                style={{
                  display: "block",
                  marginLeft: "25px",
                  fontSize: "13px",
                  color: "grey",
                  marginTop: "-5px",
                }}
              >
                {val.audioFile.size ? formatBytes(val.audioFile.size) : ""}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      id: "pdfFile",
      label: t("TREATMENT_TYPES.PDF_FILE"),
      sortValue: "selector.pdfFile",
      align: "left",
      format: (val) => {
        if (!val?.pdfFile?.url) {
          return "-";
        }
        return (
          <div>
            <img src={pdf} />
            <div style={{ display: "inline", marginLeft: "5px" }}>
              <a href={val.pdfFile.url} target="_blank" rel="noreferrer">
                {val.pdfFile.name ? TrimFileName(val.pdfFile.name) : ""}
              </a>
              <span
                style={{
                  display: "block",
                  marginLeft: "25px",
                  fontSize: "13px",
                  color: "grey",
                  marginTop: "-5px",
                }}
              >
                {val.pdfFile.size ? formatBytes(val.pdfFile.size) : ""}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      id: "description",
      label: t("TREATMENT_TYPES.DESCRIPTION"),
      sortValue: "selector.description",
      align: "left",
    },
    {
      id: "session_id",
      label: t("TREATMENT_TYPES.SESSION"),
      sortValue: "selector.session_id",
      align: "left",
    },
  ];

  const dispatch = useDispatch();
  const loading = useSelector(selectLoading);

  const handleModalContent = () => {
    setEdit("new");
    if (treatment.length < 10) {
      setOnRow(false);
      dispatch(actions.contentModalToggal());
    } else {
      toastService.error("Only 10 Treatment Content Allowed ");
    }
  };
  const handleModalContentClose = () => {
    dispatch(actions.contentModalToggal());
    dispatch(actions.resetContentForm());
  };

  function handleTreatmentContentDeletion(row, index) {
    if (mode !== "view") {
      const { id, name } = row;
      handleDeleteClose("delete", "", id, index);
    }
  }

  const handleDeleteClose = (
    eventType,
    type?: string,
    id?: number,
    index?: number
  ) => {
    if (eventType === "delete") {
      if (id == 0) {
        dispatch(actions.deleteTreatmentContentItem(index));
      } else {
        dispatch(
          actions.deleteTreatmentContentRow({
            id: id,
            treatmentId: id,
          })
        );
      }
    }
  };
  const rowDoubleAction = (e, index) => {
    let resouse = ["pdfFile", "audioFile", "videoFile"];
    setEdit("edit");
    for (let i in e) {
      if (resouse.indexOf(i) !== -1) {
        dispatch(
          actions.updateFormResourseValue({ key: `content.${i}`, value: e[i] })
        );
      } else {
        dispatch(actions.updateFormValue({ key: `content.${i}`, value: e[i] }));
      }
    }

    setOnRow(true);
    setEditIndex(index);
    navigate(`/treatment-types/${id}/edit`);
    dispatch(actions.contentModalToggal());
  };

  return (
    <>
      <TabPanel value={value} index={0}>
        <Grid container spacing={0} md={12} sx={{ paddingTop: "0px" }}>
          <Grid item xs={12}>
            <DataTable
              loading={loading}
              orderDirection={orderDirection}
              orderField={orderField}
              setQuery={setPageQuery}
              rows={treatment.filter((x) => x.type !== "delete")}
              columns={columns}
              Actions={hasFullAccess ? "Delete,Edit" : ""}
              editAction={rowDoubleAction}
              deleteAction={handleTreatmentContentDeletion}
              disableActions={
                disable || disabledOnAssociation ? "Delete,Edit" : ""
              }
            />
          </Grid>
        </Grid>
        {treatment?.length < MAX_LIMIT && (
          <Grid
            xs={12}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: "7px",
            }}
          >
            <Fab
              size="medium"
              color="secondary"
              aria-label="new"
              sx={{ marginTop: 2 }}
              disabled={disable}
              onClick={handleModalContent}
            >
              <Add />
            </Fab>
          </Grid>
        )}
      </TabPanel>
      <AddTreatmentContent
        treatmentId={id}
        openModal={contentModal}
        handleModalClose={handleModalContentClose}
        disable={disable}
        mode={edit}
        noofSession={form.noOfSession.value}
        editIndex={editIndex}
      ></AddTreatmentContent>
    </>
  );
};
export default TreatmentList;
