import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Grid, MenuItem, Select, useTheme } from "@mui/material";
import OrganizationField from "app/components/FieldComponent/Organization";
import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectQuery } from "../../redux/selector";
import { actions } from "../../redux/slice";
import { useStyles } from "./styles";

interface Props {
  mode: string;
}

export enum MessageType {
  ALL = "All",
  PRIOR_TREATMENT = "Prior Treatment Content",
  POST_TREATMENT = "Post Treatment Content",
  TREATMENT_PASSED = "Treatment Passed",
  TREATMENT_REMINDER = "Treatment Reminder",
  PRIOR_QUESTIONNAIRE = "Prior Questionnaires",
  POST_QUESTIONNAIRE = "Post Questionnaires",
  DAILY_ACTIVITY = "Daily Activity",
  TIME_SLOT_SCHEDULING = "Time Slot Scheduling",
  TREATMENT_SCHEDULED = "Treatment Scheduled",
  CUSTOM_PUSH = "Custom Push",
}

const NotificationFilters = [
  {
    id: 1,
    name: "All",
    value: MessageType.ALL,
  },
  {
    id: 2,
    name: "Custom Push",
    value: MessageType.CUSTOM_PUSH,
  },
  {
    id: 3,
    name: "Prior Treatment Content",
    value: MessageType.PRIOR_TREATMENT,
  },
  {
    id: 4,
    name: "Post treatment Content",
    value: MessageType.POST_TREATMENT,
  },
  {
    id: 5,
    name: "Prior Questionnaires",
    value: MessageType.PRIOR_QUESTIONNAIRE,
  },
  {
    id: 6,
    name: "Post Questionnaires",
    value: MessageType.POST_QUESTIONNAIRE,
  },
  {
    id: 7,
    name: "Daily Activity",
    value: MessageType.DAILY_ACTIVITY,
  },

  {
    id: 9,
    name: "Time Slot Scheduling",
    value: MessageType.TIME_SLOT_SCHEDULING,
  },
  {
    id: 10,
    name: "Treatment Scheduled",
    value: MessageType.TREATMENT_SCHEDULED,
  },
  {
    id: 10,
    name: "Treatment Reminder",
    value: MessageType.TREATMENT_REMINDER,
  },
  {
    id: 10,
    name: "Treatment Passed",
    value: MessageType.TREATMENT_PASSED,
  },
];

const FiltersForm = () => {
  const styles = useStyles();

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const form = useSelector(selectQuery);

  const direction = useTheme().direction;

  const setPageQuery = (name: string, value: any) => {
    dispatch(actions.setQuery({ name, value }));
  };

  useEffect(() => {
    return () => {
      dispatch(actions.resetQuery());
    };
  }, [dispatch]);

  return (
    <div>
      <Grid sx={{ padding: "20px 0px 0px 0px" }}>
        <Grid
          container
          spacing={1}
          md={12}
          sx={{
            padding: "10px 0px 10px 0px",
          }}
        >
          <Grid item xs={2.4}>
            <OrganizationField
              value={form.organizationId}
              xs={11.2}
              changeValue={(value) => {
                setPageQuery("organizationId", value);
              }}
              showAll={true}
              activeRequired={false}
            ></OrganizationField>
          </Grid>
          <Grid item xs={2.4}>
            <label className={styles.label}>
              {t("NOTIFICATIONS.MESSAGE_TYPE")}
            </label>
            <Select
              variant="outlined"
              fullWidth
              margin="dense"
              required
              autoComplete={"off"}
              value={form.messageType || ""}
              className={styles.textEnabled}
              onChange={(e) => {
                setPageQuery("messageType", e.target.value);
              }}
              IconComponent={KeyboardArrowDownIcon}
              inputProps={{
                name: "message-type-selection",
              }}
            >
              {NotificationFilters.map((item) => {
                return (
                  <MenuItem sx={{ direction }} key={item.id} value={item.value}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default FiltersForm;
