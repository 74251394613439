/**
 * Homepage selectors
 */

import { RootState } from "types";

import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./slice";

// TODO: Add an explanation for this
const selectDomain = (state: RootState) => state.profileState || initialState;

export const selectFirstName = createSelector(
  [selectDomain],
  (profileState) => profileState.firstName
);
export const selectLastName = createSelector(
  [selectDomain],
  (profileState) => profileState.lastName
);

export const selectPhoneNumber = createSelector(
  [selectDomain],
  (profileState) => profileState.mobileNumber
);

export const selectEmail = createSelector(
  [selectDomain],
  (profileState) => profileState.email
);

export const selectLanguage = createSelector(
  [selectDomain],
  (profileState) => profileState.language
);

export const selectProfile = createSelector(
  [selectDomain],
  (profileState) => profileState
);

export const selectIsLoading = createSelector(
  [selectDomain],
  (profileState) => profileState.isLoading
);
