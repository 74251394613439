import { PayloadAction } from "@reduxjs/toolkit";
import set from "lodash/set";
import { createSlice } from "utils/@reduxjs/toolkit";
import {
  AssignedTreatmentState,
  AvailableTimeslotsResponse,
  CheckboxPayload,
  Instructor,
  TreatmentTypeModal,
} from "../types";

const initialState = AssignedTreatmentState;

export const AssignedTreatmentBankSlice = createSlice({
  name: "assignedTreatmentState",
  initialState,
  reducers: {
    setQuery: (state, action: PayloadAction<{ name: string; value: any }>) => {
      const { name, value } = action.payload;
      state.query.page = 1;
      state.list = initialState.list;
      set(state.query, name, value);
    },
    resetPagination(state) {
      state.loading = true;
      state.query.page = 1;
    },
    getList: (state, action: PayloadAction<{ patientId?: any }>) => {
      state.loading = true;
      if (action.payload.patientId > 0) {
        state.list.items = [];
        state.query.page = 1;
      }
    },
    assignInstructorsSuccess: (state) => {
      state.list.selectedIds = [];
    },

    getUserById: (state, action: PayloadAction<{ id: any }>) => {
      state.loading = true;
    },
    editCheckBox: (state, action: PayloadAction<CheckboxPayload>) => {
      // find item
      const item = state.list.items.find(
        (item) => item.id === action.payload.id
      );
      if (item) {
        item.treatmentBankTreatment[action.payload.key] = action.payload.value;
      }
      // update item in state list
      const index = state.list.items.findIndex(
        (item) => item.id === action.payload.id
      );
      if (index !== -1) {
        // @ts-ignore
        state.list.items[index] = item;
      }
    },
    addToSelectedIds: (state, action: PayloadAction<number>) => {
      // if id is not in selectedIds, add it
      if (!state?.list?.selectedIds.includes(action.payload)) {
        state?.list?.selectedIds.push(action.payload);
      } else {
        // if id is in selectedIds, remove it
        state.list.selectedIds = state.list.selectedIds.filter(
          (id) => id !== action.payload
        );
      }
    },
    editInstructorId: (
      state,
      action: PayloadAction<{ row: number; value: string }>
    ) => {
      const item = state.list.items?.find(
        (item) => item.id === action.payload.row
      );
      if (item) {
        item.instructorId = action.payload.value;
      }
      const index = state.list.items.findIndex(
        (item) => item.id === action.payload.row
      );
      if (index !== -1) {
        // @ts-ignore
        state.list.items[index] = item;
      }
    },
    getListSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      const items = action.payload.items;
      state.list.items =
        action.payload.meta.currentPage === 1
          ? items
          : [...state.list.items, ...items];
      state.list.meta = action.payload.meta;
    },
    getListFailure: (state) => {
      state.loading = false;
    },
    getNextPage: (state, action: PayloadAction<any>) => {
      state.query.page = state.list.meta.currentPage + 1;
      state.loading = true;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    openSelectedTreatMent(state, action: PayloadAction<TreatmentTypeModal>) {
      state.selectedTreatmentIndex = action.payload.index;
      state.timeSlotModal = true;
      state.selectedTreatment = state.list.items[action.payload.index];
      state.availableTimeSlots = undefined;
    },

    openSelectedTreatMentSuccess: (
      state,
      action: PayloadAction<AvailableTimeslotsResponse>
    ) => {
      state.availableTimeSlots = action.payload;
      state.availableTimeSlots.timeslots = [];
      state.loading = false;
    },

    handleChangeInstructor: (
      state,
      action: PayloadAction<{ instructorId: number }>
    ) => {
      if (state.availableTimeSlots) {
        state.availableTimeSlots.treatmentInvolvedPatient.instructorId =
          action.payload.instructorId;
      }
    },
    getInstructorTimeslots(
      state,
      _action: PayloadAction<{ instructorId: number }>
    ) {
      if (state.availableTimeSlots) {
        state.availableTimeSlots.timeslots = [];
        state.availableTimeSlots.timeslotsLoading = true;
      }
    },

    getInstructorTimeslotsSuccess(
      state,
      action: PayloadAction<AvailableTimeslotsResponse>
    ) {
      if (state.availableTimeSlots) {
        state.availableTimeSlots.timeslots = action.payload.timeslots;
        state.availableTimeSlots.timeslotsLoading = false;
      }
    },

    handleAddTreatment: (state) => {
      state.availableTimeSlots?.treatmentInvolvedPatient.timeslotInvolvedPatients.push(
        {
          timeSlotId: null,
          type: "Regular",
          option: "new",
          patientId:
            state.availableTimeSlots.treatmentInvolvedPatient.patientId,
          treatmentInvolvedPatientId:
            state.availableTimeSlots.treatmentInvolvedPatient.id,
          assignedTreatments: [],
        } as any
      );
    },
    handleRemoveTreatment: (
      state,
      action: PayloadAction<{ index: number }>
    ) => {
      const timeslotInvolvedPatients =
        state.availableTimeSlots?.treatmentInvolvedPatient.timeslotInvolvedPatients.filter(
          (m) => m.option !== "delete"
        );
      if (timeslotInvolvedPatients) {
        const timeslotInvolvedPatient =
          timeslotInvolvedPatients[action.payload.index];
        if (!timeslotInvolvedPatient?.id) {
          state.availableTimeSlots?.treatmentInvolvedPatient.timeslotInvolvedPatients.splice(
            action.payload.index,
            1
          );
        } else {
          timeslotInvolvedPatient.option = "delete";
        }
      }
    },

    handleOnSelectTimeslot: (
      state,
      action: PayloadAction<{ index: number; timeslotId: number }>
    ) => {
      const timeslotInvolvedPatient =
        state.availableTimeSlots?.treatmentInvolvedPatient
          .timeslotInvolvedPatients[action.payload.index];
      if (timeslotInvolvedPatient) {
        timeslotInvolvedPatient.newTimeslotId = action.payload.timeslotId;
        timeslotInvolvedPatient.currentTimeslotId =
          timeslotInvolvedPatient.timeSlotId;
        timeslotInvolvedPatient.timeSlotId = undefined;
        timeslotInvolvedPatient.timeslotSessionId = undefined;
      }
    },
    handleOnSelectTimeslotSession: (
      state,
      action: PayloadAction<{
        index: number;
        timeslotSessionId: number;
        timeSlotId: number;
      }>
    ) => {
      const timeslotInvolvedPatient =
        state.availableTimeSlots?.treatmentInvolvedPatient
          .timeslotInvolvedPatients[action.payload.index];
      if (timeslotInvolvedPatient) {
        timeslotInvolvedPatient.newTimeslotId = action.payload.timeSlotId;
        // id we are selecting new date this is still empty
        if (timeslotInvolvedPatient.timeSlotId) {
          timeslotInvolvedPatient.currentTimeslotId =
            timeslotInvolvedPatient.timeSlotId;
        }
        timeslotInvolvedPatient.timeslotSessionId =
          action.payload.timeslotSessionId;
      }
    },

    openSelectedTreatMentFailure: (state) => {
      state.loading = false;
    },
    setTimeSlotModal(state) {
      state.timeSlotModal = !state.timeSlotModal;
    },
    setTimeSlot(state) {
      if (state.availableTimeSlots) {
        state.availableTimeSlots.isSaving = true;
      }
    },
    setTimeSlotFailed(state) {
      if (state.availableTimeSlots) {
        state.availableTimeSlots.isSaving = false;
      }
    },
    setTimeSlotSuccess(
      state,
      action: PayloadAction<{
        id: number;
      }>
    ) {
      state.timeSlotModal = false;
      state.selectedTreatment = undefined;
      state.availableTimeSlots = undefined;
    },
    assignInstructors(
      state,
      action: PayloadAction<{
        treatmentInvolvedPatientIds: string;
        selectedInstructorId: string;
        patientId: number;
      }>
    ) {
      state.loading = true;
    },
    assignInstructorsComplete(state) {
      state.loading = false;
    },
    getAssignedInstructors(state, action: PayloadAction<string>) {
      //state.loading = true;
    },
    getAssignedInstructorsSuccess(state, action: PayloadAction<Instructor[]>) {
      state.loading = false;
      state.assignedInstructors = action.payload;
    },
    getAssignedInstructorsFailure(state) {
      state.loading = false;
    },
    resetQuery: (state) => {
      state.query = initialState.query;
    },
  },
});
export const { reducer, actions, name: sliceKey } = AssignedTreatmentBankSlice;
