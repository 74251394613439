import { Add, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Fab,
  FormHelperText,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import DataTable from "app/components/DataTable";
import { Modal } from "app/components/Modal";
import { validateCurrentRange } from "app/containers/Questionnaires/multi-questioner-form.validator";
import {
  RangeListItemType,
  newRangeDefaultValues,
} from "app/containers/Questionnaires/types";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import toastService from "utils/toast";
import {
  selectCurrentGrade,
  selectLoading,
} from "../../../../../../redux/selector";
import { actions } from "../../../../../../redux/slice";
import { TableField } from "../../../TableField";
import { useStyles } from "./styles";
type Props = {
  mode: string;
  displayGradeRange: { min: number; max: number } | undefined;
};
const RangeForm = ({ mode, displayGradeRange }: Props) => {
  const dispatch = useDispatch();

  const grade = useSelector(selectCurrentGrade);

  const openRange = Boolean(grade?.currentRange);

  const setFormValue = (name: string, value: string | boolean) => {
    const nameValue = `currentRange.${name}`;
    dispatch(actions.setCurrentGradeValue({ name: nameValue, value }));
  };

  const { t, i18n } = useTranslation();

  const styles = useStyles();

  const loading = useSelector(selectLoading);
  const direction = useTheme().direction;

  const handleRangeSave = () => {
    if (grade?.currentRange) {
      const errors = validateCurrentRange(grade.currentRange);
      if (errors.length) {
        dispatch(
          actions.setFormErrors({ key: "currentGrade.currentRange.", errors })
        );
        return toastService.error(
          t("QUESTIONNAIRES.FORM.ERRORS.FIX_ERRORS_FIRST")
        );
      }
      dispatch(actions.editRange({ range: grade.currentRange }));
      handleRangeClose();
    }
  };

  const handleRangeClose = () => {
    dispatch(actions.setCurrentRange({ range: undefined }));
  };

  const handleRangeOpen = (index) => {
    const range = grade?.ranges[index];
    if (range) {
      dispatch(actions.setCurrentRange({ range: { ...range, index } }));
    } else {
      dispatch(actions.setCurrentRange({ range: newRangeDefaultValues }));
    }
  };

  const rangeColumns = [
    {
      id: "action",
      label: t("TABLES.ACTIONS"),
      sortValue: "selector.action",
      format: (val: RangeListItemType, index) => {
        return (
          <Tooltip title={t("TOOLTIP.EDIT")}>
            <IconButton
              disabled={mode === "view"}
              onClick={(e) => {
                handleRangeOpen(index);
              }}
            >
              <Edit
                sx={{
                  color: mode === "view" ? "#C6C9CA" : "#FCC500",
                }}
              />
            </IconButton>
          </Tooltip>
        );
      },
    },
    {
      id: "from",
      label: t("QUESTIONNAIRES.FROM"),
      sortValue: "selector.from",
      format: (val: RangeListItemType, index) => {
        return <TableField value={val?.from} error={val?.errors?.from} />;
      },
    },
    {
      id: "to",
      label: t("QUESTIONNAIRES.TO"),
      sortValue: "selector.to",
      format: (val: RangeListItemType, index) => {
        return <TableField value={val?.to} error={val?.errors?.to} />;
      },
    },
    {
      id: "rangeName",
      label: t("QUESTIONNAIRES.RANGE_NAME"),
      sortValue: "selector.rangeName",
      format: (val: RangeListItemType, index) => {
        return (
          <TableField value={val?.rangeName} error={val?.errors?.rangeName} />
        );
      },
    },
    {
      id: "description",
      label: t("QUESTIONNAIRES.DESCRIPTION"),
      sortValue: "selector.description",
      format: (val: RangeListItemType, index) => {
        return (
          <TableField
            value={val?.description}
            error={val?.errors?.description}
          />
        );
      },
    },
  ];

  return (
    <>
      {displayGradeRange ? (
        <label>{`Min: ${displayGradeRange.min}, Max: ${displayGradeRange.max}`}</label>
      ) : (
        <label> {t("QUESTIONNAIRES.SELECT_QUESTION_ABOVE")} </label>
      )}
      <DataTable
        loading={loading}
        rows={grade?.ranges || []}
        columns={rangeColumns}
        scrollClass={styles.dataTableDiv}
      />
      {grade?.errors?.gradeGeneral?.length ? (
        <FormHelperText error>
          {grade?.errors?.gradeGeneral?.[0]}
        </FormHelperText>
      ) : null}
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          paddingTop: "10px",
        }}
      >
        <Fab
          size="medium"
          color="secondary"
          aria-label="add"
          sx={{ marginTop: 2, marginBottom: 2 }}
          onClick={handleRangeOpen}
        >
          <Add />
        </Fab>
      </Grid>
      <Modal
        open={openRange}
        onClose={handleRangeClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card
          sx={{
            direction,
          }}
          className={styles.addModal1}
        >
          <div className={styles.modalContainer}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                component={"span"}
                align="center"
                className={styles.headerModal}
              >
                {t("QUESTIONNAIRES.ADD_RANGE_NAME")}
              </Typography>
            </Box>
            <Grid
              xs={12}
              container
              rowSpacing={1.5}
              sx={{
                padding: "20px 0px",
                margin: "0px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Grid item xs={4}>
                <label className={styles.label}>
                  {t("QUESTIONNAIRES.FROM")}
                </label>
                <TextField
                  type="number"
                  margin="none"
                  fullWidth
                  variant="outlined"
                  required
                  autoComplete={"off"}
                  className={styles.textEnabled}
                  name={"from"}
                  value={grade?.currentRange?.from}
                  onChange={(e) => {
                    setFormValue("from", e.target.value);
                  }}
                  error={!!grade?.currentRange?.errors?.from}
                  helperText={grade?.currentRange?.errors?.from}
                  FormHelperTextProps={{
                    style: {
                      backgroundColor: "#F5F8FF",
                      margin: "0px",
                      padding: "0px",
                      textAlign: i18n.dir() === "ltr" ? "left" : "right",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <label className={styles.label}>{t("QUESTIONNAIRES.TO")}</label>
                <TextField
                  margin="none"
                  type="number"
                  fullWidth
                  variant="outlined"
                  required
                  value={grade?.currentRange?.to}
                  autoComplete={"off"}
                  className={styles.textEnabled}
                  name={"to"}
                  onChange={(e) => {
                    setFormValue("to", e.target.value);
                  }}
                  error={!!grade?.currentRange?.errors?.to}
                  helperText={grade?.currentRange?.errors?.to}
                  FormHelperTextProps={{
                    style: {
                      backgroundColor: "#F5F8FF",
                      margin: "0px",
                      padding: "0px",
                      textAlign: i18n.dir() === "ltr" ? "left" : "right",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <label className={styles.label}>
                  {t("QUESTIONNAIRES.RANGE_NAME")}
                </label>
                <TextField
                  margin="none"
                  fullWidth
                  variant="outlined"
                  required
                  autoComplete={"off"}
                  className={styles.textEnabled}
                  name={"rangeName"}
                  value={grade?.currentRange?.rangeName}
                  onChange={(e) => {
                    setFormValue("rangeName", e.target.value);
                  }}
                  error={!!grade?.currentRange?.errors?.rangeName}
                  helperText={grade?.currentRange?.errors?.rangeName}
                  FormHelperTextProps={{
                    style: {
                      backgroundColor: "#F5F8FF",
                      margin: "0px",
                      padding: "0px",
                      textAlign: i18n.dir() === "ltr" ? "left" : "right",
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ marginBottom: "25px" }}>
              <label className={styles.label}>
                {t("QUESTIONNAIRES.DESCRIPTION")}
              </label>
              <TextField
                margin="none"
                fullWidth
                variant="outlined"
                required
                name="description"
                autoComplete={"off"}
                className={styles.textEnabled}
                value={grade?.currentRange?.description}
                onChange={(e) => {
                  setFormValue("description", e.target.value);
                }}
                error={!!grade?.currentRange?.errors?.description}
                helperText={grade?.currentRange?.errors?.description}
                FormHelperTextProps={{
                  style: {
                    backgroundColor: "#F5F8FF",
                    margin: "0px",
                    padding: "0px",
                    textAlign: i18n.dir() === "ltr" ? "left" : "right",
                  },
                }}
              />
            </Grid>
            <div>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <Button
                  className={styles.cancelBtn}
                  variant="outlined"
                  onClick={handleRangeClose}
                >
                  {t("BUTTONS.CANCEL")}
                </Button>
                <Button
                  className={styles.saveBtn}
                  variant="contained"
                  onClick={handleRangeSave}
                >
                  {t("BUTTONS.SAVE")}
                </Button>
              </Box>
            </div>
          </div>
        </Card>
      </Modal>
    </>
  );
};

export default RangeForm;
