import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Grid, MenuItem, Select, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectActualInstructor } from "store/global/fields/selector";
import { actions } from "store/global/fields/slice";
import { useStyles } from "../styles";
interface TProps {
  value: number;
  treatmentId: number;
  bankId: number;
  instructorId: number;
  changeValue: (value: number) => void;
  disabled?: boolean;
  showAll: boolean;
  isEdit: boolean;
  xs?: number;
}
const ActualInstructorField = (props: TProps) => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const List = useSelector(selectActualInstructor);

  const { t } = useTranslation();

  useEffect(() => {
    if (props.showAll) {
      dispatch(
        actions.getActualInstructorList({
          treatmentId: props.treatmentId,
          bankId: props.bankId,
        })
      );
    } else if (props.treatmentId > 0) {
      dispatch(
        actions.getActualInstructorList({
          treatmentId: props.treatmentId,
          bankId: props.bankId,
        })
      );
    } else {
      dispatch(actions.getActualInstructorListSuccess({ items: [] }));
    }

    return () => {};
  }, [props.treatmentId, props.bankId]);

  const handleChange = (event) => {
    props.changeValue(event.target.value);
  };

  const direction = useTheme().direction;
  return (
    <Grid item xs={props.xs ? props.xs : 2}>
      <label className={styles.label}>
        {t("TIMESLOT.ACTUAL_INSTRUCTOR_NAME")}
      </label>
      <Select
        margin="dense"
        fullWidth
        variant="outlined"
        disabled={props.disabled}
        required
        autoComplete={"off"}
        value={props.value}
        className={styles.textEnabled}
        onClick={() => setDropDownOpen(props.disabled ? false : !dropDownOpen)}
        onChange={handleChange}
        open={dropDownOpen}
        IconComponent={KeyboardArrowDownIcon}
      >
        {props.showAll && (
          <MenuItem sx={{ direction }} value={"0"}>
            {t("COMMON.ALL")}
          </MenuItem>
        )}
        {List.items.map((item) => (
          <MenuItem
            key={item.id}
            onClick={() => setDropDownOpen(!dropDownOpen)}
            value={item.id}
            sx={{ direction }}
          >
            {item.firstName} {item.lastName}
          </MenuItem>
        ))}
      </Select>
    </Grid>
  );
};
export default ActualInstructorField;
