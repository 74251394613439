import React, { useEffect } from "react";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { actions as ListAction } from "../ListView/redux/slice";
import { actions, reducer, sliceKey } from "./redux/slice";

import { useDispatch, useSelector } from "react-redux";
import { selectQuery } from "../ListView/redux/selector";
import { useCalenderSaga } from "./redux/saga";

import moment from "moment";
import Calender from "./components/Calender";
import { selectMonthList } from "./redux/selector";
interface Props {}

const CalenderViewMonth = (props: Props) => {
  useInjectReducer({ key: sliceKey, reducer });
  useInjectSaga({ key: sliceKey, saga: useCalenderSaga });

  const dispatch = useDispatch();
  const list = useSelector(selectMonthList);
  const {
    actualInstructorId,
    bankId,
    date,
    departmentId,
    instructorId,
    organizationId,
    q,
    treatmentId,
    mode,
  } = useSelector(selectQuery);

  const setPageQuery = (name: string, value: any) => {
    dispatch(ListAction.setQuery({ name, value }));
  };
  useEffect(() => {
    dispatch(actions.getListMonth());
    return () => {};
  }, [
    actualInstructorId,
    bankId,
    date,
    departmentId,
    instructorId,
    organizationId,
    q,
    treatmentId,
    mode,
  ]);

  useEffect(() => {
    setPageQuery("date", moment().startOf("month").format("YYYY-MM-DD"));
    setPageQuery("mode", "month");

    return () => {};
  }, []);

  return (
    <Calender
      loading={list.loading}
      data={list.items}
      dateChange={(data) => {
        setPageQuery("date", moment(data).format("YYYY-MM-DD"));
      }}
    />
  );
};
export default CalenderViewMonth;
