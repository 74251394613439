import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  scroll: {
    overflowY: "scroll",

    overflowX: "auto",
  },
  body: {
    background: "#fff",
  },
  info: {
    height: "45px",
    marginBottom: "5px",
    background: "#fff",
    alignItems: "center",
    padding: "2px",
    display: "flex",
  },
  timeSession: {
    borderBottom: "2px solid transparent",
  },
  timeSessionCcompleted: {
    borderColor: "green",
  },
  label_date: {
    fontFamily: "Cairo",
    fontSize: "16px",
    color: "#2A3333",
    marginLeft: "10px",
    position: "relative",
  },
  bold: {
    margin: "0px",
    padding: "0px",
    fontSize: "16px",
    color: "#000000",
  },
  table: {
    fontSize: "15px",
    fontWeight: "lighter",
    width: "100%",
  },
  cell: {
    padding: 4,
  },
  disabledRow: {
    color: "#ccc",
    paddingBottom: 18,
  },
  row: {
    color: "#000",
    paddingBottom: 18,
  },
  date_area: {
    color: "#683AD2",
    border: "1px solid #C6C9CA",
    marginLeft: "0px",
    marginTop: "8px",
    paddingRight: "8px",
    height: "63px",
    background: "#FFFFFF",
  },
  even_row: {
    background: "#FFFFFF",
  },
}));
