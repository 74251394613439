import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  label: {
    fontFamily: "Cairo",
    fontWeight: 400,
    fontSize: "16px",
    color: "#2A3333",
  },
  textEnabled: {
    background: "#FFF",
    width: "97%",
  },
  errorText: {
    marginTop: -5,
  },
}));
