import { Grid } from "@mui/material";
import React from "react";
import { FabAddIcon } from "../Button/FabAddIcon";
import { ChipBox } from "../ChipBox";
type ChipBoxProps = {
  label: number;
  count: string | number;
};

type Props = {
  chipBoxes: ChipBoxProps[];
  onClickFab?(): void;
  children?: React.ReactNode;
  disabled?: boolean;
};

export const BottomActions = ({
  chipBoxes = [],
  onClickFab,
  children,
  disabled,
}: Props) => {
  return (
    <Grid
      container
      style={{
        flexDirection: "row",
        display: "flex",
        marginTop: 12,
        justifyContent: "center",
      }}
    >
      <Grid item md={8}>
        <Grid
          container
          columnGap={1}
          style={{
            display: "flex",
            justifyContent: "flex-start",
          }}
          md={12}
        >
          {chipBoxes.map((m, i) => {
            return <ChipBox key={i} label={m.label} count={m.count} />;
          })}
        </Grid>
      </Grid>
      <Grid item md={4}>
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "row",
          }}
          md={12}
        >
          {children}
          {onClickFab ? (
            <FabAddIcon disabled={disabled} onClick={onClickFab} />
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
};
