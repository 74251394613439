import moment from "moment";
import queryString from "query-string";
import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { API_URL } from "utils/constants";
import {
  TRANSLATE,
  getDefaultHeaders,
  removeBlankProperties,
  safeParseJSON,
} from "utils/helpers";
import { request } from "utils/request";
import toastService from "utils/toast";
import {
  TimeSlotForm,
  TimeSlotMode,
  TimeSlotsActionsListItemType,
  TreatmentBankInfo,
} from "../types";
import {
  selectBonusPatientList,
  selectEditIndex,
  selectInvolvedPatientList,
  selectList,
  selectPatientQuery,
  selectQuery,
  selectTimeSlotFrom,
} from "./selector";
import { actions } from "./slice";

export function* saveTimeSlotRequest(action) {
  yield delay(500);

  try {
    const form: TimeSlotForm = yield select(selectTimeSlotFrom);
    const list = yield select(selectList);
    const involvedPatientList = yield select(selectInvolvedPatientList);
    const bonusPatientList = yield select(selectBonusPatientList);
    let requestData = {
      id: form.id.value,
      departmentId: form.departmentId.value,
      organizationId: form.organizationId.value,
      bankId: form.bankId.value,
      treatmentId: form.treatmentId.value,
      instructorId: form.instructorId.value,
      actualInstructorId: form.actualInstructorId.value,
      street: form.street.value,
      cityId: form.cityId.value,
      slotDate: form.slotDate.value,
      type: form.type,
      session: list.items,
      involvedPatient: involvedPatientList.items,
      timeslotBonusAssociation: bonusPatientList.items,
    };
    if (form.organizationId.value === 0) {
      throw new Error('{"message":"TIMESLOT.PLEASE_SELECT_ORGANIZATION_NAME"}');
    }
    if (form.departmentId.value === 0) {
      throw new Error('{"message":"TIMESLOT.PLEASE_SELECT_DEPARTMENT"}');
    }
    if (form.bankId.value === 0) {
      throw new Error('{"message":"TIMESLOT.PLEASE_SELECT_BANK"}');
    }
    if (form.treatmentId.value === 0) {
      throw new Error('{"message":"TIMESLOT.PLEASE_SELECT_TREATMENT_TYPE"}');
    }

    if (form.instructorId.value === 0) {
      throw new Error('{"message":"TIMESLOT.PLEASE_SELECT_INSTRUCTOR"}');
    }
    if (form.street.value.trim().length === 0) {
      throw new Error('{"message":"TIMESLOT.PLEASE_ENTER_STREET_ADDRESS"}');
    }

    if (form.cityId.value === 0) {
      throw new Error('{"message":"TIMESLOT.PLEASE_SELECT_CITY"}');
    }
    if (form.type === TimeSlotMode.SINGLE) {
      if (!form.slotDate.value) {
        throw new Error('{"message":"TIMESLOT.PLEASE_SELECT_ASSIGNED_DATE"}');
      }
    }

    if (list.items.length === 0) {
      throw new Error('{"message":"TIMESLOT.PLEASE_ADD_SESSIONS"}');
    }

    requestData.session.forEach((element) => {
      if (
        !element.completedAt &&
        moment(element.start_time).isBefore(moment())
      ) {
        throw new Error(
          `{"message":"TIMESLOT.SESSION_TIME_IS_IN_PAST","params":{"sessionNumber":${
            element.sessionNumber || 0
          }}}`
        );
      }
    });

    const options = {
      method: "POST",
      headers: getDefaultHeaders(),
      body: JSON.stringify(requestData),
    };

    const response: TimeSlotsActionsListItemType = yield request(
      `${API_URL}/v1/timeslot`,
      options
    );
    toastService.success(TRANSLATE("TIMESLOT.TIME_SLOT_ADDED_SUCCESSFULLY"));
    yield call(action.payload?.callback);
  } catch (e: any) {
    console.log({ e });
    const error = e as Error;
    const { message, errors, params } = safeParseJSON(error.message);
    yield put(actions.updateFailed({ message, errors }));
    toastService.error(TRANSLATE(message, params));
  }
}

export function* updateTimeSlotRequest(action) {
  yield delay(500);

  try {
    const form: TimeSlotForm = yield select(selectTimeSlotFrom);
    const list = yield select(selectList);
    const involvedPatientList = yield select(selectInvolvedPatientList);
    const bonusPatientList = yield select(selectBonusPatientList);
    let requestData = {
      id: form.id.value,
      departmentId: form.departmentId.value,
      organizationId: form.organizationId.value,
      bankId: form.bankId.value,
      treatmentId: form.treatmentId.value,
      instructorId: form.instructorId.value,
      actualInstructorId: form.actualInstructorId.value,
      street: form.street.value,
      cityId: form.cityId.value,
      slotDate: form.slotDate.value,
      type: form.type,
      session: list.items,
      involvedPatient: involvedPatientList.items,
      timeslotBonusAssociation: bonusPatientList.items,
    };
    if (form.organizationId.value === 0) {
      throw new Error('{"message":"TIMESLOT.PLEASE_SELECT_ORGANIZATION"}');
    }
    if (form.departmentId.value === 0) {
      throw new Error('{"message":"TIMESLOT.PLEASE_SELECT_DEPARTMENT"}');
    }
    if (form.bankId.value === 0) {
      throw new Error('{"message":"TIMESLOT.PLEASE_SELECT_BANK"}');
    }
    if (form.treatmentId.value === 0) {
      throw new Error('{"message":"TIMESLOT.PLEASE_SELECT_TREATMENT_TYPE"}');
    }

    if (form.instructorId.value === 0) {
      throw new Error('{"message":"TIMESLOT.PLEASE_SELECT_INSTRUCTOR"}');
    }
    if (form.street.value.trim().length === 0) {
      throw new Error('{"message":"TIMESLOT.PLEASE_ENTER_STREET_ADDRESS"}');
    }

    if (form.cityId.value === 0) {
      throw new Error('{"message":"TIMESLOT.PLEASE_SELECT_CITY"}');
    }
    if (form.type === TimeSlotMode.SINGLE) {
      if (form.slotDate.value === "") {
        throw new Error('{"message":"TIMESLOT.PLEASE_SELECT_ASSIGNED_DATE"}');
      }
    }

    if (list.items.length === 0) {
      throw new Error('{"message":"TIMESLOT.PLEASE_ADD_SESSIONS"}');
    }
    requestData.session.forEach((element) => {
      if (
        !element.completedAt &&
        moment(element.start_time).isBefore(moment())
      ) {
        throw new Error(
          `{"message":"TIMESLOT.SESSION_TIME_IS_IN_PAST","params":{"sessionNumber":${
            element.sessionNumber || 0
          }}}`
        );
      }
    });

    const options = {
      method: "PATCH",
      headers: getDefaultHeaders(),
      body: JSON.stringify(requestData),
    };

    const response: TimeSlotsActionsListItemType = yield request(
      `${API_URL}/v1/timeslot/${form.id.value}`,
      options
    );
    toastService.success(TRANSLATE("USERS.DATA_UPDATED_SUCCESSFULLY"));
    yield call(action.payload?.callback);
  } catch (e: any) {
    console.log({ e });
    const error = e as Error;
    const { message, params, errors } = safeParseJSON(error.message);
    yield put(actions.updateFailed({ message, errors }));
    toastService.error(TRANSLATE(message, params));
  }
}
export function* deleteTimeSlotRequest(action) {
  yield delay(500);

  try {
    const form: TimeSlotForm = yield select(selectTimeSlotFrom);

    const options = {
      method: "DELETE",
      headers: getDefaultHeaders(),
    };

    const response: TimeSlotsActionsListItemType = yield request(
      `${API_URL}/v1/timeslot/${form.id.value}`,
      options
    );
    toastService.success(TRANSLATE("TIMESLOT.TIME_SLOT_DELETED_SUCCESSFULLY"));
    yield call(action.payload?.callback);
  } catch (e: any) {
    const error = e as Error;
    const { errors } = safeParseJSON(error.message);
    toastService.error(
      TRANSLATE("TIMESLOT.TIME_SLOT_DELETE_FAILED", { error: errors?.message })
    );
  }
}

export function* getList(action) {
  yield delay(500);
  const query = yield select(selectQuery);
  const requestData = removeBlankProperties(query);
  const queries = queryString.stringify({
    ...requestData,
  });
  try {
    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    const response = yield request(`${API_URL}/users?${queries}`, options);
  } catch (e: any) {
    console.log({ e });
    const error = e as Error;
    const { errors } = safeParseJSON(error.message);
    toastService.error(
      TRANSLATE(errors.message, { defaultValue: "Error in saving" })
    );
  }
}
export function* getById(action) {
  yield delay(500);

  try {
    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    const response = yield request(
      `${API_URL}/v1/timeslot/${action.payload}`,
      options
    );
    yield put(actions.setEditData(response));
  } catch (e: any) {
    console.log({ e });
    yield put(actions.setEditDataComplete(e?.message));
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", {
        error: JSON.stringify(e),
      })
    );
  }
}

export function* getPatientRegistrationList(action) {
  yield delay(500);
  const query = yield select(selectPatientQuery);
  const requestData = removeBlankProperties(query);
  const queries = queryString.stringify({
    ...requestData,
  });
  try {
    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    const response = yield request(
      `${API_URL}/v1/patients/for-timeslot-registrations?${queries}`,
      options
    );
    yield put(actions.getPatientRegistrationListSuccess(response));
  } catch (e: any) {
    console.log({ e });
    yield put(actions.getPatientRegistrationFailure());
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", {
        error: JSON.stringify(e),
      })
    );
  }
}

export function* getPatientBonusList(action) {
  yield delay(500);
  const query = yield select(selectPatientQuery);
  const requestData = removeBlankProperties(query);
  const queries = queryString.stringify({
    ...requestData,
  });
  try {
    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    const response = yield request(
      `${API_URL}/v1/patients/for-timeslot-bonus?${queries}`,
      options
    );
    yield put(actions.getPatientBonusListSuccess(response));
  } catch (e: any) {
    console.log({ e });
    yield put(actions.getPatientBonusFailure());
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", {
        error: JSON.stringify(e),
      })
    );
  }
}

export function* editTimeSlot(action) {
  yield delay(500);
  const session = yield select(selectList);
  const editiindex = yield select(selectEditIndex);
  for (let index = 0; index < session.items.length; index++) {
    const element = session.items[index];

    if (index !== editiindex) {
      if (action.payload.type === TimeSlotMode.MULTI) {
        {
          if (
            moment(action.payload.startTime).format("YYYY-MM-DD") ===
            moment(element.start_time).format("YYYY-MM-DD")
          ) {
            toastService.error(
              TRANSLATE("TIMESLOT.DATE_SAME_AS_SESSION_ERROR")
            );
            return;
          }
        }
      } else {
        const startTime = moment(action.payload.startTime);
        const endTime = moment(action.payload.endTime);

        const checkStartTime = moment(element.start_time);
        const checkEndTime = moment(element.end_time);
        if (startTime.isSame(checkStartTime)) {
          toastService.error(
            TRANSLATE("TIMESLOT.TIME_OVERLAP_ERROR", {
              startTime: checkStartTime.format("hh:mm"),
              endTime: checkEndTime.format("hh:mm"),
            })
          );
          return;
        }

        if (startTime.isBetween(checkStartTime, checkEndTime)) {
          toastService.error(
            TRANSLATE("TIMESLOT.TIME_OVERLAP_ERROR", {
              startTime: checkStartTime.format("hh:mm"),
              endTime: checkEndTime.format("hh:mm"),
            })
          );
          return;
        }
        if (endTime.isBetween(checkStartTime, checkEndTime)) {
          toastService.error(
            TRANSLATE("TIMESLOT.TIME_OVERLAP_ERROR", {
              startTime: checkStartTime.format("hh:mm"),
              endTime: checkEndTime.format("hh:mm"),
            })
          );
          return;
        }
      }
    }
  }
  yield put(actions.setEditSessionSuccess(action.payload));
  yield call(action?.payload?.callback);
}

export function* setSessionPatient(action) {
  yield put(actions.setSessionPatientSuccess(action.payload));
  yield call(action?.payload?.callback);
}

export function* getTimeSlotInfo(action) {
  try {
    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    const queries = queryString.stringify({
      ...action.payload,
    });

    const response: TreatmentBankInfo = yield request(
      `${API_URL}/v1/treatments-bank/bank-info?${queries}`,
      options
    );
    yield put(actions.getTimeSlotInfoSuccess(response));
  } catch (e: any) {
    console.log({ e });
  }
}
export function* useTimeSlotActionSaga() {
  yield takeLatest(actions.getList.type, getList);
  yield takeLatest(actions.getById.type, getById);
  yield takeLatest(
    actions.getPatientRegistrationList.type,
    getPatientRegistrationList
  );
  yield takeLatest(
    actions.getPatientRegistrationNextList.type,
    getPatientRegistrationList
  );
  yield takeLatest(actions.getPatientBonusList.type, getPatientBonusList);
  yield takeLatest(actions.getPatientBonusNextList.type, getPatientBonusList);

  yield takeLatest(actions.doAddTimeslot.type, saveTimeSlotRequest);
  yield takeLatest(actions.doEditTimeSlot.type, updateTimeSlotRequest);
  yield takeLatest(actions.doDeleteTimeSlot.type, deleteTimeSlotRequest);
  yield takeLatest(actions.setEditSession.type, editTimeSlot);
  yield takeLatest(actions.setSessionPatient.type, setSessionPatient);
  yield takeLatest(actions.timeSlotInfo.type, getTimeSlotInfo);
}
