import { createSelector } from "@reduxjs/toolkit";
import { RoleEnum } from "app/containers/Users/types";
import { RootState } from "types";
import { FeaturePermissionSelectors, PermissionFeature } from "./types";

export const selectUserPermissions = (state: RootState) =>
  state.authPermissionState?.user?.userGroup?.userGroupsPermissions || [];

export const selectUser = (state: RootState) => state.authPermissionState?.user;

export const selectUserRole = createSelector([selectUser], (user) => user.role);

export const selectIsAdminRole = createSelector([selectUserRole], (role) => {
  return role?.id == RoleEnum.admin;
});

// Create selectors for all PermissionFeatures and permission types
export const selectFeaturePermissions = (
  state: RootState
): FeaturePermissionSelectors => {
  const userPermissions = selectUserPermissions(state);
  const isAdmin = state.authPermissionState?.user?.role?.name === "Admin";
  const result: FeaturePermissionSelectors = {} as FeaturePermissionSelectors;
  Object.values(PermissionFeature).forEach((feature) => {
    result[feature] = {
      hasNoAccess: isAdmin
        ? false
        : !!userPermissions.find(
            (permission) => permission.featureName === feature
          )?.noAccess,
      hasReadAccess: isAdmin
        ? false
        : !!userPermissions.find(
            (permission) => permission.featureName === feature
          )?.readAccess,
      hasFullAccess: isAdmin
        ? true
        : !!userPermissions.find(
            (permission) => permission.featureName === feature
          )?.fullAccess,
    };
  });
  return result;
};

export const selectFeaturePermissionsLoading = (state: RootState): boolean => {
  return state.authPermissionState?.isLoading == true;
};
