import moment from "moment";

interface TreatmentBankEditItemType {
  treatmentId?: number;
  instructorName?: any[];
  startDate?: string;
  endDate?: string;
  max?: number;
}

export interface ErrorsType {
  treatmentId?: string;
  instructorName?: string;
  startDate?: string;
  endDate?: string;
  max?: string;
}

export const onValidate = (values: TreatmentBankEditItemType) => {
  const { treatmentId, instructorName, startDate, endDate, max } = values;
  const errors: ErrorsType = {};
  if (!treatmentId || treatmentId < 1) {
    errors.treatmentId = "TREATMENT_BANKS.PLEASE_SELECT_TREATMENT_TYPE";
  }
  if (!instructorName || instructorName.length < 1) {
    errors.instructorName = "TREATMENT_BANKS.PLEASE_SELECT_INSTRUCTOR_NAME";
  }
  if (!startDate || startDate?.length === 0) {
    errors.startDate = "TREATMENT_BANKS.PLEASE_ENTER_START_DATE";
  }
  if (!endDate || endDate?.length === 0) {
    errors.endDate = "TREATMENT_BANKS.PLEASE_ENTER_END_DATE";
  }
  if (!max || max < 1) {
    errors.max = "TREATMENT_BANKS.PLEASE_ENTER_MAX_PER_PATIENT";
  }
  if ((max && max < 1) || (max && max > 100)) {
    errors.max = "TREATMENT_BANKS.MAX_PER_PATIENT_SHOULD_BE_BETWEEN_1_AND_100";
  }
  if (moment(startDate) > moment(endDate)) {
    errors.startDate =
      "TREATMENT_BANKS.START_DATE_SHOULD_BE_LESS_THAN_END_DATE";
  }
  return errors;
};
