import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "types";
import { UserState } from "../types";

const selectDomain = (state: RootState) =>
  state.timeSlotActionsState || UserState;

export const selectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const selectList = createSelector(
  [selectDomain],
  (state) => state.sessionList
);

export const selectHasSessionStarted = createSelector([selectDomain], (state) =>
  state.sessionList.items.some((s) => !!s.completedAt)
);

export const selectTimeSlotBankInfo = createSelector(
  [selectDomain],
  (state) => state.bankInfo
);

export const selectQuery = createSelector(
  [selectDomain],
  (state) => state.query
);
export const selectTimeSlotFrom = createSelector(
  [selectDomain],
  (state) => state.form
);
export const selectSearch = createSelector(
  [selectDomain],
  (state) => state.search
);
export const selectError = createSelector(
  [selectDomain],
  (state) => state.error
);
export const selectEditState = createSelector(
  [selectDomain],
  (state) => state.editState
);
export const selectMode = createSelector([selectDomain], (state) => state.mode);
export const selectTreatmentType = createSelector(
  [selectDomain],
  (state) => state.treatmentType
);
export const selectRegisterPatientModal = createSelector(
  [selectDomain],
  (state) => state.registerPatientModal
);
export const selectInvolvedPatientModal = createSelector(
  [selectDomain],
  (state) => state.involvedPatientModal
);
export const selectPatientList = createSelector(
  [selectDomain],
  (state) => state.patientList
);
export const selectPatientQuery = createSelector(
  [selectDomain],
  (state) => state.patientQuery
);
export const selectInvolvedPatientList = createSelector(
  [selectDomain],
  (state) => state.involvedPatientList
);

export const selectBonusPatientList = createSelector(
  [selectDomain],
  (state) => state.timeslotBonusAssociation
);

export const selectEditTimeSlotModel = createSelector(
  [selectDomain],
  (state) => state.editTimeSlotModel
);
export const selectEditIndex = createSelector(
  [selectDomain],
  (state) => state.editIndex
);
export const selectDeleteModal = createSelector(
  [selectDomain],
  (state) => state.deleteModal
);

export const selectSessionDuration = createSelector(
  [selectDomain],
  (state) => state.sessionDuration
);

export const involvedPatientList = createSelector(
  [selectDomain],
  (state) => state.involvedPatientList.items
);
