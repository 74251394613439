import React from "react";
import { useTranslation } from "react-i18next";

import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import { Box, useTheme } from "@mui/material";

import { useStyles } from "./styles";
// import UploadImageDialog from '../UploadImageDialog';
import { FileType } from "utils/enum";
import FileUpload from "../FileUpload";
type Props = {
  url?: string;
  isEdit: boolean;
  uploadComplete(data: any): void;
  fileClose(): void;
};
export default function ProfilePhoto({
  isEdit = false,
  uploadComplete,
  url,
  fileClose,
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const theme = useTheme();

  return (
    <Box
      className={classes.container}
      sx={{
        direction: theme.direction,
      }}
    >
      {isEdit ? (
        <Box
          className={classes.imgWrapper}
          sx={{
            cursor: isEdit ? "default" : "pointer",
          }}
        >
          {url ? (
            <img
              // src={values.image?.thumb ? `${process.env.REACT_APP_API_URL?.replace('/api', '')}${values.image?.thumb}` : values.image?.url}
              src={url}
              alt="profile"
              width={"100%"}
              height={"100%"}
              style={{
                borderRadius: "50%",
              }}
            />
          ) : (
            <AddPhotoAlternateOutlinedIcon />
          )}
        </Box>
      ) : (
        <FileUpload
          title="Image"
          fileClose={() => fileClose()}
          fileType={FileType.IMAGE}
          uploadComplete={(data) => {
            uploadComplete(data);
          }}
          type="imageFile"
          isUploading={(data) => {}}
          file={{ name: url ? "image" : "", url }}
        ></FileUpload>
      )}
    </Box>
  );
}
