import { API_URL } from "utils/constants";
import { getDefaultHeaders } from "utils/helpers";
import { request } from "utils/request";
import { PresignedResponse, UploadResponse } from "../types";
import axios from "axios";

export const getPresignedURL = async (
  file: File
): Promise<PresignedResponse> => {
  const reqData = {
    fileName: file.name,
    contentType: file.type,
  };
  const options = {
    method: "POST",
    headers: getDefaultHeaders(),
    body: JSON.stringify(reqData),
  };
  const response = (await request(
    `${API_URL}/v1/files/presigned-url`,
    options
  )) as PresignedResponse;
  return response;
};

export const uploadFileToS3 = async (
  file: File
): Promise<UploadResponse> => {
  const presignedResponse = await getPresignedURL(file);
  const uploadResponse = await axios.put(presignedResponse.signedUrl, file, {
    headers: {
      "Content-Type": file.type,
    },
  });
  return { status: uploadResponse.statusText, file: presignedResponse.file };
};
