import { TreatmentInvolvedPatients } from "../types";
export const ATTENDANCE = {
  Present: "PRESENT",
  Absent: "ABSENT",
  "Cant Make It": "CANT_MAKE_IT",
  Missed: "Missed",
  EMPTY: "",
};

const REGISTRATION = {
  Registered: "REGISTERED",
  NotRegistered: "NOT_REGISTERED",
};
export const getRegistrationStatus = (
  value: TreatmentInvolvedPatients,
  index
) => {
  const rowDisabled = value.patient.bank.bankStatus == "Done";
  if (!value?.timeslotInvolvedPatients?.length) {
    return [
      [
        {
          status: "NOT_REGISTERED",
          disabled: rowDisabled,
          meetingId: undefined,
          currentStatus: "",
          availableAction: "",
          options: {
            treatmentInvolvedPatientId: value.id,
            timeslotSessionId: 1,
            index,
          },
        },
      ],
    ];
  }

  return value?.timeslotInvolvedPatients?.map((tiP, i, array) => {
    if (!tiP?.assignedTreatments?.length) {
      return [
        {
          status: "NOT_REGISTERED",
          disabled: rowDisabled,
          currentStatus: tiP.currentStatus,
          availableAction: tiP.availableAction,
          meetingId: undefined,
          isLast: true,
          options: {
            treatmentInvolvedPatientId: value.id,
            timeslotSessionId: 1,
            index,
          },
        },
      ];
    }
    return tiP.assignedTreatments.map((aT, i) => {
      const isLast = i === tiP.assignedTreatments.length - 1;
      const isSessionCompleted = !!aT.timeslotSession.completedAt;
      const status =
        ATTENDANCE[aT.attendance] ||
        (isSessionCompleted
          ? "TREATMENT_PASSED"
          : REGISTRATION[tiP.registrationStatus] || "REGISTERED");

      return {
        status: status,
        currentStatus: tiP.currentStatus,
        availableAction: tiP.availableAction,
        meetingId: aT.timeslotSession.meetingId,
        disabled: rowDisabled,
        isLast,
        options: {
          treatmentInvolvedPatientId: value.id,
          timeslotSessionId: tiP.id,
          index,
        },
      };
    });
  });
};
