import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles(() => ({
  bg_color: {
    background: "#F5F8FF",
  },
  right: {
    marginLeft: "280px",
    marginTop: "70px",
    padding: "15px",
  },
  tdHeader: {
    padding: "20px",
    fontFamily: "Cairo",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "2",
    color: "#2A3333",
  },
  actionTD: {
    padding: "15px",
  },
  dateTd: {
    padding: "15px 18px",
    fontFamily: "Cairo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px",
    color: "#2A3333",
  },
  trHead: {
    backgroundColor: "#F5F8FF",
  },
  tr: {
    "&:nth-child(odd)": {
      backgroundColor: "#fff",
    },
  },
  table: {
    marginTop: "20px",
    width: "1173px",
    border: "1px solid lightgrey",
    borderRadius: "5px",
    overflowX: "auto",
  },
  label: {
    fontFamily: "Cairo",
    fontWeight: 400,
    fontSize: "16px",
    color: "#2A3333",
  },
  textEnabled: {
    background: "#FFF",
    marginTop: "6px",
  },
  tableDiv: {
    border: "1px",
  },
  dataTableDiv: {
    maxWidth: "1100px",
    overflowY: "auto",
    border: "1px solid #C6C9CA",
    borderRadius: "8px",
    overflowX: "auto",
    marginTop: "0px",
    background: "#F5F8FF",
  },
}));
