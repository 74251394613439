import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles(() => ({
  root: {
    position: "fixed",
    top: 0,
    height: 80,
    zIndex: 9,
    background: "#F5F8FF",
    transition: `.3s`,
  },
  wrapper: {
    padding: "0px 30px 0px 300px",
    height: "100%",
  },
  versionInfo: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#aaa",
  },
  hr: {
    margin: 0,
  },
  search: {
    "& .MuiOutlinedInput-root": {
      background: "#FFF",
      borderRadius: 3,
      margin: 0,
      width: "25vw",
      height: "55px",
    },
  },
  Typo: {
    marginLeft: "15px",
    fontFamily: "Cairo",
  },
  menuClose: {
    width: `100%`,
    transition: `.3s`,
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
  },
  leftBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    height: "100%",
  },
  rightBox: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  dynamicHeader: {
    color: "#FAA61A",
    fontWeight: "700",
    fontSize: "26px",
    flex: 1,
  },
  searchIcon: {
    height: "24px",
    width: "24px",
  },
  adminName: {
    fontSize: "14px",
    fontWeight: "bold",
  },
  notificationCircle: {
    backgroundColor: "#333333",
    padding: "7px",
    position: "absolute",
    borderRadius: "100%",
    right: "5px",
    top: "5px",
  },
  imgLogout: {
    height: "23px",
    width: "20px",
  },
  logoutModal: {
    position: "absolute",
    background: "#FFFFFF",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "560px",
    height: "182px",
    border: "0px",
    boxShadow: "0",
    zIndex: "9999",
  },
  CardActions: {
    justifyContent: "center",
    marginTop: "30px",
    marginRight: "28px",
  },
  cardContent: {
    marginLeft: "17px",
    marginTop: "12px",
  },
  bodyTypography: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.5px",
    color: "rgba(0, 0, 0, 0.6)",
  },
  headTypography: {
    color: "#000000",
    fontWeight: "550",
    fontSize: "21.5px",
    lineHeight: "24px",
    letterSpacing: "0.15px",
  },
  btnNo: {
    color: "#88929C",
    paddingRight: "38px",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.0125rem",
  },
  btnLogout: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.0125rem",
  },
}));
