import { CustomDrawer } from "app/components/Drawer";
import { NoPermission } from "app/components/NoPermission";
import React from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { selectFeaturePermissions } from "store/global/authPermission/selector";
import { PermissionFeature } from "store/global/authPermission/types";
import { selectDirection } from "styles/theme/slice";
import useQueryRefNav from "utils/user-ref-nav-query";
import Topbar from "../Dashboard/components/topbar";
import Details from "./components/Details";
import List from "./components/List";
interface Props {}

const Patients = (props: Props) => {
  const direction = useSelector(selectDirection);
  const featurePermissions = useSelector(selectFeaturePermissions);
  const { hasNoAccess } = featurePermissions[PermissionFeature.PATIENTS_LIST];
  const isResetRequired = useQueryRefNav();
  if (hasNoAccess) {
    return (
      <div>
        <CustomDrawer />
        <Topbar />
        <NoPermission />
      </div>
    );
  }
  return (
    <div dir={direction}>
      <Routes>
        <Route path="/" element={<List isResetRequired={isResetRequired} />} />
        <Route path=":id/view" element={<Details mode="view" />} />
        <Route path="new" element={<Details mode="new" />} />
        <Route path=":id/edit" element={<Details mode="edit" />} />
      </Routes>
    </div>
  );
};

export default Patients;
