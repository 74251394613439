import React, { useEffect } from "react";

import { Card, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Modal } from "app/components/Modal";
import { useDispatch, useSelector } from "react-redux";
import { selectAvailableTimeSlots } from "../../redux/selector";
import { actions } from "../../redux/slice";
import { TimeslotDetails } from "./components/TimeslotDetails";
import { TimeslotMulti } from "./components/TimeslotMulti";
import { TimeslotSingle } from "./components/TimeslotSingle";
import { useStyles } from "./styles";
type Props = {
  open: boolean;
  onClose: () => void;
};
export const TimeslotComponentModal = ({ open, onClose }: Props) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const availableTimeSlots = useSelector(selectAvailableTimeSlots);
  const treatmentInvolvedPatient = availableTimeSlots?.treatmentInvolvedPatient;
  const treatmentBankTreatment =
    treatmentInvolvedPatient?.treatmentBankTreatment;
  const treatment = treatmentBankTreatment?.treatment;
  const { t } = useTranslation();
  const isMust = treatmentBankTreatment?.isMust == true;
  const isMultiSession = availableTimeSlots?.type == 2;
  const maxTreatmentAmount = treatmentBankTreatment?.maximumPerPatient || 0;
  const minTreatmentAmount = isMust ? 1 : 0;
  const treatmentInvolvedPatientsCount =
    treatmentInvolvedPatient &&
    treatmentInvolvedPatient?.timeslotInvolvedPatients.length;
  useEffect(() => {
    if (isMust && treatmentInvolvedPatientsCount == 0) {
      dispatch(actions.handleAddTreatment());
    }
  }, [isMust, treatmentInvolvedPatientsCount]);

  if (!availableTimeSlots) {
    return (
      <Modal open={open} onClose={onClose}>
        <Card
          className={styles.messageModal}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: 300,
          }}
        >
          <div className={styles.modalContainer}>
            <CircularProgress />
          </div>
        </Card>
      </Modal>
    );
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Card className={styles.messageModal}>
        <div className={styles.modalContainer}>
          <div className={styles.modalHeader}>
            <h1 className={styles.treatment_heading}>
              {isMultiSession
                ? t("TIMESLOT.MULTI_SESSION")
                : t("TIMESLOT.SINGLE_SESSION")}
              {" - "}{" "}
              <span className={styles.treatment_name}> {treatment?.name} </span>
            </h1>

            <h6 className={styles.treatment_sub}>
              <span>
                {" "}
                {treatmentInvolvedPatient?.patient?.organization?.name}
              </span>
              /
              <span>
                {" "}
                {treatmentInvolvedPatient?.patient?.department?.departmentName}
              </span>
              /<span> {treatmentInvolvedPatient?.patient?.bank?.name}</span>
            </h6>
          </div>

          <div>
            <TimeslotDetails
              maxTreatmentAmount={maxTreatmentAmount}
              minTreatmentAmount={minTreatmentAmount}
              availableTimeslotsResponse={availableTimeSlots}
            />
            {isMultiSession ? (
              <TimeslotMulti
                availableTimeslotsResponse={availableTimeSlots}
                disable={availableTimeSlots.isSaving}
                onClose={onClose}
                maxTreatmentAmount={maxTreatmentAmount}
                minTreatmentAmount={minTreatmentAmount}
              />
            ) : (
              <TimeslotSingle
                availableTimeslotsResponse={availableTimeSlots}
                disable={availableTimeSlots.isSaving}
                onClose={onClose}
                maxTreatmentAmount={maxTreatmentAmount}
                minTreatmentAmount={minTreatmentAmount}
              />
            )}
          </div>
        </div>
      </Card>
    </Modal>
  );
};
