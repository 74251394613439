import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  textHeaderT: {
    color: "#012497",
    fontWeight: "600px",
    fontSize: "16px",
    lineHeight: "29.98px",
  },
  mainHeader: {
    display: "flex",
    alignItems: "center",
  },
  textHeader: {
    color: "#2A3333",

    fontWeight: "600px",
    fontSize: "16px",
    lineHeight: "29.98px",
    fontStyle: "Semibold",
  },
}));
