import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { getPermissionList } from "store/global/authPermission/saga";
import { API_URL } from "utils/constants";
import { TRANSLATE, getDefaultHeaders } from "utils/helpers";
import { request } from "utils/request";
import toastService from "utils/toast";
import { ProfileState } from "../types";
import { selectProfile } from "./selectors";
import { actions } from "./slice";

export function* getProfile(action) {
  yield delay(500);
  try {
    const response = yield getPermissionList({});
    yield put(actions.setFrom(response));
  } catch (e: any) {
    console.error(e);
  }
}
export function* updateProfile(action) {
  yield delay(500);
  try {
    const profile: ProfileState = yield select(selectProfile);

    const userFrom = {
      firstName: profile.firstName.value,
      lastName: profile.lastName.value,
      mobileNumber: profile.mobileNumber.value,
      email: profile.email.value,
      language: profile.language.value,
      profilePic: profile.profilePic.value,
    };
    const options = {
      method: "PATCH",
      headers: getDefaultHeaders(),
      body: JSON.stringify(userFrom),
    };
    yield request(`${API_URL}/v1/auth/me`, options);
    const response = yield getPermissionList({});
    yield put(actions.setFrom(response));
    yield call(action?.payload?.callback);
  } catch (e: any) {
    let error = e?.message;
    try {
      error = JSON.parse(e?.message)?.message;
    } catch (e) {
      console.error(e);
    }
    yield put(actions.resetIsLoading());
    toastService.error(TRANSLATE(error));
  }
}
export function* useProfileSaga() {
  yield takeLatest(actions.getProfile.type, getProfile);
  yield takeLatest(actions.updateProfile.type, updateProfile);
}
