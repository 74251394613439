import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { actions } from "store/global/authPermission/slice";

export const AuthPermission = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.getPermissionList());
  }, []);

  return <></>;
};
