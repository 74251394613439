import { PayloadAction } from "@reduxjs/toolkit";
import set from "lodash/set";
import { createSlice } from "utils/@reduxjs/toolkit";
import { ResultItem, initialState } from "../types";

export const useDashboardSlice = createSlice({
  name: "dashboardState",
  initialState,
  reducers: {
    setQuery: (state, action: PayloadAction<{ name: string; value: any }>) => {
      const { name, value } = action.payload;
      set(state.query, name, value);
    },
    getListProgress: (state) => {},
    getListProgressSuccess: (state, action) => {
      state.progress = action.payload;
    },
    setQuestionnaireId: (
      state,
      action: PayloadAction<{ questionnaireId: number }>
    ) => {
      state.questionnaire.details.questionnaireId =
        action.payload.questionnaireId;
    },
    getQuestionerDetails: (
      state,
      action: PayloadAction<{ questionnaireId: number }>
    ) => {
      state.questionnaire.details.item = undefined;
      state.questionnaire.details.isLoading = true;
    },
    getQuestionerDetailsSuccess: (state, action: PayloadAction<ResultItem>) => {
      state.questionnaire.details.item = action.payload;
      state.questionnaire.details.isLoading = false;
    },
    getQuestionerDetailsFailure: (state) => {
      state.questionnaire.details.isLoading = false;
    },

    getListGrades: (state) => {
      state.questionnaire.isLoading = true;
      state.questionnaire.items = [];
    },
    getListGradesSuccess: (state, action) => {
      state.questionnaire.isLoading = false;
      const items = action.payload.items;
      state.questionnaire.items = items;
      state.questionnaire.items =
        action.payload.meta.currentPage === 1
          ? items
          : [...state.questionnaire.items, ...items];

      if (action.payload.meta.currentPage === 1) {
        state.questionnaire.details.questionnaireId =
          state.questionnaire.items[0]?.id;
      }

      state.questionnaire.meta = action.payload.meta;
    },
    getGradesListFailure: (state) => {
      state.questionnaire.isLoading = false;
    },
    resetQuery: (state) => {
      state.query = initialState.query;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useDashboardSlice;
