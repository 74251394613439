import { FormElement } from "app/containers/types";
import moment from "moment";

export interface ResponseError {
  message: string;
}
export type RequestError = ResponseError;

export type CalenderListItemType = {
  id: string;
  title: string;
  date?: string;
  start?: string;
  end?: string;
};

export interface NewUserRequest {
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
  companyName: string;
  designation: any;
  numberOfEmployees: string;
  user_type: string;
  isActive: any;
}

export type NewUserResponse = {
  id: any;
  codeSentAt: any;
  firstName: string;
  lastName: string;
  mobileNumber: string;
  countryCode: any;
  email: string;
  companyName: string;
  designation: string;
  numberOfEmployees: string;
  language: string;
  isActive: true;
  role: any;
  image: any;
  userType: string;
  createdAt: string;
  updatedAt: string;
  fullName: string;
  message?: string;
};

export type UserForm = {
  id: FormElement;
  firstName: FormElement;
  lastName: FormElement;
  email: FormElement;
  dob: FormElement;
  mobileNumber: FormElement;
  createdAt: FormElement;
  updatedAt: FormElement;
  ip: FormElement;
  mac: FormElement;
  user_type: FormElement;
};

export type calenderStateType = {
  query: {
    departmentId: number;
    organizationId: number;
    bankId: number;
    treatmentId: number;
    instructorId: number;
    actualInstructorId: number;
    date: string;
    q: string;
    mode: string;
  };
  monthList: { loading: boolean; items: Array<CalenderListItemType>; meta: {} };
  weekList: { loading: boolean; items: Array<CalenderListItemType>; meta: {} };
  instructorList: {
    loading: boolean;
    items: Array<CalenderListItemType>;
    meta: {};
  };
  error: string;
};

export interface OppositeMonthlyResponse {
  result: Instructor[];
  meta: {
    date: string;
  };
}

export interface Timeslot {
  id: number;
  organizationId: number;
  departmentId: number;
  bankId: number;
  treatmentId: number;
  associatedPatientCount: number;
  bankTreatmentId: number;
  street: string;
  cityId: number;
  instructorId: number;
  eventColor: string;
  hasTimeslotsAvailable: boolean;
  actualInstructorId: number;
  slotDate: string;
  isActive: boolean;
  type: number;
  created_at: string;
  updated_at: string;
  timeslotSession: TimeslotSession[];
}

export interface TimeslotSession {
  id: number;
  timeSlotId: number;
  isSlotsAvailable: boolean;
  start_time: string;
  end_time: string;
  completedAt?: string;
  attendanceMarkedAt: any;
  meetingId: string;
  sessionNumber: number;
  created_at: string;
  updated_at: string;
}

export interface Instructor {
  id: number;
  firstName: string;
  lastName: string;
  gender: string;
  dateOfBirth: string;
  martialStatus: string;
  numberOfChildren: number;
  email: string;
  mobileNumber: string;
  cityId: number;
  street: string;
  area: string;
  userGroupId?: number;
  activeBankCount: number;
  futureTimeSlotsCount: number;
  profilePic: any;
  isActive: boolean;
  lastLoginAt?: string;
  createdAt: string;
  updatedAt: string;
  language: string;
  eventColor: string;
  timeSlots: Timeslot[];
  treatmentOptionalInstructor: TreatmentOptionalInstructor[];
}

export interface PatientSchedule {
  startDate: string;
  endDate: string;
  backgroundColor: string;
  color: string;
}

export interface TreatmentOptionalInstructor {
  id: number;
  treatmentBankId: number;
  isActive: boolean;
  instructorId: number;
  createdAt: string;
  updatedAt: string;
  treatmentBanktreatment: TreatmentBanktreatment;
}

export interface TreatmentBanktreatment {
  id: number;
  treatmentBankId: number;
  treatmentId: number;
  startDate: string;
  endDate: string;
  isMust: boolean;

  maximumPerPatient: number;
  isActive: boolean;
  meetingsCompletedCount: number;
  meetingsInprogressCount: number;
  meetingsMissedCount: number;
  bonusPatientsCount: number;
  createdAt: string;
  updatedAt: string;
}

export const UserState: calenderStateType = {
  query: {
    actualInstructorId: 0,
    bankId: 0,
    departmentId: 0,
    instructorId: 0,
    organizationId: 0,
    treatmentId: 0,
    date: moment().startOf("month").format("YYYY-MM-DD"),
    q: "",
    mode: "month",
  },
  monthList: { loading: false, items: [], meta: {} },
  instructorList: { loading: false, items: [], meta: {} },
  weekList: { loading: false, items: [], meta: {} },

  error: "",
};

export type ContainerState = calenderStateType;
