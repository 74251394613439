import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  calenderMain: {},
  dayHeader: {
    background: "#F5F8FF",
    color: "#2A3333",
    fontFamily: "Cairo",
    fontSize: "14px",
    fontWeight: "normal !important",
    textTransform: "uppercase",
    border: "0px !important",
    textAlign: "left",
    paddingBlock: "10px !important",
    paddingInline: "10px !important",
    position: "relative",
  },
  eventOrigin: {
    position: "absolute",
    top: -12,
    left: 1,
    fontSize: "10px",
    fontWeight: "normal !important",
    color: "#fff",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    maxWidth: 100,
    paddingLeft: "2px",
    paddingRight: "2px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  view: {
    background: "#FFFFFF !important",
    fontFamily: "Cairo",
  },

  dayCell: {
    height: "100px",
    backgroundColor: "#FFFFFF !important",
  },

  dayHeaderContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
  },

  dayHeaderText: {
    fontFamily: "Cairo",
    fontSize: "14px",
    color: "#012397",
    textAlign: "center",
    width: "100%",
  },

  dayHeaderSubText: {
    fontFamily: "Cairo",
    fontSize: "16px",
    color: "#2A3333",
    textAlign: "center",
  },

  calenderPicker: {},

  addModal: {
    position: "absolute",
    background: "#FFFFFF",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "0px",
    boxShadow: "0",
    zIndex: "9999",
    width: "730px",
    // height: "463px",
  },
  modalContainer: {
    padding: "20px",
  },
  headerModal: {
    color: "#012497",
    fontFamily: "Cairo",
    fontWeight: "800",
    fontSize: "24px",
  },
  bodyContainer: {
    padding: "30px 0px",
  },
  labelHead: {
    fontFamily: "Cairo",
    fontWeight: 700,
    fontSize: "16px",
    color: "#2A3333",
    paddingBottom: "5px",
  },
  btnContainer: {
    display: "flex",
    alignItems: "center",
  },
  cancelBtn: {
    color: "#3D63E6",
    borderColor: "#3D63E6",
    "&:hover": {
      borderColor: "#3D63E6",
    },
    fontFamily: "Cairo",
    fontWeight: 700,
    fontSize: "16px",
    width: "160px",
    height: "50px",
  },
  headerContainer: {
    background: "#3D63E6",
    padding: "5px 0px",
    border: "1px solid #e0e0e0",
    borderBottom: "0px",
    color: "#ffffff",
  },
  headerText: {
    fontFamily: "Cairo",
    fontWeight: "700",
    fontSize: "16px",
    color: "#ffffff",
  },
  headerItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  slotLabel: {
    color: "#012497",
  },
  saveBtn: {
    background: "#3D63E6",
    color: "#ffff",
    "&:hover": {
      background: "#3D63E6",
    },
    fontFamily: "Cairo",
    fontWeight: 700,
    fontSize: "16px",
    width: "160px",
    height: "50px",
  },
  cancelBtn1: {
    color: "#3D63E6",
    border: "1px solid #3D63E6",
    "&:hover": {
      borderColor: "#3D63E6",
    },
    fontFamily: "Cairo",
    fontWeight: 700,
    fontSize: "16px",
    width: "160px",
    height: "50px",
  },
  dataTableDiv: {
    maxHeight: "calc(100vh - 285px)",
    overflowY: "auto",
    overflowX: "auto",
    border: "1px solid #C6C9CA",
    marginTop: "0px",
    background: "#F5F8FF",
  },
  eventContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    paddingInline: "1px",
    paddingBlock: "6px",
    borderRadius: "4px !important",
  },

  eventContainer1: {
    display: "flex",
    flexDirection: "column",
    minHeight: "30px",
    minWidth: "70px",
    paddingInline: "1px",
    borderTopLeftRadius: "0px",
    borderTopRightRadius: "4px !important",
    borderBottomLeftRadius: "4px !important",
    borderBottomRightRadius: "4px !important",
    height: "100% !important",
    overflow: "hidden",
    background: "#fff",
  },
  eventTitle: {
    textTransform: "capitalize",
    fontSize: "10px",
    overflow: "hidden",
  },
  eventTitle1: {
    textTransform: "capitalize",
    fontSize: "10px",
    color: "#012397 !important",
    overflow: "hidden",
  },
  eventClassname: {
    borderWidth: "0px !important",
    marginBottom: "2px !important",
  },
  timeEventClassname: {
    background: "#FFFFFF !important",
    border: "none !important",
  },

  eventTime: {
    fontSize: "10px",
    color: "#000000",
    fontFamily: "Cairo",
  },

  dayCellContent: {
    background: "#3D63E6",
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dayCellText: {
    fontFamily: "Cairo",
    fontSize: "14px",
    color: "#FFFFFF",
  },
}));
