export interface ResponseError {
  message: string;
}
export type RequestError = ResponseError;

export interface ItemsEntity {
  id?: number;
  userId?: number;
  messageType: string;
  content: string;
  createdAt: string;
  updatedAt: string;
  user?: User;
  isLoading?: boolean;
  notificationPatients: Array<NotificationPatientsEntity>;
  uniqueOrganizationNames?: string;
  uniqueBankNames?: string;
}
export interface User {
  id: number;
  firstName: string;
  lastName: string;
  gender: string;
  dateOfBirth: string;
  martialStatus: string;
  numberOfChildren: number;
  email: string;
  mobileNumber?: null;
  city: string;
  street: string;
  area: string;
  userGroupId?: null;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
}
export interface NotificationPatientsEntity {
  id: number;
  patientId: number;
  notificationId?: number;
  seenAt?: string | null;
  createdAt: string;
  updatedAt: string;
  patient: Patient;
  jsonData: any;
}
export interface Patient {
  id: number;
  organizationId: number;
  departmentId: number;
  bankId: number;
  patient_status: string;
  firstName: string;
  lastName: string;
  email: string;
  cellular: string;
  sex: string;
  birthDate: string;
  city: string;
  cityId: number;
  street: string;
  numberOfChildren?: number | null;
  status: string;
  howLongInCurrentStatus?: number | null;
  education: string;
  religion: string;
  comments?: null;
  economicLevel: string;
  dailyWorkHours: string;
  monthsInCurrentOrganization?: number | null;
  monthsInCurrentJobs?: number | null;
  anyBadHistory: string;
  intakeFinalMeetingHistory1?: null;
  intakeFinalMeetingHistory2?: null;
  intakeInitialMeetingHistory2?: null;
  intakeInitialMeetingHistory1?: null;
  progressAssignedSlot: number;
  progressAssignedTreatment: number;
  progressCompletedTreatment: number;
  progressDailyActivityWatched: number;
  progressDailyActivitySent: number;
  isActive: boolean;
  created_at: string;
  updated_at: string;
  bank: Bank;
  organization: Organization;
}
export interface Bank {
  id: number;
  assignedDate: string;
  organizationId: number;
  departmentId: number;
  name: string;
  status: string;
  individualPerPatient: number;
  groupPerPatient: number;
  totalPatientCapacity: number;
  isActive: boolean;
  document?: null;
  createdAt: string;
  updatedAt: string;
}
export interface Organization {
  id: number;
  name: string;
  taxNumber: string;
  isActive: boolean;
  comments: string;
  createdAt: string;
  updatedAt: string;
  __entity: string;
}

export interface PushNotificationsDetails {}

export type PushNotificationsStateType = {
  query: {
    limit: number;
    page: number;
    q: string;
    orderField: string;
    orderDirection: string;
    organizationId: number;
    messageType: string;
  };
  form: ItemsEntity;
  list: {
    items: Array<ItemsEntity>;
    meta: {
      totalItems: number;
      itemCount: number;
      itemsPerPage: number;
      totalPages: number;
      currentPage: number;
    };
    links: {
      first: string;
      last: string;
      previous: string;
      next: string;
    };
  };
  loading: boolean;
  error: string;
  notificationId?: number;
};

export const NotificationState: PushNotificationsStateType = {
  query: {
    limit: 10,
    page: 1,
    q: "",
    orderField: "",
    orderDirection: "",
    organizationId: 0,
    messageType: "All",
  },
  list: {
    items: [],
    meta: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 100,
      totalPages: 1,
      currentPage: 1,
    },
    links: {
      first: "",
      previous: "",
      next: "",
      last: "",
    },
  },
  loading: false,
  form: {
    messageType: "",
    content: "",
    createdAt: "",
    updatedAt: "",
    notificationPatients: [],
    isLoading: true,
  },
  error: "",
  notificationId: undefined,
};

export type ContainerState = PushNotificationsStateType;
