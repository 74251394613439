import { PayloadAction } from "@reduxjs/toolkit";
import { FormError } from "app/containers/types";
import set from "lodash/set";
import { createSlice } from "utils/@reduxjs/toolkit";
import { QuestionnaireListItemType, UserState } from "../types";
const initialState = UserState;
export const DepartmentListSlice = createSlice({
  name: "questionnaireListState",
  initialState,
  reducers: {
    editUser: (state, action: PayloadAction<QuestionnaireListItemType>) => {},
    removeUser: (state) => {
      const editUser: any = {};
      editUser.firstName = "";
      editUser.lastName = "";
      editUser.email = "";
      editUser.phoneNumber = "";
      editUser.creationDate = null;
      editUser.dateOfBirth = null;
    },
    create: (state) => {
      let item = {
        id: 0,
        questionnaireId: state.form.questionnaire.id.value,
        questionnaireName: state.form.questionnaire.questionnaireName.value,
        action: "new",
      };
      if (!state.form.questionnaire.id.value) return;
      let isSame = false;
      for (let i in state.list.items) {
        if (
          state.list.items[i].questionnaireName ===
          state.form.questionnaire.questionnaireName.value
        ) {
          isSame = true;
          break;
        }
      }
      if (!isSame) {
        state.list.items.push(item);
      } else {
        state.form.questionnaire.questionnaireName.error = "Dublicate value";
      }
    },
    getList: (state, action: PayloadAction<{ id: any }>) => {
      state.loading = true;
    },
    getUserById: (state, action: PayloadAction<{ id: any }>) => {
      state.loading = true;
    },
    getListSuccess: (
      state,
      action: PayloadAction<{ items: any[]; links: any; meta: any }>
    ) => {
      state.loading = false;
      const items = action.payload.items.map((x) => {
        return {
          id: x.id,
          questionnaireId: x.questionnaireId.name,
          questionnaireName: x.questionnaire.name,
          action: "",
        };
      });
      state.list.items =
        action.payload.meta.currentPage === 1
          ? items
          : [...state.list.items, ...items];
      state.list.meta = action.payload.meta;
    },
    getNextPage: (state, action: PayloadAction<any>) => {
      state.query.page = state.list.meta.currentPage + 1;
      state.loading = true;
    },
    getListComplete: (state) => {
      state.loading = false;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    update: (
      state,
      action: PayloadAction<{ id: any; callback: () => void }>
    ) => {
      state.error = "";
      state.loading = true;
    },
    updateFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      let isSame = false;
      for (let i in state.list.items) {
        console.log(
          state.list.items[i].questionnaireName,
          action.payload.value
        );
        if (state.list.items[i].questionnaireName === action.payload.value) {
          isSame = true;
          break;
        }
      }
      if (!isSame) {
        set(
          state.form.questionnaire,
          `${action.payload.key}.value`,
          action.payload.value
        );
        set(state.form.questionnaire, `${action.payload.key}.error`, "");
      } else {
        set(
          state.form.questionnaire,
          `${action.payload.key}.error`,
          "Dublicate value"
        );
      }
    },
    updateSuccess(state, action: PayloadAction<QuestionnaireListItemType>) {
      state.loading = false;
    },
    updateFailed: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    delete: (state) => {
      state.error = "";
      state.loading = true;
    },
    deleteSuccess: (state) => {
      state.loading = false;
      state.error = "";
    },
    deleteFailed: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    resetNewQuestionnaireForm(state) {
      state.error = "";
      state.form = initialState.form;
    },
    setFormErrors(
      state,
      action: PayloadAction<{
        key: string;
        errors: FormError[];
      }>
    ) {
      action.payload.errors.forEach((error) => {
        set(
          state.form,
          `${action.payload.key}.${error.name}.error`,
          error.error
        );
      });
    },
    getNextPageItems: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    getNextPageItemsSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.list.items = state.list.items.concat(action.payload.items);
      state.list.meta.currentPage = action.payload.meta.currentPage;
      state.list.links = action.payload.links;
      state.query.page = action.payload.meta.currentPage;
    },
    setQuery: (state, action: PayloadAction<{ name: string; value: any }>) => {
      const { name, value } = action.payload;
      state.query.page = 1;
      state.list = initialState.list;
      set(state.query, name, value);
    },
    resetModule: (state) => {
      state.list.items = [];
    },
    handleBlock: (state, action: PayloadAction<string>) => {},
    handleBlockSuccess: (
      state,
      action: PayloadAction<QuestionnaireListItemType>
    ) => {},
    deleteQuestionnaire: (state, action) => {
      const { id, idx } = action.payload;
      if (id) {
        for (let i = 0; i < state.list.items.length; i++) {
          const item = state.list.items[i];
          if (item.id === id) {
            state.list.items[i].action = "delete";
            break;
          }
        }
      } else {
        state.list.items = state.list.items.filter(
          (item, index) => index !== idx
        );
      }
    },
  },
});
export const { reducer, actions, name: sliceKey } = DepartmentListSlice;
