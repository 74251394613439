import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles((theme: Theme) => ({
  gridBtnSmall: {
    height: "56px",
    justifyContent: "center",
    flexDirection: "row",
    display: "flex",
  },
}));
