import { PayloadAction } from "@reduxjs/toolkit";
import { FormError } from "app/containers/types";
import set from "lodash/set";
import { createSlice } from "utils/@reduxjs/toolkit";
import { TimeSlotListItemType, TimeSlotState } from "../types";
const initialState = TimeSlotState;
export const timeSlotSlice = createSlice({
  name: "timeslotListState",
  initialState,
  reducers: {
    editTimeSlot: (state, action: PayloadAction<TimeSlotListItemType>) => {},

    create: (state, action) => {
      state.loading = true;
    },
    createSuccess: (state) => {
      state.loading = false;
      // state.form.user = initialState.form.user;
    },
    getList: (state) => {
      state.loading = true;
    },
    getUserById: (state, action: PayloadAction<{ id: any }>) => {
      state.loading = true;
    },
    getListSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      const items = action.payload.items;
      state.list.items =
        action.payload.meta.currentPage === 1
          ? items
          : [...state.list.items, ...items];
      state.list.meta = action.payload.meta;
    },
    getOrganizationList: (state) => {
      state.loading = true;
    },
    getOrganizationListSuccess: (state, action) => {
      state.loading = false;
      state.organization.items = action.payload.map((item) => {
        return {
          id: item.id,
          organizationName: item.name,
        };
      });
    },

    getNextPage: (state, action: PayloadAction<any>) => {
      state.query.page = state.list.meta.currentPage + 1;
      state.loading = true;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    update: (state, action: PayloadAction<any>) => {
      state.error = "";
      state.loading = true;
    },
    updateFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      // set(state.form, `${action.payload.key}.value`, action.payload.value);
      // set(state.form, `${action.payload.key}.error`, null);
      state.error = "";
    },
    updateSuccess(state, action: PayloadAction<TimeSlotListItemType>) {
      state.loading = false;
    },
    updateFailed: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },

    delete: (state) => {
      state.error = "";
      state.loading = true;
    },
    deleteSuccess: (state) => {
      state.loading = false;
      state.error = "";
    },
    deleteFailed: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    resetNewUserForm(state) {
      state.error = "";
      // state.form = initialState.form;
    },
    setFormErrors(
      state,
      action: PayloadAction<{
        key: string;
        errors: FormError[];
      }>
    ) {
      action.payload.errors.forEach((error) => {
        // set(
        //   state.form,
        //   `${action.payload.key}.${error.name}.error`,
        //   error.error
        // );
      });
    },
    getNextPageItems: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    getNextPageItemsSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.list.items = state.list.items.concat(action.payload.items);
      state.list.meta.currentPage = action.payload.meta.currentPage;
      state.list.links = action.payload.links;
      state.query.page = action.payload.meta.currentPage;
    },
    setQuery: (state, action: PayloadAction<{ name: string; value: any }>) => {
      const { name, value } = action.payload;
      state.query.page = 1;
      state.list = initialState.list;
      set(state.query, name, value);
    },
    resetModule: (state) => {
      state = initialState;
    },
    resetData: (state) => {
      state.patientPermission = initialState.patientPermission;
      state.qualification = initialState.qualification;
    },
  },
});
export const { reducer, actions, name: sliceKey } = timeSlotSlice;
