import queryString from "query-string";
import { delay, put, select, takeLatest } from "redux-saga/effects";
import { API_URL } from "utils/constants";
import {
  TRANSLATE,
  getDefaultHeaders,
  removeBlankProperties,
} from "utils/helpers";
import { request } from "utils/request";
import toastService from "utils/toast";
import { Activity, Form } from "../types";
import { selectForm, selectQuery } from "./selector";
import { actions } from "./slice";

export function* createOrUpdate(action) {
  yield delay(500);
  try {
    const form: Form = yield select(selectForm);

    const payload = {
      id: form.id,
      organizationId: form.organizationId.value,
      activationDayNumber: form.activationDayNumber.value,
      name: form.name.value,
      description: form.description.value,
      resources: form.resources.filter((f) => !!f.resourcePath),
      scheduledAt: form.scheduledAt.value,
    };
    const options = {
      method: form.id ? "PATCH" : "POST",
      headers: getDefaultHeaders(),
      body: JSON.stringify(payload),
    };
    const URL = form.id
      ? `${API_URL}/v1/daily-activity/${form.id}`
      : `${API_URL}/v1/daily-activity`;
    const response = yield request(URL, options);
    yield put(actions.createOrUpdateSuccess());
    yield put(actions.getList());
  } catch (e: any) {
    yield put(actions.createOrUpdateFailure());
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", {
        error: JSON.stringify(e),
      })
    );
  }
}

export function* getList(action) {
  yield delay(500);
  const query = yield select(selectQuery);
  const requestData = removeBlankProperties(query);
  const queries = queryString.stringify({
    ...requestData,
  });
  try {
    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    const response = yield request(
      `${API_URL}/v1/daily-activity?${queries}`,
      options
    );
    yield put(actions.getListSuccess(response));
  } catch (e: any) {
    yield put(actions.getListFailure());
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", {
        error: JSON.stringify(e),
      })
    );
  }
}

export function* getById(action) {
  yield delay(500);

  try {
    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    const response: Activity = yield request(
      `${API_URL}/v1/daily-activity/${action.payload.id}`,
      options
    );
    yield put(actions.getByIdSuccess(response));
  } catch (e: any) {
    yield put(actions.getListByIdFailure());
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", {
        error: JSON.stringify(e),
      })
    );
  }
}

export function* toggleStatusApi(action) {
  yield delay(500);
  try {
    const options = {
      method: "PATCH",
      headers: getDefaultHeaders(),
    };
    yield request(
      `${API_URL}/v1/daily-activity/${action?.payload}/status`,
      options
    );
    yield put(actions.toggleStatusSuccess(action?.payload));
    toastService.success(TRANSLATE("ORGANIZATIONS.DATA_UPDATED_SUCCESSFULLY"));
  } catch (error: any) {
    let message = error?.message;
    try {
      message = JSON.parse(error?.message);
    } catch (e) {
      console.log(e);
    }
    if (message) {
      yield put(
        actions.toggleStatusFailure({
          id: action?.payload,
          message,
        })
      );
      toastService.error(
        TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", {
          error: JSON.stringify(message),
        })
      );
    }
  }
}

export function* useUserSaga() {
  yield takeLatest(actions.getList.type, getList);
  yield takeLatest(actions.getNextPage.type, getList);
  yield takeLatest(actions.createOrUpdate.type, createOrUpdate);
  yield takeLatest(actions.getById.type, getById);
  yield takeLatest(actions.toggleStatus.type, toggleStatusApi);
}
