import CreatePassword from "app/containers/Auth/CreatePassword";
import ForgotPassword from "app/containers/Auth/ForgotPassword/ForgotPassword";
import ResetPassword from "app/containers/Auth/ResetPassword";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { AuthPage } from "../containers/Auth";
import LoginForm from "../containers/Auth/LoginPage/form";

export function AuthRoutes() {
  return (
    <Routes>
      <Route path="/login/*" element={<AuthPage />}>
        <Route index element={<LoginForm className="" />} />
      </Route>
      <Route path="/recovery/*" element={<AuthPage />}>
        <Route index path="reset-password" element={<ResetPassword />} />
        <Route path="create-password" element={<CreatePassword />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
      </Route>
    </Routes>
  );
}
