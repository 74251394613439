import { Grid } from "@mui/material";
import OrganizationField from "app/components/FieldComponent/Organization";
import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectQuery } from "../../redux/selector";
import { actions } from "../../redux/slice";

const FiltersForm = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const form = useSelector(selectQuery);

  const setPageQuery = (name: string, value: any) => {
    dispatch(actions.setQuery({ name, value }));
  };

  useEffect(() => {
    return () => {
      dispatch(actions.resetQuery());
    };
  }, [dispatch]);

  return (
    <div>
      <Grid sx={{ padding: "20px 0px 0px 0px" }}>
        <Grid item container spacing={2} md={12} sx={{ paddingBottom: "10px" }}>
          <OrganizationField
            value={form.organizationId}
            xs={2}
            changeValue={(value) => {
              setPageQuery("organizationId", value);
            }}
            showAll={true}
            activeRequired={false}
          ></OrganizationField>
        </Grid>
      </Grid>
    </div>
  );
};

export default FiltersForm;
