import { UserGroupState } from "app/containers/Users/types";
import { FormElement } from "app/containers/types";

export interface ResponseError {
  message: string;
}
export type RequestError = ResponseError;

export type FieldsListItemType = {
  enable: Boolean;
  firstName: string;
  lastName: string | null;
  email: string | null;
  mobileNumber: string | null;
  sex: string | null;
  dateOfBirth: string;
  city: string | null;
  groupName: string | null;
  lastLogin: string | null;
  assignedTreatmentType: string | null;
  noOfActiveBankAssigned: string | null;
  noOfFutureTimeSlots: string | null;
};
export type CityListItemType = {
  id: number;
  name: string;
};
export type OrganizationsListItemType = {
  id: number;
  organizationName: string;
};

export type FieldGroupListItemType = {
  id: number;
  fieldGroupName: string;
};

export type InstructorListItemType = {
  id: number;
  firstName: string;
  lastName: string;
};

export type TreatmentListItemType = {
  id: number;
  treatmentName: string;
  type: string;
  session_count: number;
  session_duration: number;
  maximum_participants: number;
};
export interface NewFieldRequest {
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
  companyName: string;
  designation: any;
  numberOfEmployees: string;
  field_type: string;
  isActive: any;
}

export type NewFieldResponse = {
  id: any;
  codeSentAt: any;
  firstName: string;
  lastName: string;
  mobileNumber: string;
  countryCode: any;
  email: string;
  companyName: string;
  designation: string;
  numberOfEmployees: string;
  language: string;
  isActive: true;
  role: any;
  image: any;
  fieldType: string;
  createdAt: string;
  updatedAt: string;
  fullName: string;
  message?: string;
};

export type FieldForm = {
  id: FormElement;
  firstName: FormElement;
  lastName: FormElement;
  email: FormElement;
  dob: FormElement;
  mobileNumber: FormElement;
  createdAt: FormElement;
  updatedAt: FormElement;
  ip: FormElement;
  mac: FormElement;
  field_type: FormElement;
};

export type OrganisationState = {
  items: Array<OrganizationsListItemType>;
  meta: {
    totalItems: number;
    itemCount: number;
    itemsPerPage: number;
    totalPages: number;
    currentPage: number;
  };
  links: {
    first: string;
    last: string;
    previous: string;
    next: string;
  };
  loading?: boolean;
};

export type QuestionnaireState = {
  id: number;
  name: string;
};

export type FieldGroupState = {
  items: Array<FieldGroupListItemType>;
  meta: {
    totalItems: number;
    itemCount: number;
    itemsPerPage: number;
    totalPages: number;
    currentPage: number;
  };
  links: {
    first: string;
    last: string;
    previous: string;
    next: string;
  };
};

export type TreatmentState = {
  items: Array<TreatmentListItemType>;
  meta: {
    totalItems: number;
    itemCount: number;
    itemsPerPage: number;
    totalPages: number;
    currentPage: number;
  };
  links: {
    first: string;
    last: string;
    previous: string;
    next: string;
  };
};
export type InstructorState = {
  items: Array<InstructorListItemType>;
  meta: {
    totalItems: number;
    itemCount: number;
    itemsPerPage: number;
    totalPages: number;
    currentPage: number;
  };
  links: {
    first: string;
    last: string;
    previous: string;
    next: string;
  };
};
export type GlobalFieldsStateType = {
  userGroup: UserGroupState;
  city: {
    items: Array<CityListItemType>;
    meta: {
      totalItems: number;
      itemCount: number;
      itemsPerPage: number;
      totalPages: number;
      currentPage: number;
    };
    links: {
      first: string;
      last: string;
      previous: string;
      next: string;
    };
  };
  loading: boolean;
  error: string;
  organization: OrganisationState;
  questionnaire: QuestionnaireState[];
  fieldGroup: FieldGroupState;
  treatment: TreatmentState;
  instructor: InstructorState;
  actualInstructor: InstructorState;
  qualification: any;
  departments: any;
  banks: any;
  bankLoading?: boolean;
  patientPermission: any;
};

export const FieldState: GlobalFieldsStateType = {
  userGroup: {
    items: [],
    meta: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 100,
      totalPages: 1,
      currentPage: 1,
    },
    links: {
      first: "",
      previous: "",
      next: "",
      last: "",
    },
  },
  city: {
    items: [],
    meta: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 100,
      totalPages: 1,
      currentPage: 1,
    },
    links: {
      first: "",
      previous: "",
      next: "",
      last: "",
    },
  },
  loading: false,
  questionnaire: [],
  organization: {
    items: [],
    meta: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 100,
      totalPages: 1,
      currentPage: 1,
    },
    links: {
      first: "",
      previous: "",
      next: "",
      last: "",
    },
  },
  fieldGroup: {
    items: [
      {
        id: 1,
        fieldGroupName: "FieldGroup 1",
      },
    ],
    meta: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 100,
      totalPages: 1,
      currentPage: 1,
    },
    links: {
      first: "",
      previous: "",
      next: "",
      last: "",
    },
  },
  banks: [],
  departments: {
    items: [],
    meta: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 100,
      totalPages: 1,
      currentPage: 1,
    },
    links: {
      first: "",
      previous: "",
      next: "",
      last: "",
    },
  },
  qualification: {
    items: [],
    meta: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 100,
      totalPages: 1,
      currentPage: 1,
    },
    links: {
      first: "",
      previous: "",
      next: "",
      last: "",
    },
  },
  patientPermission: {
    items: [],
    meta: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 100,
      totalPages: 1,
      currentPage: 1,
    },
    links: {
      first: "",
      previous: "",
      next: "",
      last: "",
    },
  },
  treatment: {
    items: [],
    meta: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 100,
      totalPages: 1,
      currentPage: 1,
    },
    links: {
      first: "",
      previous: "",
      next: "",
      last: "",
    },
  },
  instructor: {
    items: [
      {
        id: 1,
        firstName: "",
        lastName: "",
      },
    ],
    meta: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 100,
      totalPages: 1,
      currentPage: 1,
    },
    links: {
      first: "",
      previous: "",
      next: "",
      last: "",
    },
  },
  actualInstructor: {
    items: [
      {
        id: 1,
        firstName: "",
        lastName: "",
      },
    ],
    meta: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 100,
      totalPages: 1,
      currentPage: 1,
    },
    links: {
      first: "",
      previous: "",
      next: "",
      last: "",
    },
  },
  error: "",
};

export type ContainerState = GlobalFieldsStateType;
