import { Bank } from "app/containers/AssignedTreament/types";
import { FormElement } from "app/containers/types";

export interface ResponseError {
  message: string;
}
export type RequestError = ResponseError;

export type PatientsListItemType = {
  organizationName: string;
  departmentName: string | null;
  bankName: string | null;
  assignedAdvisor: string | null;
  patientFirstName: string | null;
  patientLastName: string | null;
  age: string | null;
  sex: string | null;
  dateOfBirth: string | null;
  city: string | null;
  assignedTreatment: string | null;
  assignedSlots: string | null;
};
export type AssignedTreatmentTimeSlot = { id: number; meetingId: string };
export type AssignedTreatmentItemType = {
  id: number;
  banktreatmentId: number;
  treatmentId: number;
  instructorId: number;
  treatmentTypeName: string;
  groupIndividual: string;
  numberOfSession: number;
  description: string;
  isMust: boolean;
  maximumPerPatient: number;
  timeSlotId: number;
  timeSlots: AssignedTreatmentTimeSlot[];
  optionalInstructor: Array<any>;
  bonusRegular: string | null;
  reschedule: boolean;
};

export interface NewUserRequest {
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
  companyName: string;
  designation: any;
  numberOfEmployees: string;
  user_type: string;
  isActive: any;
}

export type NewUserResponse = {
  id: any;
  codeSentAt: any;
  firstName: string;
  lastName: string;
  mobileNumber: string;
  countryCode: any;
  email: string;
  companyName: string;
  designation: string;
  numberOfEmployees: string;
  language: string;
  isActive: true;
  role: any;
  image: any;
  userType: string;
  createdAt: string;
  updatedAt: string;
  fullName: string;
  message?: string;
};

export type TComments = {
  id: number;
  content: string;
  time: string;
  name: string;
};

export interface QuestionerResult {
  id: number;
  type: string;
  status: string;
  createdAt: string;
  answeredAt: string;
  updatedAt: string;
  questionnaireId: number;
  organizationId: number;
  patientId: number;
  questionnaireResultStatId: number;
  score: number;
  rangeId: number;
  patient: Patient;
  questionnaire: Questionnaire;
  organization: Organization;
}

export interface Patient {
  id: number;
  organizationId: number;
  departmentId: number;
  bankId: number;
  patient_status: string;
  firstName: string;
  lastName: string;
  email: string;
  cellular: string;
  sex: string;
  birthDate: string;
  cityId: number;
  street: string;
  numberOfChildren: number;
  status: string;
  howLongInCurrentStatus: any;
  education: string;
  religion: string;
  comments: any;
  economicLevel: string;
  dailyWorkHours: string;
  monthsInCurrentOrganization: any;
  monthsInCurrentJobs: number;
  anyBadHistory: string;
  intakeFinalMeetingHistory1: string;
  intakeFinalMeetingHistory2: string;
  intakeInitialMeetingHistory2: string;
  intakeInitialMeetingHistory1: string;
  progressAssignedSlot: number;
  progressAssignedTreatment: number;
  progressCompletedTreatment: number;
  progressDailyActivityWatched: number;
  progressDailyActivitySent: number;
  isActive: boolean;
  isMobileRegistered: boolean;
  mobileNotificationEnabled: boolean;
  created_at: string;
  fcmToken: string;
  profilePic: any;
  activatedAt: string;
  updated_at: string;
  age: number;
}

export interface Questionnaire {
  id: number;
  name: string;
  type: string;
  totalQuestions: number;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  organizationId: number;
}

export interface Organization {
  id: number;
  name: string;
  taxNumber: string;
  isActive: boolean;
  comments: any;
  createdAt: string;
  updatedAt: string;
  __entity: string;
}

export type QuestionnaireResultList = {
  isLoading: boolean;
  items: Array<QuestionerResult>;
  meta: {
    totalItems: number;
    itemCount: number;
    itemsPerPage: number;
    totalPages: number;
    currentPage: number;
  };
};
export type PatientForm = {
  id: number;
  organizationId: FormElement;
  departmentId: FormElement;
  bankId: FormElement;
  patientStatus: FormElement;
  firstName: FormElement;
  lastName: FormElement;
  email: FormElement;
  cellular: FormElement;
  sex: FormElement;
  birthDate: FormElement;
  street: FormElement;
  city: FormElement;
  status: FormElement;
  howLongInCurrentStatus: FormElement;
  numberOfChildern: FormElement;
  education: FormElement;
  religion: FormElement;
  economicLevel: FormElement;
  monthsInCurrentOrganization: FormElement;
  monthsInCurrentJobs: FormElement;
  dailyWorkJobs: FormElement;
  anyBadHistory: FormElement;
  comments: FormElement;
  cityId: FormElement;
  intakeFinalMeetingHistory1: FormElement;
  intakeFinalMeetingHistory2: FormElement;
  intakeInitialMeetingHistory1: FormElement;
  intakeInitialMeetingHistory2: FormElement;
  progressAssignedSlot: number;
  progressCompletedSlot: number;
  progressAssignedTreatment: number;
  progressCompletedTreatment: number;
  progressDailyActivitySent: number;
  progressDailyActivityWatched: number;
  timeZone: string;
};
export type WellBeingResult = {
  t0: number;
  t1: number;
  questionnaire: any;
};
export type PatientsListStateType = {
  importModalOpen: boolean;
  importModalOpenLoading: boolean;
  selectedTreatment?: any;
  selectedTreatmentIndex: number;
  query: {
    limit: number;
    page: number;
    distributorId: string;
    user_type: string;
    q: string;
    orderField: string;
    orderDirection: string;
    createMin: string;
    createMax: string;
    updateMin: string;
    updateMax: string;
    departmentId: number;
    bankId: number;
    instructorId: number;
    organizationId: number;
  };

  form: {
    wellBeingResults: WellBeingResult[];
    patient: PatientForm;
    comments: TComments[];
    questionnaireResults: QuestionnaireResultList;
    bank?: Bank;
  };
  list: {
    items: Array<PatientsListItemType>;
    selectedIds: Array<Number>;
    selectedAll: boolean;
    meta: {
      totalItems: number;
      itemCount: number;
      itemsPerPage: number;
      totalPages: number;
      currentPage: number;
    };
    links: {
      first: string;
      last: string;
      previous: string;
      next: string;
    };
  };
  loading: boolean;
  timeSlotModal: boolean;
  error: string;
  departments: Array<any>;
  organizations: Array<any>;
  banks: Array<any>;
  assignTreatmentIndividualPatient: number;
  assignTreatmentGroupPatient: number;
  assignedTreatment: Array<AssignedTreatmentItemType>;
  popupTimeSlot: Array<any>;
  timeSlotType: string;
};

export const PatientState: PatientsListStateType = {
  importModalOpen: false,
  importModalOpenLoading: false,
  selectedTreatment: undefined,
  selectedTreatmentIndex: -1,
  query: {
    user_type: "",
    distributorId: "",
    limit: 5,
    page: 1,
    q: "",
    orderField: "",
    orderDirection: "",
    updateMax: "",
    updateMin: "",
    createMax: "",
    createMin: "",
    departmentId: 0,
    bankId: 0,
    instructorId: 0,
    organizationId: 0,
  },
  list: {
    selectedAll: false,
    selectedIds: [],
    items: [],
    meta: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 100,
      totalPages: 1,
      currentPage: 1,
    },
    links: {
      first: "",
      previous: "",
      next: "",
      last: "",
    },
  },
  loading: false,
  timeSlotModal: false,
  organizations: [],
  departments: [],
  banks: [],
  assignTreatmentGroupPatient: 0,
  assignTreatmentIndividualPatient: 0,
  assignedTreatment: [],
  popupTimeSlot: [],
  form: {
    wellBeingResults: [],
    patient: {
      id: 0,
      organizationId: { value: 0 },
      departmentId: { value: 0 },
      bankId: { value: 0 },
      patientStatus: { value: "New" },
      firstName: { value: "" },
      lastName: { value: "" },
      email: { value: "" },
      cellular: { value: "" },
      sex: { value: "" },
      birthDate: { value: null },
      street: { value: "" },
      city: { value: "" },
      status: { value: "" },
      howLongInCurrentStatus: { value: "" },
      numberOfChildern: { value: "" },
      education: { value: "" },
      religion: { value: "" },
      economicLevel: { value: "" },
      monthsInCurrentOrganization: { value: "" },
      monthsInCurrentJobs: { value: "" },
      dailyWorkJobs: { value: "" },
      anyBadHistory: { value: "" },
      comments: { value: "" },
      cityId: { value: 0 },
      intakeFinalMeetingHistory1: { value: "" },
      intakeFinalMeetingHistory2: { value: "" },
      intakeInitialMeetingHistory1: { value: "" },
      intakeInitialMeetingHistory2: { value: "" },
      progressAssignedSlot: 0,
      progressCompletedSlot: 0,
      progressAssignedTreatment: 0,
      progressCompletedTreatment: 0,
      progressDailyActivitySent: 0,
      progressDailyActivityWatched: 0,
      timeZone: "",
    },
    comments: [],
    questionnaireResults: {
      isLoading: false,
      items: [] as QuestionerResult[],
      meta: {
        totalItems: 0,
        itemCount: 0,
        itemsPerPage: 100,
        totalPages: 1,
        currentPage: 1,
      },
    },
  },
  error: "",
  timeSlotType: "Regular",
};

export type CreateImportPayload = {
  file: string;
  treatmentBankId: number;
  type: "PATIENTS";
  processNow: boolean;
};

export type ContainerState = PatientsListStateType;
