import { Button, Card, Grid, Modal, Tooltip, Typography } from "@mui/material";
import { BottomActions } from "app/components/BottomActions";
import DataTable from "app/components/DataTable";
import { CustomDrawer } from "app/components/Drawer";
import { ScrollableView } from "app/components/ScrollableView";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  selectFeaturePermissions,
  selectIsAdminRole,
} from "store/global/authPermission/selector";
import { PermissionFeature } from "store/global/authPermission/types";
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import Topbar from "../../../Dashboard/components/topbar";
import { useOrganizationSaga } from "../../redux/saga";
import { selectList, selectLoading, selectQuery } from "../../redux/selector";
import { actions, reducer, sliceKey } from "../../redux/slice";
import { useStyles } from "./styles";

const List = () => {
  useInjectReducer({ key: sliceKey, reducer });
  useInjectSaga({ key: sliceKey, saga: useOrganizationSaga });

  const [openModal, setOpenModal] = useState(false);
  const [openDelete, setDelete] = useState(false);

  const { meta, items } = useSelector(selectList);

  const { q, orderField, orderDirection } = useSelector(selectQuery);

  const featurePermissions = useSelector(selectFeaturePermissions);
  const { hasFullAccess, hasReadAccess, hasNoAccess } =
    featurePermissions[PermissionFeature.ORGANIZATIONS_DETAILS];

  const isAdminRole = useSelector(selectIsAdminRole);

  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const hasMoreItems = meta.currentPage < meta.totalPages;
  const getNextPage = () => {
    if (!loading) {
      dispatch(actions.getNextPage(meta.currentPage + 1));
    }
  };

  const columns = [
    {
      id: "name",
      label: t("ORGANIZATIONS.ORGANIZATIONAL_NAME"),
      sortValue: "selector.name",
    },
    {
      id: "taxNumber",
      label: t("ORGANIZATIONS.TAX_NUMBER"),
      sortValue: "selector.taxNumber",
    },
    {
      id: "contactName",
      label: t("ORGANIZATIONS.CONTACT_NAME"),
      sortValue: undefined,
      format: (val) => {
        const list = val.organizationContact
          .map((i) => i.contactName)
          .join(",");
        return (
          <Tooltip title={list}>
            <Typography className={styles.contacts}> {list} </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "mobileNumber",
      label: t("ORGANIZATIONS.CONTACT_PHONE_NUMBER"),
      format: (val) => {
        const list = val.organizationContact
          .map((i) => i.mobileNumber)
          .join(",");
        return (
          <Tooltip title={list}>
            <Typography className={styles.contacts}> {list} </Typography>
          </Tooltip>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(actions.getList());
  }, [q, orderField, orderDirection]);

  const setPageQuery = (name: string, value: any) => {
    dispatch(actions.setQuery({ name, value }));
  };
  const handleModal = () => {
    navigate("/organizations/new");
  };
  const handleModalClose = () => {
    setOpenModal(false);
  };
  const handleEdit = (e) => {
    navigate(`/organizations/${e.id}/edit`, { state: { e } });
  };
  const handleDoubleClick = (e) => {
    if (hasFullAccess || hasReadAccess) {
      window.scrollTo(0, 0);
      navigate(`/organizations/${e.id}/view`, { state: { e } });
    }
  };
  // navigate('/users/11/edit');
  const handleDelete = () => {
    setDelete(true);
  };
  const handleDeleteClose = () => {
    setDelete(false);
  };
  const switchAction = (row, index) => {
    dispatch(actions.enableDisable({ row, id: index }));
  };

  const loading = useSelector(selectLoading);
  const { height } = useWindowDimensions();

  let availableActions = hasFullAccess ? "Edit" : "";
  availableActions += isAdminRole ? ",Toggle" : "";
  return (
    <div className={styles.mainContainer}>
      <CustomDrawer />
      <Grid item xs={12}>
        <Topbar q={q} setSearch={(q) => setPageQuery("q", q)} />
        <div className={styles.root}>
          <>
            <ScrollableView
              loadMore={getNextPage}
              hasMoreItems={hasMoreItems}
              height={height - 200}
            >
              <DataTable
                loading={loading}
                orderDirection={orderDirection}
                orderField={orderField}
                setQuery={setPageQuery}
                rows={items}
                columns={columns}
                Actions={availableActions}
                editAction={handleEdit}
                deleteAction={handleDelete}
                onRowDoubleClick={handleDoubleClick}
                isPointerCursor={true}
                switchAction={switchAction}
              />
            </ScrollableView>
            <Modal
              open={openDelete}
              onClose={handleDeleteClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Card className={styles.deleteModal}>
                <div className={styles.modalContainer}>
                  <Grid>
                    <Typography align="center" className={styles.headerModal}>
                      {t("ORGANIZATIONS.DELETE_ORGANIZATION")}
                    </Typography>
                  </Grid>
                  <Grid sx={{ padding: "30px 0px" }}>
                    <Typography align="center" className={styles.deleteTypo}>
                      {t("ORGANIZATIONS.CONFIRM_DELETE_ORGANIZATION")}
                    </Typography>
                  </Grid>
                  <div>
                    <Grid container spacing={3}>
                      <Grid
                        className={styles.btnContainer}
                        container
                        item
                        xs={6}
                        direction="column"
                      >
                        <Button
                          className={styles.cancelBtn}
                          variant="outlined"
                          onClick={handleDeleteClose}
                        >
                          {t("BUTTONS.CANCEL")}
                        </Button>
                      </Grid>
                      <Grid container item xs={6} direction="column">
                        <Button className={styles.saveBtn} variant="contained">
                          {t("BUTTONS.DELETE")}
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Card>
            </Modal>
          </>

          <BottomActions
            disabled={!hasFullAccess}
            chipBoxes={[
              {
                label: t("PLACEHOLDERS.TOTAL_ITEMS"),
                count: meta.totalItems,
              },
            ]}
            onClickFab={isAdminRole ? handleModal : undefined}
          />
        </div>
      </Grid>
    </div>
  );
};
export default List;
