export enum StatusType {
  NEW,
  TAKEN_CARE_OF,
  IRRELEVANT,
  CLOSED,
  CANCELED,
}
export enum FileType {
  PDF,
  VIDEO,
  AUDIO,
  IMAGE,
}
