import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Button,
  Card,
  Grid,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import FileUpload from "app/components/FileUpload";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { FileType } from "utils/enum";
import { TRANSLATE } from "utils/helpers";
import { selectEditContent, selectList } from "../../redux/selector";
import { actions } from "../../redux/slice";
import { useStyles } from "./styles";
import { isDuplicate, onValidate } from "./validator";

const AddTreatmentContent = ({
  treatmentId,
  openModal,
  handleModalClose,
  disable,
  mode,
  noofSession,
  editIndex,
}) => {
  const [beforeAfter, setBeforeAfter] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [sessionNum, setsessionNum] = useState(false);
  const dispatch = useDispatch();
  const form = useSelector(selectEditContent);
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const listitems = useSelector(selectList);
  const { treatment } = listitems;

  function videoClose() {
    const key = `content.videoFile`;
    dispatch(
      actions.updateFormFileResourceObj({ resourceKey: key, resourceObj: {} })
    );
  }
  function audioClose() {
    const key = `content.audioFile`;
    dispatch(
      actions.updateFormFileResourceObj({ resourceKey: key, resourceObj: {} })
    );
  }
  function pdfClose() {
    const key = `content.pdfFile`;
    dispatch(
      actions.updateFormFileResourceObj({ resourceKey: key, resourceObj: {} })
    );
  }

  const onChange = (e) => {
    // TODO: DRY
    if (e.target.name === "hours") {
      const regex = /^[0-9\b]+$/;
      const { name } = e.target;
      const key = `content.${name}`;
      const value = regex.test(e.target.value)
        ? e.target.value
        : e.target.value.replace(/[^0-9]/g, "");
      dispatch(actions.updateFormValue({ key, value }));
    } else {
      const { name } = e.target;
      const key = `content.${name}`;
      const value = e.target.value;
      dispatch(actions.updateFormValue({ key, value }));
    }
  };

  const handleSaveBtn = (e) => {
    const errors = onValidate(form);
    if (errors?.length > 0) {
      dispatch(
        actions.setFormErrors({
          key: "content",
          errors,
        })
      );
      return;
    }
    const exists = isDuplicate(form, treatment);
    if (exists) {
      dispatch(
        actions.setFormErrors({
          key: "content",
          errors: [
            {
              name: "name",
              error: TRANSLATE("TREATMENT_TYPES.TREATMENT_CONTENT_EXISTS"),
            },
          ],
        })
      );
      return;
    }
    if (mode === "edit") {
      dispatch(
        actions.doaddTreatmentContentItem({
          id: form.id?.value,
          videoFile: form.videoFile,
          audioFile: form.audioFile,
          pdfFile: form.pdfFile,
          treatmentId: treatmentId,
          name: form.name.value,
          beforeAfter: form.beforeAfter.value,
          hours: form.hours.value,
          at: form.beforeAfter.value,
          session_number: form.session_id.value,
          session:
            form.session_id.value > 1 ? "multi session" : "single session",
          session_id: form.session_id.value,
          duration: form.hours.value,
          description: form.description.value,
          index: editIndex,
          type: "edit",
        })
      );
    } else {
      dispatch(
        actions.doaddTreatmentContentItem({
          id: 0,
          videoFile: form.videoFile,
          audioFile: form.audioFile,
          pdfFile: form.pdfFile,
          treatmentId: treatmentId,
          name: form.name.value,
          beforeAfter: form.beforeAfter.value,
          hours: form.hours.value,
          at: form.beforeAfter.value,
          session_number: form.session_id.value,
          session:
            form.session_id.value > 1 ? "multi session" : "single session",
          session_id: form.session_id.value,
          duration: form.hours.value,
          description: form.description.value,
          type: "add",
        })
      );
    }
  };

  const styles = useStyles();

  const hasFileErrors =
    form?.videoFile?.error || form?.pdfFile?.error || form?.audioFile?.error;
  return (
    <Modal
      open={openModal}
      onClose={() => {}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card
        className={styles.addModal}
        sx={{
          direction: theme.direction,
        }}
      >
        <div className={styles.modalContainer}>
          <Grid>
            <Typography align="center" className={styles.headerModal}>
              {mode === "add" || mode === "new"
                ? t("TREATMENT_TYPES.ADD")
                : t("TREATMENT_TYPES.EDIT")}{" "}
              {t("TREATMENT_TYPES.TREATMENT_CONTENT")}
            </Typography>
          </Grid>
          <Grid
            xs={12}
            container
            rowSpacing={1.5}
            sx={{
              padding: "20px 0px",
              margin: "0px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid item xs={3}>
              <label className={styles.label}>
                {t("TREATMENT_TYPES.NAME")}
              </label>
              <TextField
                margin="none"
                fullWidth
                variant="outlined"
                required
                autoComplete={"off"}
                className={styles.textEnabled}
                placeholder={t("TREATMENT_TYPES.ENTER_NAME")}
                value={form.name.value}
                name="name"
                error={!!form.name.error}
                helperText={form.name.error}
                onChange={onChange}
                FormHelperTextProps={{
                  style: {
                    textAlign: i18n.dir() === "ltr" ? "left" : "right",
                  },
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <label className={styles.label}>
                {t("TREATMENT_TYPES.BEFORE_AFTER")}
              </label>
              <Select
                disabled={disable}
                variant="outlined"
                fullWidth
                margin="none"
                required
                name="beforeAfter"
                autoComplete={"off"}
                className={styles.textEnabled}
                onClick={() => setBeforeAfter(!beforeAfter)}
                open={beforeAfter}
                IconComponent={KeyboardArrowDownIcon}
                onChange={onChange}
                value={form.beforeAfter.value}
                displayEmpty
                error={!!form.beforeAfter.error}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return (
                      <p style={{ color: "#A4A9AB", margin: 0 }}>Choose</p>
                    );
                  }
                  return selected;
                }}
              >
                <MenuItem value={"Before"}>Before</MenuItem>
                <MenuItem value={"After"}>After</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={3}>
              <label className={styles.label}>
                {t("TREATMENT_TYPES.HOURS")}
              </label>
              <TextField
                margin="none"
                fullWidth
                variant="outlined"
                required
                name="hours"
                inputProps={{ pattern: "[0-9]" }}
                autoComplete={"off"}
                className={styles.textEnabled}
                placeholder={"Enter amount of hours"}
                value={form.hours.value}
                onChange={onChange}
                error={!!form.hours.error}
                helperText={form.hours.error}
                FormHelperTextProps={{
                  style: {
                    textAlign: i18n.dir() === "ltr" ? "left" : "right",
                  },
                }}
              />
            </Grid>
            <Grid item xs={2.4}>
              <label className={styles.label}>
                {t("TREATMENT_TYPES.SESSION_NUMBER")}
              </label>
              <Select
                disabled={disable}
                variant="outlined"
                fullWidth
                margin="none"
                required
                name="session_id"
                autoComplete={"off"}
                className={styles.textEnabled}
                onClick={() => setsessionNum(!sessionNum)}
                open={sessionNum}
                IconComponent={KeyboardArrowDownIcon}
                onChange={onChange}
                value={form.session_id.value}
                displayEmpty
                error={!!form.session_id.error}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return (
                      <p style={{ color: "#A4A9AB", margin: 0 }}>Choose</p>
                    );
                  }
                  return selected;
                }}
              >
                {Array.from({ length: noofSession }, (x, i) => i).map(
                  (item, index) => {
                    return (
                      <MenuItem key={index} value={item + 1}>
                        {item + 1}
                      </MenuItem>
                    );
                  }
                )}
              </Select>
            </Grid>
            <Grid item xs={12}>
              <label className={styles.label}>
                {t("TREATMENT_TYPES.DESCRIPTION")}
              </label>
              <TextField
                margin="none"
                fullWidth
                variant="outlined"
                required
                name="description"
                autoComplete={"off"}
                className={styles.textEnabled}
                placeholder={"Enter Description"}
                value={form.description.value}
                onChange={onChange}
                error={!!form.description.error}
                helperText={form.description.error}
                FormHelperTextProps={{
                  style: {
                    textAlign: i18n.dir() === "ltr" ? "left" : "right",
                  },
                }}
              />
            </Grid>
            <Grid className={styles.dotBox} item xs={4}>
              <FileUpload
                title="Video"
                fileClose={() => videoClose()}
                fileType={FileType.VIDEO}
                uploadComplete={(data) => {
                  dispatch(actions.setFileUploadSuccess(data));
                }}
                type="videoFile"
                isUploading={(data) => {
                  setIsUploading(data);
                }}
                file={form.videoFile}
              ></FileUpload>
            </Grid>
            <Grid className={styles.dotBox} item xs={4}>
              <FileUpload
                title="Audio"
                fileClose={() => audioClose()}
                fileType={FileType.AUDIO}
                uploadComplete={(data) => {
                  dispatch(actions.setFileUploadSuccess(data));
                }}
                type="audioFile"
                isUploading={(data) => {
                  setIsUploading(data);
                }}
                file={form.audioFile}
              ></FileUpload>
            </Grid>
            <Grid className={styles.dotBox} item xs={4}>
              <FileUpload
                title="PDF"
                fileClose={() => pdfClose()}
                fileType={FileType.PDF}
                uploadComplete={(data) => {
                  dispatch(actions.setFileUploadSuccess(data));
                }}
                type="pdfFile"
                isUploading={(data) => {
                  setIsUploading(data);
                }}
                file={form.pdfFile}
              ></FileUpload>
            </Grid>
            {hasFileErrors && (
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontSize: "0.75rem",
                    lineHeight: "1.66",
                    marginRight: "14px",
                    marginLeft: "14px",
                    color: "#d32f2f",
                  }}
                >
                  {form?.videoFile?.error ||
                    form?.pdfFile?.error ||
                    form?.audioFile?.error}
                </Typography>
              </Grid>
            )}
            <Grid item xs={3.2}></Grid>
          </Grid>
          <div>
            <Grid container spacing={2}>
              <Grid
                className={styles.btnContainer}
                container
                item
                xs={6}
                direction="column"
              >
                <Button
                  className={styles.cancelBtn}
                  variant="outlined"
                  onClick={() => {
                    if (isUploading) return;
                    handleModalClose();
                  }}
                >
                  {t("BUTTONS.CANCEL")}
                </Button>
              </Grid>
              <Grid container item xs={6} direction="column">
                <Button
                  className={styles.saveBtn}
                  variant="contained"
                  onClick={handleSaveBtn}
                >
                  {t("BUTTONS.SAVE")}
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </Card>
    </Modal>
  );
};
export default AddTreatmentContent;
