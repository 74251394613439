import {
  Box,
  Checkbox,
  Grid,
  InputAdornment,
  Link,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import DataTable from "app/components/DataTable";
import { ScrollableView } from "app/components/ScrollableView";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getFormattedDateTime } from "utils/helpers";
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import { getRegistrationStatus } from "../../helpers/getRegistrationStatus";
import {
  selectList,
  selectLoading,
  selectQuery,
  selectTimeSlotModal,
} from "../../redux/selector";
import { actions } from "../../redux/slice";
import { TreatmentInvolvedPatients } from "../../types";
import { TimeslotComponentModal } from "../Timeslot";
import { useStyles } from "./styles";

const AssignedTreatmentList = ({
  patientId,
  disabled,
}: {
  patientId?: number;
  disabled?: boolean;
}) => {
  const styles = useStyles();

  const params = useLocation();
  const listitems = useSelector(selectList);
  const selectedIds = listitems.selectedIds;
  const { orderField, orderDirection } = useSelector(selectQuery);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const shownColumns = patientId
    ? [
        "isActive",
        "isMust",
        "treatmentTypeName",
        "selectedInstructor",
        "registrationStatus",
        "meetingId",
        "bonusRegular",
        "grpIndividual",
        "noOfSession",
        "description",
        "startDate",
        "endDate",
        "treatment_amount",
      ]
    : [
        "isActive",
        "organizationName",
        "departmentName",
        "bankName",
        "patient.name",
        "isMust",
        "treatment_amount",
        "treatmentTypeName",
        "selectedInstructor",
        "registrationStatus",
        "meetingId",
        "bonusRegular",
        "grpIndividual",
        "noOfSession",
        "description",
        "startDate",
        "endDate",
      ];
  const setPageQuery = (name: string, value: any) => {
    dispatch(actions.setQuery({ name, value }));
  };

  const { meta } = listitems;

  const {
    bankId,
    departmentId,
    organizationId,
    treatmentId,
    from,
    to,
    status,
    instructorId,
    actualInstructorId,
    q,
  } = useSelector(selectQuery);
  const openTimeslot = useSelector(selectTimeSlotModal);
  useEffect(() => {
    if (openTimeslot == false) {
      dispatch(actions.resetPagination());
      dispatch(actions.getList({ patientId: patientId }));
    }
  }, [
    departmentId,
    organizationId,
    bankId,
    treatmentId,
    from,
    to,
    status,
    dispatch,
    q,
    instructorId,
    actualInstructorId,
    patientId,
    openTimeslot,
  ]);
  const openSelectedTreatment = (params) => {
    dispatch(actions.openSelectedTreatMent(params));
  };

  const columns = [
    {
      id: "isActive",
      label: "",
      format: (value: TreatmentInvolvedPatients) => {
        const hasTimeSlotIdEntry = value?.timeslotInvolvedPatients?.some(
          (tiP) => tiP.timeSlotId
        );

        if (hasTimeSlotIdEntry) {
          return (
            <Tooltip title={`It has Treatments with Timeslots assigned`}>
              <Typography textAlign={"center"} width={50}>
                {" "}
                -
              </Typography>
            </Tooltip>
          );
        }
        const rowDisabled = value.patient.bank.bankStatus == "Done";
        return (
          <Checkbox
            checked={selectedIds.includes(value?.id)}
            value={"isActive"}
            disabled={
              rowDisabled ||
              (params.search.split("=")[1] !== "edit" && !patientId)
            }
            onChange={(e) => {
              dispatch(actions.addToSelectedIds(value?.id));
            }}
            onDoubleClick={(e) => e.stopPropagation()}
            color="info"
          />
        );
      },
    },
    {
      id: "organizationName",
      label: t("ASSIGNED_TREATMENT.ORGANIZATION_NAME"),
      sortValue: "organization.name",
      width: 200,
      format: (value: TreatmentInvolvedPatients, index) => {
        const organization = value.patient.organization;
        return (
          <Typography
            style={{
              maxWidth: 200,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {" "}
            {organization?.name}
          </Typography>
        );
      },
    },
    {
      id: "departmentName",
      label: t("ASSIGNED_TREATMENT.DEPARTMENT_NAME"),
      sortValue: "department.departmentName",
      minWidth: 180,
      maxWidth: 280,
      format: (value: TreatmentInvolvedPatients, index) => {
        const patient = value.patient;
        return patient.department?.departmentName;
      },
    },
    {
      id: "bankName",
      label: t("ASSIGNED_TREATMENT.BANK_NAME"),
      sortValue: "bank.name",
      minWidth: 180,
      maxWidth: 280,
      format: (value: TreatmentInvolvedPatients, index) => {
        const patient = value.patient;
        return patient.bank?.name;
      },
    },
    {
      id: "patient.name",
      label: t("ASSIGNED_TREATMENT.PATIENT_NAME"),
      sortValue: "patient.firstName",
      minWidth: 140,
      maxWidth: 180,
      format: (value: TreatmentInvolvedPatients, index) => {
        const patient = value.patient;
        return `${patient?.firstName} ${patient?.lastName || ""}`;
      },
    },
    {
      id: "isMust",
      label: t("ASSIGNED_TREATMENT.IS_MUST"),
      minWidth: 150,
      maxWidth: 250,
      format: (value: TreatmentInvolvedPatients) => {
        const treatmentBankTreatment = value?.treatmentBankTreatment;
        return (
          <Checkbox
            checked={treatmentBankTreatment?.isMust}
            name={"isMust"}
            value={"isMust"}
            disabled={true} // it is a read only field
            color="info"
          />
        );
      },
    },
    {
      id: "treatment_amount",
      label: t("ASSIGNED_TREATMENT.TREATMENT_AMOUNT"),
      sortValue: "selector.treatment_amount",
      minWidth: 150,
      maxWidth: 250,
      format: (value: TreatmentInvolvedPatients, index) => {
        return value.timeslotInvolvedPatients.length || 0;
      },
    },

    {
      id: "treatmentTypeName",
      label: t("ASSIGNED_TREATMENT.TREATMENT_TYPE_NAME"),
      sortValue: "selector.treatmentTypeName",
      format: (value: TreatmentInvolvedPatients, index) => {
        return (
          <Tooltip
            title={
              value.completedAt
                ? "Treatment is completed"
                : "Treatment is in-progress"
            }
          >
            <Typography>
              {" "}
              {value?.treatmentBankTreatment?.treatment?.name}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "selectedInstructor",
      label: t("ASSIGNED_TREATMENT.SELECTED_INSTRUCTOR"),
      format: (value: TreatmentInvolvedPatients) => {
        if (!value?.instructor) {
          return "-";
        }
        return `${value.instructor.firstName} ${value.instructor.lastName}`;
      },
    },
    {
      id: "registrationStatus",
      label: t("ASSIGNED_TREATMENT.REGISTRATION_STATUS"),
      format: (value: TreatmentInvolvedPatients, index) => {
        const status = getRegistrationStatus(value, index);
        return status.map((tip) => {
          return tip.map((s) => {
            const title =
              (s.currentStatus ? `${s.currentStatus}` : "") +
              (s.availableAction ? `-${s.availableAction}` : "");
            return (
              <Tooltip title={title ? `Status:${title}` : ""}>
                <Typography> {t(`PATIENTS.REG_STATUS.${s.status}`)}</Typography>
              </Tooltip>
            );
          });
        });
      },
    },
    {
      id: "meetingId",
      label: t("ASSIGNED_TREATMENT.MEETING_ID"),
      format: (value: TreatmentInvolvedPatients, index) => {
        const status = getRegistrationStatus(value, index);
        return status.map((tip) => {
          return tip.map((s) => {
            if (s.meetingId) {
              return (
                <Tooltip title={t(`PATIENTS.REG_STATUS.${s.status}`)}>
                  <Link
                    style={{
                      marginLeft: "5px",
                      color: s.disabled ? "#ccc" : "#3D63E6",
                    }}
                    onClick={() => {
                      if (s.disabled) {
                        return;
                      }
                      openSelectedTreatment(s.options);
                    }}
                  >
                    {s.meetingId} {s.isLast ? "(Reschedule)" : ""}
                  </Link>
                </Tooltip>
              );
            }
            return (
              <Tooltip title={"Schedule"}>
                <Link
                  style={{
                    marginLeft: "5px",
                    color: s.disabled ? "#ccc" : "#3D63E6",
                  }}
                  onClick={() => {
                    if (s.disabled) {
                      return;
                    }
                    openSelectedTreatment(s.options);
                  }}
                >
                  Schedule
                </Link>
              </Tooltip>
            );
          });
        });
      },
    },
    {
      id: "bonusRegular",
      label: t("ASSIGNED_TREATMENT.BONUS_REGULAR"),
      sortValue: "selector.patientType",
      format: (value: TreatmentInvolvedPatients) => {
        const patientTypes = value.type;
        return patientTypes;
      },
    },
    {
      id: "grpIndividual",
      label: t("ASSIGNED_TREATMENT.GROUP_INDIVIDUAL"),
      sortValue: "selector.grpIndividual",
      format: (value: TreatmentInvolvedPatients) => {
        const treatmentBankTreatment = value.treatmentBankTreatment;
        return treatmentBankTreatment?.treatment?.type;
      },
    },
    {
      id: "noOfSession",
      label: t("ASSIGNED_TREATMENT.NUMBER_OF_SESSIONS"),
      sortValue: "treatmentBankTreatments.treatmentType.session_count",
      minWidth: 120,
      maxWidth: 140,
      format: (value: TreatmentInvolvedPatients) => {
        const treatmentBankTreatment = value.treatmentBankTreatment;
        return treatmentBankTreatment?.treatment?.session_count;
      },
    },
    {
      id: "description",
      label: t("ASSIGNED_TREATMENT.DESCRIPTION"),
      sortValue: "selector.description",
      format: (value: TreatmentInvolvedPatients) => {
        const treatment = value.treatmentBankTreatment?.treatment;
        return treatment?.description;
      },
    },
    {
      id: "startDate",
      label: t(`TREATMENT_BANKS.START_DATE`),
      sortValue: "selector.startDate",
      minWidth: 120,
      maxWidth: 180,
      format: (value: TreatmentInvolvedPatients) =>
        getFormattedDateTime(
          value.treatmentBankTreatment.startDate,
          "dd-MM-yyyy"
        ),
    },
    {
      id: "endDate",
      label: t(`TREATMENT_BANKS.END_DATE`),
      sortValue: "selector.endDate",
      minWidth: 120,
      maxWidth: 180,
      format: (value: TreatmentInvolvedPatients) =>
        getFormattedDateTime(
          value.treatmentBankTreatment.endDate,
          "dd-MM-yyyy"
        ),
    },
  ].filter((f) => shownColumns.includes(f.id));
  const isLoading = useSelector(selectLoading);

  const hasMoreItems = meta.currentPage < meta.totalPages;
  const getNextPage = () => {
    if (!isLoading) {
      dispatch(actions.getNextPage(meta.currentPage + 1));
    }
  };
  const disableActions = params.search.split("=")[1] !== "edit";
  const handleTimeslotClose = () => {
    dispatch(actions.setTimeSlotModal());
  };
  const { height } = useWindowDimensions();

  return (
    <>
      {Number(patientId) > 0 ? (
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <label className={styles.label}>
              {t("PATIENTS.REMAINING_INDIVIDUAL_TREATMENTS")}
            </label>
            <TextField
              className={styles.textEnabled}
              variant="outlined"
              margin="dense"
              required
              fullWidth
              name="name"
              disabled={true}
              value={
                (meta?.info?.individualPerPatient || 0) +
                (meta?.info?.individualTreatmentCountInBank || 0) -
                (meta?.info?.countOfIndividualTreatment || 0)
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Box
                      display={"flex"}
                      justifyContent="center"
                      flexDirection="column"
                      mr={-1.5}
                    >
                      <Typography style={{ color: "#999" }} mb={"3px"}>
                        Max: {meta.info?.individualPerPatient}
                      </Typography>
                    </Box>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <label className={styles.label}>
              {t("PATIENTS.REMAINING_GROUP_TREATMENTS")}
            </label>
            <TextField
              className={styles.textEnabled}
              variant="outlined"
              margin="dense"
              required
              fullWidth
              name="name"
              disabled={true}
              value={
                (meta?.info?.groupPerPatient || 0) +
                (meta?.info?.groupTreatmentCountInBank || 0) -
                (meta?.info?.countOfGroupTreatment || 0)
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Box
                      display={"flex"}
                      justifyContent="center"
                      flexDirection="column"
                      mr={-1.5}
                    >
                      <Typography style={{ color: "#999" }} mb={"3px"}>
                        Max: {meta?.info?.groupPerPatient}
                      </Typography>
                    </Box>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      ) : null}

      <ScrollableView
        loadMore={getNextPage}
        hasMoreItems={hasMoreItems}
        height={Number(patientId) > 0 ? 500 : height - 390}
      >
        <DataTable
          loading={isLoading}
          orderDirection={orderDirection}
          orderField={orderField}
          setQuery={setPageQuery}
          rows={listitems.items}
          columns={columns}
          disableActions={disableActions}
        />
      </ScrollableView>
      <TimeslotComponentModal
        open={openTimeslot}
        onClose={handleTimeslotClose}
      />
    </>
  );
};

export default AssignedTreatmentList;
