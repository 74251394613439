import { CustomDrawer } from "app/components/Drawer";
import { NoPermission } from "app/components/NoPermission";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { selectFeaturePermissions } from "store/global/authPermission/selector";
import { PermissionFeature } from "store/global/authPermission/types";
import Topbar from "../Dashboard/components/topbar";
import FiltersForm from "./components/FiltersForm";
import QuestionnaireList from "./components/List";
import { useQuestionnaireResultSaga } from "./redux/saga";
import { selectQuery } from "./redux/selector";
import { actions, reducer, sliceKey } from "./redux/slice";
import { useStyles } from "./styles";

const QuestionnaireResult = () => {
  useInjectReducer({ key: sliceKey, reducer });
  useInjectSaga({ key: sliceKey, saga: useQuestionnaireResultSaga });

  const styles = useStyles();
  const dispatch = useDispatch();

  const { q } = useSelector(selectQuery);

  const setPageQuery = (name: string, value: any) => {
    dispatch(actions.setQuery({ name, value }));
  };

  const featurePermissions = useSelector(selectFeaturePermissions);
  const { hasNoAccess, hasFullAccess } =
    featurePermissions[PermissionFeature.QUESTIONNAIRES_RESULTS];
  if (hasNoAccess) {
    return (
      <div className={styles.bg_color}>
        <CustomDrawer />
        <Topbar />
        <NoPermission />
      </div>
    );
  }
  return (
    <>
      <div className={styles.bg_color}>
        <CustomDrawer />
        <Topbar q={q} setSearch={(q) => setPageQuery("q", q)} />
        <div className={styles.right}>
          <FiltersForm />
          <QuestionnaireList />
        </div>
      </div>
    </>
  );
};
export default QuestionnaireResult;
