import { CalendarMonth, KeyboardArrowDown } from "@mui/icons-material";
import {
  Box,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import DepartmentFiled from "app/components/FieldComponent/Department";
import OrganizationField from "app/components/FieldComponent/Organization";
import FileUpload from "app/components/FileUpload";
import { selectTreatmentBankAvailableStatus } from "app/containers/TreatmentBanks/redux/selector";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { FileType } from "utils/enum";
import { valueForOnlyNumericInputTextFields } from "utils/helpers";
import { actions } from "../../../../redux/slice";
import { BankStatusOptions } from "../../../../types";
import { canBankStatusOptionActivated } from "./helpers";
import { useStyles } from "./styles";
const FiltersForm = ({
  disable,
  view,
  data,
  formData,
  setFormDataHandler,
  mode,
  id,
  maxForm,
}) => {
  const bankStatusOptions = useSelector(selectTreatmentBankAvailableStatus);

  const styles = useStyles();

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onChangeHandler = (key, value) => {
    setFormDataHandler({
      key,
      value,
    });
  };

  const onFileCancel = () => {
    dispatch(actions.resetFileUploaded());
  };

  const renderDisableEditNoNEditMode = () => {
    if (mode == "view") {
      return true;
    }
    if (mode == "edit") {
      const status: BankStatusOptions = formData?.bankStatus;
      if (status == "New") {
        return false;
      }
      return true;
    }
    return false;
  };
  useEffect(() => {
    if (!view && data.id == 0) {
      onChangeHandler("bankStatus", "New");
    }
    return () => {};
  }, [view]);

  return (
    <Grid
      container
      md={12}
      sx={{
        padding: "30px 0px 10px 0px",
      }}
    >
      <Grid container spacing={3}>
        <OrganizationField
          xs={2.4}
          value={formData.organizationId}
          changeValue={(value) => {
            onChangeHandler("organizationId", value);
          }}
          disabled={!!id}
          showAll={false}
          activeRequired={!id}
        />

        <DepartmentFiled
          xs={2.4}
          value={formData.departmentId}
          changeValue={(value) => {
            onChangeHandler("departmentId", value);
          }}
          disabled={!!id}
          showAll={false}
          orgId={formData.organizationId}
        />

        <Grid item xs={2.4}>
          <label className={styles.label}>
            {t("TREATMENT_BANKS.BANK_NAME")}
          </label>
          <TextField
            margin="dense"
            disabled={renderDisableEditNoNEditMode()}
            fullWidth
            variant="outlined"
            required
            autoComplete={"off"}
            value={formData.bankName}
            onChange={(e) => onChangeHandler("bankName", e.target.value)}
            className={styles.textEnabled}
            placeholder={"Enter Bank Name"}
          />
        </Grid>
        <Grid item xs={2.4}>
          <Typography noWrap className={styles.label}>
            {t("TREATMENT_BANKS.BANK_STATUS")}
          </Typography>
          <Select
            margin="dense"
            disabled={!id || disable}
            fullWidth
            variant="outlined"
            required
            autoComplete={"off"}
            className={styles.textEnabled}
            value={formData?.bankStatus}
            IconComponent={KeyboardArrowDown}
            displayEmpty
            onChange={(e) => onChangeHandler("bankStatus", e.target.value)}
          >
            {bankStatusOptions.map((option) => (
              <MenuItem
                disabled={canBankStatusOptionActivated(
                  id,
                  formData?.bankStatus,
                  option.name
                )}
                value={option.name}
              >
                {" "}
                {option.display}{" "}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={2.4}>
          <Typography noWrap className={styles.label}>
            {t("TREATMENT_BANKS.ASSIGNED_DATE")}
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              disabled
              className={styles.textEnabledDate}
              views={["day", "month", "year"]}
              value={formData.assignedDate}
              minDate="01/01/1900"
              onChange={(newValue) => onChangeHandler("assignedDate", newValue)}
              components={{
                OpenPickerIcon: CalendarMonth,
              }}
              OpenPickerButtonProps={{
                style: { color: disable ? "#6C7A7A" : "#012397" },
              }}
              inputFormat="dd/MM/yyyy"
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  disabled
                  margin="dense"
                  helperText={null}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: "__/__/____",
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={2.4}>
          <Typography noWrap className={styles.label}>
            {t("TREATMENT_BANKS.TOTAL_PATIENTS")}
          </Typography>
          <TextField
            disabled={mode === "view" || disable}
            margin="dense"
            fullWidth
            variant="outlined"
            required
            autoComplete={"off"}
            value={formData.totalPatient}
            onChange={(e) => {
              const value = valueForOnlyNumericInputTextFields(e.target.value);
              onChangeHandler("totalPatient", value);
            }}
            className={styles.textEnabled}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Box
                    display={"flex"}
                    justifyContent="center"
                    flexDirection="column"
                    mr={-1.5}
                  >
                    <Typography style={{ color: "#999" }} mb={"3px"}>
                      Max: {maxForm.maxForPatient}
                    </Typography>
                    <Typography style={{ color: "#999" }} mt={"3px"}>
                      Min: {maxForm.minForPatient}
                    </Typography>
                  </Box>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={2.4}>
          <Tooltip title={t("TREATMENT_BANKS.REMAINING_INDIVIDUAL_TREATMENTS")}>
            <Typography noWrap className={styles.label}>
              {t("TREATMENT_BANKS.REMAINING_INDIVIDUAL_TREATMENTS")}
            </Typography>
          </Tooltip>
          <TextField
            disabled={mode === "view" || disable}
            margin="dense"
            fullWidth
            variant="outlined"
            required
            autoComplete={"off"}
            value={formData.indPerPatient}
            onChange={(e) => {
              const value = valueForOnlyNumericInputTextFields(e.target.value);
              onChangeHandler("indPerPatient", value);
            }}
            className={styles.textEnabled}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Box
                    display={"flex"}
                    justifyContent="center"
                    flexDirection="column"
                    mr={-1.5}
                  >
                    <Typography style={{ color: "#999" }} mb={"3px"}>
                      Max: {maxForm.maxForIndividual}
                    </Typography>
                    <Typography style={{ color: "#999" }} mt={"3px"}>
                      Min: {maxForm.minForIndividual}
                    </Typography>
                  </Box>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={2.4}>
          <Tooltip title={t("TREATMENT_BANKS.REMAINING_GROUP_TREATMENTS")}>
            <Typography noWrap className={styles.label}>
              {t("TREATMENT_BANKS.REMAINING_GROUP_TREATMENTS")}
            </Typography>
          </Tooltip>
          <TextField
            disabled={mode === "view" || disable}
            margin="dense"
            fullWidth
            variant="outlined"
            required
            autoComplete={"off"}
            value={formData.groupPerPatient}
            onChange={(e) => {
              const value = valueForOnlyNumericInputTextFields(e.target.value);
              onChangeHandler("groupPerPatient", value);
            }}
            className={styles.textEnabled}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Box
                    display={"flex"}
                    justifyContent="center"
                    flexDirection="column"
                    mr={-1.5}
                  >
                    <Typography style={{ color: "#999" }} mb={"3px"}>
                      Max: {maxForm.maxForGroup}
                    </Typography>
                    <Typography style={{ color: "#999" }} mt={"3px"}>
                      Min: {maxForm.minForGroup}
                    </Typography>
                  </Box>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid
          item
          xs={2.4}
          justifyContent={"flex-end"}
          alignItems={"flex-start"}
          display={"flex"}
          flexDirection={"column"}
        >
          <FileUpload
            disabled={mode === "view" || disable}
            title={t("TREATMENT_BANKS.DOCUMENT")}
            fileClose={() => onFileCancel()}
            fileType={FileType.PDF}
            uploadComplete={(data) => {
              dispatch(actions.setUploadImageSuccess(data));
            }}
            type="pdfFile"
            isUploading={(data) => {}}
            file={{
              name: formData?.treatmentBankAsset?.resourceName,
              size: formData?.treatmentBankAsset?.resourceSize,
              url: formData?.treatmentBankAsset?.url,
            }}
          ></FileUpload>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FiltersForm;
