import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  card: {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  title: {
    color: "#464646",
    fontSize: "18px",
    fontWeight: 700,
    fontFamily: "Cairo",
    marginTop: "50px",
  },
  cell: {
    margin: 0,
    padding: 0,
  },
  tabLabel: {
    fontSize: "16px",
    fontFamily: "Cairo",
    fontWeight: "700",
  },
  description: {
    color: "#464646",
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "Cairo",
    marginTop: "20px",
  },
  chartLegend: {
    fontSize: "18px",
    fontFamily: "Cairo",
    fontWeight: "500",
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
  },
  greenDot: {
    width: "22px",
    height: "22px",
    borderRadius: "50%",
    display: "inline-block",
    marginRight: "10px",
    background: "#54C9C4",
  },
  purpleDot: {
    width: "22px",
    height: "22px",
    borderRadius: "50%",
    display: "inline-block",
    marginRight: "10px",
    background: "#3D63E6",
  },
}));
