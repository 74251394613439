export interface ResponseError {
  message: string;
}
export type RequestError = ResponseError;

export type OrganizationsListItemType = {
  id: number;
  organizationName: string;
};

export type UserGroupListItemType = {
  id: number;
  userGroupName: string;
};

export type TreatmentListItemType = {
  id: number;
  timeSlotId: string;
  meetingId: string;
  dateAssigned: string;
  orgnizationId: string;
  orgnizationnName: string;
  departmentId: string;
  departmentName: string;
  timeslotDate: string;
  startTime: string;
  endTime: string;
  treatmentId: string;
  treatmentTypeName: string;
  instructorId: string;
  instructorName: string;
  actualInstructorId: string;
  actualInstructorName: string;
  patientId: string;
  patientName: string;
};
export type TimeSlotListItemType = {
  id: number;
  userGroupName: string;
};

export type OrganisationState = {
  items: Array<OrganizationsListItemType>;
  meta: {
    totalItems: number;
    itemCount: number;
    itemsPerPage: number;
    totalPages: number;
    currentPage: number;
  };
  links: {
    first: string;
    last: string;
    previous: string;
    next: string;
  };
};

export type TimeSlotListStateType = {
  query: {
    limit: number;
    page: number;
    departmentId: number;
    organizationId: number;
    bankId: number;
    treatmentId: number;
    instructorId: number;
    actualInstructorId: number;
    q: string;
    orderField: string;
    orderDirection: string;
    createMin: string;
    createMax: string;
    updateMin: string;
    updateMax: string;
  };

  list: {
    items: Array<TimeSlotListItemType>;
    meta: {
      totalItems: number;
      itemCount: number;
      itemsPerPage: number;
      totalPages: number;
      currentPage: number;
      treatmentLeft: number;
      treatmentScheduled: number;
      treatmentRemaining: number;
    };
    links: {
      first: string;
      last: string;
      previous: string;
      next: string;
    };
  };
  loading: boolean;
  error: string;
  organization: OrganisationState;

  qualification: any;
  departments: any;
  banks: any;
  patientPermission: any;
};

export const TimeSlotState: TimeSlotListStateType = {
  query: {
    actualInstructorId: 0,
    departmentId: 0,
    bankId: 0,
    instructorId: 0,
    organizationId: 0,
    treatmentId: 0,
    limit: 10,
    page: 1,
    q: "",
    orderField: "",
    orderDirection: "",
    updateMax: "",
    updateMin: "",
    createMax: "",
    createMin: "",
  },
  list: {
    items: [],
    meta: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 10,
      totalPages: 1,
      currentPage: 1,
      treatmentLeft: 0,
      treatmentScheduled: 0,
      treatmentRemaining: 0,
    },
    links: {
      first: "",
      previous: "",
      next: "",
      last: "",
    },
  },
  loading: false,
  organization: {
    items: [
      {
        id: 1,
        organizationName: "Organization 1",
      },
    ],
    meta: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 100,
      totalPages: 1,
      currentPage: 1,
    },
    links: {
      first: "",
      previous: "",
      next: "",
      last: "",
    },
  },

  banks: [],
  departments: {
    items: [],
    meta: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 100,
      totalPages: 1,
      currentPage: 1,
    },
    links: {
      first: "",
      previous: "",
      next: "",
      last: "",
    },
  },
  qualification: {
    items: [],
    meta: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 100,
      totalPages: 1,
      currentPage: 1,
    },
    links: {
      first: "",
      previous: "",
      next: "",
      last: "",
    },
  },
  patientPermission: {
    items: [],
    meta: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 100,
      totalPages: 1,
      currentPage: 1,
    },
    links: {
      first: "",
      previous: "",
      next: "",
      last: "",
    },
  },

  error: "",
};

export type ContainerState = TimeSlotListStateType;
