import { Box, Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import * as React from "react";
import { useStyles } from "./styles";

interface SelectInputProps {
  label?: string;
  containerStyle?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
  name?: string;
  onChange?: (e: SelectChangeEvent) => void;
  value?: string;
  disabled?: boolean;
  error?: string;
  options?: Array<{ label: string; value: string }>;
}

export default function SelectInput({
  label,
  containerStyle,
  inputStyle,
  labelStyle,
  name,
  onChange,
  value,
  disabled,
  error,
  options = [],
}: SelectInputProps) {
  const classes = useStyles();
  return (
    <Box style={containerStyle}>
      {label && (
        <InputLabel style={labelStyle} className={classes.label}>
          {label}
        </InputLabel>
      )}
      <Select
        className={classes.input}
        fullWidth
        style={{
          ...inputStyle,
          ...(error && { border: "1px solid #FF0000" }),
        }}
        name={name}
        onChange={onChange}
        value={value}
        disabled={disabled}
        inputProps={{
          disableUnderline: true,
          sx: {
            fontFamily: "Cairo",
            fontSize: "16px",
            lineHeight: "24px",
          },
        }}
      >
        {options?.map((option, index) => (
          <MenuItem key={index} value={option?.value}>
            {option?.label}
          </MenuItem>
        ))}
      </Select>
      {error && (
        <Typography component="small" className={classes.error}>
          {error}
        </Typography>
      )}
    </Box>
  );
}
