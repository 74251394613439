
import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles(() => ({
  btnContainer: {
    display: "flex",
    alignItems: "flex-end",
  },
  modalContainer: {
    padding: "20px 40px 40px 40px",
  },
  cancelBtn: {
    color: "#3D63E6",
    borderColor: "#3D63E6",
    "&:hover": {
      borderColor: "#3D63E6",
    },
    fontFamily: "Cairo",
    fontWeight: 700,
    fontSize: "16px",
    width: "160px",
    height: "50px",
  },
  saveBtn: {
    background: "#3D63E6",
    color: "#ffff",
    "&:hover": {
      background: "#3D63E6",
    },
    fontFamily: "Cairo",
    fontWeight: 700,
    fontSize: "16px",
    width: "160px",
    height: "50px",
  },
  dotBox: {
    display: "flex",
    alignItems: "flex-end",
    width: '100%',
  },
  links: {
    justifyContent: 'space-between',
    display: 'flex',
    '& a': {
      color: '#6C7A7A',
    }
  },
  headerModal: {
    color: "#012497",
    fontFamily: "Cairo",
    fontWeight: "800",
    fontSize: "24px",
  },
  importModal:{
    position: "absolute",
    background: "#F5F8FF",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "0px",
    boxShadow: "0",
    zIndex: "9999",
    width: "770px",
    height:"450px"
  },
}));
