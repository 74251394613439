import { Box, Grid } from "@mui/material";
import PieChart from "app/components/Charts/PieChart/PieChart";
import ProgressTab from "app/containers/Dashboard/components/ProgressTab";
import {
  selectDailyPieProgress,
  selectMeetingPieProgress,
  selectQuery,
} from "app/containers/Dashboard/redux/selector";
import { actions } from "app/containers/Dashboard/redux/slice";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import { DAILY_OPTIONS, MEETING_OPTIONS } from "./chart.constants";
import { useStyles } from "./styles";

export default function ProgressTabPanel() {
  const dailyActivityData = useSelector(selectDailyPieProgress);
  const meetingData = useSelector(selectMeetingPieProgress);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const { organizationId, bankId, departmentId, q } = useSelector(selectQuery);
  useEffect(() => {
    dispatch(actions.getListProgress());
  }, [organizationId, bankId, departmentId, q]);
  const { height } = useWindowDimensions();
  return (
    <Box
      sx={{
        background: "#fff",
        width: "100%",
        height: `${height - 250}px`,
        overflow: "auto",
      }}
    >
      <Grid container>
        <Grid item xs={6}>
          <div
            style={{
              margin: "10px 30px 10px 0px",
              borderRadius: "12px",
              background: "#fff",
              position: "relative",
            }}
          >
            <label className={classes.title}>
              {t("DASHBOARD.DAILY_ACTIVITY")}
            </label>
            <PieChart data={dailyActivityData} options={DAILY_OPTIONS} />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div
            style={{
              margin: "10px 0px 10px 0px",
              borderRadius: "12px",
              background: "#fff",
              position: "relative",
            }}
          >
            <label className={classes.title}>{t("DASHBOARD.MEETING")}</label>
            <PieChart data={meetingData} options={MEETING_OPTIONS} />
          </div>
        </Grid>
      </Grid>
      <Grid>
        <ProgressTab />
      </Grid>
    </Box>
  );
}
