import InfoIcon from "@mui/icons-material/Info";
import { Tooltip, Typography } from "@mui/material";
import React from "react";
type Props = {
  value?: string | number;
  error?: string;
};
export const TableField = ({ value, error }: Props) => {
  const isError = !!error;
  return (
    <Tooltip title={error}>
      <Typography>
        {isError ? <InfoIcon color="error" /> : null} {value}
      </Typography>
    </Tooltip>
  );
};
