import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import AssignedTreatment from "../containers/AssignedTreament";
import DailyActivity from "../containers/DailyActivity";
import Dashboard from "../containers/Dashboard";
import Imports from "../containers/Imports";
import Notifications from "../containers/Notifications";
import Organizations from "../containers/Organizations";
import Patients from "../containers/Patients";
import Profile from "../containers/Profile";
import QuestionnairResult from "../containers/QuestionnaireResults";
import Questionnaires from "../containers/Questionnaires";
import AddMultiQuestionnaire from "../containers/Questionnaires/components/AddMultiQuestionnaire";
import AddOpenQuestionnaires from "../containers/Questionnaires/components/AddOpenQuestionnaire";
import TimeSlots from "../containers/TimeSlots";
import TimeSlotActions from "../containers/TimeSlots/components/TimeSlotActions";
import TreatmentBanks from "../containers/TreatmentBanks";
import TreatmentBankDetailsEdit from "../containers/TreatmentBanks/components/TreatmentBankDetailsEdit";
import TreatmentTypes from "../containers/TreatmentTypes";
import UserGroups from "../containers/UserGroups";
import Users from "../containers/Users";

export function InterNalRoutes() {
  return (
    <Routes>
      <Route path="profile" element={<Profile />} />
      <Route path="profile/edit" element={<Profile editable />} />

      <Route path="treatment-banks" element={<TreatmentBanks />} />
      <Route
        path="treatment-banks/treatment-bank-details/:id/:mode"
        element={<TreatmentBankDetailsEdit />}
      />
      <Route
        path="treatment-banks/treatment-bank-details"
        element={<TreatmentBankDetailsEdit />}
      />

      <Route path="daily-activity" element={<DailyActivity />} />
      <Route path="push-notification" element={<Notifications />} />
      <Route path="time-slots" element={<TimeSlots />} />
      <Route
        path="time-slots/time-slots-actions/:id/:mode"
        element={<TimeSlotActions />}
      />
      <Route
        path="time-slots/time-slots-actions/:mode"
        element={<TimeSlotActions />}
      />

      <Route path="/questionnaires" element={<Questionnaires />} />
      <Route
        path="/questionnaires/multi/add"
        element={<AddMultiQuestionnaire />}
      />
      <Route
        path="/questionnaires/multi/:id"
        element={<AddMultiQuestionnaire />}
      />
      <Route
        path="/questionnaires/open/:id"
        element={<AddOpenQuestionnaires />}
      />
      <Route
        path="/questionnaires/open/add"
        element={<AddOpenQuestionnaires />}
      />

      <Route path="/questionnaires-result" element={<QuestionnairResult />} />

      <Route path="assigned-treatment" element={<AssignedTreatment />} />

      <Route path="treatment-types/*" element={<TreatmentTypes />} />
      <Route path="organizations/*" element={<Organizations />} />
      <Route path="patients/*" element={<Patients />} />
      <Route path="imports/*" element={<Imports />} />
      <Route path="dashboard/*" element={<Dashboard />} />
      <Route path="users/*" element={<Users />} />
      <Route path="user-groups/*" element={<UserGroups />} />
      <Route path="*" element={<Navigate to={"/dashboard"} />} />
    </Routes>
  );
}
