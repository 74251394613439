import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles(() => ({
  textHeader: {
    color: "#2A3333",
    marginLeft: "14px",
    fontWeight: "600px",
    fontSize: "16px",
    lineHeight: "29.98px",
    fontStyle: "Semibold",
  },
  textHeaderT: {
    color: "#012497",
    fontWeight: "600px",
    fontSize: "16px",
    lineHeight: "29.98px",
  },
  label: {
    fontFamily: "Cairo",
    fontWeight: 400,
    fontSize: "16px",
    color: "#2A3333",
  },
  errorText: {
    fontSize: "0.75rem !important",
    lineHeight: "1.66",
    marginTop: "3px",
    marginRight: "14px",
    marginLeft: "14px",
    color: "#d32f2f",
  },
  saveBtn: {
    background: "#3D63E6",
    color: "#ffff",
    "&:hover": {
      background: "#3D63E6",
    },
    fontFamily: "Cairo",
    fontWeight: 700,
    fontSize: "16px",
    width: "160px",
    height: "50px",
  },
  cancelBtn: {
    color: "#3D63E6",
    borderColor: "#3D63E6",
    "&:hover": {
      borderColor: "#3D63E6",
    },
    fontFamily: "Cairo",
    fontWeight: 700,
    fontSize: "16px",
    width: "160px",
    height: "50px",
  },

  uploadLabel: {
    fontFamily: "Cairo",
    fontWeight: "400px",
    marginTop: "13px",
    fontSize: "14px",
    marginLeft: "16px",
    color: "#2A3333",
    textDecorationLine: "underline",
  },
  uploadBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "5px",
    borderStyle: "dashed",
    borderWidth: "1px",
    border: "1px solid #6C7A7A",
    height: "56px",
    width: "190px",
    background: "#FFF",
    marginBottom: "5px",
  },
  preview: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "56px",
    width: "190px",
    backgroundColor: "#00000",
    marginBottom: "5px",
    background: "#fff",
  },
  dotBox: {
    display: "flex",
    alignItems: "flex-end",
  },
  textEnabled: {
    background: "#FFF",
    width: "100%",
  },
  addModal: {
    position: "absolute",
    background: "#F5F8FF",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "0px",
    boxShadow: "0",
    zIndex: "9999",
    width: "740px",
  },
  modalContainer: {
    padding: "20px",
  },
  headerModal: {
    color: "#012497",
    fontFamily: "Cairo",
    fontWeight: "800",
    fontSize: "24px",
  },
  btnContainer: {
    display: "flex",
    alignItems: "flex-end",
  },
}));
