import { Edit } from "@mui/icons-material";
import {
  FormHelperText,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import imgDelete from "app/assets/Delete.png";
import disableDelete from "app/assets/disableDelete.png";
import editPatientIcon from "app/assets/edit-patient.svg";
import DataTable from "app/components/DataTable";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Link } from "react-router-dom";

import { selectFeaturePermissions } from "store/global/authPermission/selector";
import { PermissionFeature } from "store/global/authPermission/types";
import { TRANSLATE, getFormattedDateTime } from "utils/helpers";
import {
  selectError,
  selectList,
  selectLoading,
  selectMode,
  selectTimeSlotFrom,
} from "../redux/selector";
import { actions } from "../redux/slice";
import { TimeSlotMode } from "../types";
type editType = undefined | "editPatinent";
const SessionList = () => {
  const { id } = useParams();
  const sessionItems = useSelector(selectList);
  const sessionMode = useSelector(selectMode);
  const loading = useSelector(selectLoading);
  const error: any = useSelector(selectError);
  const form = useSelector(selectTimeSlotFrom);
  const { mode } = useParams();

  const featurePermissions = useSelector(selectFeaturePermissions);
  const timeslotPermission = featurePermissions[PermissionFeature.TIMESLOTS];

  const dispatch = useDispatch();

  const handleEdit = (val, index, type?: editType) => {
    dispatch(actions.setSessionEditIndex(index));
    if (type && type === "editPatinent") {
      dispatch(actions.toggleRegisterPatient());
    } else {
      dispatch(actions.setTogaglEditModal(true));
    }
  };

  useEffect(() => {
    return () => {
      dispatch(actions.resetNewUserForm());
    };
  }, []);

  const { t } = useTranslation();

  const handleSessionDelete = (row, index) => {
    dispatch(actions.deleteSessionPatient(index));
  };
  const visibleSessionsItems = sessionItems.items.filter(
    (f) => f.isActive !== false
  );
  const isDeleteDisabled = visibleSessionsItems.length <= 1;
  const columns = [
    {
      id: "actions",
      label: TRANSLATE("TABLES.ACTIONS"),
      format: (row, index) => {
        const isPassed = !!row.completedAt;
        if (isPassed) {
          return <Typography> {t("TIMESLOT.TREATMENT_PASSED")} </Typography>;
        }

        if (sessionMode == TimeSlotMode.SINGLE) {
          return (
            <>
              {timeslotPermission?.hasFullAccess && (
                <>
                  <Tooltip title={t("TOOLTIP.EDIT")}>
                    <IconButton
                      disabled={mode === "view"}
                      onClick={() => handleEdit(row, index)}
                    >
                      <Edit
                        sx={{
                          color: "#FCC500",
                          filter: mode === "view" ? "grayscale(100)" : "",
                        }}
                      />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title={t("TOOLTIP.DELETE")}>
                    <IconButton
                      disabled={isDeleteDisabled || mode === "view"}
                      onClick={() => handleSessionDelete(row, index)}
                      onDoubleClick={(e) => e.stopPropagation()}
                    >
                      <img
                        style={{ width: 16 }}
                        src={
                          isDeleteDisabled || mode === "view"
                            ? disableDelete
                            : imgDelete
                        }
                      />
                    </IconButton>
                  </Tooltip>
                </>
              )}
              <Tooltip title={t("TOOLTIP.REGISTER_PATIENTS")}>
                <IconButton
                  disabled={
                    !form.instructorId.value ||
                    !timeslotPermission?.hasFullAccess ||
                    mode === "view"
                  }
                  onClick={() => handleEdit(row, index, "editPatinent")}
                >
                  <img
                    style={
                      !form.instructorId.value ||
                      !timeslotPermission?.hasFullAccess ||
                      mode === "view"
                        ? { filter: "grayscale(100)" }
                        : {}
                    }
                    src={editPatientIcon}
                  />
                </IconButton>
              </Tooltip>
            </>
          );
        }
        return (
          <>
            <Tooltip title={t("TOOLTIP.EDIT")}>
              <IconButton
                disabled={mode === "view"}
                onClick={() => handleEdit(row, index)}
              >
                <Edit
                  sx={{
                    color: "#FCC500",
                    filter: mode === "view" ? "grayscale(100)" : "",
                  }}
                />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
    {
      id: "sessionNumber",
      label: TRANSLATE("TIMESLOT.SESSION"),
      format: (val, index) => {
        return val.sessionNumber;
      },
    },
    {
      id: "meetingId",
      label: TRANSLATE("TIMESLOT.MEETING_ID"),
    },
    {
      id: "date",
      label: TRANSLATE("TIMESLOT.DATE"),

      format: (val, index) => {
        return getFormattedDateTime(val.start_time, "dd/MM/yyyy");
      },
      isVisible: sessionMode == TimeSlotMode.MULTI,
    },
    {
      id: "start_time",
      label: TRANSLATE("TIMESLOT.START_TIME"),
      format: (val, index) => {
        return (
          <Tooltip
            title={getFormattedDateTime(val.start_time, "dd-MM-yyyy, HH:mm")}
          >
            <Typography>
              {getFormattedDateTime(val.start_time, "HH:mm")}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "endTime",
      label: TRANSLATE("TIMESLOT.END_TIME"),
      format: (val, index) => {
        return (
          <Tooltip
            title={getFormattedDateTime(val.end_time, "dd-MM-yyyy, HH:mm")}
          >
            <Typography>
              {getFormattedDateTime(val.end_time, "HH:mm")}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "patientNames",
      label: TRANSLATE("TIMESLOT.ASSIGNED_PATIENT_NAME"),
      format: (val, index) => {
        const patientNames = val.involvedPatients
          .filter((f) => !f.deleted)
          .map((e) => e.firstName + " " + e.lastName)
          .join("/ ");
        return (
          <Tooltip title={patientNames}>
            <Typography maxWidth={150} noWrap>
              {patientNames}
            </Typography>
          </Tooltip>
        );
      },
    },
  ].filter((column) => column.isVisible !== false);
  const meetingsWithLinks = error?.errors?.map((conflict) => {
    if (error.message == "ASSIGNED_TREATMENT.OVERLAPPED_SESSIONS") {
      const meetingId = conflict.existingConflictingSessions.meetingId;
      const timeSlotId = conflict.existingConflictingSessions.timeSlotId;

      return (
        <Link
          target="_blank"
          to={`/time-slots/time-slots-actions/${timeSlotId}/view`}
        >
          {" "}
          {meetingId}
        </Link>
      );
    }
    if (error.message == "TIMESLOT.PATIENT_CONFLICT_TIMINGS") {
      const meetingId = conflict.meetingId;
      const timeSlotId = conflict.timeSlotId;

      return (
        <Link
          target="_blank"
          to={`/time-slots/time-slots-actions/${timeSlotId}/view`}
        >
          {" "}
          {meetingId}
        </Link>
      );
    }
  });
  return (
    <Grid item xs={8.5}>
      <b>{t("TIMESLOT.SESSIONS")}:</b>
      <DataTable
        loading={loading}
        rows={sessionItems.items || []}
        columns={columns}
        editAction={handleEdit}
        onRowDoubleClick={() => {}}
        isPointerCursor={true}
        deleteAction={handleSessionDelete}
      />
      {error.message == "ASSIGNED_TREATMENT.OVERLAPPED_SESSIONS" ? (
        <Grid flexDirection={"row"} display={"flex"}>
          <FormHelperText error>
            {t("ASSIGNED_TREATMENT.OVERLAPPED_SESSIONS")} {meetingsWithLinks}
          </FormHelperText>{" "}
        </Grid>
      ) : null}

      {error.message == "TIMESLOT.PATIENT_CONFLICT_TIMINGS" ? (
        <Grid flexDirection={"row"} display={"flex"}>
          <FormHelperText error>
            {t("TIMESLOT.PATIENT_CONFLICT_TIMINGS_WITH_NAME", {
              fullName: error.errors[0].fullName,
            })}{" "}
            {meetingsWithLinks}
          </FormHelperText>{" "}
        </Grid>
      ) : null}
    </Grid>
  );
};
export default SessionList;
