import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles(() => ({
  mainContainer: {
    background: "#F5F8FF",
    minHeight: "100%",
    minWidth: "100%",
    bottom: "auto",
  },
  bodyContainer: {
    padding: "20px 20px 0px 300px",
    marginTop: "40px",
    minHeight: "100%",
  },
  imgClass: {
    height: 20,
    width: 20,
    cursor: "pointer",
  },
  textHeader: {
    color: "#2A3333",
    marginLeft: "14px",
    fontWeight: "600px",
    fontSize: "16px",
    lineHeight: "29.98px",
    fontStyle: "Semibold",
  },
  textHeaderT: {
    color: "#012497",
    fontWeight: "600px",
    fontSize: "16px",
    lineHeight: "29.98px",
  },
  mainHeader: {
    display: "flex",
    alignItems: "center",
    marginTop: "100px",
  },
  label: {
    fontFamily: "Cairo",
    fontWeight: 400,
    fontSize: "16px",
    color: "#2A3333",
  },
  saveBtn: {
    background: "#3D63E6",
    color: "#ffff",
    "&:hover": {
      background: "#3D63E6",
    },
    fontFamily: "Cairo",
    fontWeight: 700,
    fontSize: "16px",
    width: "160px",
    height: "50px",
  },
  gridBtn: {
    background: "transparent",
    color: "#ffff",
    "&:hover": {
      background: "transparent",
    },
    fontFamily: "Cairo",
    fontWeight: 700,
    fontSize: "16px",
    width: "162px",
    height: "56px",
  },
  gridBtnSmall: {
    background: "transparent",
    color: "#ffff",
    "&:hover": {
      background: "transparent",
    },
    fontFamily: "Cairo",
    fontWeight: 700,
    fontSize: "16px",
    width: "142px",
    height: "56px",
  },
  commentSaveBtn: {
    background: "#3D63E6",
    color: "#ffff",
    "&:hover": {
      background: "#3D63E6",
    },
    fontFamily: "Cairo",
    fontWeight: 700,
    fontSize: "16px",
    width: "120px",
    height: "56px",
  },
  cancelBtn: {
    color: "#3D63E6",
    borderColor: "#3D63E6",
    "&:hover": {
      borderColor: "#3D63E6",
    },
    fontFamily: "Cairo",
    fontWeight: 700,
    fontSize: "16px",
    width: "160px",
    height: "50px",
  },
  tabLabel: {
    color: "#3D63E6",
    fontWeight: "bold",
  },
  downloadLabel: {
    fontFamily: "Cairo",
    fontWeight: "400px",
    marginTop: "13px",
    fontSize: "16px",
    marginLeft: "10px",
    color: "#3D63E6",
  },
  messageImportLabel: {
    fontFamily: "Cairo",
    fontWeight: "400px",
    marginTop: "13px",
    fontSize: "16px",
    marginLeft: "12px",
    color: "#FFFFFF",
  },
  downloadBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "5px",
    border: "1px solid #3D63E6",
    height: "56px",
    width: "80%",
  },
  messageImportBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "5px",
    background: "#3D63E6",
    height: "56px",
    width: "100%",
  },
  chipBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "5px",
    border: "1px solid #C6C9CA",
    height: "40px",
    padding: "10px",
    background: "#FFF",
  },
  chipLabel: {
    fontFamily: "Cairo",
    fontWeight: "600px",
    fontSize: "16px",
    color: "#012497",
    marginTop: "15px",
  },
  numLabel: {
    fontFamily: "Cairo",
    fontWeight: "400px",
    fontSize: "14px",
    marginLeft: "20px",
    color: "#6C7A7A",
    marginTop: "16px",
  },
  dotBox: {
    display: "flex",
    alignItems: "flex-end",
    width: "100%",
  },
  selectBox: {
    display: "flex",
    alignItems: "flex-start",
  },
  textEnabled: {
    background: "#FFF",
    width: "92.5%",
    marginTop: "7px",
  },
  tableDiv: {
    border: "1px",
  },
  deleteModal: {
    position: "absolute",
    background: "#FFFFFF",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "0px",
    boxShadow: "0",
    zIndex: "9999",
    width: "410px",
    height: "230px",
  },
  importModal: {
    position: "absolute",
    background: "#F5F8FF",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "0px",
    boxShadow: "0",
    zIndex: "9999",
    width: "770px",
    height: "365px",
  },
  messageModal: {
    position: "absolute",
    background: "#F5F8FF",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "0px",
    boxShadow: "0",
    zIndex: "9999",
    width: "648px",
    height: "416px",
  },
  modalContainer: {
    padding: "20px 40px 40px 40px",
  },
  headerModal: {
    color: "#012497",
    fontFamily: "Cairo",
    fontWeight: "800",
    fontSize: "24px",
  },
  deleteTypo: {
    color: "#2A3333",
    fontFamily: "Cairo",
    fontWeight: "400",
    fontSize: "16px",
  },
  btnContainer: {
    display: "flex",
    alignItems: "flex-end",
  },
  uploadBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "5px",
    borderStyle: "dashed",
    borderWidth: "1px",
    border: "1px solid #6C7A7A",
    height: "56px",
    width: "100%",
    background: "#FFF",
    marginBottom: "5px",
  },
  uploadLabel: {
    fontFamily: "Cairo",
    fontWeight: "400px",
    marginTop: "13px",
    fontSize: "14px",
    marginLeft: "16px",
    color: "#2A3333",
    textDecorationLine: "underline",
  },
  selectArrow: {
    marginRight: "10px",
  },
  dataTableDiv: {
    maxHeight: "calc(100vh - 310px)",
    //maxWidth: "calc(159vh)",
    border: "1px solid #C6C9CA",
    overflowY: "auto",
    overflowX: "auto",
    marginTop: "0px",
  },
  modalDropDownStyle: {
    backgroundColor: "#FFFFFF",
    marginTop: "5px",
  },
}));
