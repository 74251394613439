import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  Box,
  CircularProgress,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectOrganization } from "store/global/fields/selector";
import { actions } from "store/global/fields/slice";
import { useStyles } from "../styles";
interface TProps {
  value: number;
  xs?: number;
  changeValue: (value: number) => void;
  disabled?: boolean;
  showAll: boolean;
  error?: string;
  activeRequired: boolean;
}
const OrganizationField = (props: TProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const styles = useStyles();
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const List = useSelector(selectOrganization);

  useEffect(() => {
    dispatch(actions.getOrganizationList(props.activeRequired));
    return () => {};
  }, []);

  const handleChange = (event) => {
    props.changeValue(event.target.value);
  };
  const hasError = !!props?.error;
  const loading = List.loading;

  const direction = useTheme().direction;
  return (
    <Grid item xs={props.xs ? props.xs : 2}>
      <label className={styles.label}>
        {t("TIMESLOT.ORGANIZATION_NAME")}
        {Number(props.value) > 0 ? (
          <Link
            to={`/organizations/${props.value}/view`}
            target="_blank"
            style={{ textDecoration: "none" }}
            rel="noopener noreferrer"
          >
            <OpenInNewIcon fontSize="small" />
          </Link>
        ) : null}
      </label>
      <Select
        margin="dense"
        disabled={props.disabled}
        fullWidth
        variant="outlined"
        required
        autoComplete={"off"}
        value={props.value}
        className={styles.textEnabled}
        onChange={handleChange}
        onClick={() => setDropDownOpen(props.disabled ? false : !dropDownOpen)}
        open={dropDownOpen}
        IconComponent={KeyboardArrowDownIcon}
        endAdornment={
          dropDownOpen && loading ? (
            <CircularProgress className={styles.loader} />
          ) : null
        }
        error={hasError}
      >
        {props.showAll && (
          <MenuItem
            sx={{
              direction,
            }}
            value={0}
          >
            {t("COMMON.ALL")}
          </MenuItem>
        )}

        {List.items.map((item) => (
          <MenuItem
            key={item.id}
            onClick={() => setDropDownOpen(!dropDownOpen)}
            value={item.id}
            sx={{
              direction,
            }}
          >
            {item.organizationName}
          </MenuItem>
        ))}
      </Select>
      <Box
        sx={{
          display: "flex",
          direction,
        }}
      >
        {hasError && <FormHelperText error>{props?.error}</FormHelperText>}
      </Box>
    </Grid>
  );
};
export default OrganizationField;
