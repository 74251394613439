import { ArrowBack } from "@mui/icons-material";
import { Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import { useStyles } from "./styles";
type Props = {
  subTitle?: string;
  onBackClick?(): void;
  title: string;
  withMargin?: boolean;
};
const BreadCrumb = ({ subTitle, onBackClick, title }: Props) => {
  const styles = useStyles();
  return (
    <Grid container mt={2} item xs={9.5} direction="column">
      <div className={styles.mainHeader}>
        <IconButton onClick={onBackClick}>
          <ArrowBack sx={{ color: "#012497" }} />{" "}
        </IconButton>

        <Typography className={styles.textHeader}>{title} </Typography>
        {subTitle ? (
          <Typography className={styles.textHeaderT}>
            &nbsp;/&nbsp;{subTitle}
          </Typography>
        ) : null}
      </div>
    </Grid>
  );
};

export default BreadCrumb;
