import { Button, Grid, TextField } from "@mui/material";
import { usePatientSaga } from "app/containers/Patients/redux/saga";
import { selectPatientCommnets } from "app/containers/Patients/redux/selector";
import {
  actions,
  reducer,
  sliceKey,
} from "app/containers/Patients/redux/slice";
import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { selectUser } from "store/global/authPermission/selector";
import { getFormattedDateTime } from "utils/helpers";
import { useStyles } from "./styles";

interface PatientDetailsProps {
  view: boolean;
  disable: boolean;
}

const CommentTextForm = ({ view, disable }: PatientDetailsProps) => {
  const styles = useStyles();
  const [comment, setComment] = useState("");
  const user = useSelector(selectUser);
  const saveClickHandler = () => {
    setComment("");
    const data = {
      content: comment,
      name: `${user.firstName} ${user.lastName}`,
    };
    dispatch(actions.setComment(data));
  };
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useInjectReducer({ key: sliceKey, reducer });
  useInjectSaga({ key: sliceKey, saga: usePatientSaga });

  const patientsComments = useSelector(selectPatientCommnets);

  return (
    <Grid
      container
      spacing={3}
      md={12}
      sx={{
        width: "100%",
        marginTop: "20px",
      }}
    >
      <Grid
        container
        spacing={3}
        md={12}
        sx={{
          width: "100%",
          justifyContent: "flex-start",
          marginLeft: "0px",
        }}
      >
        <Grid item xs={10} md={10}>
          <label className={styles.label}>{t("PATIENTS.COMMENT")}</label>
          <TextField
            disabled={disable}
            margin="dense"
            fullWidth
            variant="outlined"
            required
            value={comment}
            autoComplete={"off"}
            onChange={(e) => setComment(e.target.value)}
            placeholder={t("PATIENTS.COMMENT_TEXT")}
            className={styles.textEnabled}
          />
        </Grid>

        <Grid item xs={2} md={2}>
          <Button
            onClick={saveClickHandler}
            variant="contained"
            className={styles.btnSave}
            disabled={disable}
          >
            {t("BUTTONS.SAVE")}
          </Button>
        </Grid>
      </Grid>
      <div className={styles.inputsParent}>
        <section
          className={styles.bottomBox}
          style={{ position: "relative", left: "3px" }}
        >
          <textarea
            value={patientsComments
              .map(
                (x) =>
                  `${x.name ? getFormattedDateTime(x.time) : ""} ${
                    x.name ? "[" + x.name + "]" : ""
                  } ${x.content}`
              )
              .join("\n")}
            className={styles.paraTextArea}
            disabled={true}
          ></textarea>
        </section>
      </div>
    </Grid>
  );
};

export default CommentTextForm;
