import { Add, CalendarMonth, Delete } from "@mui/icons-material";
import { Button, Fab, Grid, TextField, Tooltip } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { BottomActions } from "app/components/BottomActions";
import BreadCrumb from "app/components/BreadCrumb";
import { ActionButtons } from "app/components/Button/ActionButtons";
import ConfirmDialog from "app/components/ConfirmDialog";
import { CustomDrawer } from "app/components/Drawer";
import ActualInstructorField from "app/components/FieldComponent/ActualInstructor";
import BankFiled from "app/components/FieldComponent/Bank";
import CityField from "app/components/FieldComponent/City";
import DepartmentFiled from "app/components/FieldComponent/Department";
import InstructorFiled from "app/components/FieldComponent/Instructor";
import OrganizationField from "app/components/FieldComponent/Organization";
import TreatmentTypeFiled from "app/components/FieldComponent/TreatmentType";
import { NoPermission } from "app/components/NoPermission";
import Topbar from "app/containers/Dashboard/components/topbar";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { selectFeaturePermissions } from "store/global/authPermission/selector";
import { PermissionFeature } from "store/global/authPermission/types";
import {
  selectBanksOptions,
  selectDepartmentOptions,
  selectOrganization,
  selectTreatment,
} from "store/global/fields/selector";
import { translatedBreadCrumb } from "styles/theme/utils";
import { InvolvementType } from "utils/constants";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import BonusPatientList from "./Components/BonusPatientList";
import EditTimeSlotModal from "./Components/EditTimeSlotModal";
import InvolvedPatientModal from "./Components/InvolvedPatientModal";
import RegisterPatientModal from "./Components/RegisterPatientModal";
import SessionList from "./Components/SesionList";
import { useTimeSlotActionSaga } from "./redux/saga";
import {
  selectDeleteModal,
  selectEditIndex,
  selectEditState,
  selectEditTimeSlotModel,
  selectHasSessionStarted,
  selectInvolvedPatientList,
  selectInvolvedPatientModal,
  selectList,
  selectLoading,
  selectMode,
  selectRegisterPatientModal,
  selectSessionDuration,
  selectTimeSlotBankInfo,
  selectTimeSlotFrom,
  selectTreatmentType,
} from "./redux/selector";
import { actions, reducer, sliceKey } from "./redux/slice";
import { useStyles } from "./styles";
import { TimeSlotMode, TreatmentType } from "./types";

const TimeSlotActions = () => {
  useInjectReducer({ key: sliceKey, reducer });
  useInjectSaga({ key: sliceKey, saga: useTimeSlotActionSaga });
  const styles = useStyles();
  const navigate = useNavigate();
  const { id } = useParams();
  const registerPatientModal = useSelector(selectRegisterPatientModal);
  const timeSlotBankInfo = useSelector(selectTimeSlotBankInfo);

  const involvedPatientModal = useSelector(selectInvolvedPatientModal);
  const sessionItems = useSelector(selectList);
  const involvedPatientList = useSelector(selectInvolvedPatientList);
  const editindex = useSelector(selectEditIndex);
  const deleteModal = useSelector(selectDeleteModal);
  const editTimeSlotModal = useSelector(selectEditTimeSlotModel);
  const sessionMode = useSelector(selectMode);
  const sessionTreatMentType = useSelector(selectTreatmentType);

  const organizationList = useSelector(selectOrganization);
  const departmentList = useSelector(selectDepartmentOptions);
  const bankList = useSelector(selectBanksOptions);
  const sessionDuration = useSelector(selectSessionDuration);
  const editMode = useSelector(selectEditState);

  const form = useSelector(selectTimeSlotFrom);
  const loading = useSelector(selectLoading);
  const treatment = useSelector(selectTreatment);
  const hasSessionStarted = useSelector(selectHasSessionStarted);

  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (!id) {
      const selectedTreatment = treatment.items.find(
        (x) => x.id === form.treatmentId.value
      );

      if (selectedTreatment) {
        dispatch(
          actions.changeTreatmentType(
            selectedTreatment.type !== "group"
              ? TreatmentType.IND
              : TreatmentType.GROUP
          )
        );

        const type =
          selectedTreatment.session_count === 1
            ? TimeSlotMode.SINGLE
            : TimeSlotMode.MULTI;
        dispatch(
          actions.timeSlotInfo({
            treatmentId: form.treatmentId.value,
            bankId: form.bankId.value,
          })
        );
        dispatch(actions.setInitSession(selectedTreatment));
        dispatch(actions.changeTimeslotMode(type));
        dispatch(actions.setTimeDuration(selectedTreatment.session_duration));
      } else {
        dispatch(actions.changeTimeslotMode(TimeSlotMode.SINGLE));
      }
    } else if (form.treatmentId.value > 0) {
      const selectedTreatment = treatment.items.find(
        (x) => x.id === form.treatmentId.value
      );

      if (selectedTreatment) {
        dispatch(actions.setTimeDuration(selectedTreatment.session_duration));
        dispatch(
          actions.timeSlotInfo({
            treatmentId: form.treatmentId.value,
            bankId: form.bankId.value,
          })
        );
      }
    }

    // Cleanup function
    return () => {};
  }, [form.treatmentId, id, treatment.items]);

  const retrunOrgnizationName = () => {
    if (form.organizationId.value == 0) {
      return "";
    } else {
      const name = organizationList.items.filter(
        (x) => x.id == form.organizationId.value
      )[0]?.organizationName;

      return name;
    }
  };

  const retrunDepartmentName = () => {
    if (form.departmentId.value == 0) {
      return "";
    } else {
      const name = departmentList.items.filter(
        (x) => x.id == form.departmentId.value
      )[0]?.name;

      return name;
    }
  };
  const retrunBankName = () => {
    if (form.bankId.value == 0) {
      return "";
    } else {
      const name = bankList.filter((x) => x.id == form.bankId.value)?.[0]?.name;
      return name;
    }
  };
  useEffect(() => {
    if (id) {
      dispatch(actions.getById(id));
      dispatch(actions.toggelEditState(true));
    } else {
      dispatch(actions.toggelEditState(false));
      dispatch(actions.clearForm());
    }
  }, [id]);
  const handelChangeValue = (val, name) => {
    dispatch(actions.updateFormValue({ key: name, value: val }));
  };

  const handelChangeSlotDate = (value) => {
    dispatch(actions.changeSlotDate({ value: value }));
  };

  useEffect(() => {
    return () => {
      dispatch(actions.clearForm());
    };
  }, []);
  const featurePermissions = useSelector(selectFeaturePermissions);
  const timeslotPermission = featurePermissions[PermissionFeature.TIMESLOTS];
  const { hasFullAccess, hasNoAccess } = timeslotPermission;

  const { mode = "view" } = useParams();

  if (hasNoAccess) {
    return (
      <div>
        <CustomDrawer />
        <Topbar />
        <NoPermission />
      </div>
    );
  }

  if ((mode === "new" || mode === "edit") && !hasFullAccess && !loading) {
    navigate("/time-slots");
    return null;
  }

  return (
    <Grid className={styles.mainContainer}>
      <CustomDrawer />
      <Grid item xs={12}>
        <Topbar />
        <div className={styles.bodyContainer}>
          <Grid
            container
            direction="row"
            spacing={0}
            md={12}
            mt={12}
            sx={{
              justifyContent: "space-between",
            }}
          >
            <Grid xs={10}>
              <BreadCrumb
                onBackClick={() => {
                  dispatch(actions.clearForm());
                  navigate("/time-slots");
                }}
                title={t("TIMESLOT.TIME_SLOT")}
                subTitle={
                  sessionMode === TimeSlotMode.MULTI
                    ? t(`TIMESLOT.MULTI.${translatedBreadCrumb(mode)}`)
                    : t(`TIMESLOT.SINGLE.${translatedBreadCrumb(mode)}`)
                }
              />
            </Grid>
            <Grid container item xs={2} direction="column" marginTop="20px">
              {id && (
                <Tooltip
                  title={
                    form?.associatedPatientCount?.value > 0
                      ? "Cannot delete because it has associated patients"
                      : "Delete"
                  }
                >
                  <Button
                    className={styles.deleteUserBtn}
                    variant="outlined"
                    disabled={
                      !id ||
                      loading ||
                      form?.associatedPatientCount?.value > 0 ||
                      mode === "view"
                    }
                    onClick={() => dispatch(actions.toggalDeleteModal())}
                  >
                    <Delete
                      sx={{
                        color: !id ? "#C6C9CA" : "#FF4839",
                        marginRight: "10px",
                      }}
                    />{" "}
                    {t("TIMESLOT.DELETE_TIME_SLOT")}
                  </Button>
                </Tooltip>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            md={12}
            sx={{
              padding: "5px 0px 10px 0px",
              justifyContent: "flex-start",
            }}
          >
            <OrganizationField
              xs={2.4}
              value={form.organizationId.value}
              changeValue={(value) => {
                handelChangeValue(value, "organizationId");
              }}
              disabled={editMode || loading || mode === "view"}
              showAll={false}
              activeRequired={false}
            ></OrganizationField>

            <DepartmentFiled
              xs={2.4}
              value={form.departmentId.value}
              changeValue={(value) => {
                handelChangeValue(value, "departmentId");
              }}
              orgId={form.organizationId.value}
              disabled={editMode || loading || mode === "view"}
              showAll={false}
            ></DepartmentFiled>

            <BankFiled
              xs={2.4}
              value={form.bankId.value}
              organizationId={form.organizationId.value}
              departmentId={form.departmentId.value}
              changeValue={(value) => {
                handelChangeValue(value, "bankId");
              }}
              disabled={editMode || loading || mode === "view"}
              showAll={false}
              includeStatus={
                form?.bank?.bankStatus == "Done" ? ["Done"] : ["New", "Running"]
              }
            ></BankFiled>

            <TreatmentTypeFiled
              xs={2.4}
              value={form.treatmentId.value}
              bankId={form.bankId.value}
              changeValue={(value) => {
                handelChangeValue(value, "treatmentId");
                handelChangeValue(undefined, "instructorId");
              }}
              disabled={editMode || loading || mode === "view"}
              showAll={false}
              fetchAll={false}
            ></TreatmentTypeFiled>
          </Grid>
          <Grid
            container
            spacing={3}
            md={12}
            sx={{
              padding: "5px 0px 10px 0px",
              justifyContent: "flex-start",
            }}
          >
            <InstructorFiled
              xs={2.4}
              label={t("TIMESLOT.INSTRUCTOR_NAME")}
              value={form.instructorId.value}
              treatmentId={form.treatmentId.value}
              bankId={form.bankId.value}
              changeValue={(value) => {
                handelChangeValue(value, "instructorId");
                if (!id) {
                  handelChangeValue(value, "actualInstructorId");
                }
              }}
              disabled={
                editMode ||
                loading ||
                !timeslotPermission?.hasFullAccess ||
                mode === "view"
              }
              showAll={false}
            ></InstructorFiled>
            <ActualInstructorField
              xs={2.4}
              value={form.actualInstructorId.value}
              treatmentId={form.treatmentId.value}
              changeValue={(value) => {
                handelChangeValue(value, "actualInstructorId");
              }}
              bankId={form.bankId.value}
              instructorId={form.instructorId.value}
              disabled={
                loading || !timeslotPermission?.hasFullAccess || mode === "view"
              }
              isEdit={id ? true : false}
              showAll={false}
            ></ActualInstructorField>
            <Grid item xs={2.4}>
              <label className={styles.label}>{t("TIMESLOT.STREET")}</label>
              <TextField
                margin="dense"
                variant="outlined"
                fullWidth
                required
                id="street"
                placeholder={t("TIMESLOT.STREET")}
                name="street"
                autoComplete={"off"}
                value={form.street.value}
                onChange={(e) => handelChangeValue(e.target.value, "street")}
                className={styles.textEnabled}
                disabled={
                  loading ||
                  !timeslotPermission?.hasFullAccess ||
                  mode === "view"
                }
              />
            </Grid>
            <CityField
              xs={2.4}
              value={form.cityId.value}
              changeValue={(value) => {
                handelChangeValue(value, "cityId");
              }}
              disabled={
                loading || !timeslotPermission?.hasFullAccess || mode === "view"
              }
            ></CityField>
            {sessionMode === TimeSlotMode.SINGLE && (
              <Tooltip
                title={hasSessionStarted ? "TimeSlot has passed sessions" : ""}
              >
                <Grid item xs={2}>
                  <label className={styles.label}>
                    {t("TIMESLOT.ASSIGNED_DATE")}
                  </label>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      views={["day", "month", "year"]}
                      value={form.slotDate.value}
                      minDate={new Date()}
                      onChange={(newValue) => handelChangeSlotDate(newValue)}
                      components={{
                        OpenPickerIcon: CalendarMonth,
                      }}
                      OpenPickerButtonProps={{
                        style: { color: true ? "#6C7A7A" : "#012397" },
                      }}
                      disabled={
                        hasSessionStarted ||
                        loading ||
                        !timeslotPermission?.hasFullAccess ||
                        mode === "view"
                      }
                      className={styles.textEnabled}
                      inputFormat="dd/MM/yyyy"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          margin="dense"
                          helperText={form?.slotDate?.error}
                          FormHelperTextProps={{
                            style:
                              i18n.dir() === "rtl"
                                ? { textAlign: "right" }
                                : {},
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "__/__/____",
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Tooltip>
            )}
          </Grid>
          <Grid
            container
            item
            xs={12}
            direction="row"
            sx={{
              padding: "20px 0px 30px 0px",
              justifyContent: "space-between",
            }}
          >
            <SessionList />
            <BonusPatientList />
          </Grid>
          <Grid
            container
            item
            direction="row"
            xs={12}
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Grid
              xs={8}
              item
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {sessionMode === TimeSlotMode.MULTI && (
                <Button
                  className={styles.regBtn}
                  disabled={
                    loading ||
                    form.hasPassedSessions ||
                    !form.instructorId.value ||
                    mode === "view"
                  }
                  onClick={() => {
                    dispatch(actions.toggleRegisterPatient());
                  }}
                  variant="outlined"
                >
                  {t("TIMESLOT.REGISTER_PATIENTS")}
                </Button>
              )}
              {sessionMode === TimeSlotMode.SINGLE && (
                <Fab
                  size="medium"
                  color="secondary"
                  aria-label="add"
                  sx={{ marginTop: 2, marginBottom: 2 }}
                  onClick={() => {
                    let treatmentData = treatment.items.find(
                      (x) => x.id === form.treatmentId.value
                    );
                    if (treatmentData) {
                      dispatch(
                        actions.addSlot({
                          duration: treatmentData?.session_duration,
                        })
                      );
                    }
                  }}
                  disabled={
                    loading ||
                    !form.treatmentId.value ||
                    !timeslotPermission?.hasFullAccess ||
                    mode === "view"
                  }
                >
                  <Add />
                </Fab>
              )}
            </Grid>
            <Grid
              xs={4}
              item
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Fab
                size="medium"
                color="secondary"
                aria-label="add"
                sx={{ marginTop: 2, marginBottom: 2 }}
                onClick={() => {
                  if (
                    involvedPatientList.items.filter(
                      (x) => x.type === InvolvementType.Bonus && !x.deleted
                    ).length > 0
                  ) {
                    dispatch(
                      actions.setPatientSelectedIds(
                        involvedPatientList.items
                          .filter(
                            (x) =>
                              x.type === InvolvementType.Bonus && !x.deleted
                          )
                          .map((x) => x.patientId)
                      )
                    );
                  }
                  dispatch(actions.toggalBonusPatient());
                }}
                disabled={
                  loading ||
                  involvedPatientList.items.filter(
                    (x) => x.type === InvolvementType.Bonus && !x.deleted
                  ).length >= timeSlotBankInfo.totalPatientCapacity ||
                  !timeslotPermission?.hasFullAccess ||
                  mode === "view"
                }
              >
                <Add />
              </Fab>
            </Grid>
          </Grid>

          <Grid
            container
            item
            columnGap={3}
            sx={{
              padding: "40px 0px 40px 0px",
              justifyContent: "flex-end",
            }}
            xs={12}
          >
            <BottomActions
              disabled={!timeslotPermission?.hasFullAccess || mode === "view"}
              chipBoxes={[
                { label: t("TIMESLOT.TYPE"), count: timeSlotBankInfo.type },
                {
                  label: t("TIMESLOT.MAX_PATIENTS_PER_SESSION"),
                  count: timeSlotBankInfo.totalTreatmentPatientCapacity,
                },
              ]}
            >
              {timeslotPermission?.hasFullAccess && (
                <>
                  {mode === "view" ? (
                    <Button
                      className={styles.editBtn}
                      variant="contained"
                      onClick={() =>
                        navigate(`/time-slots/time-slots-actions/${id}/edit`)
                      }
                    >
                      {t("BUTTONS.EDIT")}
                    </Button>
                  ) : (
                    <ActionButtons
                      loading={loading}
                      onCancel={() => {
                        dispatch(actions.clearForm());
                        navigate("/time-slots");
                      }}
                      onSave={() => {
                        if (!id) {
                          dispatch(
                            actions.doAddTimeslot({
                              callback: () => {
                                dispatch(actions.clearForm());
                                navigate("/time-slots");
                              },
                            })
                          );
                        } else {
                          dispatch(
                            actions.doEditTimeSlot({
                              callback: () => {
                                dispatch(actions.clearForm());
                                navigate("/time-slots");
                              },
                            })
                          );
                        }
                      }}
                    />
                  )}
                </>
              )}
            </BottomActions>
          </Grid>
        </div>
      </Grid>
      {registerPatientModal && (
        <RegisterPatientModal
          instructorId={form.instructorId.value}
          timeSlotId={form.id.value}
          departmentId={form.departmentId.value}
          bankId={form.bankId.value}
          organizationId={form.organizationId.value}
          treatmentId={form.treatmentId.value}
          organizationName={retrunOrgnizationName()}
          departmentName={retrunDepartmentName()}
          bankName={retrunBankName()}
          closeRequest={() => {
            dispatch(actions.toggleRegisterPatient());
          }}
          addItems={() => {
            let treatmentData = treatment.items.filter(
              (x) => x.id == form.treatmentId.value
            );
            dispatch(
              actions.setSessionPatient({
                type: sessionTreatMentType,
                duration: treatmentData[0].session_duration,
                callback: () => {
                  dispatch(actions.toggleRegisterPatient());
                },
              })
            );
          }}
          mode={sessionTreatMentType}
          open={registerPatientModal}
        ></RegisterPatientModal>
      )}
      {editTimeSlotModal && editindex > -1 && (
        <EditTimeSlotModal
          startTime={sessionItems.items[editindex]?.start_time}
          endTime={sessionItems.items[editindex]?.end_time}
          closeRequest={() => {
            dispatch(actions.setTogaglEditModal(false));
          }}
          addItems={(start_time, end_time) => {
            dispatch(
              actions.setEditSession({
                type: sessionMode,
                startTime: start_time,
                endTime: end_time,
                callback: () => {
                  dispatch(actions.setTogaglEditModal(false));
                },
              })
            );
          }}
          mode={sessionMode}
          open={editTimeSlotModal}
          sessionDuration={sessionDuration}
        ></EditTimeSlotModal>
      )}
      {involvedPatientModal && editindex && (
        <InvolvedPatientModal
          departmentId={form.departmentId.value}
          bankId={form.bankId.value}
          organizationId={form.organizationId.value}
          closeRequest={() => {
            dispatch(actions.toggalBonusPatient());
          }}
          addItems={() => {
            dispatch(actions.toggalBonusPatient());
            dispatch(actions.setBonusPatient());
          }}
          editindex={editindex}
          open={involvedPatientModal}
        ></InvolvedPatientModal>
      )}
      <ConfirmDialog
        title={t("TIMESLOT.DELETE_TIME_SLOT")}
        description={t("TIMESLOT.CONFIRM_DELETE_TIME_SLOT")}
        open={deleteModal}
        onCancel={() => dispatch(actions.toggalDeleteModal())}
        onConfirm={() => {
          dispatch(actions.toggalDeleteModal());
          dispatch(
            actions.doDeleteTimeSlot({
              callback: () => {
                navigate(-1);
              },
            })
          );
        }}
        onClose={() => dispatch(actions.toggalDeleteModal())}
      />
    </Grid>
  );
};
export default TimeSlotActions;
