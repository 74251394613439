import { formatISO, parseISO, set } from "date-fns";

export const assignTineWithDate = (date: string | Date, time: string) => {
  const parsedAssignedDate = typeof date === "string" ? parseISO(date) : date;
  const parsedStartTime = parseISO(time);
  const newTime = set(parsedStartTime, {
    year: parsedAssignedDate.getFullYear(),
    month: parsedAssignedDate.getMonth(),
    date: parsedAssignedDate.getDate(),
  });
  return formatISO(newTime);
};
// Function to find non-overlapping dates
