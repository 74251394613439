import { Button, Grid } from "@mui/material";
import { BottomActions } from "app/components/BottomActions";
import { CustomDrawer } from "app/components/Drawer";
import { NoPermission } from "app/components/NoPermission";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { selectFeaturePermissions } from "store/global/authPermission/selector";
import { PermissionFeature } from "store/global/authPermission/types";
import Topbar from "../Dashboard/components/topbar";
import AssignInstructorForm from "./components/AssignInstructorForm";
import FiltersForm from "./components/FiltersForm";
import AssignedTreatmentList from "./components/List";
import { useAssignedTreatmentSaga } from "./redux/saga";
import { selectList, selectLoading, selectQuery } from "./redux/selector";
import { actions, reducer, sliceKey } from "./redux/slice";
import { useStyles } from "./styles";

const AssignedTreatment = () => {
  useInjectReducer({ key: sliceKey, reducer });
  useInjectSaga({ key: sliceKey, saga: useAssignedTreatmentSaga });
  const [showAssignInstructor, setShowAssignInstructor] = useState(false);
  const styles = useStyles();
  const navigate = useNavigate();
  const params = useLocation();
  const isEditPage = params.search.split("=")[1] === "edit";
  const { t } = useTranslation();
  const { meta, selectedIds } = useSelector(selectList);
  const { q } = useSelector(selectQuery);
  const isLoading = useSelector(selectLoading);

  const dispatch = useDispatch();

  const handleEditMode = () => {
    navigate("/assigned-treatment?mode=edit");
  };
  const handleNoMode = () => {
    navigate("/assigned-treatment?mode=view");
  };

  const setPageQuery = (name: string, value: any) => {
    dispatch(actions.setQuery({ name, value }));
  };

  const featurePermissions = useSelector(selectFeaturePermissions);
  const assigningPermission =
    featurePermissions[PermissionFeature.ASSIGNED_TREATNEMTS_A];
  const schedulingPermission =
    featurePermissions[PermissionFeature.ASSIGNED_TREATNEMTS_B];
  if (assigningPermission.hasNoAccess && schedulingPermission.hasNoAccess) {
    return (
      <div className={styles.parent}>
        <CustomDrawer />
        <Topbar />
        <NoPermission />
      </div>
    );
  }
  return (
    <div className={styles.parent}>
      <CustomDrawer />
      <Grid item xs={12}>
        <Topbar q={q} setSearch={(q) => setPageQuery("q", q)} />
        <div className={styles.bodyContainer}>
          <FiltersForm />
          <AssignedTreatmentList />
          <BottomActions
            disabled={!assigningPermission.hasFullAccess || isLoading}
            chipBoxes={[
              {
                label: t("PLACEHOLDERS.TOTAL_ITEMS"),
                count: meta.totalItems,
              },
            ]}
          >
            {isEditPage ? (
              <>
                {selectedIds?.length > 0 ? (
                  <Button
                    onClick={() => setShowAssignInstructor(true)}
                    className={styles.button}
                    variant="contained"
                    color="secondary"
                    disabled={isLoading}
                  >
                    {" "}
                    {t("BUTTONS.ASSIGN_INSTRUCTOR")}{" "}
                  </Button>
                ) : (
                  <Button
                    onClick={handleNoMode}
                    className={styles.cancelBtn}
                    variant="outlined"
                    disabled={isLoading}
                  >
                    {" "}
                    {t("BUTTONS.CANCEL")}{" "}
                  </Button>
                )}
              </>
            ) : (
              assigningPermission.hasFullAccess && (
                <Button
                  onClick={handleEditMode}
                  className={styles.button}
                  variant="contained"
                  color="secondary"
                  disabled={!assigningPermission.hasFullAccess || isLoading}
                >
                  {" "}
                  {t("BUTTONS.EDIT")}{" "}
                </Button>
              )
            )}
          </BottomActions>
        </div>
      </Grid>
      <AssignInstructorForm
        open={showAssignInstructor}
        handleClose={() => setShowAssignInstructor(false)}
        patientId={undefined}
      />
    </div>
  );
};

export default AssignedTreatment;
