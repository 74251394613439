import {
  MAX_LIMIT_BANKS_TREATMENTS,
  TRANSLATE,
  isBetween,
} from "utils/helpers";
import toastService from "utils/toast";
import { MaxFormCalculation, TreatmentForm } from "../../types";
export const validate = (
  form: TreatmentForm,
  maxForm: MaxFormCalculation
): boolean | undefined => {
  if (!form.organizationId) {
    toastService.error(
      TRANSLATE("TREATMENT_BANKS.PLEASE_SELECT_ORGANIZATION_NAME")
    );
    return;
  }
  if (!form.departmentId) {
    toastService.error(
      TRANSLATE("TREATMENT_BANKS.PLEASE_SELECT_DEPARTMENT_NAME")
    );
    return;
  }

  if (!form.bankName) {
    toastService.error(TRANSLATE("TREATMENT_BANKS.PLEASE_ENTER_BANK_NAME"));
    return;
  }

  if (!form.bankStatus || form.bankStatus?.toString().length === 0) {
    toastService.error(TRANSLATE("TREATMENT_BANKS.PLEASE_SELECT_BANK_STATUS"));
    return;
  }
  if (!form.bankStatus || form.bankStatus?.toString().length === 0) {
    toastService.error(TRANSLATE("TREATMENT_BANKS.PLEASE_SELECT_BANK_STATUS"));
    return;
  }

  const totalPatients = isBetween(form.totalPatient, 1, maxForm.maxForPatient);
  if (!totalPatients) {
    toastService.error(
      TRANSLATE("TREATMENT_BANKS.TOTAL_PATIENTS_RANGE", {
        min: "1",
        max: `${maxForm.maxForPatient}`,
      })
    );
    return;
  }
  if (maxForm.treatmentsCount < 1) {
    toastService.error(
      TRANSLATE("TREATMENT_BANKS.PLEASE_ADD_TREATMENTS", {
        min: "1",
        max: "10",
      })
    );
    return;
  }
  const totalTreatments = isBetween(
    maxForm.treatmentsCount,
    1,
    MAX_LIMIT_BANKS_TREATMENTS
  );
  if (!totalTreatments) {
    toastService.error(
      TRANSLATE("TREATMENT_BANKS.TOTAL_TREATMENTS_COUNT_VALIDATION", {
        min: "1",
        max: String(MAX_LIMIT_BANKS_TREATMENTS),
      })
    );
    return;
  }

  if (Number(form.groupPerPatient) > 0) {
    const between = isBetween(form.groupPerPatient, 0, maxForm.maxForGroup);
    if (!between) {
      toastService.error(
        TRANSLATE(
          "TREATMENT_BANKS.PLEASE_ENTER_CORRECT_REMAINING_GROUP_PER_PATIENT",
          { min: "0", max: `${maxForm.maxForGroup}` }
        )
      );
      return;
    }
  }
  if (Number(form.indPerPatient) > 0) {
    const between = isBetween(form.indPerPatient, 0, maxForm.maxForIndividual);

    if (!between) {
      toastService.error(
        TRANSLATE(
          "TREATMENT_BANKS.PLEASE_ENTER_CORRECT_REMAINING_INDIVIDUAL_PER_PATIENT",
          { min: "0", max: `${maxForm.maxForIndividual}` }
        )
      );
      return;
    }
  }

  return true;
};
