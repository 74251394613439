import { Button, Card, Grid, Modal, Typography, useTheme } from "@mui/material";
import QuestionnaireFiled from "app/components/FieldComponent/Questionnaire";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectQuestionnaire } from "../../redux/selector";
import { actions } from "../../redux/slice";
import { useStyles } from "./styles";

type Props = {
  open: boolean;
  handleClose: any;
  style?: object;
  action: string;
  handleSave: any;
};

const QuestionnaireModal = ({
  open,
  handleClose,
  handleSave,
  style,
  action,
}: Props) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const [enable, setEnable] = useState(true);
  const form = useSelector(selectQuestionnaire);
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card
        sx={{
          direction: theme.direction,
        }}
        className={styles.importModal}
      >
        <div className={styles.modalContainer}>
          <Grid>
            <Typography align="center" className={styles.headerModal}>
              {action?.toLowerCase() === "add"
                ? t("ORGANIZATIONS.ADD_WELLBEING_QUESTIONNAIRE")
                : t("ORGANIZATIONS.EDIT_WELLBEING_QUESTIONNAIRE")}
            </Typography>
          </Grid>
          <Grid
            container
            style={{ display: "flex", justifyContent: "center" }}
            md={12}
            sx={{ padding: "10px 0px 0px 0px" }}
          >
            <Grid item xs={10}>
              <QuestionnaireFiled
                type={"mcq"}
                value={form.id.value}
                showAll={false}
                xs={12}
                disabled={!enable}
                error={form.questionnaireName.error || form.id.error}
                changeValue={(e, name) => {
                  dispatch(
                    actions.updateFormValue({
                      key: "id",
                      value: e,
                    })
                  );
                  dispatch(
                    actions.updateFormValue({
                      key: "questionnaireName",
                      value: name,
                    })
                  );
                }}
              ></QuestionnaireFiled>
            </Grid>
          </Grid>

          <div>
            <Grid
              container
              spacing={3}
              style={{ position: "relative", top: "35px" }}
            >
              <Grid
                className={styles.btnContainer}
                container
                item
                xs={6}
                direction="column"
              >
                <Button
                  className={styles.cancelBtn}
                  variant="outlined"
                  onClick={handleClose}
                >
                  {t("BUTTONS.CANCEL")}
                </Button>
              </Grid>
              <Grid container item xs={6} direction="column">
                <Button
                  disabled={!!!form.questionnaireName.value}
                  onClick={() => {
                    if (enable) handleSave();
                  }}
                  className={styles.saveBtn}
                  variant="contained"
                >
                  {t("BUTTONS.SAVE")}
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </Card>
    </Modal>
  );
};
export default QuestionnaireModal;
