export const DAILY_OPTIONS = {
  sliceVisibilityThreshold: 0,
  legend: { position: "bottom" },
  titleTextStyle: {
    color: "#464646",
    fontSize: "18px",
    postion: "absolute",
  },
  slices: [
    {
      color: "#54C9C4",
    },
    {
      color: "#686CFF",
    },
  ],
  backgroundColor: "transparent",
  enableInteractivity: false,
};

export const MEETING_OPTIONS = {
  sliceVisibilityThreshold: 0,
  legend: { position: "bottom" },
  titleTextStyle: {
    color: "#464646",
    fontSize: "18px",
    position: "absolute",
  },
  slices: [
    {
      color: "#54C9C4",
    },
    {
      color: "#686CFF",
    },
    {
      color: "#5123BA",
    },
  ],
  backgroundColor: "transparent",
  enableInteractivity: false,
};
