import i18next from "i18next";
import { FormError } from "../types";
import { getQuestionRange } from "./helpers";
import { parseNumber } from "./multi-questioner-form.validator";
import {
  GradesListItemType,
  QuestionListItemType,
  QuestionerForm,
} from "./types";

export const validateQuestions = (
  questions: QuestionListItemType[]
): FormError[] => {
  let errors: FormError[] = [];
  questions.forEach((question, index) => {
    if (!question.question) {
      errors.push({
        name: `questions.[${index}].errors.question`,
        error: i18next.t("QUESTIONNAIRES.FORM.ERRORS.QUESTION_REQUIRED"),
      });
    }
    const key = `questions.[${index}].`;
    question.answers.forEach((answer, index) => {
      if (!answer.content) {
        errors.push({
          name: `${key}answers[${index}].errors.content`,
          error: i18next.t("QUESTIONNAIRES.FORM.ERRORS.ANSWER_REQUIRED"),
        });
      }
      if (!String(answer.weight)) {
        errors.push({
          name: `${key}answers[${index}].errors.weight`,
          error: i18next.t("QUESTIONNAIRES.FORM.ERRORS.ANSWER_WEIGHT_REQUIRED"),
        });
      }
      if (answer.weight && !(Number(answer.weight) > 0)) {
        errors.push({
          name: `${key}answers[${index}].errors.weight`,
          error: i18next.t(
            "QUESTIONNAIRES.FORM.ERRORS.ANSWER_WEIGHT_MUST_BE_NUMBER"
          ),
        });
      }

      if (answer.weight && !isNumberInRange(answer.weight)) {
        errors.push({
          name: `${key}answers[${index}].errors.weight`,
          error: i18next.t(
            "QUESTIONNAIRES.FORM.ERRORS.ANSWER_MUST_BE_IN_RANGE",
            {
              min: 0,
              max: 100,
            }
          ),
        });
      }
    });
    if (!question.answers.length) {
      errors.push({
        name: `questions.[${index}].errors.answersGeneral`,
        error: i18next.t("QUESTIONNAIRES.FORM.ERRORS.MUST_HAVE_MIN_ANSWER", {
          value: 1,
        }),
      });
    }
  });
  return errors;
};

function getDuplicateAnswers(questions: QuestionListItemType[]): FormError[] {
  const errors: FormError[] = [];

  const questionContents = new Set();

  for (const questionIndex in questions) {
    const question = questions[questionIndex];
    const questionContent = String(question.question).trim();
    if (questionContents.has(questionContent)) {
      errors.push({
        name: `questions[${questionIndex}].errors.question`,
        error: "Duplicate question found.",
      });
    }
    questionContents.add(questionContent);
    const answerContents = new Set();
    for (const answerIndex in question.answers) {
      const answer = question.answers[answerIndex];
      const answerContent = String(answer.content).trim();
      if (answerContents.has(answerContent)) {
        errors.push({
          name: `questions[${questionIndex}].answers[${answerIndex}].errors.content`,
          error: "Duplicate answer found.",
        });
      }
      answerContents.add(answerContent);
    }
  }
  return errors;
}

function getDuplicateGrades(grades: GradesListItemType[]): FormError[] {
  const errors: FormError[] = [];
  const gradeContents = new Set();

  for (const gradeIndex in grades) {
    const grade = grades[gradeIndex];
    if (grade.isDeleted) {
      continue;
    }
    const gradeContent = String(grade.gradeName).trim();
    if (gradeContents.has(gradeContent)) {
      errors.push({
        name: `grades[${gradeIndex}].errors.gradeName`,
        error: "Duplicate grade found.",
      });
    }
    gradeContents.add(gradeContent);
  }

  return errors;
}

function isNumberInRange(number) {
  return Number(number) >= 0 && Number(number) <= 100;
}

export const validateGrades = (form: QuestionerForm): FormError[] => {
  const grades = form.questionnaire.grades;
  const errors: FormError[] = [];
  grades.forEach((grade, index) => {
    if (grade.isDeleted) {
      return;
    }
    if (!grade.gradeName) {
      errors.push({
        name: `grades.[${index}].errors.gradeName`,
        error: i18next.t("QUESTIONNAIRES.FORM.ERRORS.GRADE_NAME_REQUIRED"),
      });
    }
    if (!grade.gradeName) {
      errors.push({
        name: `errors.gradeName`,
        error: i18next.t("QUESTIONNAIRES.FORM.ERRORS.GRADE_NAME_REQUIRED"),
      });
    }

    if (!grade.questionNumbers) {
      errors.push({
        name: `errors.gradeName`,
        error: i18next.t("QUESTIONNAIRES.FORM.ERRORS.GRADE_QUESTION_REQUIRED"),
      });
    }

    if (!grade.gradeType) {
      errors.push({
        name: `errors.gradeName`,
        error: i18next.t("QUESTIONNAIRES.FORM.ERRORS.GRADE_TYPE_REQUIRED"),
      });
    }

    const fQuestions = grade?.questionNumbers.length
      ? form.questionnaire.questions.filter((f) =>
          grade?.questionNumbers.includes(String(f.questionNumber))
        )
      : [];

    grade.ranges.forEach((range, i) => {
      const key = `grades.[${index}].ranges[${i}].`;

      if (range.from && range.to && Number(range.to) < Number(range.from)) {
        errors.push({
          name: `errors.to`,
          error: i18next.t(
            "QUESTIONNAIRES.FORM.ERRORS.TO_MUST_BE_GREATER_THAN_FROM",
            {
              min: 0,
              max: 100,
            }
          ),
        });
      }

      const currentRange = fQuestions.length
        ? getQuestionRange(fQuestions)
        : undefined;
      const from = parseNumber(range.from);
      const to = parseNumber(range.to);
      const minNumber = parseNumber(currentRange?.min) || 0;
      const maxNumber = parseNumber(currentRange?.max) || 0;
      if (
        from === undefined ||
        to === undefined ||
        from < minNumber ||
        to > maxNumber
      ) {
        errors.push({
          name: `${key}errors.to`,
          error: i18next.t("QUESTIONNAIRES.FORM.ERRORS.RANGE_OUT_OF_BOUNDS", {
            min: minNumber,
            max: maxNumber,
            range: `${from || ""}-${to || ""}`,
          }),
        });
      }

      if (!range.rangeName) {
        errors.push({
          name: `${key}errors.rangeName`,
          error: i18next.t("QUESTIONNAIRES.FORM.ERRORS.RANGE_NAME_REQUIRED"),
        });
      }

      if (!range.description) {
        errors.push({
          name: `${key}errors.description`,
          error: i18next.t(
            "QUESTIONNAIRES.FORM.ERRORS.RANGE_DESCRIPTION_REQUIRED"
          ),
        });
      }
    });
  });
  return errors;
};

export const validate = (form: QuestionerForm): FormError[] => {
  let errors: FormError[] = [];
  if (!form.questionnaire.name) {
    errors.push({
      name: "questionnaire.errors.name",
      error: i18next.t("QUESTIONNAIRES.FORM.ERRORS.QUESTIONER_NAME_REQUIRED"),
    });
  }
  validateQuestions(form.questionnaire.questions).forEach((e, index) => {
    errors.push({
      name: `questionnaire.${e.name}`,
      error: e.error,
    });
  });
  validateGrades(form).forEach((e, index) => {
    errors.push({
      name: `questionnaire.${e.name}`,
      error: e.error,
    });
  });
  const nonDeleted = form.questionnaire.grades.filter((f) => !f.isDeleted);
  if (!nonDeleted.length) {
    errors.push({
      name: "questionnaire.errors.gradesGeneral",
      error: i18next.t(
        "QUESTIONNAIRES.FORM.ERRORS.QUESTIONER_GRADES_REQUIRED",
        {
          value: 1,
        }
      ),
    });
  }
  const hasOneMainGrade = nonDeleted.filter((f) => f.mainGrade).length;
  if (nonDeleted.length && hasOneMainGrade !== 1) {
    errors.push({
      name: "questionnaire.errors.gradesGeneral",
      error: i18next.t(
        "QUESTIONNAIRES.FORM.ERRORS.QUESTIONER_GRADES_MUST_HAVE_MAIN_GRADE",
        {
          value: 1,
        }
      ),
    });
  }

  getDuplicateAnswers(form.questionnaire.questions).forEach((e, index) => {
    errors.push({
      name: `questionnaire.${e.name}`,
      error: e.error,
    });
  });

  getDuplicateGrades(form.questionnaire.grades).forEach((e, index) => {
    errors.push({
      name: `questionnaire.${e.name}`,
      error: e.error,
    });
  });

  return errors;
};
