import { Add } from "@mui/icons-material";
import { Fab, Grid } from "@mui/material";
import DataTable from "app/components/DataTable";
import { useUsersListSaga } from "app/containers/Users/redux/saga";
import {
  reducer as userReducer,
  sliceKey as userSliceKey,
} from "app/containers/Users/redux/slice";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectFeaturePermissions } from "store/global/authPermission/selector";
import { PermissionFeature } from "store/global/authPermission/types";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import QuestionnaireModal from "./components/QuestionnaireModal";
import { useUserSaga } from "./redux/saga";
import { selectList, selectLoading, selectQuery } from "./redux/selector";
import { actions, reducer, sliceKey } from "./redux/slice";
import { useStyles } from "./styles";

interface QuestionnaireListProps {
  view: boolean;
  disable: boolean;
  mode: String;
}

export const QuestionnaireList = ({
  view,
  disable,
  mode,
}: QuestionnaireListProps) => {
  const [openDelete, setOpenDelete] = useState(false);
  const [currentQuestionnaireName, setCurrentQuestionnaireName] = useState("");
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const listitems = useSelector(selectList);

  const {
    q,
    orderField,
    orderDirection,
    distributorId,
    user_type,
    createMax,
    createMin,
    updateMax,
    updateMin,
  } = useSelector(selectQuery);
  const { t } = useTranslation();
  const styles = useStyles();
  useInjectReducer({ key: sliceKey, reducer });
  useInjectSaga({ key: sliceKey, saga: useUserSaga });
  useInjectReducer({ key: userSliceKey, reducer: userReducer });
  useInjectSaga({ key: userSliceKey, saga: useUsersListSaga });
  const columns = [
    {
      id: "questionnaireName",
      label: t("ORGANIZATIONS.QUESTIONNAIRE_NAME"),
      sortValue: "selector.questionnaireName",
    },
  ];
  const dispatch = useDispatch();
  useEffect(() => {}, [
    q,
    orderDirection,
    orderField,
    distributorId,
    user_type,
    createMax,
    createMin,
    updateMax,
    updateMin,
    dispatch,
  ]);
  const setPageQuery = (name: string, value: any) => {
    dispatch(actions.setQuery({ name, value }));
  };
  const loading = useSelector(selectLoading);

  const handlEditModalClose = () => {
    setOpenEditModal(false);
    dispatch(actions.resetNewQuestionnaireForm());
  };
  const handlEditModalSave = () => {
    setOpenEditModal(false);
    dispatch(actions.create());
    dispatch(actions.resetNewQuestionnaireForm());
  };
  const handlEditModalOpen = (e) => {
    for (let i in e) {
      dispatch(actions.updateFormValue({ key: i, value: e[i] }));
    }
    setOpenEditModal(true);
  };
  const handlAddModalClose = () => {
    setOpenAddModal(false);
    dispatch(actions.resetNewQuestionnaireForm());
  };
  const handlAddModalSave = () => {
    setOpenAddModal(false);
    dispatch(actions.create());
    dispatch(actions.resetNewQuestionnaireForm());
  };
  const handlAddModalOpen = () => {
    setOpenAddModal(true);
  };
  const handleDeleteQuestionnaire = (row, idx) => {
    dispatch(actions.deleteQuestionnaire({ ...row, idx }));
  };
  const featurePermissions = useSelector(selectFeaturePermissions);
  const { hasFullAccess, hasReadAccess, hasNoAccess } =
    featurePermissions[PermissionFeature.QUESTIONNAIRES];
  return (
    <>
      <DataTable
        loading={loading}
        orderDirection={orderDirection}
        orderField={orderField}
        setQuery={setPageQuery}
        editAction={handlEditModalOpen}
        rows={listitems["items"].filter((item) => item.action !== "delete")}
        columns={columns}
        page="questionnaireOrgList"
        Actions={hasFullAccess ? "Delete" : ""}
        scrollClass={styles.dataTableDiv}
        disableActions={disable ? "Delete" : ""}
        deleteAction={handleDeleteQuestionnaire}
      />

      <Grid
        item
        style={{
          display: "flex",
          justifyContent: "flex-end",
          maxWidth: "calc(79vh)",
        }}
      >
        <Fab
          size="medium"
          color="secondary"
          aria-label="add"
          sx={{ marginTop: 2, marginBottom: 2 }}
          onClick={handlAddModalOpen}
          disabled={disable}
        >
          <Add />
        </Fab>
      </Grid>

      <QuestionnaireModal
        open={openEditModal}
        handleClose={handlEditModalClose}
        handleSave={handlEditModalSave}
        action={"Edit"}
      />
      <QuestionnaireModal
        open={openAddModal}
        handleClose={handlAddModalClose}
        handleSave={handlAddModalSave}
        action={"Add"}
      />
    </>
  );
};
