import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  label: {
    fontFamily: "Cairo",
    fontWeight: 400,
    fontSize: "16px",
    color: "#2A3333",
  },
  patientDataLineOneDropDownStyle: {
    height: "56px",
    borderRadius: "4px",
    background: "#fff",
    bborder: "1px solid #C6C9CA",
  },
  textEnabled: {
    background: "#FFF",
    width: "102%",
    marginTop: "6px",
  },
  textEnabledSelect: {
    background: "#FFF",
    width: "92.5%",
    marginTop: "7px",
  },
}));
