import { FormError } from "app/containers/types";
import { ImportFormState } from "./types";

export function validate(form: ImportFormState): Array<FormError> {
    const errors: Array<FormError> = [];

    if(!form.organizationId) {
        errors.push({
            name: "organizationId",
            error: "Organization  required",
        });
    }

    if(!form.departmentId) {
        errors.push({
            name: "departmentId",
            error: "Department required",
        });
    }

    if(!form.bankId) {
        errors.push({
            name: "bankId",
            error: "Bank required",
        });
    }

    if(!form.file) {
        errors.push({
            name: "file",
            error: "Bank required",
        });
    }

    


  return errors;
}
