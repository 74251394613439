import { Card, Grid, Modal, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import React from "react";
import { useTranslation } from "react-i18next";
import CalendarForm from "../form";
import { useStyles } from "../styles";
interface TProps {
  show: boolean;
  calendarTitle: string;
  startDate: string;
  endDate: string;
  date: string;
  closeModal: () => void;
  handleChange: (e: any) => void;
  checked: boolean;
  description: any;
  eventInfo: any;
  anchorEl?: HTMLElement | null;
}
function MeetingDetailsModal(props: TProps) {
  const styles = useStyles();
  const { t, i18n } = useTranslation();
  return (
    <Modal
      open={props.show}
      onClose={props.closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card
        className={styles.addModal}
        sx={{
          direction: i18n.dir(),
        }}
      >
        <div className={styles.modalContainer}>
          <Grid>
            <span
              style={{
                position: "absolute",
                top: 5,
                left: 5,
                margin: 0,
                padding: 0,
                fontSize: 12,
                color: "#ccc",
              }}
            >
              {" "}
              T{props?.eventInfo?.timeSlotId}{" "}
            </span>
            <Typography align="center" className={styles.headerModal}>
              {t("TIMESLOT.ATTENDANCE_POPUP_TITLE")} (
              {props?.eventInfo?.meetingId})
            </Typography>
          </Grid>
          <div className={styles.bodyContainer}>
            <Grid2 container sx={{ padding: "10px" }} spacing={2}>
              <CalendarForm
                closeModal={props.closeModal}
                checked={props.checked}
                calendarTitle={props.calendarTitle}
                handleChange={props.handleChange}
                startDate={props.startDate}
                date={props.date}
                endDate={props.endDate}
                description={props.description}
                eventInfo={props.eventInfo}
              ></CalendarForm>
            </Grid2>
          </div>
        </div>
      </Card>
    </Modal>
  );
}

export default MeetingDetailsModal;
