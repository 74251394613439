import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { Grade } from "app/containers/Dashboard/types";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";
export default function QuestionnaireInfoCard({ data }: { data: Grade }) {
  const styles = useStyles();

  const { t } = useTranslation();

  return (
    <Card className={styles.mainContainer} style={{}} variant="outlined">
      <CardContent>
        <Typography
          variant="h3"
          align="center"
          gutterBottom
          className={styles.cardTitle}
        >
          {data.gradeName}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Box className={styles.box} style={{}}>
              <Typography
                variant="h5"
                component="div"
                className={styles.cardSubTitle}
              >
                {t("DASHBOARD.T0AVG")}
              </Typography>
              <Typography
                variant="h5"
                component="div"
                className={styles.cardSubTitle}
              >
                <label>{data.t0Avg}</label>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className={styles.box} style={{}}>
              <Typography
                variant="h5"
                component="div"
                className={styles.cardSubTitle}
              >
                {t("DASHBOARD.T1AVG")}
              </Typography>
              <Typography
                variant="h5"
                component="div"
                className={styles.cardSubTitle}
              >
                <label>{data.t1Avg}</label>
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            <Box className={styles.box} style={{}}>
              <Typography
                variant="h4"
                component="div"
                className={styles.cardSubTitle}
              >
                {t("DASHBOARD.RANGES")}
              </Typography>

              <TableContainer
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0)",
                  color: "#fff",
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={styles.cell} style={{ width: 75 }}>
                        {t("DASHBOARD.NAME")}
                      </TableCell>
                      <TableCell className={styles.cell} style={{ width: 75 }}>
                        {t("DASHBOARD.RANGE")}
                      </TableCell>
                      <TableCell className={styles.cell}>
                        {" "}
                        {t("DASHBOARD.GRAPHS.NO_OF_PATIENTS_COMPARISON")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.ranges.map((row, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell className={styles.cell}>
                            <Tooltip
                              title={row.description}
                              arrow
                              placement="left"
                            >
                              <Typography> {row.rangeName} </Typography>
                            </Tooltip>
                          </TableCell>
                          <TableCell className={styles.cell}>
                            {row.from}-{row.to}
                          </TableCell>
                          <TableCell className={styles.cell}>
                            {`${row.score?.totalPatientsT0},${row.score?.totalPatientsT1}`}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
