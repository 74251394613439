import queryString from "query-string";
import { delay, put, takeLatest } from "redux-saga/effects";
import { API_URL } from "utils/constants";
import {
  TRANSLATE,
  getDefaultHeaders,
  removeBlankProperties,
} from "utils/helpers";
import { request } from "utils/request";
import toastService from "utils/toast";
import { actions } from "./slice";

export function* getQualificationList(action) {
  // yield delay(500);

  try {
    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    const response = yield request(
      `${API_URL}/v1/fields/${action.payload.id}`,
      options
    );
    yield put(actions.getQualificationListSuccess(response));
  } catch (e: any) {
    yield put(actions.getQualificationListFailure());
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", {
        error: JSON.stringify(e),
      })
    );
  }
}

export function* getOrganization(action) {
  try {
    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    const response = yield request(
      `${API_URL}/v1/organization/dropdown?limit=200&page=1&type=${
        action.payload ? 1 : 0
      }`,
      options
    );
    yield put(actions.getOrganizationListSuccess(response.items));
  } catch (e: any) {
    yield put(actions.setOrganisationFailure(e));
    const error = JSON.parse(e.message);
    console.error(e);
  }
}

export function* getQuestionnaire(action) {
  try {
    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    const response = yield request(
      `${API_URL}/v1/questionnaires/list?limit=200&page=1&type=${action.payload.type}`,
      options
    );
    yield put(actions.getOuestionnaireListSuccess(response));
  } catch (e: any) {
    yield put(actions.setOrganisationFailure(e));
    const error = JSON.parse(e.message);
    console.error(e);
  }
}

export function* getCity(action) {
  try {
    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    const response = yield request(
      `${API_URL}/v1/city?limit=200&page=1`,
      options
    );
    yield put(actions.getCityListSuccess(response.items));
  } catch (e: any) {
    yield put(actions.setCityFailure(e));
    const error = JSON.parse(e.message);
    console.error(e);
  }
}
export function* getFieldGroups(action) {
  try {
    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    const response = yield request(
      `${API_URL}/v1/field-groups?limit=200&page=1`,
      options
    );
    yield put(actions.getFieldGroupListSuccess(response.data));
  } catch (e: any) {
    yield put(actions.setFieldGroupListFailure(e));
    const error = JSON.parse(e.message);
    console.error(e);
  }
}

export function* getTreatmentTypeList(action) {
  yield delay(500);
  try {
    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    const response = yield request(
      `${API_URL}/v1/treatment?limit=200&page=1${
        action.payload.length > 0 ? "&q=" + action.payload : ""
      }`,
      options
    );
    yield put(actions.getTreatmentListSuccess(response));
  } catch (e: any) {
    yield put(actions.setTreatmentFailure(e));
    const error = JSON.parse(e.message);
    console.error(e);
  }
}
export function* getTreatmentTypeListByBank(action) {
  try {
    if (action.payload.bankId !== undefined) {
      const options = {
        method: "GET",
        headers: getDefaultHeaders(),
      };
      const response = yield request(
        `${API_URL}/v1/treatment/${action.payload.bankId}?limit=200&page=1`,
        options
      );
      yield put(actions.getTreatmentListSuccess(response));
    } else {
      yield put(actions.getTreatmentListSuccess({ items: [] }));
    }
  } catch (e: any) {
    yield put(actions.setTreatmentFailure(e));
    const error = JSON.parse(e.message);
    console.error(e);
  }
}

export function* getInstructor(action) {
  if (action.payload == undefined) {
    return;
  }
  yield delay(500);

  try {
    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    if (action.payload.treatmentId !== undefined) {
      const response = yield request(
        `${API_URL}/v1/users/get-instructor-by-treatment/${action.payload.treatmentId}/${action.payload.bankId}?limit=200&page=1`,
        options
      );
      yield put(actions.getInstructorListSuccess(response));
    }
  } catch (e: any) {
    yield put(actions.setInstructorFailure(e));
    const error = JSON.parse(e.message);
    console.error(e);
  }
}

export function* getActualInstructor(action) {
  if (!action.payload) {
    return;
  }
  yield delay(500);

  try {
    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    if (action.payload.treatmentId !== undefined) {
      const response = yield request(
        `${API_URL}/v1/users/get-instructor-by-treatment/${action.payload.treatmentId}/${action.payload.bankId}?limit=200&page=1`,
        options
      );
      yield put(actions.getActualInstructorListSuccess(response));
    }
  } catch (e: any) {
    yield put(actions.setInstructorFailure(e));
    const error = JSON.parse(e.message);
    console.error(e);
  }
}

export function* getBankDepartment(action) {
  try {
    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    if (action.payload.id !== undefined) {
      const response = yield request(
        `${API_URL}/v1/organization/${action.payload.id}/department/dropdown?limit=200&page=1`,
        options
      );
      yield put(actions.getDepartmentListSuccess(response.items));
    }
  } catch (e: any) {
    yield put(actions.setDepartmentFailure(e));
    const error = JSON.parse(e.message);
    console.error(e);
  }
}

export function* getBanks(action) {
  try {
    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    const query = {
      includeStatus: action.payload.includeStatus,
      limit: 200,
      page: 1,
    };

    const queries = queryString.stringify({
      ...removeBlankProperties(query),
    });

    if (
      action.payload.organizationId !== undefined &&
      action.payload.departmentId !== undefined
    ) {
      const response = yield request(
        `${API_URL}/v1/treatments-bank/${action.payload.organizationId}/${action.payload.departmentId}/banks?${queries}`,
        options
      );
      yield put(actions.getBankListSuccess(response.items));
    }
  } catch (e: any) {
    yield put(actions.setBankFailure(e));
    const error = JSON.parse(e.message);
    console.error(e);
  }
}

export function* getUserGroups(action) {
  try {
    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    const response = yield request(
      `${API_URL}/v1/user-groups/dropdown?limit=200&page=1`,
      options
    );
    yield put(actions.getUserGroupListSuccess(response));
  } catch (e: any) {
    yield put(actions.setUserGroupListFailure(e));
    const error = JSON.parse(e.message);
    console.error(e);
  }
}

export function* globalFieldsSaga() {
  yield takeLatest(actions.getCityList.type, getCity);

  yield takeLatest(actions.getOrganizationList.type, getOrganization);
  yield takeLatest(actions.getQuestionnaireList.type, getQuestionnaire);
  yield takeLatest(actions.getTreatmentList.type, getTreatmentTypeList);
  yield takeLatest(
    actions.getTreatmentListByBank.type,
    getTreatmentTypeListByBank
  );
  yield takeLatest(actions.getUserGroupList.type, getUserGroups);
  yield takeLatest(actions.getInstructorList.type, getInstructor);
  yield takeLatest(actions.getActualInstructorList.type, getActualInstructor);

  yield takeLatest(actions.getFieldGroupList.type, getFieldGroups);
  yield takeLatest(actions.getQualificationList.type, getQualificationList);
  yield takeLatest(actions.getDepartmentList.type, getBankDepartment);
  yield takeLatest(actions.getBankList.type, getBanks);
}
