import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles(() => ({
  downloadLabel: {
    fontFamily: "Cairo",
    fontWeight: "400px",
    fontSize: "16px",
    marginLeft: "10px",
    color: "#3D63E6",
  },
  downloadLabelDisabled: {
    fontFamily: "Cairo",
    fontWeight: "400px",
    fontSize: "16px",
    marginLeft: "10px",
    color: "#ccc",
  },
  link: {
    justifyContent: "center",
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
  },
  gridBtnSmall: {
    fontFamily: "Cairo",
    fontWeight: 700,
    fontSize: "16px",
    height: "56px",
    "& a": {
      textDecoration: "none",
      cursor: "pointer",
    },
    "& label": {
      cursor: "pointer",
    },
  },
}));
