import React from "react";
import { Route, Routes } from "react-router-dom";
import AppDownload from "../containers/Pages/AppDownload";
import TermsAndCondtions from "../containers/Pages/Terms&Conditions";

export function PagesRoutes() {
  return (
    <Routes>
      <Route path="/*">
        <Route path="terms-of-use" element={<TermsAndCondtions />} />
        <Route path="download" element={<AppDownload />} />
      </Route>
    </Routes>
  );
}
