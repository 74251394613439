import { PayloadAction } from "@reduxjs/toolkit";
import { FormError } from "app/containers/types";
import set from "lodash/set";
import sortBy from "lodash/sortBy";
import { createSlice } from "utils/@reduxjs/toolkit";
import {
  GradesListItemType,
  QuestionerForm,
  QuestionnaireListItemType,
  QuestionnaireState,
  RangeListItemType,
  newMultiQuestionDefaultValues,
  newOpenQuestionDefaultValues,
} from "../types";

const initialState = QuestionnaireState;
const removeFormErrors = (form: QuestionerForm) => {
  form.questionnaire.errors = {};
  if (form.currentGrade) {
    form.currentGrade.errors = undefined;
    if (form.currentGrade.currentRange) {
      form.currentGrade.currentRange.errors = undefined;
    }
  }

  form.questionnaire.questions.forEach((q) => {
    q.errors = undefined;
    q.answers.forEach((f) => {
      f.errors = undefined;
    });
  });
  form.questionnaire.grades.forEach((q) => {
    q.errors = undefined;
    q.ranges.forEach((f) => {
      f.errors = undefined;
    });
  });
};
export const organizationsListSlice = createSlice({
  name: "questionnairesState",
  initialState,
  reducers: {
    answersInlineEdit: (
      state,
      action: PayloadAction<{
        index: number;
        isEdit: boolean;
      }>
    ) => {
      const answers =
        state.form.questionnaire.questions[state.form.currentQuestionIndex]
          .answers;
      answers.forEach((m) => (m.isEdit = false));
      const answer = answers[action.payload.index];
      answer.isEdit = action.payload.isEdit;
    },

    editAnswer: (
      state,
      action: PayloadAction<{
        index: number;
        name: string;
        value: string;
      }>
    ) => {
      const answers =
        state.form.questionnaire.questions[state.form.currentQuestionIndex]
          .answers;
      const answer = answers[action.payload.index];
      set(answer, action.payload.name, action.payload.value);
    },
    removeAnswer: (
      state,
      action: PayloadAction<{
        index: number;
      }>
    ) => {
      const answers =
        state.form.questionnaire.questions[state.form.currentQuestionIndex]
          .answers;
      const answer = answers[action.payload.index];
      if (answer.id) {
        answer.isDeleted = true;
      } else if (answer) {
        answers.splice(action.payload.index, 1);
      }
    },
    removeGrade: (
      state,
      action: PayloadAction<{
        index: number;
      }>
    ) => {
      const grade = state.form.questionnaire.grades[action.payload.index];
      if (grade?.id) {
        grade.isDeleted = true;
      } else if (grade) {
        state.form.questionnaire.grades.splice(action.payload.index, 1);
      }
    },

    setAnswerValidationError: (
      state,
      action: PayloadAction<{
        row: number;
        col: string;
        key: string;
        value: string;
        question: number;
      }>
    ) => {
      if (
        typeof state.form.questionnaire.questions[action.payload.question]
          .answers[action.payload.row][action.payload.col] !== "undefined"
      ) {
        state.form.questionnaire.questions[action.payload.question].answers[
          action.payload.row
        ][action.payload.col][action.payload.key] = action.payload.value;
      }
    },
    addGrade: (state, action: PayloadAction<any>) => {
      state.form.questionnaire.grades = [
        ...state.form.questionnaire.grades,
        action.payload.grade,
      ];
    },

    editGrade: (
      state,
      action: PayloadAction<{ grade: GradesListItemType }>
    ) => {
      const grade = state.form.currentGrade;
      if (!grade) {
        return;
      }
      const index = Number(grade.index);
      if (index > -1) {
        state.form.questionnaire.grades[index] = action.payload.grade;
      } else {
        state.form.questionnaire.grades = [
          ...state.form.questionnaire.grades,
          action.payload.grade,
        ];
      }
    },
    editMainGrade: (state, action: PayloadAction<{ index: number }>) => {
      state.form.questionnaire.grades.forEach((g) => {
        g.mainGrade = false;
      });
      state.form.questionnaire.grades[action.payload.index].mainGrade = true;
    },
    addAnswer: (state, action) => {
      const answers =
        state.form.questionnaire.questions[state.form.currentQuestionIndex]
          .answers;
      const newAnswer = { ...newMultiQuestionDefaultValues.answers[0] };
      newAnswer.isEdit = true;
      answers.forEach((a) => (a.isEdit = false));
      answers.push(newAnswer);
    },
    addQuestion: (state, action: PayloadAction<{ type: "mcq" | "open" }>) => {
      if (action.payload.type === "mcq") {
        const question = { ...newMultiQuestionDefaultValues };
        question.questionNumber = String(
          state.form.questionnaire.questions.length + 1
        );
        state.form.questionnaire.questions.push(question);
      }
      if (action.payload.type === "open") {
        const question = { ...newOpenQuestionDefaultValues };
        question.questionNumber = String(
          state.form.questionnaire.questions.length + 1
        );
        state.form.questionnaire.questions.push(question);
      }
      state.form.currentQuestionIndex =
        state.form.questionnaire.questions.length - 1;
    },
    setQuestionData: (state, action) => {
      set(
        state.form.questionnaire.questions[state.form.currentQuestionIndex],
        "question",
        action.payload.value
      );
    },
    resetQuestionnaire: (state) => {
      state.form = initialState.form;
      state.loading = false;
    },
    clearFormErrors: (state) => {
      removeFormErrors(state.form);
    },
    formFailure: (state) => {
      state.loading = false;
    },
    createQuestionnaire: (state, action) => {
      state.loading = true;
    },
    createOpenQuestionnaire: (state, action) => {
      state.loading = true;
    },
    getMultiList: (state) => {
      state.list.multi.loading = true;
      state.list.multi.query.page = 1;
    },
    getMultiNextPage: (state, action: PayloadAction<any>) => {
      state.list.multi.loading = true;
      state.list.multi.query.page = state.list.multi.meta.currentPage + 1;
    },
    getOpenList: (state) => {
      state.list.open.loading = true;
      state.list.open.query.page = 1;
    },
    getOpenNextPage: (state, action: PayloadAction<any>) => {
      state.list.open.loading = true;
      state.list.open.query.page = state.list.open.meta.currentPage + 1;
    },

    getMultiListSuccess: (
      state,
      action: PayloadAction<{ items: []; links: any; meta: any }>
    ) => {
      state.list.multi.loading = false;
      const items = action.payload.items;
      state.list.multi.meta = action.payload.meta;
      state.list.multi.links = action.payload.links;
      state.list.multi.items =
        action.payload.meta.currentPage === 1
          ? items
          : [...state.list.multi.items, ...items];
    },
    getMultiListFailure: (state) => {
      state.list.multi.loading = false;
    },
    getDataById: (state, action: PayloadAction<{ id: any }>) => {
      state.loading = true;
      state.form = initialState.form;
    },
    showError: (state, action: PayloadAction<{ message: any }>) => {
      state.loading = true;
    },
    getQuestionnaireDataSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.form.questionnaire = action.payload;
      state.form.questionnaire.questions = sortBy(
        state.form.questionnaire.questions,
        ["questionNumber"],
        ["asc"]
      );
    },
    getQuestionnaireDataFailure: (state) => {
      state.loading = false;
    },
    delete: (state, payload) => {
      state.error = "";
      state.loading = true;
    },
    deleteSuccess: (state) => {
      state.loading = false;
      state.error = "";
    },
    deleteFailed: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    getQuestionnaireOpenListSuccess: (
      state,
      action: PayloadAction<{ items: []; links: any; meta: any }>
    ) => {
      state.list.open.loading = false;
      const items = action.payload.items;
      state.list.open.meta = action.payload.meta;
      state.list.open.links = action.payload.links;
      state.list.open.items =
        action.payload.meta.currentPage === 1
          ? items
          : [...state.list.open.items, ...items];
    },
    getQuestionnaireOpenListFailure: (state) => {
      state.list.open.loading = false;
    },
    getNextPage: (state, action: PayloadAction<any>) => {},
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    updateOpen: (state, action: PayloadAction<any>) => {
      state.error = "";
      state.loading = true;
    },
    updateQuestionnaire: (state, action: PayloadAction<any>) => {
      state.error = "";
      state.loading = true;
    },
    updateSuccess(state, action: PayloadAction<QuestionnaireListItemType>) {
      state.loading = false;
    },
    updateFailed: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    resetNewUserForm(state) {
      state.error = "";
      state.form = initialState.form;
    },
    setFormErrors(
      state,
      action: PayloadAction<{
        key?: string;
        errors: FormError[];
      }>
    ) {
      removeFormErrors(state.form);
      action.payload.errors.forEach((error) => {
        const key = action.payload.key
          ? `${action.payload.key}${error.name}`
          : `${error.name}`;
        set(state.form, `${key}`, error.error);
      });
    },
    updateFormErrors(
      state,
      action: PayloadAction<{
        name: string;
        error: string;
      }>
    ) {
      set(state.form, `${action.payload.name}`, action.payload.error);
    },
    getNextPageItems: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    setQuery: (state, action: PayloadAction<{ name: string; value: any }>) => {
      const { name, value } = action.payload;
      set(state.query, name, value);
      set(state.list.multi.query, name, value);
      set(state.list.open.query, name, value);
    },
    setFormValue: (
      state,
      action: PayloadAction<{ name: string; value: any }>
    ) => {
      const { name, value } = action.payload;
      set(state.form, name, value);
    },
    setCurrentQuestionIndex: (
      state,
      action: PayloadAction<{ index: number }>
    ) => {
      state.form.currentQuestionIndex = action.payload.index;
    },
    setCurrentGrade: (
      state,
      action: PayloadAction<{ grade?: GradesListItemType; index?: number }>
    ) => {
      if (action.payload.grade) {
        state.form.currentGrade = {
          ...action.payload.grade,
          index: action.payload.index,
          errors: undefined,
        };
      } else {
        state.form.currentGrade = action.payload.grade;
      }
    },
    setCurrentGradeValue: (
      state,
      action: PayloadAction<{ name: string; value: string | boolean }>
    ) => {
      set(state.form.currentGrade, action.payload.name, action.payload.value);
    },
    setCurrentRange: (
      state,
      action: PayloadAction<{ range?: RangeListItemType }>
    ) => {
      if (state.form.currentGrade) {
        state.form.currentGrade.currentRange = action.payload.range;
      }
    },
    editRange: (state, action: PayloadAction<{ range: RangeListItemType }>) => {
      const grade = state.form.currentGrade;
      if (!grade) {
        return;
      }
      const index = Number(grade.currentRange?.index);
      if (index > -1) {
        grade.ranges[index] = action.payload.range;
      } else {
        grade.ranges = [...grade.ranges, action.payload.range];
      }
    },
  },
});

export const { reducer, actions, name: sliceKey } = organizationsListSlice;
