import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Grid, MenuItem, Select, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectTreatment } from "store/global/fields/selector";
import { actions } from "store/global/fields/slice";
import { useStyles } from "../styles";
interface TProps {
  value: number | "All";
  bankId: number;
  changeValue: (value: number) => void;
  disabled?: boolean;
  showAll: boolean;
  fetchAll: boolean;
  showBlank?: boolean;
  xs?: number;
}
const TreatmentTypeFiled = (props: TProps) => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const List = useSelector(selectTreatment);

  const { t } = useTranslation();

  useEffect(() => {
    if (props.showBlank) {
      dispatch(
        actions.getTreatmentListByBank({
          bankId: props.bankId,
          all: props.fetchAll,
        })
      );
    } else if (props.showAll) {
      dispatch(
        actions.getTreatmentListByBank({
          bankId: props.bankId,
          all: props.fetchAll,
        })
      );
    } else if (props.bankId > 0) {
      dispatch(
        actions.getTreatmentListByBank({
          bankId: props.bankId,
          all: props.fetchAll,
        })
      );
    } else {
      dispatch(actions.getTreatmentListSuccess({ items: [] }));
    }

    return () => {};
  }, [props.bankId, props.fetchAll]);

  const handleChange = (event) => {
    props.changeValue(event.target.value);
  };

  const direction = useTheme().direction;
  return (
    <Grid item xs={props.xs ? props.xs : 2}>
      <label className={styles.label}>
        {t("TIMESLOT.TREATMENT_TYPE")}
        {Number(props.value) > 0 ? (
          <Link
            to={`/treatment-types/${props.value}/view`}
            target="_blank"
            style={{ textDecoration: "none" }}
            rel="noopener noreferrer"
          >
            <OpenInNewIcon fontSize="small" />
          </Link>
        ) : null}
      </label>
      <Select
        margin="dense"
        fullWidth
        disabled={props.disabled}
        variant="outlined"
        required
        autoComplete={"off"}
        value={props.value}
        className={styles.textEnabled}
        onClick={() => setDropDownOpen(props.disabled ? false : !dropDownOpen)}
        onChange={handleChange}
        open={dropDownOpen}
        IconComponent={KeyboardArrowDownIcon}
      >
        {props.showAll ? (
          <MenuItem
            sx={{
              direction,
            }}
            value={"0"}
          >
            {t("COMMON.ALL")}
          </MenuItem>
        ) : props.showBlank ? (
          <MenuItem
            sx={{
              direction,
            }}
            value={"0"}
          >
            {t("COMMON.PLEASE_SELECT")}
          </MenuItem>
        ) : null}
        {List.items.map((item) => (
          <MenuItem
            key={item.id}
            sx={{
              direction,
            }}
            onClick={() =>
              setDropDownOpen(props.disabled ? false : !dropDownOpen)
            }
            value={item.id}
          >
            {item.treatmentName}
          </MenuItem>
        ))}
      </Select>
    </Grid>
  );
};
export default TreatmentTypeFiled;
