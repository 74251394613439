import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  tableDiv: {
    border: "1px",
  },
  link: {
    textDecoration: "none",
    color: "#3D63E6",
    cursor: "pointer",
  },
  dataTableDiv: {
    maxHeight: "calc(100vh - 290px)",
    //maxWidth: "calc(159vh)",
    overflowY: "auto",
    overflowX: "auto",
    marginTop: "0px",
    border: "1px solid #C6C9CA",
    background: "#F5F8FF",
  },
}));
