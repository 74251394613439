import i18next from "i18next";
import { FormError } from "../types";
import { QuestionListItemType, QuestionerForm } from "./types";

export const validateQuestions = (
  questions: QuestionListItemType[]
): FormError[] => {
  let errors: FormError[] = [];
  questions.forEach((question, index) => {
    if (!question.question) {
      errors.push({
        name: `questions.[${index}].errors.question`,
        error: i18next.t("QUESTIONNAIRES.FORM.ERRORS.QUESTION_REQUIRED"),
      });
    }
  });
  return errors;
};

export const validate = (form: QuestionerForm): FormError[] => {
  let errors: FormError[] = [];
  if (!form.questionnaire.name) {
    errors.push({
      name: "questionnaire.errors.name",
      error: i18next.t("QUESTIONNAIRES.FORM.ERRORS.QUESTIONER_NAME_REQUIRED"),
    });
  }
  validateQuestions(form.questionnaire.questions).forEach((e, index) => {
    errors.push({
      name: `questionnaire.${e.name}`,
      error: e.error,
    });
  });

  return errors;
};
