import { Box, Button, CircularProgress } from "@mui/material";

import React from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";
type Props = {
  onCancel?(): void;
  onSave?(): void;
  loading?: boolean;
  disabled?: boolean;
  disableSave?: boolean;
};
export const ActionButtons = ({
  onSave,
  onCancel,
  loading,
  disabled,
  disableSave,
}: Props) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const isDisabled = loading || disabled;
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "20px",
      }}
    >
      {onCancel ? (
        <Button
          className={styles.cancelBtn}
          variant="outlined"
          onClick={onCancel}
          disabled={isDisabled}
        >
          {t("BUTTONS.CANCEL")}
        </Button>
      ) : null}
      <Button
        disabled={isDisabled || disableSave}
        className={styles.saveBtn}
        variant="contained"
        onClick={onSave}
      >
        {t("BUTTONS.SAVE")}
        {loading ? (
          <CircularProgress size={20} className={styles.progress} />
        ) : null}
      </Button>
    </Box>
  );
};
