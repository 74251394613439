import { Grid } from "@mui/material";
import ActualInstructorField from "app/components/FieldComponent/ActualInstructor";
import BankFiled from "app/components/FieldComponent/Bank";
import DepartmentFiled from "app/components/FieldComponent/Department";
import InstructorFiled from "app/components/FieldComponent/Instructor";
import OrganizationField from "app/components/FieldComponent/Organization";
import TreatmentTypeFiled from "app/components/FieldComponent/TreatmentType";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectQuery } from "../ListView/redux/selector";
import { actions } from "../ListView/redux/slice";

const Filters = () => {
  const dispatch = useDispatch();

  const {
    actualInstructorId,
    bankId,
    departmentId,
    instructorId,
    organizationId,
    treatmentId,
    q,
    orderDirection,
    orderField,
  } = useSelector(selectQuery);
  useEffect(() => {
    dispatch(actions.getList());
  }, [
    q,
    orderDirection,
    orderField,
    departmentId,
    organizationId,
    bankId,
    treatmentId,
    instructorId,
    actualInstructorId,
    dispatch,
  ]);
  const setPageQuery = (name: string, value: any) => {
    dispatch(actions.setQuery({ name, value }));
  };
  const { t } = useTranslation();
  return (
    <Grid container spacing={2} md={12} sx={{ paddingBottom: "10px" }}>
      <OrganizationField
        value={organizationId}
        changeValue={(value) => {
          setPageQuery("organizationId", value);
        }}
        showAll={true}
        activeRequired={false}
      ></OrganizationField>
      <DepartmentFiled
        value={departmentId}
        changeValue={(value) => {
          setPageQuery("departmentId", value);
        }}
        orgId={organizationId}
        showAll
        noReset
      ></DepartmentFiled>
      <BankFiled
        value={bankId}
        organizationId={organizationId}
        departmentId={departmentId}
        changeValue={(value) => {
          setPageQuery("bankId", value);
        }}
        showAll
        noReset
      ></BankFiled>
      <TreatmentTypeFiled
        value={treatmentId}
        bankId={bankId}
        changeValue={(value) => {
          setPageQuery("treatmentId", value);
        }}
        showAll={true}
        fetchAll={true}
      ></TreatmentTypeFiled>
      <InstructorFiled
        label={t("TIMESLOT.INSTRUCTOR_NAME")}
        value={instructorId}
        treatmentId={treatmentId}
        bankId={bankId}
        changeValue={(value: number) => {
          setPageQuery("instructorId", value);
        }}
        showAll={true}
      ></InstructorFiled>
      <ActualInstructorField
        value={actualInstructorId}
        treatmentId={treatmentId}
        bankId={bankId}
        changeValue={(value: number) => {
          setPageQuery("actualInstructorId", value);
        }}
        isEdit={false}
        instructorId={instructorId}
        showAll={true}
      ></ActualInstructorField>
    </Grid>
  );
};
export default Filters;
