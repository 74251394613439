import { BorderColor, Height, Margin, Repeat } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { padding } from "@mui/system";

export const useStyles = makeStyles(() => ({
  heading:{
    fontFamily: "Cairo",
    fontWeight: 700,
    fontSize: "16px",
    color: "#2A3333",
  },
  colorPrimary: {
    backgroundColor: '#FFFFFF',
  },
  barColorPrimaryOne: {
    backgroundColor: '#FF52A7',
    borderRadius: 20
  },
  barColorPrimaryTwo: {
    backgroundColor: '#E521EF',
    borderRadius: 20
  },
  barColorPrimaryThree: {
    backgroundColor: '#9821EF',
    borderRadius: 20
  },
  headingProgressBar:{
    fontFamily: "Cairo",
    fontWeight: 600,
    fontSize: "12px",
    color: "#2A3333",
    marginTop: "30px"
  },
  headingProgressBarOne:{
    fontFamily: "Cairo",
    fontWeight: 600,
    fontSize: "12px",
    color: "#2A3333",
    marginTop: "10px"
  },
  questionnaireDetailList:{
    border: "1px solid #C6C9CA",
    borderBottomWidth: "0px",
    borderRadius: "3px",
  },
}));
