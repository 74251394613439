import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  parent: {
    backgroundColor: "#F5F8FF",
  },
  bodyContainer: {
    padding: "20px 20px 0px 300px",
    marginTop: "60px",
    minHeight: "100%",
  },
  chipBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "5px",
    border: "1px solid #C6C9CA",
    height: "40px",
    padding: "10px",
    background: "#FFF",
  },
  chipLabel: {
    fontFamily: "Cairo",
    fontWeight: "600px",
    fontSize: "16px",
    color: "#012497",
    marginTop: "15px",
  },
  numLabel: {
    fontFamily: "Cairo",
    fontWeight: "400px",
    fontSize: "14px",
    marginLeft: "16px",
    color: "#6C7A7A",
    marginTop: "16px",
  },
  bottomContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "16px",
    paddingBottom: "15px",
  },
  button: {
    width: "160px",
    height: "50px",
    padding: "10px 16px",
    background: "#3D63E6",
    borderRadius: "4px",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "30px",
    color: "#FFF",
    marginBottom: "40px",
  },
  cancelBtn: {
    width: "160px",
    padding: "10px 16px",
    fontWeight: 700,
    fontSize: "16px",
    color: "#3D63E6",
    borderColor: "#3D63E6",
    "&:hover": {
      borderColor: "#3D63E6",
    },
    marginBottom: "40px",
  },
}));
