export interface ResponseError {
  message: string;
}
export type RequestError = ResponseError;

export type QuestionnaireListItemType = {
  dateCreated: string;
  dateEdited: string;
  questionnaireName: string | null;
  noOfQuestion: string | null;
  usageLocation: string | null;
  organizationName: string | null;
};

export type AnswersListItemTypeError = {
  content?: string;
  weight?: string;
};

export type AnswersListItemType = {
  id?: number;
  content: string;
  weight: 0;
  type?: string | null;
  isEdit?: boolean;
  isDeleted?: boolean;
  errors?: AnswersListItemTypeError;
};

export type QuestionListItemTypeError = {
  question: string;
  answersGeneral: string;
};

export type QuestionListItemType = {
  questionNumber: string;
  question: string;
  answers: Array<AnswersListItemType>;
  errors?: QuestionListItemTypeError;
};

export type GradesListItemTypeError = {
  gradeName: string;
  questionNumbers: string;
  mainGrade: string;
  gradeType: string;
  ranges: string;
  gradeGeneral: string[];
};

export type GradesListItemType = {
  id?: number;
  isDeleted?: boolean;
  index?: number;
  gradeName: string;
  questionNumbers: string;
  gradeType: string;
  mainGrade: Boolean;
  ranges: Array<RangeListItemType>;
  isEdit?: boolean;
  currentRange?: RangeListItemType;
  errors?: GradesListItemTypeError;
};

export type RangeListItemTypeError = {
  to?: string;
  from?: string;
  rangeName?: string;
  description?: string;
};

export type RangeListItemType = {
  index?: number;
  to?: number;
  from?: number;
  rangeName: string;
  description: string;
  errors?: RangeListItemTypeError;
};
export type QuestionerFormError = {
  name?: string;
  gradesGeneral?: string;
};
export type QuestionerForm = {
  currentQuestionIndex: number;
  currentGrade?: GradesListItemType;
  questionnaire: {
    name: string;
    type?: "mcq" | "open";
    totalQuestions: number;
    id?: number;
    questions: Array<QuestionListItemType>;
    grades: Array<GradesListItemType>;
    errors: QuestionerFormError;
  };
};

export type questionnaireListStateType = {
  query: {
    q: string;
  };
  form: QuestionerForm;
  list: {
    open: {
      query: {
        limit: number;
        page: number;
        q: string;
        orderField: string;
        orderDirection: string;
      };
      items: Array<QuestionnaireListItemType>;
      meta: {
        totalItems: number;
        itemCount: number;
        itemsPerPage: number;
        totalPages: number;
        currentPage: number;
      };
      links: {
        first: string;
        last: string;
        previous: string;
        next: string;
      };
      loading: boolean;
    };
    multi: {
      query: {
        limit: number;
        page: number;
        q: string;
        orderField: string;
        orderDirection: string;
      };
      items: Array<QuestionnaireListItemType>;
      meta: {
        totalItems: number;
        itemCount: number;
        itemsPerPage: number;
        totalPages: number;
        currentPage: number;
      };
      links: {
        first: string;
        last: string;
        previous: string;
        next: string;
      };
      loading: boolean;
    };
  };
  loading: boolean;
  error: string;
};

export const newMultiQuestionDefaultValues = {
  questionNumber: "1",
  question: "",
  answers: [
    {
      content: "",
      weight: 0,
      errors: {},
    },
  ] as AnswersListItemType[],
} as QuestionListItemType;

export const newOpenQuestionDefaultValues = {
  questionNumber: "1",
  question: "",
  answers: [],
} as QuestionListItemType;

export const newGradeDefaultValues = {
  gradeName: "",
  questionNumbers: "",
  gradeType: "",
  mainGrade: false,
  ranges: [],
};

export const newRangeDefaultValues = {
  to: undefined,
  from: undefined,
  rangeName: "",
  description: "",
};

export const QuestionnaireState: questionnaireListStateType = {
  query: {
    q: "",
  },
  list: {
    open: {
      query: {
        limit: 10,
        page: 1,
        q: "",
        orderField: "",
        orderDirection: "",
      },
      items: [],
      meta: {
        totalItems: 0,
        itemCount: 0,
        itemsPerPage: 100,
        totalPages: 1,
        currentPage: 1,
      },
      links: {
        first: "",
        previous: "",
        next: "",
        last: "",
      },
      loading: false,
    },
    multi: {
      query: {
        limit: 10,
        page: 1,
        q: "",
        orderField: "",
        orderDirection: "",
      },
      items: [],
      meta: {
        totalItems: 0,
        itemCount: 0,
        itemsPerPage: 100,
        totalPages: 1,
        currentPage: 1,
      },
      links: {
        first: "",
        previous: "",
        next: "",
        last: "",
      },
      loading: false,
    },
  },

  loading: false,
  form: {
    currentQuestionIndex: 0,
    currentGrade: undefined,
    questionnaire: {
      name: "",
      type: undefined,
      totalQuestions: 0,
      questions: [],
      grades: [],
      errors: {},
    },
  },
  error: "",
};

export type ContainerState = questionnaireListStateType;
