import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  container: {
    background: "#F5F8FF",
    minHeight: "100%",
    minWidth: "100%",
    bottom: "auto",
  },
  root: {
    padding: "20px 20px 0px 300px",
    marginTop: "40px",
  },
  gridBtn: {
    background: "#3D63E6",
    color: "#ffff",
    "&:hover": {
      background: "#3D63E6",
    },
    width: "160px",
    height: "50px",
  },
  input: {
    marginBottom: "20px",
    background: "#FFFFFF",
  },
  label: {
    color: "#000",
    fontSize: "16px",
    marginBottom: "10px",
  },
}));
