import * as React from "react";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Styles } from "./styles";

import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { selectFeaturePermissions } from "store/global/authPermission/selector";
import { PermissionFeature } from "store/global/authPermission/types";

const drawerWidth = 270;

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const DynamicIcon = ({ icon, color }) => {
  switch (icon) {
    case icon:
      return (
        <span className="material-icons" style={{ color: color }}>
          {" "}
          {icon}{" "}
        </span>
      );

    default:
      return (
        <span className="material-icons" style={{ color: color }}>
          {" "}
          dashboard{" "}
        </span>
      );
  }
};

const Component = () => {
  const styles = Styles();
  const location = useLocation();
  const navigate = useNavigate();
  const [urlParam, setUrlParam] = React.useState("");
  const { t } = useTranslation();
  const theme = useTheme();

  React.useEffect(() => {
    setUrlParam(location.pathname.split("/")[1]);
  }, []);

  const featurePermissions = useSelector(selectFeaturePermissions);

  // const { hasNoAccessOrganization as hasNoAccess } =
  //   featurePermissions[PermissionFeature.ORGANIZATIONS_LIST];
  // const { hasNoAccessAssignedTreatment as hasNoAccess } =
  //   featurePermissions[PermissionFeature.ASSIGNED_TREATNEMTS_A];
  // const { hasNoAccessPateints as hasNoAccess } =
  //   featurePermissions[PermissionFeature.PATIENTS_LIST];
  // const { hasNoAccessQuestionnaireResult as hasNoAccess } =
  //   featurePermissions[PermissionFeature.QUESTIONNAIRES_RESULTS];
  //const { hasNoAccess } = featurePermissions[PermissionFeature.QUESTIONNAIRES];

  const drawerItems = [
    {
      name: "DASHBOARD",
      icon: "dashboard",
      route: "dashboard",
      hasAccess: () => {
        if (featurePermissions[PermissionFeature.DASHBOARD].hasNoAccess) {
          return false;
        }
        return true;
      },
    },
    {
      name: "ORGANIZATIONS",
      icon: "account_tree",
      route: "organizations",
      hasAccess: () => {
        if (
          featurePermissions[PermissionFeature.ORGANIZATIONS_LIST].hasNoAccess
        ) {
          return false;
        }
        return true;
      },
    },
    {
      name: "TREATMENT_BANKS",
      icon: "spa",
      route: "treatment-banks",
      hasAccess: () => {
        if (
          featurePermissions[PermissionFeature.TREATMENT_BANK_LIST].hasNoAccess
        ) {
          return false;
        }
        return true;
      },
    },
    {
      name: "USERS",
      icon: "person",
      route: "users",
      hasAccess: () => {
        if (featurePermissions[PermissionFeature.USER_LIST].hasNoAccess) {
          return false;
        }
        return true;
      },
    },

    {
      name: "USER_GROUPS",
      icon: "people",
      route: "user-groups",
      hasAccess: () => {
        if (featurePermissions[PermissionFeature.USER_GROUPS].hasNoAccess) {
          return false;
        }
        return true;
      },
    },
    {
      name: "PATIENTS",
      icon: "personal_injury",
      route: "patients",
      hasAccess: () => {
        if (featurePermissions[PermissionFeature.PATIENTS_LIST].hasNoAccess) {
          return false;
        }
        return true;
      },
    },
    {
      name: "ASSIGNED_TREATMENTS",
      icon: "medical_services",
      route: "assigned-treatment",
      hasAccess: () => {
        if (
          featurePermissions[PermissionFeature.ASSIGNED_TREATNEMTS_A]
            .hasNoAccess &&
          featurePermissions[PermissionFeature.ASSIGNED_TREATNEMTS_B]
            .hasNoAccess
        ) {
          return false;
        }
        return true;
      },
    },
    {
      name: "TREATMENT_TYPES",
      icon: "medication",
      route: "treatment-types",
      hasAccess: () => {
        if (featurePermissions[PermissionFeature.TREATMENT_TYPES].hasNoAccess) {
          return false;
        }
        return true;
      },
    },
    {
      name: "DAILY_ACTIVITY",
      icon: "self_improvement",
      route: "daily-activity",
      hasAccess: () => {
        if (featurePermissions[PermissionFeature.DAILY_ACTIVITY].hasNoAccess) {
          return false;
        }
        return true;
      },
    },
    {
      name: "PUSH_NOTIFICATION",
      icon: "notifications",
      route: "push-notification",
      hasAccess: () => {
        if (
          featurePermissions[PermissionFeature.PUSH_NOTIFICATION].hasNoAccess
        ) {
          return false;
        }
        return true;
      },
    },
    {
      name: "TIME_SLOTS",
      icon: "timelapse",
      route: "time-slots",
      hasAccess: () => {
        if (featurePermissions[PermissionFeature.TIMESLOTS].hasNoAccess) {
          return false;
        }
        return true;
      },
    },
    {
      name: "QUESTIONNAIRES",
      icon: "ballot",
      route: "questionnaires",
      hasAccess: () => {
        if (featurePermissions[PermissionFeature.QUESTIONNAIRES].hasNoAccess) {
          return false;
        }
        return true;
      },
    },
    {
      name: "QUESTIONNAIRE_RESULT",
      icon: "assignment_turned_in",
      route: "questionnaires-result",
      hasAccess: () => {
        if (
          featurePermissions[PermissionFeature.QUESTIONNAIRES_RESULTS]
            .hasNoAccess
        ) {
          return false;
        }
        return true;
      },
    },
  ];

  const drawer = (
    <div>
      <div className={styles.logoImage}>
        <img
          style={{ width: "180px" }}
          src={require("app/assets/inHealLogo.png")}
          alt="logo"
        />
      </div>
      <List>
        {drawerItems.map((item, index) => {
          const name = item.name;
          const route = item.route;
          const hasAccess = item.hasAccess();
          if (!hasAccess) {
            return null;
          }
          return (
            <Link
              key={index}
              to={`/${route}?ref=nav`}
              className={styles.drawerLink}
            >
              <ListItemButton
                className={urlParam === route ? styles.drawerListItems : ""}
              >
                <ListItemIcon>
                  <DynamicIcon
                    icon={item.icon}
                    color={urlParam === route ? "#012397" : "#6E758D"}
                  />
                </ListItemIcon>
                <ListItemText
                  className={
                    urlParam === route ? styles.drawerListItemText : ""
                  }
                  disableTypography
                >
                  {" "}
                  {t(`SIDEBAR.${name}`)}{" "}
                </ListItemText>
              </ListItemButton>
            </Link>
          );
        })}
      </List>
    </div>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          PaperProps={{ className: "no-visible-bar" }}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              boxShadow: "0 0 5px rgba(0,0,0,0.20)",
            },
          }}
          variant="permanent"
          anchor={theme?.direction === "rtl" ? "right" : "left"}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
};

export const CustomDrawer = React.memo(Component);
