import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import { TabPanel } from "app/components/TabPanel";
import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import FamilyHistoryTab from "./components/FamilyHistoryTab";
import { useStyles } from "./styles";

interface PatientDetailsProps {
  view: boolean;
  disable: boolean;
}

const Intake = ({ view, disable }: PatientDetailsProps) => {
  const styles = useStyles();
  const params = useLocation();
  const [familyHistoryTabValue, setFamilyHistoryTabValue] = useState(0);
  const { t } = useTranslation();

  const handleFamilyHistoryTabChange = (event, familyHistoryTabValue) => {
    setFamilyHistoryTabValue(familyHistoryTabValue);
  };

  return (
    <Grid
      container
      spacing={3}
      md={12}
      sx={{
        width: "100%",
        marginTop: "20px",
      }}
    >
      <Typography className={styles.intakeHeading}>
        {t("PATIENTS.INTAKE")}
      </Typography>

      <Grid
        item
        container
        spacing={2}
        md={12}
        sx={{ padding: "20px 0px 0px 15px" }}
      >
        <Box sx={{ width: "100%" }}>
          <Tabs
            value={familyHistoryTabValue}
            onChange={handleFamilyHistoryTabChange}
            aria-label="Family History Table"
            textColor="secondary"
            TabIndicatorProps={{ style: { background: "#012497" } }}
          >
            <Tab
              label={<span>{t("PATIENTS.INITIAL_MEETING")}</span>}
              style={{
                fontWeight: familyHistoryTabValue == 0 ? "bold" : "500",
                color: familyHistoryTabValue == 0 ? "#012497" : "#2A3333",
                fontSize: "16px",
              }}
            />
            <Tab
              label={<span>{t("PATIENTS.LAST_MEETING")}</span>}
              style={{
                fontWeight: familyHistoryTabValue == 1 ? "bold" : "500",
                color: familyHistoryTabValue == 1 ? "#012497" : "#2A3333",
                fontSize: "16px",
              }}
            />
          </Tabs>

          <TabPanel value={familyHistoryTabValue} index={0}>
            <FamilyHistoryTab view={view} disable={disable} type="initial" />
          </TabPanel>
          <TabPanel value={familyHistoryTabValue} index={1}>
            <FamilyHistoryTab view={view} disable={disable} type="final" />
          </TabPanel>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Intake;
