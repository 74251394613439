import { Grade } from "app/containers/Dashboard/types";
import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";

export const defaultData = [
  [
    "Range Name",
    "First Grade (Before Program)",
    "Second Grade (After Program)",
    "Avg",
    "Trend",
  ],
];

export const defaultOptions = {
  animation: {
    duration: 2000,
  },
  vAxis: {
    title: i18next.t("DASHBOARD.GRAPHS.V_AXIS"),
    baseline: 0,
    gridlines: { color: "rgba(200,200,200, 0.1)" },
    minValue: 0,
    viewWindow: { min: 0 },
  },
  hAxis: {
    title: i18next.t("DASHBOARD.GRAPHS.H_AXIS"),
    baseline: 0,
    minValue: 0,
  },
  seriesType: "bars",
  series: {
    2: { type: "line", color: "#464646", lineDashStyle: [5, 1, 5] },
    3: { type: "line", color: "#464646" },
  },
  curveType: "function",
  legend: {
    maxLines: 4,
    alignment: "end",
    position: "none",
  },
  backgroundColor: "transparent",
  bar: {
    groupWidth: "23px",
  },
  colors: ["#54C9C4", "#686CFF", "#464646", "#464646"],
};

type Props = {
  grade: Grade;
};
export default function ComboChart(props: Props) {
  const [data, setData] = useState(defaultData);

  useEffect(() => {
    let d: any = [...defaultData];

    props.grade.ranges.forEach((range) => {
      d.push([
        range.rangeName,
        Number(range.score?.totalPatientsT0),
        Number(range.score?.totalPatientsT1),
        Number(range.score?.totalPatientsT0),
        Number(range.score?.totalPatientsT1),
      ]);
    });
    setData(d);
  }, [props.grade]);
  return (
    <Chart
      chartType="ComboChart"
      width="100%"
      height="400px"
      data={data}
      options={defaultOptions}
    />
  );
}
