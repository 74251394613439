import { Delete } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { BottomActions } from "app/components/BottomActions";
import BreadCrumb from "app/components/BreadCrumb";
import { ActionButtons } from "app/components/Button/ActionButtons";
import ConfirmDialog from "app/components/ConfirmDialog";
import { CustomDrawer } from "app/components/Drawer";
import Topbar from "app/containers/Dashboard/components/topbar";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { selectFeaturePermissions } from "store/global/authPermission/selector";
import { PermissionFeature } from "store/global/authPermission/types";
import { translatedBreadCrumb } from "styles/theme/utils";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import QuestionnaireList from "./components/QuestionnaireList";
import TreatmentList from "./components/TreatmentList";
import { useUserSaga } from "./redux/saga";
import {
  selectEditTreatment,
  selectList,
  selectLoading,
} from "./redux/selector";
import { actions, reducer, sliceKey } from "./redux/slice";
import { useStyles } from "./styles";
import { onValidate } from "./validator";

type Props = {
  mode: "new" | "edit" | "view";
};
const AddTreatment = ({ mode }: Props) => {
  useInjectReducer({ key: sliceKey, reducer });
  useInjectSaga({ key: sliceKey, saga: useUserSaga });

  const { t, i18n } = useTranslation();
  const styles = useStyles();
  const navigate = useNavigate();
  const { id } = useParams();
  const [value, setValue] = useState(0);
  const [openDelete, setDelete] = useState(false);

  const { treatment, items } = useSelector(selectList);
  const isLoading = useSelector(selectLoading);

  const disable = mode === "view" || isLoading;

  const form = useSelector(selectEditTreatment);

  const disabledOnAssociation =
    form.treatmentBanksCount.value > 0 || form.timeSlotsCount.value > 0;
  const dispatch = useDispatch();

  const handleTabChange = (event, value) => {
    setValue(value);
  };
  const handleDelete = () => {
    setDelete(true);
  };

  const handleEditButton = () => {
    navigate(`/treatment-types/${id}/edit`);
  };
  const handleCancelBtn = () => {
    navigate("/treatment-types");
  };

  const handleSaveBtn = () => {
    const errors = onValidate(form);
    if (errors?.length > 0) {
      dispatch(actions.setFormErrors({ key: "treatment", errors }));
      window.scrollTo(0, 0);
      return;
    }
    if (mode === "new") {
      dispatch(
        actions.create({
          treatmentContents: treatment,
          treatmentQuestionaire: items,
          navigate,
        })
      );
    } else if (id) {
      dispatch(
        actions.update({
          id: id,
          toUpdate: {
            treatmentContents: treatment,
            treatmentQuestionaire: items,
          },
          navigate,
        })
      );
    }
  };

  const featurePermissions = useSelector(selectFeaturePermissions);
  const { hasFullAccess, hasReadAccess, hasNoAccess } =
    featurePermissions[PermissionFeature.TREATMENT_TYPES];

  const handleBackBtn = () => {
    navigate("/treatment-types");
  };

  useEffect(() => {
    if (mode !== "new") {
      if (id) {
        dispatch(actions.getTretament(id));

        dispatch(
          actions.getTreatmentContentList({
            treatmentId: id,
          })
        );
        dispatch(
          actions.getTreatmentQuestionnaireList({
            treatmentId: id,
          })
        );
      }
    }
  }, [id]);

  const handleDeleteClose = (eventType) => {
    if (eventType === "delete") {
      dispatch(actions.delete({ id: id, navigate }));
    }
    setDelete(false);
  };

  function onChange(e) {
    const { name } = e.target;
    let value = e.target.value;
    dispatch(actions.updateTreatmentForm({ key: name, value: value }));
  }

  useEffect(() => {
    return () => {
      dispatch(actions.resetTreatmentForm());
    };
  }, []);

  return (
    <div className={styles.mainContainer}>
      <CustomDrawer />
      <Grid item xs={12}>
        <Topbar />
        <div className={styles.bodyContainer}>
          <Grid container>
            <BreadCrumb
              onBackClick={handleBackBtn}
              title={t("TREATMENT_TYPES.TREATMENT_TYPES")}
              subTitle={t(`TREATMENT_TYPES.${translatedBreadCrumb(mode)}`)}
            />

            <Grid
              sx={{ paddingLeft: "12px", display: "flex", alignItems: "end" }}
              container
              item
              xs={2.5}
              direction="column"
              marginTop="10px"
            >
              {mode !== "new" && (
                <Button
                  className={styles.deletePatientBtn}
                  variant="outlined"
                  disabled={disable || disabledOnAssociation}
                  onClick={handleDelete}
                >
                  <Delete
                    sx={{
                      color:
                        disabledOnAssociation || disable
                          ? "#C6C9CA"
                          : "#FF4839",
                      marginRight: "10px",
                    }}
                  />{" "}
                  {t("TREATMENT_TYPES.DELETE_TREATMENT_TYPES")}
                </Button>
              )}
            </Grid>
          </Grid>
          <Grid sx={{ padding: "20px 0px 0px 0px" }}>
            <Grid container spacing={2} md={12}>
              <Grid item xs={2.8}>
                <Typography noWrap className={styles.label}>
                  {t("TREATMENT_TYPES.TREATMENT_TYPE_NAME")}
                </Typography>
                <TextField
                  variant="outlined"
                  disabled={disable}
                  margin="dense"
                  required
                  fullWidth
                  id="treatmentName"
                  placeholder={t("TREATMENT_TYPES.ENTER_TREATMENT_TYPE_NAME")}
                  className={styles.textEnabled}
                  name="treatmentName"
                  autoComplete={"off"}
                  onChange={onChange}
                  value={form.treatmentName.value}
                  error={!!form.treatmentName.error}
                  helperText={form.treatmentName.error}
                  FormHelperTextProps={{
                    style: {
                      textAlign: i18n.dir() === "ltr" ? "left" : "right",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={2.8}>
                <Typography noWrap className={styles.label}>
                  {t("TREATMENT_TYPES.LENGTH_OF_EACH_SESSION")}
                </Typography>
                <TextField
                  variant="outlined"
                  disabled={disable || disabledOnAssociation}
                  margin="dense"
                  required
                  fullWidth
                  id="numberOfSessions"
                  placeholder="Enter"
                  value={form.sessionLength.value}
                  className={styles.textEnabled}
                  name="sessionLength"
                  autoComplete={"off"}
                  onChange={(val) => {
                    const isIntegerRegexOrEmpty = /^$|^[0-9]+$/;
                    if (!isIntegerRegexOrEmpty.test(val.target.value)) return;
                    onChange(val);
                  }}
                  FormHelperTextProps={{
                    style: {
                      textAlign: i18n.dir() === "ltr" ? "left" : "right",
                    },
                  }}
                  error={!!form.sessionLength.error}
                  helperText={form.sessionLength.error}
                />
              </Grid>
              <Grid item xs={2.8}>
                <Typography noWrap className={styles.label}>
                  {t("TREATMENT_TYPES.NUMBER_OF_SESSIONS")}
                </Typography>
                <TextField
                  variant="outlined"
                  disabled={disable || disabledOnAssociation}
                  margin="dense"
                  required
                  fullWidth
                  id="noOfSession"
                  placeholder="Enter"
                  className={styles.textEnabled}
                  name="noOfSession"
                  autoComplete={"off"}
                  value={form.noOfSession.value}
                  onChange={(val) => {
                    const isIntegerRegexOrEmpty = /^$|^[0-9]+$/;
                    if (!isIntegerRegexOrEmpty.test(val.target.value)) return;
                    onChange(val);
                  }}
                  inputProps={{
                    valueType: "number",
                  }}
                  error={!!form.noOfSession.error}
                  helperText={form.noOfSession.error}
                  FormHelperTextProps={{
                    style: {
                      backgroundColor: "#F5F8FF",
                      margin: "0px",
                      padding: "0px",
                      textAlign: i18n.dir() === "ltr" ? "left" : "right",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={2.8}>
                <Typography noWrap className={styles.label}>
                  {t("TREATMENT_TYPES.MAXIMUM_PARTICIPANTS")}
                </Typography>
                <Select
                  disabled={disable || disabledOnAssociation}
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  required
                  autoComplete={"off"}
                  inputProps={{
                    valueType: "number",
                  }}
                  name="participantType"
                  className={styles.SelectEnabled}
                  IconComponent={KeyboardArrowDownIcon}
                  value={form.participantType.value}
                  displayEmpty
                  onChange={(e) => onChange(e)}
                  error={!!form.participantType.error}
                >
                  <MenuItem value={""}>Choose</MenuItem>
                  <MenuItem value={"individual"}>Individual</MenuItem>
                  <MenuItem value={"group"}>Group</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={0.7}>
                <TextField
                  variant="outlined"
                  disabled={
                    disable ||
                    form.participantType.value !== "group" ||
                    disabledOnAssociation
                  }
                  margin="dense"
                  required
                  fullWidth
                  style={{ marginTop: "31px" }}
                  id="participants"
                  value={
                    form.participantType.value === "individual"
                      ? "1"
                      : form.participants.value
                  }
                  className={styles.textEnabled}
                  name="participants"
                  autoComplete={"off"}
                  onChange={(val) => {
                    const isIntegerRegexOrEmpty = /^$|^[0-9]+$/;
                    if (!isIntegerRegexOrEmpty.test(val.target.value)) return;
                    onChange(val);
                  }}
                  error={!!form.participants.error}
                  helperText={form.participants.error}
                  FormHelperTextProps={{
                    style: {
                      backgroundColor: "#F5F8FF",
                      margin: "0px",
                      padding: "0px",
                      textAlign: i18n.dir() === "ltr" ? "left" : "right",
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2} md={12} sx={{ paddingTop: "5px" }}>
            <Grid item xs={12}>
              <Typography noWrap className={styles.label}>
                {t("TREATMENT_TYPES.DESCRIPTION")}
              </Typography>
              <TextField
                variant="outlined"
                margin="dense"
                disabled={disable}
                required
                fullWidth
                id="description"
                className={styles.textMultilines}
                name="treatmentDescription"
                value={form.treatmentDescription.value}
                onChange={onChange}
                autoComplete={"off"}
                placeholder={t("TREATMENT_TYPES.ENTER_DESCRIPTION")}
                multiline
                rows={1.5}
                error={!!form.treatmentDescription.error}
                helperText={form.treatmentDescription.error}
                FormHelperTextProps={{
                  style: {
                    textAlign: i18n.dir() === "ltr" ? "left" : "right",
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            md={12}
            sx={{
              padding: "5px 0px 10px 10px",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Tabs
                value={value}
                onChange={handleTabChange}
                aria-label="tabs"
                TabIndicatorProps={{ style: { background: "#012497" } }}
              >
                <Tab
                  label={
                    <span style={{ fontSize: "16px", fontWeight: "bolder" }}>
                      {t("TREATMENT_TYPES.TREATMENT_CONTENT")}
                    </span>
                  }
                  style={{
                    fontWeight: value === 0 ? "bold" : "500",
                    color: value === 0 ? "#012497" : "#2A3333",
                    fontSize: "16px",
                  }}
                />
                <Tab
                  label={
                    <span style={{ fontSize: "16px", fontWeight: "bolder" }}>
                      {t("TREATMENT_TYPES.TREATMENT_QUESTIONNAIRE")}
                    </span>
                  }
                  style={{
                    fontWeight: value === 1 ? "bold" : "500",
                    color: value === 1 ? "#012497" : "#2A3333",
                    fontSize: "16px",
                  }}
                />
              </Tabs>
              <TreatmentList
                disabledOnAssociation={disabledOnAssociation}
                mode={mode}
                value={value}
                disable={disable}
              />
              <QuestionnaireList
                disabledOnAssociation={disabledOnAssociation}
                mode={mode}
                value={value}
                disable={disable}
              />
            </Box>
          </Grid>

          <BottomActions
            disabled={!hasFullAccess || isLoading}
            chipBoxes={[
              {
                label: t("TREATMENT_TYPES.TREATMENTS_BANKS_COUNT"),
                count: Number(form.treatmentBanksCount.value),
              },
              {
                label: t("TREATMENT_TYPES.TIMESLOT_COUNT"),
                count: Number(form.timeSlotsCount.value),
              },
            ]}
          >
            {mode == "view" ? (
              hasFullAccess && (
                <Grid>
                  <Button
                    className={styles.saveBtn}
                    variant="contained"
                    onClick={handleEditButton}
                    disabled={isLoading}
                  >
                    {t("BUTTONS.EDIT")}
                  </Button>
                </Grid>
              )
            ) : (
              <ActionButtons
                loading={isLoading}
                onCancel={handleCancelBtn}
                onSave={handleSaveBtn}
                disabled={isLoading}
              />
            )}
          </BottomActions>
        </div>
      </Grid>
      <ConfirmDialog
        open={openDelete}
        title={t("TREATMENT_TYPES.DELETE_TREATMENT_TYPES")}
        description={t("TREATMENT_TYPES.DELETE_TREATMENT_TYPES_CONFIRMATION")}
        onCancel={() => handleDeleteClose("cancel")}
        onConfirm={() => handleDeleteClose("delete")}
        onClose={() => handleDeleteClose("cancel")}
      />
    </div>
  );
};
export default AddTreatment;
