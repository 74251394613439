export interface ResponseError {
  message: string;
}
export type RequestError = ResponseError;

export type MoveTimeSlotRequest = {
  currentTimeSlotId: number;
  newTimeSlotId: number;
  timeslotSessionId?: number;
  treatmentInvolvedPatientId: number;
};

export interface TreatmentInvolvedPatients {
  id: number;
  bankTreatmentId: number;
  instructorId: any;
  patientId: number;
  createdAt: string;
  updatedAt: string;
  patient: Patient;
  instructor?: Instructor;
  type: string;
  treatmentBankTreatment: TreatmentBankTreatment;
  timeslotInvolvedPatients: TimeslotInvolvedPatient[];
  completedAt?: string;
}

export interface Patient {
  id: number;
  organizationId: number;
  departmentId: number;
  bankId: number;
  patient_status: string;
  firstName: string;
  lastName: string;
  email: string;
  cellular: string;
  sex: string;
  birthDate: string;
  cityId: number;
  street: string;
  numberOfChildren: any;
  status: string;
  howLongInCurrentStatus: any;
  education: string;
  religion: string;
  comments: any;
  economicLevel: string;
  dailyWorkHours: string;
  monthsInCurrentOrganization: any;
  monthsInCurrentJobs: any;
  anyBadHistory: string;
  intakeFinalMeetingHistory1: string;
  intakeFinalMeetingHistory2: string;
  intakeInitialMeetingHistory2: string;
  intakeInitialMeetingHistory1: string;
  progressAssignedSlot: number;
  progressAssignedTreatment: number;
  progressCompletedTreatment: number;
  progressDailyActivityWatched: number;
  progressDailyActivitySent: number;
  isActive: boolean;
  isMobileRegistered: boolean;
  mobileNotificationEnabled: boolean;
  created_at: string;
  fcmToken: any;
  profilePic: any;
  activatedAt: string;
  updated_at: string;
  age: number;
  organization: Organization;
  department: Department;
  bank: Bank;
}
export interface TimeslotSession {
  id: number;
  timeSlotId: number;
  date: string;
  isSlotsAvailable: boolean;
  start_time: string;
  end_time: string;
  completedAt: any;
  attendanceMarkedAt: any;
  meetingId: string;
  sessionNumber: number;
  created_at: string;
  updated_at: string;
}
export interface TreatmentBankTreatment {
  id: number;
  treatmentBankId: number;
  treatmentId: number;
  startDate: string;
  endDate: string;
  isMust: boolean;
  maximumPerPatient: number;
  isActive: boolean;
  meetingsCompletedCount: number;
  meetingsInprogressCount: number;
  meetingsMissedCount: number;
  bonusPatientsCount: number;
  createdAt: string;
  updatedAt: string;
  treatment: Treatment;
  optionalInstructor: OptionalInstructor[];
}

export interface Treatment {
  id: number;
  name: string;
  type: "individual" | "grousp";
  description: string;
  session_duration: number;
  session_count: number;
  maximum_participants: number;
  location: any;
  is_active: boolean;
  status: boolean;
  created_at: string;
  updated_at: string;
}

export interface OptionalInstructor {
  id: number;
  treatmentBankId: number;
  isActive: boolean;
  instructorId: number;
  createdAt: string;
  updatedAt: string;
  treatmentOptionalInstructor: TreatmentOptionalInstructor;
}

export interface TreatmentOptionalInstructor {
  id: number;
  firstName: string;
  lastName: string;
  gender: string;
  dateOfBirth: string;
  martialStatus: string;
  numberOfChildren: number;
  email: string;
  mobileNumber: string;
  cityId: number;
  street: string;
  area: string;
  userGroupId: number;
  activeBankCount: number;
  futureTimeSlotsCount: number;
  profilePic: any;
  isActive: boolean;
  lastLoginAt: any;
  createdAt: string;
  updatedAt: string;
  language: string;
}

export interface AssignedTreatment {
  id: number;
  attendance: string;
  timeSlotId: number;
  patientType: any;
  isActive: boolean;
  created_at: string;
  updated_at: string;
  timeslotSessionId: number;
  timeslotInvolvedPatientId: number;
  timeslotSession: TimeslotSession;
  involvedPatient: TimeslotInvolvedPatient;
}

export interface TreatmentInvolvedPatient {
  id: number;
  bankTreatmentId: number;
  instructorId: any;
  completedAt: any;
  patientId: number;
  createdAt: string;
  updatedAt: string;
  treatmentBankTreatment: TreatmentBankTreatment;
  timeslotInvolvedPatients: LocalTimeslotInvolvedPatient[];
  patient: Patient;
  type: "Bonus" | "Regular";
}
export interface AvailableTimeslotsResponse {
  timeslots: Timeslot[];
  info: {
    individualPerPatient: number;
    groupPerPatient: number;
    countOfGroupTreatment: number;
    countOfIndividualTreatment: number;
    individualTreatmentCountInBank: number;
    groupTreatmentCountInBank: number;
  };
  type: number;
  timeslotsLoading?: boolean;
  isSaving?: boolean;
  treatmentInvolvedPatient: TreatmentInvolvedPatient;
  currentTimeSlotId: number;
  currentTimeslotSessionId: number;
}

export interface TimeslotInvolvedPatient {
  id: number;
  timeSlotId?: number;
  registrationStatus: string;
  currentStatus?: string;
  availableAction?: string;
  patientId: number;
  treatmentInvolvedPatientId: number;
  created_at: string;
  updated_at: string;
  timeslot: Timeslot;
  assignedTreatments: AssignedTreatment[];
  patient?: Patient;
}

export interface LocalTimeslotInvolvedPatient extends TimeslotInvolvedPatient {
  currentTimeslotId?: number;
  newTimeslotId: number;
  timeslotSessionId?: number;
  option?: "new" | "delete";
  completedAt?: string;
}

export interface Timeslot {
  id: number;
  organizationId: number;
  departmentId: number;
  bankId: number;
  treatmentId: number;
  bankTreatmentId: number;
  street: string;
  cityId: number;
  instructorId: number;
  eventColor: string;
  hasTimeslotsAvailable: boolean;
  actualInstructorId: number;
  slotDate: string;
  isActive: boolean;
  type: number;
  treatment: Treatment;
  created_at: string;
  updated_at: string;
  timeslotSession: TimeslotSession[];
}

export interface TimeslotSession {
  id: number;
  timeSlotId: number;
  date: string;
  isSlotsAvailable: boolean;
  start_time: string;
  end_time: string;
  completedAt: any;
  attendanceMarkedAt: any;
  meetingId: string;
  sessionNumber: number;
  created_at: string;
  updated_at: string;
  assignedTreatment?: AssignedTreatment[];
}

export interface Organization {
  id: number;
  name: string;
  taxNumber: string;
  isActive: boolean;
  comments: any;
  createdAt: string;
  updatedAt: string;
  __entity: string;
}

export interface Department {
  id: number;
  departmentName: string;
  organizationId: number;
  noOfBanks: number;
  city: any;
  cityId: number;
  address: string;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface Bank {
  id: number;
  assignedDate: string;
  organizationId: number;
  departmentId: number;
  name: string;
  bankStatus: string;
  individualPerPatient: number;
  groupPerPatient: number;
  totalPatientCapacity: number;
  isActive: boolean;
  treatmentAssetId: any;
  createdAt: string;
  updatedAt: string;
}

export interface Meta {
  totalItems: number;
  itemCount: number;
  itemsPerPage: number;
  totalPages: number;
  currentPage: number;
}

export interface Links {
  first: string;
  previous: string;
  next: string;
  last: string;
}
export type TreatmentTypeModal = {
  treatmentId: number;
  bankId: number;
  instructorId: number;
  patientId: number;
  index: number;
};

export type AssignedTreatmentStateType = {
  selectedTreatmentIndex: number;
  timeSlotModal: boolean;
  availableTimeSlots?: AvailableTimeslotsResponse;
  form: any;
  query: {
    limit: number;
    page: number;
    user_type: string;
    q: string;
    orderField: string;
    orderDirection: string;
    status: string;
    bankId: number;
    departmentId: number;
    organizationId: number;
    treatmentId: number;
    instructorId: number;
    actualInstructorId: number;
    from: String | null;
    to: String | null;
  };
  selectedTreatment?: TreatmentInvolvedPatients;
  list: {
    items: Array<TreatmentInvolvedPatients>;
    selectedIds: Array<Number>;
    meta: {
      totalItems: number;
      itemCount: number;
      itemsPerPage: number;
      totalPages: number;
      currentPage: number;
      info?: {
        countOfGroupTreatment: number;
        countOfIndividualTreatment: number;
        groupPerPatient: number;
        individualPerPatient: number;
        groupTreatmentCountInBank: number;
        individualTreatmentCountInBank: number;
      };
    };
    links: {
      first: string;
      last: string;
      previous: string;
      next: string;
    };
  };
  loading: boolean;
  error: string;
  assignedInstructors: Instructor[];
};

export type Instructor = {
  id: number;
  firstName: string;
  lastName: string;
  gender: string;
  dateOfBirth: string;
  martialStatus: string;
  numberOfChildren: number;
  email: string;
  mobileNumber: string;
  cityId: number;
  street: string;
  area: string;
  userGroupId: number;
  activeBankCount: number;
  futureTimeSlotsCount: number;
  profilePic: string | null;
  isActive: boolean;
  lastLoginAt: string | null;
  createdAt: string;
  updatedAt: string;
  language: string;
};

export const AssignedTreatmentState: AssignedTreatmentStateType = {
  selectedTreatmentIndex: 0,
  timeSlotModal: false,
  availableTimeSlots: undefined,
  form: {
    instructorId: null,
  },
  query: {
    user_type: "",
    limit: 10,
    page: 1,
    q: "",
    orderField: "",
    orderDirection: "",
    status: "0",
    bankId: 0,
    departmentId: 0,
    organizationId: 0,
    treatmentId: 0,
    instructorId: 0,
    actualInstructorId: 0,
    from: null,
    to: null,
  },
  selectedTreatment: undefined,
  assignedInstructors: [],
  list: {
    selectedIds: [],
    items: [],
    meta: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 100,
      totalPages: 1,
      currentPage: 1,
      info: undefined,
    },
    links: {
      first: "",
      previous: "",
      next: "",
      last: "",
    },
  },
  loading: false,
  error: "",
};

export interface ItemType {
  id: number;
  [key: string]: number | string | boolean; // This allows any string as key and the value can be number, string or boolean
}

export interface CheckboxPayload {
  id: number;
  key: keyof ItemType;
  value: boolean;
}

export type ContainerState = AssignedTreatmentStateType;
