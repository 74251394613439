import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles(() => ({
  selectBox: {
    display: "flex",
    alignItems: "flex-start",
  },
  label: {
    fontFamily: "Cairo",
    fontWeight: 400,
    fontSize: "16px",
    color:"#2A3333",
  },
  textEnabled: {
    background: "#FFF",
    width: "92.5%",
    marginTop:"7px"
  }
}))