import {
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
export const TableHeader = ({
  serialEnabled,
  columnValues,
  classes,
  order,
  orderBy,
  onRequestSort,
  HeaderActions,
  disableActions,
  Actions,
  isAllSelected,
}) => {
  const { t } = useTranslation();
  return (
    <TableHead>
      <TableRow>
        {serialEnabled && (
          <TableCell
            className={clsx(
              classes.textCenterClass,
              classes.removeLeft,
              classes.cellHeadWithoutWidth
            )}
          >
            #
          </TableCell>
        )}
        {Actions && (
          <TableCell
            className={clsx(
              classes.textCenterClass,
              classes.removeLeft,
              classes.cellHeadWithoutWidth
            )}
            key="Actions"
            style={{ width: 10, paddingLeft: "25px" }}
          >
            {HeaderActions == "CheckBox" ? (
              <Checkbox
                color="info"
                style={{ paddingLeft: "0px" }}
                disabled={disableActions.includes("CheckBox")}
              />
            ) : (
              t("TABLES.ACTIONS")
            )}
          </TableCell>
        )}

        {columnValues.map((headCell, index) => (
          <TableCell
            className={clsx(
              classes.textCenterClass,
              classes.removeLeft,
              classes.cellHeadWithoutWidth
            )}
            key={headCell.id + "_" + index}
            align={headCell.align}
            style={{
              alignItems: "end",

              width: headCell.width ? headCell.width : "auto",
              minWidth: headCell.minWidth,
              maxWidth: headCell.maxWidth ? headCell.maxWidth : "auto",
              textAlign: headCell?.align,
              ...(headCell.centeredHeader && {
                textAlign: "left",
              }),
              ...headCell.position,
            }}
          >
            {headCell.sortValue && headCell.sortValue !== "" ? (
              <TableSortLabel
                hideSortIcon={false}
                className={classes.tableSort}
                active={headCell.sortValue === orderBy}
                sx={{
                  "& .MuiButtonBase-root-MuiTableSortLabel-root.Mui-active": {
                    color: "#bdbdbd",
                  },
                }}
                direction={
                  orderBy === headCell.sortValue
                    ? order?.toLocaleLowerCase() || ""
                    : "asc"
                }
                onClick={() => onRequestSort(headCell.sortValue)}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
