import { FormError } from "app/containers/types";
import i18next from "i18next";
import { Form } from "../types";

export const executeErrorValidation = (form: Form) => {
  const errors: FormError[] = [];

  if (!form.name.value.length) {
    errors.push({
      name: "name",
      error: i18next.t("DAILY_ACTIVITY.ERRORS.NAME_IS_REQUIRED"),
    });
  }

  if (!String(form.organizationId.value).length) {
    errors.push({
      name: "organizationId",
      error: i18next.t("DAILY_ACTIVITY.ERRORS.ORGANIZATION_IS_REQUIRED"),
    });
  }

  if (!form.description.value.length) {
    errors.push({
      name: "description",
      error: i18next.t("DAILY_ACTIVITY.ERRORS.DESCRIPTION_IS_REQUIRED"),
    });
  }

  if (!(Number(form.activationDayNumber.value) >= 0)) {
    errors.push({
      name: "activationDayNumber",
      error: i18next.t("DAILY_ACTIVITY.ERRORS.ACTIVATION_DAY_IS_REQUIRED"),
    });
  }

  if (!form.scheduledAt.value) {
    errors.push({
      name: "scheduledAt",
      error: i18next.t("DAILY_ACTIVITY.ERRORS.ACTIVATION_TIME_IS_REQUIRED"),
    });
  }

  const hasAtLeastOneResource = form.resources.filter(
    (f) => !f.isDeleted && f.resourcePath
  );
  if (!hasAtLeastOneResource.length) {
    errors.push({
      name: "resourceError",
      error: i18next.t("DAILY_ACTIVITY.ERRORS.RESOURCE_REQUIRED", { min: 1 }),
    });
  }

  return errors;
};
