import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Box,
  Button,
  Card,
  Grid,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import QuestionnaireFiled from "app/components/FieldComponent/Questionnaire";
import { useUsersListSaga } from "app/containers/Users/redux/saga";
import {
  reducer as userReducer,
  sliceKey as userSliceKey,
} from "app/containers/Users/redux/slice";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { TRANSLATE } from "utils/helpers";
import { selectEditQuestionnaire, selectList } from "../../redux/selector";
import { actions } from "../../redux/slice";
import { useStyles } from "./styles";
import { isDuplicate, onValidate } from "./validator";

const AddTreatmentQuestionaire = ({
  treatmentId,
  openModal,
  handleModalClose,
  disable,
  mode,
  noofSession,
  editIndex,
}) => {
  useInjectReducer({ key: userSliceKey, reducer: userReducer });
  useInjectSaga({ key: userSliceKey, saga: useUsersListSaga });
  const [beforeAfter, setBeforeAfter] = useState(false);
  const [sessionNum, setsessionNum] = useState(false);
  const styles = useStyles();
  const dispatch = useDispatch();
  const form = useSelector(selectEditQuestionnaire);
  const listitems = useSelector(selectList);
  const { items } = listitems;
  const onChange = (e) => {
    if (e.target.name === "hours") {
      const regex = /^[0-9\b]+$/;
      const { name } = e.target;
      const key = `questionaire.${name}`;
      const value = regex.test(e.target.value)
        ? e.target.value
        : e.target.value.replace(/[^0-9]/g, "");
      dispatch(actions.updateFormValue({ key, value }));
    } else {
      const { name } = e.target;
      const key = `questionaire.${name}`;
      const value = e.target.value;
      dispatch(actions.updateFormValue({ key, value }));
    }
  };

  const direction = useTheme().direction;

  const handleSubmitAddTreatmentQuestionaire = (action) => {
    const errors = onValidate(form);
    if (errors?.length > 0) {
      dispatch(
        actions.setFormErrors({
          key: "questionaire",
          errors,
        })
      );
      return;
    }
    const exists = isDuplicate(form, items);
    if (exists && mode !== "edit") {
      dispatch(
        actions.setFormErrors({
          key: "questionaire",
          errors: [
            {
              name: "questionnaireId",
              error: TRANSLATE(
                "TREATMENT_TYPES.TREATMENT_QUESTIONNAIRE_EXISTS"
              ),
            },
          ],
        })
      );
      return;
    }
    if (action === "save") {
      if (mode === "edit") {
        dispatch(
          actions.doAddTreatmentQuestionnaireItem({
            id: form.id?.value,
            treatmentId,
            beforeAfter: form.beforeAfter.value,
            hours: form.hours.value,
            questionnaireId: form.questionnaireId.value,
            questionaireName: form.questionaireName.value,
            session_id: form.session_id.value,
            index: editIndex,
            type: "edit",
          })
        );
      } else {
        dispatch(
          actions.doAddTreatmentQuestionnaireItem({
            id: 0,
            treatmentId,
            beforeAfter: form.beforeAfter.value,
            hours: form.hours.value,
            questionnaireId: form.questionnaireId.value,
            questionaireName: form.questionaireName.value,
            session_id: form.session_id.value,
            type: "add",
          })
        );
      }
    }
  };

  const { t, i18n } = useTranslation();

  return (
    <Modal
      open={openModal}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card
        className={styles.addModal}
        sx={{
          direction,
        }}
      >
        <div className={styles.modalContainer}>
          <Grid>
            <Typography align="center" className={styles.headerModal}>
              {mode === "add" || mode === "new"
                ? t("TREATMENT_TYPES.ADD")
                : t("TREATMENT_TYPES.EDIT")}{" "}
              {t("TREATMENT_TYPES.TREATMENT_QUESTIONNAIRE")}
            </Typography>
          </Grid>
          <Grid
            xs={12}
            container
            rowSpacing={1.5}
            columnSpacing={2}
            sx={{
              padding: "10px 0px 35px 0px",
              margin: "0px",
              display: "flex",
            }}
          >
            <Grid item xs={3}>
              <QuestionnaireFiled
                type={"all"}
                changeValue={(e, name) => {
                  let key = `questionaire.questionnaireId`;
                  dispatch(actions.updateFormValue({ key, value: e }));
                  key = `questionaire.questionaireName`;
                  dispatch(actions.updateFormValue({ key, value: name }));
                }}
                value={form.questionnaireId.value}
                showAll={false}
                disabled={false}
                error={form.questionnaireId.error}
                xs={12}
              ></QuestionnaireFiled>
            </Grid>
            <Grid item xs={2.9}>
              <Box>
                <label className={styles.label}>
                  {t("TREATMENT_TYPES.BEFORE_AFTER")}
                </label>
                <Select
                  disabled={disable}
                  variant="outlined"
                  fullWidth
                  margin="none"
                  required
                  name="beforeAfter"
                  autoComplete={"off"}
                  className={styles.textEnabled}
                  onClick={() => setBeforeAfter(!beforeAfter)}
                  open={beforeAfter}
                  IconComponent={KeyboardArrowDownIcon}
                  value={form.beforeAfter.value}
                  displayEmpty
                  onChange={onChange}
                  error={!!form.beforeAfter.error}
                  renderValue={(selected) => {
                    if (selected?.length === 0) {
                      return (
                        <p style={{ color: "#A4A9AB", margin: 0 }}>Choose</p>
                      );
                    }
                    return selected;
                  }}
                >
                  <MenuItem value={"Before"}>Before</MenuItem>
                  <MenuItem value={"After"}>After</MenuItem>
                </Select>
              </Box>
            </Grid>
            <Grid item xs={3.3}>
              <label className={styles.label}>
                {t("TREATMENT_TYPES.HOURS")}
              </label>
              <TextField
                margin="none"
                fullWidth
                variant="outlined"
                required
                name="hours"
                autoComplete={"off"}
                className={styles.textEnabled}
                placeholder={"Enter amount of hours"}
                onChange={onChange}
                value={form.hours.value}
                error={!!form.hours.error}
                helperText={form.hours.error}
                FormHelperTextProps={{
                  style: {
                    textAlign: i18n.dir() === "ltr" ? "left" : "right",
                  },
                }}
              />
            </Grid>
            <Grid item xs={2.6}>
              <label className={styles.label}>
                {t("TREATMENT_TYPES.SESSION_NUMBER")}
                {form.session_id.value}
              </label>
              <Select
                disabled={disable}
                variant="outlined"
                fullWidth
                margin="none"
                required
                name="session_id"
                autoComplete={"off"}
                className={styles.textEnabled}
                onClick={() => setsessionNum(!sessionNum)}
                open={sessionNum}
                IconComponent={KeyboardArrowDownIcon}
                onChange={onChange}
                value={form.session_id.value}
                displayEmpty
                error={!!form.session_id.error}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return (
                      <p style={{ color: "#A4A9AB", margin: 0 }}>Choose</p>
                    );
                  }
                  return selected;
                }}
              >
                {Array.from({ length: noofSession }, (x, i) => i).map(
                  (item, index) => {
                    return (
                      <MenuItem key={index} value={item + 1}>
                        {item + 1}
                      </MenuItem>
                    );
                  }
                )}
              </Select>
            </Grid>
          </Grid>
          <div>
            <Grid container spacing={2}>
              <Grid
                className={styles.btnContainer}
                container
                item
                xs={6}
                direction="column"
              >
                <Button
                  className={styles.cancelBtn}
                  variant="outlined"
                  onClick={handleModalClose}
                >
                  {t("BUTTONS.CANCEL")}
                </Button>
              </Grid>
              <Grid container item xs={6} direction="column">
                <Button
                  onClick={() => handleSubmitAddTreatmentQuestionaire("save")}
                  className={styles.saveBtn}
                  variant="contained"
                >
                  {t("BUTTONS.SAVE")}
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </Card>
    </Modal>
  );
};
export default AddTreatmentQuestionaire;
