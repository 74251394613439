import { colors, createTheme } from "@mui/material";
// import { FormHelperText } from '@material-ui/core';

const theme = createTheme({
  direction: "ltr",
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            pointerEvents: "auto", // Corrected value
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            pointerEvents: "auto", // Corrected value
          },
        },
      },
    },
  },
  palette: {
    background: {
      default: colors.common.white,
      paper: colors.common.white,
    },
    primary: {
      main: "#3D63E6",
    },
    secondary: {
      main: "#3D63E6",
    },

    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600],
    },

    info: {
      main: "#012497",
    },
  },
  typography: {
    fontFamily: "Cairo !important",
    allVariants: {
      textTransform: "none",
      //fontFamily: `"Cairo",`,
    },
  },
});

export default theme;
