import InfoIcon from "@mui/icons-material/Info";
import {
  FormControlLabel,
  Grid,
  Radio,
  Tooltip,
  Typography,
} from "@mui/material";
import { RadioGroup } from "app/components/RadioGroup";
import {
  AvailableTimeslotsResponse,
  LocalTimeslotInvolvedPatient,
  Timeslot,
} from "app/containers/AssignedTreament/types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getFormattedDateTime } from "utils/helpers";

import { actions } from "app/containers/AssignedTreament/redux/slice";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { selectFeaturePermissions } from "store/global/authPermission/selector";
import { PermissionFeature } from "store/global/authPermission/types";
import { useStyles } from "./styles";
type Props = {
  availableTimeslotsResponse: AvailableTimeslotsResponse;
  timeslotInvolvedPatient: LocalTimeslotInvolvedPatient;
  index: number;
};
export const SingleTimeslotSelection = ({
  availableTimeslotsResponse,
  timeslotInvolvedPatient,
  index,
}: Props) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const [timeslots, setTimeslots] = useState<Timeslot[]>([]);

  const selectedTimeSlotId =
    timeslotInvolvedPatient.newTimeslotId || timeslotInvolvedPatient.timeSlotId;
  const assignedTreatment = timeslotInvolvedPatient.assignedTreatments?.find(
    (f) => f.timeslotInvolvedPatientId == timeslotInvolvedPatient.id
  );
  const selectedTimeSlotSessionId =
    timeslotInvolvedPatient.timeslotSessionId ||
    assignedTreatment?.timeslotSessionId;

  useEffect(() => {
    const otherTimeslotIds =
      availableTimeslotsResponse.treatmentInvolvedPatient.timeslotInvolvedPatients
        .map((tip) => {
          return [tip.newTimeslotId, tip.timeSlotId];
        })
        .flat();
    setTimeslots(
      availableTimeslotsResponse.timeslots.filter((f) => {
        if (selectedTimeSlotId == f.id) {
          return true;
        }
        if (otherTimeslotIds.includes(f.id)) {
          return false;
        }
        return true;
      })
    );
  }, [
    availableTimeslotsResponse.treatmentInvolvedPatient,
    availableTimeslotsResponse.timeslots,
    selectedTimeSlotId,
  ]);

  const selectedTimeslot = availableTimeslotsResponse.timeslots.find(
    (x) => x.id == selectedTimeSlotId
  );
  const selectedTimeslotSessions = selectedTimeslot?.timeslotSession || [];
  const { t } = useTranslation();
  const featurePermissions = useSelector(selectFeaturePermissions);

  const handleSetTimeSlot = (timeslotId) => {
    dispatch(actions.handleOnSelectTimeslot({ index, timeslotId }));
  };

  const handleSelectedSession = (timeslotSessionId, timeSlotId) => {
    dispatch(
      actions.handleOnSelectTimeslotSession({
        index,
        timeslotSessionId,
        timeSlotId,
      })
    );
  };
  const { hasFullAccess } =
    featurePermissions[PermissionFeature.ASSIGNED_TREATNEMTS_B];

  const pathname = window.location.pathname;

  const isDisabled = !hasFullAccess && pathname === "/assigned-treatment";

  if (!timeslots?.length) {
    return (
      <Grid
        xs={12}
        direction="column"
        sx={{
          minHeight: 300,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography> {t("TIMESLOT.NOT_TIMESLOTS_AVAILABLE")}</Typography>
      </Grid>
    );
  }

  const disabled = !!assignedTreatment?.timeslotSession?.completedAt;
  return (
    <Grid maxHeight={270} className={styles.scroll}>
      {assignedTreatment?.timeslotSession?.completedAt ? (
        <Typography className={styles.info}>
          <InfoIcon style={{ fontSize: "20px" }} /> This treatment is passed
          with meeting {assignedTreatment?.timeslotSession.meetingId}
        </Typography>
      ) : null}
      <Grid className={styles.body}>
        <label className={styles.label_date}>
          <span className={styles.bold}>{t("TIMESLOT.SELECT_DATE")}</span>
        </label>
        <RadioGroup defaultValue={"none"}>
          {timeslots.map((x, i) => {
            return (
              <FormControlLabel
                value={x.id}
                control={
                  <Radio
                    disabled={disabled || isDisabled}
                    sx={{
                      "&.Mui-checked": {
                        color: "#012397",
                      },
                    }}
                    onChange={(e) => {
                      handleSetTimeSlot(parseInt(e.target.value));
                    }}
                  />
                }
                checked={selectedTimeSlotId == x.id}
                label={
                  <div className={styles.label_date}>
                    <span
                      style={{
                        fontSize: 10,
                        position: "absolute",
                        top: -20,
                        left: -50,
                        color: "#bbb",
                      }}
                    >
                      {" "}
                      T{x.id}
                    </span>
                    <span
                      style={{
                        fontSize: 10,
                        position: "absolute",
                        top: -20,
                        right: 2,
                        color: "#bbb",
                      }}
                    >
                      {" "}
                      Max {x.treatment.maximum_participants}
                    </span>
                    <span>
                      {getFormattedDateTime(x.slotDate, "EEE, dd MMM")}
                    </span>
                  </div>
                }
                className={styles.date_area}
              />
            );
          })}
        </RadioGroup>
        <table className={styles.table}>
          {selectedTimeslotSessions.length ? (
            <thead>
              <tr>
                <td style={{ paddingLeft: "15px" }}>
                  <span className={styles.bold}>
                    {t("TIMESLOT.SELECT_TIME")}
                  </span>
                </td>
                <td>
                  <span className={styles.bold}>
                    {t("TIMESLOT.REGISTERED_PATIENTS")}
                  </span>
                </td>
              </tr>
            </thead>
          ) : null}

          <tbody>
            {selectedTimeslotSessions.map((item, index) => {
              const isMySession = item?.assignedTreatment?.some(
                (f) =>
                  f.involvedPatient?.patientId ==
                  availableTimeslotsResponse?.treatmentInvolvedPatient
                    ?.patientId
              );

              const assignedTreatmentCount =
                item?.assignedTreatment?.length || 0;
              const maximumParticipants =
                selectedTimeslot?.treatment?.maximum_participants || 0;
              const isSlotTaken = isMySession
                ? assignedTreatmentCount - 1 >= maximumParticipants
                : assignedTreatmentCount >= maximumParticipants;

              return (
                <tr className={styles.even_row}>
                  <td className={styles.cell}>
                    <Tooltip
                      title={
                        item.completedAt
                          ? t("TIMESLOT.SLOT_IS_COMPLETED")
                          : isSlotTaken
                          ? t("TIMESLOT.SLOT_IS_TAKEN")
                          : t("TIMESLOT.SLOT_IS_AVAILABLE")
                      }
                    >
                      <FormControlLabel
                        value={item.id}
                        disabled={disabled || isSlotTaken || isDisabled}
                        control={
                          <Radio
                            value={item.id}
                            name="radio-buttons"
                            onChange={(e) => {
                              handleSelectedSession(
                                parseInt(e.target.value),
                                item.timeSlotId
                              );
                            }}
                            inputProps={{ "aria-label": "A" }}
                            sx={{
                              "&.Mui-checked": {
                                color: "#012397",
                              },
                            }}
                          />
                        }
                        checked={selectedTimeSlotSessionId == item.id}
                        label={
                          <div
                            className={clsx(
                              styles.timeSession,
                              item.completedAt && styles.timeSessionCcompleted
                            )}
                          >
                            {getFormattedDateTime(item.start_time, "HH:mm")} -{" "}
                            {getFormattedDateTime(item.end_time, "HH:mm")} (
                            {item.meetingId})
                          </div>
                        }
                      />
                    </Tooltip>
                  </td>
                  <td className={isSlotTaken ? styles.disabledRow : styles.row}>
                    <label>
                      {item?.assignedTreatment?.map((aT, i) => {
                        return `${i != 0 ? " / " : ""}${
                          aT.involvedPatient?.patient?.firstName
                        } ${aT.involvedPatient?.patient?.lastName}`;
                      })}
                    </label>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Grid>
    </Grid>
  );
};
