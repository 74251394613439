import { Box, Button, Grid, Typography } from "@mui/material";
import BankFiled from "app/components/FieldComponent/Bank";
import DepartmentFiled from "app/components/FieldComponent/Department";
import OrganizationField from "app/components/FieldComponent/Organization";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  selectBanksOptions,
  selectDepartmentOptions,
  selectOrganization,
} from "store/global/fields/selector";
import { selectUserPermission } from "../../redux/selector";
import { actions } from "../../redux/slice";
import { useStyles } from "./styles";

export default function DepartmentPermissionsForm({
  onClose,
}: {
  onClose: () => void;
}) {
  const styles = useStyles();
  const { t } = useTranslation();
  const [organizationDropDownValue, setOrganizationDropDownValue] = useState(0);
  const [organizationError, setOrganizationError] = useState("");
  const [deptNameDropdown, setDeptNameDropdown] = useState(0);
  const [deptNameError, setDeptNameError] = useState("");
  const { id } = useParams();
  const [bankNameDropdown, setBankNameDropdown] = useState(0);
  const dispatch = useDispatch();
  const permissionList = useSelector(selectUserPermission);
  const organizationList = useSelector(selectOrganization);
  const departmentList = useSelector(selectDepartmentOptions);
  const banksList = useSelector(selectBanksOptions);

  useEffect(() => {
    return () => {
      setOrganizationDropDownValue(0);
      setDeptNameDropdown(0);
      setBankNameDropdown(0);
    };
  }, []);

  const handlePermissionSave = () => {
    if (!organizationDropDownValue || organizationDropDownValue === 0) {
      setOrganizationError("COMMON.ORGANIZATION_REQUIRED");
      return;
    }

    const items = permissionList["items"].filter(
      (item) => item.action !== "delete"
    );
    const isDuplicate = items.find(
      (item) =>
        item.organization?.id === organizationDropDownValue &&
        item.department?.id === deptNameDropdown &&
        item.bank?.id === bankNameDropdown
    );
    if (isDuplicate) {
      setOrganizationError("COMMON.DUPLICATE_PERMISSION");
      return;
    }
    dispatch(
      actions.setPermission({
        organization: organizationList.items.find(
          (o) => o?.id === organizationDropDownValue
        ),
        department: departmentList.items.find(
          (o) => o?.id === deptNameDropdown
        ),
        bank: banksList.find((x) => x?.id === bankNameDropdown),
        emailNotification: false,
      })
    );
    onClose();
    setDeptNameDropdown(0);
    setOrganizationDropDownValue(0);
    setBankNameDropdown(0);
  };
  return (
    <>
      <Grid>
        <Typography align="center" className={styles.headerModal}>
          {t("USERS.PATIENTS_DEPARTMENT_PERMISSION")}
        </Typography>
      </Grid>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ padding: "20px 0px" }}
      >
        <OrganizationField
          xs={4}
          value={organizationDropDownValue}
          changeValue={(v) => {
            setOrganizationError("");
            setOrganizationDropDownValue(v);
          }}
          showAll={false}
          activeRequired={!id}
          error={organizationError ? t(organizationError) : ""}
        />

        <DepartmentFiled
          xs={4}
          value={deptNameDropdown}
          changeValue={(v) => {
            setDeptNameError("");
            setDeptNameDropdown(v);
          }}
          showAll={true}
          orgId={organizationDropDownValue}
          error={deptNameError ? t(deptNameError) : ""}
        />
        <BankFiled
          xs={4}
          value={bankNameDropdown}
          changeValue={setBankNameDropdown}
          showAll={true}
          organizationId={organizationDropDownValue}
          departmentId={deptNameDropdown}
        />
      </Grid>
      <div className={styles.popupAction}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            marginBottom: "20px",
          }}
        >
          <Button
            className={styles.cancelBtn}
            variant="outlined"
            onClick={onClose}
          >
            {t("BUTTONS.CANCEL")}
          </Button>
          <Button
            onClick={handlePermissionSave}
            className={styles.saveBtn}
            variant="contained"
          >
            {t("BUTTONS.ADD")}
          </Button>
        </Box>
      </div>
    </>
  );
}
