export interface Root {
  items: Questionnaire[];
  meta: Meta;
  links: Links;
}

export interface RangeResult {
  rangeId: number;
  t0: number;
  totalPatientsT0: number;
  t1: number | null;
  totalPatientsT1: number;
}

export interface ResultItem {
  id: number;
  questionnaireId: number;
  t0: number;
  t1: number;
  organizationId: number;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  organization: Organization;
  questionnaire: Questionnaire;
  scores: RangeResult[];
}

export interface Organization {
  id: number;
  name: string;
  taxNumber: string;
  isActive: boolean;
  comments: string;
  createdAt: string;
  updatedAt: string;
  __entity: string;
}

export interface Questionnaire {
  id: number;
  name: string;
  type: string;
  totalQuestions: number;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  organizationId: number;
  grades: Grade[];
}

export interface Grade {
  t0Avg?: number;
  t1Avg?: number;
  id: number;
  gradeName: string;
  questionNumbers: string;
  gradeType: string;
  mainGrade: boolean;
  isActive: boolean;
  createdAt: string;
  updated_at: string;
  questionnaireId: number;
  ranges: Range[];
}

export interface Range {
  id: number;
  to: number;
  from: number;
  rangeName: string;
  description: string;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  gradeId: number;
  score?: RangeResult;
}

export interface Meta {
  totalItems: number;
  itemCount: number;
  itemsPerPage: number;
  totalPages: number;
  currentPage: number;
}

export interface Links {
  first: string;
  previous: string;
  next: string;
  last: string;
}

export type DetailedItem = {
  item?: ResultItem;
  isLoading: boolean;
  questionnaireId: number;
};

export type DashboardQueryState = {
  q: string;
  organizationId: number;
  bankId: number;
  departmentId: number;
};
export interface DashboardState {
  menuOpen: boolean;
  loading: boolean;
  query: DashboardQueryState;
  questionnaire: {
    isLoading: boolean;
    details: DetailedItem;
    items: Questionnaire[];
    meta: Meta;
  };
  progress: PRoot;
  search: string;
}

export interface PRoot {
  dailyActivity: DailyActivity;
  progressList: ProgressList;
}

export interface DailyActivity {
  total: number;
  seen: number;
}

export interface Meeting {
  total: number;
  missed: number;
  future: number;
}

export interface ProgressList {
  items: PItem[];
  meta: Meta;
  links: Links;
}

export interface PItem {
  id: number;
  assignedDate: string;
  organizationId: number;
  departmentId: number;
  name: string;
  status: string;
  individualPerPatient: number;
  groupPerPatient: number;
  totalPatientCapacity: number;
  isActive: boolean;
  document: any;
  createdAt: string;
  updatedAt: string;
  organization: Organization;
}

export interface Organization {
  id: number;
  name: string;
  taxNumber: string;
  isActive: boolean;
  comments: string;
  createdAt: string;
  updatedAt: string;
  __entity: string;
}

export interface Meta {
  totalItems: number;
  itemCount: number;
  itemsPerPage: number;
  totalPages: number;
  currentPage: number;
}

export interface Links {
  first: string;
  previous: string;
  next: string;
  last: string;
}

export const initialState: InitialDashboardState = {
  menuOpen: true,
  loading: false,
  search: "",
  query: {
    q: "",
    organizationId: 0,
    bankId: 0,
    departmentId: 0,
  },
  questionnaire: {
    details: {
      questionnaireId: 0,
      item: undefined,
      isLoading: false,
    },
    isLoading: false,
    items: [],
    meta: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 0,
      totalPages: 0,
      currentPage: 0,
    },
  },
  progress: {
    dailyActivity: {
      total: 0,
      seen: 0,
    },
    progressList: {
      items: [],
      meta: {
        totalItems: 0,
        itemCount: 0,
        itemsPerPage: 10,
        totalPages: 0,
        currentPage: 0,
      },
      links: {
        first: "",
        previous: "",
        next: "",
        last: "",
      },
    },
  },
};

export type InitialDashboardState = DashboardState;
