import React, { ChangeEvent, useEffect, useRef, useState } from "react";

import { CircularProgress, FormHelperText, Grid } from "@mui/material";
import uploadBtn from "app/containers/Patients/assets/Upload.png";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { uploadFileToS3 } from "./actions";
import { useStyles } from "./styles";
type Props = {
  supportedFile: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  onUpload(filePath: string): void;
};
export const UploadField = ({ supportedFile, onUpload }: Props) => {
  const styles = useStyles();
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const [isLoading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const docRef = useRef<HTMLInputElement | null>(null);
  function docClick() {
    if (docRef.current) docRef.current.click();
  }

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e?.target?.files) {
      return setSelectedFile(undefined);
    }
    const file = e?.target?.files[0];
    if (!file) {
      setError("XLSX file required");
      setSelectedFile(undefined);
      return;
    }
    if (!file?.type.includes(supportedFile)) {
      setError("Not a valid xlsx file");
      setSelectedFile(undefined);
      return;
    }

    setSelectedFile(file);
    setError("");
  };
  const uploadFile = async (file: File) => {
    setLoading(true);
    const res = await uploadFileToS3(file);
    if (res.file) {
      onUpload(res.file);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (selectedFile) {
      uploadFile(selectedFile);
    }
  }, [selectedFile]);

  return (
    <Grid item xs={12}>
      <div className={styles.dotBox} onClick={() => docClick()}>
        <Grid className={clsx(styles.uploadBox, error && styles.uploadBoxErr)}>
          <input
            type="file"
            accept={supportedFile}
            ref={docRef}
            className={styles.hiddenInput}
            onChange={handleFileChange}
          />
          <img width="30px" src={uploadBtn} alt="download" />
          <label className={styles.uploadLabel}>
            {selectedFile ? selectedFile?.name : t("PATIENTS.UPLOAD_DOCUMENT")}
          </label>{" "}
          {isLoading && (
            <CircularProgress
              style={{ marginLeft: 25, marginRight: 25 }}
              size={25}
            />
          )}
        </Grid>
      </div>
      {error ? <FormHelperText error> {error}</FormHelperText> : null}
    </Grid>
  );
};
