import { Grid, IconButton, Tabs, Tooltip } from "@mui/material";
import Tab from "@mui/material/Tab";
import { actions } from "app/containers/AssignedTreament/redux/slice";

import { Add, Close } from "@mui/icons-material";

import { TabPanel } from "app/components/TabPanel";
import { selectRemainingTreatments } from "app/containers/AssignedTreament/redux/selector";
import { AvailableTimeslotsResponse } from "app/containers/AssignedTreament/types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectFeaturePermissions } from "store/global/authPermission/selector";
import { PermissionFeature } from "store/global/authPermission/types";
import { TimeslotActions } from "../Actions";
import { TimeslotInfo } from "../TimeslotInfo";
import { MultiTimeslotSelection } from "./components/MultiTimeslotSelection";
import { useStyles } from "./styles";
type Props = {
  onClose: () => void;
  disable?: boolean;
  availableTimeslotsResponse: AvailableTimeslotsResponse;
  maxTreatmentAmount: number;
  minTreatmentAmount: number;
};

export const TimeslotMulti = ({
  availableTimeslotsResponse,
  disable,
  onClose,
  maxTreatmentAmount,
  minTreatmentAmount,
}: Props) => {
  const styles = useStyles();
  const remainingTreatments = useSelector(selectRemainingTreatments);
  const dispatch = useDispatch();
  const [tab, setTab] = useState(1);

  const { t } = useTranslation();

  const handleSave = () => {
    dispatch(actions.setTimeSlot());
  };

  const handleAdd = (e) => {
    dispatch(actions.handleAddTreatment());
  };

  const handleRemove = (index) => {
    if (index + 1 == tab) {
      setTab(tab - 1);
    }
    dispatch(actions.handleRemoveTreatment({ index }));
  };

  const handleChange = (e, value: number) => {
    setTab(value);
  };
  const treatmentInvolvedPatient =
    availableTimeslotsResponse.treatmentInvolvedPatient;
  const timeslotInvolvedPatients =
    treatmentInvolvedPatient.timeslotInvolvedPatients.filter(
      (m) => m.option !== "delete"
    );

  const featurePermissions = useSelector(selectFeaturePermissions);
  const assigningPermission =
    featurePermissions[PermissionFeature.ASSIGNED_TREATNEMTS_A];

  const isBonus = treatmentInvolvedPatient?.type == "Bonus";

  return (
    <Grid xs={12} direction="column">
      <Grid height={350}>
        <Grid
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          height={80}
        >
          <Tabs
            value={tab}
            TabIndicatorProps={{ style: { background: "#012397" } }}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable"
          >
            {timeslotInvolvedPatients.map((tip, index) => {
              const isStartedTreatment = tip?.assignedTreatments?.find(
                (f) => !!f.timeslotSession?.completedAt
              );
              const totalAssignedSessions = tip.assignedTreatments?.length ?? 0;
              const totalCompletedSessions = tip.assignedTreatments?.filter(
                (f) => !!f.timeslotSession?.completedAt
              ).length;
              const isCompletedTreatment =
                totalAssignedSessions > 0 &&
                totalCompletedSessions === totalAssignedSessions;

              const isDeleteAvailable =
                !isStartedTreatment &&
                !isCompletedTreatment &&
                assigningPermission.hasFullAccess &&
                timeslotInvolvedPatients.length > minTreatmentAmount;

              return (
                <Tab
                  label={t("ASSIGNED_TREATMENT.TREATMENT_NUMBER", {
                    treatment: String(index + 1),
                  })}
                  value={index + 1}
                  iconPosition="end"
                  icon={
                    isDeleteAvailable ? (
                      <IconButton
                        sx={{ padding: "5px", margin: 0 }}
                        size="small"
                        onClick={() => handleRemove(index)}
                      >
                        <Close sx={{ height: 15, width: 15 }} />
                      </IconButton>
                    ) : undefined
                  }
                />
              );
            })}
          </Tabs>
          {assigningPermission.hasFullAccess && (
            <Tooltip title={t("ASSIGNED_TREATMENT.ADD_TREATMENT_AMOUNT")}>
              <IconButton
                disabled={
                  isBonus ||
                  availableTimeslotsResponse.treatmentInvolvedPatient
                    .timeslotInvolvedPatients.length >= maxTreatmentAmount ||
                  remainingTreatments <= 0
                }
                sx={{ height: 25, width: 25 }}
                onClick={handleAdd}
              >
                <Add sx={{ height: 20, width: 20 }} />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
        {timeslotInvolvedPatients.map((timeslotInvolvedPatient, index) => {
          return (
            <TabPanel value={tab} index={index + 1}>
              <Grid xs={12} direction="column" overflow={"hidden"}>
                <MultiTimeslotSelection
                  index={index}
                  timeslotInvolvedPatient={timeslotInvolvedPatient}
                  availableTimeslotsResponse={availableTimeslotsResponse}
                />
              </Grid>
            </TabPanel>
          );
        })}
        <TimeslotInfo />
      </Grid>
      <TimeslotActions
        onSave={handleSave}
        onClose={onClose}
        disable={disable}
        isLoading={disable}
      />
    </Grid>
  );
};
