import { QuestionListItemType } from "./types";

export function getQuestionRange(data: QuestionListItemType[]): {
  min: number;
  max: number;
} {
  // Initialize variables to store the sum of minimum and maximum answer weights
  let totalMinWeight = 0;
  let totalMaxWeight = 0;

  // Iterate through the questions and their answers
  for (const question of data) {
    let questionMinWeight = Infinity; // Initialize with a high value
    let questionMaxWeight = -Infinity; // Initialize with a low value // Initialize with a low value
    for (const answer of question.answers) {
      // Parse weight as a float (assuming it's a numeric value)
      const weight = Number(answer.weight);
      // Check if the weight is a valid number
      if (!isNaN(weight)) {
        // Update the minimum and maximum weights for this question
        questionMinWeight = Math.min(questionMinWeight, weight);
        questionMaxWeight = Math.max(questionMaxWeight, weight);
      }
    }

    // Add the minimum and maximum weights for this question to the total
    totalMinWeight += questionMinWeight;
    totalMaxWeight += questionMaxWeight;
  }

  // Return an object with the total minimum and maximum weights
  return {
    min: totalMinWeight,
    max: totalMaxWeight,
  };
}
