import { CustomTable } from "app/components/DataTable/components/Table";
import React from "react";

type Props = {
  rows: Array<any>;
  columns: Array<any>;
  Actions?: any;
  editComponent?: any;
  editAction?: any;
  editActionDbl?: any;
  deleteAction?: any;
  actions?: Array<any>;
  scrollClass?: any;
  onRowClick?: any;
  onRowDoubleClick?: any;
  setQuery?: (name: string, value: string) => void;
  orderField?: any;
  orderDirection?: any;
  loading?: boolean;
  page?: string;
  HeaderActions?: any;
  isPointerCursor?: boolean;
  className?: any;
  disableActions?: any;
  selectedRows?: any;
  selectChange?: (e: any, index: number) => void;
  switchAction?: (e: any, index: number) => void;
  setModalName?: any;
  checkboxChange?: any;
  inlineEdit?: boolean;
  isAllSelected?: boolean;
  selectAllHandler?: any;
  selectCheckboxChangeHandler?: (
    e: any,
    parentIndex: number,
    childIndex: number
  ) => void;
  noDataLabel?: string;
};

const DataTable = ({
  rows,
  columns,
  Actions,
  HeaderActions,
  editComponent,
  editAction,
  editActionDbl,
  deleteAction,
  actions,
  scrollClass,
  onRowClick,
  onRowDoubleClick,
  setQuery,
  orderField,
  orderDirection,
  page,
  loading = false,
  isPointerCursor,
  className,
  disableActions = "",
  selectedRows,
  selectChange,
  setModalName,
  switchAction,
  checkboxChange,
  isAllSelected,
  selectAllHandler,
  inlineEdit,
  selectCheckboxChangeHandler,
  noDataLabel,
}: Props) => {
  const doubleClickEvent = (row, index) => {
    onRowDoubleClick(row, index);
  };

  const onRowTap = (e) => {
    {
      onRowClick && onRowClick(e);
    }
  };
  return (
    <CustomTable
      noDataLabel={noDataLabel}
      loading={loading}
      Actions={Actions}
      editComponent={editComponent}
      doubleClickEvent={doubleClickEvent}
      singleClickEvent={onRowTap}
      rows={rows}
      onRowDoubleClick={onRowDoubleClick}
      editAction={editAction}
      editActionDbl={editActionDbl}
      deleteAction={deleteAction}
      order={orderDirection}
      orderBy={orderField}
      columns={columns}
      actions={actions}
      scrollClass={scrollClass}
      setQuery={setQuery}
      page={page}
      HeaderActions={HeaderActions}
      isPointerCursor={isPointerCursor}
      className={className}
      disableActions={disableActions}
      selectedRows={selectedRows}
      selectChange={selectChange}
      setModalName={setModalName}
      switchAction={switchAction}
      checkboxChange={checkboxChange}
      inlineEdit={inlineEdit}
      isAllSelected={isAllSelected}
      selectAllHandler={selectAllHandler}
      selectCheckboxChangeHandler={selectCheckboxChangeHandler}
    />
  );
};

export default DataTable;
