import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  tableDiv: {
    border: "1px",
  },
  dataTableDiv: {
    maxHeight: "calc(100vh - 290px)",
    //maxWidth: "calc(159vh)",
    overflowY: "auto",
    overflowX: "auto",
    border: "1px solid #C6C9CA",
    marginTop: "0px",
    background: "#F5F8FF",
  },
  deleteModal: {
    position: "absolute",
    background: "#FFFFFF",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "0px",
    boxShadow: "0",
    zIndex: "9999",
    width: "410px",
    height: "230px",
  },
  modalContainer: {
    padding: "20px",
    background: "#F5F8FF",
  },
  instructorListCell: {
    maxWidth: "200px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  btnContainer: {
    display: "flex",
    alignItems: "center",
  },
  cancelBtn: {
    color: "#3D63E6",
    borderColor: "#3D63E6",
    "&:hover": {
      borderColor: "#3D63E6",
    },
    fontFamily: "Cairo",
    fontWeight: 700,
    fontSize: "16px",
    width: "160px",
    height: "50px",
  },
  saveBtn: {
    background: "#3D63E6",
    color: "#ffff",
    "&:hover": {
      background: "#3D63E6",
    },
    fontFamily: "Cairo",
    fontWeight: 700,
    fontSize: "16px",
    width: "160px",
    height: "50px",
  },
  headerModal: {
    color: "#012497",
    fontFamily: "Cairo",
    fontWeight: "800",
    fontSize: "24px",
  },
  deleteTypo: {
    color: "#2A3333",
    fontFamily: "Cairo",
    fontWeight: "400",
    fontSize: "16px",
  },
}));
