import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  modal_input1: {
    flexDirection: "row",
    display: "flex",
  },
  label: {
    fontFamily: "Cairo",
    fontWeight: 400,
    fontSize: "16px",
    color: "#2A3333",
  },
  input_section: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    justifyContent: "space-between",
    marginBottom: "1rem",
  },
  textEnabled: {
    backgroundColor: "#fff",
  },
  textEnabledSelect: {
    background: "#FFF",
    width: "100%",
  },
  loader: {
    width: "30px !important",
    height: "auto !important",
    marginRight: "15px !important",
  },
}));
