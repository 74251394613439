import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { FormHelperText, Grid, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectQuestionnaire } from "store/global/fields/selector";
import { actions } from "store/global/fields/slice";
import { useStyles } from "../styles";
interface TProps {
  value: number;
  xs?: number;
  changeValue: (value: number, name?: string) => void;
  disabled?: boolean;
  showAll: boolean;
  error?: string;
  type: "open" | "mcq" | "all";
}
const QuestionnaireFiled = (props: TProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const styles = useStyles();
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const List = useSelector(selectQuestionnaire);
  useEffect(() => {
    dispatch(actions.getQuestionnaireList({ type: props.type }));
    return () => {};
  }, []);
  const handleChange = (event) => {
    if (List.filter((x) => x.id == event.target.value).length > 0) {
      props.changeValue(
        event.target.value,
        List.filter((x) => x.id == event.target.value)[0].name
      );
    } else {
      props.changeValue(event.target.value, "");
    }
  };
  const hasError = !!props?.error;

  return (
    <Grid item xs={props.xs ? props.xs : 2}>
      <label className={styles.label}>
        {t("ORGANIZATIONS.QUESTIONNAIRE_NAME")}
      </label>
      <Select
        margin="dense"
        disabled={props.disabled}
        fullWidth
        variant="outlined"
        required
        autoComplete={"off"}
        value={props.value}
        placeholder={t("ORGANIZATIONS.SELECT_QUESTIONNAIRE_NAME")}
        className={styles.textEnabled}
        onChange={handleChange}
        onClick={() => setDropDownOpen(props.disabled ? false : !dropDownOpen)}
        open={dropDownOpen}
        IconComponent={KeyboardArrowDownIcon}
        error={hasError}
      >
        {props.showAll && (
          <MenuItem value={"0"}>{t("ORGANIZATIONS.ALL")}</MenuItem>
        )}

        {List.map((item) => (
          <MenuItem
            key={item.id}
            onClick={() => setDropDownOpen(!dropDownOpen)}
            value={item.id}
          >
            {item.name}
          </MenuItem>
        ))}
      </Select>
      {hasError && <FormHelperText error>{props?.error}</FormHelperText>}
    </Grid>
  );
};
export default QuestionnaireFiled;
