export const onValidate = (form) => {
  const errors: { error: any; name: string }[] = [];
  if (form.departmentName.value.length === 0) {
    errors.push({
      name: "departmentName",
      error: "COMMON.DEPARTMENT_REQUIRED",
    });
  }
  if (form.city.value.length === 0) {
    errors.push({
      name: "city",
      error: "COMMON.CITY_REQUIRED",
    });
  }
  if (form.address.value.length === 0) {
    errors.push({
      name: "address",
      error: "COMMON.ADDRESS_REQUIRED",
    });
  }
  return errors;
};

export const isDuplicate = (form, departments) => {
  const errors: { error: any; name: string }[] = [];
  const duplicateDepartmentName = departments?.find(
    (department) =>
      department?.departmentName?.toLowerCase() ===
      form?.departmentName?.value?.toLowerCase()
  );
  if (duplicateDepartmentName) {
    errors.push({
      name: "departmentName",
      error: "COMMON.DUPLICATE_DEPARTMENT",
    });
  }
  return errors;
};
