import { TableCell, styled } from "@mui/material";

export const StyledTableCell = styled(TableCell)({
  fontSize: 15,
  lineHeight: "20px",
  backgroundColor: "transparent",
  color: "#3C4858",
  borderRight: "1px solid #C4C4C4",
  textAlign: "unset",
  "&:first-of-type": {
    borderLeft: "1px solid #C4C4C4",
  },
  whiteSpace: "nowrap",
});
