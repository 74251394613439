import { Box, Button, Grid, Tab, Tabs } from "@mui/material";
import { TabPanel } from "app/components/TabPanel";
import React, { useState } from "react";

import AssignedTreatmentList from "app/containers/AssignedTreament/components/List";
import { useAssignedTreatmentSaga } from "app/containers/AssignedTreament/redux/saga";
import {
  reducer as assignedTreatmentReducer,
  sliceKey as assignedTreatmentSliceKey,
} from "app/containers/AssignedTreament/redux/slice";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import AssignInstructorForm from "app/containers/AssignedTreament/components/AssignInstructorForm";
import {
  selectList,
  selectLoading,
} from "app/containers/AssignedTreament/redux/selector";
import QuestionnaireList from "app/containers/QuestionnaireResults/components/List";
import { useQuestionnaireResultSaga } from "app/containers/QuestionnaireResults/redux/saga";
import {
  reducer as questionnaireReducer,
  sliceKey as questionnaireSliceKey,
} from "app/containers/QuestionnaireResults/redux/slice";
import { useSelector } from "react-redux";
import { selectFeaturePermissions } from "store/global/authPermission/selector";
import { PermissionFeature } from "store/global/authPermission/types";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { useStyles } from "./styles";

interface PatientDetailsProps {
  view: boolean;
  disable: boolean;
  setModalName: any;
  mode: String;
}

const AssignedBankAndQuestionnaireComponent = ({
  view,
  disable,
  setModalName,
  mode,
}: PatientDetailsProps) => {
  const [showAssignInstructor, setShowAssignInstructor] = useState(false);
  useInjectReducer({
    key: assignedTreatmentSliceKey,
    reducer: assignedTreatmentReducer,
  });
  useInjectSaga({
    key: assignedTreatmentSliceKey,
    saga: useAssignedTreatmentSaga,
  });

  useInjectReducer({
    key: questionnaireSliceKey,
    reducer: questionnaireReducer,
  });
  useInjectSaga({
    key: questionnaireSliceKey,
    saga: useQuestionnaireResultSaga,
  });

  const [tabValue, setTabValue] = useState(0);
  const { t } = useTranslation();

  const handleTabChange = (event, tabValue) => {
    setTabValue(tabValue);
  };
  const { id } = useParams();
  const patientId = Number(id);
  const { selectedIds } = useSelector(selectList);
  const isLoading = useSelector(selectLoading);
  const styles = useStyles();

  const featurePermissions = useSelector(selectFeaturePermissions);
  const assignedTreatmentAssigning =
    featurePermissions[PermissionFeature.ASSIGNED_TREATNEMTS_A];
  const assignedTreatmentScheduling =
    featurePermissions[PermissionFeature.ASSIGNED_TREATNEMTS_B];

  const questionerResultsPermission =
    featurePermissions[PermissionFeature.QUESTIONNAIRES_RESULTS];

  const isAssignedTreatmentVisible =
    assignedTreatmentAssigning.hasFullAccess ||
    assignedTreatmentAssigning.hasReadAccess ||
    assignedTreatmentScheduling.hasFullAccess ||
    assignedTreatmentScheduling.hasReadAccess;

  const questionerResultsVisible =
    questionerResultsPermission.hasFullAccess ||
    questionerResultsPermission.hasReadAccess;

  return (
    <Grid
      container
      spacing={3}
      md={12}
      sx={{
        width: "100%",
        marginTop: "20px",
      }}
    >
      <Grid
        item
        container
        spacing={2}
        md={12}
        sx={{ padding: "10px 0px 0px 15px" }}
      >
        <Box sx={{ width: "100%" }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="Table"
            textColor="secondary"
            TabIndicatorProps={{ style: { background: "#012497" } }}
          >
            {isAssignedTreatmentVisible && (
              <Tab
                label={<span>{t("PATIENTS.ASSIGNED_BANK_TREATMENTS")}</span>}
                style={{
                  fontWeight: tabValue == 0 ? "bold" : "500",
                  color: tabValue == 0 ? "#012497" : "#2A3333",
                  fontSize: "16px",
                }}
              />
            )}
            {questionerResultsVisible && (
              <Tab
                label={<span>{t("PATIENTS.QUESTIONNAIRE_RESULTS")}</span>}
                style={{
                  fontWeight: tabValue == 1 ? "bold" : "500",
                  color: tabValue == 1 ? "#012497" : "#2A3333",
                  fontSize: "16px",
                }}
              />
            )}
          </Tabs>
          {isAssignedTreatmentVisible && (
            <TabPanel value={tabValue} index={0}>
              <>
                <AssignedTreatmentList
                  disabled={disable}
                  patientId={patientId}
                />

                {!!patientId && selectedIds?.length > 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "20px",
                    }}
                  >
                    <Button
                      onClick={() => setShowAssignInstructor(true)}
                      className={styles.button}
                      variant="outlined"
                      disabled={isLoading}
                    >
                      {" "}
                      {t("BUTTONS.ASSIGN_INSTRUCTOR")}{" "}
                    </Button>
                  </Box>
                )}
              </>
            </TabPanel>
          )}
          {questionerResultsVisible && (
            <TabPanel value={tabValue} index={1}>
              <QuestionnaireList patientId={patientId} />
            </TabPanel>
          )}
        </Box>
      </Grid>
      <AssignInstructorForm
        open={showAssignInstructor}
        handleClose={() => setShowAssignInstructor(false)}
        patientId={patientId}
      />
    </Grid>
  );
};

export default AssignedBankAndQuestionnaireComponent;
