import React from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { userRepoSaga } from "./containers/Auth/saga";
import { reducer, sliceKey } from "./containers/Auth/slice";

import { globalFieldsSaga } from "store/global/fields/saga";
import {
  reducer as fieldsReducer,
  sliceKey as fieldsSliceKey,
} from "store/global/fields/slice";

import {
  reducer as authReducer,
  sliceKey as authSliceKey,
} from "store/global/authPermission/slice";

import { authFieldsSaga } from "store/global/authPermission/saga";
import { selectDirection } from "../styles/theme/slice";
import { AuthPermission } from "./components/AuthPermission";
import { UnsupportedScreen } from "./components/UnsupportedScreen";
import { selectIsLoggedIn } from "./containers/Auth/selectors";
import {
  reducer as profileReducer,
  profileSliceKey,
} from "./containers/Profile/redux/slice";
import { AuthRoutes } from "./routes/auth-routes";
import { InterNalRoutes } from "./routes/internal-routes";
import { PagesRoutes } from "./routes/pages-routes";

export function App() {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: userRepoSaga });
  useInjectReducer({ key: profileSliceKey, reducer: profileReducer });

  useInjectReducer({ key: fieldsSliceKey, reducer: fieldsReducer });
  useInjectSaga({ key: fieldsSliceKey, saga: globalFieldsSaga });

  useInjectReducer({ key: authSliceKey, reducer: authReducer });
  useInjectSaga({ key: authSliceKey, saga: authFieldsSaga });

  const isLoggedIn = useSelector(selectIsLoggedIn);

  const direction = useSelector(selectDirection);

  return (
    <BrowserRouter>
      <UnsupportedScreen />
      <Helmet titleTemplate="%s - Medical Care" defaultTitle="Medical Care">
        <meta name="description" content="A Medical Care application" />
      </Helmet>
      <div dir={direction}>
        <Routes>
          <Route path="/auth/*" element={<AuthRoutes />} />
          <Route path="/pages/*" element={<PagesRoutes />} />
          {isLoggedIn ? <Route path="/*" element={<InterNalRoutes />} /> : null}
          <Route
            path="*"
            element={
              <Navigate to={isLoggedIn ? "/dashboard" : "/auth/login"} />
            }
          />
        </Routes>
      </div>
      {isLoggedIn ? <AuthPermission /> : ""}
    </BrowserRouter>
  );
}
