import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "types";
import { TimeSlotState } from "../types";

const selectDomain = (state?: RootState) =>
  state?.timeslotListState || TimeSlotState;

export const selectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const selectList = createSelector([selectDomain], (state) => state.list);
export const selectQuery = createSelector(
  [selectDomain],
  (state) => state.query
);
// export const selectEditUser = createSelector(
//   [selectDomain],
//   (state) => state.form.user
// );
export const selectOrganization = createSelector(
  [selectDomain],
  (state) => state.organization
);

export const selectUserQualification = createSelector(
  [selectDomain],
  (state) => state.qualification
);
export const selectUserPermission = createSelector(
  [selectDomain],
  (state) => state.patientPermission
);
export const selectDepartmentOptions = createSelector(
  [selectDomain],
  (state) => state.departments
);
export const selectBanksOptions = createSelector(
  [selectDomain],
  (state) => state.banks
);

export const selectError = createSelector(
  [selectDomain],
  (state) => state.error
);
