import { Add } from "@mui/icons-material";
import { Fab, Grid } from "@mui/material";
import DataTable from "app/components/DataTable";
import { TabPanel } from "app/components/TabPanel";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { MAX_LIMIT } from "utils/helpers";
import toastService from "utils/toast";
import AddTreatmentQuestionaire from "../../components/AddTreatmentQuestionaire";
import {
  selectEditTreatment,
  selectList,
  selectLoading,
  selectQuery,
  selectQuestionnaireModal,
} from "../../redux/selector";
import { actions } from "../../redux/slice";

type Props = {
  mode: "new" | "edit" | "view";
  value: number;
  disable: boolean;
  disabledOnAssociation: boolean;
};
const QuestionnaireList = ({
  mode,
  value,
  disable,
  disabledOnAssociation,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [onRow, setOnRow] = useState(true);
  const [edit, setEdit] = useState(mode);

  const [editIndex, setEditIndex] = useState(0);
  const listitems = useSelector(selectList);
  const questionnaireModal = useSelector(selectQuestionnaireModal);

  const { items } = listitems;

  const { orderField, orderDirection } = useSelector(selectQuery);

  const form = useSelector(selectEditTreatment);

  const setPageQuery = (name: string, value: any) => {
    dispatch(actions.setQuery({ name, value }));
  };
  const columns = [
    {
      id: "beforeAfter",
      label: t("TABLES.BEFORE_AFTER"),
      sortValue: "selector.beforeafter",
      align: "left",
    },
    {
      id: "hours",
      label: t("TABLES.HOURS"),
      sortValue: "selector.hours",
      align: "left",
    },
    {
      id: "questionaireName",
      label: t("TABLES.QUESTIONAIRE_NAME"),
      sortValue: "selector.questionaireName",
      align: "left",
    },
    {
      id: "session_id",
      label: t("TABLES.SESSION"),
      sortValue: "selector.session_id",
      align: "left",
    },
  ];
  const dispatch = useDispatch();
  const loading = useSelector(selectLoading);

  const handleModalQuestionaire = () => {
    setEdit("new");
    if (items.length < 10) {
      setOnRow(false);
      dispatch(actions.questionnaireModalToggal());
    } else {
      toastService.error("Only 10 Treatment Questionnaire Allowed");
    }
  };
  const handleModalQuestionaireClose = () => {
    dispatch(actions.questionnaireModalToggal());
    dispatch(actions.resetQuestionaireForm());
  };

  function handleTreatmentQuestionnaireDeletion(row, index) {
    if (mode !== "view") {
      const { id } = row;
      handleDeleteClose("delete", "", id, index);
    }
  }

  const handleDeleteClose = (
    eventType,
    type?: string,
    id?: number,
    index?: number
  ) => {
    if (eventType === "delete") {
      if (id == 0) {
        dispatch(actions.deleteTreatmentQuestionnaireItem(index));
      } else {
        dispatch(
          actions.deleteTreatmentQuestionnaireRow({
            id: id,
            treatmentId: id,
          })
        );
      }
    }
  };

  const rowDoubleQuestionnaireAction = (e, index) => {
    setOnRow(true);
    for (let i in e) {
      dispatch(
        actions.updateFormValue({ key: `questionaire.${i}`, value: e[i] })
      );
    }

    setOnRow(true);
    navigate(`/treatment-types/${id}/edit`);
    setEditIndex(index);
    setEdit("edit");
    dispatch(actions.questionnaireModalToggal());
  };

  useEffect(() => {
    if (mode !== "new") {
      if (id) {
        dispatch(actions.getTretament(id));

        dispatch(
          actions.getTreatmentContentList({
            treatmentId: id,
          })
        );
        dispatch(
          actions.getTreatmentQuestionnaireList({
            treatmentId: id,
          })
        );
      }
    }
  }, [id]);

  return (
    <>
      <TabPanel value={value} index={1}>
        <Grid container spacing={0} md={12} sx={{ paddingTop: "0px" }}>
          <Grid item xs={8}>
            <DataTable
              loading={loading}
              orderDirection={orderDirection}
              orderField={orderField}
              setQuery={setPageQuery}
              rows={items.filter((x) => x.type !== "delete")}
              columns={columns}
              Actions={disable ? "" : "Delete,Edit"}
              editAction={rowDoubleQuestionnaireAction}
              deleteAction={handleTreatmentQuestionnaireDeletion}
              disableActions={
                disable || disabledOnAssociation ? "Delete,Edit" : ""
              }
            />
          </Grid>
        </Grid>
        {items.length < MAX_LIMIT && (
          <Grid
            xs={8}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: "7px",
            }}
          >
            <Fab
              size="medium"
              color="secondary"
              aria-label="new"
              sx={{ marginTop: 2 }}
              disabled={disable}
              onClick={handleModalQuestionaire}
            >
              <Add />
            </Fab>
          </Grid>
        )}
      </TabPanel>

      <AddTreatmentQuestionaire
        treatmentId={id}
        openModal={questionnaireModal}
        handleModalClose={handleModalQuestionaireClose}
        disable={disable}
        mode={edit}
        noofSession={form.noOfSession.value}
        editIndex={editIndex}
      ></AddTreatmentQuestionaire>
    </>
  );
};
export default QuestionnaireList;
