import { CircularProgress, Grid } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { selectFeaturePermissionsLoading } from "store/global/authPermission/selector";

export const NoPermission = () => {
  const isLoading = useSelector(selectFeaturePermissionsLoading);
  if (isLoading) {
    return (
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: 500,
        }}
      >
        <CircularProgress />
      </Grid>
    );
  }
  return (
    <>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: 500,
          flexDirection: "column",
        }}
      >
        <h3 style={{ textAlign: "center" }}>Permission Denied</h3>
        <h5 style={{ textAlign: "center" }}>Please Contact Admin</h5>
      </Grid>
    </>
  );
};
