import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "types";
import { UserState } from "../types";

const selectDomain = (state?: RootState) => state?.usersListState || UserState;

export const selectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const selectList = createSelector([selectDomain], (state) => state.list);
export const selectQuery = createSelector(
  [selectDomain],
  (state) => state.query
);
export const selectEditUser = createSelector(
  [selectDomain],
  (state) => state.form.user
);

export const selectOrganization = createSelector(
  [selectDomain],
  (state) => state.organization
);
export const selectUserGroup = createSelector(
  [selectDomain],
  (state) => state.userGroup
);
export const selectUserQualification = createSelector(
  [selectDomain],
  (state) => state.qualification
);
export const selectUserPermission = createSelector(
  [selectDomain],
  (state) => state.patientPermission
);
export const selectDepartmentOptions = createSelector(
  [selectDomain],
  (state) => state.departments
);
export const selectBanksOptions = createSelector(
  [selectDomain],
  (state) => state.banks
);
export const selectTreatment = createSelector(
  [selectDomain],
  (state) => state.treatment
);
export const selectError = createSelector(
  [selectDomain],
  (state) => state.error
);
export const selectInstructor = createSelector(
  [selectDomain],
  (state) => state.instructor
);
export const selectActualInstructor = createSelector(
  [selectDomain],
  (state) => state.actualInstructor
);
export const selectCity = createSelector([selectDomain], (state) => state.city);
export const selectQuestionnaire = createSelector(
  [selectDomain],
  (state) => state.questionnaire
);
export const selectFrom = createSelector(
  [selectDomain],
  (state) => state.form.user
);
