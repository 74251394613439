import { CalendarMonth } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import BankFiled from "app/components/FieldComponent/Bank";
import CityField from "app/components/FieldComponent/City";
import DepartmentFiled from "app/components/FieldComponent/Department";
import OrganizationField from "app/components/FieldComponent/Organization";
import ReligionField from "app/components/FieldComponent/Religion";
import { usePatientSaga } from "app/containers/Patients/redux/saga";
import {
  selectIsPatientFormBankStatusDone,
  selectLoading,
  selectPatients,
} from "app/containers/Patients/redux/selector";
import {
  actions,
  reducer,
  sliceKey,
} from "app/containers/Patients/redux/slice";
import React from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { selectFeaturePermissions } from "store/global/authPermission/selector";
import { PermissionFeature } from "store/global/authPermission/types";
import { validateAndFormatIsraeliMobileNumber } from "styles/theme/utils";
import { useStyles } from "./styles";

interface Props {
  mode: string;
  id?: string;
}

const PatientDetailsForm = ({ mode, id }: Props) => {
  const styles = useStyles();
  const { t, i18n } = useTranslation();
  const patientsDetails = useSelector(selectPatients);
  const isPatientFormBankDone = useSelector(selectIsPatientFormBankStatusDone);

  const isLoading = useSelector(selectLoading);
  useInjectReducer({ key: sliceKey, reducer });
  useInjectSaga({ key: sliceKey, saga: usePatientSaga });

  const dispatch = useDispatch();

  const handelChangeValue = (val: any, name: string) => {
    const key = `patient.${name}`;
    dispatch(actions.updateFormValue({ key, value: val }));
  };
  const viewMode = mode == "view" || isPatientFormBankDone;
  const statusDisabled = viewMode || mode === "new";
  const featurePermissions = useSelector(selectFeaturePermissions);
  const detailsStatusPermission =
    featurePermissions[PermissionFeature.PATIENT_DETAILS_STATUS];
  const statusVisible =
    detailsStatusPermission.hasFullAccess ||
    detailsStatusPermission.hasReadAccess;

  return (
    <div>
      <Grid
        container
        spacing={3}
        sx={{
          padding: "5px 0px 10px 0px",
          justifyContent: "space-between",
          width: "80%",
        }}
      >
        <OrganizationField
          disabled={mode == "edit" || viewMode || isLoading}
          value={patientsDetails.organizationId.value}
          xs={3}
          changeValue={(value) => {
            handelChangeValue(value, "organizationId");
          }}
          showAll={false}
          activeRequired={!id}
        ></OrganizationField>

        <DepartmentFiled
          disabled={mode == "edit" || viewMode || isLoading}
          xs={3}
          value={patientsDetails.departmentId.value}
          changeValue={(value) => {
            handelChangeValue(value, "departmentId");
          }}
          orgId={patientsDetails.organizationId.value}
          showAll={false}
        ></DepartmentFiled>

        <BankFiled
          disabled={mode == "edit" || viewMode || isLoading}
          value={patientsDetails.bankId.value}
          xs={3}
          organizationId={patientsDetails.organizationId.value}
          departmentId={patientsDetails.departmentId.value}
          changeValue={(value) => {
            handelChangeValue(value, "bankId");
          }}
          showAll={false}
          includeStatus={isPatientFormBankDone ? undefined : ["New", "Running"]}
        ></BankFiled>

        <Grid item xs={3}>
          {statusVisible && (
            <>
              <Typography noWrap className={styles.label}>
                {t("PATIENTS.PATIENT_STATUS")}
              </Typography>
              <Select
                disabled={
                  statusDisabled ||
                  isLoading ||
                  detailsStatusPermission.hasReadAccess
                }
                margin="dense"
                id="patientStatus"
                name="patientStatus"
                fullWidth
                variant="outlined"
                required
                autoComplete={"off"}
                dir={i18n.dir()}
                MenuProps={{
                  dir: i18n.dir(),
                }}
                value={patientsDetails.patientStatus.value || "New"}
                className={styles.patientDataLineOneDropDownStyle}
                onChange={(e) =>
                  handelChangeValue(e.target.value, e.target.name)
                }
                IconComponent={KeyboardArrowDownIcon}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return (
                      <p style={{ color: "#A4A9AB", margin: 0 }}>
                        {t("PATIENTS.OPTIONS.CHOOSE_STATUS")}
                      </p>
                    );
                  }
                  return t(`PATIENTS.OPTIONS.${selected?.toUpperCase()}`);
                }}
              >
                <MenuItem value={"New"}>{t("PATIENTS.OPTIONS.NEW")}</MenuItem>
                <MenuItem value={"Active"}>
                  {t("PATIENTS.OPTIONS.ACTIVE")}
                </MenuItem>
                <MenuItem value={"Done"}>{t("PATIENTS.OPTIONS.DONE")}</MenuItem>
              </Select>
            </>
          )}
        </Grid>
      </Grid>

      <Grid
        container
        spacing={3}
        sx={{
          padding: "5px 0px 10px 0px",
          justifyContent: "space-between",
          width: "80%",
        }}
      >
        <Grid item xs={3}>
          <Typography noWrap className={styles.label}>
            {t("PATIENTS.FIRST_NAME")}
          </Typography>
          <TextField
            disabled={viewMode || isLoading}
            margin="dense"
            fullWidth
            variant="outlined"
            required
            value={patientsDetails.firstName.value}
            autoComplete={"off"}
            name="firstName"
            placeholder={t("PATIENTS.ENTER_FIRST_NAME")}
            onChange={(e) => handelChangeValue(e.target.value, e.target.name)}
            className={styles.textEnabled}
          />
        </Grid>
        <Grid item xs={3}>
          <Typography noWrap className={styles.label}>
            {t("PATIENTS.LAST_NAME")}
          </Typography>
          <TextField
            disabled={viewMode || isLoading}
            variant="outlined"
            fullWidth
            margin="dense"
            required
            value={patientsDetails.lastName.value}
            autoComplete={"off"}
            placeholder={t("PATIENTS.ENTER_LAST_NAME")}
            className={styles.textEnabled}
            onChange={(e) => handelChangeValue(e.target.value, e.target.name)}
            name="lastName"
          />
        </Grid>
        <Grid item xs={3}>
          <Typography noWrap className={styles.label}>
            {t("PATIENTS.EMAIL")}
          </Typography>
          <TextField
            disabled={viewMode || isLoading}
            variant="outlined"
            fullWidth
            margin="dense"
            required
            value={patientsDetails.email.value}
            autoComplete={"off"}
            placeholder={t("PATIENTS.ENTER_EMAIL")}
            className={styles.textEnabled}
            name="email"
            onChange={(e) => handelChangeValue(e.target.value, e.target.name)}
          />
        </Grid>

        <Grid item xs={3}>
          <Typography noWrap className={styles.label}>
            {t("PATIENTS.PHONE_NUMBER")}
          </Typography>
          <TextField
            disabled={viewMode || isLoading}
            variant="outlined"
            fullWidth
            margin="dense"
            required
            error={
              patientsDetails.cellular.value &&
              !validateAndFormatIsraeliMobileNumber(
                patientsDetails.cellular.value
              ).isValid
            }
            autoComplete={"off"}
            name={"cellular"}
            value={
              validateAndFormatIsraeliMobileNumber(
                patientsDetails.cellular.value
              ).formattedNumber
            }
            inputProps={{ maxLength: 10 }}
            placeholder={t("PATIENTS.ENTER_MOBILE_NUMBER")}
            className={styles.textEnabled}
            FormHelperTextProps={{
              style: i18n.dir() === "rtl" ? { textAlign: "right" } : {},
            }}
            helperText={
              validateAndFormatIsraeliMobileNumber(
                patientsDetails.cellular.value
              ).isValid
                ? ""
                : t("COMMON.FORMAT_MOBILE_NUMBER")
            }
            onChange={(e) => {
              handelChangeValue(e.target.value, e.target.name);
            }}
          />
        </Grid>
      </Grid>

      <Grid
        container
        spacing={3}
        sx={{
          padding: "5px 0px 10px 0px",
          justifyContent: "flex-start",
          width: "80%",
        }}
      >
        <Grid item xs={3}>
          <Typography noWrap className={styles.label}>
            {t("PATIENTS.SEX")}
          </Typography>
          <Select
            disabled={viewMode || isLoading}
            margin="dense"
            fullWidth
            variant="outlined"
            required
            autoComplete={"off"}
            value={patientsDetails.sex.value}
            className={styles.textEnabledSelect}
            name={"sex"}
            onChange={(e) => handelChangeValue(e.target.value, e.target.name)}
            IconComponent={KeyboardArrowDownIcon}
            MenuProps={{
              dir: i18n.dir(),
            }}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return (
                  <p style={{ color: "#A4A9AB", margin: 0 }}>
                    {t("PATIENTS.OPTIONS.CHOOSE_SEX")}
                  </p>
                );
              }
              return t(`PATIENTS.OPTIONS.${selected?.toUpperCase()}`);
            }}
          >
            <MenuItem value={"male"}>
              <Typography>{t("PATIENTS.OPTIONS.MALE")}</Typography>
            </MenuItem>
            <MenuItem value={"female"}>
              <Typography>{t("PATIENTS.OPTIONS.FEMALE")}</Typography>
            </MenuItem>
            <MenuItem value={"other"}>
              <Typography>{t("PATIENTS.OPTIONS.OTHER")}</Typography>
            </MenuItem>
          </Select>
        </Grid>

        <Grid item xs={3}>
          <Typography noWrap className={styles.label}>
            {t("PATIENTS.BIRTH_DATE")}
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              disabled={viewMode || isLoading}
              className={styles.textEnabled}
              views={["day", "month", "year"]}
              value={patientsDetails.birthDate.value}
              maxDate={new Date()}
              onChange={(newValue: any) => {
                handelChangeValue(newValue, "birthDate");
              }}
              components={{
                OpenPickerIcon: CalendarMonth,
              }}
              OpenPickerButtonProps={{
                style: { color: viewMode ? "#6C7A7A" : "#012397" },
              }}
              inputFormat="dd/MM/yyyy"
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  disabled={viewMode}
                  margin="dense"
                  helperText={null}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: "__/__/____",
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={3}>
          <Typography noWrap className={styles.label}>
            {t("PATIENTS.STREET")}
          </Typography>
          <TextField
            disabled={viewMode || isLoading}
            variant="outlined"
            fullWidth
            margin="dense"
            required
            autoComplete={"off"}
            value={patientsDetails.street.value}
            name={"street"}
            onChange={(e) => handelChangeValue(e.target.value, e.target.name)}
            placeholder="Enter Street"
            className={styles.textEnabled}
          />
        </Grid>

        <CityField
          disabled={viewMode || isLoading}
          changeValue={(e, name) => {
            handelChangeValue(name, "city");
            handelChangeValue(e, "cityId");
          }}
          value={patientsDetails.cityId.value}
          xs={3}
        ></CityField>
      </Grid>

      <Grid
        container
        spacing={3}
        sx={{
          padding: "5px 0px 10px 0px",
          justifyContent: "space-between",
          width: "80%",
        }}
      >
        <Grid item xs={3}>
          <Typography noWrap className={styles.label}>
            {t("PATIENTS.STATUS")}
          </Typography>
          <Select
            disabled={viewMode || isLoading}
            margin="dense"
            fullWidth
            variant="outlined"
            required
            name={"status"}
            onChange={(e) => {
              handelChangeValue(e.target.value, e.target.name);
            }}
            autoComplete={"off"}
            value={patientsDetails.status.value}
            className={styles.textEnabledSelect}
            IconComponent={KeyboardArrowDownIcon}
          >
            <MenuItem value={"single"}>{t("PATIENTS.OPTIONS.SINGLE")}</MenuItem>
            <MenuItem value={"known in public"}>
              {t("PATIENTS.OPTIONS.KNOWN_IN_PUBLIC")}
            </MenuItem>
            <MenuItem value={"married"}>
              {t("PATIENTS.OPTIONS.MARRIED")}
            </MenuItem>
            <MenuItem value={"widowed"}>
              {t("PATIENTS.OPTIONS.WIDOWED")}
            </MenuItem>
            <MenuItem value={"divorced"}>
              {t("PATIENTS.OPTIONS.DIVORCED")}
            </MenuItem>
            <MenuItem value={"separated"}>
              {t("PATIENTS.OPTIONS.SEPARATED")}
            </MenuItem>
          </Select>
        </Grid>

        <Grid item xs={3}>
          <Typography noWrap className={styles.label}>
            {t("PATIENTS.HOW_LONG_IS_CURRENT_STATUS")}
          </Typography>
          <TextField
            disabled={viewMode || isLoading}
            variant="outlined"
            fullWidth
            margin="dense"
            required
            type={"number"}
            autoComplete={"off"}
            value={patientsDetails.howLongInCurrentStatus.value}
            placeholder={t("PATIENTS.ENTER")}
            name={"howLongInCurrentStatus"}
            className={styles.textEnabled}
            onChange={(e) => {
              const value = e.target.value;
              const regex = /^\w+$/;
              const data = regex.test(value)
                ? value
                : value.replace(/^\w+$/g, "");
              handelChangeValue(data, e.target.name);
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <Typography noWrap className={styles.label}>
            {t("PATIENTS.NUMBER_OF_CHILDREN")}
          </Typography>
          <TextField
            disabled={viewMode || isLoading}
            variant="outlined"
            fullWidth
            margin="dense"
            required
            autoComplete={"off"}
            placeholder={t("PATIENTS.ENTER")}
            value={patientsDetails.numberOfChildern.value}
            className={styles.textEnabled}
            name={"numberOfChildern"}
            onChange={(e) => {
              const value = e.target.value;
              const regex = /^[0-9\b]+$/;
              const data = regex.test(value)
                ? value
                : value.replace(/[^0-9]/g, "");
              handelChangeValue(data, e.target.name);
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <Typography noWrap className={styles.label}>
            {t("PATIENTS.EDUCATION")}
          </Typography>
          <Select
            disabled={viewMode || isLoading}
            margin="dense"
            fullWidth
            variant="outlined"
            required
            autoComplete={"off"}
            className={styles.textEnabledSelect}
            IconComponent={KeyboardArrowDownIcon}
            value={patientsDetails.education.value}
            name={"education"}
            MenuProps={{
              dir: i18n.dir(),
            }}
            onChange={(e) => handelChangeValue(e.target.value, e.target.name)}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return (
                  <p style={{ color: "#A4A9AB", margin: 0 }}>
                    {t("PATIENTS.OPTIONS.CHOOSE_EDUCATION")}
                  </p>
                );
              }
              return t(
                `PATIENTS.OPTIONS.${selected
                  ?.replaceAll(" ", "_")
                  ?.toUpperCase()}`
              );
            }}
          >
            <MenuItem value={"No Formal Education"}>
              {t("PATIENTS.OPTIONS.NO_FORMAL_EDUCATION")}
            </MenuItem>
            <MenuItem value={"Primary school"}>
              {t("PATIENTS.OPTIONS.PRIMARY_SCHOOL")}
            </MenuItem>
            <MenuItem value={"High school graduate"}>
              {t("PATIENTS.OPTIONS.HIGH_SCHOOL_GRADUATE")}
            </MenuItem>
            <MenuItem value={"BA degree"}>
              {t("PATIENTS.OPTIONS.BA_DEGREE")}
            </MenuItem>
            <MenuItem value={"MA degree"}>
              {t("PATIENTS.OPTIONS.MA_DEGREE")}
            </MenuItem>
            <MenuItem value={"Doctorate or higher"}>
              {t("PATIENTS.OPTIONS.DOCTORATE_OR_HIGHER")}
            </MenuItem>
          </Select>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={3}
        sx={{
          padding: "5px 0px 10px 0px",
          justifyContent: "space-between",
          width: "80%",
        }}
      >
        <Grid item xs={3}>
          <ReligionField
            label={t("PATIENTS.RELIGION")}
            value={patientsDetails.religion.value}
            disabled={viewMode || isLoading}
            onChange={(e) => handelChangeValue(e.target.value, "religion")}
          />
        </Grid>

        <Grid item xs={3}>
          <Typography noWrap className={styles.label}>
            {t("PATIENTS.MONTHS_IN_CURRENT_ORGANIZATION")}
          </Typography>
          <TextField
            disabled={viewMode || isLoading}
            variant="outlined"
            fullWidth
            margin="dense"
            required
            autoComplete={"off"}
            value={patientsDetails.monthsInCurrentOrganization.value}
            placeholder={t("PATIENTS.ENTER")}
            name={"monthsInCurrentOrganization"}
            className={styles.textEnabled}
            onChange={(e) => {
              const value = e.target.value;
              const regex = /^[0-9\b]+$/;
              const data = regex.test(value)
                ? value
                : value.replace(/[^0-9]/g, "");
              handelChangeValue(data, e.target.name);
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <Typography noWrap className={styles.label}>
            {t("PATIENTS.MONTHS_IN_CURRENT_JOB")}
          </Typography>
          <TextField
            disabled={viewMode || isLoading}
            variant="outlined"
            fullWidth
            margin="dense"
            required
            autoComplete={"off"}
            value={patientsDetails.monthsInCurrentJobs.value}
            placeholder={t("PATIENTS.ENTER")}
            className={styles.textEnabled}
            name={"monthsInCurrentJobs"}
            onChange={(e) => {
              const value = e.target.value;
              const regex = /^[0-9\b]+$/;
              const data = regex.test(value)
                ? value
                : value.replace(/[^0-9]/g, "");
              handelChangeValue(data, e.target.name);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Typography noWrap className={styles.label}>
            {t("PATIENTS.DAILY_WORK_HOURS")}
          </Typography>
          <Select
            disabled={viewMode || isLoading}
            margin="dense"
            fullWidth
            variant="outlined"
            required
            autoComplete={"off"}
            className={styles.textEnabledSelect}
            value={patientsDetails.dailyWorkJobs.value}
            IconComponent={KeyboardArrowDownIcon}
            name={"dailyWorkJobs"}
            MenuProps={{
              dir: i18n.dir(),
            }}
            onChange={(e) => handelChangeValue(e.target.value, e.target.name)}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return (
                  <p style={{ color: "#A4A9AB", margin: 0 }}>
                    {t("PATIENTS.OPTIONS.CHOOSE")}
                  </p>
                );
              }
              return selected;
            }}
          >
            <MenuItem value={"0-4"}>0-4</MenuItem>
            <MenuItem value={"5-8"}>5-8</MenuItem>
            <MenuItem value={"8-12"}>8-12</MenuItem>
            <MenuItem value={"12+"}>12+</MenuItem>
          </Select>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
        sx={{
          padding: "5px 0px 10px 0px",
          justifyContent: "flex-start",
          width: "80%",
        }}
      >
        <Grid item xs={3}>
          <Typography noWrap className={styles.label}>
            {t("PATIENTS.ANY_BAD_HISTORY")}
          </Typography>
          <Select
            disabled={viewMode || isLoading}
            margin="dense"
            fullWidth
            variant="outlined"
            required
            autoComplete={"off"}
            className={styles.textEnabledSelect}
            IconComponent={KeyboardArrowDownIcon}
            value={patientsDetails.anyBadHistory.value}
            name={"anyBadHistory"}
            onChange={(e) => handelChangeValue(e.target.value, e.target.name)}
            MenuProps={{
              dir: i18n.dir(),
            }}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return (
                  <p style={{ color: "#A4A9AB", margin: 0 }}>
                    {t("PATIENTS.OPTIONS.CHOOSE")}
                  </p>
                );
              }
              return t(`PATIENTS.OPTIONS.${selected?.toUpperCase()}`);
            }}
          >
            <MenuItem value={"Yes"}>{t("PATIENTS.OPTIONS.YES")}</MenuItem>
            <MenuItem value={"No"}>{t("PATIENTS.OPTIONS.NO")}</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={3}>
          <Typography noWrap className={styles.label}>
            {t("PATIENTS.TIME_ZONE")}
          </Typography>
          <TextField
            disabled
            variant="outlined"
            fullWidth
            margin="dense"
            autoComplete={"off"}
            value={patientsDetails.timeZone}
            placeholder={t("PATIENTS.ENTER")}
            className={styles.textEnabled}
            name={"timeZone"}
            onChange={(e) => {}}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default PatientDetailsForm;
