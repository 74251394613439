import CloseIcon from "@mui/icons-material/Close";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  Box,
  Grid,
  IconButton,
  Popover,
  Tooltip,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import React from "react";
import { Link } from "react-router-dom";
import { useStyles } from "./styles";

interface TProps {
  onClose: () => void;
  anchorEl?: any;
  eventInfo: any;
  calendarTitle: string;
  startDate: string;
  endDate: string;
}

function CustomSVG({ color, className }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={12}
      height={12}
      fill={color}
    >
      <circle cx="50%" cy="50%" r="50%" />
    </svg>
  );
}

function DetailsView(props: TProps) {
  const styles = useStyles();
  const open = !!props.anchorEl;
  if (!open) {
    return null;
  }
  const eventColor = props.eventInfo.timeslot.eventColor;
  const listLength = props.eventInfo.assignedTreatment.length;
  const title = `${props.eventInfo?.timeslot?.organization?.name} /
  ${props.eventInfo?.timeslot?.department?.departmentName} /
  ${props.eventInfo?.timeslot?.treatment?.name} /
  ${props.eventInfo?.timeslot?.treatment?.type}`;
  return (
    <Popover
      anchorEl={props.anchorEl}
      open={open}
      onClose={props.onClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      PaperProps={{ className: styles.wrap }}
    >
      <Grid className={styles.container}>
        <Grid
          sx={{ backgroundColor: eventColor }}
          className={clsx(styles.header)}
        >
          <Typography className={styles.title}>
            {props?.startDate}-{props?.endDate} ({props.eventInfo.meetingId})
          </Typography>
          <IconButton className={styles.close} onClick={props.onClose}>
            <CloseIcon />
          </IconButton>
          <Grid className={styles.time}>
            <Tooltip title={title}>
              <Typography>{title}</Typography>
            </Tooltip>

            <Link
              style={{
                marginRight: 10,
                marginLeft: 10,
                textDecoration: "none",
                cursor: "pointer",
              }}
              target="_blank"
              className={styles.button}
              to={`/time-slots/time-slots-actions/${props.eventInfo?.timeslot?.id}/edit`}
            >
              Edit
            </Link>
          </Grid>
        </Grid>

        <Grid className={styles.body}>
          <>
            {listLength ? (
              <ol className={styles.calProgress}>
                {props.eventInfo.assignedTreatment.map((m) => {
                  const name = `${m?.involvedPatient?.patient?.firstName} 
                  ${m?.involvedPatient?.patient?.lastName}`;
                  return (
                    <li
                      style={{
                        borderLeftColor:
                          listLength > 1 ? eventColor : "transparent",
                      }}
                      className={styles.calProgressItem}
                    >
                      <CustomSVG
                        className={
                          listLength > 1
                            ? styles.calProgressDot
                            : styles.calProgressCenterDot
                        }
                        color={eventColor}
                      />
                      <Grid
                        display={"flex"}
                        justifyContent={"space-between"}
                        flexDirection={"row"}
                      >
                        <Tooltip title={name}>
                          <Typography>{name}</Typography>
                        </Tooltip>

                        <Link
                          to={`/patients/${m?.involvedPatient?.patient?.id}/view`}
                          target="_blank"
                          style={{
                            marginRight: 10,
                            marginLeft: 10,
                            textDecoration: "none",
                            cursor: "pointer",
                          }}
                          rel="noopener noreferrer"
                        >
                          <OpenInNewIcon fontSize="small" />
                        </Link>
                      </Grid>
                    </li>
                  );
                })}
              </ol>
            ) : (
              <Box
                sx={{
                  minHeight: 100,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography> No Patients In this session </Typography>
              </Box>
            )}
          </>
        </Grid>
      </Grid>
    </Popover>
  );
}

export default DetailsView;
