import MuiModal from "@mui/material/Modal";
import React from "react";

export const Modal = ({ open, onClose, children, ...props }) => {
  return (
    <MuiModal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={onClose}
      {...props}
    >
      {children}
    </MuiModal>
  );
};
