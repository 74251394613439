import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { useTranslation } from "react-i18next";
import { selectImportLogs, selectIsAutoRefresh } from "../redux/selector";
import { actions } from "../redux/slice";
import { Heading, List } from "./List";

type Props = {
  id: number | null;
  setId: any;
  dialogProps: DialogProps;
};

const useStyles = makeStyles({
  listContainer: {
    maxHeight: 400,
    overflow: "auto",
  },
  headerCheckbox: {
    ml: 1,
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginEnd: "20px",
  },
});

const ImportLogs = (props: Props) => {
  const { items, query } = useSelector(selectImportLogs);
  const autoRefresh = useSelector(selectIsAutoRefresh);
  const dispatch = useDispatch();
  const styles = useStyles();

  const { t } = useTranslation();

  const setQuery = (name: string, value: string) => {};

  useEffect(() => {
    if (props.id) {
      dispatch(actions.getImportLogById({ id: props.id }));
    }
  }, [props.id]);

  useEffect(() => {
    let timer: any = null;
    if (autoRefresh && props.id) {
      timer = setInterval(() => {
        dispatch(actions.getImportLogById({ id: props.id }));
      }, 3000);
    } else {
      clearInterval(timer);
    }

    return () => clearInterval(timer);
  }, [autoRefresh, props.id]);

  const resetLogsDialog = () => {
    dispatch(actions.resetLogsDialog());
    props.setId(null);
  };

  const handleChange = () => {
    dispatch(actions.setAutoRefresh());
  };

  return (
    <Dialog {...props.dialogProps} onClose={resetLogsDialog}>
      <Box className={styles.headerCheckbox}>
        <DialogTitle>Import Logs-{props.id}</DialogTitle>
        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography sx={{ marginRight: 1 }}> Auto Refresh</Typography>
          <Tooltip
            title={autoRefresh ? t("TOOLTIP.ENABLE") : t("TOOLTIP.DISABLE")}
          >
            <Switch
              checked={autoRefresh}
              value={autoRefresh ? "TRUE" : "FALSE"}
              onChange={handleChange}
            ></Switch>
          </Tooltip>
        </Box>
      </Box>
      <DialogContent>
        <Box id="default-scroll" className={styles.listContainer}>
          <TableContainer>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <Heading />
              </TableHead>
              <TableBody>
                <List logs={items} />
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={resetLogsDialog}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImportLogs;
