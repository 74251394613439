import { makeStyles } from "@mui/styles";

export const useImportsStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    marginLeft: "300px",
  },
  inputSectionParent: {
    marginTop: "75px",
    display: "flex",
    alignItems: "center",
  },
  patients: {
    marginLeft: "10px",
  },
  tableContainer: {
    marginTop: 20,
    maxHeight: "calc(100vh - 150px)",
    maxWidth: "calc(159vh)",
    border: "1px solid #C6C9CA",
    overflowY: "auto",
    overflowX: "auto",
    background: "#F5F8FF",
  },
  mainContainer: {
    minHeight: "100%",
    position: "absolute",
    bottom: "auto",
    width: "100%",
  },
});
