import { FormError } from "app/containers/types";
import { TRANSLATE } from "utils/helpers";

export const onValidate = (form) => {
  const errors: FormError[] = [];

  if (form.questionnaireId.value?.length === 0) {
    errors.push({
      name: "questionnaireId",
      error: TRANSLATE("TREATMENT_TYPES.QUESTIONNAIRE_REQUIRED"),
    });
  }
  if (form.beforeAfter.value?.length === 0) {
    errors.push({
      name: "beforeAfter",
      error: TRANSLATE("TREATMENT_TYPES.BEFORE_AFTER_REQUIRED"),
    });
  }

  if (form.hours.value?.length === 0) {
    errors.push({
      name: "hours",
      error: TRANSLATE("TREATMENT_TYPES.HOURS_REQUIRED"),
    });
  }
  if (form.session_id.value?.length === 0) {
    errors.push({
      name: "session_id",
      error: TRANSLATE("TREATMENT_TYPES.SESSION_ID_REQUIRED"),
    });
  }
  return errors;
};

export const isDuplicate = (form, treatments) => {
  const duplicate = treatments.find(
    (treatment) => treatment.questionnaireId === form.questionnaireId.value
  );
  return !!duplicate;
};
