import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  container: {
    background: "#F5F8FF",
    minHeight: "100%",
    minWidth: "100%",
    //  position: "absolute",
    bottom: "auto",
  },
  root: {
    padding: "20px 20px 0px 300px",
    marginTop: "40px",
  },
}));
