import { PayloadAction } from "@reduxjs/toolkit";
import { FormError } from "app/containers/types";
import set from "lodash/set";
import { createSlice } from "utils/@reduxjs/toolkit";
import { AttendanceStatus } from "utils/constants";
import { CalenderListItemType, UserState } from "../types";
const initialState = UserState;
export const CalenderListSlice = createSlice({
  name: "calenderListState",
  initialState,
  reducers: {
    editUser: (state, action: PayloadAction<CalenderListItemType>) => {},
    removeUser: (state) => {
      const editUser: any = {};
      editUser.firstName = "";
      editUser.lastName = "";
      editUser.email = "";
      editUser.phoneNumber = "";
      editUser.creationDate = null;
      editUser.dateOfBirth = null;
    },
    create: (state, action) => {
      state.loading = true;
    },
    createSuccess: (state) => {
      state.loading = false;
      state.form.user = initialState.form.user;
    },
    getList: (state) => {
      state.loading = true;
      state.query.page = 1;
    },
    getUserById: (state, action: PayloadAction<{ id: any }>) => {
      state.loading = true;
    },
    getListSuccess: (
      state,
      action: PayloadAction<{ items: []; links: any; meta: any }>
    ) => {
      state.loading = false;
      const items = action.payload.items;
      state.list.meta = action.payload.meta;
      state.list.items =
        action.payload.meta.currentPage === 1
          ? items
          : [...state.list.items, ...items];
    },
    getNextPage: (state, action: PayloadAction<any>) => {
      state.query.page = state.list.meta.currentPage + 1;
      state.loading = true;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    update: (
      state,
      action: PayloadAction<{ id: any; callback: () => void }>
    ) => {
      state.error = "";
      state.loading = true;
    },
    updateFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state.form, `${action.payload.key}.value`, action.payload.value);
      set(state.form, `${action.payload.key}.error`, null);
      state.error = "";
    },
    updateSuccess(state, action: PayloadAction<CalenderListItemType>) {
      state.loading = false;
    },
    updateFailed: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    delete: (state) => {
      state.error = "";
      state.loading = true;
    },
    deleteSuccess: (state) => {
      state.loading = false;
      state.error = "";
    },
    deleteFailed: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    resetNewUserForm(state) {
      state.error = "";
      state.form = initialState.form;
    },
    setFormErrors(
      state,
      action: PayloadAction<{
        key: string;
        errors: FormError[];
      }>
    ) {
      action.payload.errors.forEach((error) => {
        set(
          state.form,
          `${action.payload.key}.${error.name}.error`,
          error.error
        );
      });
    },
    getNextPageItems: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    getNextPageItemsSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.list.items = state.list.items.concat(action.payload.items);
      state.list.meta.currentPage = action.payload.meta.currentPage;
      state.list.links = action.payload.links;
      state.query.page = action.payload.meta.currentPage;
    },
    setQuery: (state, action: PayloadAction<{ name: string; value: any }>) => {
      const { name, value } = action.payload;
      state.query.page = 1;
      state.list = initialState.list;
      set(state.query, name, value);
    },
    resetModule: (state) => {
      state = initialState;
    },
    handleBlock: (state, action: PayloadAction<string>) => {},
    getTimeslotPatientList: (state, action: PayloadAction<number>) => {
      state.patientList = [];
      state.loading = true;
    },
    updateTimeslotPatientAttendance: (
      state,
      action: PayloadAction<{
        assignedTreatmentId: number;
        attendance: AttendanceStatus;
        index: number;
      }>
    ) => {
      const assignedTreatment = state.attendanceForm.assignedTreatment.find(
        (f) => f.id == action.payload.assignedTreatmentId
      );
      assignedTreatment.isLoading = true;
    },
    updateTimeslotPatientAttendanceSuccess: (
      state,
      action: PayloadAction<{
        assignedTreatmentId;
        attendance: AttendanceStatus;
      }>
    ) => {
      const assignedTreatment = state.attendanceForm.assignedTreatment.find(
        (f) => f.id == action.payload.assignedTreatmentId
      );
      assignedTreatment.isLoading = false;
      assignedTreatment.attendance = action.payload.attendance;
    },
    updateTimeslotPatientAttendanceFailure: (
      state,
      action: PayloadAction<{
        assignedTreatmentId;
      }>
    ) => {
      const assignedTreatment = state.attendanceForm.assignedTreatment.find(
        (f) => f.id == action.payload.assignedTreatmentId
      );
      assignedTreatment.isLoading = false;
    },
    setTimeslotPatientList: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.attendanceForm = action.payload;
    },
    resetQuery: (state) => {
      state.query = initialState.query;
    },
  },
});
export const { reducer, actions, name: sliceKey } = CalenderListSlice;
