import queryString from "query-string";
import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { API_URL } from "utils/constants";
import {
  TRANSLATE,
  getDefaultHeaders,
  removeBlankProperties,
  safeParseJSON,
} from "utils/helpers";
import { request } from "utils/request";
import toastService from "utils/toast";
import { QuestionerForm } from "../types";
import { selectForm, selectMultiQuery, selectOpenQuery } from "./selector";
import { actions } from "./slice";

export function* create(action) {
  yield delay(500);
  try {
    const form: QuestionerForm = yield select(selectForm);
    const newQuestionnaire = {
      questionnaire: {
        name: form.questionnaire.name,
        type: "mcq",
        totalQuestions: form.questionnaire.questions.length,
        questions: form.questionnaire.questions,
        grades: form.questionnaire.grades,
      },
    };
    const requestOptions = {
      method: "POST",
      headers: getDefaultHeaders(),
      body: JSON.stringify(newQuestionnaire),
    };
    const url = `${API_URL}/v1/questionnaires/multi`;
    yield request(url, requestOptions);
    yield put(actions.getMultiList());
    toastService.success(TRANSLATE("QUESTIONNAIRES.CREATED_SUCCESSFULLY"));
    action.payload.navigate("/questionnaires");
  } catch (e) {
    const error = e as Error;
    const { errors } = safeParseJSON(error.message);
    toastService.error(
      TRANSLATE(errors.message, { defaultValue: "Error in saving" })
    );
    yield put(actions.formFailure());
  }
}

export function* updateQuestionnaire(action) {
  yield delay(500);
  try {
    const form: QuestionerForm = yield select(selectForm);
    const newQuestionnaire = {
      questionnaire: {
        name: form.questionnaire.name,
        type: "mcq",
        totalQuestions: form.questionnaire.questions.length,
        questions: form.questionnaire.questions,
        grades: form.questionnaire.grades,
      },
    };
    const requestOptions = {
      method: "PATCH",
      headers: getDefaultHeaders(),
      body: JSON.stringify(newQuestionnaire),
    };
    const url = `${API_URL}/v1/questionnaires/multi/${form.questionnaire.id}`;
    yield request(url, requestOptions);
    yield put(actions.getMultiList());
    toastService.success(TRANSLATE("QUESTIONNAIRES.UPDATED_SUCCESSFULLY"));
    action.payload.navigate("/questionnaires");
  } catch (e) {
    const error = e as Error;
    const { errors } = safeParseJSON(error.message);
    toastService.error(
      TRANSLATE(errors.message, { defaultValue: "Error in saving" })
    );
    yield put(actions.formFailure());
  }
}

export function* createOpen(action) {
  yield delay(500);
  try {
    const form: QuestionerForm = yield select(selectForm);
    const newQuestionnaire = {
      questionnaire: {
        name: form.questionnaire.name,
        type: "mcq",
        totalQuestions: form.questionnaire.questions.length,
        questions: form.questionnaire.questions,
      },
    };
    const requestOptions = {
      method: "POST",
      headers: getDefaultHeaders(),
      body: JSON.stringify(newQuestionnaire),
    };
    const url = `${API_URL}/v1/questionnaires/open`;
    yield request(url, requestOptions);
    yield put(actions.getOpenList());
    toastService.success(TRANSLATE("QUESTIONNAIRES.CREATED_SUCCESSFULLY"));
    action.payload.navigate("/questionnaires?type=open");
  } catch (e) {
    const error = e as Error;
    const { errors } = safeParseJSON(error.message);
    toastService.error(
      TRANSLATE(errors.message, { defaultValue: "Error in saving" })
    );
    yield put(actions.formFailure());
  }
}

export function* updateOpen(action) {
  yield delay(500);
  try {
    const form: QuestionerForm = yield select(selectForm);
    const newQuestionnaire = {
      questionnaire: {
        name: form.questionnaire.name,
        type: "open",
        totalQuestions: form.questionnaire.questions.length,
        questions: form.questionnaire.questions,
      },
    };

    const requestOptions = {
      method: "PATCH",
      headers: getDefaultHeaders(),
      body: JSON.stringify(newQuestionnaire),
    };
    const url = `${API_URL}/v1/questionnaires/open/${action.payload.id}`;
    yield request(url, requestOptions);
    yield put(actions.getOpenList());
    toastService.success(TRANSLATE("QUESTIONNAIRES.UPDATED_SUCCESSFULLY"));
    action.payload.navigate("/questionnaires?type=open");
  } catch (e) {
    const error = e as Error;
    const { errors } = safeParseJSON(error.message);
    toastService.error(
      TRANSLATE(errors.message, { defaultValue: "Error in saving" })
    );
    yield put(actions.formFailure());
  }
}

export function* getListMulti(action) {
  yield delay(500);
  const query = yield select(selectMultiQuery);
  const requestData = removeBlankProperties(query);
  const queries = queryString.stringify({
    ...requestData,
  });
  try {
    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    const response = yield request(
      `${API_URL}/v1/questionnaires/mcq?${queries}`,
      options
    );
    yield put(actions.getMultiListSuccess(response));
  } catch (e: any) {
    yield put(actions.getMultiListFailure());
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", {
        error: JSON.stringify(e),
      })
    );
  }
}

export function* getListOpen(action) {
  yield delay(500);
  const query = yield select(selectOpenQuery);
  const requestData = removeBlankProperties(query);
  const queries = queryString.stringify({
    ...requestData,
  });
  try {
    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    const response = yield request(
      `${API_URL}/v1/questionnaires/open?${queries}`,
      options
    );
    yield put(actions.getQuestionnaireOpenListSuccess(response));
  } catch (e: any) {
    yield put(actions.getQuestionnaireOpenListFailure());
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", {
        error: JSON.stringify(e),
      })
    );
  }
}

export function* deleteRow(action) {
  yield delay(500);
  try {
    const options = {
      method: "DELETE",
      headers: getDefaultHeaders(),
    };
    if (action.payload.id) {
      const response = yield call(
        request,
        `${API_URL}/v1/questionnaires/${action.payload.id}/`,
        options
      );
      yield put(actions.getMultiList());
      toastService.success(TRANSLATE("QUESTIONNAIRES.DELETED_SUCCESSFULLY"));
      action.payload.navigate("/questionnaires");
    }
  } catch (e: any) {
    const error = JSON.parse(e.message);
    console.error(e);
  }
}

export function* getDataById(action) {
  yield delay(500);
  try {
    const options = {
      method: "GET",
      headers: getDefaultHeaders(),
    };
    const response = yield request(
      `${API_URL}/v1/questionnaires/question/${action.payload.id}`,
      options
    );
    yield put(actions.getQuestionnaireDataSuccess(response));
  } catch (e: any) {
    yield put(actions.getQuestionnaireDataFailure());
    toastService.error(
      TRANSLATE("ERRORS.SOMETHING_WENT_WRONG", {
        error: JSON.stringify(e),
      })
    );
  }
}

export function* showError(action) {
  toastService.error(action.payload.message);
}

export function* useQuestionnaireSaga() {
  yield takeLatest(actions.getMultiList.type, getListMulti);
  yield takeLatest(actions.getOpenList.type, getListOpen);
  yield takeLatest(actions.getMultiNextPage.type, getListMulti);
  yield takeLatest(actions.getOpenNextPage.type, getListOpen);
  yield takeLatest(actions.createQuestionnaire.type, create);
  yield takeLatest(actions.createOpenQuestionnaire.type, createOpen);
  yield takeLatest(actions.delete.type, deleteRow);
  yield takeLatest(actions.updateOpen.type, updateOpen);
  yield takeLatest(actions.updateQuestionnaire.type, updateQuestionnaire);
  yield takeLatest(actions.getDataById.type, getDataById);
  yield takeLatest(actions.showError.type, showError);
}
