import { Box, Card, Grid, Modal, Typography, useTheme } from "@mui/material";
import React, { useEffect } from "react";

import { PrimaryButton } from "app/components/Button/PrimaryButton";
import TableLoader from "app/components/DataTable/components/TableLoader";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getFormattedDateTime } from "utils/helpers";
import { selectForm, selectNotificationId } from "../../redux/selector";
import { actions } from "../../redux/slice";
import { MessageType } from "../FiltersForm";
import { getNotificationInfo, getRatingInfo } from "./helper";
import { useStyles } from "./styles";

interface Props {}

const OpenNotificationModal = ({}: Props) => {
  const styles = useStyles();
  const form = useSelector(selectForm);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const direction = useTheme().direction;

  const notificationId = useSelector(selectNotificationId);
  const handleClose = () => {
    dispatch(actions.toggalModal({ id: undefined }));
  };

  useEffect(() => {
    if (notificationId) {
      dispatch(actions.getById({ id: notificationId }));
    }
  }, [notificationId]);

  const isOpen = Number(notificationId) > 0;
  const info = getNotificationInfo(form);
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card
        sx={{
          direction,
        }}
        className={styles.modal}
      >
        <div className={styles.modalContainer}>
          <Grid>
            <Typography align="center" className={styles.headerModal}>
              {t("NOTIFICATIONS.PUSH_NOTIFICATION")}
            </Typography>
          </Grid>
          <div className={styles.bodyContainer}>
            <table className={styles.table}>
              <thead>
                <tr className={styles.trHead}>
                  <td className={styles.tdHeader}>
                    {t("NOTIFICATIONS.PATIENT")}
                  </td>
                  <td className={styles.tdHeader}>{t("NOTIFICATIONS.SEEN")}</td>
                  {form.messageType == MessageType.TREATMENT_PASSED && (
                    <td className={styles.tdHeader}>
                      {t("NOTIFICATIONS.RESPONSE")}
                    </td>
                  )}
                </tr>
              </thead>
              <tbody>
                {form?.isLoading && (
                  <tr className={styles.tr}>
                    <td colSpan={4}>
                      <TableLoader />
                    </td>
                  </tr>
                )}
                {form?.notificationPatients.map((val) => {
                  return (
                    <tr className={styles.tr}>
                      <td className={styles.dateTd}>
                        {val.patient.lastName
                          ? `${val.patient.firstName} ${val.patient.lastName}`
                          : `${val.patient.firstName}`}
                      </td>
                      <td className={styles.dateTd}>
                        {getFormattedDateTime(val.seenAt)}
                      </td>
                      {form.messageType == MessageType.TREATMENT_PASSED && (
                        <td className={styles.dateTd}>{getRatingInfo(val)}</td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>

            <Grid container sx={{ padding: "10px 0px" }}>
              <Grid item xs={12}>
                <label className={styles.labelContent}>
                  {t("NOTIFICATIONS.DETAILS")}
                </label>
                <Box className={styles.box}>{info}</Box>
              </Grid>
            </Grid>
          </div>

          <div>
            <Grid container spacing={3}>
              <Grid
                className={styles.btnContainer}
                container
                item
                xs={12}
                direction="column"
              >
                <PrimaryButton
                  isLoading={form.isLoading}
                  label={t("BUTTONS.CANCEL")}
                  onClick={handleClose}
                  size="small"
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </Card>
    </Modal>
  );
};
export default OpenNotificationModal;
