import { RootState } from "types";

import { createSelector } from "@reduxjs/toolkit";

import { defaultImportsContainerState } from "./types";

export const selectDomain = (state: RootState) =>
  state.importsState || defaultImportsContainerState;
export const selectImportsLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const selectImportsLogsLoading = createSelector(
  [selectDomain],
  (state) => state.logsLoading
);
export const selectImportsList = createSelector(
  [selectDomain],
  (state) => state.list
);
export const selectIsFormOpen = createSelector(
  [selectDomain],
  (state) => state.isFormOpen
);

export const selectIsAutoRefresh = createSelector(
  [selectDomain],
  (state) => state.autoRefresh
);

export const selectImportForm = createSelector(
  [selectDomain],
  (state) => state.form
);
export const selectFileForUpload = createSelector(
  [selectDomain],
  (state) => state.forUpload
);
export const selectImportLogs = createSelector(
  [selectDomain],
  (state) => state.logs
);
export const selectIsOpenLogsDialog = createSelector(
  [selectDomain],
  (state) => state.isLogsOpened
);
