import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "types";
import { QuestionnaireState } from "../types";

const selectDomain = (state: RootState) =>
  state.questionnairesState || QuestionnaireState;

export const selectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);

export const selectQuestionnaire = createSelector(
  [selectDomain],
  (state) => state.form.questionnaire
);

export const selectQuestions = createSelector(
  [selectDomain],
  (state) => state.form.questionnaire.questions
);

export const selectForm = createSelector([selectDomain], (state) => state.form);

export const selectCurrentQuestion = createSelector([selectDomain], (state) => {
  return (
    state.form.questionnaire.questions[state.form.currentQuestionIndex] || {}
  );
});

export const selectCurrentQuestionIndex = createSelector(
  [selectDomain],
  (state) => {
    return state.form.currentQuestionIndex;
  }
);

export const selectCurrentGrade = createSelector([selectDomain], (state) => {
  return state.form.currentGrade;
});

export const selectGrades = createSelector(
  [selectDomain],
  (state) => state.form.questionnaire.grades
);

export const selectHasAnswerAdded = createSelector([selectDomain], (state) => {
  return state.form.questionnaire.questions.some(
    (question) => question.answers.length > 0
  );
});

export const selectList = createSelector([selectDomain], (state) => state.list);
export const selectMultiList = createSelector(
  [selectDomain],
  (state) => state.list.multi
);
export const selectOpenList = createSelector(
  [selectDomain],
  (state) => state.list.open
);

export const selectQuery = createSelector(
  [selectDomain],
  (state) => state.query
);

export const selectMultiQuery = createSelector(
  [selectDomain],
  (state) => state.list.multi.query
);

export const selectOpenQuery = createSelector(
  [selectDomain],
  (state) => state.list.open.query
);

export const selectError = createSelector(
  [selectDomain],
  (state) => state.error
);
