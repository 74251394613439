import { Grid, Tooltip, Typography } from "@mui/material";
import { BottomActions } from "app/components/BottomActions";
import DataTable from "app/components/DataTable";
import { CustomDrawer } from "app/components/Drawer";
import { NoPermission } from "app/components/NoPermission";
import { ScrollableView } from "app/components/ScrollableView";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectFeaturePermissions } from "store/global/authPermission/selector";
import { PermissionFeature } from "store/global/authPermission/types";
import { getFormattedDateTime } from "utils/helpers";
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import Topbar from "../Dashboard/components/topbar";
import FiltersForm from "./components/FiltersForm";
import OpenNotificationModal from "./components/OpenNotificationModal";
import { useNotificationSaga } from "./redux/saga";
import { selectList, selectLoading, selectQuery } from "./redux/selector";
import { actions, reducer, sliceKey } from "./redux/slice";
import { useStyles } from "./styles";
import { ItemsEntity } from "./types";

const Notification = () => {
  useInjectReducer({ key: sliceKey, reducer });
  useInjectSaga({ key: sliceKey, saga: useNotificationSaga });

  const listitems = useSelector(selectList);
  const { meta, items } = listitems;
  const { q, orderField, orderDirection, organizationId, messageType } =
    useSelector(selectQuery);

  const styles = useStyles();

  const { t } = useTranslation();
  const columns = [
    {
      id: "dateTimeSent",
      label: t("NOTIFICATIONS.DATE_TIME_SENT"),
      sortValue: "selector.createdAt",
      format: (val, index) => {
        return getFormattedDateTime(val?.createdAt);
      },
    },
    {
      id: "organizationName",
      label: t("NOTIFICATIONS.ORGANIZATIONAL_NAME"),
      format: (val: ItemsEntity, index) => {
        return (
          <Tooltip title={val.uniqueOrganizationNames}>
            <Typography className={styles.cell}>
              {val.uniqueOrganizationNames}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "bankName",
      label: t("NOTIFICATIONS.BANK_NAME"),
      format: (val: ItemsEntity, index) => {
        return (
          <Tooltip title={val.uniqueBankNames}>
            <Typography className={styles.cell}>
              {val.uniqueBankNames}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "messageType",
      label: t("NOTIFICATIONS.MESSAGE_TYPE"),
      format: (val: ItemsEntity, index) => {
        return val.messageType;
      },
    },
    {
      id: "senderName",
      label: t("NOTIFICATIONS.SENDER_NAME"),
      sortValue: "user.firstName",
      format: (val: ItemsEntity, index) => {
        return val?.user
          ? `${val?.user?.firstName} ${val?.user?.lastName}`
          : "System Push";
      },
    },
    {
      id: "content",
      label: t("NOTIFICATIONS.CONTENT"),
      sortValue: "selector.content",
    },
  ];

  const handleViewAction = (e) => {
    dispatch(actions.toggalModal({ id: e.id }));
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getList());
  }, [q, orderDirection, orderField, organizationId, messageType, dispatch]);

  const setPageQuery = (name: string, value: any) => {
    dispatch(actions.setQuery({ name, value }));
  };

  const hasMoreItems = meta.currentPage < meta.totalPages;
  const getNextPage = () => {
    if (!loading) {
      dispatch(actions.getNextPage(meta.currentPage + 1));
    }
  };

  const loading = useSelector(selectLoading);
  const featurePermissions = useSelector(selectFeaturePermissions);
  const { hasNoAccess } =
    featurePermissions[PermissionFeature.PUSH_NOTIFICATION];
  const { height } = useWindowDimensions();
  if (hasNoAccess) {
    return (
      <div className={styles.mainContainer}>
        <CustomDrawer />
        <Topbar />
        <NoPermission />
      </div>
    );
  }
  return (
    <div className={styles.mainContainer}>
      {}
      <CustomDrawer />
      {}
      <Grid item xs={10}>
        <Topbar q={q} setSearch={(q) => setPageQuery("q", q)} />
        <div className={styles.root}>
          <FiltersForm />

          <ScrollableView
            loadMore={getNextPage}
            hasMoreItems={hasMoreItems}
            height={height - 280}
          >
            <DataTable
              loading={loading}
              orderDirection={orderDirection}
              orderField={orderField}
              setQuery={setPageQuery}
              rows={items}
              columns={columns}
              onRowDoubleClick={handleViewAction}
              page="pushNotificationPage"
              isPointerCursor={true}
            />
          </ScrollableView>

          <BottomActions
            chipBoxes={[
              {
                label: t("PLACEHOLDERS.TOTAL_ITEMS"),
                count: meta.totalItems,
              },
            ]}
          />
        </div>
      </Grid>
      <OpenNotificationModal />
    </div>
  );
};
export default Notification;
