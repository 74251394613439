import { Button, Typography } from "@mui/material";
import React from "react";
import { useStyles } from "./styles";
type Props = {
  isLoading?: boolean;
  label: string;
  icon?: any;
  onClick(): void;
  variant?: "outlined" | "contained";
  disabled?: boolean;
};
export const IconButton = ({
  icon,
  isLoading,
  label,
  onClick,
  variant = "contained",
  disabled,
}: Props) => {
  const styles = useStyles();
  return (
    <Button
      className={styles.gridBtnSmall}
      variant={variant}
      color="primary"
      onClick={onClick}
      startIcon={icon}
      disabled={disabled}
    >
      <Typography> {label} </Typography>
    </Button>
  );
};
