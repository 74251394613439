import { Grid, TextField } from "@mui/material";
import { selectPatients } from "app/containers/Patients/redux/selector";
import { actions } from "app/containers/Patients/redux/slice";
import React from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./styles";

interface PatientDetailsProps {
  view: boolean;
  disable: boolean;
  type: string;
}

const FamilyHistoryTab = ({ view, disable, type }: PatientDetailsProps) => {
  const patientsDetails = useSelector(selectPatients);
  const dispatch = useDispatch();
  const styles = useStyles();
  const { t } = useTranslation();
  const handelChangeValue = (val: any, name: string) => {
    const key = `patient.${name}`;
    dispatch(actions.updateFormValue({ key, value: val }));
  };
  return (
    <Grid
      container
      spacing={2}
      md={12}
      sx={{ marginTop: "20px", marginBottom: "5px", marginLeft: "-5px" }}
    >
      <Grid item xs={6}>
        <label className={styles.label}>
          {t("PATIENTS.BAD_HISTORY_QUESTION_1")}
        </label>
        <TextField
          disabled={disable}
          variant="outlined"
          fullWidth
          margin="dense"
          required
          autoComplete={"off"}
          multiline
          maxRows={7}
          minRows={7}
          name={
            type == "final"
              ? "intakeFinalMeetingHistory1"
              : "intakeInitialMeetingHistory1"
          }
          className={styles.textEnabled}
          label=""
          InputLabelProps={{
            shrink: true,
          }}
          value={
            type == "final"
              ? patientsDetails.intakeFinalMeetingHistory1.value
              : patientsDetails.intakeInitialMeetingHistory1.value
          }
          onChange={(e) => handelChangeValue(e.target.value, e.target.name)}
        />
      </Grid>

      <Grid item xs={6}>
        <label className={styles.label}>
          {t("PATIENTS.BAD_HISTORY_QUESTION_2")}
        </label>
        <TextField
          disabled={disable}
          variant="outlined"
          fullWidth
          margin="dense"
          required
          autoComplete={"off"}
          multiline
          maxRows={7}
          minRows={7}
          name={
            type == "final"
              ? "intakeFinalMeetingHistory2"
              : "intakeInitialMeetingHistory2"
          }
          className={styles.textEnabled}
          label=""
          InputLabelProps={{
            shrink: true,
          }}
          value={
            type == "final"
              ? patientsDetails.intakeFinalMeetingHistory2.value
              : patientsDetails.intakeInitialMeetingHistory2.value
          }
          onChange={(e) => handelChangeValue(e.target.value, e.target.name)}
        />
      </Grid>
    </Grid>
  );
};

export default FamilyHistoryTab;
