import { PayloadAction } from "@reduxjs/toolkit";
import { FormError } from "app/containers/types";
import set from "lodash/set";
import { createSlice } from "utils/@reduxjs/toolkit";
import { ProgressTabListItemType, initialState } from "../types";
export const PatientListSlice = createSlice({
  name: "questionnaireResultState",
  initialState,
  reducers: {
    editUser: (state, action: PayloadAction<ProgressTabListItemType>) => {},
    removeUser: (state) => {
      const editUser: any = {};
      editUser.firstName = "";
      editUser.lastName = "";
      editUser.email = "";
      editUser.phoneNumber = "";
      editUser.creationDate = null;
      editUser.dateOfBirth = null;
    },

    getList: (state) => {
      state.loading = true;
    },
    getById: (state, action: PayloadAction<{ id: any }>) => {
      state.loading = true;
    },
    getListSuccess: (
      state,
      action: PayloadAction<{ items: []; links: any; meta: any }>
    ) => {
      state.loading = false;
      state.list.items = action.payload.items;
      state.list.meta = action.payload.meta;
      state.list.links = action.payload.links;
    },
    getNextPage: (state, action: PayloadAction<any>) => {
      state.query.page = state.list.meta.currentPage + 1;
      state.loading = true;
    },
    getListComplete: (state) => {
      state.loading = false;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    updateFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state.form, `${action.payload.key}.value`, action.payload.value);
      set(state.form, `${action.payload.key}.error`, null);
      state.error = "";
    },

    resetNewUserForm(state) {
      state.error = "";
      state.form = initialState.form;
    },
    setFormErrors(
      state,
      action: PayloadAction<{
        key: string;
        errors: FormError[];
      }>
    ) {
      action.payload.errors.forEach((error) => {
        set(
          state.form,
          `${action.payload.key}.${error.name}.error`,
          error.error
        );
      });
    },
    getNextPageItems: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    getNextPageItemsSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.list.items = state.list.items.concat(action.payload.items);
      state.list.meta.currentPage = action.payload.meta.currentPage;
      state.list.links = action.payload.links;
      state.query.page = action.payload.meta.currentPage;
    },
    setQuery: (state, action: PayloadAction<{ name: string; value: any }>) => {
      const { name, value } = action.payload;
      state.query.page = 1;
      state.list = initialState.list;
      set(state.query, name, value);
    },
    setSelected: (state, action: PayloadAction<Array<Number>>) => {
      state.list.selectedIds = action.payload;
    },
    resetModule: (state) => {
      state = initialState;
    },
    handleBlock: (state, action: PayloadAction<string>) => {},
    handleBlockSuccess: (
      state,
      action: PayloadAction<ProgressTabListItemType>
    ) => {},
    toggalModal: (state) => {
      state.modal = !state.modal;
    },
    setOpenQuestionnaire: (state, action: PayloadAction<any>) => {
      state.form = action.payload;
    },
  },
});
export const { reducer, actions, name: sliceKey } = PatientListSlice;
