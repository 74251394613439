import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { selectQuestionnaireDetails } from "../../redux/selector";
import "./carousel.css";
import QuestionnaireInfoCard from "./components/QuestionnaireInfoCard";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        background: "#fff",
        boxShadow:
          "5px 11px 5px rgba(143, 146, 153, 0.01), 3px 6px 4px rgba(143, 146, 153, 0.05), 1px 3px 3px rgba(143, 146, 153, 0.09), 0px 1px 2px rgba(143, 146, 153, 0.1), 0px 0px 0px rgba(143, 146, 153, 0.1)",
        width: "40px",
        height: "40px",
        borderRadius: "50%",
      }}
      onClick={onClick}
    >
      <ArrowForwardIosIcon
        style={{
          color: "#2A3333",
          fontSize: "30px",
          margin: "auto",
          paddingLeft: "5px",
        }}
      ></ArrowForwardIosIcon>
    </div>
  );
}

const PreviousBtn = (props) => {
  const { className, onClick, style } = props;
  return (
    <>
      <div
        className={className}
        onClick={onClick}
        style={{
          ...style,
          display: "flex",
          background: "#fff",
          boxShadow:
            "5px 11px 5px rgba(143, 146, 153, 0.01), 3px 6px 4px rgba(143, 146, 153, 0.05), 1px 3px 3px rgba(143, 146, 153, 0.09), 0px 1px 2px rgba(143, 146, 153, 0.1), 0px 0px 0px rgba(143, 146, 153, 0.1)",
          width: "40px",
          height: "40px",
          borderRadius: "50%",
        }}
      >
        <ArrowBackIosIcon
          style={{
            color: "#2A3333",
            fontSize: "30px",
            margin: "auto",
            paddingLeft: "5px",
          }}
        ></ArrowBackIosIcon>
      </div>
    </>
  );
};
export default function Carousel({ isLoading }: { isLoading: boolean }) {
  const settings = {
    dots: false,
    infinite: false,
    arrow: true,
    slidesToShow: 2.8,
    slidesToScroll: 1,
    centerPadding: "200px",
    rightPadding: "100px",
    leftPadding: "100px",
    outerWidth: "60vh",
    nextArrow: <SampleNextArrow />,
    prevArrow: <PreviousBtn />,
  };

  const questionnaireDetails = useSelector(selectQuestionnaireDetails);
  const grades = questionnaireDetails.item?.questionnaire.grades;
  if (isLoading) {
    return null;
  }
  console.log({ grades });
  return (
    <Box
      style={{
        width: "72vw",
        margin: 30,
        marginBottom: 50,
      }}
      className="carousel"
    >
      <Slider {...settings}>
        {grades
          ?.filter((m) => !m.mainGrade)
          ?.map((grade, idx) => (
            <QuestionnaireInfoCard key={idx} data={grade} />
          ))}
      </Slider>
    </Box>
  );
}
