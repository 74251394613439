import { Search } from "@mui/icons-material";
import PersonIcon from "@mui/icons-material/Person";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Menu,
  MenuItem,
  Modal,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import clsx from "clsx";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectUser } from "store/global/authPermission/selector";
import { APP_VERSION } from "utils/constants";
import { useInjectReducer } from "utils/redux-injectors";
import { actions as authActions } from "../../../Auth/slice";
import { reducer, sliceKey } from "../../redux/slice";
import { useStyles } from "./styles";
type Props = {
  q?: string;
  setSearch?(q: string): void;
};
export default function TopBar({ q, setSearch }: Props) {
  useInjectReducer({
    key: sliceKey,
    reducer: reducer,
  });
  const user = useSelector(selectUser);

  const [openModal, setOpenModal] = useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const theme = useTheme();

  const styles = useStyles();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleModalClose = () => {
    setOpenModal(false);
  };
  const handleLogout = () => {
    localStorage.clear();
    dispatch(authActions.logout());
  };
  const handleSearchChange = (e) => {
    if (setSearch) {
      const { value } = e.target;
      setSearch(value);
    }
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <>
      <Box className={clsx(styles.root, styles.menuClose)}>
        <Box className={styles.wrapper}>
          <Box className={styles.container}>
            <Box className={styles.leftBox}>
              {setSearch ? (
                <TextField
                  className={styles.search}
                  placeholder={`${t("PLACEHOLDERS.SEARCH")}...`}
                  size="small"
                  value={q}
                  onChange={handleSearchChange}
                  InputProps={{
                    endAdornment: <Search sx={{ color: "#012497" }} />,
                  }}
                />
              ) : null}
            </Box>

            <Tooltip title={t("TOOLTIP.OPEN_SETTINGS")}>
              <Box
                display="flex"
                alignItems="center"
                onClick={handleOpenUserMenu}
                sx={{ cursor: "pointer" }}
              >
                <Avatar
                  src={user?.url}
                  style={{ width: "36px", height: "36px" }}
                >
                  {user?.firstName?.charAt(0)?.toUpperCase()}
                </Avatar>
                <Typography className={styles.Typo}>
                  {user?.firstName}
                </Typography>
              </Box>
            </Tooltip>
            <Menu
              sx={{
                mt: "45px",
              }}
              PaperProps={{
                sx: {
                  borderRadius: "4px",
                  minWidth: "200px",
                  padding: "8px 0",
                },
              }}
              MenuListProps={{
                sx: {
                  padding: 0,
                  direction: theme.direction,
                },
              }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
                onClick={() => {
                  navigate("/profile");
                  handleCloseUserMenu();
                }}
              >
                <PersonIcon sx={{ color: "rgb(110, 117, 141)" }} />
                <Typography>{t("COMMON.PROFILE")}</Typography>
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={handleLogout}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography>{t("COMMON.LOG_OUT")}</Typography>
                <PowerSettingsNewIcon sx={{ color: "rgb(110, 117, 141)" }} />
              </MenuItem>
              <Divider />
              <Typography className={styles.versionInfo}>
                {t("COMMON.APP_VERSION", { version: APP_VERSION })}
              </Typography>
            </Menu>
          </Box>
        </Box>
      </Box>
      <Modal
        open={openModal}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card className={styles.logoutModal}>
          <CardContent className={styles.cardContent}>
            <Typography
              className={styles.headTypography}
              gutterBottom
              variant="h6"
              component="div"
            >
              {t("COMMON.LOG_OUT")}
            </Typography>
            <Typography className={styles.bodyTypography} variant="body2">
              {t("COMMON.CONFIRM_LOG_OUT")}
            </Typography>
          </CardContent>
          <CardActions className={styles.CardActions}>
            <Button
              className={styles.btnNo}
              size="medium"
              onClick={handleModalClose}
            >
              {t("BUTTONS.NO")}
            </Button>
            <Button
              className={styles.btnLogout}
              size="medium"
              onClick={handleLogout}
            >
              {t("COMMON.LOG_OUT")}
            </Button>
          </CardActions>
        </Card>
      </Modal>
    </>
  );
}
