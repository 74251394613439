import MuiRadioGroup from "@mui/material/RadioGroup";
import React from "react";

export const RadioGroup = ({ children, ...props }) => {
  return (
    <MuiRadioGroup
      aria-labelledby="demo-radio-buttons-group-label"
      row
      {...props}
    >
      {children}
    </MuiRadioGroup>
  );
};
