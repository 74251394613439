import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  intakeHeading: {
    marginTop: "20px",
    marginLeft: "10px",
  },
  button: {
    width: "160px",
    height: "50px",
    padding: "10px 16px",
    background: "#3D63E6",
    borderRadius: "4px",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "30px",
    color: "#FFF",
    marginBottom: "40px",
    "&:hover": {
      color: "#3D63E6",
    },
  },
}));
