import { Search } from "@mui/icons-material";
import {
  Button,
  Card,
  Checkbox,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import DataTable from "app/components/DataTable";
import BankFiled from "app/components/FieldComponent/Bank";
import DepartmentFiled from "app/components/FieldComponent/Department";
import OrganizationField from "app/components/FieldComponent/Organization";
import { ScrollableView } from "app/components/ScrollableView";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  selectLoading,
  selectPatientList,
  selectPatientQuery,
} from "../redux/selector";
import { actions } from "../redux/slice";
import { useStyles } from "../styles";
interface TProps {
  open: boolean;
  closeRequest: () => void;
  organizationId: number;
  departmentId: number;
  bankId: number;
  addItems: () => void;
  editindex: number | null;
}

const InvolvedPatientModal = (props: TProps) => {
  const loading = useSelector(selectLoading);
  const dispatch = useDispatch();
  const styles = useStyles();
  const List = useSelector(selectPatientList);
  const [search, setSearch] = useState("");
  const { items, links, selectedIds, meta } = List;
  const {
    q,
    orderField,
    orderDirection,
    organizationId,
    departmentId,
    bankId,
  } = useSelector(selectPatientQuery);

  const { t, i18n } = useTranslation();

  const handleSelectChange = (e, index) => {
    if (props.editindex == -1) {
      if (!e.target.checked) {
        const arr = selectedIds.filter((val) => val !== index);
        if (arr.length == 0) {
          dispatch(actions.setPatientSelected([]));
        }
      } else {
        const arr = [...selectedIds, index];
        dispatch(actions.setPatientSelected(arr));
      }
    } else {
      const arr = [index];
      dispatch(actions.setPatientSelected(arr));
    }
  };
  useEffect(() => {
    dispatch(actions.getPatientBonusList());
    return () => {};
  }, [q, orderField, orderDirection, organizationId, departmentId, bankId]);

  useEffect(() => {
    dispatch(
      actions.resetPatientQuery({
        bankId: 0,
        departmentId: props.departmentId,
        organizationId: props.organizationId,
        treatmentId: 0,
        excludeBankId: props.bankId,
        timeSlotId: 0,
        instructorId: 0,
      })
    );
    return () => {};
  }, [props.organizationId, props.departmentId, props.bankId]);

  const handleSearchChange = (event) => {
    setPageQuery("q", event.target.value);
  };
  const setPageQuery = (name: string, value: any) => {
    dispatch(actions.setPatientQuery({ name, value }));
  };
  const setPageDropDown = (name: string, value: any) => {
    dispatch(actions.setPatientQuery({ name, value }));
  };

  const columnsReg = [
    {
      id: "regActions",
      label: t("TABLES.ACTIONS"),
      sortValue: "selector.patientName",
      format: (val, index) => {
        const checked = selectedIds.includes(val.patientId);
        return (
          <Checkbox
            checked={checked}
            value={""}
            onChange={(e) => {
              handleSelectChange(e, val?.patientId);
            }}
            onDoubleClick={(e) => e.stopPropagation()}
            color="info"
          />
        );
      },
    },
    {
      id: "name",
      label: t("TIMESLOT.PATIENT_NAME"),
      sortValue: "selector.sex",
      format: (val, index) => {
        return (
          <Typography
            sx={{
              color: "#000",
            }}
          >
            {val.firstName} {val.lastName}
          </Typography>
        );
      },
    },
    {
      id: "sex",
      label: t("TIMESLOT.SEX"),
      sortValue: "selector.sex",
    },
    {
      id: "organizationName",
      label: t("TIMESLOT.ORGANIZATION"),
      sortValue: "selector.sex",
    },
    {
      id: "departmentName",
      label: t("TIMESLOT.DEPARTMENT"),
      sortValue: "selector.sex",
    },
    {
      id: "email",
      label: t("TIMESLOT.EMAIL"),
      sortValue: "selector.email",
    },
  ];
  const hasMoreItems = meta.currentPage < meta.totalPages;
  const getNextPage = () => {
    if (!loading) {
      dispatch(actions.getPatientBonusNextList());
    }
  };
  return (
    <Modal
      open={props.open}
      onClose={props.closeRequest}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card
        className={styles.regModal}
        style={{
          direction: i18n.dir(),
        }}
      >
        <div className={styles.modalContainer}>
          <Grid>
            <Typography align="center" className={styles.headerModal}>
              {t("TIMESLOT.ADD_BONUS_PATIENTS")}
            </Typography>
          </Grid>
          <Grid sx={{ padding: "10px 0px" }}></Grid>
          <Grid
            container
            spacing={2}
            md={12}
            sx={{
              padding: "10px 0px 10px 0px",
            }}
          >
            <Grid item xs={3} sx={{ justifyContent: "center" }}>
              <Typography noWrap className={styles.label}>
                {t("ASSIGNED_TREATMENT.SEARCH")}
              </Typography>
              <TextField
                className={styles.searchB}
                placeholder={t("ASSIGNED_TREATMENT.SEARCH")}
                value={q}
                onChange={handleSearchChange}
                InputProps={{
                  endAdornment: <Search sx={{ color: "#012497" }} />,
                }}
              />
            </Grid>

            <OrganizationField
              value={organizationId}
              changeValue={(e) => {}}
              xs={3}
              showAll={true}
              disabled={true}
              activeRequired={true}
            ></OrganizationField>

            <DepartmentFiled
              value={departmentId}
              changeValue={(e) => {}}
              orgId={organizationId}
              xs={3}
              showAll={true}
              disabled={true}
            ></DepartmentFiled>

            <BankFiled
              organizationId={organizationId}
              value={bankId}
              changeValue={(e) => {
                setPageDropDown("bankId", e);
              }}
              departmentId={departmentId}
              xs={3}
              showAll={true}
            ></BankFiled>
          </Grid>
          <ScrollableView
            height={350}
            hasMoreItems={hasMoreItems}
            loadMore={getNextPage}
          >
            <DataTable
              loading={loading}
              orderDirection={orderDirection}
              orderField={orderField}
              rows={items}
              columns={columnsReg}
              editAction={() => {}}
              onRowDoubleClick={() => {}}
              isPointerCursor={true}
            />
          </ScrollableView>
          <div>
            <Grid
              container
              spacing={3}
              sx={{
                padding: "20px 0px 0px 0px",
                justifyContent: "space-between",
              }}
            >
              <Grid
                className={styles.btnContainer}
                container
                item
                xs={6}
                direction="column"
              >
                <Button
                  className={styles.cancelBtn}
                  variant="outlined"
                  onClick={() => props.closeRequest()}
                >
                  {t("BUTTONS.CANCEL")}
                </Button>
              </Grid>
              <Grid container item xs={6} direction="column">
                <Button
                  className={styles.saveBtn}
                  onClick={() => props.addItems()}
                  variant="contained"
                  disabled={selectedIds.length == 0}
                >
                  {t("BUTTONS.SAVE")}
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </Card>
    </Modal>
  );
};

export default InvolvedPatientModal;
