import React from "react";

import { Grid, Typography } from "@mui/material";
import { selectAvailableTimeSlots } from "app/containers/AssignedTreament/redux/selector";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export const TimeslotInfo = () => {
  const availableTimeSlots = useSelector(selectAvailableTimeSlots);
  const treatmentInvolvedPatient = availableTimeSlots?.treatmentInvolvedPatient;

  const treatmentBankTreatment =
    treatmentInvolvedPatient?.treatmentBankTreatment;
  const treatment = treatmentBankTreatment?.treatment;
  const { t } = useTranslation();
  const isMust = treatmentBankTreatment?.isMust == true;
  const isMultiSession = availableTimeSlots?.type == 2;
  const maxTreatmentAmount = treatmentBankTreatment?.maximumPerPatient || 0;
  const minTreatmentAmount = isMust ? 1 : 0;
  const treatmentInvolvedPatientsCount =
    treatmentInvolvedPatient &&
    treatmentInvolvedPatient?.timeslotInvolvedPatients.length;
  if (treatmentInvolvedPatientsCount !== 0) {
    return null;
  }
  return (
    <Grid
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      xs={12}
      height={200}
    >
      {!treatmentInvolvedPatient?.instructorId ? (
        <Typography> {t("TIMESLOT.PLEASE_SELECT_INSTRUCTOR")} </Typography>
      ) : (
        <Typography>
          {" "}
          {t("ASSIGNED_TREATMENT.PLEASE_ADD_TREATMENT_AMOUNT")}{" "}
        </Typography>
      )}
    </Grid>
  );
};
