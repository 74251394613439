import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "types";
import { UserState } from "../types";
import { UserState as TreatmentTypeState } from "../../../types";

const selectDomain = (state?: RootState) =>
  state?.addTreatmentStateType || UserState;
const treatmentTypeListDomain = (state?: RootState) =>
  state?.treatmentTypeState || TreatmentTypeState;

export const selectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const selectList = createSelector([selectDomain], (state) => state.list);
export const selectQuery = createSelector(
  [selectDomain],
  (state) => state.query
);
export const selectEditContent = createSelector(
  [selectDomain],
  (state) => state.form.content
);
export const selectEditQuestionnaire = createSelector(
  [selectDomain],
  (state) => state.form.questionaire
);
export const selectEditTreatment = createSelector(
  [selectDomain],
  (state) => state.form.treatment
);
export const selectQuestionnaireList = createSelector(
  [selectDomain],
  (state) => state.questionnaireList
);
export const selectError = createSelector(
  [selectDomain],
  (state) => state.error
);
export const treatmentTypesList = createSelector(
  [treatmentTypeListDomain],
  (state) => state.list
);
export const selectQuestionnaireModal = createSelector(
  [selectDomain],
  (state) => state.questionnaireModal
);
export const selectcontentModal = createSelector(
  [selectDomain],
  (state) => state.contentModal
);
