import { Bank } from "app/containers/AssignedTreament/types";
import { FormElement } from "app/containers/types";
import { InvolvementType } from "utils/constants";
export enum TimeSlotMode {
  SINGLE = 1,
  MULTI = 2,
}
export enum TreatmentType {
  IND = 1,
  GROUP = 2,
}
export interface ResponseError {
  message: string;
}
export type RequestError = ResponseError;
export type Patient = {
  id: number;
  patientId: number;
  assignedTreatmentId?: number | null;
  type?: InvolvementType;
  firstName: string;
  lastName: string;
  sex: string;
  email: string;
  cellular: string;
  deleted: boolean;
};
export type PatientItemType = {
  id: number;
  patientId: number;
  firstName: string;
  lastName: string;
  sex: string;
  organizationName: string;
  departmentName: string;
  email: string;
  cellular: string;
};
export type TimeSlotsActionsListItemType = {
  id: number;
  sessionNumber: number;
  meetingId: string | null;
  date: string | null;
  start_time: string | null;
  end_time: string | null;
  involvedPatients: Patient[];
  action: string;
  isActive?: boolean;
  completedAt?: string;
  isDeleted?: boolean;
};

export type TreatmentBankInfo = {
  totalPatientCapacity: number;
  type: "individual" | "group";
  groupPerPatient: number;
  individualPerPatient: number;
  totalTreatmentPatientCapacity: number;
};
export interface NewUserRequest {
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
  companyName: string;
  designation: any;
  numberOfEmployees: string;
  user_type: string;
  isActive: any;
}

export type NewUserResponse = {
  id: any;
  codeSentAt: any;
  firstName: string;
  lastName: string;
  mobileNumber: string;
  countryCode: any;
  email: string;
  companyName: string;
  designation: string;
  numberOfEmployees: string;
  language: string;
  isActive: true;
  role: any;
  image: any;
  userType: string;
  createdAt: string;
  updatedAt: string;
  fullName: string;
  message?: string;
};

export type TimeSlotForm = {
  id: FormElement;
  departmentId: FormElement;
  organizationId: FormElement;
  bankId: FormElement;
  treatmentId: FormElement;
  instructorId: FormElement;
  actualInstructorId: FormElement;
  street: FormElement;
  cityId: FormElement;
  slotDate: FormElement;
  type: TimeSlotMode;
  hasPassedSessions?: boolean;
  associatedPatientCount: FormElement;
  bank?: Bank;
};

export type timeSlotActionsListStateType = {
  editIndex: number;
  query: {
    limit: number;
    page: number;
    distributorId: string;
    user_type: string;
    q: string;
    orderField: string;
    orderDirection: string;
    createMin: string;
    createMax: string;
    updateMin: string;
    updateMax: string;
  };
  form: TimeSlotForm;
  bankInfo: TreatmentBankInfo;
  sessionList: {
    items: Array<TimeSlotsActionsListItemType>;
    selectedIds: Array<Number>;
    meta: {
      totalItems: number;
      itemCount: number;
      itemsPerPage: number;
      totalPages: number;
      currentPage: number;
    };
    links: {
      first: string;
      last: string;
      previous: string;
      next: string;
    };
  };
  timeslotBonusAssociation: {
    items: Array<Patient>;
  };
  involvedPatientList: {
    items: Array<Patient>;
    meta: {
      totalItems: number;
      itemCount: number;
      itemsPerPage: number;
      totalPages: number;
      currentPage: number;
    };
    links: {
      first: string;
      last: string;
      previous: string;
      next: string;
    };
  };
  patientQuery: {
    limit: number;
    page: number;
    organizationId: number;
    departmentId: number;
    instructorId: number;
    bankId: number;
    treatmentId: number;
    excludeBankId: number;
    timeSlotId: number;
    q: string;
    orderField: string;
    orderDirection: string;
  };
  patientList: {
    items: Array<PatientItemType>;
    selectedIds: Array<Number>;
    selectedOtherSessionsPatientsIds: Array<Number>;
    selected: Array<Number>;
    meta: {
      totalItems: number;
      itemCount: number;
      itemsPerPage: number;
      totalPages: number;
      currentPage: number;
    };
    links: {
      first: string;
      last: string;
      previous: string;
      next: string;
    };
  };
  loading: boolean;
  search: string;
  error: string;
  registerPatientModal: boolean;
  involvedPatientModal: boolean;
  editTimeSlotModel: boolean;
  deleteModal: boolean;
  editState: boolean;
  sessionDuration: number;
  mode: TimeSlotMode;
  treatmentType: TreatmentType;
};

export const UserState: timeSlotActionsListStateType = {
  bankInfo: {
    totalPatientCapacity: 0,
    type: "individual",
    groupPerPatient: 0,
    individualPerPatient: 0,
    totalTreatmentPatientCapacity: 0,
  },
  editIndex: -1,
  query: {
    user_type: "",
    distributorId: "",
    limit: 10,
    page: 1,
    q: "",
    orderField: "",
    orderDirection: "",
    updateMax: "",
    updateMin: "",
    createMax: "",
    createMin: "",
  },
  sessionList: {
    selectedIds: [],
    items: [],
    meta: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 100,
      totalPages: 1,
      currentPage: 1,
    },
    links: {
      first: "",
      previous: "",
      next: "",
      last: "",
    },
  },
  patientQuery: {
    timeSlotId: 0,
    bankId: 0,
    excludeBankId: 0,
    instructorId: 0,
    departmentId: 0,
    treatmentId: 0,
    orderField: "",
    organizationId: 0,
    q: "",
    limit: 5,
    orderDirection: "",
    page: 1,
  },
  patientList: {
    selectedIds: [],
    selectedOtherSessionsPatientsIds: [],
    items: [],
    selected: [],
    meta: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 100,
      totalPages: 1,
      currentPage: 1,
    },
    links: {
      first: "",
      previous: "",
      next: "",
      last: "",
    },
  },
  timeslotBonusAssociation: {
    items: [],
  },
  involvedPatientList: {
    items: [],
    meta: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 100,
      totalPages: 1,
      currentPage: 1,
    },
    links: {
      first: "",
      previous: "",
      next: "",
      last: "",
    },
  },
  loading: false,
  search: "",
  form: {
    id: { value: "" },
    departmentId: { value: 0 },
    organizationId: { value: 0 },
    bankId: { value: 0 },
    treatmentId: { value: 0 },
    instructorId: { value: 0 },
    actualInstructorId: { value: 0 },
    street: { value: "" },
    cityId: { value: 0 },
    slotDate: { value: null, error: "" },
    type: TimeSlotMode.SINGLE,
    hasPassedSessions: false,
    associatedPatientCount: {
      value: 0,
    },
  },
  registerPatientModal: false,
  involvedPatientModal: false,
  editTimeSlotModel: false,
  deleteModal: false,
  editState: false,
  sessionDuration: 0,
  error: "",
  mode: TimeSlotMode.SINGLE,
  treatmentType: TreatmentType.IND,
};

export type ContainerState = timeSlotActionsListStateType;
